import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetFieldIdDialogComponent } from './get-field-id-dialog.component';
import { FormsModule } from '@angular/forms';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';

@NgModule({
  declarations: [GetFieldIdDialogComponent],
  imports: [AppMaterialModule, CommonModule, FormsModule],
  entryComponents: [GetFieldIdDialogComponent]
})
export class GetFieldIDDialogModule {}
