import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { CCMCNavigationService } from '../../navigation.service';
import { NavigationEnd, Router } from '@angular/router';
import { CCMCAnimations } from '../../../../animations/index';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { navigation } from 'src/app/navigation/navigation';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { GlobalSearchService } from 'src/@ccmc/services/global-search.service';

@Component({
  selector: 'ccmc-nav-vertical-collapse',
  templateUrl: './nav-vertical-collapse.component.html',
  styleUrls: ['./nav-vertical-collapse.component.scss'],
  animations: CCMCAnimations
})
export class CCMCNavVerticalCollapseComponent implements OnInit {
  @Input() item: any;
  @HostBinding('class') classes = 'nav-collapse nav-item';
  @HostBinding('class.open') public isOpen = false;
  showSpinner = false; // flag for the mat-spinner
  currentSort = {button: 'numeric', alphaNumeric: 'numeric'};
  setVisibility: boolean = false;
  dynamicNav: any = navigation;
  defaultInstance: boolean = true;
  firstEye: boolean = false;
  isNXTsoftDev = this.amplifyService.isNXTsoftDev;
  isNXTsoftOperations = this.amplifyService.isNXTsoftOperations;
  sentFlag: boolean = false;
  receiptList: any;
  croppedTitle: any;
  transactionHistoryToggle: any;

  constructor(
    public ccmcApiService: CcmcApiService,
    private navigationService: CCMCNavigationService,
    private spinnerService: SpinnerService,
    private router: Router,
    private amplifyService: AmplifyService,
    private globalSearchService: GlobalSearchService
  ) {
    // Listen for route changes
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Check if the url can be found in
        // one of the children of this item
        if (this.isUrlInChildren(this.item, event.urlAfterRedirects)) {
          this.expand();
        } else {
          this.collapse();
        }
      }
    });

    // Listen for collapsing of any navigation item
    this.navigationService.onItemCollapsed.subscribe(clickedItem => {
      if (clickedItem && clickedItem.children) {
        // Check if the clicked item is one
        // of the children of this item
        if (this.isChildrenOf(this.item, clickedItem)) {
          return;
        }

        // Check if the url can be found in
        // one of the children of this item
        if (this.isUrlInChildren(this.item, this.router.url)) {
          return;
        }

        // If the clicked item is not this item, collapse...
        if (this.item !== clickedItem) {
          this.collapse();
        }
      }
    });

    // Handles if eye icons are visible
    // and whether all transactions are shown or just the default
    this.navigationService.onVisibilityChange.subscribe(currentState => {
      this.setVisibility = currentState;
    });

    // Keeps track of which sorting button is currently selected
    // and if sort should remain alpha or numerical when sorting by eye
    this.navigationService.onSortChange.subscribe(currentState => {
      this.currentSort = currentState;
    });

    this.ccmcApiService.transactionReceiptList.subscribe(receiptList => {
      if (receiptList) {
        this.receiptList = receiptList;
      }
    });

    this.navigationService.transactionHistoryToggle.subscribe(toggleState => {
      this.transactionHistoryToggle = toggleState;
    });
  }

  ngOnInit() {
    // subscribes to the status of mat-spinner
    this.navigationService.onSpinnerChange$.subscribe(result => {
      this.showSpinner = result;
    });

    // Prevents Transactions from getting an eye icon
    if (this.item.title !== 'Transactions') {
      this.item.eye = true;
    }

    // Gets transaction receipt info to show a transaction has successfully been sent or not
    if (this.item.title === 'Transactions') {
      this.ccmcApiService.currentLoanNumber.subscribe(result => {
        this.ccmcApiService
          .getTransactionInfo(result)
          .subscribe((transactionInfo: any) => {
            console.log('Transaction Info ', transactionInfo);
            this.ccmcApiService.transactionReceiptList.next(transactionInfo);
          });
      });
    }

    // Required for CSI
    if (this.item.title === 'LoanNote') {
      this.ccmcApiService.currentLoanNumber.subscribe(result => {
        this.ccmcApiService
          .getTransactionInfo(result)
          .subscribe((transactionInfo: any) => {
            console.log('LoanNote Info ', transactionInfo);
            this.ccmcApiService.transactionReceiptList.next(transactionInfo);
          });
      });
    }

    // Crops any title that is too long to fully show in Transaction nav
    this.croppedTitle = this.item.title.length > 28 ? this.item.title.slice(0, 28) + '...' : this.item.title;

    // Sets sentFlags to show if a transaction has been sent
    if (this.receiptList && !this.receiptList.hasOwnProperty('statusFlag')) {
      for (let listItem of this.receiptList.transactionInfo) {
        if (listItem && listItem.transactionID === this.item.id) {
          this.sentFlag = listItem.wasSuccessfulFlag;
        }
      }
    }

    // Setting up default transactions to display
    // if (this.ccmcApiService.mapping !== undefined &&
    //   this.ccmcApiService.transactions[this.item.transactionIndex] &&
    //   this.ccmcApiService.transactions[this.item.transactionIndex].display === true) {
    //     this.item.visible = true;
    // }

    // Check if the url can be found in
    // one of the children of this item
    if (this.isUrlInChildren(this.item, this.router.url)) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  /**
   * Toggle collapse
   *
   * @param ev
   */
  toggleOpen(ev: any) {
    ev.preventDefault();

    this.isOpen = !this.isOpen;

    // Navigation collapse toggled...
    this.navigationService.onItemCollapsed.next(this.item);
    this.navigationService.onItemCollapseToggled.next(0);
  }

  /**
   * Expand the collapsable navigation
   */
  expand() {
    if (this.isOpen) {
      return;
    }

    this.isOpen = true;
    this.navigationService.onItemCollapseToggled.next(0);
  }

  /**
   * Collapse the collapsable navigation
   */
  collapse() {
    if (!this.isOpen) {
      return;
    }

    this.isOpen = false;
    this.navigationService.onItemCollapseToggled.next(0);
  }

  /**
   * Check if the given parent has the
   * given item in one of its children
   *
   * @param parent
   * @param item
   * @return {any}
   */
  isChildrenOf(parent: any, item: any): any {
    if (!parent.children) {
      return false;
    }

    if (parent.children.indexOf(item) !== -1) {
      return true;
    }

    for (const children of parent.children) {
      if (children.children) {
        return this.isChildrenOf(children, item);
      }
    }
  }

  /**
   * Check if the given url can be found
   * in one of the given parent's children
   *
   * @param parent
   * @param url
   * @returns {any}
   */
  isUrlInChildren(parent: any, url: any) {
    if (!parent.children) {
      return false;
    }

    for (let i = 0; i < parent.children.length; i++) {
      if (parent.children[i].children) {
        if (this.isUrlInChildren(parent.children[i], url)) {
          return true;
        }
      }

      if (
        parent.children[i].url === url ||
        url.includes(parent.children[i].url)
      ) {
        return true;
      }
    }

    return false;
  }

  blinkOfAnEye(selectedItem: any) {
    if (selectedItem.eye === true) {
      selectedItem.eye = false;
    } else {
      selectedItem.eye = true;
    }
    
    // Handling if eye sort options is already selected and
    // the user slashes an eye
    if (this.currentSort.button === 'eye') {
      this.eyeSort(selectedItem);
    }
  }

  // Sorting by visibility when the eye sort is selected
  fullEyeSort() {
    let tempStart: any = [];
    let tempOpen: any = [];
    let tempSlashed: any = [];
    for (let index = 2; index >= 0; index--) {
      if (this.dynamicNav[2].children[1].children[index].id === "issueList" || 
      this.dynamicNav[2].children[1].children[index].id === "reviewList" || 
      this.dynamicNav[2].children[1].children[index].id === "changerequestList") {
        tempStart.push(this.dynamicNav[2].children[1].children[index]);
      }
    }
    this.dynamicNav[2].children[1].children.splice(0,3);

    this.dynamicNav[2].children[1].children.forEach((transaction: any) => {
      if (transaction.eye === true) {
        tempOpen.push(transaction);
      } else {
        tempSlashed.push(transaction);
      }
    });

    // Holds sorting type that was previously selected
    // when sorting by visibility
    if (this.currentSort.alphaNumeric === 'alpha') {
      tempOpen = this.alphaSort(tempOpen);
      tempSlashed = this.alphaSort(tempSlashed);
    } else if (this.currentSort.alphaNumeric === 'numeric') {
      tempOpen = this.numericSort(tempOpen);
      tempSlashed = this.numericSort(tempSlashed);
    }

    tempStart.forEach((transaction: any) => {
      tempOpen.unshift(transaction);
    });
    tempSlashed.forEach((transaction: any) => {
      tempOpen.push(transaction);
    });

    this.dynamicNav[2].children[1].children = tempOpen;

    this.navigationService.onSortChange.next({button: 'eye', alphaNumeric: this.currentSort.alphaNumeric});
  }

  // Handles if eye sort is already selected
  // and user slashes an eye
  eyeSort(data: any) {
    let temp: any = [];
    const transactionIndex = this.dynamicNav[2].children[1].children.findIndex(
      (transaction: any) => transaction == data
    );
    temp.push(this.dynamicNav[2].children[1].children[transactionIndex]);
    this.dynamicNav[2].children[1].children.splice(transactionIndex, 1);

    // Holds sorting type that was previously selected
    // when sorting by visibility
    if (this.currentSort.alphaNumeric === 'alpha') {
      this.dynamicNav[2].children[1].children.forEach((transaction: any, index: any) => {
        if (transaction.eye === false) {
          temp.push(transaction);
          this.dynamicNav[2].children[1].children.splice(index, 1);
        }
      });
      temp = this.alphaSort(temp);
    } else if (this.currentSort.alphaNumeric === 'numeric') {
      this.dynamicNav[2].children[1].children.forEach((transaction: any, index: any) => {
        if (transaction.eye === false) {
          temp.push(transaction);
          this.dynamicNav[2].children[1].children.splice(index, 1);
        }
      });
      temp = this.numericSort(temp);
    }
    temp.forEach((transaction: any) => {
      this.dynamicNav[2].children[1].children.push(transaction);
    });
  }

  // Sorts transactions alphabetically when alpha button is clicked
  fullAlphaSort() {
    let tempStart = [];
    let tempEnd = [];
    for (let index = 2; index >= 0; index--) {
      if (this.dynamicNav[2].children[1].children[index].id === "issueList" || 
      this.dynamicNav[2].children[1].children[index].id === "reviewList" || 
      this.dynamicNav[2].children[1].children[index].id === "changerequestList") {
        tempStart.push(this.dynamicNav[2].children[1].children[index]);
      }
    }
    this.dynamicNav[2].children[1].children.splice(0,3);
    tempEnd = this.alphaSort(this.dynamicNav[2].children[1].children);

    tempStart.forEach((transaction: any) => {
      tempEnd.unshift(transaction);
    });

    this.dynamicNav[2].children[1].children = tempEnd;

    this.navigationService.onSortChange.next({button: 'alpha', alphaNumeric: 'alpha'});
  }

  // Default alphabetical sort
  alphaSort(data: any) {
    data.sort(function (a: any, b: any) {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    });
    return data;
  }

  // Sorts transactions by index when numeric is clicked
  fullNumericSort() {
    let tempStart = [];
    let tempEnd = [];

    for (let index = 2; index >= 0; index--) {
      if (this.dynamicNav[2].children[1].children[index].id === "issueList" || 
      this.dynamicNav[2].children[1].children[index].id === "reviewList" || 
      this.dynamicNav[2].children[1].children[index].id === "changerequestList") {
        tempStart.push(this.dynamicNav[2].children[1].children[index]);
      }
    }
    this.dynamicNav[2].children[1].children.splice(0,3);

    tempEnd = this.numericSort(this.dynamicNav[2].children[1].children);
    tempStart.forEach((transaction: any) => {
      tempEnd.unshift(transaction);
    });

    this.dynamicNav[2].children[1].children = tempEnd;

    this.navigationService.onSortChange.next({button: 'numeric', alphaNumeric: 'numeric'});
  }

  // Default numeric sort
  numericSort(data: any) {
    data.sort(function(a: any, b: any) {
      return a.transactionIndex - b.transactionIndex;
    });
    return data;
  }

  // Visibility settings toggle
  displaySettings() {
    this.spinnerService.setShowSpinner(true);
    this.navigationService.setShowNavSpinner(true);
    if (this.setVisibility === true) {
      // Hides transactions with slashed eyes and eye sort button
      this.dynamicNav[2].children[1].children.forEach((transaction: any) => {
        if (transaction.eye === false) {
          transaction.visible = false;
          const globalTransactionIndex = this.globalSearchService.transactionsObject.findIndex(
            (obj: any) => obj.transactionID === transaction.id
          );
          if (globalTransactionIndex > -1) {
            this.globalSearchService.transactionsObject[globalTransactionIndex].display = false;
          }
        } else {
          const globalTransactionIndex = this.globalSearchService.transactionsObject.findIndex(
            (obj: any) => obj.transactionID === transaction.id
          );
          if (globalTransactionIndex > -1) {
            this.globalSearchService.transactionsObject[globalTransactionIndex].display = true;
          }
        }
      });
      this.navigationService.onVisibilityChange.next(false);
    } else {
      // Reveals all transactions and eye sort button
      // Transactions that were previously hidden will have slashed eyes
      this.dynamicNav[2].children[1].children.forEach((transaction: any) => {
        transaction.visible = true;
        // if (this.navigationService.defaultInstance === true) {
          if (this.ccmcApiService.mapping !== undefined &&
          this.ccmcApiService.transactions[transaction.transactionIndex] &&
          this.ccmcApiService.transactions[transaction.transactionIndex].display === true) {
            transaction.eye = true;
          } else if (
            transaction.id === "issueList" || 
            transaction.id === "reviewList" || 
            transaction.id === "changerequestList") {
              transaction.eye = true;
              this.navigationService.nonIndexedNavItems++;
          } else {
            transaction.eye = false;
          }
        // }
        // Determines the end of the default instance
        if ((this.dynamicNav[2].children[1].children.length - (this.navigationService.nonIndexedNavItems + 1)) === transaction.transactionIndex) {
          // this.navigationService.defaultInstance = false;
        }
      });
      this.navigationService.onVisibilityChange.next(true);
    }

    this.navigationService.setShowNavSpinner(false);
    this.spinnerService.setShowSpinner(false);
  }
}
