import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

export class SelectRowColor {
  /**
   * Value
   *
   * @type {string}
   * @memberof SelectRowColor
   */
  value: string;

  /**
   * View Value
   *
   * @type {string}
   * @memberof SelectRowColor
   */
  viewValue: string;
}
@Component({
  selector: 'app-color-picker-dialog',
  templateUrl: './color-picker-dialog.component.html',
  styleUrls: ['./color-picker-dialog.component.scss']
})
export class ColorPickerDialogComponent implements OnInit {
  newColor: any;
  selectionColor: SelectRowColor[] = [
    {
      value: '97D3E7',
      viewValue: 'Blue'
    },
    {
      value: 'BAB4F1',
      viewValue: 'Purple'
    },
    {
      value: 'F1CD7B',
      viewValue: 'Orange'
    },
    {
      value: 'D1D1D1',
      viewValue: 'Grey (Review List)'
    },
    {
      value: 'FFFFFF',
      viewValue: 'White'
    }
  ];
  selectedSub: any;
  unsubscribe: Subject<any> = new Subject();
  selectedColor: any;
  localSelected = {
    rowColor: ''
  }
  selected: any;
  selectedColorButton: number;
  constructor(
    private dialogRef: MatDialogRef<ColorPickerDialogComponent>,
    private selectedFieldService: CCMCSelectedFieldService,
    @Inject(MAT_DIALOG_DATA) public mapping: any
    ) {}

  ngOnInit() {
    this.selected = this.mapping;
    this.localSelected = JSON.parse(JSON.stringify(this.selected));
  }

  updateRowColor() {
    this.dialogRef.close(this.localSelected.rowColor);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
