import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone
} from '@angular/core';
import { Subscription, Subject, fromEvent } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
import { MatDialog } from '@angular/material/dialog';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { MatTableDataSource } from '@angular/material/table';
import {
  takeUntil,
  map,
  debounceTime,
  distinctUntilChanged
} from 'rxjs/operators';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { CCMCConfirmDialogComponent } from 'src/@ccmc/components/confirm-dialog/confirm-dialog.component';
import { CCMCAddSupportValuesDialogComponent } from 'src/@ccmc/components/add-support-values-dialog/add-support-values-dialog.component';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';

@Component({
  selector: 'app-support-values',
  templateUrl: './support-values.component.html',
  styleUrls: ['./support-values.component.scss']
})
export class SupportValuesComponent implements OnInit {
  private documentCoreSub: Subscription;
  private selectedSub: Subscription;
  showSpinner: boolean;
  private spinnerSub: Subscription;
  private fieldEditedSub: Subscription;
  private editedObjectSub: Subscription;
  fieldEditedFlag = false;
  supportValues: any;
  displayedColumns = ['category', 'source', 'value', 'edited'];
  dataSource: any;
  tempDocument: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filterSearch', { static: true }) filterSearchEl: ElementRef;
  public selected: any;
  public selectedFilter: any;
  filterSearch: string;
  dialogRef: any;
  editedFlag = false;
  public localSelected: any;
  editedObjectsArray : any[];
  unsubscribe: Subject<any> = new Subject();

  /**
   * Creates an instance of SupportValuesComponent.
   * @param {CcmcApiService} ccmcApiService
   * @param {AdminApiService} adminApiService
   * @param {CCMCSelectedFieldService} selectedFieldService
   * @param {SpinnerService} spinnerService
   * @param {MatDialog} dialog
   * @param {FieldEditedService} fieldEditedService
   * @param {SnackbarService} snackBarService
   * @memberof SupportValuesComponent
   */
  constructor(
    private ccmcApiService: CcmcApiService,
    private adminApiService: AdminApiService,
    private selectedFieldService: CCMCSelectedFieldService,
    private spinnerService: SpinnerService,
    private dialog: MatDialog,
    private fieldEditedService: FieldEditedService,
    private snackBarService: SnackbarService
  ) {}

  /**
   * On Init
   *
   * @memberof SupportValuesComponent
   */
  ngOnInit() {
    // Set field edited to false
    this.fieldEditedService.fieldEdited.next(false);
    // Set spinner to true
    this.spinnerService.setShowSpinner(true);
    // Set filter to all
    this.selectedFilter = 'all';
    // Get Data
    this.getData();
    // Init filter search
    this.initFilterSearch();
    // Spinner sub
    this.spinnerSub = this.spinnerService.spinner.subscribe(spinner => {
      this.showSpinner = spinner;
    });
  }

  /**
   * On Destroy
   *
   * @memberof SupportValuesComponent
   */
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  /**
   * Get Data
   * @description Gets the data needed for the component
   * @memberof SupportValuesComponent
   */
  getData() {
    this.editedObjectSub = this.fieldEditedService.editedObjects
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => {
        this.editedObjectsArray = value;
        //console.log(this.editedObjectsArray);
      });
    // Subscribe to selected support validation thread
    this.selectedSub = this.selectedFieldService.onSupportValueFieldSelected
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(selected => {
        if (this.editedFlag) {
          // If there is currently a field edited, ask user to save changes before selecting new value
          const confirmDialogRef = this.dialog.open(
            CCMCConfirmDialogComponent,
            {
              data: 'Save your current changes?'
            }
          );
          confirmDialogRef.afterClosed().subscribe(data => {
            // If true
            if (data) {
              // save the support value
              this.save();
              // Set new selected
              this.selected = selected;
              // Set edited flag to false for new field
              this.editedFlag = false;
              // Set fieldEdited flag to flase
              this.fieldEditedService.fieldEdited.next(false);
              // Set local support value
              this.localSelected = {
                category: selected.category,
                source: selected.source,
                value: selected.value,
                edited: selected.edited
              };
              // Dont save
            } else {
              // set selected
              this.selected = selected;
              // Set edited flag to false for new field
              this.editedFlag = false;
              // Set fieldEdited flag to false
              this.fieldEditedService.fieldEdited.next(false);
              // Set local support value
              this.localSelected = {
                category: selected.category,
                source: selected.source,
                value: selected.value,
                edited: selected.edited
              };
            }
          });
          // No current edits made, user can select freely
        } else {
          // Set Selected
          this.selected = selected;
          // Set edited Flag
          this.editedFlag = false;
          // Set fieldEdited Flag
          this.fieldEditedService.fieldEdited.next(false);
          // Sert local support value
          this.localSelected = {
            category: selected.category,
            source: selected.source,
            value: selected.value,
            edited: selected.edited
          };
        }
      });
    // Subscribe to the document
    this.documentCoreSub = this.adminApiService.documentCore
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(documentCore => {
        if (documentCore) {
          // Set spinner to false
          this.spinnerService.setShowSpinner(false);
          // Set temp Document
          this.tempDocument = documentCore;
          // If support values exist
          if (documentCore.supportValues) {
            // Set support values
            this.supportValues = documentCore.supportValues;
            // If there are no support values we return
            if (this.supportValues.length === 0) {
              return;
            }
            // Set Datasource for the material table
            this.dataSource = new MatTableDataSource(this.supportValues);
            // initializes pagination
            this.dataSource.paginator = this.paginator;
            // initializes sort
            this.dataSource.sort = this.sort;

            // initializes first index as selected
            if (this.selected.category) {
              this.selectedFieldService.onSupportValueFieldSelected.next(
                documentCore.supportValues[0]
              );
            }
          } else {
            this.supportValues = undefined;
          }
        }
      });
    // Subscribe to the fieldEdited Flag
    this.fieldEditedSub = this.fieldEditedService.fieldEdited
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(edited => {
        this.fieldEditedFlag = edited;
      });
  }

  /**
   * Apply Filter
   * @description Apply filter to apply to the table
   * @param {string} filterValue
   * @memberof SupportValuesComponent
   */
  applyFilter(filterValue: string) {
    // trim and lowercase filter value
    this.dataSource.filter = filterValue.trim().toLowerCase();
    // If data
    if (this.dataSource.filteredData[0]) {
      // select first instance
      this.selectedFieldService.onSupportValueFieldSelected.next(
        this.dataSource.filteredData[0]
      );
    }
    // Set filter search
    this.filterSearch = filterValue;
  }

  /**
   * On Select
   * @description Selects the support value
   * @param {*} e
   * @param {*} selected
   * @memberof SupportValuesComponent
   */
  onSelect(e: any, selected: any) {
    this.selectedFieldService.onSupportValueFieldSelected.next(selected);
  }

  /**
   * Save
   * @description Save the change to the support value
   * @memberof SupportValuesComponent
   */
  save() {
    let oldSupportValue = JSON.parse(JSON.stringify(this.selected));
    let newSupportValue = JSON.parse(
      JSON.stringify(this.localSelected)
    );
    const oldObject = oldSupportValue;
    const newObject =  newSupportValue;
    console.log(oldObject);
    console.log(newObject);
    const editedObject = { oldObject, newObject };
    const msg = {
      type: 'Support Values',
      editedObject
    };
    this.ccmcApiService.appendLogChanges(msg, 'Target');
    // Set core edited to true
    this.fieldEditedService.coreEdited.next(true);
    // update the selected support value
    this.selected.category = this.localSelected.category;
    this.selected.source = this.localSelected.source;
    this.selected.value = this.localSelected.value;
    // Set support value edited flag to true
    this.selected.edited = true;
    // Set edited flag to false
    this.editedFlag = false;
    // Set FieldEdited to false
    this.fieldEditedService.fieldEdited.next(false);
  }

  /**
   * Field Edited
   * @description hnadler for chhecking if the fieldws are equivalent
   * @memberof SupportValuesComponent
   */
  fieldEdited() {
    // Set Edited Flag true
    this.editedFlag = true;
    // Set fieldEdited to true
    this.fieldEditedService.fieldEdited.next(true);
    // Checks to see if modified local selected variable is the same as the current fields
    if (this.isEquivalent(this.selected, this.localSelected)) {
      this.editedFlag = false;
      this.fieldEditedService.fieldEdited.next(false);
    }
  }

  /**
   * is Equivalent
   * @description checks if object a and b are equivalent
   * @param {*} a
   * @param {*} b
   * @returns
   * @memberof SupportValuesComponent
   */
  isEquivalent(a: any, b: any) {
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      // ignores the edited property since those values will not be the same
      if (aProps[i] !== 'edited') {
        const propName = aProps[i];

        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * Change Filter Predicate
   * @description Changes filter predicate for the search bar
   * @param {string} predicate
   * @memberof SupportValuesComponent
   */
  changeFilterPredicate(predicate: string) {
    // console.log(predicate);
    if (predicate === 'all') {
      this.dataSource.filterPredicate = function (
        data: any,
        filter: string
      ): boolean {
        // tslint:disable-next-line: max-line-length
        return (
          JSON.stringify(data.category).toLowerCase().includes(filter) ||
          JSON.stringify(data.source).toLowerCase().includes(filter) ||
          JSON.stringify(data.value).toLowerCase().includes(filter)
        );
      };
    } else if (predicate === 'category') {
      this.dataSource.filterPredicate = function (
        data: any,
        filter: string
      ): boolean {
        return JSON.stringify(data.category).toLowerCase().includes(filter);
      };
    } else if (predicate === 'source') {
      this.dataSource.filterPredicate = function (
        data: any,
        filter: string
      ): boolean {
        return JSON.stringify(data.source).toLowerCase().includes(filter);
      };
    }
  }

  /**
   * Add Support Value
   * @description Open the add support value diagram
   * @memberof SupportValuesComponent
   */
  addSupportValue() {
    const addSupportValueDialogRef = this.dialog.open(
      CCMCAddSupportValuesDialogComponent,
      {}
    );
    addSupportValueDialogRef.afterClosed().subscribe(() => {
      // Set selected field
      this.selectedFieldService.onSupportValueFieldSelected.next(this.selected);
      // if filter search is available, apply filter
      if (this.filterSearch) {
        if (this.filterSearch.length > 0) {
          this.applyFilter(this.filterSearch);
        }
      }
    });
  }

  /**
   * Remove Support Value
   * @description Removes the support value
   * @memberof SupportValuesComponent
   */
  removeSupportValue() {
    // Confirm with user to remove the support vlaue
    const confirmDialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
      data: 'Are you sure you want to remove this support value?'
    });
    confirmDialogRef.afterClosed().subscribe(data => {
      if (data) {
        console.log('remove', this.selected);
        // Set core edited to true
        this.fieldEditedService.coreEdited.next(true);
        // Remove the selected support value
        for (let i = 0; i < this.supportValues.length; i++) {
          if (
            this.supportValues[i].category === this.selected.category &&
            this.supportValues[i].source === this.selected.source
          ) {
            this.supportValues.splice(i, 1);
          }
        }
        // Set support values
        this.tempDocument.supportValues = this.supportValues;
        // Set document
        this.adminApiService.setDocumentCoreSimple(this.tempDocument);
        // Apply filter search if applicable
        if (this.filterSearch && this.filterSearch.length > 0) {
          this.applyFilter(this.filterSearch);
        }
      }
    });
  }

  /**
   * Can Deactivate
   * @description Handles whether the user can leave the support values route
   * @returns
   * @memberof SupportValuesComponent
   */
  canDeactivate() {
    // If true, display message
    if (this.fieldEditedFlag) {
      this.snackBarService.openSnackBar('Please resolve changes', 'Okay');
    }
    return !this.fieldEditedFlag;
  }

  /**
   * Init Filter Search
   * @description inits the filter search and watches for keyups on search
   * @memberof SupportValuesComponent
   */
  initFilterSearch() {
    // Auto Focus filter search item
    this.filterSearchEl.nativeElement.focus();
    fromEvent(this.filterSearchEl.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // Time in milliseconds between key events
        debounceTime(1000),
        // If previous query is diffent from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((text: string) => {
        this.applyFilter(text);
      });
  }
}
