<!--Background Image-->
<img src="assets/images/backgrounds/light-grey-tech.png" no-repeat center center fixed class="BG-img">
<div>
  <!--Login Card-->
  <div id="login" fxLayout="column">
    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
      <div id="login-form">
        <div class="GroundControlAdminlogo">

          <!--Ground Control Logo-->
          <img src="assets/images/logos/GC-rocket-omni1.png">
        </div>

        <!--title-->
        <div class="title">Login to your Account</div>
        <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate>
          <div class="inputField">

            <!--Email Input-->
            <mat-form-field class="emailInput">
              <input matInput placeholder="Email" formControlName="email" autofocus data-lpignore="true">
              <mat-error *ngIf="loginFormErrors.email.required">
                Email is required
              </mat-error>
              <mat-error *ngIf="!loginFormErrors.email.required && loginFormErrors.email.email">
                Please enter a valid email address
              </mat-error>
              <mat-icon matPrefix class="inputIcon">
                person
              </mat-icon>
            </mat-form-field>

            <!--Password Input-->
            <mat-form-field class="passwordInput">
              <input matInput type="password" placeholder="Password" formControlName="password" data-lpignore="true">
              <mat-error *ngIf="loginFormErrors.password.required">
                Password is required
              </mat-error>
              <mat-icon matPrefix class="inputIcon">lock
              </mat-icon>
            </mat-form-field>
          </div>

          <!--Login Button-->
          <div fxLayout="column" fxLayoutAlign="center center">
            <button mat-raised-button color="accent" class="form-button" type="submit" aria-label="LOG IN"
              [disabled]="loginForm.invalid">
              LOGIN
            </button>
          </div>
        </form>

        <!--Forgot Password Link-->
        <div fxLayout="column" fxLayoutAlign="center center">
          <a class="forgot-password" [routerLink]="['/forgot-password']">
            Forgot Password?
          </a>
        </div>
      </div>
    </div>

    <!--CCMC Logo-->
    <img src="assets/images/logos/omni_connect.png" class="ccmcLogo">
  </div>
  <div class="spinner-container" *ngIf="showSpinner">
    <div class="new-spinner">
      <div class="outer-ring"></div>
      <div class="middle-ring"></div>
      <div class="inner-ring"></div>
    </div>
  </div>
</div>