import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddNewDestinationDialogComponent } from './add-new-destination-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';

@NgModule({
  declarations: [AddNewDestinationDialogComponent],
  imports: [AppMaterialModule, CommonModule, FormsModule, ReactiveFormsModule],
  entryComponents: [AddNewDestinationDialogComponent]
})
export class AddNewDestinationDialogModule {}
