import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IbsCredentialComponent } from './ibs-credential.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';

@NgModule({
  declarations: [IbsCredentialComponent],
  imports: [
    AppMaterialModule,
    ReactiveFormsModule,
    CommonModule,
    MatCheckboxModule,
    FormsModule
  ]
})
export class IbsCredentialModule {}
