import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ElementRef,
  NgZone,
  AfterViewInit
} from '@angular/core';
import { CcmcApiService } from '../../../../@ccmc/services/ccmc-api.service';
import { Subscription, fromEvent, Subject } from 'rxjs';
import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter,
  takeUntil
} from 'rxjs/operators';
import { CCMCSelectedFieldService } from '../../../../@ccmc/services/selected-field.service';
import { SpinnerService } from '../../../../@ccmc/services/spinner.service';

import { CCMCConfirmDialogComponent } from '../../../../@ccmc/components/confirm-dialog/confirm-dialog.component';
import { FieldEditedService } from '../../../../@ccmc/services/field-edited.service';

import { ShortcutInput, ShortcutEventOutput } from 'ng-keyboard-shortcuts';

import { SnackbarService } from '../../../../@ccmc/services/snackbar.service';
import { GeneralLedgerAccountingService } from '../../../../@ccmc/services/general-ledger-accounting.service';
import { AddTargetLayoutColumnDialogComponent } from '../../../../@ccmc/components/add-target-layout-column-dialog/add-target-layout-column-dialog.component';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FindAndReplaceDialogComponent } from 'src/@ccmc/components/find-and-replace-dialog/find-and-replace-dialog.component';

@Component({
  selector: 'app-target-layout',
  templateUrl: './target-layout.component.html',
  styleUrls: ['./target-layout.component.scss']
})
export class TargetLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  /**
   * Shortcuts for hotkeys
   *
   * @type {ShortcutInput[]}
   * @memberof TargetLayoutComponent
   */
  shortcuts: ShortcutInput[] = [];

  /**
   * Document core subscription
   *
   * @private
   * @type {Subscription}
   * @memberof TargetLayoutComponent
   */
  private documentCoreSub: Subscription;

  /**
   * Selected transaction
   *
   * @private
   * @type {Subscription}
   * @memberof TargetLayoutComponent
   */
  private selectedSub: Subscription;

  /**
   * Field edited subscription
   *
   * @private
   * @type {Subscription}
   * @memberof TargetLayoutComponent
   */
  private fieldEditedSub: Subscription;

  /**
   * Field edited flag
   *
   * @memberof TargetLayoutComponent
   */
  fieldEditedFlag = false;

  /**
   * targetLayout object
   *
   * @memberof TargetLayoutComponent
   */
  targetLayout: any;

  /**
   * Temporary document
   *
   * @memberof TargetLayoutComponent
   */
  tempDocument: any;

  /**
   * Selected transaction
   *
   * @memberof TargetLayoutComponent
   */
  selected: any;

  /**
   * Displayed columns for the transaction table
   *
   * @memberof TargetLayoutComponent
   */
  displayedColumns = ['edited'];

  /**
   * Datasource for Angular Material Table
   *
   * @type {*}
   * @memberof TargetLayoutComponent
   */
  dataSource: any;

  /**
   * Selected fiter for the table
   *
   * @type {*}
   * @memberof TargetLayoutComponent
   */
  selectedFilter: any;

  /**
   * Material paginator reference
   *
   * @type {MatPaginator}
   * @memberof TargetLayoutComponent
   */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /**
   * Material sort reference
   *
   * @type {MatSort}
   * @memberof TargetLayoutComponent
   */
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  glaEditSub: Subscription;
  glaEditFlag = false;

  /**
   * Filter search reference
   *
   * @type {ElementRef}
   * @memberof TargetLayoutComponent
   */
  @ViewChild('filterSearch', { static: true }) filterSearchEl: ElementRef;

  /**
   * Filter search
   *
   * @type {string}
   * @memberof TargetLayoutComponent
   */
  filterSearch: string;

  /**
   * Unsubscribe from observables
   *
   * @type {Subject<any>}
   * @memberof TargetLayoutComponent
   */
  unsubscribe: Subject<any> = new Subject();

  /**
   * Dialog reference for find and replace dialog
   *
   * @memberof TargetLayoutComponent
   */
  dialogRef: any;

  /**
   * Boolean for showing the loading spinner
   *
   * @type {boolean}
   * @memberof TargetLayoutComponent
   */
  showSpinner: boolean;

  /**
   * Loading spinner subscription
   *
   * @private
   * @type {Subscription}
   * @memberof TargetLayoutComponent
   */
  private spinnerSub: Subscription;

  /**
   * Column Widths
   *
   * @memberof TargetLayoutComponent
   */
  columnWidths: any;

  /**
   * Total Table Width
   *
   * @memberof TargetLayoutComponent
   */
  totalTableWidth: any;

  /**
   * Creates an instance of TargetLayoutComponent.
   * @param {GeneralLedgerAccountingService} ccmcGlaService
   * @param {CCMCSelectedFieldService} selectedFieldService
   * @param {MatDialog} dialog
   * @param {FieldEditedService} fieldEditedService
   * @param {MatSnackBar} snackBar
   * @param {SpinnerService} spinnerService
   * @memberof TargetLayoutComponent
   */
  constructor(
    private ccmcGlaService: GeneralLedgerAccountingService,
    private selectedFieldService: CCMCSelectedFieldService,
    private dialog: MatDialog,
    private fieldEditedService: FieldEditedService,
    private snackBarService: SnackbarService,
    private spinnerService: SpinnerService,
    private router: Router,
    private el: ElementRef
  ) {}

  /**
   * Initialize the component
   *
   * @memberof TargetLayoutComponent
   */
  ngOnInit() {
    // Set fields to false on load
    this.fieldEditedService.fieldEdited.next(false);
    // Initialize filter as all
    this.selectedFilter = 'all';
    this.getData();

    fromEvent(this.filterSearchEl.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // Time in milliseconds between key events
        debounceTime(1000),
        // If previous query is diffent from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((text: string) => {
        this.applyFilter(text);
      });
  }

  /**
   * Unsubscribe from observables on destroy
   *
   * @memberof TargetLayoutComponent
   */
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  /**
   * Load shortcuts after init
   *
   * @memberof TargetLayoutComponent
   */
  ngAfterViewInit() {
    this.shortcuts.push({
      key: ['cmd + h'],
      label: 'Find and Replace',
      description: 'Find and replace',
      command: e => {
        this.findAndReplace();
      },
      preventDefault: true
    });
  }

  /**
   * Get data needed for the component
   *
   * @memberof TargetLayoutComponent
   */
  getData() {
    this.selectedSub = this.selectedFieldService.onTargetLayoutFieldSelected
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(selected => {
        if (selected) {
          this.selected = selected;
        } else {
          this.selected = undefined;
        }
      });

    this.documentCoreSub = this.ccmcGlaService.glaDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(glaDocument => {
        if (glaDocument) {
          this.tempDocument = glaDocument;
          console.log('GLA Document', glaDocument);
          if (glaDocument.coreLayout) {
            this.targetLayout = glaDocument.coreLayout;
            if (this.targetLayout.length === 0) {
              return;
            }
            this.calculateColumnWidth();
            this.displayedColumns = Object.keys(this.targetLayout[0]);
            this.dataSource = new MatTableDataSource(this.targetLayout);
            if (-1 == this.displayedColumns.indexOf('edited')) {
              this.displayedColumns.push('edited');
            }
            console.log(this.displayedColumns);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.sort.active = 'columnID';
            this.sort.direction = 'asc';
            // initializes first index as selected
            if (this.selected === null || this.selected === undefined) {
              this.onSelect(glaDocument.coreLayout[0]);
            }
          } else {
            this.targetLayout = undefined;
          }
        }
      });

    this.fieldEditedSub = this.fieldEditedService.fieldEdited
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(edited => {
        this.fieldEditedFlag = edited;
        this.dataSource = new MatTableDataSource(this.targetLayout);
        this.dataSource.sort = this.sort;
      });

    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        if (this.dialog.openDialogs.length === 0) {
          this.showSpinner = spinner;
        }
      });
  }

  /**
   * Applys filter to the transactions array
   *
   * @param {string} filterValue
   * @memberof TargetLayoutComponent
   */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    // Select first instance
    if (this.dataSource.filteredData[0]) {
      this.onSelect(this.dataSource.filteredData[0]);
    }
    this.filterSearch = filterValue;
  }

  /**
   * When user selects a transaction
   *
   * @param {*} selected
   * @memberof TargetLayoutComponent
   */
  onSelect(selected: any) {
    this.selected = selected;
    this.selectedFieldService.onTargetLayoutFieldSelected.next(selected);
  }
  /**
   * Adds a transaction to the current array
   *
   * @memberof TargetLayoutComponent
   */
  createTargetLayout() {
    if (this.targetLayout.length > 0) {
      const addColumnDialogRef = this.dialog.open(
        AddTargetLayoutColumnDialogComponent,
        {}
      );
      addColumnDialogRef.afterClosed().subscribe(newTargetLayoutColumn => {
        if (newTargetLayoutColumn) {
          this.targetLayout.push(newTargetLayoutColumn);
          this.fieldEditedService.onGLADocumentEdited.next(true);
          this.dataSource.data = this.targetLayout;
          console.log(
            'Target Layout',
            JSON.parse(JSON.stringify(this.targetLayout))
          );
          this.selectedFieldService.onTransactionFieldSelected.next(
            this.selected
          );
          this.ccmcGlaService.updateGLADocument(this.tempDocument);
          if (this.filterSearch) {
            if (this.filterSearch.length > 0) {
              this.applyFilter(this.filterSearch);
            }
          }
        }
      });
    }
  }

  /**
   * Removes the selected transaction
   *
   * @memberof TargetLayoutComponent
   */
  removeColumn() {
    const confirmDialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
      data: 'Are you sure you want to remove this transaction?'
    });
    confirmDialogRef.afterClosed().subscribe(data => {
      if (data) {
        console.log('remove', this.selected);
        this.targetLayout = this.targetLayout.filter(
          (targetLayout: any) => targetLayout !== this.selected
        );
        this.fieldEditedService.onGLADocumentEdited.next(true);
        this.tempDocument.coreLayout = this.targetLayout;
        console.log(JSON.parse(JSON.stringify(this.tempDocument)));
        this.ccmcGlaService.updateGLADocument(this.tempDocument);
        if (this.filterSearch && this.filterSearch.length > 0) {
          this.applyFilter(this.filterSearch);
        }
      }
    });
  }

  /**
   * Controls whether the user can navigate away from the tab
   *
   * @returns
   * @memberof TargetLayoutComponent
   */
  canDeactivate() {
    if (this.fieldEditedFlag) {
      this.snackBarService.openSnackBar('Please resolve changes', 'Okay');
    }
    return !this.fieldEditedFlag;
  }

  /**
   * Opens the find and replace dialog
   *
   * @returns
   * @memberof TargetLayoutComponent
   */
  findAndReplace() {
    if (this.dialogRef) {
      return;
    } else {
      this.dialogRef = this.dialog.open(FindAndReplaceDialogComponent, {
        data: 'columns'
      });
      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = undefined;
      });
    }
  }

  calculateColumnWidth() {
    this.columnWidths = [];
    let columnPixels = [];
    let totalLength = 0;
    this.totalTableWidth = 0;
    const targetLayoutFields = Object.keys(this.targetLayout[0]);
    let targetLayoutColumnLengthArray = [];
    for (let targetLayoutField of targetLayoutFields) {
      const tempObj = {
        fieldName: targetLayoutField,
        length: 0
      };
      targetLayoutColumnLengthArray.push(tempObj);
    }
    for (let targetLayoutItem of this.targetLayout) {
      for (let i = 0; i < targetLayoutFields.length; i++) {
        if (
          JSON.stringify(targetLayoutItem[targetLayoutFields[i]]).length >
          targetLayoutColumnLengthArray[i].length
        ) {
          targetLayoutColumnLengthArray[i].length = JSON.stringify(
            targetLayoutItem[targetLayoutFields[i]]
          ).length;
        }
      }
    }
    for (let column of targetLayoutColumnLengthArray) {
      if (column.length < 5) {
        columnPixels.push(
          (1 / targetLayoutColumnLengthArray.length) * window.innerWidth + 'px'
        );
        this.totalTableWidth +=
          (1 / targetLayoutColumnLengthArray.length) * window.innerWidth;
      }
      if (column.length > 4 && column.length < 11) {
        columnPixels.push(
          (1.2 / targetLayoutColumnLengthArray.length) * window.innerWidth +
            'px'
        );
        this.totalTableWidth +=
          (1.2 / targetLayoutColumnLengthArray.length) * window.innerWidth;
      }
      if (column.length > 10 && column.length < 15) {
        columnPixels.push(
          (1.5 / targetLayoutColumnLengthArray.length) * window.innerWidth +
            'px'
        );
        this.totalTableWidth +=
          (1.5 / targetLayoutColumnLengthArray.length) * window.innerWidth;
      }
      if (column.length > 14 && column.length < 20) {
        columnPixels.push(
          (2 / targetLayoutColumnLengthArray.length) * window.innerWidth + 'px'
        );
        this.totalTableWidth +=
          (2 / targetLayoutColumnLengthArray.length) * window.innerWidth;
      }
      if (column.length > 19) {
        columnPixels.push(
          (2.5 / targetLayoutColumnLengthArray.length) * window.innerWidth +
            'px'
        );
        this.totalTableWidth +=
          (2.5 / targetLayoutColumnLengthArray.length) * window.innerWidth;
      }
    }
    this.columnWidths = columnPixels;
  }

  getTargetLayoutWidth() {
    return this.el.nativeElement.offsetWidth + 'px';
  }
}
