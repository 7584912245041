import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CCMCSharedModule } from 'src/@ccmc/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CCMCContentModule } from '../../content.module';
import { DocConnectorSourceComponent } from './doc-connector-source.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';

const routes: Routes = [
  {
    path: 'doc-connector/source-and-destination/source',
    component: DocConnectorSourceComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [DocConnectorSourceComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    CCMCSharedModule,
    MatButtonModule,
    MatIconModule,
  ]
})
export class DocConnectorSourceModule { }
