import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActiveCoreService } from 'src/@ccmc/services/active-core.service';
import { GeneralLedgerAccountingService } from 'src/@ccmc/services/general-ledger-accounting.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
import { glaNavigation } from 'src/app/navigation/navigation';

@Component({
  selector: 'app-funding-ledger',
  templateUrl: './funding-ledger.component.html',
  styleUrls: ['./funding-ledger.component.scss']
})
export class FundingLedgerComponent implements OnInit {
  debitsDataSource: any;
  creditsDataSource: any;
  debitCodes: any;
  creditCodes: any;
  displayedColumns = ['description', 'amount'];

  dynamicGLANav: any = glaNavigation;
  shortcuts: ShortcutInput[] = [];
  unsubscribe: Subject<any> = new Subject();
  creditsTotal = 0;
  debitsTotal = 0;
  private glaDocumentSub: Subscription;
  private exportDocumentSub: Subscription;
  glaDocument: any;
  showSpinner: boolean; // flag for the mat-spinner
  private spinnerSub: Subscription;
  amountByPolarity = false;
  creditDebitColumnName: any;
  constructor(
    private glaService: GeneralLedgerAccountingService,
    private spinnerService: SpinnerService,
    private snackBarService: SnackbarService,
    private activeCoreService: ActiveCoreService
  ) {}

  ngOnInit(): void {
    this.spinnerService.setShowSpinner(true);
    this.getData();
    this.subscribeToSpinner();
  }

  ngAfterViewInit() {
    // Init shortcuts
    this.shortcuts.push({
      key: ['cmd + shift + d'],
      label: 'Demo Mode',
      description: 'Demo Mode Toggle',
      command: e => {
        this.setDemoMode();
      },
      preventDefault: true
    });
  }

  setDemoMode() {
    this.glaDocument.configurations.demoMode =
      !this.glaDocument.configurations.demoMode;
    this.snackBarService.openSnackBar(
      'Demo Mode: ' + this.glaDocument.configurations.demoMode,
      'Okay'
    );
  }

  getData() {
    // Subscribe to the document
    this.glaDocumentSub = this.glaService.selectedSetDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(glaDocument => {
        console.log(glaDocument);
        if (glaDocument) {
          if (glaDocument) {
            this.glaDocument = glaDocument;
            console.log(glaDocument);
            this.getTableData(glaDocument);
            this.glaService.setExportDocument(this.glaDocument.transactionTable);
            // If Prologue use amount polarity +/- to determine credit and debit
            // this.setCreditsAndDebitsArraysByPolarity();
            this.getCreditAndDebitCode();
            this.creditDebitColumnName = this.getCreditDebitColumnName();
            if (this.amountByPolarity) {
              this.setCreditsAndDebitsArraysByPolarity();
            } else {
              this.setCreditsAndDebitsArrays();
            }
            this.getTotals();
            this.spinnerService.setShowSpinner(false);
          }
        }
      });
  }

  subscribeToSpinner() {
    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        this.showSpinner = spinner;
      });
  }

  getTableData(glaDocument: any) {
    console.log(glaDocument.set.transactions);
    const transactionsTable = [];
    for (let transaction of glaDocument.set.transactions) {
      const columns = transaction.columns;
      let tempTransactionItem: any = {};
      for (let column of columns) {
        const columnIndex = glaDocument.coreLayout.findIndex(
          (col: any) => col.columnID === column.columnID
        );
        if (columnIndex > -1) {
          tempTransactionItem[glaDocument.coreLayout[columnIndex].columnName] =
            column.fieldValue;
        }
      }
      transactionsTable.push(tempTransactionItem);
    }
    console.log('Core Layout Data', glaDocument.coreLayout);
    console.log('Transaction Table Data', transactionsTable);
    glaDocument.transactionTable = transactionsTable;
  }

  getCreditAndDebitCode() {
    console.log(this.glaDocument.configurations.debitCode);
    this.debitCodes = this.glaDocument.configurations.debitCode.split(',');
    this.creditCodes = this.glaDocument.configurations.creditCode.split(',');
    for (let debitCode of this.debitCodes) {
      debitCode = debitCode.trim();
    }
    for (let creditCode of this.creditCodes) {
      creditCode = creditCode.trim();
    }
    if (
      this.debitCodes.includes('+') ||
      this.creditCodes.includes('+') ||
      this.debitCodes.includes('-') ||
      this.creditCodes.includes('-')
    ) {
      this.amountByPolarity = true;
    } else {
      this.amountByPolarity = false;
    }
    console.log(this.debitCodes);
    console.log(this.creditCodes);
  }

  getCreditDebitColumnName() {
    let credDebColumnName =
      this.glaDocument.configurations.creditDebitColumnName;

    if (credDebColumnName) {
      return credDebColumnName;
    } else {
      return 'Credit/Debit Code';
    }
  }

  setCreditsAndDebitsArrays() {
    // const set = this.glaDocument.set;
    // const creditDebitcolumnID = this.retrieveCreditDebitColumnID();
    const debitsArray = [];
    const creditsArray = [];
    for (let transaction of this.glaDocument.transactionTable) {
      if (this.creditCodes.includes(transaction[this.creditDebitColumnName])) {
        creditsArray.push(transaction);
      } else if (
        this.debitCodes.includes(transaction[this.creditDebitColumnName])
      ) {
        debitsArray.push(transaction);
      }
    }
    this.setCreditsTable(creditsArray);
    this.setDebitsTable(debitsArray);
    this.checkIsGLABalanced();
  }

  setCreditsAndDebitsArraysByPolarity() {
    // const set = this.glaDocument.set;
    const debitsArray = [];
    const creditsArray = [];
    for (let transaction of this.glaDocument.transactionTable) {
      console.log('Amount', transaction['Amount']);
      let tempAmount;
      if (transaction['Amount']) {
        tempAmount = transaction['Amount'].replace('$', '');
      } else {
        tempAmount = transaction['Amount'] = '';
      }
      if (+tempAmount < 0) {
        creditsArray.push(transaction);
      } else {
        debitsArray.push(transaction);
      }
    }
    this.setCreditsTable(creditsArray);
    this.setDebitsTable(debitsArray);
    this.checkIsGLABalanced();
  }

  setCreditsTable(credits: any) {
    console.log(credits);
    const creditsTable = [];
    for (let credit of credits) {
      let tempCredit = {
        description: credit['Transaction Name'],
        amount: credit['Amount']
      };
      console.log('tempcredit', tempCredit);
      if (tempCredit.amount.length > 0) {
        creditsTable.push(tempCredit);
      }
    }
    this.creditsTotal = this.getTotal(creditsTable);
    this.creditsDataSource = new MatTableDataSource(creditsTable);
  }

  getTotal(table: any) {
    let total = 0;
    for (let item of table) {
      total += +item.amount;
    }
    return total;
  }

  setDebitsTable(debits: any) {
    console.log(debits);
    const debitsTable = [];
    for (let debit of debits) {
      let tempDebit = {
        description: debit['Transaction Name'],
        amount: debit['Amount']
      };
      if (tempDebit.amount.length > 0) {
        debitsTable.push(tempDebit);
      }
    }
    this.debitsTotal = this.getTotal(debitsTable);
    this.debitsDataSource = new MatTableDataSource(debitsTable);
  }

  retrieveCreditDebitColumnID() {
    const creditDebitIndex = this.glaDocument.coreLayout.findIndex(
      (obj: any) => obj.columnName === this.creditDebitColumnName
    );
    if (creditDebitIndex > -1) {
      return this.glaDocument.coreLayout[creditDebitIndex].columnID;
    } else {
      console.log('cant find credit/debit column');
    }
  }

  retrieveAmountColumnID() {
    const amountIndex = this.glaDocument.coreLayout.findIndex(
      (obj: any) => obj.columnName === 'Amount'
    );
    if (amountIndex > -1) {
      return this.glaDocument.coreLayout[amountIndex].columnID;
    } else {
      console.log('cant find amount column');
    }
  }

  retrieveTransactionNameColumnID() {
    const amountIndex = this.glaDocument.coreLayout.findIndex(
      (obj: any) => obj.columnName === 'Transaction Name'
    );
    if (amountIndex > -1) {
      return this.glaDocument.coreLayout[amountIndex].columnID;
    } else {
      console.log('cant find transaction name column');
    }
  }

  getTotals() {}

  checkIsGLABalanced() {
    console.log('Credits Total', this.creditsTotal);
    console.log('Debits Total', this.debitsTotal);
    if (Math.abs(this.creditsTotal) === Math.abs(this.debitsTotal)) {
      console.log('Debit and Credit are equal');
      this.glaService.setIsGLABalanced(true);
    } else {
      console.log('Debit and Credit not equal');
      this.glaService.setIsGLABalanced(false);
    }
  }
}
