import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { CifData, SearchData } from '../models/cifSearchData.model';

@Injectable({
  providedIn: 'root'
})
export class CifSearchService {
  /**
   *Creates an instance of CifSearchService.
   * @memberof CifSearchService
   */
  constructor() {}

  private selectedRow = new Subject<SearchData>();
  private cif = new Subject<CifData>();
  private isCifSet: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  selectedRow$ = this.selectedRow.asObservable();
  cifResults$ = this.cif.asObservable();
  cifSet$ = this.isCifSet.asObservable();

  /**
   * Send Cif
   * @description sets the cif
   * @param {*} cif
   * @memberof CifSearchService
   */
  sendCif(cif: any) {
    this.cif.next(cif);
  }

  /**
   * Semd Selected Row
   * @description sets the selectedRow
   * @param {*} row
   * @memberof CifSearchService
   */
  sendSelectedRow(row: any) {
    this.selectedRow.next(row);
  }

  /**
   * Setter for Cif set
   * @description sets if the cif is set
   * @memberof CifSearchService
   */
  set cifSet(isSet: boolean) {
    this.isCifSet.next(isSet);
  }
}
