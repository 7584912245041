import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Subscription, from, defer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NewStatusDialogComponent } from 'src/@ccmc/components/new-status-dialog/new-status-dialog.component';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { ActiveLosService } from 'src/@ccmc/services/active-los.service';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { RequestChangeService } from 'src/@ccmc/services/request-change.service';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
import { RequestChangeComponent } from '../request-change.component';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { v4 as uuidv4 }  from 'uuid';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from "moment";
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';

interface urgency {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-selected-change',
  templateUrl: './selected-change.component.html',
  styleUrls: ['./selected-change.component.scss']
})
export class SelectedChangeComponent implements OnInit {
  urgency: urgency[] = [
    { value: 'low', viewValue: 'Low Priority' },
    { value: 'medium', viewValue: 'Medium Priority' },
    { value: 'high', viewValue: 'High Priority' }
  ];
  @Input() tab: any;
  selectedStatus: string;
  selectedUrgencies: string;
  unsubscribe: Subject<any> = new Subject();
  unsubscribes: Subject<any> = new Subject();
  statusesSub: Subscription;
  refreshSub: Subscription;
  rowsSub: Subscription;
  private spinnerSub: Subscription;
  showSpinner: boolean; // flag for the mat-spinner
  notesTable: any = [];
  isRowSelected: boolean;

  transactionSelectedSub: Subscription;
  isNXTsoft = this.amplifyService.isNXTsoft;
  isNXTsoftDev = this.amplifyService.isNXTsoftDev;
  isNXTsoftOp = this.amplifyService.isNXTsoftOperations;
  selectedRow = {
    fieldID: '',
    fieldDisplay: '',
    changeRequestID: uuidv4(),
    currentValue: '',
    correctedValue: '',
    loanNumber: '',
    status: '',
    urgency: '',
    description: '',
    nxtsoftNotes: '',
    notesTable: [{
      username: '',
      date: '',
      notes: ''
    }]
  };
  emptyRow = {
    fieldID: '',
    fieldDisplay: '',
    changeRequestID: uuidv4(),
    currentValue: '',
    correctedValue: '',
    loanNumber: '',
    status: '',
    urgency: '',
    description: '',
    nxtsoftNotes: '',
    notesTable: [{
      username: '',
      date: '',
      notes: ''
    }]
  };
  statuses = [];
  currentLoanNum: any;
  pressed: boolean;
  currentTable: any;
  editedFlag = false;
  originalSelectedRow: any;
  index = 0;
  indexToUse: number;
  updateTable: any;
  isOrigenate: any;
  changeRequestFieldsSub: Subscription;
  currentTabSub: Subscription;
  docFields: any;
  document: any;
  newDocumentFields: any;
  currentIndex: any;
  displayedColumns2 = ['notes'];
  currentNote: any;
  notesObj: any;
  username: any;
  constructor(
    private selectedFieldService: CCMCSelectedFieldService,
    private ccmcApiService: CcmcApiService,
    private fieldEditedService: FieldEditedService,
    public dialog: MatDialog,
    private snackBarService: SnackbarService,
    private activeLosService: ActiveLosService,
    private spinnerService: SpinnerService,
    private requestChangeService: RequestChangeService,
    private amplifyService: AmplifyService,
    private assetService: AssetService,
    private adminApiService: AdminApiService
  ) {}

  async ngOnInit() {
    this.currentTabSub = this.requestChangeService.currentSelectedTab
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(index => {
        // console.log(check);
        this.currentIndex = index;
        console.log(index);
      });
  
    this.isOrigenate = this.activeLosService.activeLos;
    console.log(this.isOrigenate);
    // console.log(this.selectedRow);
    this.transactionSelectedSub = defer(() =>
      from(this.requestChangeService.onChangeRequestFields)
    )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((selected: any) => {
        console.log('Current Selection ',selected)
        this.selectedRow = { ...selected };
        this.originalSelectedRow = { ...selected };
        this.notesTable = new MatTableDataSource(this.selectedRow.notesTable);
        this.autoSaveNotes(this.selectedRow.notesTable);
        this.isRowSelected = this.selectedRow.changeRequestID != '';
      });
    this.statusesSub = defer(() =>
      from(this.requestChangeService.changeRequestDocStatuses)
    )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((selected: any) => {
        this.statuses = selected.statuses;
      });
    // this.requestChangeService.onChangeRequestFields;
    this.currentLoanNum = this.ccmcApiService.loanNumber;
    this.spinnerSub = this.spinnerService.spinner.subscribe((spinner: any) => {
      this.showSpinner = spinner;
    });
    this.username = await this.amplifyService.getUserName();
  }

  async save() {
    this.spinnerService.setShowSpinner(true);
    console.log('selected',this.selectedRow);
    console.log('original selected row',this.originalSelectedRow);
    this.editedFlag = false;
    let remoteChangeReqCopy = JSON.parse(JSON.stringify(await this.requestChangeService.getChangeRequestItem(this.assetService.getSelectedAssetId(), this.selectedRow.changeRequestID)));
    console.log(JSON.parse(JSON.stringify(remoteChangeReqCopy)));
    delete remoteChangeReqCopy.assetItem.changeRequestID;
    remoteChangeReqCopy.assetItem = this.selectedRow;
    await this.requestChangeService.postDynamoFieldValues(remoteChangeReqCopy);
    this.adminApiService
      .getDynamoDBItemsByIndex('change_request_log', this.assetService.getSelectedAssetId(), 'assetID')
      .subscribe(result => {
        console.log(result);
        const parsedResult = JSON.parse(JSON.stringify(result));
        if (parsedResult.statusFlag === true) {
          // console.log(result);
          this.requestChangeService.changeRequestDoc.next(parsedResult.content.Items);
        }
        this.spinnerService.setShowSpinner(false);
      });
}
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  createNewStatus() {
    // console.log(element);
    const premierSearchValueRef = this.dialog.open(NewStatusDialogComponent, {
      panelClass: 'new-status__panel-dialog'
    });
    premierSearchValueRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.transactionSelectedSub = defer(() =>
          from(this.requestChangeService.onChangeRequestNewField)
        )
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((selected: any) => {
            if (selected) {
              // console.log(selected);
              this.snackBarService.openSnackBar('Created New Status', 'Okay');
            }
          });
      }
    });
  }
  fieldEdited() {
    // console.log(this.selectedRow);
    // console.log(this.originalSelectedRow);
    // this.editedFlag = true;
    // this.fieldEditedService.fieldEdited.next(true);
    // Checks to see if modified local selected variable is the same as the current fields
    if (this.isEquivalent(this.selectedRow, this.originalSelectedRow)) {
      this.editedFlag = false;
      this.fieldEditedService.fieldEdited.next(false);
    } else {
      this.editedFlag = true;
      this.fieldEditedService.fieldEdited.next(true);
    }
  }
  isEquivalent(a: any, b: any) {
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      return false;
    }
    for (let i = 0; i < aProps.length; i++) {
      // ignores the edited property since those values will not be the same
      if (aProps[i] !== 'edited') {
        const propName = aProps[i];
        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
          return false;
        }
      }
    }
    return true;
  }

  async onNotesSubmit() {
    this.editedFlag = true;
    this.notesObj = {
      username: this.username,
      notes: this.currentNote,
      date: new Date().toISOString()
    };
    if (this.selectedRow.notesTable) {
      this.selectedRow.notesTable.push(this.notesObj);
      this.notesTable.data = [...this.selectedRow.notesTable];
      this.currentNote = '';
    }  else {
      this.selectedRow.notesTable = [this.notesObj];
      this.notesTable.data = [...this.selectedRow.notesTable];
      this.currentNote = '';
    }
  }

  deleteNote(date: any) {
    let counter = 0;
    for (let comment of this.selectedRow.notesTable) {
      if (comment.date === date) {
        this.selectedRow.notesTable.splice(counter, 1);
        break;
      }
      counter++;
    }
    this.notesTable.data = [...this.selectedRow.notesTable];
    this.editedFlag = true;
  }

  autoSaveNotes(notes: any) {
    if (notes) {
      let autoSaveFlag:boolean = false;
      for (let note of notes) {
        if (note.username === 'nxtsoftNotes' && note.nxtsoftNotes !== '' && moment(note.date).format('DD/MM/YYYY') === moment(new Date()).format('DD/MM/YYYY')) {
          note.nxtsoftNotes = '';
          autoSaveFlag = true;
        }
        if (note.username === 'description' && note.description !== '' && moment(note.date).format('DD/MM/YYYY') === moment(new Date()).format('DD/MM/YYYY')) {
          note.description = '';
          autoSaveFlag = true;
        }
      }
      if (autoSaveFlag === true) {
        console.log('auto saving');
        this.save();
      }
    }
  }
}
