<div class="support-values-container">
    <!--filter Search section -->
    <div class="filterContainer">
        <mat-form-field class="filterField">
            <input matInput placeholder="Filter Search" #filterSearch />
        </mat-form-field>
        <mat-form-field>
            <mat-label>Filter Options</mat-label>
            <mat-select [(value)]="selectedFilter">
                <mat-option value="all" (click)="changeFilterPredicate('all')">Filter All</mat-option>
                <mat-option value="category" (click)="changeFilterPredicate('category')">Category</mat-option>
                <mat-option value="source" (click)="changeFilterPredicate('source')">Source</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <!--Core Field Table -->
    <div class="table-container">
        <table class="table" mat-table #table [dataSource]="dataSource" style="width: 100%; overflow: scroll; height: max-content" CCMCPerfectScrollbar matSort>
            <!-- Category Column -->
            <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
                <td mat-cell *matCellDef="let element" style="padding-right: 90px" class="noselect">
                    {{ element.category }}
                </td>
            </ng-container>

            <!-- Source Column -->
            <ng-container matColumnDef="source">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 540px">
                    Source
                </th>
                <td mat-cell *matCellDef="let element" style="padding-right: 90px" class="noselect">
                    {{ element.source }}
                </td>
            </ng-container>

            <!-- Value Column -->
            <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Value</th>
                <td mat-cell *matCellDef="let element" style="padding-right: 90px" class="noselect">
                    {{ element.value }}
                </td>
            </ng-container>

            <!-- Field Edited Column -->
            <ng-container matColumnDef="edited">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Edited
                    <!-- <div class="AddBtn-Container">
            <button class="Add-btn" matTooltip="Add a New Support Value" matTooltipClass="toolTipStyle"
              (click)="addSupportValue()">
              <mat-icon style="color:#3FB2C6;">add_circle</mat-icon>
            </button>
          </div> -->
                </th>
                <td mat-cell *matCellDef="let element" style="padding-right: 130px" class="noselect">
                    <mat-icon *ngIf="element.edited === true" class="editIcon">
                        star</mat-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onSelect($event, row)" [ngClass]="{ 'accent-50-bg': row == selected }"></tr>
        </table>
    </div>

    <!--paginator-->
    <div class="paginatorContainer">
        <mat-paginator [pageSizeOptions]="[100]" showFirstLastButtons class="paginator"></mat-paginator>
    </div>

    <!--TabBar-->
    <div class="tabBar">
        <div class="RemoveBtn-Container">
            <button class="Btn-remove" matTooltip="Remove Selected Support Value" matTooltipClass="toolTipStyle" color="primary" (click)="removeSupportValue()">
        <mat-icon style="color: #7570d2">remove_circle</mat-icon>&nbsp;
      </button>
        </div>
        <mat-tab-group>
            <mat-tab label="Support Values">
                <div *ngIf="supportValues">
                    <!--Select Options for Support Values-Support Values-->
                    <div class="support-values-support-valuesContainer" *ngIf="selected">
                        <div class="support-values-support-valuesSelectOption">
                            <div class="selectOptions-title">Select Options</div>
                            <button mat-raised-button matTooltip="Saves any changes you make, within this section." matTooltipClass="toolTipStyle" color="accent" (click)="save()" [disabled]="!editedFlag" class="saveBtn-support-values-support-values">
                Save
              </button>
                        </div>
                        <!--Form Fields-->
                        <div class="selectOptions-line-one">
                            <mat-form-field class="form-field-one">
                                <input matInput placeholder="Category" [(ngModel)]="localSelected.category" (ngModelChange)="fieldEdited()" />
                            </mat-form-field>
                            <mat-form-field class="form-field-two">
                                <input matInput placeholder="Source" [(ngModel)]="localSelected.source" (ngModelChange)="fieldEdited()" />
                            </mat-form-field>
                            <mat-form-field class="form-field-three">
                                <input matInput placeholder="Value" [(ngModel)]="localSelected.value" (ngModelChange)="fieldEdited()" />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

<div class="AddBtn-Container">
    <button class="Add-btn" matTooltip="Add a New Support Value" matTooltipClass="toolTipStyle" (click)="addSupportValue()">
    <mat-icon style="color: #3fb2c6">add_circle</mat-icon>
  </button>
</div>

<!--Spinner-->
<div class="spinner-container" *ngIf="showSpinner">
    <div class="new-spinner">
        <div class="outer-ring"></div>
        <div class="middle-ring"></div>
        <div class="inner-ring"></div>
    </div>
</div>