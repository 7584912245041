import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GeneralLedgerAccountingService } from '../../services/general-ledger-accounting.service';
import { CCMCSelectedFieldService } from '../../services/selected-field.service';
import { SnackbarService } from '../../services/snackbar.service';

@Component({
  selector: 'app-create-set-transaction-dialog',
  templateUrl: './create-set-transaction-dialog.component.html',
  styleUrls: ['./create-set-transaction-dialog.component.scss']
})
export class CreateSetTransactionDialogComponent implements OnInit {
  newTransaction: any;
  selectedSetSub: Subscription;
  unsubscribe: Subject<any> = new Subject();
  glaDocumentSubscription: Subscription;
  targetLayout: any;
  constructor(
    private glaService: GeneralLedgerAccountingService,
    private dialogRef: MatDialogRef<CreateSetTransactionDialogComponent>,
    private snackbarService: SnackbarService,
    private selectedFieldService: CCMCSelectedFieldService,
    @Inject(MAT_DIALOG_DATA) public set: any
  ) {}

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  getData() {
    this.glaDocumentSubscription = this.glaService.glaDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(glaDocument => {
        if (glaDocument) {
          this.targetLayout = glaDocument.coreLayout;
          this.initNewTransaction();
        }
      });
  }

  initNewTransaction() {
    let newDisplayOrder = 1;
    if (this.set.transactions) {
      for (let transaction of this.set.transactions) {
        if (transaction.displayOrder >= newDisplayOrder) {
          newDisplayOrder = newDisplayOrder + 1;
        }
      }
    } else {
      newDisplayOrder = 1;
    }

    this.newTransaction = {
      Name: '',
      columns: [],
      displayOrder: newDisplayOrder,
      edited: true
    };
    for (let targetLayoutItem of this.targetLayout) {
      const tempColumn = {
        columnID: targetLayoutItem.columnID,
        edited: true,
        vbScript: ''
      };
      this.newTransaction.columns.push(tempColumn);
    }
    console.log(this.newTransaction);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  create() {
    if (this.newTransaction.Name.length > 0) {
      this.dialogRef.close(this.newTransaction);
    } else {
      this.snackbarService.openSnackBar(
        'Transaction Name must not be blank',
        'Okay'
      );
    }
  }
}
