<div style="width: 40%; min-width: 400px; background-color: e1e1e1">
    <h1>Edit FieldIDs</h1>
    <div *ngIf="fieldIDsInput">
      <mat-form-field style="width: 100%">
        <input matInput placeholder="FieldIDs" [(ngModel)]="fieldIDsInput" />
      </mat-form-field>
    </div>
    <div style="display: flex; justify-content: space-between">
      <button
        mat-raised-button
        matTooltip="Close Dialog"
        matTooltipClass="toolTipStyle"
        (click)="closeDialog()"
        style="text-align: left; max-width: 75px"
      >
        Cancel
      </button>
      <button
        mat-raised-button
        matTooltip="Update current FieldIDs."
        matTooltipClass="toolTipStyle"
        color="accent"
        (click)="updateFieldIDs()"
        style="text-align: right; max-width: 175px"
      >
        Update FieldIDs
      </button>
    </div>
  </div>
