import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { CcmcApiService } from '../../services/ccmc-api.service';

import { SnackbarService } from '../../services/snackbar.service';
import { SpinnerService } from '../../services/spinner.service';
import { CCMCConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
/**
 * Core Class
 *
 * @export
 * @class Core
 */
export class Core {
  /**
   * Cognito ID
   *
   * @type {string}
   * @memberof Core
   */
  cognitoID: string;

  /**
   * cStation
   *
   * @type {string}
   * @memberof Core
   */
  cStation: string;

  /**
   * password
   *
   * @type {string}
   * @memberof Core
   */
  password: string;

  /**
   * username
   *
   * @type {string}
   * @memberof Core
   */
  username: string;
}
/**
 * The CCMCConfirm dialog asks the user a message and returns true or false
 *
 * @export
 * @class AssetSelectorEditDialogComponent
 */

@Component({
  selector: 'app-asset-selector-edit-dialog',
  templateUrl: './asset-selector-edit-dialog.component.html',
  styleUrls: ['./asset-selector-edit-dialog.component.scss']
})
export class AssetSelectorEditDialogComponent implements OnInit {
  localSaved: any;
  /**
   * confirm message
   *
   * @type {string}
   * @memberof AssetSelectorEditDialogComponent
   */
  unsubscribe: Subject<any> = new Subject();
  selected: any;
  editedFlag = false;
  pinnedAssets: any = [];
  tmpAssetDynamoDB: any;
  assetEnvironment = 'prod';
  tempLOS: any;
  losOptions = [];
  coreOptions = [];
  losConfigurations: any;
  coreConfigruations: any;
  losConfigruations: any;
  isNXTsoftOperations = true;
  spinnerSub: Subscription;
  showSpinner: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<AssetSelectorEditDialogComponent>,
    private snackBarService: SnackbarService,
    private dialog: MatDialog,
    private ccmcApiService: CcmcApiService,
    private spinnerService: SpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private amplifyService: AmplifyService
  ) {}

  ngOnInit() {
    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        this.showSpinner = spinner;
      });
    this.localSaved = JSON.parse(JSON.stringify(this.data.asset));
    let assetsFromStorage: any = localStorage.getItem('assetIds');
    if (assetsFromStorage) {
      const assets = JSON.parse(assetsFromStorage).forEach((assetId: any) => {
        this.pinnedAssets.push(assetId);
      });
    }
  }
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }
  fieldEdited(event: any, category: any) {
    // the drop down feild passes in an array this checks if it is an array
    // if it is sets event to the event value
    if (event.length == undefined) {
      event = event.value;
    }
    if (this.isEquivalent(this.data.asset, this.localSaved, event, category)) {
      // Checks to see if modified local selected variable is the same as the current fields
      this.editedFlag = false;
    } else {
      this.editedFlag = true;
    }
  }

  /**
   * Compares 2 objects and returns if they are equivalent
   *
   * @param {*} a
   * @param {*} b
   * @returns
   * @memberof SupportValuesSupportValuesComponent
   */
  isEquivalent(a: any, b: any, changedvalue: any, category: any) {
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);
    for (let i = 0; i < aProps.length; i++) {
      // ignores the edited property since those values will not be the same
      if (bProps[i] !== 'edited') {
        const propName = aProps[i];
        // If values of same property are not equal,
        // objects are not equivalent
        //this fixes an issue where b[propName] was one behind
        if (propName == category) {
          if (a[propName] !== changedvalue) {
            return false;
          } else {
            return true;
          }
        }
      }
    }
    return true;
  }

  changeOrAddAsset() {
    const message = 'Save your current changes?';
    const dialogRefBackup = this.dialog.open(CCMCConfirmDialogComponent, {
      data: message,
      panelClass: 'save_current_changes__panel'
    });
    dialogRefBackup.afterClosed().subscribe(data => {
      if (data) {
        this.spinnerService.setShowSpinner(true);
        const assetId = 'assetId';
        // checks to see if the asset Id has changed
        if (this.localSaved[assetId] == this.data.asset[assetId]) {
          this.submitAsset();
        } else {
          // asset Id changed
          const assetIndex = this.data.assetsArray.findIndex(
            (asset: any) => asset.assetId == this.localSaved.assetId
          );
          if (assetIndex > -1) {
            //check to ensure new asset id doesnt exits in assetArray
            const finishedMessage = `Asset ${this.localSaved.assetId} Already Exists`;
            this.snackBarService.openSnackBar(finishedMessage, 'Okay');
          } else {
            let isGLA = false;
            //checks if GLA
            if (this.localSaved.product.includes('general_ledger_accounting')) {
              isGLA = true;
              this.pullCurrentCouchBaseDocument(isGLA);
            } else {
              this.pullCurrentCouchBaseDocument(isGLA);
            }
          }
          // updates pinned list so it stays current
          this.updatePinnedList();
        }
      }
    });
  }
  pullCurrentCouchBaseDocument(isGLA: any) {
    if (isGLA) {
      this.getCoreConnection();
    } else {
      this.getCoreConnection();
      this.getLosConnection();
    }
    setTimeout(() => {
      //waits for previous to finish
      this.submitNewAsset();
    });
  }
  deleteOldCouchBaseDocument(assetID: any, bucket: any, cluster: any) {
    this.ccmcApiService
      .deleteCouchbaseItem(assetID, bucket, cluster)
      .subscribe(result => {
        if (result) {
          const parsedResult = JSON.parse(JSON.stringify(result));
          if (parsedResult.statusFlag) {
            console.log(parsedResult);
          }
        }
      });
  }
  submitAsset() {
    delete this.localSaved['isPinned'];
    this.ccmcApiService
      .createDynamoDB('test', 'assets', this.localSaved)
      .subscribe(result => {
        if (result) {
          this.dialogRef.close(true);
        }
      });
  }
  submitNewAsset() {
    delete this.localSaved['isPinned'];
    this.ccmcApiService
      .createDynamoDB('test', 'assets', this.localSaved)
      .subscribe(result => {
        if (result) {
          this.ccmcApiService
            .deleteDynamoDBItem('assetId', this.data.asset.assetId, 'assets')
            .subscribe(result => {
              if (result) {
                this.spinnerService.setShowSpinner(false);
                this.dialogRef.close(true);
              }
            });
        }
      });
  }
  updatePinnedList() {
    let localStorageAssets: any = localStorage.getItem('assetIds');
    const assetIds = JSON.parse(localStorageAssets);
    // checks if the asset is in the current pinned
    if (assetIds.includes(this.data.asset.assetId)) {
      // checks if the asset changed
      if (this.data.asset.assetId !== this.localSaved.assetId) {
        this.removeFromPersonalList();
      }
    }
  }
  removeFromPersonalList() {
    let oldAsset = this.data.asset.assetId;
    let localStorageAssets: any = localStorage.getItem('assetIds');
    const assetIds = JSON.parse(localStorageAssets);
    const assetId = oldAsset;
    const assetIndex = assetIds.findIndex((a: any) => a === assetId);
    if (assetIndex > -1) {
      //removes old and places new in place
      assetIds.splice(assetIndex, 1);
      assetIds.push(this.localSaved.assetId);
    }

    this.updateAssets(assetIds, assetId);
  }

  updateAssets(assetIds: any, assetNumber: any) {
    const userName = this.amplifyService.username;
    this.ccmcApiService.updateAssets(userName, assetIds).subscribe(result => {
      if (result) {
        localStorage.setItem('assetIds', JSON.stringify(assetIds));
        const successdialoguref = this.dialog.open(SuccessDialogComponent, {
          data: { title: 'Success', message: result }
        });
        successdialoguref.afterClosed().subscribe(close => {});
      }
    });
  }

  getLosConnection() {
    this.ccmcApiService
      .getDynamoDBItem('los_connection_data', this.localSaved.los, 'losName')
      .subscribe(result => {
        const parsedResult = JSON.parse(JSON.stringify(result));
        let couchbaseConnectionString = parsedResult.couchbaseConnectionString;
        let couchbaseBucket = parsedResult.bucket;
        this.getLosDocument(
          this.data.asset.assetId,
          couchbaseBucket,
          couchbaseConnectionString
        );
      });
  }
  getLosDocument(
    assetId: any,
    couchbaseBucket: any,
    couchbaseConnectionString: any
  ) {
    this.ccmcApiService
      .getCouchbaseDocument(couchbaseConnectionString, couchbaseBucket, assetId)
      .subscribe(coreDocumentResult => {
        console.log('Get Core Standard', coreDocumentResult);
        if (coreDocumentResult) {
          const parsedResult = JSON.parse(JSON.stringify(coreDocumentResult));
          if (parsedResult.statusFlag) {
            parsedResult.document['_id'] = this.localSaved.assetId;
            this.createCouchbaseDocument(
              couchbaseConnectionString,
              this.localSaved.assetId,
              couchbaseBucket,
              parsedResult.document
            );
          } else {
            console.log('Core parsedresult', parsedResult);
          }
        }
      });
  }
  /*
  Grabbing the list of cores
  */
  getCoreConnection() {
    this.coreOptions = [];
    this.ccmcApiService
      .getDynamoDBItem('core_connection_data', this.localSaved.core, 'coreName')
      .subscribe(result => {
        // gets current dynamo item for selected item
        const parsedResult = JSON.parse(JSON.stringify(result));
        this.coreConfigruations = parsedResult;
        let couchbaseConnectionString = parsedResult.couchbaseConnectionString;
        let couchbaseBucket = parsedResult.bucket;
        this.getCoreDocument(
          this.data.asset.assetId,
          couchbaseBucket,
          couchbaseConnectionString
        );
        console.log(this.coreOptions);
      });
  }
  getCoreDocument(assetID: string, bucket: string, connectionString: string) {
    this.ccmcApiService
      .getCouchbaseDocument(connectionString, bucket, assetID)
      .subscribe(coreDocumentResult => {
        // gets current document for selected
        console.log('Get Core Standard', coreDocumentResult);
        if (coreDocumentResult) {
          const parsedResult = JSON.parse(JSON.stringify(coreDocumentResult));
          if (parsedResult.statusFlag) {
            parsedResult.document['_id'] = this.localSaved.assetId;
            this.createCouchbaseDocument(
              connectionString,
              this.localSaved.assetId,
              bucket,
              parsedResult.document
            );
          } else {
            console.log('Core parsedresult', parsedResult);
          }
        }
      });
  }
  createCouchbaseDocument(
    connectionString: any,
    assetID: string,
    bucket: string,
    document: any
  ) {
    // creates new couchbase or updates existing
    this.ccmcApiService
      .createCouchbaseGeneric(connectionString, assetID, bucket, document)
      .subscribe(createDocumentResult => {
        if (createDocumentResult) {
          // after creating a new one deletes old
          this.deleteOldCouchBaseDocument(
            this.data.asset.assetId,
            bucket,
            connectionString
          );
        }
      });
  }
}
