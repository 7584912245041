import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { fromEvent, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil
} from 'rxjs/operators';
import { AddRemoveColumnsDialogComponent } from 'src/@ccmc/components/add-remove-columns-dialog/add-remove-columns-dialog.component';
import { CreateSetColumnDialogComponent } from 'src/@ccmc/components/create-set-column-dialog/create-set-column-dialog.component';
import { CreateSetDialogComponent } from 'src/@ccmc/components/create-set-dialog/create-set-dialog.component';
import { CreateSetTransactionDialogComponent } from 'src/@ccmc/components/create-set-transaction-dialog/create-set-transaction-dialog.component';
import { EditSetDialogComponent } from 'src/@ccmc/components/edit-set-dialog/edit-set-dialog.component';
import { EditSetTransactionDialogComponent } from 'src/@ccmc/components/edit-set-transaction-dialog/edit-set-transaction-dialog.component';
import { SnippetsDialogComponent } from 'src/@ccmc/components/snippets-dialog/snippets-dialog.component';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { FieldEditedService } from '../../../../@ccmc/services/field-edited.service';
import { GeneralLedgerAccountingService } from '../../../../@ccmc/services/general-ledger-accounting.service';
import { CCMCSelectedFieldService } from '../../../../@ccmc/services/selected-field.service';

@Component({
  selector: 'app-set-configuration',
  templateUrl: './set-configuration.component.html',
  styleUrls: ['./set-configuration.component.scss']
})
export class SetConfigurationComponent implements OnInit {
  private glaDocumentSub: Subscription;
  private selectedSub: Subscription;
  showSpinner: boolean;
  private spinnerSub: Subscription;
  sets: any;
  vbScriptEdited: any;
  displayedColumns = ['Name', 'edited', 'editColumn'];
  transactionsDataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filterSearch', { static: true }) filterSearchEl: ElementRef;
  selectedTransaction: any;
  selectedSet: any;
  filterSearch: string;
  unsubscribe: Subject<any> = new Subject();
  tempDocument: any;
  selectedTransactionSubscription: any;
  targetLayout: any;
  editColumnsSubscription: any;
  canEditColumns = false;
  constructor(
    private glaService: GeneralLedgerAccountingService,
    private selectedFieldService: CCMCSelectedFieldService,
    private dialog: MatDialog,
    private fieldEditedService: FieldEditedService,
    private assetService: AssetService,
    private adminApiService: AdminApiService
  ) {}

  ngOnInit() {
    this.getData();
    this.initFilterSearch();
  }

  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  getData() {
    let core = this.assetService.getSelectedCore();
    this.adminApiService
      .getCoreConnection(core)
      .subscribe(coreConnection => {
        console.log(coreConnection);
        if (coreConnection) {
          const parsedCoreConnection = JSON.parse(
            JSON.stringify(coreConnection)
          );
          if (parsedCoreConnection.statusFlag == false) {
            return;
          } else {
            // this.glaDocumentSub = this.glaService.glaDocument
            this.adminApiService
              .getMappingDocument(parsedCoreConnection)!
              .subscribe(glaDocument => {
                if (glaDocument) {
                  this.tempDocument = glaDocument;
                  console.log('GLA Document', glaDocument);
                  console.log(this.tempDocument.document.sets);
                  // TODO: Init set at index 0 as starter set
                  if (this.tempDocument.document.sets) {
                    this.sets = this.tempDocument.document.sets;
                    this.targetLayout = this.tempDocument.document.coreLayout;
                    // console.log(glaDocument);
                    // console.log(this.targetLayout);
                    if (this.sets.length > 0) {
                      this.selectSet(this.sets[0]);
                      // initializes pagination
                      this.transactionsDataSource.paginator = this.paginator;
                      // initializes sort
                      this.transactionsDataSource.sort = this.sort;
                    } else {
                      this.transactionsDataSource = new MatTableDataSource([]);
                      this.selectedFieldService.onGLATransactionFieldSelected.next(
                        null
                      );
                    }
                  } else {
                    this.sets = [];
                  }
                }
              });
            }
          }
      });

    // Subscribe to the selected transaction
    this.selectedTransactionSubscription =
      this.selectedFieldService.onGLATransactionFieldSelected
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(selectedTransaction => {
          if (selectedTransaction) {
            // console.log(selectedTransaction);
            this.selectedTransaction = selectedTransaction;
          }
        });
  }

  selectSet(set: any) {
    // console.log(set);
    this.selectedSet = set;
    this.selectedFieldService.onGLATransactionFieldSelected.next(
      this.selectedSet
    );

    if (set.transactions) {
      // console.log(set.transactions);
      this.transactionsDataSource = new MatTableDataSource(set.transactions);
      this.onSelectTransaction(this.transactionsDataSource.filteredData[0]);
    } else {
      this.transactionsDataSource = new MatTableDataSource([]);
    }
  }

  initFilterSearch() {
    this.filterSearchEl.nativeElement.focus();
    fromEvent(this.filterSearchEl.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // Time in milliseconds between key events
        debounceTime(1000),
        // If previous query is diffent from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((text: string) => {
        this.applyFilter(text);
      });
  }

  applyFilter(filterValue: string) {
    // console.log(this.transactionsDataSource);
    this.transactionsDataSource.filter = filterValue.trim().toLowerCase();
    // Select first instance
    if (this.transactionsDataSource.filteredData[0]) {
      this.onSelectTransaction(this.transactionsDataSource.filteredData[0]);
    }
    this.filterSearch = filterValue;
  }

  onSelectTransaction(selected: any) {
    // console.log('look here selected', selected);
    this.selectedTransaction = selected;
    this.selectedFieldService.onGLATransactionFieldSelected.next(selected);
  }

  openSnippetDialog() {
    this.dialog.open(SnippetsDialogComponent, {
      panelClass: 'snippets-dialog'
    });
  }

  addSetColumn() {
    const createSetColumnDialogRef = this.dialog.open(
      CreateSetColumnDialogComponent
    );
    createSetColumnDialogRef.afterClosed().subscribe(newColumn => {
      if (newColumn) {
        this.selectedTransaction.columns.push(newColumn);
        this.selectedTransaction.edited = true;
        this.fieldEditedService.onGLADocumentEdited.next(true);
        this.onSelectTransaction(this.selectedTransaction);
        this.selectedFieldService.onTransactionColumnFieldSelected.next(
          newColumn
        );
      }
    });
  }

  /**
   * Sync Columns
   * The Sync Columns function loops through the target layout
   * and creates a new columns array off that.
   * @memberof SetConfigurationComponent
   */
  syncColumns() {
    // Init temp column array
    const tempColumnArray = [];
    // Loop through target layout
    for (let targetLayoutItem of this.targetLayout) {
      // Find column index of columnID
      const columnIndex = this.selectedTransaction.columns.findIndex(
        (col: any) => col.columnID == targetLayoutItem.columnID
      );
      // If it exists
      if (columnIndex > -1) {
        // Create new column using old column data
        const tempColumn = {
          columnID: this.selectedTransaction.columns[columnIndex].columnID,
          vbScript: this.selectedTransaction.columns[columnIndex].vbScript,
          edited: false
        };
        // Push to temp column array
        tempColumnArray.push(tempColumn);
      } else {
        // Create new column using target layout columnID
        const tempColumn = {
          columnID: targetLayoutItem.columnID,
          vbScript: '',
          edited: true
        };
        // Transaction has been edited here
        this.selectedTransaction.edited = true;
        // Push to temp column array
        tempColumnArray.push(tempColumn);
      }
    }
    // If lengths don't match we know the transaction has been edited
    if (this.selectedTransaction.columns.length !== tempColumnArray.length) {
      this.selectedTransaction.edited = true;
    }
    // Update selected transaction columns object
    this.selectedTransaction.columns = tempColumnArray;
    // Reselect transaction to trigger set configuration columns subscription update
    this.onSelectTransaction(this.selectedTransaction);
  }

  addSet() {
    const createSetDialogRef = this.dialog.open(CreateSetDialogComponent);
    createSetDialogRef.afterClosed().subscribe(newSet => {
      if (newSet) {
        this.sets.push(newSet);
        this.fieldEditedService.onGLADocumentEdited.next(true);
        this.selectSet(newSet);
      }
    });
  }

  editSet() {
    const editSetDialogRef = this.dialog.open(EditSetDialogComponent, {
      data: this.selectedSet
    });
    editSetDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fieldEditedService.onGLADocumentEdited.next(true);
      }
    });
  }

  createSetTransaction() {
    const createTransactionDialogRef = this.dialog.open(
      CreateSetTransactionDialogComponent,
      {
        data: this.selectedSet
      }
    );
    createTransactionDialogRef.afterClosed().subscribe(newTransaction => {
      if (newTransaction) {
        if (this.selectedSet.transactions) {
          this.selectedSet.transactions.push(newTransaction);
        } else {
          this.selectedSet.transactions = [newTransaction];
        }
        this.fieldEditedService.onGLADocumentEdited.next(true);
        console.log(this.selectedSet);
        this.transactionsDataSource = new MatTableDataSource(
          this.selectedSet.transactions
        );
        this.onSelectTransaction(newTransaction);
      }
    });
  }

  openEditTransactionDialog(transaction: any) {
    const editTransactionDialogRef = this.dialog.open(
      EditSetTransactionDialogComponent,
      {
        data: transaction
      }
    );
    editTransactionDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fieldEditedService.onGLADocumentEdited.next(true);
        this.selectedTransaction.edited = true;
      }
    });
  }

  openAddRemoveColumnsDialogComponent() {
    const addRemoveColumnsDialogComponentDialogRef = this.dialog.open(
      AddRemoveColumnsDialogComponent,
      {
        data: this.selectedSet
      }
    );
    addRemoveColumnsDialogComponentDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fieldEditedService.onGLADocumentEdited.next(true);
        this.selectSet(this.selectedSet);
      }
    });
  }
}
