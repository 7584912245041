import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { SuccessDialogComponent } from './success-dialog.component';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';

@NgModule({
    declarations: [
        SuccessDialogComponent
    ],
    imports: [
        MatDialogModule,
        AppMaterialModule,
        MatButtonModule
    ],
    entryComponents: [
        SuccessDialogComponent
    ],
})
export class CCMCSuccessDialogModule {
}
