import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CCMCSharedModule } from '../../../../@ccmc/shared.module';

import { CCMCTransactionsComponent } from './transactions.component';
import { AuthGuard } from '../../../auth/auth.guard';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RequestChangeModule } from '../request-change/request-change.module';
import { Role } from 'src/@ccmc/models/role';
import { ImportModule } from '../import/import.module';

const routes: Routes = [
  {
    path: 'transactions/:id',
    component: CCMCTransactionsComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [CCMCTransactionsComponent],
  imports: [
    RouterModule.forChild(routes),
    CCMCSharedModule,
    KeyboardShortcutsModule.forRoot(),
    // tslint:disable-next-line: deprecation
    ScrollingModule,
    RequestChangeModule,
    ImportModule
  ],
  exports: [CCMCTransactionsComponent]
})
export class CCMCTransactionsModule { }
