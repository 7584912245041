import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-select-sets-dialog',
  templateUrl: './select-sets-dialog.component.html',
  styleUrls: ['./select-sets-dialog.component.scss']
})
export class SelectSetsDialogComponent implements OnInit {
  selectedSet: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public sets: any,
    private dialogRef: MatDialogRef<SelectSetsDialogComponent>
  ) {}

  ngOnInit(): void {
    console.log(this.sets);
  }

  checkContinue() {
    if (this.selectedSet) {
      return true;
    } else {
      return false;
    }
  }

  submit() {
    this.dialogRef.close(this.selectedSet);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
