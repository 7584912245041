import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Pipe,
  PipeTransform,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { fromEvent, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil
} from 'rxjs/operators';
import { RequestChangeDialogComponentBlank } from 'src/@ccmc/components/request-change-dialog-blank/request-change-dialog.component-blank';
import { RequestChangeDialogComponent } from 'src/@ccmc/components/request-change-dialog/request-change-dialog.component';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { navigation } from '../../../../app/navigation/navigation';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { RequestChangeService } from 'src/@ccmc/services/request-change.service';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
import { BrowserModule } from '@angular/platform-browser';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { GlobalSearchService } from 'src/@ccmc/services/global-search.service';
import { NewStatusDialogComponent } from 'src/@ccmc/components/new-status-dialog/new-status-dialog.component';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { ActiveLosService } from 'src/@ccmc/services/active-los.service';
import { AssetService } from 'src/@ccmc/services/asset.service';

@Component({
  selector: 'app-request-change',
  templateUrl: './request-change.component.html',
  styleUrls: ['./request-change.component.scss']
})
export class RequestChangeComponent implements OnInit {
  statuses: any;
  detailedFilter = { detailed: '', tab: '' };
  peopleFilter: { tab: string; value: string };
  showOnlyLoan = false;
  displayAllCheckBox: Subscription;
  selectedTab: Subscription;
  loanNumber: any;
  otherGlobalSearchFields: any;
  isOrigenate: any;
  constructor(
    private selectedFieldService: CCMCSelectedFieldService,
    public dialog: MatDialog,
    private snackBarService: SnackbarService,
    private globalSearchService: GlobalSearchService,
    private ccmcApiService: CcmcApiService,
    private spinnerService: SpinnerService,
    private fieldEditedService: FieldEditedService,
    private amplifyService: AmplifyService,
    private requestChangeService: RequestChangeService,
    private activeLosService: ActiveLosService,
    private assetService: AssetService
  ) {}
  @Input() tab = '';
  @Input() fromLocation = '';
  @Input() newTabSelect = '';
  @Input() selectedIndex = 1;
  rowsSub: Subscription;
  transactionSelectedSub: Subscription;
  refreshSub: Subscription;
  private spinnerSub: Subscription;
  showSpinner: boolean; // flag for the mat-spinner
  dataSourceChangePage: any;
  displayedColumnsChange = [];
  impModeChecked: boolean;
  @ViewChild(MatPaginator, { static: true }) paginators: MatPaginator;
  unsubscribe: Subject<any> = new Subject();
  @ViewChild(MatSort) sort: MatSort;
  displayedColumnsDropDown = [
    'loanNumber',
    'fieldID',
    'fieldDisplay',
    'openedDate',
    'currentValue',
    'correctedValue'
  ];
  displayedColumns = [
    'loanNumber',
    'fieldID',
    'fieldDisplay',
    'openedDate',
    'correctedValue',
    'linkedValue'
  ];
  emptyRow = {
    fieldID: '',
    fieldDisplay: '',
    currentValue: '',
    correctedValue: '',
    loanNumber: '',
    status: '',
    urgency: '',
    description: '',
    nxtsoftNotes: ''
  };
  isNXTsoft = this.amplifyService.isNXTsoft;
  isNXTsoftDev = this.amplifyService.isNXTsoftDev;
  isNXTsoftOp = this.amplifyService.isNXTsoftOperations;
  public selected: any;
  changeRequestFieldsSub: Subscription;
  docFields: any;
  public selectedFilter: any;
  extraFilter = '';
  @ViewChild('filterSearch', { static: true }) filterSearchEl: ElementRef;
  filterSearch: string;
  rowForCurrentTable = { status: '', rows: [] };
  alwaysHaveAllTables: any[] = [];
  allWowsInTable: any[] = [];
  allRowsInTable: any[] = [];

  ngOnInit(): void {
    this.isOrigenate = this.activeLosService.activeLos;
    this.fieldEditedService.displayAllChecks.next(this.showOnlyLoan);
    this.selectedFilter = 'all';
    this.detailedFilter.tab = this.tab;
    // this.spinnerService.setShowSpinner(true);
    this.displayAllCheckBox = this.fieldEditedService.displayAllChecks
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(checked => {
        this.showOnlyLoan = checked;
        // console.log(this.showOnlyLoan);
        // console.log(this.showOnlyLoan);
      });
    this.getChangeList();
    this.spinnerSub = this.spinnerService.spinner.subscribe(spinner => {
      this.showSpinner = spinner;
      // console.log(this.showSpinner);
    });
    if (this.tab == 'Items To Test') {
      if (this.fromLocation == 'transactions') {
        if (this.allRowsInTable[0] == undefined) {
          this.onSelects('', this.emptyRow);
        } else {
          this.onSelects('', this.allRowsInTable[0]);
        }
      }
    }

    this.selectedTab = this.fieldEditedService.newTableChecked
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(tab => {
        if (tab === this.tab) {
          {
            this.resetTables();
            this.checkboxTrigger();
            this.getChangeList();
            this.turnOffSpinner();
          }
          this.selectTopOption();
        }
      });

    this.requestChangeService.changeRequestDocStatuses
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(result => {
        // console.log(check);
        this.statuses = result.statuses;
      });

    this.filterSearchEl.nativeElement.focus();
    fromEvent(this.filterSearchEl.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // Time in milliseconds between key events
        debounceTime(1000),
        // If previous query is diffent from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((text: string) => {
        this.applyFilter(text);
      });
  }

  selectTopOption() {
    if (this.fromLocation == 'dropdown') {
      if (this.alwaysHaveAllTables[0] == undefined) {
        this.onSelects('', this.emptyRow);
      } else {
        this.onSelects('', this.alwaysHaveAllTables[0]);
      }
    } else {
      if (!this.showOnlyLoan) {
        if (this.allRowsInTable[0] == undefined) {
          this.onSelects('', this.emptyRow);
        } else {
          this.onSelects('', this.allRowsInTable[0]);
        }
      } else {
        if (this.alwaysHaveAllTables[0] == undefined) {
          this.onSelects('', this.emptyRow);
        } else {
          this.onSelects('', this.alwaysHaveAllTables[0]);
        }
      }
    }
  }
  changeFilterPredicate(predicate: string) {
    this.extraFilter = '';
    this.dataSourceChangePage.filter = '';
    console.log(predicate);
    // console.log(this.dataSourceChangePage.filterPredicate);
    if (predicate === 'all') {
      // tslint:disable-next-line: no-shadowed-variable
      this.dataSourceChangePage.filterPredicate = function (
        data: any,
        filter: string
      ): boolean {
        // tslint:disable-next-line: max-line-length

        return (
          data.loanNumber.toLowerCase().includes(filter) ||
          data.fieldID.toLowerCase().includes(filter) ||
          data.fieldDisplay.toLowerCase().includes(filter) ||
          data.currentValue.toLowerCase().includes(filter) ||
          data.urgency.toLowerCase().includes(filter) ||
          data.correctedValue.toLowerCase().includes(filter)
        );
      };
    } else if (predicate === 'loanNumber') {
      // tslint:disable-next-line: no-shadowed-variable
      this.dataSourceChangePage.filterPredicate = function (
        data: any,
        filter: string
      ): boolean {
        return (
          data.loanNumber.toLowerCase().includes(filter) ||
          data.loanNumber.includes(filter)
        );
      };
    } else if (predicate === 'fieldID') {
      // tslint:disable-next-line: no-shadowed-variable
      this.dataSourceChangePage.filterPredicate = function (
        data: any,
        filter: string
      ): boolean {
        return (
          data.fieldID.toLowerCase().includes(filter) ||
          data.fieldID.includes(filter)
        );
      };
    } else if (predicate === 'fieldDisplay') {
      // tslint:disable-next-line: no-shadowed-variable
      this.dataSourceChangePage.filterPredicate = function (
        data: any,
        filter: string
      ): boolean {
        return (
          data.fieldDisplay.toLowerCase().includes(filter) ||
          data.fieldDisplay.includes(filter)
        );
      };
    } else if (predicate === 'currentValue') {
      // tslint:disable-next-line: no-shadowed-variable
      this.dataSourceChangePage.filterPredicate = function (
        data: any,
        filter: string
      ): boolean {
        return (
          data.currentValue.toLowerCase().includes(filter) ||
          data.currentValue.includes(filter)
        );
      };
    } else if (predicate === 'correctedValue') {
      // tslint:disable-next-line: no-shadowed-variable
      this.dataSourceChangePage.filterPredicate = function (
        data: any,
        filter: string
      ): boolean {
        return (
          data.correctedValue.toLowerCase().includes(filter) ||
          data.correctedValue.includes(filter)
        );
      };
    } else if (predicate === 'urgency') {
      // tslint:disable-next-line: no-shadowed-variable
      this.dataSourceChangePage.filterPredicate = function (
        data: any,
        filter: string
      ): boolean {
        return (
          data.urgency.toLowerCase().includes(filter) ||
          data.urgency.includes(filter)
        );
      };
    }
  }
  resetTables() {
    this.alwaysHaveAllTables = [];
    this.allWowsInTable = [];
    this.allRowsInTable = [];
  }
  checkboxTrigger() {
      // this.showOnlyLoan = true;
      this.fieldEditedService.displayAllChecks.next(this.showOnlyLoan);
  }
  
  arrayObjectIndexOf(myArray: any, searchTerm: any, property: any) {
    for (var i = 0, len = myArray.length; i < len; i++) {
      if (myArray[i][property] === searchTerm) return i;
    }
    return -1;
  }
  getChangeList() {
    // console.log(this.tab);
    this.impModeChecked = true;
    // console.log(this.impModeChecked);
    this.rowsSub = this.requestChangeService.changeRequestDoc
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(changeRequestItem => {
        this.resetTables();
        this.spinnerService.setShowSpinner(false);
        changeRequestItem.forEach((field: any) => {
          if(!field.assetItem.dateCreated) {
            field.assetItem.dateCreated = '';
          }

          field.assetItem.changeRequestID = field.changeRequestID;
        });
        let temporaryNotes = JSON.parse(JSON.stringify(changeRequestItem));
        let counter = 0;
        for(let field of temporaryNotes) {
          delete field.changeRequestID;
          delete field.assetID;
          delete field.assetItem.changeRequestID;
          let newNote: any = '';
          if (field.assetItem.notesTable) {
            for (let note of field.assetItem.notesTable) {
              if (note.username === 'description') {
                continue;
              }
              // The tabbing below is intentionally strange.
              // This is the only way I could get new lines to export properly to excel.
              newNote = newNote + note.username.concat(`
  `, note.date, `
  `, note.notes, `
  
  `);
            }
          }
          temporaryNotes[counter] = field.assetItem;
          temporaryNotes[counter].notesTable = newNote;
          counter++;
        }
        let newTempObj = JSON.parse(JSON.stringify(temporaryNotes, [
        "status", 
        "loanNumber", 
        "fieldID",
        "fieldDisplay",
        "correctedValue",
        "urgency",
        "description",
        "nxtsoftNotes",
        "notesTable",
        "dateCreated"
      ], 4));
        this.requestChangeService.setChangeRequestExport(newTempObj);
        let rows = changeRequestItem;
        for (let row of rows) {
          this.loanNumber = this.ccmcApiService.loanNumber;
          // console.log(this.loanNumber);
          // console.log(this.ccmcApiService.loanNumber);
          if (this.fromLocation == 'dropdown') {
            if (row.assetItem.status == this.tab) {
              // console.log('pushing');
              this.allWowsInTable.push(row.assetItem);
            }
          } else {
            if (
              row.assetItem.loanNumber == this.ccmcApiService.loanNumber ||
              row.assetItem.loanNumber == ''
            ) {
              let index = this.arrayObjectIndexOf(
                this.ccmcApiService.mapping,
                row.assetItem.fieldID,
                'fieldID'
              );
              // console.log(index);
              if (index > -1) {
                row.assetItem.linkedValue = this.ccmcApiService.mapping[index].fieldValue;
              } else {
                row.assetItem.linkedValue = '';
              }

              if (row.assetItem.status == this.tab) {
                this.allRowsInTable.push(row.assetItem);
              }
            }
          }
          if (row.assetItem.status == this.tab) {
            // console.log('pushing');
            this.alwaysHaveAllTables.push(row.assetItem);
          }
        }
        if (this.allWowsInTable && this.allWowsInTable.length > 0) {
          for (let item of this.allWowsInTable) {
            if (item.notesTable) {
              for (let note of item.notesTable) {
                if (item.description === '' && note.username === 'description') {
                  item.description = note.notes;
                }
                if (item.nxtsoftNotes === '' && note.username === 'nxtsoftNotes') {
                  item.nxtsoftNotes = note.notes;
                }
              }
            }
          }
        }
        if (this.fromLocation == 'dropdown') {
          if (this.allWowsInTable.length > 0) {
            if (!this.allWowsInTable[0].notesTable) {
              // Creates initial notesTable array adding in description and nxtsoft notes
              this.createNotesArray();
            } else {
              // Handles if notesTable already exists
              let descriptionFlag: boolean = false;
              let nxtsoftNotesFlag: boolean = false;
              for (let item of this.allWowsInTable) {
                if (item.notesTable) {
                  for (let note of item.notesTable) {
                    // Determines if description / nxtsoft notes exists
                    // The purpose of this is to populate those fields at a later point below if they don't exist
                    if (note.username && note.username === 'description') {
                      descriptionFlag = true;
                    }
                    if (note.username && note.username === 'nxtsoftNotes') {
                      nxtsoftNotesFlag = true;
                    }
                    // Checks to see if nxtsoftNotes needs to be updated compared to prod
                    // Checks if prod needs to be updated if the notes were erased from a previous bug
                    if (note.username && note.username === 'nxtsoftNotes' && (item.nxtsoftNotes !== '' && item.nxtsoftNotes !== note.notes)) {
                      note.notes = item.nxtsoftNotes;
                      note.date = new Date().toISOString();
                      continue;
                    }
                    if (note.username && note.username === 'nxtsoftNotes' && item.nxtsoftNotes === '') {
                      item.nxtsoftNotes = note.notes;
                      continue;
                    }
                    // Checks to see if description needs to be updated compared to prod
                    // Checks if prod needs to be updated if the notes were erased from a previous bug
                    if (note.username && note.username === 'description' && (item.description !== '' && item.description !== note.notes)) {
                      note.notes = item.description;
                      note.date = new Date().toISOString();
                      continue;
                    }
                    if (note.username && note.username === 'description' && item.description === '') {
                      item.description = note.notes;
                      continue;
                    }
                  }
                  // Adds note for description or nxtsoftNotes if they didn't exist before but do now in prod
                  if (descriptionFlag === false) {
                    if (item.description !== "") {
                      item.notesTable.push({
                        username: 'description',
                        date: new Date().toISOString(),
                        notes: item.description
                      });
                    }
                  }
                  if (nxtsoftNotesFlag === false) {
                    if (item.nxtsoftNotes !== "") {
                      item.notesTable.push({
                        username: 'nxtsoftNotes',
                        date: new Date().toISOString(),
                        notes: item.nxtsoftNotes
                      });
                    }
                  }
                  descriptionFlag = false;
                  nxtsoftNotesFlag = false;
                } else {
                  // runs notes creation process if an item was found without the notesTable field
                  this.createNotesArray();
                }
              }
            }
          }
          this.dataSourceChangePage = new MatTableDataSource(
            this.allWowsInTable
          );
        } else {
          if (!this.showOnlyLoan) {
            this.dataSourceChangePage = new MatTableDataSource(
              this.allRowsInTable
            );
          } else {
            this.dataSourceChangePage = new MatTableDataSource(
              this.alwaysHaveAllTables
            );
          }
        }
        this.allWowsInTable = [];
        this.dataSourceChangePage.paginator = this.paginators;
        this.dataSourceChangePage.sort = this.sort;
        this.dataSourceChangePage.sortingDataAccessor = (item: any, property: any) => {
          switch (property) {
            case 'openedDate': return new Date(item.dateCreated);
            default: return item[property];
          }
        };
        // this.dataSourceChangePage.filter = this.tab;
        this.requestChangeService.changeRequestDocStatuses
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(result => {
          this.statuses = result.statuses;
          let totalLength = this.statuses.length;
          if (this.tab == this.statuses[totalLength - 1]) {
            this.spinnerService.setShowSpinner(false);
          }
        });
        
      });
  }
  onSelects(e: any, selected: any) {
    this.selected = selected;
    this.requestChangeService.onChangeRequestFields.next(selected);
  }
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }
  applyFilter(filterValue: string) {
    this.detailedFilter;
    let filteredValueLower = filterValue.toLocaleLowerCase();
    if (filterValue.includes('*')) {
      const data = this.dataSourceChangePage.data;
      const filteredArray = data.filter(
        (obj: any) =>
          obj.fieldID.includes(filterValue.split('*')[0]) &&
          obj.fieldID.includes(filterValue.split('*')[1])
      );
      this.dataSourceChangePage.filteredData = filteredArray;
      this.dataSourceChangePage.data = filteredArray;
    } else if (filterValue.length > 0) {
      this.detailedFilter.detailed = filterValue; //.trim().toLowerCase();
      this.dataSourceChangePage.filter = this.detailedFilter.detailed;
    } else if (filterValue.length === 0) {
      this.detailedFilter.detailed = ''; //.trim().toLowerCase();
      this.dataSourceChangePage.filter = '';
      this.filterSearch = '';
      return;
    }
    if (this.dataSourceChangePage.filteredData[0]) {
      this.selectedFieldService.onMappingFieldSelected.next(
        this.dataSourceChangePage.filteredData[0]
      );
    }
    this.filterSearch = filterValue;
  }

  newChangeRequest(event: any) {
    event.stopPropagation();
    // console.log(element);
    const premierSearchValueRef = this.dialog.open(
      RequestChangeDialogComponentBlank,
      {
        panelClass: 'change-request__panel-dialog'
      }
    );
    premierSearchValueRef.afterClosed().subscribe(result => {
      if (result) {
        this.transactionSelectedSub =
          this.requestChangeService.onChangeRequestNewField
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(selected => {
              if (selected) {
                // console.log(selected);
                this.snackBarService.openSnackBar(
                  'Change Request Added',
                  'Okay'
                );
              }
            });
        }
    });
  }
  turnOffSpinner() {
    this.spinnerService.setShowSpinner(false);
  }
  ngAfterViewInit() {
    this.dataSourceChangePage.sort = this.sort;
    // console.log(this.dataSourceChangePage.sort);
  }

  getDisplayedColumns(): string[] {
    if (this.fromLocation == 'dropdown') {
      return this.displayedColumnsDropDown;
    } else {
      return this.displayedColumns;
    }
  }
  createNewStatus() {
    // console.log(element);
    const premierSearchValueRef = this.dialog.open(NewStatusDialogComponent, {
      panelClass: 'new-status__panel-dialog'
    });
    premierSearchValueRef.afterClosed().subscribe(result => {
      if (result) {
        this.transactionSelectedSub =
          this.requestChangeService.onChangeRequestNewField
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(selected => {
              if (selected) {
                // console.log(selected);
                this.snackBarService.openSnackBar('Created New Status', 'Okay');
              }
            });
      }
    });
  }

  createNotesArray() {
    // Adds notesTable if it doesn't exist yet
    for (let item of this.allWowsInTable) {
      if (!item.notesTable) {
        item.notesTable = [];
        // Captures old nxtsoftNotes
        if (item.nxtsoftNotes !== "") {
          item.notesTable = [{
            username: 'nxtsoftNotes',
            date: new Date().toISOString(),
            notes: item.nxtsoftNotes
          }];
        }
        // Captures old description
        if (!item.notesTable) {
          item.notesTable = [{
            username: 'description',
            date: new Date().toISOString(),
            notes: item.description
          }];
        } else {
          item.notesTable.push({
            username: 'description',
            date: new Date().toISOString(),
            notes: item.description
          });
        }
      }
    }
  }
}
