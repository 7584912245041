import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditAssetDialogComponent } from './edit-asset-dialog.component';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [EditAssetDialogComponent],
  imports: [AppMaterialModule, CommonModule, FormsModule, ReactiveFormsModule],
  entryComponents: [EditAssetDialogComponent]
})
export class EditAssetDialogModule {}
