import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '../../services/snackbar.service';

@Component({
  selector: 'app-edit-set-dialog',
  templateUrl: './edit-set-dialog.component.html',
  styleUrls: ['./edit-set-dialog.component.scss']
})
export class EditSetDialogComponent implements OnInit {
  localSet: any;
  constructor(
    public dialogRef: MatDialogRef<EditSetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public set: any,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    this.localSet = JSON.parse(JSON.stringify(this.set));
  }

  closeDialog() {
    this.dialogRef.close();
  }

  update() {
    if (this.localSet.setName.length > 0) {
      this.set.setName = this.localSet.setName;
      this.dialogRef.close(true);
    } else {
      this.snackbarService.openSnackBar('Set Name must not be blank', 'Okay');
    }
  }
}
