import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog
} from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { CifSearchService } from '../../services/cif-search.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  SearchData,
  SearchDialogData,
  CifData
} from '../../models/cifSearchData.model';
import { CcmcApiService } from '../../services/ccmc-api.service';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { ActiveCoreService } from '../../services/active-core.service';
import { SpinnerService } from '../../services/spinner.service';
import { ConditionsService } from '../../services/conditions.service';
import { IssuesService } from 'src/@ccmc/services/issues.service';
import { SuffixSearchDialogComponent } from '../suffix-search-dialog/suffix-search-dialog.component';
import { PremierSearchValueComponent } from '../premier-search-value/premier-search-value.component';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { I } from '@angular/cdk/keycodes';
import { DNAService } from 'src/@ccmc/services/dna.service';

@Component({
  selector: 'ccmc-search-dialog',
  templateUrl: './search-borrower-dialog.component.html',
  styleUrls: ['./search-borrower-dialog.component.scss']
})
export class CCMCSearchBorrowerDialogComponent implements OnInit, OnDestroy {
  newBtnDisabled = true;
  cifSubscription: Subscription;
  resultsData: Array<any> = [];
  searchDataSource: any;
  // resultsDataSource: MatTableDataSource<CifData>;
  resultsDataSource: MatTableDataSource<any>;
  searchDisplayedColumns = ['name', 'taxID', 'CIF'];
  resultsDisplayedColumns = ['name', 'taxID', 'CIF'];
  @ViewChild('searchSort') searchSort: MatSort;
  @ViewChild('resultsSort') resultsSort: MatSort;
  @ViewChild('resultsTable') resultsTable: MatTable<any>;
  // selectedRows = [];
  selectedRow: any;
  selectedCif: any;
  party: any[] = [];
  showSpinner: boolean;
  private spinnerSub: Subscription;
  changeNameSub: Subscription;
  searchDisabled = true;
  newDisabled = false;
  changeName: boolean;
  unsubscribe: Subject<any> = new Subject();
  /**
   * Creates an instance of CCMCSearchBorrowerDialogComponent.
   * @param {SearchDialogData} data
   * @param {MatDialogRef<CCMCSearchBorrowerDialogComponent>} dialogRef
   * @param {CifSearchService} cifSearchService
   * @param {CcmcApiService} ccmcApiService
   * @param {ActiveCoreService} activeCoreSerivce
   * @param {MatDialog} dialog
   * @param {ChangeDetectorRef} cd
   * @param {ConditionsService} conditionsService
   * @param {SpinnerService} spinnerService
   * @param {IssuesService} issuesService
   * @memberof CCMCSearchBorrowerDialogComponent
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SearchDialogData,
    private dialogRef: MatDialogRef<CCMCSearchBorrowerDialogComponent>,
    private cifSearchService: CifSearchService,
    private ccmcApiService: CcmcApiService,
    private activeCoreSerivce: ActiveCoreService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private conditionsService: ConditionsService,
    private fieldEditedService: FieldEditedService,
    private spinnerService: SpinnerService,
    private issuesService: IssuesService,
    private dnaService: DNAService
  ) {}

  /**
   * On Init
   *
   * @memberof CCMCSearchBorrowerDialogComponent
   */
  ngOnInit() {
    this.changeNameSub = this.fieldEditedService.copyNameCheck.subscribe(
      name => {
        if (name) {
          this.changeName = name;
        } else {
          this.changeName = false;
        }
      }
    );

    // get the spinner status
    this.subscribeToSpinner();
    // init party to an array
    this.party = [];
    // subscribe to the cifResults
    this.cifSubscription = this.cifSearchService.cifResults$.subscribe(
      (cif: any) => {}
    ); // wait for the cif to be returned
    // get the party
    this.getParty();
    // init results data source from the results generated
    this.resultsDataSource = new MatTableDataSource(this.resultsData);
    this.setSearchCif();
  }

  /**
   * Get Spinner Status
   * @description subscribes to the spinner subject which returns true or false
   * @memberof CCMCSearchBorrowerDialogComponent
   */
  subscribeToSpinner() {
    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        this.showSpinner = spinner;

        if (!this.showSpinner) {
          if (this.selectedRow) {
            this.searchDisabled = false;
          }
        }
      });
  }

  /**
   * Get Party
   * @description uses core database to find the populated borrowers
   * @memberof CCMCSearchBorrowerDialogComponent
   */
  getParty() {
    console.log(this.ccmcApiService.search.customerSearch);
    // Loop through the customer search
    for (let i = 0; i < this.ccmcApiService.search.customerSearch.length; i++) {
      let borrower: any = {};
      // If premier use custom logic
      if (this.activeCoreSerivce.activeCore === 'premier') {
        // Set first name index
        const fnIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) =>
            f.fieldID === this.ccmcApiService.search.customerSearch[i].firstName
        );
        // Set middle name index
        const mnIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) =>
            f.fieldID ===
            this.ccmcApiService.search.customerSearch[i].middleName
        );
        // Set last name index
        const lnIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) =>
            f.fieldID === this.ccmcApiService.search.customerSearch[i].lastName
        );
        // Set taxID index
        const taxIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) => f.fieldID === this.ccmcApiService.search.customerSearch[i].taxID
        );
        // Set portfolia Index
        const portfolioIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) =>
            f.fieldID === this.ccmcApiService.search.customerSearch[i].portfolio
        );
        // Set name line index
        const nameLineIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) =>
            f.fieldID ===
            this.ccmcApiService.search.customerSearch[i].nameLine
              .split(',')[0]
              .trim()
        );
        // Set portfolio
        const portfolio =
          this.ccmcApiService.mapping[portfolioIndex].fieldValue;
        // Set name line
        const nameLine = this.ccmcApiService.mapping[nameLineIndex].fieldValue;
        // Set CIF
        let CIF =
          portfolio !== '' && nameLine !== '' ? `${portfolio}-${nameLine}` : '';
        //  if new set CIF to new
        if (nameLine.toUpperCase() === 'NEW') {
          CIF = 'NEW';
        }
        console.log(nameLine);
        // set borrower data
        borrower = {
          firstName: this.ccmcApiService.mapping[fnIndex].fieldValue,
          middleName: this.ccmcApiService.mapping[mnIndex].fieldValue,
          lastName: this.ccmcApiService.mapping[lnIndex].fieldValue,
          taxID: this.ccmcApiService.mapping[taxIndex].fieldValue,
          businessName: '',
          CIF: CIF,
          portfolio: this.ccmcApiService.mapping[portfolioIndex].fieldValue,
          nameLine: this.ccmcApiService.mapping[nameLineIndex].fieldValue,
          portfolioFieldID:
            this.ccmcApiService.search.customerSearch[i].portfolio,
          nameLineFieldID: this.ccmcApiService.search.customerSearch[i].nameLine
        };
      } else if (this.activeCoreSerivce.activeCore === 'opendci') {
        // Set first name index
        const fnIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) =>
            f.fieldID === this.ccmcApiService.search.customerSearch[i].firstName
        );
        // Set middle name index
        const mnIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) =>
            f.fieldID ===
            this.ccmcApiService.search.customerSearch[i].middleName
        );
        // Set last name index
        const lnIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) =>
            f.fieldID === this.ccmcApiService.search.customerSearch[i].lastName
        );
        // Set TaxIndex
        const taxIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) => f.fieldID === this.ccmcApiService.search.customerSearch[i].taxID
        );
        // tslint:disable-next-line:max-line-length
        // const bnIndex = this.ccmcApiService.mapping.findIndex((f: any) => f.fieldID === this.ccmcApiService.search.customerSearch[i].businessName);
        // Set cif index
        const cifIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) => f.fieldID === this.ccmcApiService.search.customerSearch[i].CIF
        );
        // console.log(fnIndex);
        // console.log(mnIndex);
        // console.log(lnIndex);
        // console.log(taxIndex);
        // console.log(bnIndex);
        // console.log(cifIndex);
        // Set borrower
        borrower = {
          firstName: this.ccmcApiService.mapping[fnIndex].fieldValue,
          middleName: this.ccmcApiService.mapping[mnIndex].fieldValue,
          lastName: this.ccmcApiService.mapping[lnIndex].fieldValue,
          taxID: this.ccmcApiService.mapping[taxIndex].fieldValue,
          businessName: '',
          CIF: this.ccmcApiService.mapping[cifIndex].fieldValue,
          CIFFields: this.ccmcApiService.search.customerSearch[i].CIFFields,
          perNameKeyField:
            this.ccmcApiService.search.customerSearch[i].perNameKeyField,
          tieBreakerField:
            this.ccmcApiService.search.customerSearch[i].tieBreakerField
        };
      } else {
        // Set first name index
        const fnIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) =>
            f.fieldID === this.ccmcApiService.search.customerSearch[i].firstName
        );
        // Set middle name index
        const mnIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) =>
            f.fieldID ===
            this.ccmcApiService.search.customerSearch[i].middleName
        );
        // Set last name index
        const lnIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) =>
            f.fieldID === this.ccmcApiService.search.customerSearch[i].lastName
        );
        // Set taxID index
        const taxIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) => f.fieldID === this.ccmcApiService.search.customerSearch[i].taxID
        );
        // Set Business Name Index
        const bnIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) =>
            f.fieldID ===
            this.ccmcApiService.search.customerSearch[i].businessName
        );
        // Set cif index
        const cifIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) => f.fieldID === this.ccmcApiService.search.customerSearch[i].CIF
        );
        console.log(fnIndex);
        console.log(mnIndex);
        console.log(lnIndex);
        console.log(taxIndex);
        // console.log(bnIndex);
        console.log(cifIndex);
        console.log(this.ccmcApiService.mapping[fnIndex]);
        if (this.ccmcApiService.mapping[taxIndex] !== undefined) {
          // Set borrower
          borrower = {
            firstName:
              fnIndex > -1
                ? this.ccmcApiService.mapping[fnIndex].fieldValue
                : '',
            middleName:
              mnIndex > -1
                ? this.ccmcApiService.mapping[mnIndex].fieldValue
                : '',
            lastName:
              lnIndex > -1
                ? this.ccmcApiService.mapping[lnIndex].fieldValue
                : '',
            taxID: this.ccmcApiService.mapping[taxIndex].fieldValue,
            businessName:
              bnIndex > -1
                ? this.ccmcApiService.mapping[bnIndex].fieldValue
                : '',
            CIF:
              cifIndex > -1
                ? this.ccmcApiService.mapping[cifIndex].fieldValue
                : '',
            CIFFields: this.ccmcApiService.search.customerSearch[i].CIFFields,
            FNFields: this.ccmcApiService.search.customerSearch[i].firstName,
            isNew: this.ccmcApiService.search.customerSearch[i].isNew
          };
          console.log(borrower);
        }
      }
      console.log('borrower: ', borrower);
      // If the borrower has a taxID push it to the party
      if (borrower.taxID !== '' && JSON.stringify(borrower) !== '{}') {
        this.party.push(borrower);
      }
    }
    console.log(this.party);
    // Builds the party name
    this.party = this.resolvePartyName(this.party);
    console.log(this.party);
    // Set the search data source
    this.searchDataSource = new MatTableDataSource(this.party);
    // Selects first instance of the data source for default
    this.select(this.searchDataSource.data[0]);
    // init the sort
    this.searchDataSource.sort = this.searchSort;
  }

  /**
   * Resolve Party Name
   * @description sets the party name for each party, first and last
   * @param {*} party
   * @returns
   * @memberof CCMCSearchBorrowerDialogComponent
   */
  resolvePartyName(party: any) {
    console.log(party);
    party = JSON.parse(JSON.stringify(party));
    for (let i = 0; i < party.length; i++) {
      console.log(party[i]);
      if (party[i].middleName !== undefined && party[i].middleName !== '') {
        party[
          i
        ].name = `${party[i].firstName} ${party[i].middleName} ${party[i].lastName}`;
      } else {
        party[i].name = `${party[i].firstName} ${party[i].lastName}`;
      }
    }
    console.log(party);
    return party;
  }
  /**
   * Select
   * @description sets the currently selected CIF to the selected row
   * @param {*} row
   * @memberof CCMCSearchBorrowerDialogComponent
   */
  select(row: any) {
    // console.log(row);
    if (!this.showSpinner) {
      this.selectedRow = row;
      if (this.selectedRow) {
        this.searchDisabled = false;
      }
    }
  }
  /**
   * Select Cif
   * @description sets the currently selected row to the selected CIF
   * @param {*} row
   * @memberof CCMCSearchBorrowerDialogComponent
   */
  selectCif(row: any) {
    console.log(row);
    this.selectedCif = this.selectedCif !== row ? row : '';
  }
  /**
   * Search
   * @description sends the selected row to the parent component and receives the result
   * @memberof CCMCSearchBorrowerDialogComponent
   */
  async autoSearch() {
    this.searchDisabled = true;
    this.newBtnDisabled = true;
    this.selectedCif = false;
    // If premier set portfolio
    if (this.activeCoreSerivce.activeCore === 'premier') {
      this.selectedRow.portfolio =
        this.party[0].CIF.split('-') !== ''
          ? this.party[0].CIF.split('-')[0]
          : '';
    }

    for (let searchItem of this.searchDataSource.data) {
      // Set configurations
      searchItem['configurations'] = this.ccmcApiService.configurations;
      this.select(searchItem);
      const temporaryBorrower = JSON.parse(JSON.stringify(searchItem));
      // Delete the name field in the final object
      delete temporaryBorrower.name;
      // Set the spinner to true
      this.spinnerService.setShowSpinner(true);
      let customerSearchResult = await this.asyncCustomerSearch(
        temporaryBorrower
      );
      if (customerSearchResult === false) {
        return;
      }
    }
    this.newBtnDisabled = false;
  }

  asyncCustomerSearch(borrower: any) {
    this.searchDisabled = true;
    this.newBtnDisabled = true;
    this.selectedCif = false;
    return new Promise(resolve => {
      // Perform customer search
      this.activeCoreSerivce.activeService.autoCustomerSearch(
        borrower,
        (result: any) => {
          console.log('search result: ', result);
          if (result.statusFlag !== undefined && !result.statusFlag) {
            this.newBtnDisabled = false;
            return resolve(false);
          }
          if (result[0].firstName != 'No Result') {
            console.log('has a first name');
            // Resolve party name
            const searchResult = this.resolvePartyName(result);
            // Set results as data
            this.resultsDataSource = new MatTableDataSource(searchResult);
            // Set CIf to the first index for default
            this.selectCif(this.resultsDataSource.data[0]);
            // Init the sort
            this.resultsDataSource.sort = this.resultsSort;
            if (result.length === 1) {
              if (this.activeCoreSerivce.activeCore === 'dna') {
                this.changeName = true;
              }
              this.populateCif();
            }
          } else {
            this.selectedRow.CIF = 'NEW';
          }
          resolve(true);
          this.newBtnDisabled = false;
        },
        (error: any) => {
          console.log(error);
          resolve(false);
        }
      );
    });
  }

  /**
   * Search
   * @description sends the selected row to the parent component and receives the result
   * @memberof CCMCSearchBorrowerDialogComponent
   */
  manualSearch() {
    this.searchDisabled = true;
    this.newBtnDisabled = true;
    this.selectedCif = false;
    // If premier set portfolio
    if (this.activeCoreSerivce.activeCore === 'premier') {
      this.selectedRow.portfolio =
        this.party[0].CIF.split('-') !== ''
          ? this.party[0].CIF.split('-')[0]
          : '';
    }
    // Set configurations
    this.selectedRow.configurations = this.ccmcApiService.configurations;
    // Remove added name field from borrower object
    let temporaryBorrower: any;
    if (this.selectedRow.originalCIF && this.selectedRow.originalCIF !== '') {
      temporaryBorrower = JSON.parse(JSON.stringify(this.selectedRow));
      temporaryBorrower.CIF = temporaryBorrower.originalCIF;
    } else {
      temporaryBorrower = JSON.parse(JSON.stringify(this.selectedRow));
    }
    // Delete the name field in the final object
    delete temporaryBorrower.name;
    // Set the spinner to true
    this.spinnerService.setShowSpinner(true);
    setTimeout(() => {
      // Perform customer search
      this.activeCoreSerivce.activeService.customerSearch(
        temporaryBorrower,
        (result: any) => {
          console.log('search result: ', result);
          if (result.length > 0) {
            if (result[0].firstName === 'No Result') {
              if (this.activeCoreSerivce.activeCore === 'premier') {
                console.log('open popup here');
                const premierSearchValueRef = this.dialog.open(
                  PremierSearchValueComponent,
                  {
                    data: temporaryBorrower,
                    panelClass: 'suffix-search__panel-dialog'
                  }
                );
                premierSearchValueRef.afterClosed().subscribe(result => {
                  if (result) {
                    const searchResult = this.resolvePartyName(result);
                    // Set results as data
                    this.resultsDataSource = new MatTableDataSource(
                      searchResult
                    );
                    // Set CIf to the first index for default
                    this.selectCif(this.resultsDataSource.data[0]);
                    // Init the sort
                    this.resultsDataSource.sort = this.resultsSort;
                    // Init change detection
                    this.cd.detectChanges();
                  }
                });
              } else {
                const errorMessage = {
                  message: 'No Results Found',
                  title: 'Search Result'
                };

                const dialogRef = this.dialog.open(ErrorDialogComponent, {
                  data: errorMessage
                });
              }
            } else {
              // Resolve party name
              const searchResult = this.resolvePartyName(result);
              // Set results as data
              this.resultsDataSource = new MatTableDataSource(searchResult);
              // Set CIf to the first index for default
              this.selectCif(this.resultsDataSource.data[0]);
              // Init the sort
              this.resultsDataSource.sort = this.resultsSort;
              // Init change detection
              this.cd.detectChanges();
            }
          } else {
            console.log(temporaryBorrower);
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
      // Set new button to false to enable it
      this.newBtnDisabled = false;
    }, 800);
  }

  /**
   * Populate Cif
   * @description sets the CIF of the selected row to the selected CIF
   * @memberof CCMCSearchBorrowerDialogComponent
   */
  populateCif() {
    // this.changeName = true;
    console.log(this.changeName);
    if (!this.selectedRow.originalCIF) {
      this.selectedRow.originalCIF = this.selectedRow.CIF;
    }
    // TODO: remove core specific logic and place in core service
    if (this.activeCoreSerivce.activeCore === 'opendci') {
      this.selectedRow.CIF = this.selectedCif.CIF;
      this.selectedRow.tieBreaker = this.selectedCif.tieBreaker;
      this.selectedRow.perNameKey = this.selectedCif.perNameKey;
    } else {
      this.selectedRow.CIF = this.selectedCif.CIF;
      if (this.changeName == true) {
        this.selectedRow.firstName = this.selectedCif.firstName;
        this.selectedRow.lastName = this.selectedCif.lastName;
        this.selectedRow.middleName = this.selectedCif.middleName;
        this.selectedRow.name = this.selectedCif.firstName.concat(' ', this.selectedCif.middleName, ' ', this.selectedCif.lastName);
      }
    }
  }

  /**
   * Set CIF New
   * @description sets the current row's CIF to 'NEW'
   * @memberof CCMCSearchBorrowerDialogComponent
   */
  setCifNew() {
    // TODO: remove core specific logic and place in core service
    if (this.activeCoreSerivce.activeCore === 'opendci') {
      this.selectedRow.CIF = 'NEW';
      this.selectedRow.tieBreaker = '';
      this.selectedRow.perNameKey = '';
    } else {
      this.selectedRow.CIF = 'NEW';
    }
  }

  /**
   * Check Continue
   * @description determines if continue button should be enabled
   * @returns
   * @memberof CCMCSearchBorrowerDialogComponent
   */
  checkContinue() {
    // Only check if searchDataSource exists
    if (this.searchDataSource) {
      // true if all are true
      return this.searchDataSource.data.every((val: any) => {
        // if val has cif return true
        if (val.CIF) {
          return true;
        }
        return false;
      });
    } else {
      return false;
    }
  }

  /**
   * Continue
   * @description Assigns the borrowers and allows the user to continue to exporting the loan
   * @memberof CCMCSearchBorrowerDialogComponent
   */
  continue() {
    // TODO: strip method into core service, so core specific logic is independent
    // Remove name field from party object
    // Remove added name field from borrower object
    const finalPartyObject = JSON.parse(JSON.stringify(this.party));
    // Delete the name property from the object
    for (let i = 0; i < finalPartyObject.length; i++) {
      delete finalPartyObject[i].name;
    }
    // Assign borrower
    this.activeCoreSerivce.activeService.assignBorrowers(finalPartyObject);
    // Set cifSet to true
    this.cifSearchService.cifSet = true;
    // Evaluate new values with conditions
    this.conditionsService.evaluateConditions();
    // Get issues
    this.issuesService.onGetIssues.next(true);
    // Close the search borrower dialog
    this.closeDialog();

    if (this.activeCoreSerivce.activeCore === 'spectrum') {
      console.log('open suffix search here');
      this.dialog.open(SuffixSearchDialogComponent, {
        data: finalPartyObject[0],
        panelClass: 'suffix-search__panel-dialog'
      });
    }

    if (this.activeCoreSerivce.activeCore === 'dna') {
      this.dnaService.finalParty = finalPartyObject;
    }
    // Pass finalParty[0] to suffixsearch
    // call suffix
    // Display results
    //
  }

  /**
   * Close Dialog
   * @description Close the search borrower dialog
   * @memberof CCMCSearchBorrowerDialogComponent
   */
  closeDialog() {
    this.dialogRef.close(true);
  }

  /**
   * On Destroy
   * Unsubscribe from the observables
   * @memberof CCMCSearchBorrowerDialogComponent
   */
  ngOnDestroy() {
    if (this.cifSubscription) {
      this.cifSubscription.unsubscribe();
    }
    if (this.spinnerSub) {
      this.spinnerSub.unsubscribe();
    }
  }

  setSearchCif() {
    this.autoSearch();
  }
}
