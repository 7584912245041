import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  /**
   * Spinner
   * Behavior subject which holds true or false for displaying the loaing spinner
   * @public
   * @type {BehaviorSubject<boolean>}
   * @memberof SpinnerService
   */
  //switched lines 12 and 17 to public
  public _spinner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  /**
   *Creates an instance of SpinnerService.
   * @memberof SpinnerService
   */
  constructor() {}

  /**
   * Spinner
   * @description returns true or false whether to show the spinner or not
   * @readonly
   * @memberof SpinnerService
   */
  get spinner() {
    return this._spinner.asObservable().pipe(
      map(spinner => {
        return spinner;
      })
    );
  }

  /**
   * Set Show Spinner
   * @description sets whether to show the spinner or not
   * @param {boolean} showSpinner
   * @memberof SpinnerService
   */
  setShowSpinner(showSpinner: boolean) {
    this._spinner.next(showSpinner);
  }
}
