import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Settings } from '../../../@ccmc/models/settings.model';

@Component({
    selector     : 'ccmc-quick-panel',
    templateUrl  : './quick-panel.component.html',
    styleUrls    : ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CCMCQuickPanelComponent implements OnInit {
    date: Date;
    settings: Settings;
    notes: any[] = [];
    events: any[] = [];

    constructor() {
        this.date = new Date();
        this.settings = {
            notify: true,
            cloud : false,
            retro : true
        };
    }

    ngOnInit() {

    }

}
