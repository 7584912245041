import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { LoanFilesService } from './loan-files.service';
import { SpinnerService } from '../../../../@ccmc/services/spinner.service';
import { CCMCConfirmDialogComponent } from '../../../../@ccmc/components/confirm-dialog/confirm-dialog.component';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { ActiveLosService } from 'src/@ccmc/services/active-los.service';
import { WriteLoggingService } from 'src/@ccmc/services/write-logging.service';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { ErrorDialogComponent } from 'src/@ccmc/components/error-dialog/error-dialog.component';

@Component({
  selector: 'app-loan-files',
  templateUrl: './loan-files.component.html',
  styleUrls: ['./loan-files.component.scss']
})
export class LoanFilesComponent implements OnInit {
  assetID: any;
  los: any;
  loans: any;
  displayedColumns = ['name', 'loanNumber', 'taxId', 'date'];
  dataSource: any;
  selectedLoan: any;
  showSpinner: boolean;
  private spinnerSub: Subscription;
  unsubscribe: Subject<any> = new Subject();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  isOrigenate: any;
  acceptedFileList = [
    'bat',
    'bin',
    'csv',
    'dat',
    'dll',
    'doc',
    'docx',
    'if3',
    'jpeg',
    'jpg',
    'json',
    'key',
    'log',
    'lst',
    'mdb',
    'msg',
    'pdb',
    'pdf',
    'png',
    'reg',
    'sql',
    'svclog',
    'trc',
    'txt',
    'xls',
    'xlsx',
    'xml',
    'zip'
  ];

  constructor(
    private loanFilesService: LoanFilesService,
    private spinnerService: SpinnerService,
    private dialog: MatDialog,
    private matSnackBark: MatSnackBar,
    private snackbarService: SnackbarService,
    private assetService: AssetService,
    private activeLosService: ActiveLosService,
    private writeLoggingService: WriteLoggingService,
    private amplifyService: AmplifyService
  ) {}

  ngOnInit() {
    this.spinnerService.setShowSpinner(true);
    this.assetID = this.assetService.getSelectedAssetId();
    this.los = this.activeLosService.activeLos;
    this.los = this.los + '-loans';
    console.log(this.los);

    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        // console.log(spinner);
        if (this.dialog.openDialogs.length === 0) {
          this.showSpinner = spinner;
        }
      });

    this.getLoans();
    this.isOrigenate = this.activeLosService.activeLos;
    console.log(this.isOrigenate);
  }

  /**
 * Apply Filter
 * @description filter the loan data according to user input
 * @param {string} filterValue
 * @memberof CCMCFileSelectorComponent
 */
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  getLoans() {
    this.spinnerService.setShowSpinner(true);
    console.log(this.los);
    this.loanFilesService
      .s3ListFiles(this.assetID, this.los)
      .subscribe(result => {
        this.spinnerService.setShowSpinner(false);
        console.log(result);
        const parsedResult = JSON.parse(JSON.stringify(result));
        if (parsedResult.statusCode === 200) {
          this.loans = parsedResult.body;
          this.dataSource = new MatTableDataSource(this.loans);
          this.selectLoan(this.dataSource.data[0]);
          this.dataSource.sort = this.sort;
        }
        console.log(this.loans);
      });
  }

  refreshLoanFiles() {
    this.getLoans();
  }

  rotate(event: any) {
    event.srcElement.classList.remove('rotate');
    setTimeout(() => {
      event.srcElement.classList.add('rotate');
    }, 0);
  }

  selectLoan(row: any) {
    this.selectedLoan = row;
  }

  downloadFile() {
    const params = {
      bucket: this.los,
      key: this.selectedLoan.fileName
    };
    console.log(params);
    this.loanFilesService.s3GetFile(params).subscribe(result => {
      console.log(result);
      let resulty = result;
      if (typeof resulty !== 'string') {
        resulty = JSON.stringify(result);
      }
      const blob = new Blob([resulty as BlobPart], {});
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = params.key;
      a.click();
    });
  }

  deleteFile() {
    const confirmDialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
      data: 'Are you sure you want to delete this loan file?'
    });
    confirmDialogRef.afterClosed().subscribe(data => {
      if (data) {
        console.log(this.selectedLoan.fileName);
        this.loanFilesService
          .s3DeleteFile(this.los, this.selectedLoan.fileName, 'user-deletion')
          .subscribe(result => {
            console.log(result);
            this.refreshLoanFiles();
            this.snackbarService.openSnackBar(
              'Successfully deleted file',
              'Okay'
            );
          });
          const assetID = this.assetService.getSelectedAssetId();
          const stream = assetID;
          this.writeLoggingService.setStream(stream);
          this.writeLoggingService
            .writeLog({
              username: this.amplifyService.username,
              action: 'Loan Deleted',
              time: new Date().toISOString(),
              log : `Loan: ${this.selectedLoan.loanNumber} has been deleted.`
              })
            .subscribe(result => {
        // logging result
                console.log(result);
            });
      }
    });
  }

  uploadFile(event: any) {
    if (event.target.files.length == 0) {
      console.log('No file selected');
      return;
    } else {
      // Loop through files selected and upload each one
      for (let i = 0; i < event.target.files.length; i++) {
        let file: File = event.target.files[i];
        const params = {
          bucket: this.los,
          key: `${this.assetID}/${file.name}`,
          file: file
        };

        const splitName = params.file.name.split('.');
        const type = splitName[splitName.length - 1].toLowerCase();
        let acceptedFileTypeFlag = false;
        for (let i = 0; i < this.acceptedFileList.length; i++) {
          if (type.includes(this.acceptedFileList[i])) {
            acceptedFileTypeFlag = true;
          }
        }

        const reader = new FileReader();

        // Start reading the blob as text.
        reader.readAsText(file);

        // This fires after the blob has been read/loaded.
        reader.addEventListener('loadend', (e: any) => {
          const text = e.srcElement.result;
          const param = {
            Bucket: params.bucket,
            Key: params.key,
            Body: text,
            ContentType: type,
            assetID: this.assetService.getSelectedAssetId()
          };

          this.loanFilesService.uploadS3File(param).subscribe(result => {
            const parsedResult = JSON.parse(JSON.stringify(result));
            console.log('Upload loan file result', parsedResult);
            if (parsedResult.statusFlag === true) {
              this.getLoans();
              this.matSnackBark.open(`Successfully Uploaded Loan(s)`, 'Okay');
            } else if (parsedResult.statusFlag === false) {
              this.dialog.open(ErrorDialogComponent, {
                data: {
                  title: 'Error',
                  message: parsedResult.statusMessage
                }
              });
            } else {
              this.dialog.open(ErrorDialogComponent, {
                data: {
                  title: 'Error',
                  message: 'Upload Failed'
                }
              });
            }
          });
        });
      }
    }
  }

  uploadFileCallback(error: any, result: any) {
    if (result) {
      this.snackbarService.openSnackBar('Upload Successful', 'Okay');
      this.refreshLoanFiles();
    }
    if (error) {
      this.matSnackBark.open(error, 'Okay');
    }
  }
}
