import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { fromEvent, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil
} from 'rxjs/operators';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';

@Component({
  selector: 'app-field-options',
  templateUrl: './field-options.component.html',
  styleUrls: ['./field-options.component.scss']
})
export class FieldOptionsComponent implements OnInit, OnDestroy {
  private documentCoreSub: Subscription;
  unsubscribe: Subject<any> = new Subject();
  mapping: any;
  displayedColumns = ['fieldID', 'transactionName', 'fieldDisplay', 'edited'];
  @ViewChild('filterSearch', { static: true }) filterSearchEl: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  private fieldEditSub: Subscription;
  private selectedSub: Subscription;
  fieldEdited: any;
  public selected: any;
  dataSource: any;
  filterSearch: string;
  tempDocument: any;
  transactions: any;
  showSpinner: boolean;
  private spinnerSub: Subscription;
  public localSelected: any;
  public selectedFilter: any;
  constructor(
    private adminApiService: AdminApiService,
    private selectedFieldService: CCMCSelectedFieldService,
    private fieldEditedService: FieldEditedService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit() {
    this.selectedFilter = 'all';
    this.getData();
    this.filterSearchEl.nativeElement.focus();
    this.subscribeToSpinner();
    fromEvent(this.filterSearchEl.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // Time in milliseconds between key events
        debounceTime(1000),
        // If previous query is diffent from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((text: string) => {
        this.applyFilter(text);
      });

    this.onSelect(this.dataSource.data[0]);
  }

  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  getData() {
    this.selectedSub = this.selectedFieldService.onMappingFieldSelected
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(selected => {
        console.log(selected);

        if (selected) {
          this.selected = selected;
          console.log(this.selected);
          this.localSelected = {
            fieldID: this.selected.fieldID
          };
        }
      });
    // Subscribe to the mapping object
    this.documentCoreSub = this.adminApiService.documentCore
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(documentCore => {
        if (documentCore) {
          this.tempDocument = documentCore;
          if (documentCore.mapping) {
            this.mapping = documentCore.mapping;
            this.transactions = documentCore.transactions;
            if (this.mapping.length === 0) {
              return;
            }
            // set transactionName
            for (let mappingItem of this.mapping) {
              let transactionId = mappingItem.transactionID;
              let transactionIndex = this.transactions.findIndex(
                (tran: any) => transactionId === tran.transactionID
              );
              if (transactionIndex > -1) {
                mappingItem.transactionName =
                  this.transactions[transactionIndex].transactionName;
              }
            }
            // assign data source as mapping
            this.dataSource = new MatTableDataSource(this.mapping);
            // initializes pagination
            this.dataSource.paginator = this.paginator;
            // initializes sort
            this.dataSource.sort = this.sort;
            // initializes first index as selected
            console.log('selected ', this.selected);
            if (this.selected === null || this.selected === undefined) {
              console.log('select 0 mapping');
              console.log(documentCore.mapping[0]);
              this.selectedFieldService.onMappingFieldSelected.next(
                documentCore.mapping[0]
              );
            }
          } else {
            this.mapping = undefined;
          }
        }
      });

    this.fieldEditSub = this.fieldEditedService.fieldEdited
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(edited => {
        this.fieldEdited = edited;
      });
  }

  subscribeToSpinner() {
    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        this.showSpinner = spinner;
      });
  }

  changeFilterPredicate(predicate: string) {
    // console.log(predicate);
    if (predicate === 'all') {
      // tslint:disable-next-line: no-shadowed-variable
      this.dataSource.filterPredicate = function (
        data: any,
        filter: string
      ): boolean {
        // tslint:disable-next-line: max-line-length
        return (
          data.fieldID.toLowerCase().includes(filter) ||
          data.fieldDisplay.toLowerCase().includes(filter) ||
          data.transactionName.toLowerCase().includes(filter)
        );
      };
    } else if (predicate === 'fieldID') {
      // tslint:disable-next-line: no-shadowed-variable
      this.dataSource.filterPredicate = function (
        data: any,
        filter: string
      ): boolean {
        return data.fieldID.toLowerCase().includes(filter);
      };
    } else if (predicate === 'fieldDisplay') {
      // tslint:disable-next-line: no-shadowed-variable
      this.dataSource.filterPredicate = function (
        data: any,
        filter: string
      ): boolean {
        return data.fieldDisplay.toLowerCase().includes(filter);
      };
    } else if (predicate === 'transactionName') {
      // tslint:disable-next-line: no-shadowed-variable
      this.dataSource.filterPredicate = function (
        data: any,
        filter: string
      ): boolean {
        return data.transactionName.toLowerCase().includes(filter);
      };
    }
  }
  onSelect(selected: any) {
    this.localSelected.rowColor = this.selected.rowColor;
    this.selected = selected;
    this.selectedFieldService.onMappingFieldSelected.next(selected);
  }
  applyFilter(filterValue: string) {
    this.dataSource.data = this.mapping;
    if (filterValue.includes('*')) {
      const data = this.dataSource.data;
      const filteredArray = data.filter(
        (obj: any) =>
          obj.fieldID.includes(filterValue.split('*')[0]) &&
          obj.fieldID.includes(filterValue.split('*')[1])
      );
      this.dataSource.filteredData = filteredArray;
      this.dataSource.data = filteredArray;
    } else if (filterValue.length > 0) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    if (this.dataSource.filteredData[0]) {
      this.selectedFieldService.onMappingFieldSelected.next(
        this.dataSource.filteredData[0]
      );
    }
    this.filterSearch = filterValue;
  }
}
