import { Component, OnInit, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../../../../@ccmc/components/error-dialog/error-dialog.component';
import { ErrorDialogModal } from '../../../../@ccmc/models/errorDialog.model';
import { passwordMatchValidator } from './validate-passwords.validators';
import { Router } from '@angular/router';
import { SuccessDialogComponent } from '../../../../@ccmc/components/success-dialog/success-dialog.component';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';

@Component({
  selector: 'ccmc-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class CCMCChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  oldPasswordType = 'password';
  newPasswordType = 'password';
  confNewPasswordType = 'password';
  oldPasswordVisibility = 'visibility';
  newPasswordVisibility = 'visibility';
  confNewPasswordVisibility = 'visibility';
  showSpinner = false;
  updateBtnDisabled = false;
  hideFields1 = true;
  hideFields2 = true;
  hideFields3 = true;

  constructor(
    private fb: FormBuilder,
    private amplifyService: AmplifyService,
    private router: Router,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<CCMCChangePasswordComponent>
  ) {}

  ngOnInit() {
    this.changePasswordForm = this.fb.group(
      {
        oldPassword: ['', Validators.required],
        newPassword: ['', Validators.required],
        confNewPassword: ['', Validators.required]
      },
      { validators: passwordMatchValidator }
    );
  }

  /**
   * @description handles change password form submission
   */
  changePassword() {
    if (this.changePasswordForm.status === 'VALID') {
      this.showSpinner = true;
      this.updateBtnDisabled = true;
      this.amplifyService.changePassword(
        this.changePasswordForm.controls['oldPassword'].value,
        this.changePasswordForm.controls['newPassword'].value,
        this
      );
    }
  }

  /**
   * @description handles change password error response
   */
  cognitoCallback(error: any) {
    this.showSpinner = false;
    if (error && error.message) {
      this.updateBtnDisabled = false;
      const errorData: ErrorDialogModal = {
        message: error.message,
        title: 'Change Password Error'
      };
      this.matDialog
        .open(ErrorDialogComponent, {
          data: errorData
        })
        .afterClosed()
        .subscribe(
          response => {},
          err => console.log(err)
        );
    }
    this.changePasswordForm.reset({
      oldPassword: '',
      newPassword: '',
      confNewPassword: ''
    });
  }

  /**
   * @description handles change password successful response
   */
  passwordUpdated() {
    const errorData: ErrorDialogModal = {
      message: 'Password Successfully Updated',
      title: 'Change Password'
    };
    const dialogRef = this.matDialog
      .open(SuccessDialogComponent, {
        data: errorData
      })
      .afterClosed()
      .subscribe(
        response => {
          this.dialogRef.close();
          
        },
        err => console.log(err)
      );
  }

  /**
   * @description handles visibility for the password and confirm password fields
   */
  changePasswordVisibility(input: HTMLInputElement) {
    switch (input.name) {
      case 'oldPassword':
        this.oldPasswordVisibility =
          this.oldPasswordVisibility === 'visibility'
            ? 'visibility_off'
            : 'visibility';
        this.oldPasswordType =
          this.oldPasswordType === 'text' ? 'password' : 'text';
        break;
      case 'newPassword':
        this.newPasswordVisibility =
          this.newPasswordVisibility === 'visibility'
            ? 'visibility_off'
            : 'visibility';
        this.newPasswordType =
          this.newPasswordType === 'text' ? 'password' : 'text';
        break;
      case 'confNewPassword':
        this.confNewPasswordVisibility =
          this.confNewPasswordVisibility === 'visibility'
            ? 'visibility_off'
            : 'visibility';
        this.confNewPasswordType =
          this.confNewPasswordType === 'text' ? 'password' : 'text';
        break;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
