import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { CcmcApiService } from './ccmc-api.service';
import { environment } from 'src/environments/environment';
const { Buffer } = require('buffer');
@Injectable({
  providedIn: 'root'
})
export class WriteLoggingService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  currentLogStream: any;
  constructor(
    private http: HttpClient,
    private customErrorHandlerService: CustomErrorHandlerService,
    private ccmcApiService: CcmcApiService
  ) {
    this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, PUT'
    );
    this.httpOptions.headers.append('Accept', 'application/json');
  }

  /**
   * Set stream
   * @description sets the currenng log stream
   * @param {*} stream
   * @memberof WriteLoggingService
   */
  setStream(stream: any) {
    this.currentLogStream = stream;
  }

  /**
   * Write log
   * @description writes log to the curent log stream
   * @param {*} log
   * @returns
   * @memberof WriteLoggingService
   */
  writeLog(log: any) {
    const params = {
      logStream: this.currentLogStream,
      logGroupName: 'gcadmininternal',
      log: log
    };
    
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/cloudwatch/write-to-log-stream`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  writeUserLog(log: any) {
    const params = {
      logStream: this.currentLogStream,
      logGroupName: 'gcuserlogs',
      log: log
    };
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/cloudwatch/write-to-log-stream`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }
}
