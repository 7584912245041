<div
  style="
    display: grid;
    grid-template-columns: 1fr 3fr;
    width: 100%;
    background-color: #ececec;
  "
>
  <div style="border-right: 1px solid gray">
    <h1 style="margin: 30px; text-align: center">Profile Information</h1>
    <div
      style="
        display: grid;
        grid-template-rows: 2fr 1fr 1fr 1fr -1fr 1fr 1fr 1fr;
        margin: 30px;
      "
    >
      <div class="user-profile-image">
        <!--Avatar-->
        <!-- <img
          class="user-profile-image--container--image"
          *ngIf="
            isClientAdmin ||
            isNXTsoftDev ||
            isNXTsoftOperations ||
            isNXTsoftSales ||
            isSuperAdmin
          "
          src="assets/images/avatars/admin_user_profile1.png"
        /> -->
        <i *ngIf="userIcon === 'user'" class="fa-solid fa-user user-profile-icon--container--icon"></i>
        <i *ngIf="userIcon === 'key'" class="fa-solid fa-key user-profile-icon--container--icon"></i>
        <i *ngIf="userIcon === 'cog'" style="font-size: 90px;" class="fa-solid fa-user-cog user-profile-icon--container--icon"></i>
        <i *ngIf="userIcon === 'pen'" style="font-size: 90px;" class="fa-solid fa-user-pen user-profile-icon--container--icon"></i>
        <i *ngIf="userIcon === 'rocket'" style="line-height: 150px; font-size: 108px;" class="fa-solid fa-rocket user-profile-icon--container--icon"></i>
        <img
          class="user-profile-image--container--image"
          *ngIf="
            !isClientAdmin &&
            !isNXTsoftDev &&
            !isNXTsoftOperations &&
            !isNXTsoftSales &&
            !isSuperAdmin && 
            !isLoanServicingAdmin && 
            !isLoanSystemsAdmin
          "
          src="assets/images/avatars/{{ avatar }}.png"
        />
      </div>
      <div
        *ngIf="
          !isNXTsoftDev &&
          !isNXTsoftOperations &&
          !isClientAdmin &&
          !isNXTsoftSales &&
          !isSuperAdmin && 
          !isLoanServicingAdmin && 
          !isLoanSystemsAdmin
        "
        style="text-align: center"
      >
        <button
          *ngIf="
            !isNXTsoftDev &&
            !isNXTsoftOperations &&
            !isClientAdmin &&
            !isNXTsoftSales &&
            !isSuperAdmin && 
            !isLoanServicingAdmin && 
            !isLoanSystemsAdmin
          "
          mat-stroked-button
          style="
            width: 30%;
            margin: auto;
            border: 1px solid;
            color: #3fb2c6;
            margin-top: 3%;
          "
          (click)="editAvatar()"
        >
          Edit Image
        </button>
      </div>
      <mat-form-field style="margin-top: 5%">
        <input
          class="inputField"
          matInput
          [(ngModel)]="user.firstName"
          placeholder="First Name"
          style="margin-top: -5px"
        />
      </mat-form-field>
      <mat-form-field style="margin-top: 5%">
        <input
          class="inputField"
          matInput
          [(ngModel)]="user.lastName"
          placeholder="Last Name"
          style="margin-top: -5px"
        />
      </mat-form-field>
      <mat-form-field style="margin-top: 5%">
        <input
          class="inputField"
          matInput
          [(ngModel)]="user.username"
          placeholder="Email"
          style="margin-top: -5px"
        />
      </mat-form-field>
      <!--<mat-form-field>
                <input class="inputField" matInput [(ngModel)]="user.password" (ngModelChange)="profileEdited()" placeholder="Password" style="margin-top: -5px" />
            </mat-form-field> -->
      <button
        (click)="openChangePassword()"
        mat-stroked-button
        class="passwordBtn"
      >
        CHANGE PASSWORD
      </button>
      <hr style="width: 100%" />
      <h1 class="additionalSettingHeader">Additional Settings</h1>
      <mat-slide-toggle
        class="mfaToggle"
        [(ngModel)]="mfaToggle"
        (change)="onClickSwitch()"
        >Multi-Factor Authentication</mat-slide-toggle
      >
      <button
        *ngIf="mfaToggle"
        (click)="onClickSwitch()"
        mat-stroked-button
        class="resetButton"
      >
        <mat-icon>settings</mat-icon> Reset Authentication
      </button>
    </div>
  </div>
  <div style="grid-template-rows: 1fr 1fr 1fr; row-gap: 15px;border-bottom: 1px solid gray;height: 40%;">
    <div style="margin: 30px;">
      <h1 style="margin-top: -1px">Company Information</h1>
      <div
        style="
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          column-gap: 30px;
        "
      >
        <div
          style="display: grid; grid-template-rows: 1fr 1fr 1fr; row-gap: 15px"
          *ngIf="company"
        >
          <mat-form-field>
            <input
              class="inputField"
              matInput
              [(ngModel)]="company.FIName"
              placeholder="Company"
              style="margin-top: -5px"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              class="inputField"
              matInput
              [(ngModel)]="company.fiClassification"
              placeholder="Classification"
              style="margin-top: -5px"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              class="inputField"
              matInput
              [(ngModel)]="company.totalAssets"
              placeholder="Total Assets"
              style="margin-top: -5px"
            />
          </mat-form-field>
        </div>
        <div
          style="display: grid; grid-template-rows: 1fr 1fr 1fr; row-gap: 15px"
        >
          <mat-form-field>
            <input
              class="inputField"
              matInput
              [(ngModel)]="company.fiStatus"
              placeholder="Status"
              style="margin-top: -5px"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              class="inputField"
              matInput
              [(ngModel)]="company.federalInsurer"
              placeholder="FDIC/CUNA Number"
              style="margin-top: -5px"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              class="inputField"
              matInput
              [(ngModel)]="company.billingAddress"
              placeholder="Address"
              style="margin-top: -5px"
            />
          </mat-form-field>
        </div>
        <div
          style="display: grid; grid-template-rows: 1fr 1fr 1fr; row-gap: 15px"
        >
          <mat-form-field>
            <input
              class="inputField"
              matInput
              [(ngModel)]="company.phoneNumber"
              placeholder="Phone"
              style="margin-top: -5px"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              class="inputField"
              matInput
              [(ngModel)]="company.website"
              placeholder="Website"
              style="margin-top: -5px"
            />
          </mat-form-field>
          <div
            style="
              display: grid;
              grid-template-columns: 1fr 1fr;
              column-gap: 30px;
            "
          >
            <mat-form-field>
              <input
                class="inputField"
                matInput
                [(ngModel)]="company.cityState"
                placeholder="City, State"
                style="margin-top: -5px"
              />
            </mat-form-field>
            <mat-form-field>
              <input
                class="inputField"
                matInput
                [(ngModel)]="company.zipCode"
                placeholder="Zip Code"
                style="margin-top: -5px"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="this.activeCore === 'premier' || this.activeCore === 'ibs' || this.activeCore === 'miser'" class="target-creds-container">
      <div class="title-container">
      <h1 style="margin: 30px;">Target Credentials</h1>
      </div>
      <div style="margin-left: 7%;" *ngIf="variableKeys">
        <form>
        <div *ngFor="let variable of variableKeys; let i = index">
          <div
            *ngIf="
              !variable.toLowerCase().includes('password') &&
              !variable.toLowerCase().includes('cognitoid') &&
              !variable.toLowerCase().includes('cashboxnumber') &&
              !variable.toLowerCase().includes('hostbranchnumber')
            "
          >
            <mat-form-field style="width:90%">
              <input 
                matInput
                placeholder="{{ variable }}"
                [(ngModel)]="variables[variable]"
              (ngModelChange)="fieldEdited()"
                name = "{{ variable }}"
              />
            </mat-form-field>
          </div>
          <div *ngIf="variable.toLowerCase().includes('password')">
            <mat-form-field style="width:90%">
              <input
                matInput
                [type]="hideFields[i] ? 'password' : 'text'"
                placeholder="{{ variable }}"
                [(ngModel)]="variables[variable]"
                (ngModelChange)="fieldEdited()"
                autocomplete="new-password"
                name = "{{ variable }}"
              />
              <mat-icon matSuffix (click)="hideFields[i] = !hideFields[i]">{{
                hideFields[i] ? 'visibility_off' : 'visibility'
              }}</mat-icon>
            </mat-form-field>
            </div>
          </div>
        </form>
          <div class="Btn-container">
            <button
              mat-raised-button
              matTooltip="Update the variables."
              [disabled]="!editedFlag"
              matTooltipClass="toolTipStyle"
              color="accent"
              (click)="updateVariables()"
              class="saveBtnTop"
            >
              Update
            </button>
            </div>
        </div>
    </div>
    <div *ngIf="this.activeCore === 'spectrum'" class="target-creds-container">
      <div class="title-container">
      <h1 style="margin: 30px;">Target Credentials</h1>
      </div>
      <div style="margin-left: 7%;" *ngIf="variableKeys">
        <form>
        <div *ngFor="let variable of variableKeys; let i = index">
          <div
            *ngIf="
              !variable.toLowerCase().includes('password') &&
              !variable.toLowerCase().includes('cognitoid') &&
              !variable.toLowerCase().includes('cashboxnumber') &&
              !variable.toLowerCase().includes('hostbranchnumber')
            "
          >
            <mat-form-field style="width:90%">
              <input 
                matInput
                placeholder="{{ variable }}"
                [(ngModel)]="variables[variable]"
              (ngModelChange)="fieldEdited()"
                name = "{{ variable }}"
              />
            </mat-form-field>
          </div>
          </div>
        </form>
        <div class="Btn-container">
          <button
            mat-raised-button
            matTooltip="Update the variables."
            [disabled]="!editedFlag"
            matTooltipClass="toolTipStyle"
            color="accent"
            (click)="updateVariables()"
            class="saveBtnTop"
          >
            Update
          </button>
          </div>
        </div>
    </div>
    <!-- <div style="text-align: right; margin-top: 30px">
      <button mat-raised-button color="accent" style="width: 150px">
        Update
      </button>
    </div> -->
  </div>
  
</div>
