import { Component, OnInit } from '@angular/core';
import { ReadLoggingService } from '../logging/read-logging.service';

@Component({
  selector: 'app-logging',
  templateUrl: './logging.component.html',
  styleUrls: ['./logging.component.scss']
})
export class LoggingComponent implements OnInit {

  loggingGroup: any;

  constructor(
    private readLoggingService: ReadLoggingService,
  ) {
    this.readLoggingService.selectedLoggingGroup.subscribe(loggingGroup => {
      this.loggingGroup = loggingGroup;
    });
  }

  ngOnInit(): void {
  }

}
