<h1 style="padding-left: 3%;">Edit Validation Group</h1>
<div>
    <div class="top-section">
        <mat-form-field id="selectedValidationGroup" style="width: 25%;margin-top: 2%" [ngStyle]="{'background-color': data.selectedValidationGroup === 'all' ? '#ffffff' : '#E6E6E6'}">
        <mat-label>Validation Group Name</mat-label>
        <mat-select [(ngModel)]="selectedValGroup" (ngModelChange)="onDropdownChange()" [disabled]="data.selectedValidationGroup !== 'all'">
            <mat-option [value]="group" *ngFor="let group of validationGroupsArray"> {{ group }} </mat-option>
        </mat-select>
        </mat-form-field>
        <button id="deleteButton" mat-raised-button style="left: 3%;" (click)="deleteValidationGroup()" [disabled]="selectedValGroup === 'all' || validationGroupsArray.length === 0">Delete Group</button>
    </div>
    <div class="table-container" name="valuesTable">
        <table
          mat-table
          [dataSource]="dataSource"
          class="mat-elevation-z0"
          style="width: 100%"
        >
          <!--Category-->
          <ng-container matColumnDef="fieldIDs">
            <th mat-header-cell *matHeaderCellDef style="font-size: 16px; min-width: 100px; width: 500px;
        ">
              FieldID
            </th>
            <td mat-cell *matCellDef="let element" style="min-width: 100px;width: 500px;">
              <input
                [(ngModel)]="element.fieldIDs"
                name="element.fieldIDs"
                matInput
                type="search"
                style="padding: 3px; width: auto; height: 30px; width: 100%;"
                readonly
              />
            </td>
            <td mat-footer-cell *matFooterCellDef style="font-size: 16px">
                <input 
                  type="search" 
                  placeholder="Field ID" 
                  matInput
                  [(ngModel)]="tempFieldIDs" 
                  name="tempFieldIDs"
                  style="
                  padding: 3px;
                  margin-right: 10px;
                  width: 95%;
                  height: 35px;
                  background-color: white;
                "
                  />
            </td>
          </ng-container>
          <ng-container matColumnDef="edit/delete">
            <th mat-header-cell *matHeaderCellDef style="font-size: 16px">
              Edit/Delete
            </th>
            <td mat-cell *matCellDef="let element" style="font-size: 16px">
                <button
                  *ngIf="element.modified"
                  mat-icon-button
                  matTooltip="Edit selected fieldID/s" 
                  matTooltipClass="toolTipStyle"
                  (click)="editFieldIDsItem(element)"
                >
                  <mat-icon style="color: black">create</mat-icon>
                </button>
                <button
                *ngIf="element.modified"
                mat-icon-button
                matTooltip="Delete selected fieldIDs/s" 
                matTooltipClass="toolTipStyle"
                (click)="removeFieldIDsItem(element)"
              >
                <mat-icon style="color: red">delete</mat-icon>
              </button>
            </td>
            <td
            mat-footer-cell
            *matFooterCellDef
            style="font-size: 16px;display: flex;margin-top: 2%;"
          >
          <button
          mat-stroked-button
          style="background-color: white;border: 1px solid darkgray;"
        (click)="addNewFieldIDs();capFieldIDs($event)"
        ><mat-icon style="margin-left: -10px;font-size: 20px;
        ">add</mat-icon>
          Add
        </button>
        <button
          mat-raised-button
          style="background-color:#454545; 
          color:white;
          margin-left: 5%;"
          (click)="pasteNewValidations()"
        >
        <mat-icon style="margin-left: -10px;font-size: 20px;">list_alt</mat-icon>
          Paste Clipboard
        </button></td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr
            mat-footer-row
            *matFooterRowDef="displayedColumns; sticky: true"
            style="background-color: #eeeeee;"
          ></tr>
        </table>
      </div>
    <div style="margin-top: 6%;
      justify-content: space-between;
      display: flex;
      padding: 3%;">
        <button mat-stroked-button style="background-color: #F2F2F1;border: 1px solid darkgray;" (click)="closeDialog()">Cancel</button>
        <div *ngIf="selectedValGroup !== 'all' && emptyFlag === true" style="margin-left: auto">
          <div>When adding Field IDs to an empty Validation Group</div> 
          <div>they will be populated with placeholder data</div>
        </div>
        <button id="saveButton" mat-raised-button style="margin-left: 1em"
        (click)="saveFieldIDs()">Save</button>
      </div>
</div>
