import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { CCMCSharedModule } from 'src/@ccmc/shared.module';
import { DocConnectorLogsComponent } from './doc-connector-logs.component';
import { MatButtonModule } from '@angular/material/button';

const routes: Routes = [
  {
    path: 'doc-connector/logs',
    component: DocConnectorLogsComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [DocConnectorLogsComponent],
  imports: [RouterModule.forChild(routes), CCMCSharedModule, MatButtonModule],
  exports: [DocConnectorLogsComponent]
})
export class DocConnectorLogsModule {}
