import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CCMCSharedModule } from 'src/@ccmc/shared.module';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { EncompassLoanSearchComponent } from './encompass-loan-search.component';
import { AppMaterialModule } from '../../../app-material/app-material.module';
import { MatRadioModule } from '@angular/material/radio';
import { Role } from 'src/@ccmc/models/role';

const routes: Routes = [
  {
    path: 'encompass-loan-search',
    component: EncompassLoanSearchComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: {
      roles: [
        Role.ClientAdmin,
        Role.NxtsoftSales,
        Role.NxtsoftOp,
        Role.NxtsoftDev
      ]
    }
  }
];

@NgModule({
  declarations: [EncompassLoanSearchComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CCMCSharedModule,
    KeyboardShortcutsModule.forRoot(),
    AppMaterialModule,
    MatRadioModule
  ]
})
export class EncompassLoanSearchModule {}
