<div class="new-support-valuesContainer">
  <div class="moveContainer" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle></div>
  <div style="display: flex; flex-direction: row; justify-content: space-between;">
    <div>
      <h1>New Support Values</h1>
    </div>
    <div>
      <h1>
        <i class="material-icons noselect" (click)="closeDialog()">
          close
        </i>
      </h1>
    </div>
  </div>
  <div class="formContainer">
    <div>
      <mat-form-field style="padding-right: 30px">
        <input matInput #categoryInput placeholder="category" [(ngModel)]="tempSupportValue.category"
          (keyup.enter)="addSupportValue()">
      </mat-form-field>
      <mat-form-field style="padding-right: 30px">
        <input matInput #sourceInput placeholder="source" [(ngModel)]="tempSupportValue.source"
          (keyup.enter)="addSupportValue()">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput #valueInput placeholder="value" [(ngModel)]="tempSupportValue.value"
          (keyup.enter)="addSupportValue()">
      </mat-form-field>
    </div>

  </div>

  <div class="Btn-Container">
    <button class="cancelBtn-SupportValues" mat-stroked-button (click)="closeDialog()">Cancel</button>

    <button class="add-support-valueBtn" mat-raised-button (click)="addSupportValue()">
      <mat-icon style="color:#3FB2C6;">add_circle</mat-icon> &nbsp; Add Support Value
    </button>
  </div>


</div>