<div class="new-translator-container">
  <div
    class="moveContainer"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  ></div>
  <div class="titleRow">
    <div>
      <h1>New Translators</h1>
    </div>
    <div>
      <h1>
        <i class="material-icons noselect" (click)="closeDialog()"> close </i>
      </h1>
    </div>
  </div>
  <div class="form-container">
    <div class="field-container">
      <mat-form-field class="form-field1">
        <input
          matInput
          #fieldNameInput
          placeholder="Field Name"
          [(ngModel)]="tempTranslator.fieldName"
          (keydown.enter)="addTranslator()"
        />
      </mat-form-field>
      <mat-form-field class="form-field2" style="height: 50px">
        <mat-label>Evaluation Method</mat-label>
        <mat-select
          [(value)]="tempTranslator.evaluationMethod"
          (keydown.enter)="addTranslator()"
        >
          <mat-option value="path">Path</mat-option>
          <mat-option value="code">Code</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="form-field3">
        <input
          matInput
          #multBeginInput
          placeholder="Mult Begin"
          [(ngModel)]="tempTranslator.multBegin"
          (keydown.enter)="addTranslator()"
        />
      </mat-form-field>
      <mat-form-field class="form-field4">
        <input
          matInput
          #multEndInput
          placeholder="Mult End"
          [(ngModel)]="tempTranslator.multEnd"
          (keydown.enter)="addTranslator()"
        />
      </mat-form-field>
    </div>
    <mat-form-field style="width: 100%">
      <mat-label style="padding: 5px">VBScript</mat-label>
      <textarea
        matInput
        (keydown.enter)="addTranslator()"
        [(ngModel)]="tempTranslator.vbScript"
        class="VBScript-textarea"
        placeholder="Empty VBscript will set evaluation method to 'path'..."
      ></textarea>
    </mat-form-field>
    <div class="btn-Container">
      <button class="cancelBtn" mat-stroked-button (click)="closeDialog()">
        Cancel
      </button>
      <button class="addBtn" mat-raised-button (click)="addTranslator()">
        <mat-icon style="color: #3fb2c6">add_circle</mat-icon> &nbsp;Add
        Translator
      </button>
    </div>
  </div>
</div>
