import { Component, OnInit, OnDestroy, NgZone, ViewChild } from '@angular/core';
import { GCSettingsService } from '../../../../@ccmc/services/gcsettings.service';
import { Subscription, of, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CCMCConfigService } from '../../../../@ccmc/services/config.service';
import { ActiveCoreService } from '../../../../@ccmc/services/active-core.service';
import { ActiveLosService } from '../../../../@ccmc/services/active-los.service';
import { FormControl } from '@angular/forms';
import {
  navigation,
  adminNavigation,
  glaNavigation,
  docConnectorNavigation
} from '../../../../app/navigation/navigation';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  takeUntil
} from 'rxjs/operators';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { AuthService } from 'src/app/auth/auth.service';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { EncompassService } from 'src/@ccmc/services/encompass.service';
import { CCMCNavigationService } from 'src/@ccmc/components/navigation/navigation.service';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { WriteLoggingService } from 'src/@ccmc/services/write-logging.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { GeneralLedgerAccountingService } from 'src/@ccmc/services/general-ledger-accounting.service';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { RequestChangeService } from 'src/@ccmc/services/request-change.service';
import { MatTableDataSource } from '@angular/material/table';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { ErrorDialogComponent } from 'src/@ccmc/components/error-dialog/error-dialog.component';
import { SuccessDialogComponent } from 'src/@ccmc/components/success-dialog/success-dialog.component';
import { AssetSelectorEditDialogComponent } from 'src/@ccmc/components/asset-selector-edit-dialog/asset-selector-edit-dialog.component';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { DocumentConnectorService } from 'src/@ccmc/services/doc-connector.service';
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'src/environments/environment';
import { MatPaginator } from '@angular/material/paginator';
@Component({
  selector: 'ccmc-assetid-selector',
  templateUrl: './assetid-selector.component.html',
  styleUrls: ['./assetid-selector.component.scss']
})
export class CCMCAssetidSelectorComponent implements OnInit, OnDestroy {
  showDelay = new FormControl(1200);
  settings: Array<any> = [];
  assetIdSettingsSubscription: Subscription;
  filteredSettings: any;
  dynamicNav: any = navigation;
  dynamicAdminNav: any = adminNavigation;
  dynamicGLANav: any = glaNavigation;
  dynamicDCNav: any = docConnectorNavigation;
  coreOptions: any[] = [];
  userSubscription: Subscription;
  username: any;
  isNXTsoft = false;
  changeRequestFieldsSub: Subscription;
  changeRequestStatusesSub: Subscription;
  docFields: any;
  unsubscribe: Subject<any> = new Subject();
  docStatuses: any;
  dataSource: any;
  fullDataSourceTable: MatTableDataSource<unknown>;
  fullDataSourceArray: any[] = [];
  showSpinner: boolean;
  displayedColumns = [
    'select',
    'assetId',
    'product',
    'FI',
    'los',
    'core',
    'modifiedByUser',
    'dateModified'
  ];
  selected: any;
  innerWidth: number;
  pinnedAssets: any[] = [];
  pinnedErrors: any[];
  private spinnerSub: Subscription;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  isNXTsoftDev = false;
  isNXTsoftOperations = false;
  isNXTsoftSales = false;
  isAdmin = false;
  isSuperAdmin = false;
  isLoanServicingAdmin = false;
  isLoanSystemsAdmin = false;
  filter: string;
  filterChanged: Subject<string> = new Subject<string>();
  assetIdsToPass: any;
  configurations = {};
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /**
   * Creates an instance of CCMCAssetidSelectorComponent.
   * @param {GCSettingsService} gcSettingsService
   * @param {Router} router
   * @param {CCMCConfigService} ccmcConfig
   * @param {ActiveCoreService} activeCoreService
   * @param {ActiveLosService} activeLosService
   * @param {AssetService} assetService
   * @param {AuthService} authService
   * @param {CcmcApiService} ccmcApiService
   * @param {AdminApiService} adminApiService
   * @param {CCMCNavigationService} navigationService
   * @param {NgZone} zone
   * @param {WriteLoggingService} writeLoggingService
   * @param {SnackbarService} snackBarService
   * @param {EncompassService} encompassService
   * @memberof CCMCAssetidSelectorComponent
   */
  constructor(
    private gcSettingsService: GCSettingsService,
    private router: Router,
    private ccmcConfig: CCMCConfigService,
    private activeCoreService: ActiveCoreService,
    private activeLosService: ActiveLosService,
    private assetService: AssetService,
    private amplifyService: AmplifyService,
    private authService: AuthService,
    private ccmcApiService: CcmcApiService,
    private fieldEditedService: FieldEditedService,
    private requestChangeService: RequestChangeService,
    private adminApiService: AdminApiService,
    private navigationService: CCMCNavigationService,
    private spinnerService: SpinnerService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private zone: NgZone,
    private writeLoggingService: WriteLoggingService,
    private snackBarService: SnackbarService,
    private glaService: GeneralLedgerAccountingService,
    private encompassService: EncompassService,
    private dcService: DocumentConnectorService
  ) {
    this.ccmcConfig.setConfig({
      layout: {
        navigation: 'none',
        toolbar: 'none',
        footer: 'none'
      }
    });

    this.filterChanged
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(filter => {
        if (this.isNXTsoftDev || this.isNXTsoftOperations) {
          this.applyTotalAssetFilter(filter);
        } else {
          this.applyFilter(filter);
        }
      });

    window.addEventListener('resize', this.selectedAssetRow);
  }

  /**
   * On Init
   *
   * @memberof CCMCAssetidSelectorComponent
   */
  async ngOnInit() {
    this.spinnerService.setShowSpinner(true);
    this.isNXTsoft = this.amplifyService.isNXTsoft;
    this.isNXTsoftDev = this.amplifyService.isNXTsoftDev;
    this.isNXTsoftOperations = this.amplifyService.isNXTsoftOperations;
    this.isNXTsoftSales = this.amplifyService.isNXTsoftSales;
    this.isAdmin = this.amplifyService.isAdmin;
    this.isSuperAdmin = this.amplifyService.isSuperAdmin;
    this.isLoanServicingAdmin = this.amplifyService.isLoanServicingAdmin;
    this.isLoanSystemsAdmin = this.amplifyService.isLoanSystemsAdmin;
    this.retrieveAssetIds();
    // Set default navigation object
    this.navigationService.onNavChange.next('default');
    this.navigationService.turnGLANavItemsOff();
    // Clear selected asset on entry
    this.clearOutSelectedFields();
    this.clearSelectedAsset();
    this.loadTableValues();
    this.navigationService.resetNavigation();

    // Subscribe to the spinner sub
    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        // console.log(spinner);
        if (this.dialog.openDialogs.length === 0) {
          this.showSpinner = spinner;
        }
      });

    this.username = await this.amplifyService.getUserName();
    this.amplifyService.resetCookies();
    this.amplifyService.getCurrentUser();
  }

  /**
   * Retrieve AssetIds
   * @description retrieves and parses the assetIds
   * @memberof CCMCAssetidSelectorComponent
   */
  retrieveAssetIds() {
    if (this.assetService.getAssetIds()) {
      this.pinnedAssets = [];
      this.pinnedErrors = [];
      const assets = this.assetService.getAssetIds().forEach((assetId: any) => {
        this.pinnedAssets.push(assetId);
        return this.retrieveAssetIdSettings(assetId);
      });
      this.dataSource = new MatTableDataSource([]);
      // this.pinnedErrors = this.pinnedAssets;
    }
    console.log(this.assetService.getAssetIds());
  }

  /**
   * Retrieve AssetId Settings
   * @description gets the assetId, core, loan path, los, and product info
   * for the current authenticated financial institution
   * @param {*} assetId
   * @memberof CCMCAssetidSelectorComponent
   */
  retrieveAssetIdSettings(assetId: any) {
    this.assetIdSettingsSubscription = this.gcSettingsService
      .retrieveSettings(assetId)
      .pipe(filter(settings => !!settings))
      .subscribe(settings => {
        console.log(settings);
        this.settings.push({
          assetId,
          ...settings,
          icon: this.setIcon(settings.product)
        });
        this.settings.sort((a, b) => {
          if (a.FI === b.FI) {
            // filters by product if FI is the same
            return a.product > b.product ? 1 : -1;
          }
          return a.FI > b.FI ? 1 : -1;
        });
        for (let setting of this.settings) {
          setting.formattedFIName = setting.FI.split('-');
        }
        this.filteredSettings = this.settings;
        this.dataSource = new MatTableDataSource(this.filteredSettings);
        this.dataSource.sort = this.sort;
      });
  }

  /**
   * Select Asset
   * @description sets the assetId, core, product type, gets the active core
   * @param {*} setting
   * @memberof CCMCAssetidSelectorComponent
   */
  async selectAsset(setting: any) {
    this.fieldEditedService.resetAssetLoggingTable.next(true);
    // If user doesnt have asset
    let userHasAccess = await this.amplifyService.checkUserAssets(
      setting.assetId
    );
    if (!userHasAccess && !this.isNXTSoft()) {
      this.snackBarService.openSnackBar(
        'User does not have access to asset',
        'Okay'
      );
      this.logout();
    } else {
      console.log(setting);
      // Set Selected AssetId
      this.assetService.setSelectedAssetId(setting.assetId);
      // Set Active Core
      this.activeCoreService.setActiveCore(setting.core);
      // Set Active Los
      this.activeLosService.setActiveLos(setting.los);
      this.activeLosService.selectLos();
      // Set the selected Financial Institution
      this.assetService.setSelectedFI(setting.FI);
      // Set the selected loan path
      this.assetService.setSelectedPath(setting.loanPath);
      // Set the selected product
      this.assetService.setSelectedProduct(setting.product);
      // Sets the product Type for gc settings
      this.gcSettingsService.setProductType(setting.product);
      // Set if the selected has ebm
      this.assetService.setHasEBM(setting.ebm);
      // Set the selceted Core
      this.assetService.setSelectedCore(setting.core);
      // Set the selceted Core Company
      this.assetService.setSelectedCoreCompany(setting.coreCompany);
      // Set the selected LOS
      this.assetService.setSelectedLos(setting.los);
      // Set Can Capture Data
      this.assetService.setCanCaptureData(setting.captureData);
      // Loan Asset information in the navigation bar
      this.navigationService.loadAssetInformation();
      this.setChangeRequestIDs();
      // Init the log stream
      this.getChangeRequestTable();
      // this.initLogger(this.username, setting.FI);
      // Clears encompass access token for new assets
      this.clearEncompassToken(setting);
      console.log(setting);
      console.log(
        'Settings includes doc connector',
        setting.product.includes('doc_connector')
      );
      this.spinnerService.setShowSpinner(true);
      switch (setting.product !== undefined) {
        case setting.product.includes('general_ledger_accounting'):
          console.log('Do GLA Stuff here');
          this.adminApiService
            .getCoreConnection(setting.core)
            .subscribe(coreConnection => {
              console.log(coreConnection);
              if (coreConnection) {
                const parsedCoreConnection = JSON.parse(
                  JSON.stringify(coreConnection)
                );
                if (parsedCoreConnection.statusFlag == false) {
                  return;
                } else {
                  this.adminApiService
                    .getMappingDocument(parsedCoreConnection)!
                    .subscribe(glaDocument => {
                      console.log('GLA Document', glaDocument);
                      // Set tmpDoc as deep copy of document core
                      // copies without leaving a reference
                      const tmpDoc = JSON.parse(JSON.stringify(glaDocument));
                      this.spinnerService.setShowSpinner(false);
                      if (tmpDoc.statusFlag) {
                        this.ccmcApiService.checkForSpecificConfig(
                          tmpDoc.document.configurations,
                          'implementationMode',
                          setting
                        );
                        // Set document core
                        this.glaService.setGLAObject(tmpDoc.document);
                        // Assigns core service
                        this.activeCoreService.getActiveCore();
                        // Sets the los string
                        this.activeLosService.activeLos = setting.los;
                        // Update the Navigation depending if encompass
                        this.navigationService.updateNavigation(
                          setting.product,
                          setting.los,
                          setting.core,
                          setting.ebm
                        );
                        if (
                          setting.los === 'encompass' ||
                          setting.los === 'bytepro'
                        ) {
                          this.router.navigate(['loan-lookup']);
                        } else {
                          this.router.navigate(['file-selector']);
                        }
                      } else {
                        // Set document to empty object
                        this.adminApiService.setDocumentCoreData({});
                        // Open snackbar with error message
                        this.snackBarService.openSnackBar(
                          tmpDoc.statusMessage,
                          'Okay'
                        );
                      }
                    });
                }
              }
            });
          // Pull Core Document
          break;
        case setting.product.includes('doc_connector'):
          console.log('Do Doc Connector Stuff here');
          // Retrieve Los Connection
          this.adminApiService
            .getLosConnection(setting.los)
            .subscribe(losConnection => {
              console.log(losConnection);
              if (losConnection) {
                const parsedLosConnection = JSON.parse(
                  JSON.stringify(losConnection)
                );
                // If Parsed Los Connection includes statusFlag we know it failed
                if (parsedLosConnection.statusFlag == false) {
                  return;
                } else {
                  this.adminApiService
                    .getMappingDocument(parsedLosConnection)!
                    .subscribe(docConnectorDocument => {
                      console.log(
                        'Doc Connector Document',
                        docConnectorDocument
                      );
                      // Set tmpDoc as deep copy of document core
                      // copies without leaving a reference
                      const parsedDocumentResponse = JSON.parse(
                        JSON.stringify(docConnectorDocument)
                      );
                      this.spinnerService.setShowSpinner(false);
                      if (parsedDocumentResponse.statusFlag) {
                        // Set Document Connector Document
                        this.dcService.setDocumentConnectorDocument(
                          parsedDocumentResponse.document
                        );
                        // Assigns core service
                        this.activeCoreService.getActiveCore();
                        // Sets the los string
                        this.activeLosService.activeLos = setting.los;
                        // Update the Navigation depending if encompass
                        this.navigationService.updateNavigation(
                          setting.product,
                          setting.los,
                          setting.core,
                          setting.ebm
                        );
                        // Update base url for communicators and ice
                        this.router.navigate(['doc-connector/history']);
                      }
                    });
                }
              }
            });
          break;
        default:
          console.log('Do Standard Stuff here');
          this.adminApiService
            .getCoreConnection(setting.core)
            .subscribe(coreConnection => {
              console.log('Core Connection', coreConnection);
              if (coreConnection) {
                const parsedConnection = JSON.parse(
                  JSON.stringify(coreConnection)
                );
                if (parsedConnection.statusFlag == false) {
                  this.dialog.open(ErrorDialogComponent, {
                    data: {
                      title: 'Error',
                      message: parsedConnection.statusMessage
                    }
                  });
                  return;
                } else {
                  // Get the document for the selected asset
                  this.adminApiService
                    .getMappingDocument(parsedConnection)!
                    .subscribe(documentCore => {
                      // Set tmpDoc as deep copy of document core
                      // copies without leaving a reference
                      let tmpDoc = JSON.parse(JSON.stringify(documentCore));
                      this.spinnerService.setShowSpinner(false);
                      // If document was successful
                      if (tmpDoc.statusFlag) {
                        this.ccmcApiService.configurations =
                          tmpDoc.document.configurations;
                        this.ccmcApiService.search = tmpDoc.document.search;
                        this.ccmcApiService.checkForSpecificConfig(
                          tmpDoc.document.configurations,
                          'implementationMode',
                          setting
                        );
                        this.fieldEditedService.impModechecked.next(
                          tmpDoc.document.configurations.implementationMode
                        );
                        // Set document core
                        this.adminApiService.setDocumentCoreData(
                          tmpDoc.document
                        );

                        this.fieldEditedService.copyNameCheck.next(
                          tmpDoc.document.configurations
                            .overwriteBorrNamesWithSearchResults
                        );
                        if(!setting.product.includes('general_ledger_accounting') && !this.ccmcApiService.configurations.showGLBalancePrompt){ 
                          this.ccmcApiService.configurations.showGLBalancePrompt = false;
                        }
                        // Assigns core service
                        this.activeCoreService.getActiveCore();
                        // Update the Navigation depending if encompass
                        this.navigationService.updateNavigation(
                          setting.product,
                          setting.los,
                          setting.core,
                          setting.ebm
                        );
                        // Update base url for communicators
                        
                        if (
                          setting.los === 'encompass' ||
                          setting.los === 'bytepro'
                        ) {
                          this.router.navigate(['loan-lookup']);
                        } else {
                          this.router.navigate(['file-selector']);
                        }
                      } else {
                        // Set document to empty object
                        this.adminApiService.setDocumentCoreData({});
                        // Open snackbar with error message
                        this.snackBarService.openSnackBar(
                          tmpDoc.statusMessage,
                          'Okay'
                        );
                      }
                    });
                }
              }
            });
      }
    }
  }

  isNXTSoft() {
    if (
      this.amplifyService.isNXTsoftDev ||
      this.amplifyService.isNXTsoftOperations ||
      this.amplifyService.isNXTsoftSales
    ) {
      return true;
    } else {
      return false;
    }
  }

  // setNavigationObject() {}

  getChangeRequestTable() {
    this.requestChangeService.getDynamoTable();
    this.requestChangeService.getChangeRequestDocStatuses()
  }
  // checkUserPermissions() {}

  /**
   * Set Product Type
   * @description sets the assetId, core, product type, gets the active core
   * @param {*} type
   * @memberof CCMCAssetidSelectorComponent
   */
  setProductType(type: any) {
    this.gcSettingsService.setProductType(type);
  }

  /**
   * Set Icon
   * @description sets the product icon according to the product type
   * @param {*} product
   * @returns
   * @memberof CCMCAssetidSelectorComponent
   */
  setIcon(product: any) {
    let icon = '';
    switch (product) {
      case 'home':
        icon = 'home';
        break;
      case 'auto':
        icon = 'directions_car';
        break;
      default:
        icon = 'home';
    }

    return icon;
  }

  /**
   * On Destroy
   *
   * @memberof CCMCAssetidSelectorComponent
   */
  ngOnDestroy() {
    if (this.assetIdSettingsSubscription) {
      this.assetIdSettingsSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  changed(text: string) {
    this.filterChanged.next(text);
  }

  /**
   * Apply Filter
   * @description filters the products based upon user input
   * @param {*} filters
   * @memberof CCMCAssetidSelectorComponent
   */
  applyFilter(filters: any) {
    console.log(filters);
    if (filters === '') {
      this.filteredSettings = this.settings;
    } else {
      this.filteredSettings = this.settings.filter(o => {
        return (
          o.FI.toLowerCase().includes(filters.toLowerCase()) ||
          o.product
            .replace('_', ' ')
            .toLowerCase()
            .includes(filters.toLowerCase()) ||
          o.los.toLowerCase().includes(filters.toLowerCase()) ||
          o.core.toLowerCase().includes(filters.toLowerCase()) ||
          o.assetId.toLowerCase().includes(filters.toLowerCase())
        );
      });
    }
  }


  /**
   * Logout
   * @description Logs the user out
   * @memberof CCMCAssetidSelectorComponent
   */
  logout() {
    // removes the tokens set by login and redirects to the login page
    this.amplifyService.logout();
    // Set isLoggedIn flag to false
    this.authService.isLoggedIn = false;
    // Remove stored data
    this.removeData();
    // Navigate back to login screen
    this.router.navigate(['login']);
  }

  /**
   * Remove Data
   * @description Removes api data
   * @memberof CCMCAssetidSelectorComponent
   */
  removeData() {
    this.ccmcApiService.mapping = undefined;
    this.ccmcApiService.coreSettings = undefined;
    this.ccmcApiService.conditions = undefined;
    this.ccmcApiService.search = undefined;
    this.ccmcApiService.transactions = undefined;
    this.ccmcApiService.configurations = undefined;
  }

  /**
   * Get Cores
   * @description gets the core table from dynamoDB
   * @memberof CCMCAssetidSelectorComponent
   */
  getCores() {
    // Get cores call
    this.adminApiService.getCores();
  }

  applyTotalAssetFilter(filters: any) {
    console.log(filters);
    this.fullDataSourceTable.filter = filters.trim().toLowerCase();
    if (!this.fullDataSourceTable.filter) {
      this.sortAllLoans();
    }
  }
  sortAllLoans() {
    this.fullDataSourceArray.sort((a, b) => {
      //Places pinned on the top
      if (a.isPinned === true) {
        return -1;
      }
      //Places pinned on the top
      if (b.isPinned === true) {
        return 1;
      }
      //keeps the standard order if neither are pinned
      return a.isPinned > b.isPinned ? 1 : -1;
    });
    this.spinnerService.setShowSpinner(false);
    // console.log(this.filteredSettings);

    // this.fullDataSourceTable = new MatTableDataSource(this.fullDataSourceArray);
  }
  selectedAssetRow(element: any) {
    if (element === 'height') {
      this.innerWidth = window.innerWidth;
      let useableHeight = (this.innerWidth / 2) * 0.85;
      return {
        height: useableHeight + 'px'
      };
    }
    const addToBeStickyIndex = this.fullDataSourceArray.findIndex(
      a => a === element
    );
    if (!element.isPinned) {
      return {
        // add effect to the not pinned options
        // 'background-color': 'lightgrey'
      };
    } else if (!this.fullDataSourceTable.filter) {
      let stickyIndexNumber = 56 + 30 * addToBeStickyIndex;
      if (addToBeStickyIndex % 2 == 0) {
        return {
          // add effect to the even pinned options
          //next three are to make it sticky
          // position: 'sticky',
          // 'z-index': 100,
          // top: stickyIndexNumber + 'px',
          'background-color': 'white'
        };
      } else {
        return {
          // add effect to the odd pinned options
          //next three are to make it sticky
          // position: 'sticky',
          // 'z-index': 100,
          // top: stickyIndexNumber + 'px',
          'background-color': '#f8f8f8'
        };
      }
    }
  }
  saveCheckBox(element: any) {
    if (element.assetId == 0) {
      return;
    }
    this.spinnerService.setShowSpinner(true);
    const assetIds = this.assetService.getAssetIds();
    let updatedAssetsExist = [];
    let updatedAssetsNotExist = [];
    console.log(element.assetId);

    if (!assetIds.includes(element.assetId)) {
      let pinnedLegnth = assetIds.length;
      console.log(assetIds);
      if (pinnedLegnth >= 12) {
        this.dialog.open(ErrorDialogComponent, {
          data: {
            title: 'Warning',
            message: 'Only twelve assets can be pinned'
          }
        });
        return;
      }

      updatedAssetsNotExist.push(element);
    } else {
      updatedAssetsExist.push(element);
    }

    if (updatedAssetsExist.length > 0) {
      this.removeFromPersonalList(updatedAssetsExist);
    }
    if (updatedAssetsNotExist.length > 0) {
      this.addToPersonalList(updatedAssetsNotExist);
    }
  }
  addToPersonalList(assetIdsElement: any) {
    let asset = assetIdsElement[0].assetId;
    const assetIds = this.assetService.getAssetIds();
    const assetID = asset;
    if (!assetIds.includes(assetID)) {
      assetIds.push(assetID);
      this.pinnedAssets.push(asset);
    }
    this.updateaddAssets(assetIds, assetID);
  }

  removeFromPersonalList(assetIdsElement: any) {
    let asset = assetIdsElement[0].assetId;
    const assetIds = this.assetService.getAssetIds();
    const assetId = asset;
    const assetIndexpinned = this.pinnedAssets.findIndex(a => a === assetId);
    const assetIndex = assetIds.findIndex((a: any) => a === assetId);
    if (assetIndex > -1) {
      if (assetIndexpinned > -1) {
        this.pinnedAssets.splice(assetIndexpinned, 1);
      }
      assetIds.splice(assetIndex, 1);
    }

    this.updateremoveAssets(assetIds, assetId);
  }

  updateaddAssets(assetIds: any, assetNumber: any) {
    console.log(this.username);
    this.ccmcApiService
      .updateAssets(this.username, assetIds)
      .subscribe(result => {
        console.log(assetIds);
        if (result) {
          console.log(result);
          this.assetService.setAssetids(assetIds);
          console.log(this.assetService.setAssetids(assetIds));
          const successdialoguref = this.dialog.open(SuccessDialogComponent, {
            panelClass: 'pinned_update__panel',
            data: { title: 'Success', message: 'Asset Pinned' }
          });
          successdialoguref.afterClosed().subscribe(close => {
            this.refreshTableVariables(assetNumber);
          });
        }
      });
  }

  updateremoveAssets(assetIds: any, assetNumber: any) {
    this.ccmcApiService
      .updateAssets(this.username, assetIds)
      .subscribe(result => {
        if (result) {
          console.log(result);
          this.assetService.setAssetids(assetIds);
          const successdialoguref = this.dialog.open(SuccessDialogComponent, {
            panelClass: 'pinned_update__panel',
            data: { title: 'Success', message: 'Asset Unpinned' }
          });
          successdialoguref.afterClosed().subscribe(close => {
            this.refreshTableVariables(assetNumber);
          });
        }
      });
  }
  editAssetBox(asset: any) {
    const dialogRefBackup = this.dialog.open(AssetSelectorEditDialogComponent, {
      data: { asset: asset, assetsArray: this.fullDataSourceTable.data },
      panelClass: 'asset-selector-edit-dialog__panel'
    });
    dialogRefBackup.afterClosed().subscribe(data => {
      if (data) {
        this.spinnerService.setShowSpinner(true);
        const finishedMessage = `Asset ${asset.assetId} Updated`;
        this.openSnackBar(finishedMessage, 'Okay');
        this.retrieveAssetIds();
        this.loadTableValues();
      }
    });
  }
  windowSizeAdjust() {
    this.innerWidth = window.innerWidth;
    let useableHeight = this.innerWidth * 0.8;
    return {
      height: useableHeight + '%'
    };
  }
  refreshTableVariables(result: any) {
    this.amplifyService.resetCookies();
    this.amplifyService.getCurrentUser();
    const assestToBeChanged = this.fullDataSourceArray.findIndex(
      a => a.assetId === result
    );
    if (this.fullDataSourceArray[assestToBeChanged].isPinned === false) {
      this.fullDataSourceArray[assestToBeChanged].isPinned = true;
    } else if (this.fullDataSourceArray[assestToBeChanged].isPinned === true) {
      this.fullDataSourceArray[assestToBeChanged].isPinned = false;
    }
    if (!this.fullDataSourceTable.filter) {
      this.sortAllLoans();
    }
  }
  openSnackBar(message: string, action: string) {
    this.zone.run(() => {
      setTimeout(() => {
        this.snackBar.open(message, action, {
          duration: 5000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }, 0);
    });
  }
  loadTableValues() {
    // Gathers all the assets and creates the table
    this.ccmcApiService.getDynamoDB('assets').subscribe((result: any) => {
      const parsedResult = JSON.parse(JSON.stringify(result));
      let dataTableResults = parsedResult;
      this.fullDataSourceArray = [];
      // loops through the table
      for (let i = 0; i < dataTableResults.length; i++) {
        this.fullDataSourceArray.push(result[i]);
        this.fullDataSourceArray[i].isPinned = false;
        for (let j = 0; j < this.pinnedAssets.length; j++) {
          // checks asset against the pinned assets to see if it needs to get the isPinned attribute
          if (this.fullDataSourceArray[i].assetId === this.pinnedAssets[j]) {
            this.fullDataSourceArray[i].isPinned = true;
          }
        }
      }
      // Sorts all assets with with isPinned
      this.sortAllLoans();

      this.fullDataSourceTable = new MatTableDataSource(this.fullDataSourceArray);
      // initializes pagination
      console.log('table', this.fullDataSourceTable);
      console.log('paginator?', this.paginator);
      this.fullDataSourceTable.paginator = this.paginator;
      // const paginatorIntl = this.paginator._intl;
      // paginatorIntl.nextPageLabel = '';
      // paginatorIntl.previousPageLabel = '';
      // paginatorIntl.firstPageLabel = '';
      // paginatorIntl.lastPageLabel = '';

      this.spinnerService.setShowSpinner(false);
      this.checkForExtraPinned();
    });
  }
  checkForExtraPinned() {
    // console.log(this.pinnedErrors);
    // console.log('assets', this.pinnedAssets);
    // console.log(this.fullDataSourceArray);
    // console.log(this.pinnedErrors.length);
    // for (let i = 0; i < this.fullDataSourceArray.length; i++) {
    //   for (let j = 0; j < this.pinnedAssets.length; j++) {
    //     if (this.fullDataSourceArray[i].assetId === this.pinnedAssets[j]) {
    //       console.log('found');
    //       this.pinnedErrors.push(this.pinnedAssets[j]);
    //     }
    //   }
    // }
    // console.log(this.pinnedErrors.length);
    // console.log(this.pinnedErrors);
    // if (this.pinnedErrors.length > 0) {
    //   for (let j = 0; j < this.pinnedErrors.length; j++) {
    //     let asset = this.pinnedErrors[j];
    //     this.assetIdsToPass = this.assetService.getAssetIds();
    //     console.log(this.assetIdsToPass);
    //     let assetId = asset;
    //     let assetIndexpinned = this.pinnedAssets.findIndex(a => a == assetId);
    //     let assetIndex = this.assetIdsToPass.findIndex(a => a === assetId);
    //     if (assetIndex > -1) {
    //       if (assetIndexpinned > -1) {
    //         // console.log('assets override', assetIndexpinned);
    //         this.pinnedAssets.splice(assetIndexpinned, 1);
    //       }
    //       this.assetIdsToPass.splice(assetIndex, 1);
    //       console.log('assets override', this.assetIdsToPass);
    //     }
    //   }
    //   this.ccmcApiService
    //     .updateAssets(this.username, this.assetIdsToPass)
    //     .subscribe(result => {
    //       if (result) {
    //         this.assetService.setAssetids(this.assetIdsToPass);
    //       }
    //     });
    // }
  }

  async setChangeRequestIDs() {
    return new Promise(resolve => {
      if (!environment.test) {
        this.ccmcApiService
        .getDynamoDBItemsByIndex(
          'change_request_log',
          this.assetService.getSelectedAssetId(),
          'assetID'
        )
        .subscribe(changeReqRes => {
          let parsedChangeReqRes = JSON.parse(JSON.stringify(changeReqRes));
          if (parsedChangeReqRes && parsedChangeReqRes.content.Items && parsedChangeReqRes.content.Items.length > 0) {
            console.log(parsedChangeReqRes);
            for(let changeRequestItem of parsedChangeReqRes.content.Items) {
              if (changeRequestItem.assetID) {
                  if (!changeRequestItem.changeRequestID) {
                      changeRequestItem.changeRequestID = uuidv4();
                      this.ccmcApiService
                    .createDynamoDB('prod', 'change_request_log', changeRequestItem)
                    .subscribe(res => {
                      resolve(true);
                    });
                    }
                  
              }
            }
          } else {
            resolve(true);
          }
        });
      } else {
        resolve(false);
      }
      
    });
  }
  clearOutSelectedFields() {
    // this.selectedFieldService.clearOutSelectedFields();
    // this.fieldEditedService.coreEdited.next(false);
    // this.fieldEditedService.losEdited.next(false);
    // this.fieldEditedService.onGLADocumentEdited.next(false);
    // this.fieldEditedService.onGLAConfigurationFieldEdited.next(false);
    // this.fieldEditedService.onConfigurationsFieldEdited.next(false);
  }
  clearSelectedAsset() {
    // Remove data from localStorage
    localStorage.removeItem('selectedCore');
    localStorage.removeItem('selectedCoreCompany');
    localStorage.removeItem('selectedFI');
    localStorage.removeItem('selectedLos');
    localStorage.removeItem('selectedProduct');
    localStorage.removeItem('selectedAsset');
  }

  clearEncompassToken(setting: any) {
    if (setting.los === 'encompass') {
      console.log('Clear Encompass Token');
      this.activeLosService.activeLosService.setAccessToken(undefined);
    }
  }
}
