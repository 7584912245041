<mat-stepper
  labelPosition="bottom"
  #stepper
  linear
  style="width: 65vw; max-width: 1600px"
  *ngIf="newDestination"
>
  <!-- #enddocregion label-position -->
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>Destination(s) and Configurations</ng-template>
      <div style="padding: 15px">
        <div style="max-height: 150px; overflow: auto">
          <table
            mat-table
            class="table-TableContainer"
            style="width: 100%"
            #table
            [dataSource]="completeReviewDataSource"
            CCMCPerfectScrollbar
          >
            <!-- Field ID Column -->
            <ng-container matColumnDef="id">
              <th
                mat-header-cell
                *matHeaderCellDef
                style="font-size: 20px; color: black"
              >
                Destination ID
              </th>
              <td mat-cell *matCellDef="let element" class="noselect">
                {{ element }}
              </td>
            </ng-container>

            <!-- Remove Column -->
            <ng-container matColumnDef="remove">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element" class="noselect">
                <mat-icon
                  style="
                    margin-top: 1px;
                    margin-right: 8%;
                    float: right;
                    color: #aaaaaa;
                  "
                  (click)="removeDestinationFromComplete(element)"
                  >remove_circle</mat-icon
                >
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="completeReviewTableColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: completeReviewTableColumns"
            ></tr>
          </table>
        </div>

        <mat-toolbar style="background-color: white">
          <mat-form-field style="width: 100%">
            <input
              matInput
              placeholder="Destination ID"
              autocomplete="off"
              data-lpignore="true"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tempDestinationID"
            />
          </mat-form-field>
          <div class="Btn-Container" style="float: right">
            <button
              class="Add-btn"
              mat-raised-button
              style="margin-right: 10px; margin-left: 10px"
              matTooltip="Add a New DestinationID"
              matTooltipClass="toolTipStyle"
              (click)="addToDestinationIDList()"
            >
              <mat-icon style="color: #3fb2c6">add_circle</mat-icon>
            </button>
            <button
              class="Add-btn"
              mat-raised-button
              matTooltip="Paste IDs From Excel, this will disable the meta and source step if applicatable."
              matTooltipClass="toolTipStyle"
              (click)="openPasteFromExcelDialog()"
            >
              <mat-icon style="color: #8883e2">content_paste</mat-icon>
            </button>
          </div>
        </mat-toolbar>
        <div style="background-color: #efefef; padding: 10px">
          <h2>Configurations</h2>
          <div
            style="
              display: grid;
              grid-template-columns: 1fr 1fr;
              column-gap: 30px;
            "
          >
            <mat-form-field>
              <mat-label>Join Images</mat-label>
              <mat-select [(value)]="newDestination.joinImages">
                <mat-option [value]="true"> True </mat-option>
                <mat-option [value]="false"> False </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Separate Pages</mat-label>
              <mat-select [(value)]="newDestination.separatePages">
                <mat-option [value]="true"> True </mat-option>
                <mat-option [value]="false"> False </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            style="
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;
              column-gap: 30px;
            "
          >
            <mat-form-field>
              <input
                matInput
                type="number"
                autocomplete="off"
                placeholder="Order Index"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="newDestination.orderIndex"
              />
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                autocomplete="off"
                data-lpignore="true"
                placeholder="New File Name"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="newDestination.newFileName"
              />
            </mat-form-field>
            <mat-form-field>
              <mat-label>New File Type</mat-label>
              <mat-select [(value)]="newDestination.newFileType">
                <mat-option [value]="'pdf'"> PDF </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="Btn-Container">
        <button class="cancelBtn" mat-stroked-button (click)="close()">
          Cancel
        </button>

        <button
          class="add-Btn"
          mat-raised-button
          (click)="verifyConfigurationFields(stepper)"
        >
          Next
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step
    [stepControl]="secondFormGroup"
    *ngIf="newDestination.metas.length > 0 && !hideMetaStep"
  >
    <form [formGroup]="secondFormGroup">
      <ng-template matStepLabel>Index Fields</ng-template>
      <div class="table-container" style="margin-bottom: 10px">
        <div style="max-height: 150px; overflow: auto">
          <table
            mat-table
            class="table-TableContainer"
            #table
            [dataSource]="metasDataSource"
            CCMCPerfectScrollbar
            matSort
          >
            <!-- Field ID Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
              <td
                mat-cell
                *matCellDef="let element"
                class="noselect"
                style="width: 30%"
              >
                {{ element.name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="index">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Order</th>

              <td
                mat-cell
                *matCellDef="let element"
                class="noselect"
                style="width: 10%"
              >
                {{ element.index }}
              </td>
            </ng-container>

            <ng-container matColumnDef="value">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Value</th>

              <td
                mat-cell
                *matCellDef="let element"
                class="noselect"
                style="width: 30%"
              >
                {{ element.value }}
              </td>
            </ng-container>

            <ng-container matColumnDef="path">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Path</th>

              <td
                mat-cell
                *matCellDef="let element"
                class="noselect"
                style="width: 30%"
              >
                {{ element.path }}
              </td>
            </ng-container>
            <!-- Remove Column -->
            <ng-container matColumnDef="remove">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element" class="noselect">
                <mat-icon
                  style="
                    margin-top: 1px;
                    margin-right: 8%;
                    float: right;
                    color: #aaaaaa;
                  "
                  (click)="removeMeta(element)"
                  >remove_circle</mat-icon
                >
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="metasDisplayedColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: metasDisplayedColumns"
              (click)="onSelectMeta(row)"
              [ngClass]="{ 'accent-bg': row == selectedMeta }"
            ></tr>
          </table>
        </div>
        <div style="background-color: #efefef; padding: 10px">
          <h2>Selected Index</h2>
          <div
            style="
              display: grid;
              grid-template-columns: 1fr 1fr;
              row-gap: 10px;
              column-gap: 10px;
              grid-template-rows: 1fr 1fr;
            "
          >
            <mat-form-field>
              <input
                matInput
                placeholder="Name"
                autocomplete="off"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="selectedMeta.name"
              />
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                placeholder="Order"
                autocomplete="off"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="selectedMeta.index"
              />
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                required
                placeholder="Value"
                autocomplete="off"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="selectedMeta.value"
              />
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                placeholder="Path"
                autocomplete="off"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="selectedMeta.path"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="Btn-Container">
        <div>
          <button class="cancelBtn" mat-stroked-button (click)="close()">
            Cancel
          </button>
          <button
            class="cancelBtn"
            mat-stroked-button
            matStepperPrevious
            style="margin-left: 10px"
          >
            Back
          </button>
        </div>

        <button
          class="add-Btn"
          mat-raised-button
          (click)="verifyMetas(stepper)"
        >
          Next
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step
    [stepControl]="thirdFormGroup"
    *ngIf="newDestination.sources.length > 0 && !hideSourceStep"
  >
    <form [formGroup]="thirdFormGroup">
      <ng-template matStepLabel>Sources</ng-template>
      <div
        style="
          position: relative;
          border: 1px solid #8d8d8d;
          background: #f7f7f7;
          width: 100%;
        "
      >
        <div style="max-height: 150px; overflow: auto">
          <table
            mat-table
            class="table-TableContainer"
            style="width: 100%"
            #table
            [dataSource]="sourcesDataSource"
            CCMCPerfectScrollbar
          >
            <!-- Field ID Column -->
            <ng-container matColumnDef="source">
              <th
                mat-header-cell
                *matHeaderCellDef
                style="font-size: 20px; color: black"
              >
                Sources
              </th>
              <td mat-cell *matCellDef="let element" class="noselect">
                {{ element }}
              </td>
            </ng-container>

            <!-- Remove Column -->
            <ng-container matColumnDef="remove">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element" class="noselect">
                <mat-icon
                  style="
                    margin-top: 1px;
                    margin-right: 8%;
                    float: right;
                    color: #aaaaaa;
                  "
                  (click)="removeSource(element)"
                  >remove_circle</mat-icon
                >
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="sourcesDisplayedColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: sourcesDisplayedColumns"
            ></tr>
          </table>
        </div>
        <mat-toolbar style="background-color: white">
          <mat-form-field style="width: 100%">
            <input
              matInput
              placeholder="New Source"
              autocomplete="off"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="newSource"
            />
          </mat-form-field>
          <div class="AddBtn-Container" style="float: right; margin-right: 8%">
            <button
              class="Add-btn"
              matTooltip="Add a New Source"
              matTooltipClass="toolTipStyle"
              (click)="addNewSource()"
            >
              <mat-icon style="color: #3fb2c6">add_circle</mat-icon>
            </button>
          </div>
        </mat-toolbar>
      </div>
      <div class="Btn-Container">
        <div>
          <button class="cancelBtn" mat-stroked-button (click)="close()">
            Cancel
          </button>
          <button
            class="cancelBtn"
            matStepperPrevious
            mat-stroked-button
            style="margin-left: 10px"
          >
            Back
          </button>
        </div>

        <button
          class="add-Btn"
          mat-raised-button
          (click)="verifySources(stepper)"
        >
          Next
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Review</ng-template>
    <div style="max-height: 400px; overflow: auto">
      <table
        mat-table
        class="table-TableContainer"
        style="width: 100%"
        #table
        [dataSource]="completeReviewDataSource"
        CCMCPerfectScrollbar
      >
        <!-- Field ID Column -->
        <ng-container matColumnDef="id">
          <th
            mat-header-cell
            *matHeaderCellDef
            style="font-size: 20px; color: black"
          >
            Destination ID
          </th>
          <td mat-cell *matCellDef="let element" class="noselect">
            {{ element }}
          </td>
        </ng-container>

        <!-- Remove Column -->
        <ng-container matColumnDef="remove">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="noselect">
            <mat-icon
              style="
                margin-top: 1px;
                margin-right: 8%;
                float: right;
                color: #aaaaaa;
              "
              (click)="removeDestinationFromComplete(element)"
              >remove_circle</mat-icon
            >
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="completeReviewTableColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: completeReviewTableColumns"
        ></tr>
      </table>
    </div>
    <div class="Btn-Container">
      <div>
        <button class="cancelBtn" mat-stroked-button (click)="close()">
          Cancel
        </button>
        <button
          class="cancelBtn"
          matStepperPrevious
          mat-stroked-button
          style="margin-left: 10px"
        >
          Back
        </button>
      </div>
      <button
        class="Add-Btn"
        mat-raised-button
        (click)="completeNewDestination()"
      >
        Complete
      </button>
    </div>
  </mat-step>
</mat-stepper>
