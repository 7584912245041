import { Component, OnInit, ViewChild } from '@angular/core';
import { ActiveLosService } from 'src/@ccmc/services/active-los.service';
import { Subject, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
import { CCMCNavigationService } from 'src/@ccmc/components/navigation/navigation.service';
import {
  glaNavigation,
  navigation
} from '../../../../app/navigation/navigation';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from 'src/@ccmc/components/error-dialog/error-dialog.component';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { Navigation, Router } from '@angular/router';
import { ActiveCoreService } from 'src/@ccmc/services/active-core.service';
import { ErrorStateMatcher } from '@angular/material/core';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectSetsDialogComponent } from 'src/@ccmc/components/select-sets-dialog/select-sets-dialog.component';
import { GeneralLedgerAccountingService } from 'src/@ccmc/services/general-ledger-accounting.service';
import { takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { CaptureDataService } from 'src/@ccmc/services/capture-data.service';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { CifSearchService } from 'src/@ccmc/services/cif-search.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-loan-lookup',
  templateUrl: './loan-lookup.component.html',
  styleUrls: ['./loan-lookup.component.scss']
})
export class LoanLookupComponent implements OnInit {
  changedLoans: any[] = [];
  freshchangedLoan: any;
  currentLos: string;
  loanGetParams: any = {};
  constructor(
    private activeLosService: ActiveLosService,
    private spinnerService: SpinnerService,
    private navigationService: CCMCNavigationService,
    private matDialog: MatDialog,
    private assetService: AssetService,
    private ccmcApiService: CcmcApiService,
    private activeCoreService: ActiveCoreService,
    private router: Router,
    private glaService: GeneralLedgerAccountingService,
    private captureDataService: CaptureDataService,
    private fieldEditedService: FieldEditedService,
    private adminApiService: AdminApiService,
    private amplifyService: AmplifyService,
    private cifSearchService: CifSearchService
  ) {}
  matcher = new MyErrorStateMatcher();
  loanLookupParams: any;
  losSubscription: Subscription;
  impModeSub: Subscription;
  loans: any;
  dynamicNav: any = navigation;
  dynamicGLANav: any = glaNavigation;
  displayedColumns: any[] = [];
  encompassUseColumns = [
    'borrowerName',
    'loanNumber',
    'product',
    'taxID',
    'lastModified',
    'loanGuid'
  ];
  byteproUseColumns = [
    'borrowerName',
    'loanNumber',
    'product',
    'taxID',
    'lastModified'
  ];
  dataSource: any;
  showSpinner: boolean; // flag for the mat-spinner
  private spinnerSub: Subscription;
  selectedLoan: any;
  navigation: Navigation;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  loanNumberFormControl = new FormControl('', [Validators.minLength(3)]);
  taxIDFormControl = new FormControl('', [Validators.minLength(3)]);
  firstNameFormControl = new FormControl('', [Validators.minLength(2)]);
  lastNameFormControl = new FormControl('', [Validators.minLength(2)]);
  isSearchDisabled = true;
  glaDocument: any;
  unsubscribe: Subject<any> = new Subject();
  showChangeRequest = false;
  losCheck: string;
  selectedEnvironment: any;
  environments: string[] = ['Client', 'Nxtsoft'];

  /**
   * On Init
   *
   * @memberof LoanLookupComponent
   */
  ngOnInit(): void {
    this.losCheck = this.assetService.getSelectedLos();
    // Set spinner to false
    this.spinnerService.setShowSpinner(false);
    // Set los
    this.activeLosService.selectLos();
    // Subscribe to currnet los
    this.subscribeToLos();
    // init loan lookup parameters
    this.loanLookupParams = {
      loanNumber: '',
      firstName: '',
      lastName: '',
      taxID: ''
    };

    if (
      this.activeLosService.activeLos === 'bytepro' &&
      (this.amplifyService.isNXTsoftDev ||
        this.amplifyService.isNXTsoftOperations)
    ) {
      this.byteproUseColumns.push('download');
    }

    // If NXTsoft Instantiate The selected environment variable
    if (this.getIsNXTsoft()) {
      this.selectedEnvironment = 'Client';
    }

    // Subscribe to spinner
    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        this.showSpinner = spinner;
      });

    this.impModeSub = this.fieldEditedService.impModechecked
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(show => {
        this.showChangeRequest = show;
      });
    this.getGLADocument();
    // checks if encompass or bytepro to decide which columns to display
    if (this.activeLosService.activeLos === 'encompass') {
      this.currentLos = 'encompass';
      this.displayedColumns = this.encompassUseColumns;
    } else {
      this.currentLos = 'bytepro';
      this.displayedColumns = this.byteproUseColumns;
    }
  }

  getGLADocument() {
    this.glaService.glaDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(glaDocument => {
        if (glaDocument) {
          this.glaDocument = glaDocument;
        }
      });
  }

  getIsNXTsoft() {
    return (
      this.amplifyService.isNXTsoftDev ||
      this.amplifyService.isNXTsoftOperations
    );
  }
  /**
   * Subscribe to Los
   * @description Subscribes to the los subject
   * @memberof LoanLookupComponent
   */
  subscribeToLos() {
    this.losSubscription = this.activeLosService.losSubject
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        complete: () => {}
      });
  }

  /**
   * Loan Look Up
   *
   * @memberof LoanLookupComponent
   */
  loanLookUp() {
    if (this.isSearchDisabled === false) {
      console.log(this.loanNumberFormControl);
      console.log(this.firstNameFormControl);
      console.log(this.lastNameFormControl);
      console.log(this.taxIDFormControl);
      // Set spinner to true
      this.spinnerService.setShowSpinner(true);
      this.loanLookupParams.env = this.selectedEnvironment;
      console.log(this.activeLosService.activeLosService);
      // Call loan look up for active los
      this.activeLosService.activeLosService
        .loanLookUp(this.loanLookupParams)
        .subscribe((result: any) => {
          console.log(result);
          this.amplifyService.environmentOverride = false;
          // Set spinnerto false
          this.spinnerService.setShowSpinner(false);
          // If token returned save it in the curent los service
          if (
            result &&
            result.content &&
            result.content.accessToken &&
            this.activeLosService.activeLos === 'encompass'
          ) {
            this.activeLosService.activeLosService.setAccessToken(
              result.content.accessToken
            );
          }
          // If success
          if (result && result.statusFlag === true) {
            // Set loans
            this.loans = result.content.loans;
            // update badge count on navigation object
            this.updateLoanCount(this.loans.length);
            // if loans length is greater than 25 notify user
            // creates a copy of loans to work with
            this.changedLoans = this.loans;
            // changes all the dates to be in a format that can be sorted
            // in html reformats the date to "look" correct
            for (var index of this.changedLoans) {
              this.freshchangedLoan = index;
              var datePipe = new DatePipe('en-US');
              var freshDate = datePipe.transform(
                index.lastModified,
                'yyyy/MM/dd hh:mm a'
              );
              index.lastModified = freshDate;
            }
            if (this.loans.length === 25) {
              this.matDialog.open(ErrorDialogComponent, {
                data: {
                  message: 'More than 25 results.',
                  title: 'Caution'
                }
              });
            }
            // If no loans come back notify user
            if (this.loans.length === 0) {
              this.matDialog.open(ErrorDialogComponent, {
                data: {
                  message: 'No results found.',
                  title: 'Caution'
                }
              });
            }
            // Update data source to show loans
            this.dataSource = new MatTableDataSource(this.loans);
            // initializes sort
            this.dataSource.sort = this.sort;
          } else {
            this.matDialog.open(ErrorDialogComponent, {
              data: {
                message: result.statusMessage,
                title: 'Error'
              }
            });
          }
        });
    }
  }

  /**
   * Select Loan
   *
   * @param {*} row
   * @memberof LoanLookupComponent
   */
  selectLoan(row: any) {
    // init transactions to empty array
    this.dynamicNav[2].children[1].children = [];
    // set spinner to true
    this.spinnerService.setShowSpinner(true);
    // Set nav spinner to true
    this.navigationService.setShowNavSpinner(true);
    // set loan number in navigation
    const loanDetails = navigation[2].children[0];
    // update loan badge count
    loanDetails.badge!.title = row.loanNumber;
    // set the loan number in the service
    this.ccmcApiService.loanNumber = row.loanNumber;
    this.ccmcApiService.currentLoanNumber.next(row.loanNumber);
    this.captureDataService.setLoanNumber(row.loanNumber);
    // reset service properties to defaults
    this.cifSearchService.cifSet = false;
    this.ccmcApiService.doIssuesExist(true);
    // clear out internal Display Settings
    this.navigationService.onVisibilityChange.next(false);
    this.navigationService.onSortChange.next({button: 'numeric', alphaNumeric: 'numeric'});
    this.navigationService.defaultInstance = true;
    // set selected loan
    this.selectedLoan = row;
    // Set loan get parameters
    if (this.activeLosService.activeLos != 'bytepro') {
      this.loanGetParams = {
        loanGuid: this.selectedLoan.loanGuid
      };
    } else if (this.activeLosService.activeLos == 'bytepro') {
      this.loanGetParams = {
        fileDataID: this.selectedLoan.fileDataID,
        assetID: this.assetService.getSelectedAssetId()
      };
    }
    console.log(this.loanGetParams);
    this.loanGetParams.env = this.selectedEnvironment;
    // Call active los get loan
    this.activeLosService.activeLosService
      .getLoan(this.loanGetParams)
      .subscribe((result: any) => {
        console.log(result);
        this.amplifyService.environmentOverride = false;
        if (result && result.statusFlag) {
          // Call ice with response
          if (this.assetService.getGLA()) {
            this.updateNavDetailsLoanNumber(row.loanNumber);
            this.openSetsDialog(result.content.loans);
          } else {
            this.ccmcApiService.ice(result.content.loans).subscribe(
              (response: any) => {
                console.log('ice response', response);
                // if success handle the mapping response
                if (response.statusFlag === true) {
                  return this.handleCoreMappingResponse(null, response);
                } else if (response.statusMessage) {
                  console.log('Condition error response ',response);
                  // Send error message to handle mapping response
                  if (response.statusMessage.includes('undefined')) {
                    let temp = response.statusMessage.split("~");
                    const errorMessage = {
                      message: 'Breaking Condition error ~ ' + temp[1],
                      title: 'Ice Error'
                    };
                    return this.handleCoreMappingResponse(errorMessage, null);
                  } else {
                    const errorMessage = {
                      message: response.statusMessage,
                      title: 'Ice Error'
                    };
                    return this.handleCoreMappingResponse(errorMessage, null);
                  }
                  // no message recieved
                } else {
                  const errorMessage = {
                    message: 'No Response found',
                    title: 'Ice Error'
                  };
                  return this.handleCoreMappingResponse(errorMessage, null);
                }
              },
              // no message recieved
              error => {
                const errorMessage = {
                  message: 'No Result Found',
                  title: 'Ice Error'
                };
                return this.handleCoreMappingResponse(errorMessage, null);
              }
            );
          }
        } else {
          const errorMessage = {
            message: result.statusMessage,
            title: 'LOS Settings Error'
          };
          return this.handleCoreMappingResponse(errorMessage, null);
        }
      });
  }

  updateNavDetailsLoanNumber(loanNumber: any) {
    this.dynamicGLANav[2].children = [];
    const loanNumberNav = {
      id: 'loan-number',
      title: 'Loan Number: ',
      type: 'item',
      visible: true,
      desc: true,
      descValue: loanNumber
    };
    console.log('Update Gla Nav with LoanNumber', loanNumberNav);
    this.dynamicGLANav[2].children.push(loanNumberNav);

    // const glaloanDetails = this.dynamicNav[2];
    // gloanDetails.children[0].badge.title = loanNumber;
  }

  /**
   * On Destroy
   *
   * @memberof LoanLookupComponent
   */
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  /**
   * Handle Core Mapping Response
   * @description Handles response from generating the mapping
   * @param {*} err
   * @param {*} response
   * @returns
   * @memberof LoanLookupComponent
   */
  async handleCoreMappingResponse(err: any, response: any) {
    if (response) {
      console.log('ice result ', response);
      if (response.content && response.content.data) {
        // Set CCMC API variables
        const result: any = response.content.data;
        this.ccmcApiService.mapping = result.mapping;
        this.ccmcApiService.coreSettings = result.settings;
        this.ccmcApiService.conditions = result.conditions;
        this.ccmcApiService.mapping = result.mapping;
        this.ccmcApiService.supportValues = result.supportValues;
        this.ccmcApiService.search = result.search;
        this.ccmcApiService.transactions = result.transactions;
        this.ccmcApiService.configurations = result.configurations;
        this.ccmcApiService.ncinoWriteBack = result.ncinoWriteBack;
        // nav item for the issues list
        const navItem = {
          id: 'issueList',
          title: 'Issue List',
          type: 'item',
          icon: 'warning',
          visible: true,
          url: `/transactions/issueList`
        };
        // Pus transaction to navigation object
        this.dynamicNav[2].children[1].children.push(navItem);
        // nav item for the issues list
        const navItem2 = {
          id: 'reviewList',
          title: 'Review List',
          type: 'item',
          icon: 'rate_review',
          visible: true,
          url: `/transactions/reviewList`
        };
        this.dynamicNav[2].children[1].children.push(navItem2);

        if (this.showChangeRequest) {
          const navItem3 = {
            id: 'changerequestList',
            title: 'Change Request List',
            type: 'item',
            icon: 'rules',
            visible: true,
            url: `/transactions/changereq`
          };
          this.dynamicNav[2].children[1].children.push(navItem3);
        }

        // Sort transactions based on order
        this.ccmcApiService.transactions.sort(
          (a: any, b: any) => a.order - b.order
        );
        this.ccmcApiService.transactions.forEach((t: any, index: any) => {
          if (t.transactionName[0] !== '-') {
            let collapsableItem: any = {
              id: t.transactionID,
              title: t.transactionName.replace(/([\_/])+/g, ' '),
              type: 'collapse',
              fieldIndex: this.ccmcApiService.mapping.indexOf(
                (obj: any) => obj.fieldID === t.fieldID
              ),
              transactionIndex: index,
              additionalCost: t.additionalCost ? t.additionalCost : false,
              url: `/transactions/${t.transactionID}`,
              children: []
            };
            if (t.display === true) {
              collapsableItem.visible = true;
            }
            console.log('Collapse Item', JSON.parse(JSON.stringify(collapsableItem)));
            this.dynamicNav[2].children[1].children.push(collapsableItem);
          } else {
            // tslint:disable-next-line:no-shadowed-variable
            let navItem: any = {
              id: t.transactionID,
              title: t.transactionName.replace(/([\_/])+/g, ' '),
              type: 'item',
              fieldIndex: this.ccmcApiService.mapping.indexOf(
                (obj: any) => obj.fieldID === t.fieldID
              ),
              transactionIndex: index,
              additionalCost: t.additionalCost ? t.additionalCost : false,
              url: `/transactions/${t.transactionID}`
            };
            if (t.display === true) {
              navItem.visible = true;
            }
            console.log('Item', JSON.parse(JSON.stringify(navItem)));
            // Calculate where to push
            let lastIndex = this.dynamicNav[2].children[1].children.length;
            console.log(this.dynamicNav[2].children[1].children);
            this.dynamicNav[2].children[1].children[
              lastIndex - 1
            ].children.push(navItem);
            this.navigation = this.dynamicNav;
          }
        });
        // Turn on transaction navigation
        this.dynamicNav[2].children[1].visible = true;
        console.log('Transaction nav item', JSON.parse(JSON.stringify(this.dynamicNav[2].children[1])));
        // Set product inquiry if signature
        if (
          this.activeCoreService.activeCore.toLowerCase() === 'signature' &&
          this.activeCoreService.activeService.checkProductInquiry()
        ) {
          await this.activeCoreService.activeService.productInquiry();
        }
        this.navigation = this.dynamicNav;
        console.log('DynamicNav', this.dynamicNav);
        this.navigationService.setShowNavSpinner(false);
        this.spinnerService.setShowSpinner(false);
        // Navigate to first instance of the transactions
        return this.router.navigateByUrl(
          `${this.dynamicNav[2].children[1].children[0].url}`
        );
      }
      // connection error
      this.matDialog.open(ErrorDialogComponent, {
        data: {
          message: 'Connection Error',
          title: 'Ice Error'
        }
      });
    } else {
      // Show error to user
      const dialogRef2 = this.matDialog.open(ErrorDialogComponent, {
        data: err
      });
    }
  }

  handleGLACoreMappingResponse(err: any, response: any) {
    if (response) {
      this.spinnerService.setShowSpinner(false);
      console.log('ice result ', response);
      if (response.content) {
        this.glaService.setSelectedSetDocument(response.content);
        this.turnGLANavItemsOn();
        this.router.navigate([`gla/funding-ledger`]);
      } else {
        // connection error
        this.matDialog.open(ErrorDialogComponent, {
          data: {
            message: 'Connection Error',
            title: 'Ice Error'
          }
        });
      }
    } else {
      // Show error to user
      const dialogRef2 = this.matDialog.open(ErrorDialogComponent, {
        data: err
      });
    }
  }

  //
  /**
   * Update Loan Count
   * @description This updates the badge count for how many loans are loaded into the loan lookup
   * @param {*} count
   * @memberof LoanLookupComponent
   */
  updateLoanCount(count: any) {
    this.dynamicNav[1].children[1].badge.title = count;
    this.dynamicGLANav[1].children[1].badge.title = count;
  }

  /**
   * Clear
   * @description Clears loan look up params and table data
   * @memberof LoanLookupComponent
   */
  clear() {
    this.loanLookupParams.loanNumber = '';
    this.loanLookupParams.firstName = '';
    this.loanLookupParams.lastName = '';
    this.loanLookupParams.taxID = '';
    this.loans = [];
    this.updateLoanCount(0);
    this.dataSource = new MatTableDataSource(this.loans);
  }

  /**
   * Check Form
   * @description This checks if the form is vald before the user is able to perform the loan lookup
   * @returns
   * @memberof LoanLookupComponent
   */
  checkForm() {
    // If form is blank
    if (
      this.loanLookupParams.loanNumber === '' &&
      this.loanLookupParams.firstName === '' &&
      this.loanLookupParams.lastName === '' &&
      this.loanLookupParams.taxID === ''
    ) {
      this.isSearchDisabled = true;
      return;
    }
    // Check if all are valid
    if (this.lastNameFormControl.status === 'VALID') {
      if (this.loanNumberFormControl.status === 'VALID') {
        if (this.firstNameFormControl.status === 'VALID') {
          if (this.taxIDFormControl.status === 'VALID') {
            this.isSearchDisabled = false;
          } else {
            this.isSearchDisabled = true;
          }
        } else {
          this.isSearchDisabled = true;
        }
      } else {
        this.isSearchDisabled = true;
      }
    } else {
      this.isSearchDisabled = true;
    }
  }

  turnGLANavItemsOn() {
    this.dynamicGLANav[3].children[0].visible = true;
    this.dynamicGLANav[3].children[1].visible = true;
  }

  turnGLANavItemsOff() {
    this.dynamicGLANav[3].children[0].visible = false;
    this.dynamicGLANav[3].children[1].visible = false;
  }

  openSetsDialog(loan: any) {
    this.spinnerService.setShowSpinner(false);
    var activeCore = this.assetService.getSelectedCore();
    this.adminApiService
      .getCoreConnection(activeCore)
      .subscribe(coreConnection => {
        if (coreConnection) {
          const parsedCoreConnection = JSON.parse(
            JSON.stringify(coreConnection)
          );
          console.log(parsedCoreConnection);
          this.adminApiService
            .getMappingDocument(parsedCoreConnection)!
            .subscribe((glaDocument: any) => {
              console.log(glaDocument);
              console.log(glaDocument['document'].sets);
              let selectedGlaDocumentSet: any;
              if (glaDocument['document'].sets.length === 1) {
                selectedGlaDocumentSet = glaDocument['document'].sets[0];

                this.spinnerService.setShowSpinner(true);
                    console.log(selectedGlaDocumentSet);
                    const tempGLA = {
                      set: selectedGlaDocumentSet,
                      coreLayout: glaDocument['document'].coreLayout,
                      translators: glaDocument['document'].translators,
                      configurations: glaDocument['document'].configurations
                    };
                    const iceReq: any = tempGLA;
                    iceReq['loan'] = loan;
                    this.glaService.ice(iceReq).subscribe(
                      (response: any) => {
                        console.log(response);
                        // if success handle the mapping response
                        if (response.statusFlag === true) {
                          return this.handleGLACoreMappingResponse(
                            null,
                            response
                          );
                        } else if (response.statusMessage) {
                          // Send error message to handle mapping repose
                          const errorMessage = {
                            message: response.statusMessage,
                            title: 'Ice Error'
                          };
                          return this.handleGLACoreMappingResponse(
                            errorMessage,
                            null
                          );
                          // no message received
                        } else {
                          const errorMessage = {
                            message: 'No Response found',
                            title: 'Ice Error'
                          };
                          return this.handleGLACoreMappingResponse(
                            errorMessage,
                            null
                          );
                        }
                      },
                      // no message received
                      error => {
                        const errorMessage = {
                          message: 'No Result Found',
                          title: 'Ice Error'
                        };
                        return this.handleCoreMappingResponse(errorMessage, null);
                      }
                    );
              } else if(glaDocument['document'].sets.length < 1) {
                this.matDialog.open(ErrorDialogComponent, {
                  data: {
                    message: 'No Set Configurations found, at least one is required!',
                    title: 'Set Configuration Error'
                  }
                });
              } else {
                selectedGlaDocumentSet = this.matDialog.open(
                  SelectSetsDialogComponent,
                  {
                    data: glaDocument['document'].sets,
                    panelClass: 'select-sets-dialog'
                  }
                );
                const selectSetsDialogRef = selectedGlaDocumentSet;
                selectSetsDialogRef.afterClosed().subscribe((result: any) => {
                  if (result) {
                    this.spinnerService.setShowSpinner(true);
                    console.log(result);
                    const tempGLA = {
                      set: result,
                      coreLayout: glaDocument['document'].coreLayout,
                      translators: glaDocument['document'].translators,
                      configurations: glaDocument['document'].configurations
                    };
                    const iceReq: any = tempGLA;
                    iceReq['loan'] = loan;
                    this.glaService.ice(iceReq).subscribe(
                      (response: any) => {
                        console.log(response);
                        // if success handle the mapping response
                        if (response.statusFlag === true) {
                          return this.handleGLACoreMappingResponse(
                            null,
                            response
                          );
                        } else if (response.statusMessage) {
                          // Send error message to handle mapping repose
                          const errorMessage = {
                            message: response.statusMessage,
                            title: 'Ice Error'
                          };
                          return this.handleGLACoreMappingResponse(
                            errorMessage,
                            null
                          );
                          // no message received
                        } else {
                          const errorMessage = {
                            message: 'No Response found',
                            title: 'Ice Error'
                          };
                          return this.handleGLACoreMappingResponse(
                            errorMessage,
                            null
                          );
                        }
                      },
                      // no message received
                      error => {
                        const errorMessage = {
                          message: 'No Result Found',
                          title: 'Ice Error'
                        };
                        return this.handleCoreMappingResponse(errorMessage, null);
                      }
                    );
                  }
                });
              }
            });
        }
      });
  }

  downloadLoan(loan: any) {
    this.spinnerService.setShowSpinner(true);
    let loanGetParams = {
      fileDataID: loan.fileDataID,
      assetID: this.assetService.getSelectedAssetId()
    };
    // Call active los get loan
    this.activeLosService.activeLosService
      .getLoan(loanGetParams)
      .subscribe((result: any) => {
        this.spinnerService.setShowSpinner(false);
        console.log(result);
        const parsedResult = JSON.parse(JSON.stringify(result));
        if (result && result.statusFlag) {
          const exportString = parsedResult.content.loans;
          const blob = new Blob([exportString as BlobPart], {
            type: 'xml'
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${loan.firstName}_${loan.lastName}.xml`;
          a.click();
        } else {
          const errorMessage = {
            message: result.statusMessage,
            title: 'Error'
          };
          // connection error
          this.matDialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
        }
      });
  }
}
