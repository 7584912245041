<div class="add-global-meta-Container">
  <div
    class="moveContainer"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  ></div>
  <h1 matDialogTitle>Add New Destination Index</h1>
  <div class="formContainer">
    <div
      style="display: grid; grid-template-columns: 1fr 1fr; column-gap: 10px"
    >
      <mat-form-field style="padding-right: 30px">
        <input
          matInput
          #nameInput
          placeholder="Name"
          [(ngModel)]="tempSNDMeta.name"
          (keyup.enter)="createSNDMeta()"
        />
      </mat-form-field>
      <mat-form-field style="padding-right: 30px">
        <input
          matInput
          #sourceInput
          type="number"
          placeholder="Order"
          [(ngModel)]="tempSNDMeta.index"
          (keyup.enter)="createSNDMeta()"
        />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="width: 100%">
        <input
          matInput
          #valueInput
          placeholder="Value"
          [(ngModel)]="tempSNDMeta.value"
          style="width: 100%"
          (keyup.enter)="createSNDMeta()"
        />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="width: 100%">
        <input
          matInput
          #valueInput
          style="width: 100%"
          placeholder="Path"
          [(ngModel)]="tempSNDMeta.path"
          (keyup.enter)="createSNDMeta()"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="Btn-Container">
    <button
      class="cancelBtn-globalMeta"
      mat-stroked-button
      (click)="dialogRef.close(false)"
    >
      Cancel
    </button>
    <div>
      <button
        class="add-global-metaBtn"
        mat-raised-button
        (click)="createSNDMeta()"
      >
        <mat-icon style="color: #3fb2c6">add_circle</mat-icon> &nbsp; Save
      </button>
    </div>
  </div>
</div>
