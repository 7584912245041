import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { CCMCModule } from 'src/@ccmc/ccmc.module';
import { ccmcConfig } from './ccmc-config';
import { CCMCSharedModule } from 'src/@ccmc/shared.module';
import { CCMCMainModule } from './main/main.module';
import { AppMaterialModule } from './app-material/app-material.module';
import { httpInterceptorProviders } from 'src/@ccmc/interceptor';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import '../@ccmc/codemirror/addon/hint/vbscript-hint.ts';
import '../@ccmc/codemirror/addon/hint/javascript-hint.js';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
const appRoutes: Routes = [
  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
    // ccmc Main and Shared modules
    CCMCModule.forRoot(ccmcConfig),
    CCMCSharedModule,
    CCMCMainModule,
    AppMaterialModule,
    MatTabsModule,
    MatIconModule,
    MatSnackBarModule,
    CodemirrorModule,
  ],
  providers: [httpInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule {}
