import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CCMCConfigService } from '../../../../@ccmc/services/config.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogResult } from '../../../../@ccmc/models/dialog-result.model';
import { Router } from '@angular/router';
import {
  ConfirmValidParentMatcher,
  errorMessages,
  passwordMatchValidator
} from '../../toolbar/change-password/validate-passwords.validators';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';

@Component({
  selector: 'ccmc-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class CCMCForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  verificationForm: FormGroup;
  @ViewChild('resultTemplate') resultTemplate: any;
  forgotPasswordResultDialog: DialogResult;
  showVerificationForm = false;
  newPasswordType = 'password';
  confNewPasswordType = 'password';
  newPasswordVisibility = 'visibility';
  confNewPasswordVisibility = 'visibility';
  confirmValidParentMatcher = new ConfirmValidParentMatcher();
  errors = errorMessages;
  email = '';
  verificationSent = false;

  constructor(
    private fb: FormBuilder,
    private amplifyService: AmplifyService,
    private ccmcConfig: CCMCConfigService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.ccmcConfig.setConfig({
      layout: {
        navigation: 'none',
        toolbar: 'none',
        footer: 'none'
      }
    });
  }

  ngOnInit() {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  /**
   * @description handles submission of the forgot password form
   */
  submitRequest() {
    if (this.forgotPasswordForm.status === 'VALID') {
      this.email = this.forgotPasswordForm.controls['email'].value;
      this.amplifyService.forgotPassword(
        this.forgotPasswordForm.controls['email'].value.toLowerCase(),
        this
      );
    }
  }

  /**
   * @description handles displaying the results from the forgot password form
   */
  requestResult(err: any, success: any) {
    let dialogRef = this.dialog.open(this.resultTemplate, {
      panelClass: 'forgot-password__results-dialog'
    });

    this.forgotPasswordResultDialog = {
      msg: err || success,
      dialogRef
    };

    dialogRef.afterClosed().subscribe(() => {
      if (success) {
        if (!this.verificationSent) {
          this.showVerificationForm = true;
          this.buildVerificationForm();
          this.verificationSent = true;
          return;
        }
        return this.router.navigate(['login']);
      }
    });
  }

  /**
   * builds the forgot password (with verification code) form
   */
  buildVerificationForm() {
    this.verificationForm = this.fb.group(
      {
        verificationCode: ['', Validators.required],
        newPassword: ['', Validators.required],
        confNewPassword: ['', Validators.required]
      },
      {
        validators: passwordMatchValidator
      }
    );
  }

  /**
   * @description handles visibility for the password and confirm password fields
   */
  passwordVisibility(input: HTMLInputElement) {
    switch (input.name) {
      case 'newPassword':
        this.newPasswordVisibility =
          this.newPasswordVisibility === 'visibility'
            ? 'visibility_off'
            : 'visibility';
        this.newPasswordType =
          this.newPasswordType === 'text' ? 'password' : 'text';
        break;
      case 'confNewPassword':
        this.confNewPasswordVisibility =
          this.confNewPasswordVisibility === 'visibility'
            ? 'visibility_off'
            : 'visibility';
        this.confNewPasswordType =
          this.confNewPasswordType === 'text' ? 'password' : 'text';
        break;
    }
  }

  /**
   * @description handles submission of the forgot password form (once user has verification code)
   */
  submitVerification() {
    if (this.verificationForm.status === 'VALID') {
      const code = this.verificationForm.get('verificationCode')?.value;
      const password = this.verificationForm.get('newPassword')?.value;
      this.amplifyService.resetPasswordWithCode(
        this.email.toLowerCase(),
        code,
        password,
        this
      );
    }
  }
}
