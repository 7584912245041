import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { CcmcApiService } from './ccmc-api.service';
import { Navigation } from '../models/navigation.model';
import { navigation } from '../../app/navigation/navigation';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { timingSafeEqual } from 'crypto';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService {
  currentField: any;
  mappingObject: any;
  transactionsObject: any;
  mappingFoundObject: any;
  globalSearchFields: any;
  navigation: Navigation;
  dataSource: any;
  globalSearchIndex = 0;
  globalSearchCounter = '';
  public globalSearchRefresh: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  /**
   * Creates an instance of GlobalSearchService.
   * @param {CcmcApiService} ccmcApiService
   * @param {Router} router
   * @memberof GlobalSearchService
   */
  constructor(private ccmcApiService: CcmcApiService, private router: Router) {}

  initData() {
    this.mappingObject = this.ccmcApiService.mapping;
    this.transactionsObject = this.ccmcApiService.transactions;
    this.dataSource = new MatTableDataSource(this.mappingObject);
  }

  /**
   * Global Search
   * @description performs the global search on all transactions
   * @param {string} searchString
   * @memberof GlobalSearchService
   */
  globalSearch(searchString: string) {
    this.dataSource.filterPredicate = function (data: any, filter: string): boolean {
      // tslint:disable-next-line: max-line-length
      return (
        data.fieldID.toLowerCase().includes(filter) ||
        data.fieldDisplay.toLowerCase().includes(filter) ||
        data.fieldValue.toLowerCase().includes(filter)
      );
    };
    this.globalSearchIndex = -1;
    this.dataSource.filter = searchString;
    this.mappingFoundObject = [];
    this.globalSearchFields = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      if (this.dataSource.filteredData[i].display) {
        this.mappingFoundObject.push(this.dataSource.filteredData[i]);
      }
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.transactionsObject.length; i++) {
      if (this.transactionsObject[i].display) {
        // tslint:disable-next-line: prefer-for-of
        for (let j = 0; j < this.mappingFoundObject.length; j++) {
          if (
            this.mappingFoundObject[j].transactionID ===
            this.transactionsObject[i].transactionID
          ) {
            this.globalSearchFields.push(this.mappingFoundObject[j]);
          }
        }
      }
    }
    if (this.globalSearchFields.length > 0) {
      this.getNext();
    } else {
      this.globalSearchFields = undefined;
    }
  }

  /**
   * Get Next
   * @description gets the next index of the global searhc fields
   * @memberof GlobalSearchService
   */
  getNext() {
    if (this.globalSearchIndex < this.globalSearchFields.length - 1) {
      this.globalSearchIndex++;
    }
    this.currentField = this.globalSearchFields[this.globalSearchIndex];
    this.globalSearchCounter =
      this.globalSearchIndex + 1 + '/' + this.globalSearchFields.length;
    this.router.navigateByUrl(
      '/transactions/' +
        this.globalSearchFields[this.globalSearchIndex].transactionID
    );
  }

  /**
   * Get Previous
   * @description get the previous field for the global search fields
   * @memberof GlobalSearchService
   */
  getPrev() {
    this.globalSearchIndex--;
    if (this.globalSearchIndex < 0) {
      this.globalSearchIndex = 0;
    }
    this.currentField = this.globalSearchFields[this.globalSearchIndex];
    this.globalSearchCounter =
      this.globalSearchIndex + 1 + '/' + this.globalSearchFields.length;
    this.router.navigateByUrl(
      '/transactions/' +
        this.globalSearchFields[this.globalSearchIndex].transactionID
    );
  }

  /**
   * Clear Data
   * @description clears the data used in global search
   * @memberof GlobalSearchService
   */
  clearData() {
    this.globalSearchFields = undefined;
    this.currentField = undefined;
    this.globalSearchIndex = undefined!;
  }
}
