import { Injectable } from '@angular/core';
import { CcmcApiService } from './ccmc-api.service';
import { environment } from 'src/environments/environment';
import { AssetService } from './asset.service';

@Injectable({
  providedIn: 'root'
})
export class NcinoService {
  currentLoanKey: string;
  constructor(
    private ccmcApiService: CcmcApiService,
    private assetService: AssetService
  ) { }

  /**
   * Retrieve Files
   * @description displays and sorts the loans, updates the badge count
   * @param {*} callback
   * @memberof NcinoService
   */
  retrieveFiles(callback: any) {
    this.ccmcApiService.s3ListFiles('ncino-loans').subscribe((res: any) => {
      if (res) {
        return callback.handleRetrievedFiles(null, res.body);
      }
      const errorMessage = {
        message: 'No files found.',
        title: 'No Results'
      };

      return callback.handleRetrievedFiles(errorMessage, null);
    });
  }

  /**
   * Loan Selected
   * @description gets the loan information and returns the populated core mapping
   * @param {*} key
   * @param {*} callback
   * @memberof NcinoService
   */
  loanSelected(key: any, callback: any) {
    this.currentLoanKey = key;
    this.ccmcApiService.s3GetFile('ncino-loans', key).subscribe((res: any) => {
      if (res.statusFlag) {
        console.log('s3getFile result ', res);
        this.ccmcApiService.ice(res.content).subscribe(
          (response: any) => {
            console.log(response);
            if (response.statusFlag === true) {
              return callback.handleCoreMappingResponse(null, response);
            } else if (response.statusMessage) {
              const errorMessage = {
                message: response.statusMessage,
                title: 'Ice Error'
              };
              return callback.handleCoreMappingResponse(errorMessage, null);
            } else {
              const errorMessage = {
                message: response.errorMessage,
                title: 'Ice Error'
              };
              return callback.handleCoreMappingResponse(errorMessage, null);
            }
          },
          error => {
            console.log(error);
            const errorMessage = {
              message: error,
              title: 'Ice Error'
            };
            return callback.handleCoreMappingResponse(errorMessage, null);
          }
        );
      } else {
        const errorMessage = {
          message: 'Error Retrieving Loan',
          title: 'Loan Error'
        };
        return callback.handleCoreMappingResponse(errorMessage, null);
      }
    });
  }

  /**
   * Loan Booked
   * @description Defines what is done to the LOS after the loan has been booked
   * This is a required function, leave empty if nothing is required for the LOS
   * @param {*} result
   * @memberof NcinoService
   */
  loanBooked(result: any, configurations: any) {
    if (!configurations.demoMode && !configurations.implementationMode) {

      // Record Sender
      this.ccmcApiService.recordSender().subscribe(result => {
        console.log(result);
      });
      if (environment.production) {
        let productType = this.assetService.getSelectedProduct();
        if (
          productType.includes('mortgage') ||
          productType.includes('consumer') ||
          productType.includes('commercial')
        ) {
          // send the booking result to nCino
          this.ccmcApiService
            .ncinoBookingResults({
              assetId: this.assetService.getSelectedAssetId(),
              statusSummary: result.statusMessage,
              statusCode: 'BOOKED',
              loanKey: this.currentLoanKey
            })
            .subscribe(response => {
              console.log('response ', response)
              // deletes the loan from S3
              this.ccmcApiService
                .s3DeleteFile('ncino-loans', this.currentLoanKey, 'loan-booked')
                .subscribe(response => console.log('response ', response));
            });
        }

      }
    }
  }

  async deleteLoan(loanPath: any) {
    return new Promise(resolve => {
      this.ccmcApiService
        .s3DeleteFile('ncino-loans', loanPath, 'user-deletion')
        .subscribe(response => {
          console.log('response ', response);
          resolve(response);
        });
    });
  }
}
