import { Component, OnInit, NgZone } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CcmcApiService } from '../../services/ccmc-api.service';

@Component({
  selector: 'app-add-usergroup',
  templateUrl: './add-usergroup.component.html',
  styleUrls: ['./add-usergroup.component.scss']
})
export class AddUsergroupComponent implements OnInit {
  username: any;
  usergroup: any;
  constructor(
    private ccmcApiService: CcmcApiService,
    private snackBar: MatSnackBar,
    private zone: NgZone,
    private dialogRef: MatDialogRef<AddUsergroupComponent>
  ) {}

  ngOnInit() {}

  addUserToGroup() {
    this.ccmcApiService
      .addUserToGroup(this.username.toLowerCase(), this.usergroup)
      .subscribe(result => {
        const parsedResult = JSON.parse(JSON.stringify(result));
        if (parsedResult.errorMessage) {
          this.openSnackBar(parsedResult.errorMessage, 'Okay');
        } else {
          this.openSnackBar(JSON.stringify(result), 'Okay');
        }
      });
  }

  openSnackBar(message: string, action: string) {
    this.zone.run(() => {
      setTimeout(() => {
        this.snackBar.open(message, action, {
          duration: 5000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }, 0);
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
