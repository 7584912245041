import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
// import {
//   onAuthUIStateChange,
//   CognitoUserInterface,
//   AuthState
// } from '@aws-amplify/ui-components';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';

@Component({
  selector: 'app-amplify-qr-code-dialog',
  templateUrl: './amplify-qr-code-dialog.component.html',
  styleUrls: ['./amplify-qr-code-dialog.component.scss']
})
export class AmplifyQrCodeDialogComponent implements OnInit {
  // user: CognitoUserInterface | undefined;
  // authState: AuthState | undefined;
  elementType = 'url';
  correctionLevel = NgxQrcodeErrorCorrectionLevels.MEDIUM;
  value = '';
  setupTOTPResponse: {
    statusFlag: boolean;
    statusMessage: string;
    content: any;
  };
  qrCodeResponse = '';
  codeFromUser: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public localUser: any,
    private amplifyService: AmplifyService,
    private ref: ChangeDetectorRef,
    private dialogRef: MatDialogRef<AmplifyQrCodeDialogComponent>,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    if (!this.localUser.Session) {
      this.amplifyService.callTOTP(this.localUser).then(callTOTPResponse => {
        console.log(callTOTPResponse);
        let code = callTOTPResponse.content;
        this.value =
          'otpauth://totp/AWSCognito:' +
          this.localUser.username +
          '?secret=' +
          code +
          '&issuer=' +
          'NXTsoft';
      });
    }

    // onAuthUIStateChange((authState, authData) => {
    //   this.authState = authState;
    //   this.user = authData as CognitoUserInterface;
    //   console.log(this.authState);
    //   console.log(JSON.parse(JSON.stringify(this.user)));
    //   this.ref.detectChanges();
    // });
  }
  async checkCode() {
    if (!this.localUser.Session) {
      let verifyTotpResponse: any = await this.amplifyService.verifyTotpCode(
        this.localUser,
        this.codeFromUser
      );
      if (verifyTotpResponse['statusFlag']) {
        this.dialogRef.close(true);
      } else {
        this.snackbarService.openSnackBar(
          verifyTotpResponse['statusMessage'],
          'Okay'
        );
      }
    } else {
      let setAuthResponse: any =
        await this.amplifyService.setAuthSigninSessionConfirmed(
          this.localUser,
          this.codeFromUser
        );
      if (setAuthResponse['statusFlag']) {
        this.dialogRef.close(true);
      } else {
        this.snackbarService.openSnackBar(
          setAuthResponse['statusMessage'],
          'Okay'
        );
      }
    }
  }
}
