<p>Request Change</p>
<div>
  <div style="grid-template-columns: 1fr 1fr; display: grid">
    <ng-container>
      <mat-form-field>
        <mat-label>Loan Number/File Number</mat-label>
        <mat-select [(ngModel)]="selectedRow.loanNumber" name="loannum">
          <mat-option *ngFor="let LoanNum of LoanNums" [value]="LoanNum.value">
            {{ LoanNum.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container>
      <mat-form-field>
        <input
          matInput
          #sourceInput
          placeholder="Corrected Field Value"
          maxlength="{{ allowedFieldWidth }}"
          [(ngModel)]="selectedRow.correctedValue"
        />
      </mat-form-field>
    </ng-container>
    <ng-container>
      <mat-form-field>
        <input
          readonly
          matInput
          #sourceInput
          placeholder="Field Display"
          [(ngModel)]="selectedRow.fieldDisplay"
        />
      </mat-form-field>
    </ng-container>
    <ng-container>
      <mat-form-field>
        <mat-label>Item Priority</mat-label>
        <mat-select [(ngModel)]="selectedRow.urgency" name="ergency">
          <mat-option *ngFor="let ergency of ergencys" [value]="ergency.value">
            {{ ergency.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
  <ng-container>
    <mat-form-field style="width: 100%" appearance="outline">
      <mat-label>Description</mat-label>
      <textarea
        class="textareanotes"
        matInput
        placeholder="Description"
        [(ngModel)]="selectedRow.description"
      ></textarea>
    </mat-form-field>
  </ng-container>
</div>
<button mat-raised-button class="cancel-btn" (click)="closeBox()">
  <span>Cancel</span>
</button>
<button mat-raised-button class="continue-btn" (click)="continue()">
  <span>Submit &nbsp;</span>
  <mat-icon class="continue-icon">forward </mat-icon>
</button>

<!--Spinner-->
<div class="spinner-container" *ngIf="showSpinner">
  <div class="new-spinner">
    <div class="outer-ring"></div>
    <div class="middle-ring"></div>
    <div class="inner-ring"></div>
  </div>
</div>
