<div class="new-transaction-container">
    <div class="moveContainer" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle></div>
    <div style="display: flex; flex-direction: row; justify-content: space-between">
        <div>
            <h1>New Target Layout Column</h1>
        </div>
        <div>
            <h1>
                <i class="material-icons noselect" (click)="closeDialog()"> close </i>
            </h1>
        </div>
    </div>
    <div class="form-container">
        <div style="
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
        column-gap: 15px;
      ">
            <div *ngFor="let field of targetLayoutFields; let i = index">
                <mat-form-field class="mat-form-field1" *ngIf="field !== 'edited' && targetLayoutTypes[i] === 'number'">
                    <input matInput type="number" placeholder="{{ field }}" [(ngModel)]="newTargetLayoutColumn[field]" (keydown.enter)="addTransaction()" />
                </mat-form-field>
                <mat-form-field class="mat-form-field1" *ngIf="field !== 'edited' && targetLayoutTypes[i] !== 'number' && targetLayoutTypes[i] !== 'boolean'">
                    <input matInput  placeholder="{{ field }}" [(ngModel)]="newTargetLayoutColumn[field]" (keydown.enter)="addTransaction()" />
                </mat-form-field>
                <mat-form-field
                    *ngIf="targetLayoutTypes[i] === 'boolean' && field !== 'edited'"
                    class="mat-form-field1"
                >
                    <mat-select
                        placeholder="{{ field }}"
                        [(ngModel)]="newTargetLayoutColumn[field]"
                        (keydown.enter)="addTransaction()"
                    >
              <mat-option [value]="true">true</mat-option>
              <mat-option [value]="false">false</mat-option>
            </mat-select>
          </mat-form-field>
            </div>
        </div>
    </div>
    <div class="btn-Container">
        <button class="cancelBtn" mat-stroked-button (click)="closeDialog()">
      Cancel
    </button>
        <button class="addBtn" mat-raised-button (click)="addTransaction()">
      <mat-icon style="color: #3fb2c6">add_circle</mat-icon> &nbsp;Add
      Transaction
    </button>
    </div>
</div>