import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CCMCForceChangePasswordComponent } from './force-change-password.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';
import { CCMCDirectivesModule } from '../../directives/directives';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'force-change-password',
    component: CCMCForceChangePasswordComponent
  }
];
@NgModule({
  imports: [
    CommonModule,
    CCMCDirectivesModule,
    ReactiveFormsModule,
    AppMaterialModule,
    RouterModule.forChild(routes)
  ],
  declarations: [CCMCForceChangePasswordComponent],
  entryComponents: [CCMCForceChangePasswordComponent],
  exports: [CCMCForceChangePasswordComponent]
})
export class CCMCForceChangePasswordModule {}
