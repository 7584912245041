<div class="addUsergroupContainer">
  <div
    class="moveContainer"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  ></div>
  <div
    style="display: flex; flex-direction: row; justify-content: space-between"
  >
    <div>
      <h1>Add User to Usergroup</h1>
    </div>
    <div>
      <h1>
        <i class="material-icons noselect" (click)="closeDialog()"> close </i>
      </h1>
    </div>
  </div>
  <div>
    <mat-form-field class="username">
      <input matInput placeholder="Username..." [(ngModel)]="username" />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="usergroup">
      <input matInput placeholder="Usergroup..." [(ngModel)]="usergroup" />
    </mat-form-field>
  </div>
  <div class="btn-container">
    <button class="cancelBtn" mat-stroked-button (click)="closeDialog()">
      Cancel
    </button>
    <button class="addBtn" mat-raised-button (click)="addUserToGroup()">
      <mat-icon style="color: #3fb2c6">add_circle</mat-icon> &nbsp;Submit
    </button>
  </div>
</div>
