<!--Background Image-->
<img class="BG-image" src="assets/images/backgrounds/light-grey-tech.png">
<div>
  <ng-template #resultTemplate>
    <div class="forgot-password__results">
      <div class="title" matDialogTitle style="color: black;font-size: 17px;   
      color: #615f5f;">Forgot Password Recovery</div>
      <div class="title" mat-dialog-content style="color: black;">{{ forgotPasswordResultDialog.msg }}</div>
    </div>
    <div mat-dialog-actions class="pt-24 error-actions">
      <button mat-raised-button class="mat-accent"
        (click)="forgotPasswordResultDialog.dialogRef.close(true)">OK</button>
    </div>
  </ng-template>

  <div class="forgot-password">
    <div class="forgot-password__form-wrapper">
      <div class="forgot-password__logo-wrapper">
        <img src="assets/images/logos/GC-rocket-omni1.png" class="forgot-password__logo" alt="CCMC Logo">
      </div>
      <ng-container *ngIf="!showVerificationForm">

        <!--Title-->
        <div class="title" [textContent]="'Forgot Password'"></div>
        <form [formGroup]="forgotPasswordForm" class="forgot-password__form">

          <!--Email Input-->
          <mat-form-field class="forgot-password--form-field">
            <input matInput type="text" placeholder="Email" formControlName="email">
            <mat-error>Please enter a valid email address</mat-error>
            <mat-icon class="forgot-password__email-icon" matPrefix>person</mat-icon>
          </mat-form-field>

          <div class="forgot-password__button-wrapper">
            <button mat-raised-button class="forgot-password__button" color="primary" type="submit"
              (click)="submitRequest()" aria-label="Forgot Password" [disabled]="forgotPasswordForm.invalid"
              [class.disabled]="forgotPasswordForm.invalid">Submit</button>
          </div>
        </form>
      </ng-container>

      <ng-container *ngIf="showVerificationForm">
        <h2 [textContent]="'Reset Password' | uppercase"></h2>
        <form [formGroup]="verificationForm" class="forgot-password__form" ccmcPasswordMatcher>
          
          <!--Verification Input-->
          <div>
            <mat-form-field class="forgot-password--form-field1">
              <input matInput type="text" placeholder="Verification Code" formControlName="verificationCode">
              <mat-error>Please enter a verification code</mat-error>
            </mat-form-field>
          </div>

          <!--New Password Input-->
          <div class="tooltip">
          <div>
            <mat-form-field class="forgot-password--form-field2">
              <input matInput #newPassword [type]="newPasswordType"
                class="force-change-password__form__new-password--input force-change-password__form--input"
                formControlName="newPassword" placeholder="New Password" name="newPassword">
              <mat-error>
                new password is required
              </mat-error>
            </mat-form-field>
            <button type="button" (click)="passwordVisibility(newPassword)">
              <mat-icon class="force-change-password__form--visibility s-20">
                {{ newPasswordVisibility }}
              </mat-icon>
            </button>

            <!--inside input-->
<div class="arrow-left"></div>
<div class="tooltiptext">
<ul style="position:relative; right:8px;">
  <li>8 character length required</li><br>
  <li>Uppercase required</li><br>
  <li>Lowercase required</li><br>
  <li>Number required</li><br>
  <li>Special character required</li>
  </ul>
</div>



          </div>
        </div>
          <!--Confirm New Password Input-->
          <div>
            <mat-form-field class="forgot-password--form-field2">
              <input matInput #confNewPassword [type]="confNewPasswordType"
                class="change__password--form__new-password--input change__password--form--input" name="confNewPassword"
                formControlName="confNewPassword" placeholder="Confirm New Password"
                [errorStateMatcher]="confirmValidParentMatcher">
              <mat-error
                *ngIf="verificationForm.get('confNewPassword')?.touched && verificationForm.get('confNewPassword')?.errors?.required">
                confirm password is required
              </mat-error>
              <mat-error>
                {{ errors.confNewPassword }}
              </mat-error>
            </mat-form-field>
            <button type="button" (click)="passwordVisibility(confNewPassword)">
              <mat-icon class="force-change-password__form--visibility s-20">
                {{ confNewPasswordVisibility }}
              </mat-icon>
            </button>
          </div>

          <!--Submit Button-->
          <div class="forgot-password__button-wrapper">
            <button mat-raised-button class="forgot-password__button" color="accent" type="submit"
              (click)="submitVerification()" aria-label="Forgot Password" [disabled]="verificationForm.invalid"
              [class.disabled]="verificationForm.invalid">Submit</button>
          </div>
        </form>
      </ng-container>

      <!--Back to Login Link-->
      <div style="padding-bottom: 7px;">
        <a class="forgot-password__login-link" [routerLink]="['/login']">Back to Login</a>
      </div>
    </div>
    <div>
      <!--CCMC Logo-->
      <img src="assets/images/logos/omni_connect.png" class="ccmcLogo">
    </div>
  </div>
</div>