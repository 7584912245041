<div>
  <h1>Select Set</h1>
  <mat-form-field appearance="fill" style="width: 100%">
    <mat-label>Select an option</mat-label>
    <mat-select [(value)]="selectedSet" style="width: 100%">
      <mat-option *ngFor="let set of sets" [value]="set">{{
        set.setName
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <div style="display: grid; grid-template-columns: 1fr 1fr; width: 100%">
    <div style="text-align: left">
      <button
        mat-raised-button
        class="cancel-btn"
        (click)="closeDialog()"
        id="cancel-button"
      >
        Cancel
      </button>
    </div>
    <div style="text-align: right">
      <button
        mat-raised-button
        class="continue-btn"
        [ngClass]="{ disabled: !checkContinue() }"
        [disabled]="!checkContinue()"
        (click)="submit()"
        id="continue-button"
        color="accent"
      >
        <span class="button-text">Submit</span>
      </button>
    </div>
  </div>
</div>
