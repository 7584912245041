import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-set-transaction-dialog',
  templateUrl: './edit-set-transaction-dialog.component.html',
  styleUrls: ['./edit-set-transaction-dialog.component.scss']
})
export class EditSetTransactionDialogComponent implements OnInit {
  localTransaction: any;
  constructor(
    public dialogRef: MatDialogRef<EditSetTransactionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public transaction: any
  ) {}

  ngOnInit() {
    this.localTransaction = JSON.parse(JSON.stringify(this.transaction));
  }

  closeDialog() {
    this.dialogRef.close();
  }

  update() {
    const jsonFields = Object.keys(this.localTransaction);
    for (let field of jsonFields) {
      this.transaction[field] = this.localTransaction[field];
    }
    this.dialogRef.close(true);
  }
}
