<!--Add Validation Dialog Box-->
<div class="add-validationContainer">
  <div>
    <h1>Add Validation</h1>
  </div>
  <div class="add-validation-container">
    <div class="form-add-validation">
      <mat-form-field style="width: 300px; padding-right: 40px;">
        <input matInput #displayNameInput placeholder="Display Name" [(ngModel)]="tempValidation.displayName">
      </mat-form-field>
      <mat-form-field style="width: 300px;">
        <input matInput #valueInput placeholder="Value" [(ngModel)]="tempValidation.value">
      </mat-form-field>
    </div>
  </div>
  <div class="add-validation-container">
    <div class="form-add-validation">
      <mat-form-field class="filter-FormField" style="width: 100%;">
        <input type="text" #fieldIDInput placeholder="Field ID" matInput [formControl]="myControlFieldID" [matAutocomplete]="auto1" [(ngModel)]="tempFieldID">
        <mat-autocomplete #auto1="matAutocomplete">
          <mat-option *ngFor="let option of filteredOptionsFieldID | async" [value]="option['fieldID']">
            {{option['fieldID']}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>


  <div class="Btn-Container">
    <button class="cancelBtn-addValidation" mat-stroked-button (click)="closeDialog()">Close</button>
    <button class="addBtn" mat-raised-button (click)="addValidation()">
      <mat-icon style="color:#3FB2C6;">add_circle</mat-icon> &nbsp;Add
    </button>
  </div>
</div>