import { Injectable, NgZone } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, map, takeUntil, take, filter, catchError } from 'rxjs/operators';
import { Navigation } from '../../@ccmc/models/navigation.model';
import { navigation } from '../../app/navigation/navigation';
import { APPCONSTANTS } from 'src/app/app.constants';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { Router } from '@angular/router';
import { CCMCToolbarComponent } from 'src/app/main/toolbar/toolbar.component';
import { AuthService } from 'src/app/auth/auth.service';
import { SpinnerService } from './spinner.service';
import { CCMCSplashScreenService } from './splash-screen.service';
import { CookieService } from 'ngx-cookie-service';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { environment } from 'src/environments/environment';
import { AmplifyService } from './amplify.service';
const { Buffer } = require('buffer');
@Injectable({
  providedIn: 'root'
})
export class SalesforceService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  private baseUrl: string = `https://api.nxtsoft.com/v1`;
  navigation: Navigation;
  salesforceAccountID: any;
  assets: any;
  contactID: any;
  email: any;
  hasAccountSelector = false;
  // tslint:disable-next-line: ban-types
  private _contactData = new BehaviorSubject<any>(null);
  private _accountData = new BehaviorSubject<any>(null);

  setAccountID(salesforceAccountID: any) {
    this.salesforceAccountID = salesforceAccountID;
  }

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private router: Router,
    private amplifyService: AmplifyService,
    private splashScreenService: CCMCSplashScreenService,
    private cookieService: CookieService,
    private zone: NgZone,
    private customErrorHandlerService: CustomErrorHandlerService
  ) {
    this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, PUT'
    );
    this.httpOptions.headers.append('Accept', 'application/json');
  }

  getAccountData(): Observable<any> {
    const body = JSON.stringify({
      accountID: this.salesforceAccountID
    });
    console.log(body);
    return this.http
      .post<any[]>(`${Buffer.from(environment.environmentURL, "base64").toString()}/salesforce/account`, body, this.httpOptions)
      .pipe(
        tap(this.setAccountData.bind(this)),
        map(result => {
          return JSON.parse(JSON.stringify(result)).content;
        })
      );
  }

  setAccountData(data: any) {
    console.log(data);
    const accountData = JSON.parse(data.content.StatusMessage);
    console.log(accountData);
    this._accountData.next(accountData);
  }

  getContactData(): Observable<any> {
    const body = JSON.stringify({
      accountID: this.salesforceAccountID
    });
    return this.http
      .post<any[]>(`${Buffer.from(environment.environmentURL, "base64").toString()}/salesforce/contact`, body, this.httpOptions)
      .pipe(
        tap(this.setContactData.bind(this)),
        map(result => {
          return JSON.parse(JSON.stringify(result)).content;
        })
      );
  }

  setContactData(data: any) {
    this._contactData.next(JSON.parse(data.content.StatusMessage));
  }

  clearData() {
    this._contactData.next(undefined);
    this._accountData.next(undefined);
  }

  getContactID(email: any): Observable<any> {
    const body = JSON.stringify({
      email: email
    });
    console.log(body);
    return this.http
      .post<any[]>(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/salesforce/contact-id`,
        body,
        this.httpOptions
      )
      .pipe(
        tap(this.setContactID.bind(this)),
        map(result => {
          return JSON.parse(JSON.stringify(result)).content;
        })
      );
  }
  setContactID(data: any) {
    console.log(data);
    if (data.content.StatusFlag === true) {
      this.contactID = JSON.parse(data.content.StatusMessage).contactID;
      console.log(this.contactID);
    } else {
      this.contactID = null;
    }
  }
  addNewContactInfo(packagedContact: any): Observable<any> {
    /* "contact": { 
      "accountID": "0011R00002NfcCyQAJ", 
      "contactEmail": "testers.com.invalid", 
      "contactFirstName": "test", 
      "contactID": "", 
      "contactLastName": "testerson", 
      "contactPhone": "", 
      "contactStatus": "Active", 
      "contactTitle": "master tester", 
      "contactRole": "" } */
    const body = JSON.stringify(packagedContact);
    console.log(body);
    console.log(packagedContact);
    return this.http
      .post<any[]>(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/salesforce/edit-contact`,
        body,
        this.httpOptions
      )
      .pipe(
        map(result => {
          // console.log(result);
          return JSON.parse(JSON.stringify(result));
        })
      );
  }
}
