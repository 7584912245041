import { Injectable } from '@angular/core';
import { CcmcApiService } from './ccmc-api.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { catchError } from 'rxjs/operators';
import { AssetService } from './asset.service';
import { APPCONSTANTS } from 'src/app/app.constants';
import { AdminApiService } from './admin-api.service';
import { MatDialog } from '@angular/material/dialog';
import { DocConnectorHistoryResponseDialogComponent } from '../components/doc-connector-history-response-dialog/doc-connector-history-response-dialog.component';
import { SpinnerService } from './spinner.service';
import { Router } from '@angular/router';
import { AmplifyService } from './amplify.service';
const { Buffer } = require('buffer');
@Injectable({
  providedIn: 'root'
})
export class EncompassService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  currentLoanKey: string;
  encompassAccessToken: string;
  constructor(
    private ccmcApiService: CcmcApiService,
    private http: HttpClient,
    private customErrorHandlerService: CustomErrorHandlerService,
    private assetService: AssetService,
    private dialog: MatDialog,
    private spinnerService: SpinnerService,
    private router: Router,
    private amplifyService: AmplifyService
  ) {
    this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, PUT'
    );
    this.httpOptions.headers.append('Accept', 'application/json');
  }

  /**
   * Set Access Token
   * @description Sets the access token for encompass environment
   * @param {*} token
   * @memberof EncompassService
   */
  setAccessToken(token: any) {
    this.encompassAccessToken = token;
  }
  getJSONSchema(params: any) {
    return this.http
      .post(`${Buffer.from(environment.environmentURL, "base64").toString()}/encompass/get-json-schema`, params, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }
  /**
   * Loan Lookup
   * @description looks up a loan in the encompass communicator
   * @param {*} params
   * @returns
   * @memberof EncompassService
   */
  loanLookUp(params: any) {
    let url: any;
    if (params.env === 'Client') {
      url = 'aHR0cHM6Ly9hcGkubnh0c29mdC5jb20vdjE=';
    } else if (params.env === 'Nxtsoft') {
      url = 'aHR0cHM6Ly9hcGktZGV2Lm54dHNvZnQuY29tL3Yy';
    } else {
      url = environment.environmentURL;
    }

    params.assetID = this.assetService.getSelectedAssetId();
    if (this.encompassAccessToken) {
      params.accessToken = this.encompassAccessToken;
    }

    // lets hard coded url return access token
    this.amplifyService.environmentOverride = true;
    return this.http
      .post(`${Buffer.from(url, "base64").toString()}/encompass/pipeline-search`, params, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * Get Loan
   * @description Retrieves selected loan from the encompass communicator
   * @param {*} params
   * @returns
   * @memberof EncompassService
   */
  getLoan(params: any) {
    params.assetID = this.assetService.getSelectedAssetId();
    if (this.encompassAccessToken) {
      params.accessToken = this.encompassAccessToken;
    }

    let url: any;
    if (params.env === 'Client') {
      url = 'aHR0cHM6Ly9hcGkubnh0c29mdC5jb20vdjE=';
    } else if (params.env === 'Nxtsoft') {
      url = 'aHR0cHM6Ly9hcGktZGV2Lm54dHNvZnQuY29tL3Yy';
    } else {
      url = environment.environmentURL;
    }

    this.amplifyService.environmentOverride = true;
    
    console.log(params);
    return this.http
      .post(`${Buffer.from(url, "base64").toString()}/encompass/get-loan`, params, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * Loan Booked
   * @description Defines what is done to the LOS after the loan has been booked
   * This is a required function, leave empty if nothing is required for the LOS
   * @param {*} result
   * @memberof EncompassService
   */
  loanBooked(result: any, configurations: any) {
    console.log('configurations', configurations);
    if (!configurations.demoMode && !configurations.implementationMode) {
      // Record Sender
      this.ccmcApiService.recordSender().subscribe(result => {
        console.log(result);
      });
    }
  }

  async sendDocConnectorLoans(instanceID: any, loansToSend: any) {
    let loansReviewObject = [];
    for (let loan of loansToSend) {
      let loanResponse = await this.sendIndividualLoan(instanceID, loan);
      loansReviewObject.push({
        loanResponse,
        loan
      });
    }
    this.spinnerService.setShowSpinner(false);
    console.log('Send DocConnectorLoans Complete', loansReviewObject);
    const dialogRef = this.dialog.open(
      DocConnectorHistoryResponseDialogComponent,
      {
        data: loansReviewObject,
        disableClose: true
      }
    );
    dialogRef.afterClosed().subscribe(res => {
      this.router
        .navigateByUrl('/RefrshComponent', {
          skipLocationChange: true
        })
        .then(() => this.router.navigate(['/doc-connector/history']));
    });
  }

  async sendIndividualLoan(instanceID: any, loan: any) {
    return new Promise(resolve => {
      const sendLoanParams = {
        headers: {
          'Elli-SubscriptionId': ''
        },
        body: {
          meta: {
            instanceId: instanceID,
            resourceId: loan.loanGUID,
            assetId: this.assetService.getSelectedAssetId()
          }
        }
      };
      this.sendLoan(sendLoanParams).subscribe(sendLoanResult => {
        console.log(sendLoanResult);
        return resolve(sendLoanResult);
      });
    });
  }

  sendLoan(params: any) {
    
    console.log(params);
    return this.http
      .post(`${Buffer.from(environment.environmentURL, "base64").toString()}/encompass/doc-connector`, params, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  generatePath(params: any) {
    console.log(params);
    return this.http
      .post(`${Buffer.from(environment.environmentURL, "base64").toString()}/encompass/path-generator`, params, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }
  
  async retrieveEncompassSettings(assetID: string) {
    return new Promise((resolve) => {
      this.retrieveEncompassSettingsDynamoDB(assetID).subscribe(res => {
        console.log("Encompass Settings", res);
        return resolve(res);
      })
    })
  }

  retrieveEncompassSettingsDynamoDB(assetID: string) {
    
    const params =  {
      tableName: 'encompass_settings',
      primaryKeyAttribute: 'assetID',
      primaryKey: assetID
    };
    console.log("Params", params);
    return this.http
      .post(`${Buffer.from(environment.environmentURL, "base64").toString()}/dynamodb/get-document`, params, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }
}
