import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddTargetLayoutColumnDialogComponent } from './add-target-layout-column-dialog.component';
import { FormsModule } from '@angular/forms';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';

@NgModule({
  declarations: [AddTargetLayoutColumnDialogComponent],
  imports: [AppMaterialModule, CommonModule, FormsModule]
})
export class AddTargetLayoutColumnDialogModule {}
