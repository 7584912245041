import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EbmLoggingComponent } from './ebm-logging.component';
import { Routes, RouterModule } from '@angular/router';
import { CCMCSharedModule } from 'src/@ccmc/shared.module';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { Role } from 'src/@ccmc/models/role';

const routes: Routes = [
  {
    path: 'ebm-logging',
    component: EbmLoggingComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: {
      roles: [
        Role.ClientAdmin,
        Role.NxtsoftSales,
        Role.NxtsoftOp,
        Role.NxtsoftDev
      ]
    }
  }
];

@NgModule({
  declarations: [EbmLoggingComponent],
  imports: [CommonModule, RouterModule.forChild(routes), CCMCSharedModule],
  exports: [EbmLoggingComponent]
})
export class EbmLoggingModule {}
