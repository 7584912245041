import { Component, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { CCMCConfigService } from '../../../@ccmc/services/config.service';

@Component({
  selector: 'ccmc-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class CCMCSearchBarComponent implements OnDestroy {
  collapsed: boolean;
  toolbarColor: string;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onInput: EventEmitter<any> = new EventEmitter();
  onConfigChanged: Subscription;

  //switched constructor ccmcConfig setting from "private" to "public"
  constructor(public ccmcConfig: CCMCConfigService) {
    this.collapsed = true;
    this.onConfigChanged = this.ccmcConfig.onConfigChanged.subscribe(
      newSettings => {
        // console.log('newSettings ', newSettings);
        if (

          newSettings.colorClasses &&

          newSettings.colorClasses.toolbar

        ){

          this.toolbarColor = newSettings.colorClasses.toolbar;}
      }
    );
  }

  collapse() {
    this.collapsed = true;
  }

  expand() {
    this.collapsed = false;
  }

  search(event: any) {
    const value = event.target.value;
    this.onInput.emit(value);
  }

  ngOnDestroy() {
    if (this.onConfigChanged) {
      this.onConfigChanged.unsubscribe();
    }
  }
}
