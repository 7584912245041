import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';

@Component({
  selector: 'app-target-environment-config',
  templateUrl: './target-credentials.component.html',
  styleUrls: ['./target-credentials.component.scss']
})
export class TargetCredentialsComponent implements OnInit {
  variables: any;
  oldVariables: any;
  variableKeys: string[];
  editedFlag = false;
  environmentType = 'test';
  isNXTsoft = false;
  isNXTsoftDev = false;
  isNXTsoftOperations = false;
  isNXTsoftSales = false;
  hideFields: any[] = [];
  constructor(
    private ccmcApiService: CcmcApiService,
    private amplifyService: AmplifyService,
    private router: Router,
    private snackBarService: SnackbarService,
    private assetService: AssetService,
    private adminApiService: AdminApiService
  ) {}

  ngOnInit(): void {
    this.isNXTsoft = this.amplifyService.isNXTsoft;
    this.isNXTsoftDev = this.amplifyService.isNXTsoftDev;
    this.isNXTsoftOperations = this.amplifyService.isNXTsoftOperations;
    this.isNXTsoftSales = this.amplifyService.isNXTsoftSales;
    this.getTargetConfigurationTable();
  }

  getTargetConfigurationTable() {
    if (this.assetService.getSelectedCore() === 'premier') {
      this.adminApiService
        .getDynamoDBItem(
          'fiserv_premier_users',
          this.amplifyService.getCognitoId(),
          'cognitoID'
        )
        .subscribe(result => {
          console.log(result);
          if (result) {
            const parsedResult = JSON.parse(JSON.stringify(result));
            this.variables = parsedResult;
            this.oldVariables = JSON.parse(JSON.stringify(this.variables));
            this.variableKeys = Object.keys(this.variables);
            this.hideFields = [];
            for (let variable of this.variableKeys) {
              this.hideFields.push(true);
            }
          } else {
            // if no user found pull generic for schema
            this.adminApiService
              .getDynamoDBItem('fiserv_premier_users', 'generic', 'cognitoID')
              .subscribe(result => {
                console.log(result);
                if (result) {
                  const parsedResult = JSON.parse(JSON.stringify(result));
                  this.variables = parsedResult;
                  this.variables.cognitoID = this.amplifyService.getCognitoId();
                  this.oldVariables = JSON.parse(
                    JSON.stringify(this.variables)
                  );
                  this.variableKeys = Object.keys(this.variables);
                }
                this.hideFields = [];
                for (let variable of this.variableKeys) {
                  this.hideFields.push(true);
                }
              });
          }
        });
    } else if (this.assetService.getSelectedCore() === 'spectrum') {
      this.adminApiService
      .getDynamoDBItem(
        'fiserv_spectrum_users',
        this.amplifyService.getCognitoId(),
        'cognitoID'
      )
      .subscribe(result => {
        console.log(result);
        if (result) {
          const parsedResult = JSON.parse(JSON.stringify(result));
          this.variables = parsedResult;
          this.oldVariables = JSON.parse(JSON.stringify(this.variables));
          this.variableKeys = Object.keys(this.variables);
          this.hideFields = [];
          for (let variable of this.variableKeys) {
            this.hideFields.push(true);
          }
        } else {
          // if no user found pull generic for schema
          this.adminApiService
            .getDynamoDBItem('fiserv_spectrum_users', 'generic', 'cognitoID')
            .subscribe(result => {
              console.log(result);
              if (result) {
                const parsedResult = JSON.parse(JSON.stringify(result));
                this.variables = parsedResult;
                this.variables.cognitoID = this.amplifyService.getCognitoId();
                this.oldVariables = JSON.parse(
                  JSON.stringify(this.variables)
                );
                this.variableKeys = Object.keys(this.variables);
              }
              this.hideFields = [];
              for (let variable of this.variableKeys) {
                this.hideFields.push(true);
              }
            });
        }
      });
    }
  }

  fieldEdited() {
    this.editedFlag = true;
    if (this.isEquivalent(this.variables, this.oldVariables)) {
      console.log('is equiv');
      this.editedFlag = false;
    }
  }

  isEquivalent(a: any, b: any) {
    console.log(a);
    console.log(b);
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      console.log();
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];
      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
        return false;
      }
    }
    return true;
  }
  updateVariables() {
    if (this.assetService.getSelectedCore() === 'premier') {
      this.ccmcApiService
        .createDynamoDB(
          this.environmentType,
          'fiserv_premier_users',
          this.variables
        )
        .subscribe(result => {
          if (result) {
            this.snackBarService.openSnackBar(
              'Updated Target Credentials',
              'Okay'
            );
            this.router
              .navigateByUrl('/RefrshComponent', {
                skipLocationChange: true
              })
              .then(() => this.router.navigate(['/target-environment-config']));
          }
        });
    } else if (this.assetService.getSelectedCore() === 'spectrum') {
      this.ccmcApiService
      .createDynamoDB(
        this.environmentType,
        'fiserv_spectrum_users',
        this.variables
      )
      .subscribe(result => {
        if (result) {
          this.snackBarService.openSnackBar(
            'Updated Target Credentials',
            'Okay'
          );
          this.router
            .navigateByUrl('/RefrshComponent', {
              skipLocationChange: true
            })
            .then(() => this.router.navigate(['/target-environment-config']));
        }
      });
    } else return;
  }
}
