import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-should-send-gl-dialog',
  templateUrl: './should-send-gl-dialog.component.html',
  styleUrls: ['./should-send-gl-dialog.component.scss']
})
export class ShouldSendGlDialogComponent {
  /**
   * Creates an instance of ShouldSendGlDialogComponent.
   * @param {MatDialogRef<CCMCConfirmDialogComponent>} dialogRef
   * @param {*} message
   * @memberof ShouldSendGlDialogComponent
   */
  constructor(public dialogRef: MatDialogRef<ShouldSendGlDialogComponent>) {}
}
