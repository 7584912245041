import { E } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { fromEvent, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil
} from 'rxjs/operators';
import { AddNewDestinationDialogComponent } from 'src/@ccmc/components/add-new-destination-dialog/add-new-destination-dialog.component';
import { CCMCConfirmDialogComponent } from 'src/@ccmc/components/confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from 'src/@ccmc/components/error-dialog/error-dialog.component';
import { SuccessDialogComponent } from 'src/@ccmc/components/success-dialog/success-dialog.component';
import { DocumentConnectorService } from 'src/@ccmc/services/doc-connector.service';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';

@Component({
  selector: 'app-destinations',
  templateUrl: './destinations.component.html',
  styleUrls: ['./destinations.component.scss']
})
export class DestinationsComponent implements OnInit {
  dcDocument: any;
  private selectedDestinationSub: Subscription;
  destinations: any;
  destinationsDisplayedColumns = ['id', 'edited', 'remove'];
  @ViewChild('filterSearch', { static: true }) filterSearchEl: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  unsubscribe: Subject<any> = new Subject();
  selectedDestination: any;
  currentSelection: any = [];
  filterSearch: string;
  private dcSub: Subscription;
  destinationsDataSource: any;
  constructor(
    private selectedFieldService: CCMCSelectedFieldService,
    private fieldEditedService: FieldEditedService,
    private dcService: DocumentConnectorService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getData();
    this.initDestinationFilterSearch();
  }

  initDestinationFilterSearch() {
    // Auto Focus filter search item
    this.filterSearchEl.nativeElement.focus();
    fromEvent(this.filterSearchEl.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // Time in milliseconds between key events
        debounceTime(1000),
        // If previous query is different from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((text: string) => {
        this.applyFilterOnDestinations(text);
      });
  }

  applyFilterOnDestinations(filterValue: string) {
    // Trim the data
    this.destinationsDataSource.filter = filterValue.trim().toLowerCase();
  }

  getData() {
    this.dcSub = this.dcService.dcDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dcDocument => {
        if (dcDocument) {
          console.log('Doc Connector Document', dcDocument);
          this.dcDocument = dcDocument;
          if (this.dcDocument.destinations) {
            this.destinations = this.dcDocument.destinations;
            if (this.destinations.length === 0) {
              return;
            }
            this.destinationsDataSource = new MatTableDataSource(
              this.destinations
            );
            this.destinationsDataSource.paginator = this.paginator;
            this.destinationsDataSource.sort = this.sort;
          }
        } else {
          this.destinations = undefined;
        }
      });
  }

  onSelectDestination(selectedDestination: any) {
    this.selectedDestination = selectedDestination;
    this.selectedFieldService.onDestinationFieldSelected.next(
      selectedDestination
    );
  }

  multiSelectDestinations(selected: any, event: any) {
    if (event.shiftKey) {
      // Selection while holding shift
      let previousSelection = this.currentSelection[this.currentSelection.length - 1];
      let currentSelection = selected;
      let beginningOfSelection: any;
      let endOfSelection: any;
      let counter = 0;

      // loops through the dataSource array to find the top most selection and the bottom
      for (let field of this.destinationsDataSource.filteredData) {
        if (field === previousSelection || field === currentSelection) {
          if (beginningOfSelection === null || beginningOfSelection === undefined) {
            beginningOfSelection = counter;
          } else {
            endOfSelection = counter;
            break;
          }
        }
        counter++;
      }

      // fills currentSelection array with all the fields to highlight
      for (let index = beginningOfSelection; index <= endOfSelection; index++) {
        if (!this.currentSelection.includes(this.destinationsDataSource.filteredData[index])) {
          this.currentSelection.push(this.destinationsDataSource.filteredData[index]);
        }
      }
    } else if (event.ctrlKey) {
      // Selection while holding ctrl
      this.currentSelection.push(selected);
    } else {
      // Regular selection
      this.currentSelection = [];
      this.currentSelection.push(selected);
    }
  }

  addNewDestination() {
    console.log('Add new destination');
    this.dialog.open(AddNewDestinationDialogComponent);
  }

  removeDestination() {
    const confirmDialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
      data: `Are you sure you want to remove the selected destinations?`
    });
    confirmDialogRef.afterClosed().subscribe(data => {
      if (data) {
        let didRemove = false;
        let errorCount = [];
        for (let destination of this.currentSelection) {
          for (let i = 0; i < this.destinations.length; i++) {
            if (this.destinations[i] === destination) {
              this.destinations.splice(i, 1);
              didRemove = true;
            }
          }
          if (didRemove) {
            this.dcDocument.destinations = this.destinations;
            // Update datasource with new data
            this.destinationsDataSource.data = this.destinations;
            this.dcService.setDcDocumentSimple(this.dcDocument);
            this.fieldEditedService.losEdited.next(true);
          } else {
            errorCount.push(destination.id);
          }
        }
        if (errorCount.length > 0) {
          const errorDialogRef = this.dialog.open(ErrorDialogComponent, {
            data: {
              title: 'Error',
              message: `Error removing ${errorCount} from the list`
            }
          });
        } else {
          const successDialogRef = this.dialog.open(SuccessDialogComponent, {
            data: {
              title: 'Success',
              message: `Successfully removed selection from the list`
            }
          });
        }
      }
    });
  }
}
