<i class="material-icons noselect" style="cursor: pointer; margin-top: -6%; margin-right: -8%; float: right" matDialogClose>
  close
</i>
<div *ngIf="value; then thenBlock; else elseBlock"></div>
<ng-template #thenBlock>
    <h3 style="font-weight: 500">Scan then enter verification code</h3>
    <ngx-qrcode [value]="value" cssClass="bshadow"></ngx-qrcode>
    <div>
        <mat-form-field class="filter-FormField">
            <input matInput placeholder="Enter security code" [(ngModel)]="codeFromUser" maxlength="6" />
        </mat-form-field>
        <div>
            <button mat-raised-button color="accent" style="margin-left: 6%; margin-top: 4%" (click)="checkCode()" [disabled]="codeFromUser?.length != 6">
        VERIFY SOFTWARE TOKEN
      </button>
        </div>
    </div>
</ng-template>
<ng-template #elseBlock>
    <p>Enter your 6-digit security code below:</p>
    <div>
        <mat-form-field class="filter-FormField">
            <input matInput placeholder="Security Code" [(ngModel)]="codeFromUser" maxlength="6" />
        </mat-form-field>
        <div>
            <button mat-raised-button color="accent" style="margin-top: 4%; width: 100%" (click)="checkCode()" [disabled]="codeFromUser?.length != 6">
        VERIFY
      </button>
        </div>
    </div>
</ng-template>