<div class="getPathContainer">
    <div class="moveContainer" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle></div>
    <div style="display: flex; flex-direction: row; justify-content: space-between">
        <div>
            <h1>Get Encompass FieldID</h1>
        </div>
        <div>
            <h1>
                <i class="material-icons noselect" (click)="closeDialog()" style="cursor:pointer"> close </i>
            </h1>
        </div>
    </div>
    <div class="content-container">
        <div class="row1">
            <mat-form-field class="field1">
                <input matInput placeholder="JSON Path" [(ngModel)]="jsonPath" (keydown.enter)="getEncompassFieldID()" />
            </mat-form-field>
        </div>
        <div class="row1">
            <mat-form-field class="field1">
                <input matInput placeholder="Encompass ID" [(ngModel)]="encompassID" (keydown.enter)="getEncompassFieldID()" />
            </mat-form-field>
        </div>
    </div>
    <div class="btn-container">
        <button class="cancelBtn" mat-stroked-button (click)="closeDialog()">
      Cancel
    </button>
        <button class="get-path-Btn" color="accent" mat-raised-button (click)="getEncompassFieldID()">
      Get FieldID
    </button>
    </div>

    <div class="spinner-container" *ngIf="showSpinner">
        <div class="new-spinner">
            <div style="position: relative" class="outer-ring"></div>
            <div style="position: relative; bottom: 582px; left: 12px" class="middle-ring"></div>
            <div style="position: relative; bottom: 1163px; left: 25px" class="inner-ring"></div>
        </div>
    </div>
</div>