<div class="gla-translators-gla-translators-container" *ngIf="selected">
  <div class="gla-translators-gla-translators-select-options">
    <div class="select-options-title">Select Options</div>
    <div class="tooltip">
      <button
        mat-raised-button
        matTooltip="Get Encompass FieldID."
        matTooltipClass="toolTipStyle"
        color="primary"
        (click)="getEncompassID()"
        class="gla-translators-gla-translators-get-fieldID-btn"
        style="margin-right: 10px"
        *ngIf="showEncompassPathButton"
      >
        Get FieldID
      </button>
      <button
        mat-raised-button
        matTooltip="Get Encompass path."
        matTooltipClass="toolTipStyle"
        color="primary"
        (click)="getEncompassPath()"
        class="gla-translators-gla-translators-save-btn"
        style="margin-right: 10px"
        *ngIf="showEncompassPathButton"
      >
        Get Path
      </button>
      <button
        mat-raised-button
        matTooltip="Saves any changes you make, within this section."
        matTooltipClass="toolTipStyle"
        color="accent"
        (click)="save()"
        [disabled]="!editedFlag"
        class="gla-translators-gla-translators-save-btn"
      >
        Save
      </button>
    </div>
  </div>
  <div class="select-options-form">
    <div class="field-container-one" style="justify-content: end">
      <mat-form-field class="form-field-one">
        <input
          matInput
          placeholder="Field Name"
          [(ngModel)]="localSelected.fieldName"
          (ngModelChange)="fieldEdited()"
          (keydown.control.s)="save()"
        />
      </mat-form-field>

      <mat-form-field class="form-field-one" style="height: 50px">
        <mat-label>Evaluation Method</mat-label>
        <mat-select
          [(value)]="localSelected.evaluationMethod"
          (selectionChange)="fieldEdited()"
          (keydown.control.s)="save()"
        >
          <mat-option value="path">Path</mat-option>
          <mat-option value="code">Code</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="form-field-two">
        <input
          matInput
          placeholder="Mult Begin"
          [(ngModel)]="localSelected.multBegin"
          (ngModelChange)="fieldEdited()"
          (keydown.control.s)="save()"
        />
      </mat-form-field>

      <mat-form-field class="form-field-three">
        <input
          matInput
          placeholder="Mult End"
          [(ngModel)]="localSelected.multEnd"
          (ngModelChange)="fieldEdited()"
          (keydown.control.s)="save()"
        />
      </mat-form-field>
    </div>

    <mat-form-field style="width: 100%">
      <mat-label style="padding: 5px">Path</mat-label>
      <textarea
        matInput
        [(ngModel)]="localSelected.vbScript"
        (ngModelChange)="fieldEdited()"
        class="VBScript-textarea"
        (keydown.control.s)="save()"
      ></textarea>
    </mat-form-field>
  </div>
</div>

<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
