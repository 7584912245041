import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FieldEditedService {
  /**
   * Field edited
   * @description flag used to track if certain features will be available depending on if there is a current field edited
   * @type {BehaviorSubject<any>}
   * @memberof FieldEditedService
   */
  public fieldEdited: BehaviorSubject<any> = new BehaviorSubject(false);
  public copyNameCheck: BehaviorSubject<any> = new BehaviorSubject({});
  public editedObjects: BehaviorSubject<any> = new BehaviorSubject([]);
  public onGLADocumentEdited: BehaviorSubject<any> = new BehaviorSubject(false);
  public onGLAConfigurationFieldEdited: BehaviorSubject<any> = new BehaviorSubject(false);

  /**
   * Core Edited
   * @description flag used to enable updating the core
   * @type {BehaviorSubject<any>}
   * @memberof FieldEditedService
   */
  public coreEdited: BehaviorSubject<any> = new BehaviorSubject(false);

  public impModechecked: BehaviorSubject<any> = new BehaviorSubject(false);

  public displayAllChecks: BehaviorSubject<any> = new BehaviorSubject(false);

  public newTableChecked: BehaviorSubject<any> = new BehaviorSubject('');

  public listOfReqs: BehaviorSubject<any> = new BehaviorSubject({});

  public refreshTable: BehaviorSubject<any> = new BehaviorSubject(false);

  public activeMFA: BehaviorSubject<any> = new BehaviorSubject(false);

  /**
   * Los Edited
   * @description flag used to enable updating the los
   * @type {BehaviorSubject<any>}
   * @memberof FieldEditedService
   */
  public losEdited: BehaviorSubject<any> = new BehaviorSubject(false);

  public changeRequest: BehaviorSubject<any> = new BehaviorSubject([]);

  public resetAssetLoggingTable: BehaviorSubject<any> = new BehaviorSubject({});

  /**
   * Configurations Field Edited
   * @description flag used to enable saving the json configuration
   * @type {BehaviorSubject<
   *     any
   *   >}
   * @memberof FieldEditedService
   */
  public onConfigurationsFieldEdited: BehaviorSubject<any> =
    new BehaviorSubject({});

  constructor() {}
}
