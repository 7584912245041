<div class="source-destination-page">
  <div class="filepath-container">
    <mat-toolbar class="headerText">
      <div style="width: 25px; display: inline-block">
        <mat-icon
          *ngIf="selectedDestination"
          style="font-size: 14pt; color: #8883e2"
          (click)="clearSelectedDestination()"
          >arrow_back</mat-icon
        >
      </div>
      <a
        (click)="clearSelectedDestination()"
        style="display: inline-block"
        [ngStyle]="selectedDestination && { color: '#8883e2' }"
      >
        Destinations
      </a>
      <div
        *ngIf="selectedDestination"
        style="display: inline-block; padding-left: 5px"
      >
        / {{ selectedDestination?.id }}
      </div>
    </mat-toolbar>
  </div>

  <div style="height: 92%" *ngIf="!selectedDestination">
    <app-destinations></app-destinations>
  </div>
  <div
    style="height: 92%"
    *ngIf="selectedDestination && selectedDestination.id"
  >
    <app-sources></app-sources>
  </div>

  <!-- <div class="AddBtn-Container">
    <button class="Add-btn">
      <mat-icon style="color: #ffffff; transform: scale(1.5)">add</mat-icon>
    </button>
  </div> -->
</div>
