import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportComponent } from './import.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { CCMCSharedModule } from 'src/@ccmc/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';

const routes: Routes = [
  {
    path: 'import',
    component: ImportComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    ImportComponent
  ],
  exports: [
    ImportComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CCMCSharedModule,
    KeyboardShortcutsModule.forRoot(),
    // tslint:disable-next-line: deprecation
    ScrollingModule,
    AppMaterialModule,
    FormsModule,
    MatAutocompleteModule
  ]
})
export class ImportModule { }
