import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CCMCSharedModule } from '../../../@ccmc/shared.module';

import { CCMCContentComponent } from '../../../app/main/content/content.component';
import { CCMCFileSelectorModule } from './file-selector/file-selector.module';
import { CCMCLoginModule } from './login/login.module';
import { CCMCTransactionsModule } from './transactions/transactions.module';
import { CCMCGCSettingsModule } from './gc-settings/gc-settings.module';
import { CCMCAssetidSelectorModule } from './assetid-selector/assetid-selector.module';
import { LoanLookupModule } from './loan-lookup/loan-lookup.module';
import { SupportValuesModule } from './support-values/support-values.module';
import { ValidationModule } from './validation/validation.module';
import { ConfigurationsModule } from './configurations/configurations.module';
import { UsersModule } from './users/users.module';
// import { LoggingModule } from './logging/logging.module';
import { EbmReportModule } from './ebm-report/ebm-report.module';
import { EbmLoggingModule } from './ebm-logging/ebm-logging.module';
import { LoanFilesModule } from './loan-files/loan-files.module';
import { TargetCredentialsModule } from './target-credentials/target-credentials.module';
import { SourceConfigurationsModule } from './source-configurations/source-configurations.module';
import { FundingLedgerModule } from './funding-ledger/funding-ledger.module';
import { GlaTransactionsModule } from './gla-transactions/gla-transactions.module';
import { EncompassLoanSearchModule } from './encompass-loan-search/encompass-loan-search.module';
import { FieldOptionsModule } from './field-options/field-options.module';
import { RequestChangeModule } from './request-change/request-change.module';
import { ChangeRequestDropdownModule } from './change-request-dropdown/change-request-dropdown.module';
import { DocConnectorHistoryModule } from './doc-connector-history/doc-connector-history.module';
import { DocConnectorGlobalMetasModule } from './doc-connector-global-metas/doc-connector-global-metas.module';
import { DocConnectorSourceAndDestinationModule } from './doc-connector-source-and-destination/doc-connector-source-and-destination.module';
import { DocConnectorLogsModule } from './doc-connector-logs/doc-connector-logs.module';
import { DocConnectorSourceModule } from './doc-connector-source-and-destination/doc-connector-source/doc-connector-source.module';

// import { AssetLoggingModule } from './asset-logging/asset-logging.module';
import { TargetLayoutModule } from './target-layout/target-layout.module';
import { GlaTranslatorsModule } from './gla-translators/gla-translators.module';
import { SetConfigurationModule } from './set-configuration/set-configuration.module';
import { ImportModule } from './import/import.module';
import { LoggingModule } from './logging/logging.module';
@NgModule({
  declarations: [CCMCContentComponent],
  imports: [
    RouterModule,
    CCMCFileSelectorModule,
    CCMCLoginModule,
    CCMCTransactionsModule,
    CCMCSharedModule,
    CCMCGCSettingsModule,
    CCMCAssetidSelectorModule,
    SupportValuesModule,
    ValidationModule,
    ConfigurationsModule,
    UsersModule,
    // LoggingModule,
    LoanLookupModule,
    EbmReportModule,
    EbmLoggingModule,
    LoanFilesModule,
    FundingLedgerModule,
    GlaTransactionsModule,
    TargetCredentialsModule,
    SourceConfigurationsModule,
    SetConfigurationModule,
    TargetLayoutModule,
    GlaTranslatorsModule,
    EncompassLoanSearchModule,
    FieldOptionsModule,
    RequestChangeModule,
    ChangeRequestDropdownModule,
    DocConnectorHistoryModule,
    DocConnectorGlobalMetasModule,
    DocConnectorSourceAndDestinationModule,
    DocConnectorLogsModule,
    DocConnectorSourceModule,
    // AssetLoggingModule,
    ImportModule,
    LoggingModule
  ],
  exports: [CCMCContentComponent]
})
export class CCMCContentModule { }
