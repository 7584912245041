import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  NgZone
} from '@angular/core';
import { AmplifyService } from '../../services/amplify.service';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { CcmcApiService } from '../../services/ccmc-api.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { WriteLoggingService } from 'src/@ccmc/services/write-logging.service';

/**
 * Asset Class
 *
 * @export
 * @interface Asset
 */
export interface Asset {
  /**
   * Asset ID
   *
   * @type {string}
   * @memberof Asset
   */
  id: string;
}
/**
 * This component lets users add new users.
 *
 * @export
 * @class AddNewUserDialogComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-add-new-user-dialog',
  templateUrl: './add-new-user-dialog.component.html',
  styleUrls: ['./add-new-user-dialog.component.scss']
})
export class AddNewUserDialogComponent implements OnInit {
  /**
   * Temporary User
   *
   * @memberof AddNewUserDialogComponent
   */
  tmpUser: any;

  /**
   * Creates an instance of AddNewUserDialogComponent.
   * @param {AmplifyService} amplifyService
   * @param {MatDialogRef<AddNewUserDialogComponent>} dialogRef
   * @param {CcmcApiService} ccmcAPIService
   * @param {MatSnackBar} snackBar
   * @param {NgZone} zone
   * @memberof AddNewUserDialogComponent
   */
  constructor(
    private amplifyService: AmplifyService,
    private dialogRef: MatDialogRef<AddNewUserDialogComponent>,
    private ccmcAPIService: CcmcApiService,
    private snackBar: MatSnackBar,
    private zone: NgZone,
    private assetService: AssetService,
    private writeLoggingService: WriteLoggingService
  ) {}

  /**
   * Boolean for if the chips are selected
   *
   * @memberof AddNewUserDialogComponent
   */
  selectable = true;

  /**
   * Boolean for if the chips are removable
   *
   * @memberof AddNewUserDialogComponent
   */
  removable = true;

  /**
   * Add chips together on blue
   *
   * @memberof AddNewUserDialogComponent
   */
  addOnBlur = true;

  /**
   * Current assets list
   *
   * @type {Asset[]}
   * @memberof AddNewUserDialogComponent
   */
  assets: Asset[] = [];

  /**
   * keys to add up the assets
   *
   * @type {number[]}
   * @memberof AddNewUserDialogComponent
   */
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  /**
   * Initializes the add new user dialog component
   *
   * @memberof AddNewUserDialogComponent
   */
  ngOnInit() {
    this.tmpUser = {
      email: '',
      password: '',
      accountID: '',
      assetID: ''
    };
  }

  /**
   * Sends the users information to the db
   *
   * @memberof AddNewUserDialogComponent
   */
  createUser() {
    let tmpAsset = '';
    for (let i = 0; i < this.assets.length; i++) {
      if (i === 0) {
        tmpAsset = this.assets[i].id;
      } else {
        tmpAsset = tmpAsset + ', ' + this.assets[i].id;
      }
    }
    this.tmpUser.assetID = tmpAsset;
    console.log(this.tmpUser);
    // params sent
    const params = {
      email: this.tmpUser.email.toLowerCase(),
      password: this.tmpUser.password,
      accountID: this.tmpUser.accountID,
      assetID: this.tmpUser.assetID
    };
    this.ccmcAPIService.createUser(params).subscribe(res => {
      const result = JSON.parse(JSON.stringify(res));
      console.log(result);
      if (result.errorMessage) {
        // Check if asset contains includes already exists
        if (result.errorMessage.includes('User account already exists')) {
          this.openSnackBar('User account already exists', 'Okay');
        } else {
          this.openSnackBar('Error Creating User', 'Okay');
        }
      } else {
        this.openSnackBar(res.toString(), 'Okay');
        const stream = this.assetService.getSelectedAssetId();
        this.writeLoggingService.setStream(stream);
        this.writeLoggingService
          .writeUserLog({
            username: this.amplifyService.username,
            action: 'User Created',
            time: new Date().toISOString(),
            log: `User: ${this.tmpUser.email.toLowerCase()} was created successfully.`
          })
          .subscribe(result => {
            // logging result
            console.log(result);
          });
      }
    });
  }

  /**
   * Adds current asset to the list of assets
   *
   * @param {MatChipInputEvent} event
   * @memberof AddNewUserDialogComponent
   */
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our asset
    if ((value || '').trim()) {
      this.assets.push({ id: value.trim() });
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  /**
   * Removes the current asset from the list of assets
   *
   * @param {Asset} asset
   * @memberof AddNewUserDialogComponent
   */
  remove(asset: Asset): void {
    const index = this.assets.indexOf(asset);

    if (index >= 0) {
      this.assets.splice(index, 1);
    }
  }

  /**
   * Closes itself
   *
   * @memberof AddNewUserDialogComponent
   */
  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * Opens snackbar with given message and action
   *
   * @param {string} message
   * @param {string} action
   * @memberof AddNewUserDialogComponent
   */
  openSnackBar(message: string, action: string) {
    this.zone.run(() => {
      setTimeout(() => {
        this.snackBar.open(message, action, {
          duration: 5000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }, 0);
    });
  }
}
