import { S3 } from 'aws-sdk';
import { Injectable } from '@angular/core';
import { APPCONSTANTS } from '../../app/app.constants';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { SuccessDialogComponent } from '../components/success-dialog/success-dialog.component';
import { formatDate } from '@angular/common';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from'src/environments/environment';
const { Buffer } = require('buffer');

@Injectable({
    providedIn: 'root'
  })


export class FileService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(
  private http: HttpClient,
  private customErrorHandlerService: CustomErrorHandlerService){}

 async uploadBackuptoS3(params: {bucket:string; key:string; file:any;}){
  const param = {
    Bucket: params.bucket,
    Key: `${params.key}.json`,
    Body: params.file,
    ContentType: 'json'
  };
  return this.http
  .post(
    `${Buffer.from(environment.environmentURL, "base64").toString()}/s3/upload-file/omniportal`,
    param,
    this.httpOptions
  )
  .pipe(catchError(this.customErrorHandlerService.handleError));
  }
}