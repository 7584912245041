import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { fromEvent, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil
} from 'rxjs/operators';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { ReadLoggingService } from '../../logging/read-logging.service';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { ErrorDialogComponent } from 'src/@ccmc/components/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-asset-group',
  templateUrl: './asset-group.component.html',
  styleUrls: ['./asset-group.component.scss']
})
export class AssetGroupComponent implements OnInit {
  /**
   * Unsubscribes from the observables
   *
   * @type {Subject<any>}
   * @memberof LoggingComponent
   */
  unsubscribe: Subject<any> = new Subject();

  /**
   * Subscription to the current Logs retrieved
   *
   * @private
   * @type {Subscription}
   * @memberof LoggingComponent
   */
  private logsSub: Subscription;

  /**
   * Subscription to the spinner
   *
   * @private
   * @type {Subscription}
   * @memberof LoggingComponent
   */
  private spinnerSub: Subscription;

  /**
   * Local instance of the logs
   *
   * @memberof LoggingComponent
   */
  logs: any;

  /**
   * Currently selected log
   *
   * @memberof LoggingComponent
   */
  currentLog: any;

  /**
   * Currnet message of current log
   *
   * @memberof LoggingComponent
   */
  oldValues: any;

  /**
   * Changes that were done in current log
   *
   * @memberof LoggingComponent
   */
  newValues: any;

  /**
   * Current Exception of current log
   *
   * @memberof LoggingComponent
   */
  currentException: any;

  /**
   * Displayed columns used for logging table
   *
   * @memberof LoggingComponent
   */
  displayedColumns = ['user', 'action', 'timestamp'];

  /**
   * DAta for table
   *
   * @type {*}
   * @memberof LoggingComponent
   */
  dataSource: any;

  /**
   * Material Sort Reference
   *
   * @type {MatSort}
   * @memberof LoggingComponent
   */
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /**
   * Material Paginator Reference
   *
   * @type {MatPaginator}
   * @memberof LoggingComponent
   */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /**
   * Filter Search reference
   *
   * @type {ElementRef}
   * @memberof LoggingComponent
   */
  @ViewChild('filterSearch', { static: true }) filterSearchEl: ElementRef;

  /**
   * Filter string
   *
   * @type {string}
   * @memberof LoggingComponent
   */
  filterSearch: string;

  /**
   * XML Beautifier
   *
   * @memberof LoggingComponent
   */
  xmlBeautifier: any;

  /**
   * Show spinner boolean
   *
   * @type {boolean}
   * @memberof LoggingComponent
   */
  showSpinner: boolean;

  /**
   * Max date allowed for date selector
   *
   * @memberof LoggingComponent
   */
  maxDate = new Date();

  /**
   * End date range for logs
   *
   * @memberof LoggingComponent
   */
  endDate = new FormControl(new Date());

  /**
   * Start date range for logs (initializes as 7 days before current date)
   *
   * @memberof LoggingComponent
   */
  startDate = new FormControl(
    new Date(this.endDate.value.getTime() - 2 * 24 * 60 * 60 * 1000)
  );

  /**
   * Logging environment
   * Used to getting logs from proper lambda
   * @memberof LoggingComponent
   */
  loggingEnvironment: any;
  fieldEditedSub: any;
  loggingGroup: any;

  /**
   * Creates an instance of LoggingComponent.
   * @param {ReadLoggingService} readLoggingService
   * @memberof LoggingComponent
   */
  constructor(
    private readLoggingService: ReadLoggingService,
    private spinnerService: SpinnerService,
    private fieldEditedService: FieldEditedService,
    private assetService: AssetService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    this.readLoggingService.selectedLoggingGroup.subscribe(loggingGroup => {
      this.loggingGroup = loggingGroup;
    });
  }

  codemirrorConfig = {
    mode: { name: 'javascript', json: true },
    lineNumbers: true,
    theme: 'material',
    extraKeys: { 'Ctrl-Space': 'autocomplete' }
  };
  /**
   * Initialize the logging component
   *
   * @memberof LoggingComponent
   */
  ngOnInit() {
    this.resetTable();
    // Get Logs
    //this.getLogs();
    // Subscribe to data needed
    this.getData();
    // Auto Focus filter search item
    this.filterSearchEl.nativeElement.focus();
    fromEvent(this.filterSearchEl.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // Time in milliseconds between key events
        debounceTime(1000),
        // If previous query is diffent from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((text: string) => {
        this.applyFilter(text);
      });
  }

  /**
   * Unsubscribe from observables on destroy
   *
   * @memberof LoggingComponent
   */
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  /**
   * Get Data needed for logging component
   *
   * @memberof LoggingComponent
   */
  getData() {
    // Init data as empty array
    this.dataSource = new MatTableDataSource([]);
    // Subscribe to logs
    this.logsSub = this.readLoggingService.logs
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(logs => {
        if (logs) {

          
          this.logs = logs;
          console.log(this.logs);
          if (logs.length > 0) {
            for(var i = 0; i < this.logs.length; i++) {
              if(this.logs[i].action.includes('logged in')){
                this.logs.splice(i, 1);
                i--;
              }
            }
            console.log(this.logs);
            this.dataSource = new MatTableDataSource(this.logs);
            // initializes pagination
            this.dataSource.paginator = this.paginator;
            // initializes sort
            this.dataSource.sort = this.sort;
            if (this.filterSearch) {
              if (this.filterSearch.length > 0) {
                this.applyFilter(this.filterSearch);
              }
            } else {
              this.onSelect(this.dataSource.data[0]);
            }
          } else {
            this.dataSource = new MatTableDataSource([]);
            this.currentLog = undefined;
          }
        }
      });
    

    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        this.showSpinner = spinner;
      });
  }

  /**
   * Apply filter search
   *
   * @param {string} filterValue
   * @memberof LoggingComponent
   */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData[0]) {
      this.onSelect(this.dataSource.filteredData[0]);
    }
    this.filterSearch = filterValue;
  }

  /**
   * Select current log
   *
   * @param {*} selected
   * @memberof LoggingComponent
   */
  onSelect(selected: any) {
    this.currentLog = selected;
    console.log(selected.log);
    this.jsonView(selected.log);
    this.currentException = selected.exception;
  }

  jsonView(jsonLog: any) {
    let oldValueObject = [];
    let newValueObject = [];
    let jsonOld;
    let jsonNew;
    for (let i = 0; i < jsonLog.length; i++) {
      if (jsonLog[i].editedObject) {
        jsonOld = {
          type: jsonLog[i].type,
          log: jsonLog[i].editedObject.oldObject
        };
        oldValueObject.push(jsonOld);
        jsonNew = {
          type: jsonLog[i].type,
          log: jsonLog[i].editedObject.newObject
        };
        newValueObject.push(jsonNew);
      } else {
        oldValueObject = jsonLog;
        newValueObject = jsonLog;
      }
    }
    console.log(oldValueObject);
    console.log(newValueObject);
    this.oldValues = JSON.stringify(oldValueObject, null, 2);
    this.newValues = JSON.stringify(newValueObject, null, 2);
  }

  /**
   * Get logs
   *
   * @memberof LoggingComponent
   */
  async getLogs() {
    if (this.showSpinner) {
      return;
    }
    this.openSnackBar('Fetching asset logs, this may take a moment', 'Okay');
    this.spinnerService.setShowSpinner(true);
    this.readLoggingService.clearData();

    if (this.startDate.value === null || this.endDate.value === null) {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          title: 'Error',
          message: 'One or more date selection fields is blank'
        }
      });
      this.spinnerService.setShowSpinner(false);
      return;
    }

    // Set lower and upper bounds for times
    const finalStartDate = this.startDate.value.setHours(0, 0, 0, 0);
    const finalEndDate = this.endDate.value.setHours(23, 59, 59, 999);
    const assetID = this.assetService.getSelectedAssetId();

    // Init logging params
    let loggingParams = {
      assetID: assetID,
      startDate: new Date(finalStartDate).getTime(),
      endDate: new Date(finalEndDate).getTime(),
      environment: this.loggingEnvironment
    };
    let getLogsResponse: any;
    let logs: any = [];
    let previousLogCount = 0;
    let count = 1;

    // Get logs
    getLogsResponse = await this.getCloudWatchLogs(loggingParams);
    if (!getLogsResponse.statusFlag) {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          title: 'Error',
          message: getLogsResponse.statusMessage
        }
      });
      return;
    }
    if (getLogsResponse.content.nextToken) {
      let currentNextToken: any = getLogsResponse.content.nextToken;
      let getMoreLogsResponse: any;
      logs = [...logs, ...getLogsResponse.content.events];
      // Loop getting more logs until we get a duplicate next token
      do {
        if (getMoreLogsResponse && getMoreLogsResponse.content.nextToken) {
          currentNextToken = getMoreLogsResponse.content.nextToken;
        }

        let moreLoggingParams = {
          assetID: assetID,
          startDate: new Date(finalStartDate).getTime(),
          endDate: new Date(finalEndDate).getTime(),
          environment: this.loggingEnvironment,
          nextToken: currentNextToken
        };
        // Get More Logs
        getMoreLogsResponse = await this.getCloudWatchLogs(moreLoggingParams);
        // End log fetching process when either the next token is repeated
        // or the max number of loops has been reached to conserve resources.
        if ((getMoreLogsResponse && getMoreLogsResponse.content.nextToken === currentNextToken) || count === 30) {
          console.log("No more logs to fetch");
          if (count === 30) {
            this.dialog.open(ErrorDialogComponent, {
              data: {
                title: 'Error',
                message: 'Process ended early and may not have retrieved all logs, please shorten date range.'
              }
            });
          }
          break;
        }
        if (!getMoreLogsResponse.statusFlag) {
          this.dialog.open(ErrorDialogComponent, {
            data: {
              title: 'Error',
              message: getMoreLogsResponse.statusMessage
            }
          });
          return;
        }

        logs = [...logs, ...getMoreLogsResponse.content.events];
        console.log('Current Token', currentNextToken);
        if (logs && logs.length > 0 && previousLogCount !== logs.length) {
          // To prevent spamming the same message to the user
          // it will only display when there is an update to report.
          this.openSnackBar('Fetching asset logs, current total: ' + logs.length, 'Okay');
          previousLogCount = logs.length;
        }
        console.log(`current number of loops`, count);
        count++;
      } while (currentNextToken);
      this.openSnackBar('Finished fetching asset logs', 'Okay');
      this.readLoggingService.cleanAssetLogs(logs);
    } else {
      this.spinnerService.setShowSpinner(false);
      const errorMessage = {
        message: 'No Logs found.',
        title: 'Logging'
      };
      this.dialog.open(ErrorDialogComponent, {
        data: errorMessage
      });
    }
  }

  setEnvironment(env: any) {
    this.loggingEnvironment = env;
  }

  resetTable() {
    this.fieldEditedSub = this.fieldEditedService.resetAssetLoggingTable
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(isBoolean => {
        console.log(isBoolean);
        if (isBoolean === true) {
          console.log('reset Asset Table', this.dataSource);
          this.dataSource = new MatTableDataSource([]);
          this.readLoggingService.logsSub.next([]);
          this.fieldEditedService.resetAssetLoggingTable.next(false);
        }
      });
  }

  async getCloudWatchLogs(body: any) {
    return new Promise((resolve) => {
      this.readLoggingService.getAssetLogs(body).subscribe((result: any) => {
        const getLogsResponse = JSON.parse(JSON.stringify(result));
        console.log('get asset logs response', getLogsResponse);
        return resolve(getLogsResponse);
      });
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}

