<div id="body" fxLayout="column">
  <!--Header-->
  <div
    *ngIf="transactionID !== 'changereq'"
    class="header mat-accent-bg p-16 p-sm-24"
    id="header-container"
  >
    <div class="search mat-white-bg mat-elevation-z7" id="search-bar-container">
      <form class="search-form" style="width: 100%">
        <button
          class="local-search-button"
          *ngIf="!globalSearch"
          (click)="toggleGlobal('global')"
          role="img"
          aria-hidden="true"
          matTooltip="Filter"
          matTooltipClass="tooltip-color"
          [matTooltipShowDelay]="showDelay.value"
          style="border-radius: 4px; cursor: pointer"
        >
          <mat-icon
            class="mat-icon material-icons"
            style="position: relative; top: 2px; left: 1px"
            >search
          </mat-icon>
        </button>
        <button
          type="button"
          class="global-search-button"
          *ngIf="globalSearch"
          (click)="toggleGlobal('local')"
          role="img"
          aria-hidden="true"
          matTooltip="Global Search"
          matTooltipClass="tooltip-color"
          [matTooltipShowDelay]="showDelay.value"
          style="border-radius: 4px; cursor: pointer"
        >
          <mat-icon
            class="mat-icon material-icons"
            style="position: relative; top: 2px"
            >language
          </mat-icon>
        </button>
        <input
          *ngIf="!globalSearch"
          class="search-bar ng-untouched ng-pristine ng-valid"
          matInput
          #searchbar
          (keyup)="applyFilter($any($event.target).value)"
          (keyup.esc)="clearSearchData()"
          type="search"
          autocomplete="off"
          placeholder="Filter by field.."
          style="border: none; color: black; flex-grow: 2; padding-left: 10px"
        />
        <input
          *ngIf="globalSearch"
          class="search-bar ng-untouched ng-pristine ng-valid"
          matInput
          #searchbar
          (keyup)="applyGlobalSearch($event, currentGlobalSearchField); onSelect($event, currentGlobalSearchField)"
          (keyup.esc)="clearSearchData()"
          type="search"
          autocomplete="off"
          placeholder="Global search by field.."
          style="border: none; color: black; flex-grow: 2; padding-left: 10px"
        />
        <mat-icon
          class="mat-icon material-icons"
          role="img"
          aria-hidden="true"
          id="arrow-icons"
          *ngIf="currentGlobalSearchField"
          (click)="getPrevGlobalSearch($event, currentGlobalSearchField)"
          style="margin-right: 8px; cursor: pointer; padding-top: 3px"
        >
          keyboard_arrow_left
        </mat-icon>
        <div
          aria-hidden="true"
          id="counter"
          *ngIf="currentGlobalSearchField"
          style="line-height: normal; padding-top: 6px; margin-right: 5px"
        >
          {{ globalSearchCounter }}
        </div>
        <div
          aria-hidden="true"
          id="counter"
          *ngIf="
            !currentGlobalSearchField && showNoResults && showGlobalSearchCancel
          "
          style="
            line-height: normal;
            white-space: nowrap;
            padding-top: 6px;
            margin-right: 10px;
          "
        >
          {{ 'No Results' }}
        </div>
        <mat-icon
          class="mat-icon material-icons"
          role="img"
          aria-hidden="true"
          id="arrow-icons"
          *ngIf="currentGlobalSearchField"
          (click)="getNextGlobalSearch($event, currentGlobalSearchField)"
          style="margin-right: 8px; cursor: pointer; padding-top: 3px"
        >
          keyboard_arrow_right
        </mat-icon>
        <mat-icon
          class="mat-icon material-icons"
          role="img"
          aria-hidden="true"
          id="arrow-icons"
          *ngIf="currentGlobalSearchField || showGlobalSearchCancel"
          (click)="clearSearchData()"
          style="
            margin-right: 10px;
            margin-left: 10px;
            cursor: pointer;
            padding-top: 3px;
          "
        >
          cancel
        </mat-icon>
      </form>
    </div>
  </div>
  <div style="overflow-y: scroll" *ngIf="transactionID !== 'changereq'">
    <!--Core Table-->
    <div class="content" cdkScrollable>
      <form #transactionForm="ngForm" (ngSubmit)="onSubmit(transactionForm)">
        <table
          mat-table
          #table
          [dataSource]="dataSource"
          class="table"
          CCMCPerfectScrollbar
          matSort
        >
          <!-- Field ID Column -->
          <ng-container matColumnDef="fieldId">
            <th
              class="cell-text"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
            >
              FieldID
            </th>
            <td
              mat-cell
              *matCellDef="let element"
            >
              {{ element.fieldID }}
            </td>
          </ng-container>

          <!-- Field Display Column -->
          <ng-container matColumnDef="fieldDisplay">
            <th
              class="cell-text"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
            >
              Field Display
            </th>
            <td
              mat-cell
              *matCellDef="let element"
            >
              {{ element.fieldDisplay }}
            </td>
          </ng-container>

          <!-- Field Value Column -->
          <ng-container matColumnDef="fieldValue">
            <th
              class="cell-text"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
            >
              Field Value
            </th>

            <td
              class="field-value-styling"
              mat-cell
              *matCellDef="let element; let i = index"
            >
              <ng-template
                [ngIf]="((element || {}).validation || []).length > 0"
                [ngIfElse]="inputValidation"
              >
                <div class="arrow-container">
                  <input
                  *ngIf="!element.disabled === true"
                    type="search"
                    placeholder="Select Value"
                    matInput
                    #valueField
                    [(ngModel)]="element.fieldValue"
                    (keydown.enter)="blurSelect($event, element)"
                    [name]="element.fieldID"
                    [id]="element.fieldID"
                    (keydown)="applyAutoFilter($any($event.target).value)"
                    [matAutocomplete]="auto"
                    (focusout)="getIssues(); evaluateConditions(element)"
                    (focus)="onSelect($event, element)"
                    [readonly]="readOnlyArray[i]"
                    [required]="requiredArray[i]"
                    [pattern]="
                      element.fieldType.toLowerCase() === 'number'
                        ? '[0-9]+'
                        : ''
                    "
                    style="width: 100%;"
                  />
                  <span class="drop-down-arrow" style="pointer-events: none">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </span>
                </div>
                <mat-autocomplete
                  autoActiveFirstOption
                  #auto="matAutocomplete"
                  style="position: relative; left: 4px; width: 100%"
                >
                  <mat-option
                    *ngFor="let option of filteredOptions"
                    [value]="option.value"
                  >
                    {{ option.displayName }} - {{ option.value }}
                  </mat-option>
                </mat-autocomplete>
              </ng-template>

              <ng-template #inputValidation>
                <div *ngIf="element.fieldWidth > 0">
                  <input
                    *ngIf="
                      transactionID !== 'issueList' &&
                      transactionID !== 'reviewList' &&
                      !element.disabled === true
                    "
                    [(ngModel)]="element.fieldValue"
                    [name]="element.fieldID"
                    (ngModelChange)="getFields()"
                    (keydown.enter)="blurSelect($event, element)"
                    [id]="element.fieldID"
                    matInput
                    [ngStyle]="{
                      'background-color': '	#CAC8CF' + element.rowColor
                    }"
                    type="search"
                    [readonly]="readOnlyArray[i]"
                    [required]="requiredArray[i]"
                    (focus)="onSelect($event, element)"
                    [pattern]="
                      element.fieldType.toLowerCase() === 'number'
                        ? '[0-9]+'
                        : ''
                    "
                    maxlength="{{ element.fieldWidth }}"
                    (focusout)="getIssues(); evaluateConditions(element)"
                    [disabled]="showSpinner"
                    style="
                      border: 1px solid rgb(226, 226, 226);
                      padding: 3px;
                      width: 100%;
                    "
                  />
                  <!-- Handle field differently when tab is issueList -->
                  <input
                    *ngIf="transactionID === 'issueList' && !element.disabled === true"
                    #valueField
                    (keydown.enter)="blurSelect($event, element)"
                    [(ngModel)]="element.fieldValue"
                    [name]="element.fieldID"
                    (onBlur)="getIssues()"
                    [id]="element.fieldID"
                    matInput
                    [ngStyle]="{
                      'background-color': '	#CAC8CF' + element.rowColor
                    }"
                    type="search"
                    [readonly]="readOnlyArray[i]"
                    [required]="requiredArray[i]"
                    (focus)="onSelect($event, element)"
                    [pattern]="
                      element.fieldType.toLowerCase() === 'number'
                        ? '[0-9]+'
                        : ''
                    "
                    maxlength="{{ element.fieldWidth }}"
                    (focusout)="getIssues(); evaluateConditions(element)"
                    [disabled]="showSpinner"
                    style="
                      border: 1px solid rgb(226, 226, 226);
                      padding: 3px;
                      width: 100%;
                    "
                  />
                  <!-- Handle field differently when tab is issueList -->
                  <input
                    *ngIf="transactionID === 'reviewList' && !element.disabled === true"
                    [(ngModel)]="element.fieldValue"
                    [name]="element.fieldID"
                    (onBlur)="getIssues()"
                    (keydown.enter)="blurSelect($event, element)"
                    [id]="element.fieldID"
                    matInput
                    [ngStyle]="{
                      'background-color': '	#CAC8CF' + element.rowColor
                    }"
                    type="search"
                    [readonly]="readOnlyArray[i]"
                    [required]="requiredArray[i]"
                    (focus)="onSelect($event, element)"
                    [pattern]="
                      element.fieldType.toLowerCase() === 'number'
                        ? '[0-9]+'
                        : ''
                    "
                    maxlength="{{ element.fieldWidth }}"
                    (focusout)="getIssues(); evaluateConditions(element)"
                    [disabled]="showSpinner"
                    style="
                      border: 1px solid rgb(226, 226, 226);
                      padding: 3px;
                      width: 100%;
                    "
                  />
                  <input
                    *ngIf="element.disabled === true"
                    [name]="element.fieldID"
                    [id]="element.fieldID"
                    matInput
                    [ngStyle]="{
                      'background-color': '	#CAC8CF' + element.rowColor
                    }"
                    type="search"
                    [pattern]="
                      element.fieldType.toLowerCase() === 'number'
                        ? '[0-9]+'
                        : ''
                    "
                    [disabled]="true"
                    style="
                      border: 1px solid rgb(226, 226, 226);
                      padding: 3px;
                      width: 100%;
                    "
                  />
                </div>
              </ng-template>
            </td>
          </ng-container>

          <!-- Field Display Column (Status Column)-->
          <ng-container matColumnDef="status">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="status-header"
            >
              Status
            </th>
            <td
              mat-cell
              class="alert-text"
              *matCellDef="let element; let i = index"
            >
              {{ getStatus(element) }}
              <div
                style="float: right"
                *ngIf="showCheckBox && element.fieldWidth > 0"
              >
                <button
                  style="float: right"
                  class="reqchangebutton"
                  (click)="newChangeRequest(element, i)"
                >
                  Request Change
                </button>
              </div>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="onSelect($event, row)"
            [ngClass]="{ 'accent-50-bg': row == selected }"
            [ngStyle]="{'background-color': (row.rowColor === 'ffffff') 
                                            || (row.rowColor === 'fffff')
                                            || (row.rowColor === 'FFFFFF')
                                            ? 'mat-row:nth-child(odd)' 
                                            && 'mat-row:nth-child(even)' 
                                            : '#' + row.rowColor}"
          ></tr>
        </table>
      </form>
    </div>
  </div>
  <div
    style="height: 100%"
    fxLayout="column"
    *ngIf="transactionID === 'changereq'"
  >
    <mat-tab-group
      #tabGroup
      style="height: 100%"
      [selectedIndex]="1"
      (selectedTabChange)="newTabSelect($event)"
    >
      <!--[selectedIndex]="2"-->
      <mat-tab
        style="height: auto; overflow-x: scroll"
        *ngFor="let status of statuses"
        label="{{ status }}"
      >
        <app-request-change
          [fromLocation]="'transactions'"
          [tab]="status"
          [newTabSelect]="statusCurrent"
        ></app-request-change>
      </mat-tab>
    </mat-tab-group>
    <app-selected-change
      style="
        width: 30%;
        height: 82%;
        position: absolute;
        z-index: 1;
        display: flex;
        right: 0;
        bottom: 0;
      "
      ></app-selected-change>
  </div>

  <!--Spinner-->
  <div class="spinner-container" *ngIf="showSpinner">
    <div class="new-spinner">
      <div class="outer-ring"></div>
      <div class="middle-ring"></div>
      <div class="inner-ring"></div>
    </div>
  </div>
</div>

<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
