<div class="confirm-dialog" style="position: relative">
  <div
    mat-dialog-content
    class="confirm-dialog-message"
    style="font-size: 20px; margin-top: -10px"
  >
    Edit User Information
    <mat-slide-toggle *ngIf="isClientAdmin || isSuperAdmin" style="font-size: 14px;float:right" [(ngModel)]="isUserClientAdmin" (ngModelChange)="toggleClientAdmin()">
      ADMIN USER
    </mat-slide-toggle>
    <mat-slide-toggle *ngIf="isSuperAdmin" style="font-size: 14px;float:right; margin-right: 5%;" [(ngModel)]="isUserSuperAdmin" (ngModelChange)="toggleSuperAdmin()">
      SUPER ADMIN USER
    </mat-slide-toggle>
  </div>
  <mat-form-field
    class="example-full-width"
    style="width: 65%; margin-right: 2%"
  >
    <mat-label>Username</mat-label>
    <input matInput readonly value="{{ data.username }}" />
  </mat-form-field>
  <mat-form-field
    class="example-full-width"
    style="width: 31%; margin-right: 2%"
  >
    <mat-label>Status</mat-label>
    <input readonly matInput value="{{ data.status }}" />
  </mat-form-field>
  <mat-form-field style="width: 65%; margin-right: 2%">
    <mat-label>Assets</mat-label>
    <mat-select [(ngModel)]="assetsToOwn" placeholder="Assets" multiple>
      <mat-select-trigger>
        {{ assetsToOwn[0] ? assetsToOwn[0].SalesforceAssetId : '' }}
        <span *ngIf="assetsToOwn?.length > 1">
          (+{{ assetsToOwn.length - 1 }}
          {{ assetsToOwn?.length === 2 ? 'other' : 'others' }})
        </span>
      </mat-select-trigger>
      <mat-option *ngFor="let asset of assetsInDropDown" [value]="asset"
        >{{ asset.SalesforceAssetId }} : {{ asset.AssetName }}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <button
    *ngIf="data.enabled == false"
    (click)="enableActivation(data.username)"
    mat-raised-button
    style="background-color: #3fb2c6; color: white"
  >
    Activate
  </button>
  <button
    *ngIf="data.enabled == true"
    (click)="disableActivation(data.username)"
    mat-raised-button
    style="background-color: #3b3b3b; color: white"
  >
    Deactivate
  </button>
  <div style="display: flex; justify-content: space-between; padding-top: 3%">
    <button
      mat-raised-button
      matTooltip="Close Dialog"
      matTooltipClass="toolTipStyle"
      (click)="closeDialog()"
      style="text-align: left; max-width: 75px"
    >
      Cancel
    </button>
    <button
      mat-raised-button
      matTooltip="Update User"
      matTooltipClass="toolTipStyle"
      color="accent"
      (click)="save()"
      style="text-align: right; max-width: 50px"
    >
      Save
    </button>
  </div>
  <!-- <mat-form-field style="width: 65%; margin-right: 2%" *ngIf="!isNXTsoft">
    <mat-chip-list #chipList>
      <mat-chip
        *ngFor="let asset of assetsArray"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(asset)"
      >
        {{ asset }}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input
        placeholder="Asset(s)"
        #assetsInput
        [formControl]="assetsCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-list>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selectedAssetOption($event)"
    >
      <mat-option *ngFor="let asset of filteredAssets | async" [value]="asset">
        {{ asset }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field style="width: 65%; margin-right: 2%" *ngIf="isNXTsoft">
    <mat-chip-list #chipList>
      <mat-chip
        *ngFor="let asset of assetsArray"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(asset)"
      >
        {{ asset }}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input
        placeholder="Asset(s)"
        #assetsInput
        [formControl]="assetsCtrl"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-list>
  </mat-form-field> -->
</div>
<div class="spinner-container" *ngIf="showSpinner">
  <div class="new-spinner">
    <div class="outer-ring"></div>
    <div class="middle-ring"></div>
    <div class="inner-ring"></div>
  </div>
</div>
