<div class="configContainer" *ngIf="configurations">
  <mat-card id="ConfigCard">
    <div class="titleContainer">
      <h1>Configuration Options</h1>
      <div class="Btn-container">
        <button
          mat-raised-button
          matTooltip="Saves any changes you make, within this section."
          [disabled]="edited === false"
          matTooltipClass="toolTipStyle"
          color="accent"
          (click)="save()"
          class="saveBtnTop"
        >
          Save
        </button>
      </div>
    </div>
    <div class="edit">
      Edited: &nbsp;
      <div>
        <mat-icon
          *ngIf="saveConfigFlag === true"
          color="primary"
          class="starIcon"
          >star</mat-icon
        >
      </div>
    </div>
    <div class="mainForm" *ngIf="localConfigurations">
      <div *ngFor="let config of finalConfigKeys; let i = index">
        <div *ngIf="config !== 'productInquirySettings'">
          <mat-form-field
            *ngIf="configTypes[i] !== 'boolean' && config !== 'logLevel'"
            class="mat-form-field1"
          >
            <input
              *ngIf="!config.toLowerCase().includes('password')"
              data-lpignore="true"
              matInput
              placeholder="{{ config }}"
              [(ngModel)]="localConfigurations[config]"
              (ngModelChange)="fieldEdited()"
              (keydown.control.s)="save()"
            />
            <input
              *ngIf="config.toLowerCase().includes('password')"
              [type]="showHideFields[i] ? 'password' : 'text'"
              data-lpignore="true"
              type="password"
              matInput
              placeholder="{{ config }}"
              [(ngModel)]="localConfigurations[config]"
              (ngModelChange)="fieldEdited()"
              (keydown.control.s)="save()"
            />
            <mat-icon
              *ngIf="config.toLowerCase().includes('password')"
              matSuffix
              (click)="showHideFields[i] = !showHideFields[i]"
              >{{
                showHideFields[i] ? 'visibility_off' : 'visibility'
              }}</mat-icon
            >
          </mat-form-field>
          <mat-form-field
            *ngIf="configTypes[i] === 'boolean'"
            class="mat-form-field1"
          >
            <mat-select
              placeholder="{{ config }}"
              [(ngModel)]="localConfigurations[config]"
              (ngModelChange)="fieldEdited()"
              (keydown.control.s)="save()"
            >
              <mat-option [value]="true">true</mat-option>
              <mat-option [value]="false">false</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            *ngIf="config === 'logLevel' && configTypes[i] === 'string'"
            class="mat-form-field1"
          >
            <mat-select
              placeholder="{{ config }}"
              [(ngModel)]="localConfigurations[config]"
              (ngModelChange)="fieldEdited()"
              (keydown.control.s)="save()"
            >
              <mat-option value="DEBUG">DEBUG</mat-option>
              <mat-option value="INFO">INFO</mat-option>
              <mat-option value="TRACE">TRACE</mat-option>
              <mat-option value="ERROR">ERROR</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-card>
</div>

<!--Spinner-->
<div class="spinner-container" *ngIf="showSpinner">
  <div class="new-spinner">
    <div class="outer-ring"></div>
    <div class="middle-ring"></div>
    <div class="inner-ring"></div>
  </div>
</div>
