import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddUsergroupComponent } from './add-usergroup.component';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [AddUsergroupComponent],
  imports: [AppMaterialModule, CommonModule, FormsModule],
  entryComponents: [AddUsergroupComponent]
})
export class AddUsergroupModule {}
