import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class DialogHandlerService {
    
    constructor(private dialog: MatDialog) {}

    async handleDialog(matDialog: any, data: any) {
        return new Promise((resolve) => {
          try {
            const dialogRef = this.dialog.open(
              matDialog,
              data,
            );
            dialogRef.afterClosed().subscribe(async (responseData) => {
              if (responseData) {
                return resolve(responseData);
              } else {
                return resolve(false);
              }
            });
          } catch (error) {
            console.error("Dialog Handler", error);
            return resolve(false);
          }
        });
      }

}
