import { Injectable, NgZone } from '@angular/core';
import { navigation } from 'src/app/navigation/navigation';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { CcmcApiService } from './ccmc-api.service';
import { Router } from '@angular/router';
import { SpinnerService } from './spinner.service';
import { ActiveLosService } from './active-los.service';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { AssetService } from './asset.service';
import { filter, catchError } from 'rxjs/operators';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { SuccessDialogComponent } from '../components/success-dialog/success-dialog.component';
import { environment } from 'src/environments/environment';
import { CaptureDataService } from './capture-data.service';
import { AmplifyService } from './amplify.service';
import { GlobalSearchService } from './global-search.service';
import { TargetResponseDialogComponent } from '../components/target-response-dialog/target-response-dialog.component';
const { Buffer } = require('buffer');

@Injectable({
  providedIn: 'root'
})
export class SpectrumService {
  dynamicNav: any = navigation;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(
    private dialog: MatDialog,
    private ccmcApiService: CcmcApiService,
    public router: Router,
    private spinnerService: SpinnerService,
    private activeLosService: ActiveLosService,
    private zone: NgZone,
    private http: HttpClient,
    private customErrorHandlerService: CustomErrorHandlerService,
    private assetService: AssetService,
    private amplifyService: AmplifyService,
    private captureDataService: CaptureDataService,
    private globalSearchService: GlobalSearchService
  ) {
    this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, PUT'
    );
    this.httpOptions.headers.append('Accept', 'application/json');
  }

  /**
   * Send
   * @description Sends the loan to the ibs communicator
   * @memberof SpectrumService
   */
  async send() {
    this.spinnerService.setShowSpinner(true);
    let userName = this.amplifyService.username;
    const exportObject = {
      content: {
        mapping: this.ccmcApiService.mapping,
        configurations: this.ccmcApiService.configurations,
        transactions: this.ccmcApiService.transactions,
        cognitoID: this.amplifyService.getCognitoId()
      },
      user: userName,
      assetID: this.assetService.getSelectedAssetId()
    };
    (await this.export(exportObject)).subscribe(async (res:any) => {
      const parsedResult = JSON.parse(JSON.stringify(res));
      if (parsedResult.error) {
        const errorMessage = {
          statusMessage: "Ground Control failed to receive a response back from the communicator",
          statusFlag: false
        };
        const dialogRef2 = this.zone.run(() => {
          this.dialog.open(TargetResponseDialogComponent, {
            data: errorMessage
          });
        });
        await this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: this.getLoanNumber(),
          statusMessage: parsedResult.error,
          statusFlag: false
        });
      }
      if (parsedResult.statusFlag === true) {
        let loanNumber;
        if (parsedResult.loanNumber) {
          loanNumber = parsedResult.loanNumber;
        } else {
          loanNumber = this.getLoanNumber();
        }
        const resultMessage = {
          statusMessage: parsedResult.statusMessage,
          loanNumber: loanNumber,
          statusFlag: true
        };

        this.captureDataService.uploadS3File(this.ccmcApiService.mapping);
        this.activeLosService.activeLosService.loanBooked(
          parsedResult,
          JSON.parse(JSON.stringify(this.ccmcApiService.configurations))
        );
        await this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: loanNumber,
          statusMessage: parsedResult.statusMessage,
          statusFlag: parsedResult.statusFlag
        });
        this.ccmcApiService.mapping = undefined;
        this.ccmcApiService.coreSettings = undefined;
        this.ccmcApiService.conditions = undefined;
        this.ccmcApiService.mapping = undefined;
        this.ccmcApiService.supportValues = undefined;
        this.ccmcApiService.search = undefined;
        this.ccmcApiService.transactions = undefined;
        this.ccmcApiService.configurations = undefined;
        if (this.dynamicNav[1].children[1]) {
          this.dynamicNav[1].children[1].children = [];
        }
        this.dynamicNav[1].children[0].badge.title = undefined;
        const dialogRef = this.dialog.open(TargetResponseDialogComponent, {
          data: resultMessage
        });
        dialogRef.afterClosed().subscribe(result => {
          if (this.activeLosService.activeLos === 'encompass' || this.activeLosService.activeLos === 'bytepro') {
            this.router.navigate(['loan-lookup']);
          } else {
            this.router.navigate(['file-selector']);
          }
        });
      } else {
        if (parsedResult.content && parsedResult.content.mapping) {
          this.ccmcApiService.mapping = parsedResult.content.mapping;
        }
        if (parsedResult.content && parsedResult.content.transactions) {
          this.ccmcApiService.transactions = parsedResult.content.transactions;
        }
        await this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: this.getLoanNumber(),
          statusMessage: parsedResult.statusMessage,
          statusFlag: parsedResult.statusFlag
        });
        this.globalSearchService.initData();
        const errorMessage = {
          statusMessage: parsedResult.statusMessage,
          statusFlag: false
        };
        const dialogRef = this.dialog.open(TargetResponseDialogComponent, {
          data: errorMessage
        });
      }
    });
  }

  /**
   * Customer Search
   * @description performs the customers search in the ibs communicator
   * @param {*} borrower
   * @param {*} callback
   * @memberof SpectrumService
   */
  customerSearch(borrower: any, callback: any) {
    let userName = this.amplifyService.username;
    const request = {
      content: borrower,
      assetID: this.assetService.getSelectedAssetId(),
      user: userName
    };
    console.log('Request: \n', JSON.stringify(request));
    request.content.cognitoID = this.amplifyService.getCognitoId();
    this.searchCustomer(request).subscribe(res => {
      console.log('Customer search response: ', res);
      const parsedResult = JSON.parse(JSON.stringify(res));
      console.log(parsedResult);
      if (parsedResult.error) {
        const errorMessage = {
          message: JSON.stringify(parsedResult.error.message),
          title: 'Spectrum Result'
        };

        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      }
      if (parsedResult.statusFlag === true) {
        this.spinnerService.setShowSpinner(false);
        const payload = parsedResult.content;
        if (payload.length > 0) {
          const searchResult = payload;
          console.log(searchResult);
          callback(searchResult);
        } else {
          const noResult = [
            {
              firstName: 'No Result',
              lastName: '',
              taxID: '',
              CIF: '',
              address: ''
            }
          ];
          const searchResult = noResult;
          console.log(searchResult);
          callback(searchResult);
        }
      } else if (parsedResult.statusFlag === false) {
        const errorMessage = {
          message: parsedResult.statusMessage,
          title: 'Search Error'
        };

        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      }
    });
  }

  autoCustomerSearch(borrower: any, callback: any) {
    let userName = this.amplifyService.username;
    const request = {
      content: borrower,
      assetID: this.assetService.getSelectedAssetId(),
      user: userName
    };
    console.log('Request: \n', JSON.stringify(request));
    request.content.cognitoID = this.amplifyService.getCognitoId();
    this.searchCustomer(request).subscribe(res => {
      console.log('Customer search response: ', res);
      const parsedResult = JSON.parse(JSON.stringify(res));
      console.log(parsedResult);
      if (parsedResult.error) {
        const errorMessage = {
          message: JSON.stringify(parsedResult.error.message),
          title: 'Spectrum Result'
        };

        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      }
      if (parsedResult.statusFlag === true) {
        this.spinnerService.setShowSpinner(false);
        const payload = parsedResult.content;
        if (payload.length > 0) {
          const searchResult = payload;
          console.log(searchResult);
          callback(searchResult);
        } else {
          const noResult = [
            {
              firstName: 'No Result',
              lastName: '',
              taxID: '',
              CIF: '',
              address: ''
            }
          ];
          const searchResult = noResult;
          console.log(searchResult);
          callback(searchResult);
        }
      } else if (parsedResult.statusFlag === false) {
        this.spinnerService.setShowSpinner(false);
        if (
          parsedResult.statusMessage
            .toLowerCase()
            .includes('no customer record found')
        ) {
          const noResult = [
            {
              firstName: 'No Result',
              lastName: '',
              taxID: '',
              CIF: '',
              address: ''
            }
          ];
          const searchResult = noResult;
          console.log(searchResult);
          callback(searchResult);
        } else {
          const errorMessage = {
            message: parsedResult.statusMessage,
            title: 'Search Error'
          };

          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
          callback(parsedResult);
        }
      }
    });
  }

  /**
   * Assign Borrowers
   * @description set the field value to the customer name
   * @param {*} party
   * @memberof SpectrumService
   */
  assignBorrowers(party: any) {
    for (let i = 0; i < party.length; i++) {
      const CIFFields = party[i].CIFFields.split(',');
      CIFFields.forEach((cf: any) => {
        const cifIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) => f.fieldID === cf.trim()
        );
        this.ccmcApiService.mapping[cifIndex].fieldValue = party[i].CIF;
      });
    }
  }

  suffixSearch(borrower: any, callback: any) {
    let userName = this.amplifyService.username;
    const request = {
      content: borrower,
      assetID: this.assetService.getSelectedAssetId(),
      user: userName
    };
    console.log('Request: \n', JSON.stringify(request));
    request.content.cognitoID = this.amplifyService.getCognitoId();
    this.searchSuffix(request).subscribe(res => {
      console.log('Suffix Search response: ', res);
      const parsedResult = JSON.parse(JSON.stringify(res));
      console.log(parsedResult);
      if (parsedResult.error) {
        const errorMessage = {
          message: JSON.stringify(parsedResult.error.message),
          title: 'Spectrum Result'
        };

        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      }
      if (parsedResult.statusFlag === true) {
        this.spinnerService.setShowSpinner(false);
        if (
          parsedResult.suffixes.loans.length > 0 ||
          parsedResult.suffixes.shares.length > 0
        ) {
          callback(parsedResult);
        } else {
          // const noResult = [
          //   {
          //     firstName: 'No Result',
          //     lastName: '',
          //     taxID: '',
          //     CIF: '',
          //     address: ''
          //   }
          // ];
          // const searchResult = noResult;
          // console.log(searchResult);
          // callback(searchResult);
          console.log('error', parsedResult);
        }
      } else if (parsedResult.statusFlag === false) {
        const errorMessage = {
          message: parsedResult.statusMessage,
          title: 'Search Error'
        };

        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      }
    });
  }

  /**
   * Search Customer
   * @description calls the search customer api
   * @param {*} params
   * @returns
   * @memberof SpectrumService
   */
  searchCustomer(params: any) {
    
    console.log(params);
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/fiserv/spectrum/search-customer`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * Search Suffix
   * @description calls the search suffix api
   * @param {*} params
   * @returns
   * @memberof SpectrumService
   */
  searchSuffix(params: any) {
    
    console.log(params);
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/fiserv/spectrum/search-suffix`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * Export
   * @description calls the export api
   * @param {*} params
   * @returns
   * @memberof SpectrumService
   */
  export(params: any) {
    
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/fiserv/spectrum/export-messages`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  getLoanNumber() {
    console.log('get Loan number');
    let loanFieldIndex = this.ccmcApiService.mapping.findIndex(
      (obj: any) => obj.fieldID === 'LOCH_006'
    );
    if (loanFieldIndex > -1) {
      // Return loan number
      console.log(
        'loan number',
        this.ccmcApiService.mapping[loanFieldIndex].fieldValue
      );
      return this.ccmcApiService.mapping[loanFieldIndex].fieldValue;
    }
  }

  handleAssetIDs(spectrumUserCredentialItem: any) {
    let currentAsset = this.assetService.getSelectedAssetId();
    if (Array.isArray(spectrumUserCredentialItem.assetID)) {
      console.log('array');
      if (!spectrumUserCredentialItem.assetID.includes(currentAsset)) {
        spectrumUserCredentialItem.assetID.push(currentAsset);
      }
    } else if (
      (typeof spectrumUserCredentialItem.assetID === 'string' || 
      spectrumUserCredentialItem.assetID instanceof String) &&
      spectrumUserCredentialItem.assetID.length > 0) {
      console.log('string adding to array');
      let temp = spectrumUserCredentialItem.assetID;
      spectrumUserCredentialItem.assetID = [];
      spectrumUserCredentialItem.assetID.push(temp);
      if (!spectrumUserCredentialItem.assetID.includes(currentAsset)) {
        spectrumUserCredentialItem.assetID.push(currentAsset);
      }
    } else {
      console.log('empty');
      spectrumUserCredentialItem.assetID = [];
      spectrumUserCredentialItem.assetID.push(currentAsset);
    }
    return spectrumUserCredentialItem;
  }
}
