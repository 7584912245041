<div style="position: relative">
  <h1>Edit Snippet</h1>
  <div>
    <div>
      <mat-form-field style="width: 100%">
        <input matInput placeholder="Title" [(ngModel)]="newSnippet.title" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="width: 100%">
        <textarea
          matInput
          placeholder="Description"
          [(ngModel)]="newSnippet.description"
        ></textarea>
      </mat-form-field>
    </div>
    <div class="CodeMirrorMappingContainer formContainer6">
      <ngx-codemirror
        #codeeditor
        [(ngModel)]="newSnippet.snippet"
        [options]="codemirrorConfig"
      >
      </ngx-codemirror>
    </div>
  </div>
  <mat-action-row>
    <button mat-button color="primary" (click)="closeDialog()">Cancel</button>
    <button mat-button color="accent" (click)="createSnippet()">
      Create Snippet
    </button>
  </mat-action-row>
  <div
    style="
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    "
    *ngIf="showSpinner"
  >
    <mat-spinner></mat-spinner>
  </div>
</div>
