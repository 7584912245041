<div style="position: relative">
  <div style="display: grid; grid-template-columns: 1fr 1fr">
    <h1>Snippets</h1>
    <div style="text-align: right; display: inline-block; margin-bottom: 5px">
      <h1>
        <i class="material-icons noselect" (click)="closeDialog()"> close </i>
      </h1>
      <button
        mat-flat-button
        color="primary"
        style="height: 36px; width: 125px"
        (click)="createSnippet()"
      >
        New Snippet
      </button>
    </div>
  </div>
  <div class="filter-container">
    <mat-form-field class="filter-FormField">
      <input
        matInput
        placeholder="Filter Search"
        #filterSearch
        floatLabel="never"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Filter Options</mat-label>
      <mat-select [(value)]="selectedFilter">
        <mat-option value="all" (click)="changeFilterPredicate('all')"
          >Filter All</mat-option
        >
        <mat-option value="title" (click)="changeFilterPredicate('title')"
          >Title</mat-option
        >
        <mat-option
          value="description"
          (click)="changeFilterPredicate('description')"
          >Description</mat-option
        >
        <mat-option value="snippet" (click)="changeFilterPredicate('snippet')"
          >Snippet</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <div
      style="
        display: grid;
        grid-template-rows: auto;
        margin: auto;
        padding: 5px;
        row-gap: 5px;
        background-color: #aaaaaa;
        max-height: 300px;
        height: 300px;
        overflow-y: auto;
      "
    >
      <!-- <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel
        hideToggle
        (opened)="accordion.closeAll(); selectSnippet(snippet)"
        *ngFor="let snippet of dataSource.filteredData"
      >
        <mat-expansion-panel-header [collapsedHeight]="'48px'">
          <mat-panel-title> {{ snippet.title }} </mat-panel-title>
        </mat-expansion-panel-header>
        <p>{{ snippet.description }}</p>
        <mat-action-row>
          <button mat-button color="primary" (click)="(true)">Edit</button>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion> -->
      <mat-accordion class="example-headers-align" *ngIf="dataSource">
        <mat-expansion-panel
          *ngFor="let snippet of dataSource.filteredData; let i = index"
          [expanded]="snippet === selectedSnippet"
          (opened)="editSnippetFlag = false; selectSnippet(snippet)"
          hideToggle
        >
          <mat-expansion-panel-header [collapsedHeight]="'48px'">
            <mat-panel-title>
              {{ snippet.title }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p *ngIf="!editSnippetFlag">{{ snippet.description }}</p>
          <p *ngIf="editSnippetFlag">
            <mat-form-field style="width: 100%">
              <textarea
                matInput
                [(ngModel)]="localSelectedSnippet.description"
                style="height: 55px"
                (ngModelChange)="fieldEdited()"
              ></textarea>
            </mat-form-field>
          </p>
          <mat-action-row>
            <button mat-button color="accent" (click)="copySnippet(snippet)">
              Copy Code Snippet
            </button>
            <button
              mat-button
              color="primary"
              *ngIf="!editSnippetFlag"
              (click)="editSnippet(snippet)"
            >
              Edit
            </button>
            <button
              mat-button
              color="warning"
              *ngIf="editSnippetFlag"
              (click)="cancelEdit()"
            >
              Cancel
            </button>
            <button
              mat-button
              color="primary"
              (click)="saveSnippet()"
              [disabled]="!editedFlag"
              *ngIf="editSnippetFlag"
            >
              Save
            </button>
            <button
              mat-flat-button
              color="warning"
              (click)="removeSnippet(snippet)"
            >
              Remove
            </button>
          </mat-action-row>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="CodeMirrorMappingContainer formContainer6">
      <ngx-codemirror
        #codemirror
        [(ngModel)]="localSelectedSnippet.snippet"
        [options]="readOnlyCodeMirrorConfig"
        *ngIf="!editSnippetFlag && localSelectedSnippet"
      >
      </ngx-codemirror>
      <ngx-codemirror
        #codemirror
        [(ngModel)]="localSelectedSnippet.snippet"
        [options]="codeMirrorConfig"
        (change)="fieldEdited()"
        *ngIf="editSnippetFlag && localSelectedSnippet"
      >
      </ngx-codemirror>
    </div>
  </div>

  <div
    style="
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    "
    *ngIf="showSpinner"
  >
    <mat-spinner></mat-spinner>
  </div>
</div>
