import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { fromEvent, Subject, Subscription } from 'rxjs';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  takeUntil
} from 'rxjs/operators';
import { CCMCEdgeService } from 'src/@ccmc/services/edge.service';
import { CcmcApiService } from '../../services/ccmc-api.service';
import { FieldEditedService } from '../../services/field-edited.service';
import { GeneralLedgerAccountingService } from '../../services/general-ledger-accounting.service';
import { CCMCSelectedFieldService } from '../../services/selected-field.service';
import { SpinnerService } from '../../services/spinner.service';
import { CCMCConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { EditSnippetsDialogComponent } from './edit-snippets-dialog/edit-snippets-dialog.component';
import { NewSnippetsDialogComponent } from './new-snippets-dialog/new-snippets-dialog.component';
@Component({
  selector: 'app-snippets-dialog',
  templateUrl: './snippets-dialog.component.html',
  styleUrls: ['./snippets-dialog.component.scss']
})
export class SnippetsDialogComponent implements OnInit {
  showDelay = new FormControl(1200);
  codeMirror: CodemirrorComponent;
  showSpinner: boolean;
  private spinnerSub: Subscription;
  dataSource: any;
  selectedSnippet: any;
  localSelectedSnippet: any;
  selectedSnippetSub: Subscription;
  editSnippetFlag = false;
  editedFlag: any;
  snippetToEdit: any;
  @ViewChild('filterSearch', { static: true }) filterSearchEl: ElementRef;
  public selectedFilter = 'all';
  filterSearch: string;
  unsubscribe: Subject<any> = new Subject();
  snippetExpandedFlags: any;
  readOnlyCodeMirrorConfig = {
    mode: 'vbscript',
    lineNumbers: true,
    theme: 'material',
    extraKeys: { 'Ctrl-Space': 'autocomplete' },
    lineWrapping: true,
    foldGutter: true,
    gutters: [
      'CodeMirror-linenumbers',
      'CodeMirror-foldgutter',
      'CodeMirror-lint-markers'
    ],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true,
    readOnly: true
  };
  codeMirrorConfig = {
    mode: 'vbscript',
    lineNumbers: true,
    theme: 'material',
    extraKeys: { 'Ctrl-Space': 'autocomplete' },
    lineWrapping: true,
    foldGutter: true,
    gutters: [
      'CodeMirror-linenumbers',
      'CodeMirror-foldgutter',
      'CodeMirror-lint-markers'
    ],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true,
    readOnly: false
  };
  snippets: any;
  translators: any;
  private translatorsSub: Subscription;
  constructor(
    private ccmcApiService: CcmcApiService,
    private selectedFieldService: CCMCSelectedFieldService,
    private spinnerService: SpinnerService,
    private fieldEditedService: FieldEditedService,
    private zone: NgZone,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<SnippetsDialogComponent>,
    private edgeService: CCMCEdgeService,
    private glaService: GeneralLedgerAccountingService
  ) {}

  ngOnInit() {
    this.getData();
    this.spinnerService.setShowSpinner(true);
    this.getSnippets();
    this.getSpinnerStatus();
    this.filterSearchEl.nativeElement.focus();
    fromEvent(this.filterSearchEl.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // Time in milliseconds between key events
        debounceTime(1000),
        // If previous query is diffent from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((text: string) => {
        this.applyFilter(text);
      });
  }

  getData() {
    if (localStorage.getItem('isGLA') === 'true') {
      this.translatorsSub = this.glaService.glaDocument
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(glaDocument => {
          if (glaDocument) {
            this.translators = glaDocument.translators;
          }
        });
    } else {
      // Subscribe to the document LOS to get the translators
      this.translatorsSub = this.ccmcApiService.documentLos
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(documentLos => {
          if (documentLos) {
            this.translators = documentLos.loan;
          }
        });
    }
  }

  // ngAfterViewInit() {}

  getSpinnerStatus() {
    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        this.showSpinner = spinner;
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  applyFilter(filterValue: string) {
    // console.log(filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log('filteredSnippetData', this.dataSource.filteredData);
    console.log(this.dataSource);
    console.log(this.dataSource.filteredData);
    this.setExpansionStatus();
    if (this.dataSource.filteredData[0]) {
      // select snippet here
      this.selectSnippet(this.dataSource.filteredData[0]);
    }
    this.filterSearch = filterValue;
  }

  selectSnippet(snippet: any) {
    this.selectedSnippet = snippet;
    this.localSelectedSnippet = JSON.parse(JSON.stringify(snippet));
  }

  getSnippets() {
    this.ccmcApiService.getDynamoDB('code_snippets').subscribe(result => {
      // console.log(result);
      if (result) {
        this.spinnerService.setShowSpinner(false);
        this.snippets = JSON.parse(JSON.stringify(result));
        this.snippets.sort((a: any, b: any) => (a.title > b.title ? 1 : -1));
        this.dataSource = new MatTableDataSource(this.snippets);
        this.selectedSnippet = this.dataSource.filteredData[0];
        this.setExpansionStatus();
        // console.log(result);
      } else {
        console.log('Error getting snippets', result);
      }
    });
  }

  setExpansionStatus() {
    // Sets index 0 as expanded
    this.snippetExpandedFlags = [true];
    this.selectSnippet(this.dataSource.filteredData[0]);
    // Sets all others as false
    for (let i = 0; i < this.dataSource.filteredData.length - 1; i++) {
      this.snippetExpandedFlags.push(false);
    }
  }

  updateSnippet(snippet: any) {
    this.ccmcApiService
      .createDynamoDB('test', 'code_snippets', snippet)
      .subscribe(result => {
        if (result) {
          this.editSnippetFlag = false;
          this.openSnackBar('Updated Code Snippet', 'Okay');
          this.getSnippets();
        }
      });
  }

  changeFilterPredicate(predicate: string) {
    // console.log(predicate);
    if (predicate === 'all') {
      // tslint:disable-next-line: no-shadowed-variable
      this.dataSource.filterPredicate = function (
        data: any,
        filter: string
      ): boolean {
        // tslint:disable-next-line: max-line-length
        return (
          data.title.toLowerCase().includes(filter) ||
          data.description.toLowerCase().includes(filter) ||
          data.snippet.toLowerCase().includes(filter)
        );
      };
    } else if (predicate === 'title') {
      // tslint:disable-next-line: no-shadowed-variable
      this.dataSource.filterPredicate = function (
        data: any,
        filter: string
      ): boolean {
        return data.title.toLowerCase().includes(filter);
      };
    } else if (predicate === 'description') {
      // tslint:disable-next-line: no-shadowed-variable
      this.dataSource.filterPredicate = function (
        data: any,
        filter: string
      ): boolean {
        return data.description.toLowerCase().includes(filter);
      };
    } else if (predicate === 'snippet') {
      // tslint:disable-next-line: no-shadowed-variable
      this.dataSource.filterPredicate = function (
        data: any,
        filter: string
      ): boolean {
        return data.snippet.toLowerCase().includes(filter);
      };
    }
  }

  addSnippet() {
    console.log('add snippet');
  }

  openSnackBar(message: string, action: string) {
    this.zone.run(() => {
      setTimeout(() => {
        this.snackBar.open(message, action, {
          duration: 5000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }, 0);
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  copySnippet(snippet: any) {
    console.log('copy snippet');
    this.openSnackBar(`Copied ${snippet.title} Snippet to Clipboard`, 'Okay');
  }

  editSnippet(snippet: any) {
    this.localSelectedSnippet = undefined;
    console.log('edit snippet', snippet);
    this.selectSnippet(snippet);
    this.editSnippetFlag = true;
    // const editSnippetDialogRef = this.dialog.open(EditSnippetsDialogComponent, {
    //   data: snippet,
    //   panelClass: 'snippets-dialog'
    // });
    // editSnippetDialogRef.afterClosed().subscribe(result => {
    //   if (result && result.data) {
    //     this.spinnerService.setShowSpinner(true);
    //     this.dataSource = new MatTableDataSource([]);
    //     this.selectedSnippet = undefined;
    //     this.getSnippets();
    //   }
    // });
  }

  createSnippet() {
    console.log('create snippet');
    const editSnippetDialogRef = this.dialog.open(NewSnippetsDialogComponent, {
      panelClass: 'snippets-dialog',
      data: this.snippets
    });
    editSnippetDialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        this.spinnerService.setShowSpinner(true);
        this.dataSource = new MatTableDataSource([]);
        this.selectedSnippet = undefined;
        this.getSnippets();
      }
    });
  }

  removeSnippet(snippet: any) {
    const dialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
      data: `Are you sure you want to remove the ${snippet.title} snippet?`
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        console.log('remove snippet', snippet);
        const params = {
          keyName: 'title',
          key: snippet.title,
          tableName: 'code_snippets'
        };
        this.ccmcApiService
          .deleteDynamoDBItem('title', snippet.title, 'code_snippets')
          .subscribe(result => {
            console.log(result);
            if (result) {
              const parsedResult = JSON.parse(JSON.stringify(result));
              if (parsedResult.statusFlag) {
                this.openSnackBar('Successfully Deleted Snippet', 'Okay');
                this.getSnippets();
              } else {
                this.openSnackBar(
                  'There was an error deleting your snippet',
                  'Okay'
                );
                console.log(result);
              }
            }
          });
      }
    });
  }

  saveSnippet() {
    this.compileSnippet(this.localSelectedSnippet);
  }

  compileSnippet(snippet: any) {
    let multEnd;
    // Initialize vbscript errors to empty array
    let vbScriptErrors: any[] = [];
    // Pass vbscript to the compile engine
    this.edgeService.compileEngine(
      snippet.snippet,
      async (error: any, result: any) => {
        // splits the error message into an array
        if (result.indexOf('\r\n') !== -1) {
          vbScriptErrors = result.split('\r\n');
        }
        // If vbscript errors exist
        if (vbScriptErrors.length > 0) {
          for (let i = 0; i < vbScriptErrors.length - 1; i++) {
            // checks to see if the error message is because of an undeclared variable
            if (vbScriptErrors[i].indexOf('BC30451') > 0) {
              const tmp = vbScriptErrors[i].match(/'(.*)'/);
              let tempTranslator: any;
              if (tmp[1].length > 0 && tmp[1] !== 'MXR') {
                tempTranslator = tmp[1];
                if (
                  this.translators.findIndex(
                    (t: any) => t.fieldName === tempTranslator
                  ) == -1
                ) {
                  console.log(tempTranslator);
                  let lastChar = tempTranslator[tempTranslator.length - 1];
                  // If last character is a number remove it
                  if (lastChar >= '0' && lastChar <= '9') {
                    multEnd = +lastChar;
                    tempTranslator = tempTranslator.slice(0, -1);
                  } else {
                    multEnd = null;
                  }
                  lastChar = tempTranslator[tempTranslator.length - 1];
                  if (lastChar >= '0' && lastChar <= '9') {
                    multEnd = multEnd! + 10 * +lastChar;
                    tempTranslator = tempTranslator.slice(0, -1);
                  }
                  const tmpIndex = this.translators.findIndex(
                    (obj: any) => obj.fieldName === tempTranslator
                  );
                  if (multEnd && tmpIndex > -1) {
                    if (multEnd > this.translators[tmpIndex].multEnd) {
                      console.log(this.translators[tmpIndex]);
                      this.openSnackBar(
                        'Exceeds Mult End ' +
                          this.translators[tmpIndex].multEnd +
                          '.',
                        'Okay'
                      );
                      return;
                    }
                  }
                  // If index is less than 1 the translator doesnt exist
                  if (tmpIndex < 0) {
                    const msg = vbScriptErrors[i];
                    this.openSnackBar(msg, 'Okay');
                    return;
                  }
                }
                // If we get here we know the translator exists and we can remove this error from the
                // errors list
                vbScriptErrors.splice(i, 1);
                i--;
              }
            }
          }
        }
        // Remove MXR Error here
        for (let i = 0; i < vbScriptErrors.length; i++) {
          const tmp = vbScriptErrors[i].match(/'(.*)'/);
          if (tmp && tmp[1] == 'MXR') {
            vbScriptErrors.splice(i, 1);
            i--;
          }
        }
        // If there are errors display the error
        if (vbScriptErrors.length > 1) {
          const msg = vbScriptErrors[0];
          this.openSnackBar(msg, 'Okay');
          return;
        } else {
          this.updateSnippet(snippet);
        }
      }
    );
  }

  fieldEdited() {
    this.editedFlag = true;

    if (this.isEquivalent(this.selectedSnippet, this.localSelectedSnippet)) {
      console.log('is equiv');
      this.editedFlag = false;
    }
  }

  isEquivalent(a: any, b: any) {
    console.log(a);
    console.log(b);
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      console.log();
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];
      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
        return false;
      }
    }
    return true;
  }

  cancelEdit() {
    this.localSelectedSnippet = JSON.parse(
      JSON.stringify(this.selectedSnippet)
    );
    this.editSnippetFlag = false;
  }
}
