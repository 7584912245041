import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { CCMCSelectedFieldService } from '../../../../../@ccmc/services/selected-field.service';
import { CcmcApiService } from '../../../../../@ccmc/services/ccmc-api.service';
import { CCMCEdgeService } from '../../../../../@ccmc/services/edge.service';
import { CCMCConfirmDialogComponent } from '../../../../../@ccmc/components/confirm-dialog/confirm-dialog.component';
import { FieldEditedService } from '../../../../../@ccmc/services/field-edited.service';
import { takeUntil } from 'rxjs/operators';
import { EncompassService } from '../../../../../@ccmc/services/encompass.service';
import { GetPathDialogComponent } from '../../../../../@ccmc/components/get-path-dialog/get-path-dialog.component';
import { Console } from 'console';
import {
  ShortcutInput,
  ShortcutEventOutput,
  KeyboardShortcutsModule
} from 'ng-keyboard-shortcuts';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GetFieldIdDialogComponent } from 'src/@ccmc/components/get-field-id-dialog/get-field-id-dialog.component';
/**
 * The Translators Translators Component lets the user edit the translator selected
 *
 * @export
 * @class TranslatorsTranslatorsComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-gla-translators-translators',
  templateUrl: './gla-translators-translators.component.html',
  styleUrls: ['./gla-translators-translators.component.scss']
})
export class GlaTranslatorsTranslatorsComponent implements OnInit, OnDestroy {
  /**
   * Selected Translator
   *
   * @type {*}
   * @memberof TranslatorsTranslatorsComponent
   */
  public selected: any;

  /**
   * Local instance of the selected translator
   *
   * @type {*}
   * @memberof TranslatorsTranslatorsComponent
   */
  localSelected: any;

  /**
   * Selected Translator subscription
   *
   * @private
   * @type {Subscription}
   * @memberof TranslatorsTranslatorsComponent
   */
  private selectedSub: Subscription;

  /**
   * Edited flag
   *
   * @memberof TranslatorsTranslatorsComponent
   */
  editedFlag = false;

  /**
   * Field edited subscription
   *
   * @private
   * @type {Subscription}
   * @memberof TranslatorsTranslatorsComponent
   */
  private fieldEditedSub: Subscription;

  /**
   * Field edited flag for translators
   *
   * @memberof TranslatorsTranslatorsComponent
   */
  fieldEditedFlag = false;

  /**
   * Unsubscribes from observables
   *
   * @type {Subject<any>}
   * @memberof TranslatorsTranslatorsComponent
   */
  unsubscribe: Subject<any> = new Subject();
  showEncompassPathButton: any;
  /**
   * Creates an instance of TranslatorsTranslatorsComponent.
   * @param {CCMCSelectedFieldService} selectedFieldService
   * @param {CcmcApiService} ccmcApiService
   * @param {CCMCEdgeService} edgeService
   * @param {FieldEditedService} fieldEditedService
   * @param {MatDialog} dialog
   * @memberof TranslatorsTranslatorsComponent
   */
  constructor(
    private selectedFieldService: CCMCSelectedFieldService,
    private ccmcApiService: CcmcApiService,
    private edgeService: CCMCEdgeService,
    private fieldEditedService: FieldEditedService,
    private dialog: MatDialog,
    private encompassService: EncompassService,
    private snackBar: MatSnackBar
  ) {}
  /**
   * Initializes this component
   *
   * @memberof TranslatorsTranslatorsComponent
   */
  ngOnInit() {
    this.getData();
    console.log(localStorage.getItem('selectedLos'));
    if (localStorage.getItem('selectedLos') === 'encompass') {
      this.showEncompassPathButton = true;
    } else {
      this.showEncompassPathButton = false;
    }
  }
  //make shortcuts into a array to append shortcuts into ngAfterViewInit
  shortcuts: ShortcutInput[] = [];

  //ngAfterViewinit method that calls the cmd+s shortcut
  ngAfterViewInit() {
    this.shortcuts.push({
      key: ['cmd + s'],
      label: 'Saving',
      description: 'saving',
      command: e => {
        this.save();
      }
    });
  }
  /**
   * unsubscribes from observables on destroy
   *
   * @memberof TranslatorsTranslatorsComponent
   */
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  /**
   * Gets the data needed for the component
   *
   * @memberof TranslatorsTranslatorsComponent
   */
  getData() {
    // Initialize selected sub
    this.selectedSub = this.selectedFieldService.onGLATranslatorFieldSelected
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(selected => {
        if (selected) {
          if (this.editedFlag) {
            const confirmDialogRef = this.dialog.open(
              CCMCConfirmDialogComponent,
              {
                data: 'Save your current changes?',
                panelClass: 'save-current-dialog'
              }
            );
            confirmDialogRef.afterClosed().subscribe(data => {
              if (data) {
                this.save();
                // Assign selected to current selected translator
                this.selected = selected;
                // Initialize localSelected translator
                this.localSelected = {
                  edited: false,
                  fieldName: selected.fieldName,
                  multEnd: selected.multEnd,
                  multBegin: selected.multBegin,
                  vbScript: selected.vbScript,
                  evaluationMethod: selected.evaluationMethod
                };
              } else {
                // Assign selected to current selected translator
                this.selected = selected;
                this.fieldEditedService.fieldEdited.next(false);
                this.editedFlag = false;
                // Initialize localSelected translator
                this.localSelected = {
                  edited: false,
                  fieldName: selected.fieldName,

                  vbScript: selected.vbScript,
                  evaluationMethod: selected.evaluationMethod,
                  multEnd: selected.multEnd,
                  multBegin: selected.multBegin
                };
              }
              if (
                selected.evaluationMethod === '' ||
                selected.evaluationMethod === ' '
              ) {
                this.localSelected.evaluationMethod = 'path';
              }
            });
          } else {
            // Assign selected to current selected translator
            this.selected = selected;
            this.fieldEditedService.fieldEdited.next(false);
            this.editedFlag = false;
            // Initialize localSelected translator
            this.localSelected = {
              edited: false,
              fieldName: selected.fieldName,
              vbScript: selected.vbScript,
              evaluationMethod: selected.evaluationMethod,
              multEnd: selected.multEnd,
              multBegin: selected.multBegin
            };
            if (
              selected.evaluationMethod === '' ||
              selected.evaluationMethod === ' '
            ) {
              this.localSelected.evaluationMethod = 'path';
            }
          }
        }
      });
    this.fieldEditedSub = this.fieldEditedService.fieldEdited
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(fieldEditedObj => {
        this.fieldEditedFlag = fieldEditedObj;
      });
  }

  /**
   * Overrieds current translator with local edited translator
   *
   * @memberof TranslatorsTranslatorsComponent
   */
  save() {
    if (this.localSelected.multEnd < this.localSelected.multBegin) {
      this.openSnackBar(
        'Error: MultEnd can not be larger than MultBegin',
        'Okay'
      );
      return;
    }
    if (!this.localSelected.evaluationMethod) {
      this.openSnackBar('Error: Evaluation Method may not be blank.', 'Okay');
      return;
    }
    if (
      this.localSelected.vbScript &&
      this.localSelected.vbScript.includes('~#~')
    ) {
      if (this.localSelected.multEnd <= this.localSelected.multBegin) {
        this.openSnackBar(
          'Error: Multi End must be greater than Multi Begin',
          'Okay'
        );
        return;
      }
    }
    //Removing any spaces
    this.localSelected.vbScript = this.localSelected.vbScript.trim();

    //Checking vbscript to see if is has ~#~

    //Checking vbscript to see if is has includes \n then removing it
    if (
      this.localSelected.vbScript.substring(
        this.localSelected.vbScript.length - 2
      ) == '\\n'
    ) {
      this.localSelected.vbScript = this.localSelected.vbScript.substring(
        0,
        this.localSelected.vbScript.length - 2
      );
      this.localSelected.vbScript = this.localSelected.vbScript.trim();
    }

    this.fieldEditedService.onGLADocumentEdited.next(true);
    // Sets the edited field to true
    this.selected.edited = true;
    this.selected.fieldName = this.localSelected.fieldName;
    this.selected.vbScript = this.localSelected.vbScript;
    this.selected.evaluationMethod = this.localSelected.evaluationMethod;
    this.selected.multEnd = this.localSelected.multEnd;
    this.selected.multBegin = this.localSelected.multBegin;
    // Set flag to false when update is done
    this.editedFlag = false;
    this.fieldEditedService.fieldEdited.next(false);
  }

  /**
   * Checks if the current translator has been edited by the user
   *
   * @memberof TranslatorsTranslatorsComponent
   */
  fieldEdited() {
    console.log(this.selected);
    console.log(this.localSelected);
    // Checks to see if modified local selected variable is the same as the current fields
    if (this.isEquivalent(this.selected, this.localSelected)) {
      this.editedFlag = false;
      this.fieldEditedService.fieldEdited.next(false);
    } else {
      this.editedFlag = true;
      this.fieldEditedService.fieldEdited.next(true);
    }
  }

  /**
   * Compares 2 translators
   *
   * @param {*} a
   * @param {*} b
   * @returns
   * @memberof TranslatorsTranslatorsComponent
   */
  isEquivalent(a: any, b: any) {
    if (a.fieldName !== b.fieldName) {
      return false;
    } else if (a.vbScript !== b.vbScript) {
      return false;
    } else if (a.evaluationMethod !== b.evaluationMethod) {
      return false;
    } else if (a.multEnd !== b.multEnd) {
      return false;
    } else if (a.multBegin !== b.multBegin) {
      return false;
    } else {
      return true;
    }
  }

  getEncompassPath() {
    console.log('open path dialog');
    const pathDialogRef = this.dialog.open(GetPathDialogComponent);
    pathDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.localSelected.vbScript = result.data;
        this.fieldEdited();
      }
    });
  }

  getEncompassID() {
    console.log('open path dialog');
    const getFieldIDDialogRef = this.dialog.open(GetFieldIdDialogComponent, {
      data: this.selected
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000
    });
  }
}
