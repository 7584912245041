import { Injectable } from '@angular/core';
import { adminNavigation, glaNavigation } from 'src/app/navigation/navigation';

@Injectable({
  providedIn: 'root'
})
export class AssetService {
  private assetIds: any;
  private selectedAssetId: any;
  private selectedFI: any;
  private selectedPath: any;
  private selectedProduct: any;
  private hasEBM: any;
  private hasGLA: any;
  private selectedCore: any;
  private selectedLos: any;
  private selectedCoreCompany: any;
  private canCaptureData: any;
  adminNav: any = adminNavigation;
  constructor() {}

  /**
   * Get AssetIds
   * Returns the current assetids for a user
   * @returns
   * @memberof AssetService
   */
  getAssetIds() {
    return this.assetIds;
  }

  /**
   * Set Assetids
   * @description set assets ids
   * @param {*} assetIds
   * @memberof AssetService
   */
  setAssetids(assetIds :any) {
    this.assetIds = assetIds;
  }

  /**
   * Get Selected AssetId
   * gets the selected assetID
   * @returns
   * @memberof AssetService
   */
  getSelectedAssetId() {
    return this.selectedAssetId;
  }

  /**
   * Set Selected AssetId
   * @description set selected assetId
   * @param {*} assetId
   * @memberof AssetService
   */
  setSelectedAssetId(assetId: any) {
    this.selectedAssetId = assetId;
  }

  /**
   * Get selected FI
   * @description get selected financial institution
   * @returns
   * @memberof AssetService
   */
  getSelectedFI() {
    return this.selectedFI;
  }

  /**
   * Set Selected FI
   * @description set selected financial institution
   * @param {*} FI
   * @memberof AssetService
   */
  setSelectedFI(FI: any) {
    this.selectedFI = FI;
  }

  /**
   * Get Selected Path
   * @description get selected path
   * @returns
   * @memberof AssetService
   */
  getSelectedPath() {
    return this.selectedPath;
  }

  /**
   * Set Selected Path
   * @description sets the selected path
   * @param {*} path
   * @memberof AssetService
   */
  setSelectedPath(path: any) {
    this.selectedPath = path;
  }

  /**
   * Get Selected Product
   *
   * @returns
   * @memberof AssetService
   */
  getSelectedProduct() {
    return this.selectedProduct;
  }

  /**
   * Get Has EBM
   * @description Gets If the selected asset has ebm
   * @returns
   * @memberof AssetService
   */
  getHasEBM() {
    return this.hasEBM;
  }

  /**
   *  Set Has EBM
   *  @description Sets if the selected asset has ebm
   * @param {*} hasEBM
   * @memberof AssetService
   */
  setHasEBM(hasEBM: any) {
    let product = this.getSelectedProduct();
    console.log(this.adminNav);
    const ebmReportIndex = this.adminNav[1].children.findIndex(
      (nav: any) => nav.id === 'ebm-report'
    );
    if (ebmReportIndex == -1) {
      console.log(
        "Developer Intervention needed. Can't find EBM Report id in admin navigation array."
      );
      return;
    }
    if (hasEBM && !product.includes('doc_connector')) {
      this.adminNav[1].children[ebmReportIndex].visible = true;
      this.hasEBM = true;
    } else {
      this.adminNav[1].children[ebmReportIndex].visible = false;
      this.hasEBM = false;
    }
  }

  /**
   * Set Selected Product
   * @description Set Selected Product
   * @param {*} product
   * @memberof AssetService
   */
  setSelectedProduct(product: any) {
    console.log(product);
    if (product.includes('general_ledger_accounting')) {
      this.setGLA(true);
    } else {
      this.setGLA(false);
    }
    this.selectedProduct = product;
  }

  setGLA(hasGLA: any) {
    this.hasGLA = hasGLA;
  }

  getGLA() {
    return this.hasGLA;
  }

  /**
   * Get selected Core
   * @description get selected Core
   * @returns
   * @memberof AssetService
   */
  getSelectedCore() {
    return this.selectedCore;
  }

  /**
   * Set Selected Core
   * @description set selected Core
   * @param {*} Core
   * @memberof AssetService
   */
  setSelectedCore(Core: any) {
    this.selectedCore = Core;
  }

  /**
   * Get selected LOS
   * @description get selected LOS
   * @returns
   * @memberof AssetService
   */
  getSelectedLos() {
    return this.selectedLos;
  }

  /**
   * Set Selected LOS
   * @description set selected LOS
   * @param {*} Los
   * @memberof AssetService
   */
  setSelectedLos(Los: any) {
    this.selectedLos = Los;
  }

  /**
   * Get selected CoreCompany
   * @description get selected CoreCompany
   * @returns
   * @memberof AssetService
   */
  getSelectedCoreCompany() {
    return this.selectedCoreCompany;
  }

  /**
   * Set Selected CoreCompany
   * @description set selected CoreCompany
   * @param {*} CoreCompany
   * @memberof AssetService
   */
  setSelectedCoreCompany(coreCompany: any) {
    this.selectedCoreCompany = coreCompany;
  }

  /**
   * Can Capture Data
   * @description boolean flag for determining if capturing data is permitted
   * @returns
   * @memberof AssetService
   */
  getCanCaptureData() {
    return this.canCaptureData;
  }

  /**
   * Set Can Capture Data
   * @description sets can capture data
   * @param {*} canCaptureData
   * @memberof AssetService
   */
  setCanCaptureData(canCaptureData: any) {
    if (canCaptureData) {
      this.canCaptureData = canCaptureData;
    } else {
      this.canCaptureData = false;
    }
  }
}
