import { NgModule } from '@angular/core';

import { CCMCSidebarComponent } from './sidebar.component';

@NgModule({
    declarations: [
        CCMCSidebarComponent
    ],
    exports     : [
        CCMCSidebarComponent
    ]
})
export class CCMCSidebarModule {
}
