import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateSetColumnDialogComponent } from './create-set-column-dialog.component';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CreateSetColumnDialogComponent],
  imports: [AppMaterialModule, CommonModule, FormsModule, ReactiveFormsModule],
  entryComponents: [CreateSetColumnDialogComponent]
})
export class CreateSetColumnDialogModule {}
