import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { RequestChangeService } from 'src/@ccmc/services/request-change.service';

@Component({
  selector: 'app-edit-loan-type-dialog',
  templateUrl: './edit-loan-type-dialog.component.html',
  styleUrls: ['./edit-loan-type-dialog.component.scss']
})
export class EditLoanTypeDialogComponent implements OnInit {
  selectedLoanTypeItem = {
    loanType: '',
    productName: '',
    coreValue: '',
    approver: '',
    approvedDate: '',
    edit: '',
    status: false
  }
  originalLoanTypeItem = {
    ignored: false,
    displayName: '',
    value: '',
    businessLogic: '',
  }
  originalLoanTypeItemApproved = {
    ignored: false,
    displayName: '',
    value: '',
    businessLogic: '',
    approvedBy: '',
    approvedDate: '',
    status: false
  }
  unsubscribe: Subject<any> = new Subject();
  editedFlag = false;
  originalLoanTypeTableItem: any;
  loanTypesArray: any;
  changeRequestDoc: any;
  constructor(
    private dialogRef: MatDialogRef<EditLoanTypeDialogComponent>,
    private requestChangeService: RequestChangeService,
    private fieldEditedService: FieldEditedService) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.requestChangeService.editedLoanTypeField
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(data => {
      this.originalLoanTypeTableItem = data;
      this.selectedLoanTypeItem = {
        loanType: data.loanType,
        productName: data.productName,
        coreValue: data.coreValue,
        approver: data.approver,
        approvedDate: data.approvedDate,
        edit: data.edit,
        status: data.status
      }
    });

    this.requestChangeService.changeRequestDocStatuses
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(data => {
      this.changeRequestDoc = data;
      this.loanTypesArray = data.loanTypes;
    });

  }

  save() {
    this.editedFlag = false;
    if(this.selectedLoanTypeItem.approver !== '' && this.selectedLoanTypeItem.approvedDate !== '') {
        this.originalLoanTypeItemApproved = {
        ignored: false,
        displayName: this.selectedLoanTypeItem.productName,
        value: this.selectedLoanTypeItem.coreValue,
        businessLogic: '',
        approvedBy: this.selectedLoanTypeItem.approver,
        approvedDate: this.selectedLoanTypeItem.approvedDate,
        status: this.selectedLoanTypeItem.status
      }
    } else {
      this.originalLoanTypeItem = {
        ignored: false,
        displayName: this.selectedLoanTypeItem.productName,
        value: this.selectedLoanTypeItem.coreValue,
        businessLogic: '',
      }
    }
    let loanTypeIndex = this.loanTypesArray.findIndex((loanType: any) => {
      return loanType.selLoanType === this.selectedLoanTypeItem.loanType;
    });
    if(loanTypeIndex > -1) {
      let loanTypeValuesIndex = this.loanTypesArray[loanTypeIndex].values.findIndex((item: any) => {
        return item.value === this.originalLoanTypeTableItem.coreValue && item.displayName === this.originalLoanTypeTableItem.productName;
      });
        if(loanTypeValuesIndex > -1) {
          if(this.selectedLoanTypeItem.approver !== '' && this.selectedLoanTypeItem.approvedDate !== '') {
            this.loanTypesArray[loanTypeIndex].values[loanTypeValuesIndex] = this.originalLoanTypeItemApproved;
          } else {
            this.loanTypesArray[loanTypeIndex].values[loanTypeValuesIndex] = this.originalLoanTypeItem;
          }
        }
      } 
     this.changeRequestDoc.loanTypes = this.loanTypesArray;
     this.requestChangeService.postDynamoChangeRequestStatuses(this.changeRequestDoc);
     this.dialogRef.close(this.loanTypesArray);
  }

  fieldEdited() {
    if (this.isEquivalent(this.selectedLoanTypeItem, this.originalLoanTypeItem)) {
      this.editedFlag = false;
      this.fieldEditedService.fieldEdited.next(false);
    } else {
      this.editedFlag = true;
      this.fieldEditedService.fieldEdited.next(true);
    }
  }
  isEquivalent(a: any, b: any) {
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      return false;
    }
    for (let i = 0; i < aProps.length; i++) {
      // ignores the edited property since those values will not be the same
      if (aProps[i] !== 'edited') {
        const propName = aProps[i];
        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
          return false;
        }
      }
    }
    return true;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }
}
