<div class="validation-container">
  <div class="filter-container">
    <mat-form-field class="dropdownValidationGroup">
      <mat-select [(ngModel)]="selectedValidationGroup" placeholder="Validation Group"
        (selectionChange)="onChange($event)">
        <mat-option value="all"> All</mat-option>
        <mat-option *ngFor="let group of validationGroups" [value]="group"> {{ group }}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="EditBtn-Container">
      <button class="Edit-btn" matTooltip="Edit current Validation Group" matTooltipClass="toolTipStyle"
        (click)="editValidationGroup()">
        <mat-icon style="color: black">create</mat-icon>
      </button>
      <button class="AddValGroup-btn" matTooltip="Add New Validation Group" matTooltipClass="toolTipStyle"
        (click)="addValidationGroup($event)">
        <mat-icon style="color: #3fb2c6">add_circle</mat-icon>
      </button>
    </div>
    <mat-form-field floatLabel="never" id="formField" class="filter-FormField1">
      <mat-icon style="transform: translate(-26px,-3px);z-index: 1000;position: absolute;">search</mat-icon>
      <input matInput placeholder="Filter by..." #filterSearch />
    </mat-form-field>
  </div>
    <div #container class="table-container">
        <!--Add Button-->
        <div class="AddBtn-Container">
            <button class="Add-btn" matTooltip="Add New Validation" matTooltipClass="toolTipStyle" (click)="addValidation()">
        <mat-icon style="color: #3fb2c6">add_circle</mat-icon>
      </button>
    </div>
    <table
      mat-table
      class="table-TableContainer"
      #table
      #dataSourceSort="matSort"
      [dataSource]="dataSource"
      CCMCPerfectScrollbar
      matSort
    >
      <!-- Field ID Column -->
      <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%">
          Display Name
        </th>
        <td mat-cell *matCellDef="let element" class="noselect">
          {{ element.displayName }}
        </td>
      </ng-container>

      <!-- Field Display Column -->
      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%">
          Value
        </th>
        <td mat-cell *matCellDef="let element" class="value-col noselect">
          {{ element.value }}
        </td>
      </ng-container>

      <!-- Field Display Column -->
      <ng-container matColumnDef="fieldIDs">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="fieldIDs" style="
            width: 70%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding-left: 5%;
          ">
          FieldIDs
        </th>
        <td mat-cell *matCellDef="let element" class="value-col noselect" style="
            width: 10%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding-left: 5%;
          ">
          {{ element.fieldIDs | addSpaceAfterComma }}
        </td>
      </ng-container>

      <!-- Field Edited Column -->
      <ng-container matColumnDef="edited">
        <th
          class="editHeader"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          style="width: 10%; padding-left: 78px; padding-right: 81px"
        >
          Edited
        </th>

        <td mat-cell *matCellDef="let element" class="noselect">
          <mat-icon
            *ngIf="element.edited === true"
            style="margin-left: 83px; vertical-align: top"
          >
            star</mat-icon
          >
        </td>
      </ng-container>

      <!-- validation Group column -->
      <ng-container matColumnDef="validationGroup">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;padding-left: 6%;">
          Validation Group
        </th>
        <td mat-cell *matCellDef="let element" class="value-col noselect" style="padding-left: 6%;">
          {{ element.validationGroup }}
        </td>
      </ng-container>

      <!-- Check Box Column -->
      <ng-container matColumnDef="conditionalCheckBox">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox class="example-margin"
            matTooltip="Check / Uncheck All (If multiselect will only apply to selection)"
            matTooltipClass="toolTipStyle" (change)="checkAllBox($event)" [checked]="tableHeaderCheckBox">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element" style="min-width: 40px" class="noselect">
          <mat-checkbox class="example-margin" s (change)="setUpdateFieldByFieldID(element, $event)"
            [checked]="element.updateField">
          </mat-checkbox>
        </td>
      </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onSelectValidation(row, $event)"
              [ngClass]="{ 'accent-50-bg': selectedValidation.includes(row) }"></tr>
        </table>
    </div>
    <div class="paginatorContainer">
        <mat-paginator
          [pageSizeOptions]="[100]"
          showFirstLastButtons
          class="paginator-conditions"
          (click)="container.scrollTop = 0"
        >
        </mat-paginator>
        <button mat-button style="z-index: 10000000;
        color: #AD0000;
        margin-top: -3%;
        position: absolute;" (click)="removeValidation()">
          <mat-icon style="color:#AD0000">delete_forever</mat-icon>
          Delete Checked
        </button>
      </div>
    <div class="tabBar">
        <!-- <div class="RemoveBtn-Container">
            <button class="Btn-remove" matTooltip="Remove Selected Validation" matTooltipClass="toolTipStyle" color="primary" (click)="removeValidation()">
        <mat-icon style="color: #7570d2">remove_circle</mat-icon>&nbsp;
      </button>
    </div> -->
    <!--Tab Menu-->
    <mat-tab-group>
      <mat-tab label="Validation">
        <div style="display: grid; grid-template-columns: 1fr 1fr; width: 100%" *ngIf="selectedValidation">
          <div class="mapping-validationSelectOptionsBox">
            <div style="display: flex; justify-content: space-between">
              <div class="validationOptions-title">Edit Validation</div>
              <button mat-raised-button matTooltip="Saves any changes you make, within this section."
                matTooltipClass="toolTipStyle" color="accent" (click)="save()" [disabled]="!editedFlag"
                class="saveBtn-mapping-validation">
                Save
              </button>
            </div>
            <div class="selectOptions-line-one">
              <mat-form-field class="form-fieldMappingValidation" style="padding-right: 50px">
                <mat-select [(ngModel)]="localSelectedValidation.validationGroup" (ngModelChange)="fieldEdited()"
                  placeholder="Validation Group Name">
                  <mat-option *ngFor="let group of validationGroups" [value]="group"> {{ group }}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="form-fieldMappingValidation">
                <input matInput placeholder="Value" [(ngModel)]="localSelectedValidation.value"
                  (ngModelChange)="fieldEdited()" (keydown.control.s)="save()" />
              </mat-form-field>
              <mat-form-field class="form-fieldMappingValidation" style="padding-right: 50px;margin-left: 7%;">
                <input matInput placeholder="Display Name" [(ngModel)]="localSelectedValidation.displayName"
                  (ngModelChange)="fieldEdited()" (keydown.control.s)="save()" />
              </mat-form-field>
            </div>
          </div>
          <div style="display: grid; grid-template-rows: 4fr 1fr; height: 84%">
            <div class="table-containter" style="
                overflow-y: scroll;
                overflow-x: hidden;
                background-color: #ffffff;
                height: 32vh;
              ">
              <table mat-table class="table-TableContainer" style="width: 100%" #fieldIDsDataSourceSort="matSort"
                [dataSource]="fieldIDsDataSource" CCMCPerfectScrollbar matSort>
                <!-- Field ID Column -->
                <ng-container matColumnDef="fieldID">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    FieldID
                  </th>
                  <td mat-cell *matCellDef="let element" class="noselect">
                    {{ element.fieldID }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="fieldIDsDisplayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: fieldIDsDisplayedColumns" (click)="onSelectFieldID(row)"
                  [ngClass]="{ 'accent-50-bg': row == selectedFieldID }"></tr>
              </table>
            </div>
            <div style="
                background-color: #e1e1e1;
                display: grid;
                grid-template-columns: 10fr 1fr 1fr;
                border: 1px solid lightgray;
              ">
              <mat-form-field class="filter-FormField2" style="margin-left: 20px; margin-right: 25px">
                <input type="text" #fieldIDInput placeholder="Field ID" matInput [formControl]="myControlFieldID"
                  [matAutocomplete]="auto1" [(ngModel)]="newFieldID" (keydown.control.s)="save()" />
                <mat-autocomplete #auto1="matAutocomplete">
                  <mat-optgroup label="Select an available validation">
                    <mat-option *ngFor="let option of filteredOptionsFieldID | async" [value]="option['fieldID']">
                      {{ option['fieldID'] }}
                    </mat-option>
                  </mat-optgroup>
                </mat-autocomplete>
              </mat-form-field>
              <button class="fieldIDAdd-btn" matTooltip="Add FieldID to current validation"
                matTooltipClass="toolTipStyle" (click)="addFieldIDToValidation()"
                (keydown.enter)="addFieldIDToValidation()">
                <mat-icon style="color: #3fb2c6; margin: 2px">add_circle</mat-icon>
              </button>
              <button class="fieldIDBtn-remove" matTooltip="Remove FieldID from current validation"
                matTooltipClass="toolTipStyle" color="primary" (click)="removeFieldIDFromValidation()">
                <mat-icon style="color: #7570d2; margin: 2px">remove_circle</mat-icon>&nbsp;
              </button>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<!--Spinner-->
<div class="spinner-container" *ngIf="showSpinner">
  <div class="new-spinner">
    <div class="outer-ring"></div>
    <div class="middle-ring"></div>
    <div class="inner-ring"></div>
  </div>
</div>
