import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CCMCSharedModule } from '../../../../@ccmc/shared.module';

import { CCMCFileSelectorComponent } from './file-selector.component';
import { AuthGuard } from '../../../auth/auth.guard';

const routes: Routes = [
  {
    path: 'file-selector',
    component: CCMCFileSelectorComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [CCMCFileSelectorComponent],
  imports: [RouterModule.forChild(routes), CCMCSharedModule],
  exports: [CCMCFileSelectorComponent]
})
export class CCMCFileSelectorModule {}
