<div class="environment-variables-container">
    <mat-card *ngIf="
    losCheck === 'encompass' ||
    losCheck === 'calyx' ||
    losCheck === 'mortgagebot' ||
    losCheck === 'ncino' ||
    losCheck === 'bytepro'
  "
  class="environment-variables-card">
        <div class="title-container">
            <h1>Environment Variables</h1>
            <div class="Btn-container">
                <button mat-raised-button matTooltip="Update the variables." [disabled]="editedFlag === false" matTooltipClass="toolTipStyle" color="accent" (click)="updateVariables()" class="saveBtnTop">
          Update
        </button>
            </div>
        </div>
        <div class="mainForm" *ngIf="variableKeys">
            <div *ngFor="let variable of variableKeys; let i = index">
                <div *ngIf="isNXTsoft || isNXTsoftDev || isNXTsoftOperations || isNXTsoftSales">
                    <mat-form-field class="mat-form-field1">
                        <input matInput placeholder="{{ variable }}" [(ngModel)]="variables[variable]" (ngModelChange)="fieldEdited()" />
                    </mat-form-field>
                </div>
                <div *ngIf="!isNXTsoft && !isNXTsoftDev && !isNXTsoftOperations && !isNXTsoftSales">
                    <div *ngIf="
                    !variable.toLowerCase().includes('clientsecret') && 
                    !variable.toLowerCase().includes('assetid') &&
                    !variable.toLowerCase().includes('authorizationkey') &&
                    !variable.toLowerCase().includes('sessionid') &&
                    !variable.toLowerCase().includes('servername')">
                        <mat-form-field *ngIf="!variable.toLowerCase().includes('password')" class="mat-form-field1">
                            <input matInput placeholder="{{ variable }}" [(ngModel)]="variables[variable]" (ngModelChange)="fieldEdited()" />
                        </mat-form-field>
                        <mat-form-field *ngIf="variable.toLowerCase().includes('password')" class="mat-form-field1">
                          <input matInput type="password" placeholder="{{ variable }}" [(ngModel)]="variables[variable]" (ngModelChange)="fieldEdited()" />
                      </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
    <mat-card
    *ngIf="
      !(
        losCheck === 'encompass' ||
        losCheck === 'calyx' ||
        losCheck === 'mortgagebot' ||
        losCheck === 'ncino' ||
        losCheck === 'bytepro'
      )
    "
    class="source-watcher-configuration-card"
  >
    <div>
      <h1>Source Watcher Configuration</h1>
      <form>
      <div class="mainForm">
        <div>
          <mat-form-field class="mat-form-field2">
            <input
              matInput
              placeholder="username"
              [(ngModel)]="localSelected.username"
              name="username"
            />
          </mat-form-field>
          <mat-form-field class="mat-form-field2">
            <input
              matInput
              [type]="hideFields ? 'password' : 'text'"
              [(ngModel)]="localSelected.password"
              matInput
              placeholder="password"
              autocomplete="new-password"
              name="password"
            />
            <mat-icon matSuffix (click)="hideFields = !hideFields"
              >{{ hideFields ? 'visibility_off' : 'visibility' }}
            </mat-icon>
          </mat-form-field>

          <mat-form-field class="mat-form-field2">
            <input
              type="text"
              matInput
              placeholder="path"
              [(ngModel)]="localSelected.path"
              name="path"
            />
          </mat-form-field>
      </div>
    </div>
  </form>
      <div>
        <button
          mat-raised-button
          matTooltip="Generate a new Source Watcher XML Config file."
          matTooltipClass="toolTipStyle"
          color="accent"
          (click)="generateXML()"
          style="float: right; margin-right: 7%"
        >
          Generate XML
        </button>
      </div>
    </div>
  </mat-card>
</div>

<!--Spinner-->
<div class="spinner-container" *ngIf="showSpinner">
  <div class="new-spinner">
    <div class="outer-ring"></div>
    <div class="middle-ring"></div>
    <div class="inner-ring"></div>
  </div>
</div>