<div
  style="
    display: grid;
    grid-template-rows: 50px 313px;
    height: 100%;
    width: 100%;
  "
>
  <div class="filter-container" style="min-height: 50px !important">
    <div class="left-aligned-header">
      <mat-form-field class="select-form" appearance="outline">
        <mat-label>Select Set</mat-label>
        <mat-select [(value)]="selectedSet" panelClass="dropDown-overlay">
          <mat-option
            *ngFor="let set of sets"
            [value]="set"
            (click)="selectSet(set)"
            >{{ set.setName }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <button
        mat-flat-button
        matTooltip="Edit Set"
        matTooltipClass="toolTipStyle"
        color="primary"
        onclick="this.blur()"
        (click)="editSet()"
        class="edit-set-button"
      >
        <mat-icon style="color: white; margin-bottom: 4px">edit</mat-icon>Edit
        Set
      </button>
      <button
        mat-flat-button
        matTooltip="Add Set"
        matTooltipClass="toolTipStyle"
        color="primary"
        onclick="this.blur()"
        (click)="addSet()"
        class="add-set-button"
      >
        <mat-icon style="color: white; margin-bottom: 4px">add_circle</mat-icon
        >Add Set
      </button>
      <button
        mat-flat-button
        matTooltip="Edit Columns"
        matTooltipClass="toolTipStyle"
        color="accent"
        onclick="this.blur()"
        (click)="openAddRemoveColumnsDialogComponent()"
        class="add-set-button"
      >
        <mat-icon style="color: white; margin-bottom: 4px">edit</mat-icon>Edit
        Columns
      </button>
    </div>
    <mat-form-field class="filter-form" appearance="outline" floatLabel="never">
      <mat-icon matPrefix class="search-icon">search</mat-icon>
      <input
        matInput
        #filterSearch
        type="search"
        autocomplete="off"
        placeholder="Filter transactions.."
        style="color: black"
      />
    </mat-form-field>
  </div>
      <div class="table-container">
        <table
          mat-table
          class="table-TableContainer"
          #table
          [dataSource]="transactionsDataSource"
          CCMCPerfectScrollbar
          matSort
          id="columnTable"
        >
          <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let element" class="noselect">
              <div id="item-{{ element.displayOrder }}">
                {{ element.Name }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="edited">
            <th
              class="editHeader"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
            >
              Edited
            </th>

            <td mat-cell *matCellDef="let element" class="noselect">
              <mat-icon *ngIf="element.edited === true"> star</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="editColumn">
            <th mat-header-cell *matHeaderCellDef>
              <div class="AddBtn-Container">
                <button
                  class="Add-btn"
                  matTooltip="Add New Transaction"
                  matTooltipClass="toolTipStyle"
                  (click)="createSetTransaction()"
                  [disabled]="!selectedSet"
                >
                  <mat-icon style="color: #3fb2c6">add_circle</mat-icon>
                </button>
              </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-icon
                style="cursor: pointer"
                (click)="openEditTransactionDialog(element)"
                >edit</mat-icon
              >
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="onSelectTransaction(row)"
            [ngClass]="{ 'accent-50-bg': row == selectedTransaction }"
          ></tr>
        </table>
      </div>
      <div class="paginatorContainer">
        <mat-paginator
          [pageSizeOptions]="[25]"
          showFirstLastButtons
          class="paginator"
        >
        </mat-paginator>
    </div>
    <div class="tabBar">
      <div class="tabBar-button-container">
        <button
          mat-flat-button
          matTooltip="Open Snippets Dialog"
          matTooltipClass="toolTipStyle"
          color="primary"
          (click)="openSnippetDialog()"
          class="snippet-button"
          style="margin-right: 15px"
        >
          Snippets
        </button>
      </div>
      <mat-tab-group>
        <mat-tab label="Columns">
          <div>
            <app-set-configuration-columns></app-set-configuration-columns>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
