import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CCMCEdgeService } from 'src/@ccmc/services/edge.service';
import { CcmcApiService } from '../../../services/ccmc-api.service';

@Component({
  selector: 'app-new-snippets-dialog',
  templateUrl: './new-snippets-dialog.component.html',
  styleUrls: ['./new-snippets-dialog.component.scss']
})
export class NewSnippetsDialogComponent implements OnInit {
  newSnippet: any;
  codemirrorConfig = {
    mode: 'vbscript',
    lineNumbers: true,
    theme: 'material',
    extraKeys: { 'Ctrl-Space': 'autocomplete' },
    lineWrapping: true,
    foldGutter: true,
    gutters: [
      'CodeMirror-linenumbers',
      'CodeMirror-foldgutter',
      'CodeMirror-lint-markers'
    ],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true
  };
  showSpinner = false;
  translators: any;
  private documentLosSub: Subscription;
  unsubscribe: Subject<any> = new Subject();
  constructor(
    private zone: NgZone,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<NewSnippetsDialogComponent>,
    private ccmcApiService: CcmcApiService,
    @Inject(MAT_DIALOG_DATA) public snippets: any,
    private edgeService: CCMCEdgeService
  ) {}

  ngOnInit() {
    this.getData();
    this.newSnippet = {
      title: '',
      description: '',
      snippet: ''
    };
  }

  createSnippet() {
    if (this.checkValidSnippet() && this.checkIfNewSnippet()) {
      this.compileSnippet();
    } else {
    }
  }

  openSnackBar(message: string, action: string) {
    this.zone.run(() => {
      setTimeout(() => {
        this.snackBar.open(message, action, {
          duration: 5000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }, 0);
    });
  }

  closeDialog() {
    this.dialogRef.close({ data: false });
  }

  checkIfNewSnippet() {
    for (let snippet of this.snippets) {
      if (snippet.title === this.newSnippet.title) {
        this.openSnackBar(`${this.newSnippet.title} already exists`, 'Okay');
        return false;
      }
    }
    return true;
  }

  getData() {
    // Subscribe to the document LOS to get the translators
    this.documentLosSub = this.ccmcApiService.documentLos
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(documentLos => {
        if (documentLos) {
          this.translators = documentLos.loan;
        }
      });
  }

  checkValidSnippet() {
    if (this.newSnippet.title.length < 1) {
      this.openSnackBar('Title must not be blank', 'Okay');
      return false;
    } else if (this.newSnippet.description.length < 1) {
      this.openSnackBar('Description must not be blank', 'Okay');
      return false;
    } else if (this.newSnippet.snippet.length < 1) {
      this.openSnackBar('Snippet must not be blank', 'Okay');
      return false;
    } else {
      return true;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  compileSnippet() {
    let multEnd;
    // Initialize vbscript errors to empty array
    let vbScriptErrors: any[] = [];
    // Pass vbscript to the compile engine
    this.edgeService.compileEngine(
      this.newSnippet.snippet,
      async (error: any, result: any) => {
        // splits the error message into an array
        if (result.indexOf('\r\n') !== -1) {
          vbScriptErrors = result.split('\r\n');
        }
        // If vbscript errors exist
        if (vbScriptErrors.length > 0) {
          for (let i = 0; i < vbScriptErrors.length - 1; i++) {
            // checks to see if the error message is because of an undeclared variable
            if (vbScriptErrors[i].indexOf('BC30451') > 0) {
              const tmp = vbScriptErrors[i].match(/'(.*)'/);
              let tempTranslator: any;
              if (tmp[1].length > 0 && tmp[1] !== 'MXR') {
                tempTranslator = tmp[1];
                if (
                  this.translators.findIndex(
                    (t: any) => t.fieldName === tempTranslator
                  ) == -1
                ) {
                  console.log(tempTranslator);
                  let lastChar = tempTranslator[tempTranslator.length - 1];
                  // If last character is a number remove it
                  if (lastChar >= '0' && lastChar <= '9') {
                    multEnd = +lastChar;
                    tempTranslator = tempTranslator.slice(0, -1);
                  } else {
                    multEnd = null;
                  }
                  lastChar = tempTranslator[tempTranslator.length - 1];
                  if (lastChar >= '0' && lastChar <= '9') {
                    multEnd = multEnd! + 10 * +lastChar;
                    tempTranslator = tempTranslator.slice(0, -1);
                  }
                  const tmpIndex = this.translators.findIndex(
                    (obj: any) => obj.fieldName === tempTranslator
                  );
                  if (multEnd && tmpIndex > -1) {
                    if (multEnd > this.translators[tmpIndex].multEnd) {
                      console.log(this.translators[tmpIndex]);
                      this.openSnackBar(
                        'Exceeds Mult End ' +
                          this.translators[tmpIndex].multEnd +
                          '.',
                        'Okay'
                      );
                      return;
                    }
                  }
                  // If index is less than 1 the translator doesnt exist
                  if (tmpIndex < 0) {
                    const msg = vbScriptErrors[i];
                    this.openSnackBar(msg, 'Okay');
                    return;
                  }
                }
                // If we get here we know the translator exists and we can remove this error from the
                // errors list
                vbScriptErrors.splice(i, 1);
                i--;
              }
            }
          }
        }
        // Remove MXR Error here
        for (let i = 0; i < vbScriptErrors.length; i++) {
          const tmp = vbScriptErrors[i].match(/'(.*)'/);
          if (tmp && tmp[1] == 'MXR') {
            vbScriptErrors.splice(i, 1);
            i--;
          }
        }
        // If there are errors display the error
        if (vbScriptErrors.length > 1) {
          const msg = vbScriptErrors[0];
          this.openSnackBar(msg, 'Okay');
          return;
        } else {
          this.updateSnippet();
        }
      }
    );
  }

  updateSnippet() {
    this.showSpinner = true;
    this.ccmcApiService
      .createDynamoDB('test', 'code_snippets', this.newSnippet)
      .subscribe(result => {
        if (result) {
          this.openSnackBar('Updated Code Snippet', 'Okay');
          this.dialogRef.close({
            data: true
          });
        }
      });
  }
}
