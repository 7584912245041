import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddUserPopupDialogComponent } from './add-user-popup-dialog.component';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [AddUserPopupDialogComponent],
  imports: [AppMaterialModule, CommonModule, FormsModule, ReactiveFormsModule],
  entryComponents: [AddUserPopupDialogComponent]
})
export class AddUserPopupDialogModule {}
