<div style="min-width: 50vw">
  <h1 matDialogTitle>Doc Connector Result</h1>
  <div *ngIf="responseMessages">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let response of responseMessages">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ response.loan.loanName }}
          </mat-panel-title>
          <mat-panel-description>
            {{ response.loanResponse.statusMessage }}
            <mat-icon
              *ngIf="response.loanResponse.statusFlag === true"
              class="success"
              >check_circle</mat-icon
            >
            <mat-icon
              *ngIf="response.loanResponse.statusFlag === false"
              class="error"
              >error_outline</mat-icon
            >
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <mat-list
            role="list"
            style="flex-grow: 1; overflow: auto; height: 250px"
          >
            <mat-list-item
              role="listitem"
              *ngFor="let attatchment of response.loanResponse.content"
            >
              <mat-icon
                mat-list-icon
                *ngIf="attatchment.fileName.includes('.pdf')"
                >picture_as_pdf</mat-icon
              >
              <mat-icon
                mat-list-icon
                *ngIf="!attatchment.fileName.includes('.pdf')"
                >folder</mat-icon
              >
              <div mat-line>{{ attatchment.fileName }}</div>
              <div mat-line>{{ attatchment.statusMessage }}</div>
              <mat-divider></mat-divider>
            </mat-list-item>
          </mat-list>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div
    mat-dialog-actions
    class="pt-24 error-actions"
    style="padding-bottom: 35px"
  >
    <button
      class="ok-button"
      mat-raised-button
      color="accent"
      (click)="dialogRef.close(true)"
    >
      OK
    </button>
  </div>
</div>
