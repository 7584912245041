import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestChangeDialogComponent } from './request-change-dialog.component';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CCMCSharedModule } from 'src/@ccmc/shared.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [RequestChangeDialogComponent],
  imports: [CommonModule, AppMaterialModule, FormsModule],
  entryComponents: [RequestChangeDialogComponent]
})
export class RequestChangeDialogModule {}
