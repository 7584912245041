<div class="confirm-dialog" style="position: relative">
    <div mat-dialog-content class="confirm-dialog-message" style="font-size: 20px; margin-top: -10px">
        Edit Asset: {{ data.asset.assetId }}
    </div>
    <form class="example-form">
        <div class="field-container">
            <div *ngIf="isNXTsoftOperations; then thenBlock; else elseBlock"></div>
            <ng-template #thenBlock>
                <mat-form-field class="example-full-width" style="width: 31%; margin-right: 2%">
                    <mat-label>Asset ID</mat-label>
                    <input matInput name="id" disabled="true" [value]="localSaved.assetId" />
                </mat-form-field>
            </ng-template>
            <ng-template #elseBlock>
                <mat-form-field class="example-full-width" style="width: 31%; margin-right: 2%">
                    <mat-label>Asset ID</mat-label>
                    <input matInput name="id" (ngModelChange)="fieldEdited($event, 'assetId')" [(ngModel)]="localSaved.assetId" />
                </mat-form-field>
            </ng-template>

            <mat-form-field class="example-full-width" style="width: 31%; margin-right: 2%">
                <mat-label>Company Name</mat-label>
                <input matInput name="company" (ngModelChange)="fieldEdited($event, 'FI')" [(ngModel)]="localSaved.FI" />
            </mat-form-field>

            <mat-form-field style="width: 33%">
                <mat-label>Status</mat-label>
                <mat-select (selectionChange)="fieldEdited($event, 'status')" [(value)]="localSaved.status">
                    <mat-option value="active">Active</mat-option>
                    <mat-option value="inactive">Inactive</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>
    <div mat-dialog-actions class="pt-30" class="confirm-dialogBtn-Container">
        <button mat-stroked-button class="cancelBtn" (click)="dialogRef.close(false)">
      Cancel
    </button>
        <button [disabled]="!editedFlag" style="margin: auto" mat-raised-button class="mat-accent mr-16" (click)="changeOrAddAsset()">
      Update
    </button>
    </div>
    <div style="
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 999999;
    " *ngIf="showSpinner">
        <mat-spinner></mat-spinner>
    </div>
</div>