import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-collateral-detail-dialog',
  templateUrl: './collateral-detail-dialog.component.html',
  styleUrls: ['./collateral-detail-dialog.component.scss']
})
export class CollateralDetailDialogComponent implements OnInit {
  tableColumns: any = ['name', 'value'];
  tableData: any = [];
  collateralDataSource: any;
  @ViewChild('collateralDetailsSort')
  collateralDetailsSort: MatSort;
  @ViewChild('collateralDetailsTable') table: MatTable<any>;
  constructor(
    @Inject(MAT_DIALOG_DATA) public collateral: any,
    private dialogRef: MatDialogRef<CollateralDetailDialogComponent>
  ) {}

  ngOnInit(): void {
    console.log(this.collateral);
    // Existing collateral from search
    if (this.collateral.collateralID) {
      let collateralKeys = Object.keys(this.collateral);
      for (let key of collateralKeys) {
        if (key !== 'fields') {
          let tempTableItem = {
            name: key,
            value: this.collateral[key]
          };
          this.tableData.push(tempTableItem);
        }
      }
    } else {
      for (let field of this.collateral.fields) {
        if (field.fieldValue !== '') {
          this.tableData.push({
            name: field.fieldDisplay,
            value: field.fieldValue
          });
        }
      }
    }
    this.collateralDataSource = new MatTableDataSource(this.tableData);
  }

  close() {
    this.dialogRef.close();
  }
}
