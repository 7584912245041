<div class="change__password">

    <i class="material-icons noselect" style="cursor: pointer;margin-top: -13%;margin-left: -14%;" (click)="closeDialog()">
  close
</i>
    <!--Title-->
    <div class="form-title">CHANGE PASSWORD</div>
    <div class="sub-title-main">Enter your passwords below.</div>

    <!--Form-->
    <form class="change__password--form" [formGroup]="changePasswordForm" (ngSubmit)="changePassword()" ccmcPasswordMatcher style="display: grid; flex-direction: column;justify-content: space-between; grid-row-gap: 16px; margin-top:9%">


        <mat-form-field style="margin-top:5%">
            <input matInput #oldPassword [type]="hideFields1 ? 'password' : 'text'" formControlName="oldPassword" placeholder="Old Password" name="oldPassword">
            <mat-icon matSuffix style="cursor: pointer;" (click)="hideFields1 = !hideFields1">{{ hideFields1 ? 'visibility_off' : 'visibility' }}
            </mat-icon>

        </mat-form-field>

        <div class="change__password--form__error" *ngIf="changePasswordForm.controls.oldPassword.touched && changePasswordForm.controls.oldPassword.errors">
            old password is required
        </div>

        <mat-form-field style="margin-top:5%">
            <input matInput #newPassword [type]="hideFields2 ? 'password' : 'text'" class="change__password--form__new-password--input change__password--form--input" formControlName="newPassword" placeholder="New Password" name="newPassword">
            <mat-icon matSuffix style="cursor: pointer;" (click)="hideFields2 = !hideFields2">{{ hideFields2 ? 'visibility_off' : 'visibility' }}
            </mat-icon>
        </mat-form-field>

        <div class="change__password--form__error" *ngIf="changePasswordForm.get('newPassword')?.touched && changePasswordForm.get('newPassword')?.errors?.required">
            new password is required
        </div>

        <mat-form-field style="margin-top:5%">
            <input matInput #confNewPassword [type]="hideFields3 ? 'password' : 'text'" class="change__password--form__new-password--input change__password--form--input" name="confNewPassword" formControlName="confNewPassword" placeholder="Confirm New Password">
            <mat-icon matSuffix style="cursor: pointer;" (click)="hideFields3 = !hideFields3">{{ hideFields3 ? 'visibility_off' : 'visibility' }}
            </mat-icon>
        </mat-form-field>

        <div class="change__password--form__error" *ngIf="changePasswordForm.get('confNewPassword')?.touched && changePasswordForm.get('confNewPassword')?.errors?.required">
            confirm password is required
        </div>
        <div class="change__password--form__error" *ngIf="changePasswordForm.errors?.passwordMatch">
            passwords don't match
        </div>

        <!--Update Button-->
        <button [disabled]="changePasswordForm.get('newPassword')?.value !== changePasswordForm.get('confNewPassword')?.value || changePasswordForm.invalid || updateBtnDisabled" [class.change__password--button__disabled]="changePasswordForm.get('newPassword')?.value !== changePasswordForm.get('confNewPassword')?.value ||changePasswordForm.invalid || updateBtnDisabled"
            type="submit" mat-button class="change__password--button">Update</button>
    </form>

    <!--Spinner-->
    <div class="mat_spinner" [style.display]="showSpinner ? 'block' : 'none'">
        <img src="assets/images/logos/CCMC_Loading_250px.gif" alt="Loading..." />
    </div>
</div>