import { NgModule } from '@angular/core';
import { CCMCAssetidSelectorComponent } from './assetid-selector.component';
import { CCMCSharedModule } from '../../../../@ccmc/shared.module';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../../auth/auth.guard';

const routes: Routes = [
  {
    path: 'assetid-selector',
    component: CCMCAssetidSelectorComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [CCMCSharedModule, RouterModule.forChild(routes)],
  declarations: [CCMCAssetidSelectorComponent],
  exports: [CCMCAssetidSelectorComponent],
  
})
export class CCMCAssetidSelectorModule {}
