import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';
import { takeUntil } from 'rxjs/operators';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { DocumentConnectorService } from 'src/@ccmc/services/doc-connector.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-doc-connector-source-and-destination',
  templateUrl: './doc-connector-source-and-destination.component.html',
  styleUrls: ['./doc-connector-source-and-destination.component.scss']
})
export class DocConnectorSourceAndDestinationComponent implements OnInit {
  dcDocument: any;
  destinations: any;
  selectedDestination: any;
  destinationsDataSource: any;
  @ViewChild('filterSearch', { static: true }) filterSearchEl: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns = ['id', 'edit'];
  fieldEdited: any;
  unsubscribe: Subject<any> = new Subject();
  public selected: any;
  filterSearch: string;
  private dcSub: Subscription;
  private selectedSub: Subscription;
  constructor(
    private selectedFieldService: CCMCSelectedFieldService,
    private fieldEditedService: FieldEditedService,
    private dcService: DocumentConnectorService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  clearSelectedDestination() {
    this.selectedFieldService.onDestinationFieldSelected.next(undefined);
  }

  getData() {
    this.selectedSub = this.selectedFieldService.onDestinationFieldSelected
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(selected => {
        console.log('Selected Destination', selected);
        this.selectedDestination = selected;
      });
    this.dcSub = this.dcService.dcDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dcDocument => {
        if (dcDocument) {
          console.log('Doc Connector Document', dcDocument);
          this.dcDocument = dcDocument;
          if (this.dcDocument.destinations) {
            this.destinations = this.dcDocument.destinations;
            if (this.destinations.length === 0) {
              return;
            }
            this.destinationsDataSource = new MatTableDataSource(
              this.destinations
            );
            this.destinationsDataSource.paginator = this.paginator;
            this.destinationsDataSource.sort = this.sort;
          }
        } else {
          this.destinations = undefined;
        }
      });
  }
}
