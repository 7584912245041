import { CanDeactivate } from '@angular/router';
import { SupportValuesComponent } from './support-values.component';
import { Injectable } from "@angular/core";

/**
 * Deactivate support values
 *
 * @export
 * @class DeactivateSupportValues
 * @implements {CanDeactivate<SupportValuesComponent>}
 */
@Injectable()
export class DeactivateSupportValues implements CanDeactivate<SupportValuesComponent> {

    /**
     * Controls if the user can route away from the support values tab
     *
     * @param {CCMCSupportValuesComponent} component
     * @returns
     * @memberof DeactivateSupportValues
     */
    async canDeactivate(component: SupportValuesComponent) {
        return true;
        // return component.canDeactivate();
    }
}
