<div class="confirm-dialog" style="position: relative">
  <div mat-dialog-content class="confirm-dialog-message" style="font-size: 20px; margin-top: -10px">
    Add User
  </div>

  <mat-form-field class="example-full-width" style="width: 49%;  margin-right: 2%">
    <mat-label>First Name</mat-label>
    <input matInput [(ngModel)]="tmpUser.contactFirstName" />

    <!-- <button
      style="float: right; margin-top: -36px"
      mat-raised-button
      color="primary"
      (click)="loadAssets(tmpUser.accountID)"
    >
      Validate
    </button> -->
  </mat-form-field>
  <mat-form-field class="example-full-width" style="width: 49%; ">
    <mat-label>Last Name</mat-label>
    <input matInput [(ngModel)]="tmpUser.contactLastName" />
  </mat-form-field>
  <mat-form-field class="example-full-width" style="width: 49%; margin-right: 2%">
    <mat-label>Email</mat-label>
    <input matInput [(ngModel)]="tmpUser.email" />
  </mat-form-field>
  <mat-form-field style="width: 49%">
    <mat-label>Assets</mat-label>
    <mat-select [(ngModel)]="assetIDList" placeholder="Assets" multiple (openedChange)="setAssetIDs()">
      <mat-select-trigger>
        {{ assetIDList[0] ? assetIDList[0].SalesforceAssetId : '' }}
        <span *ngIf="assetIDList?.length > 1">
          (+{{ assetIDList.length - 1 }}
          {{ assetIDList?.length === 2 ? 'other' : 'others' }})
        </span>
      </mat-select-trigger>
      <mat-option *ngFor="let asset of availableAssets" [value]="asset">{{ asset.SalesforceAssetId }} : {{
        asset.AssetName }}</mat-option>
    </mat-select>
  </mat-form-field>


  <div style="display: flex; justify-content: space-between; padding-top: 3%">
    <button mat-raised-button matTooltip="Close Dialog" matTooltipClass="toolTipStyle" (click)="closeDialog()"
      style="text-align: left; max-width: 75px">
      Cancel
    </button>
    <button mat-raised-button matTooltip="Update User"
      [disabled]="!tmpUser.email || !tmpUser.contactFirstName || !tmpUser.contactLastName || tmpUser.assetID === ''"
      matTooltipClass="toolTipStyle" color="accent" (click)="save()" style="text-align: right; max-width: 50px">
      Save
    </button>
  </div>
  <!-- <mat-form-field style="width: 49%; margin-right: 2%" *ngIf="!isNXTsoft">
    <mat-chip-list #chipList>
      <mat-chip
        *ngFor="let asset of assetsArray"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(asset)"
      >
        {{ asset }}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input
        placeholder="Asset(s)"
        #assetsInput
        [formControl]="assetsCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-list>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selectedAssetOption($event)"
    >
      <mat-option *ngFor="let asset of filteredAssets | async" [value]="asset">
        {{ asset }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field style="width: 49%; margin-right: 2%" *ngIf="isNXTsoft">
    <mat-chip-list #chipList>
      <mat-chip
        *ngFor="let asset of assetsArray"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(asset)"
      >
        {{ asset }}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input
        placeholder="Asset(s)"
        #assetsInput
        [formControl]="assetsCtrl"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-list>
  </mat-form-field> -->
</div>
<div class="spinner-container" *ngIf="showSpinner">
  <div class="new-spinner">
    <div class="outer-ring"></div>
    <div class="middle-ring"></div>
    <div class="inner-ring"></div>
  </div>
</div>