import { Pipe, PipeTransform } from '@angular/core';

/**
 * Adds spaces after commas
 *
 * @export
 * @class AddSpaceAfterCommaPipe
 * @implements {PipeTransform}
 */
@Pipe({ name: 'addSpaceAfterComma' })
export class AddSpaceAfterCommaPipe implements PipeTransform {
  /**
   * Transforms camelcase to dashes
   *
   * @param {string} value
   * @param {any[]} [args=[]]
   * @returns
   * @memberof AddSpaceAfterCommaPipe
   */
  transform(value: string, args: any[] = []) {
    return value ? String(value).replace(/[\,,]/g, ', ') : '';
  }
}
