<div id="background-container" [ngStyle]="{'display': isNXTsoftDev || isNXTsoftOperations ? '' : 'none' }">
  <div class="headerBar">
    <!--Header Bar-->
    <div class="header-content">
      <!--Header Text-->
      <img
        src="assets/images/logos/GC-rocket-omni1.png"
        width="100"
        height="100"
        style="float: left; margin-left: 55px"
      />
      <div class="header-text">Select an Asset</div>

      <!--Create Asset Button-->
      <div class="logoutContainer">
        <button (click)="logout()" mat-stroked-button class="logoutBtn">
          <mat-icon>exit_to_app</mat-icon>&nbsp;Logout
        </button>
      </div>

      <!--Search Bar Container-->
      <div class="search-bar">
        <button class="icon-button">
          <mat-icon class="search-icon">search</mat-icon>
        </button>
        <input
          class="search-input"
          placeholder="Search Assets..."
          (keyup)="changed($any($event.target).value)"
        />
      </div>
    </div>
  </div>
  <div [ngStyle]="selectedAssetRow('height')!">
    <mat-card class="selectorWindow" style="margin-top: 118px;">
      <div #container style="width: 100%; overflow: scroll; height: 92%">
        <table
          mat-table
          #table
          [dataSource]="fullDataSourceTable"
          CCMCPerfectScrollbar
          matSort
          style="width: 100%; overflow: scroll"
        >
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <mat-icon
                *ngIf="element.isPinned; else elseBlock"
                style="cursor: pointer"
                aria-hidden="false"
                aria-label="icon to tell if pinned"
                (click)="saveCheckBox(element)"
                color="accent"
                >star</mat-icon
              >
              <ng-template #elseBlock>
                <mat-icon
                  aria-hidden="false"
                  style="cursor: pointer"
                  aria-label="icon to tell if pinned"
                  (click)="saveCheckBox(element)"
                  >star_border</mat-icon
                >
              </ng-template>
            </td>
          </ng-container>
          <ng-container matColumnDef="assetId">
            <th mat-header-cell *matHeaderCellDef>Asset ID</th>
            <td mat-cell *matCellDef="let element" class="cell stickyStart assetIDColumn">
              {{ element.assetId }}
            </td>
          </ng-container>
          <ng-container matColumnDef="product">
            <th mat-header-cell *matHeaderCellDef>Product</th>
            <td
              mat-cell
              *matCellDef="let element"
              class="fieldDisplay-col noselect"
            >
              {{ element.product }}
            </td>
          </ng-container>

          <ng-container matColumnDef="FI">
            <th mat-header-cell *matHeaderCellDef>Company</th>
            <td
              mat-cell
              *matCellDef="let element"
              class="transactionsId-col noselect"
            >
              {{ element.FI }}
            </td>
          </ng-container>

          <ng-container matColumnDef="los">
            <th mat-header-cell *matHeaderCellDef>Source</th>
            <td
              mat-cell
              *matCellDef="let element"
              [matTooltipShowDelay]="showDelay.value"
              matTooltip="{{ element.los }}"
            >
              {{ element.los }}
            </td>
          </ng-container>

          <ng-container matColumnDef="core">
            <th mat-header-cell *matHeaderCellDef>Target</th>
            <td
              mat-cell
              *matCellDef="let element"
              [matTooltipShowDelay]="showDelay.value"
              matTooltip="{{ element.core }}"
              class="transactionsId-col noselect"
            >
              {{ element.core }}
            </td>
          </ng-container>
          <ng-container matColumnDef="modifiedByUser">
            <th mat-header-cell *matHeaderCellDef>Modified By</th>
            <td
              mat-cell
              *matCellDef="let element"
              class="transactionsId-col noselect"
            >
              {{ element.modifiedByUser }}
            </td>
          </ng-container>
          <ng-container matColumnDef="dateModified">
            <th mat-header-cell *matHeaderCellDef>Modified Date</th>
            <td
              mat-cell
              *matCellDef="let element"
              class="transactionsId-col noselect"
            >
              {{ element.dateModified | date: 'MM/dd/yyyy, h:mm a' }}
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (dblclick)="selectAsset(row)"
            [ngClass]="{ 'accent-50-bg': row == selected }"
          ></tr>
        </table>
        <div class="paginatorContainer">
          <mat-paginator [pageSizeOptions]="[100]" showFirstLastButtons class="paginator-conditions"
              (click)="container.scrollTop = 0">
          </mat-paginator>
        </div>
      </div>
    </mat-card>
  </div>
  <!--Spinner-->
  <div class="spinner-container" *ngIf="showSpinner">
    <div class="new-spinner">
      <div class="outer-ring"></div>
      <div class="middle-ring"></div>
      <div class="inner-ring"></div>
    </div>
  </div>
</div>

<div id="assetcard-container" [ngStyle]="{'display': !(isNXTsoftDev || isNXTsoftOperations) ? '' : 'none' }">
  <div class="headerBar">
    <!--Header Bar-->
    <div class="header-content">
      <!--Header Text-->

      <div class="header-text">Select an Asset</div>

      <!--Create Asset Button-->
      <div class="logoutContainer">
        <button (click)="logout()" mat-stroked-button class="logoutBtn">
          <mat-icon>exit_to_app</mat-icon>&nbsp;Logout
        </button>
      </div>

      <!--Search Bar Container-->
      <div class="search-bar">
        <button class="icon-button">
          <mat-icon class="search-icon">search</mat-icon>
        </button>
        <input
          class="search-input"
          placeholder="Search Assets..."
          (keyup)="changed($any($event.target).value)"
        />
      </div>
    </div>
  </div>

  <!--Background Image-->
  <img
    class="BG-image"
    src="assets/images/backgrounds/light-grey-tech.png"
    no-repeat
    center
    center
    fixed
  />

  <!--Asset Cards-->
  <div class="container-center">
    <ng-template ngFor let-setting [ngForOf]="filteredSettings">
      <button mat-button>
        <mat-card class="card" (click)="selectAsset(setting)">
          <!--Card Image commerical/comsumer, test and Production-->
          <img
            mat-card-image
            class="card-image"
            *ngIf="setting.product.includes('prod_commercial')"
            src="assets/images/cards/commercial.png"
          />

          <img
            mat-card-image
            class="card-image"
            *ngIf="setting.product.includes('test_commercial')"
            src="assets/images/cards/test_commercial.png"
          />

          <img
            mat-card-image
            class="card-image"
            *ngIf="setting.product.includes('prod_consumer')"
            src="assets/images/cards/consumer.png"
          />

          <img
            mat-card-image
            class="card-image"
            *ngIf="setting.product.includes('test_consumer')"
            src="assets/images/cards/test_consumer.png"
          />

          <!--Card Image Doc Connector, test and Production-->
          <img
            mat-card-image
            class="card-image"
            *ngIf="setting.product.includes('prod_doc_connector')"
            src="assets/images/cards/doc_connector.png"
          />

          <img
            mat-card-image
            class="card-image"
            *ngIf="setting.product.includes('test_doc_connector')"
            src="assets/images/cards/test_doc_connector.png"
          />

          <!--Card Image Mortgage/GLL/CRM test and Production-->
          <img
            mat-card-image
            class="card-image"
            *ngIf="setting.product.includes('prod_mortgage')"
            src="assets/images/cards/mortgage.png"
          />

          <img
            mat-card-image
            class="card-image"
            *ngIf="setting.product.includes('test_mortgage')"
            src="assets/images/cards/test_mortgage.png"
          />

          <img
            mat-card-image
            class="card-image"
            *ngIf="setting.product.includes('prod_general_ledger_accounting')"
            src="assets/images/cards/general_ledger.png"
          />

          <img
            mat-card-image
            class="card-image"
            *ngIf="setting.product.includes('test_general_ledger_accounting')"
            src="assets/images/cards/test_general_ledger.png"
          />
          <img
            mat-card-image
            class="card-image"
            *ngIf="setting.product.includes('prod_loan_maintenance')"
            src="assets/images/cards/loan_maintenance.png"
          />

          <img
            mat-card-image
            class="card-image"
            *ngIf="setting.product.includes('test_loan_maintenance')"
            src="assets/images/cards/test_loan_maintenance.png"
          />
          <img
            mat-card-image
            class="card-image"
            *ngIf="setting.product.includes('test_loan_creation')"
            src="assets/images/cards/test_loan_creation.png"
          />
          <img
            mat-card-image
            class="card-image"
            *ngIf="setting.product.includes('prod_loan_creation')"
            src="assets/images/cards/loan_creation.png"
          />
          <!-- Card Image CRM test and Production
              <img mat-card-image class="card-image" *ngIf="setting.product.includes('prod_general')"
              src="assets/images/cards/crm.png" />

            <img mat-card-image class="card-image" *ngIf="setting.product.includes('test_general')"
              src="assets/images/cards/test_crm.png" />
            -->

          <!--FINANCIAL INSTITUTION-->
          <div class="assetid-selector__info-title">
            <!-- <b class="text-styling">FINANCIAL INSTITUTION: </b>-->
          </div>
          <div
            class="assetid-selector__asset-info"
            id="FI-styling"
            [matTooltipShowDelay]="showDelay.value"
            matTooltip="{{ setting.FI.toUpperCase() }}"
          >
            {{ setting.FI }}
          </div>
          <div
            class="assetId-styling"
            [matTooltipShowDelay]="showDelay.value"
            matTooltip="GENERIC"
            *ngIf="isNXTsoftSales"
          >
            AssetID: &nbsp;{{ setting.assetId }}
          </div>

          <!--LOS to CORE-->
          <div class="los-core-container">
            <div
              *ngIf="setting.assetId.toLowerCase().substring(0, 5) != 'sales'"
              class="assetid-selector__info-title"
              [matTooltipShowDelay]="showDelay.value"
              matTooltip="{{ setting.los.toUpperCase() }}"
            >
              <b class="text-styling">SOURCE: </b
              >{{ setting.los.toUpperCase() }}
            </div>
            <div
              *ngIf="setting.assetId.toLowerCase().substring(0, 5) == 'sales'"
              class="assetid-selector__info-title"
              [matTooltipShowDelay]="showDelay.value"
              matTooltip="GENERIC"
            >
              <b class="text-styling">SOURCE: </b>GENERIC
            </div>

            <div
              *ngIf="setting.assetId.toLowerCase().substring(0, 5) != 'sales'"
              class="assetid-selector__info-title"
              [matTooltipShowDelay]="showDelay.value"
              matTooltip="{{ setting.core.toUpperCase() }}"
            >
              <b class="text-styling">TARGET: </b
              >{{ setting.core.toUpperCase() }}
            </div>

            <div
              *ngIf="setting.assetId.toLowerCase().substring(0, 5) == 'sales'"
              class="assetid-selector__info-title"
              [matTooltipShowDelay]="showDelay.value"
              matTooltip="GENERIC"
            >
              <b class="text-styling">TARGET: </b>GENERIC
            </div>
          </div>
        </mat-card>
        <div class="space"></div>
      </button>
    </ng-template>
  </div>
  <!--Spinner-->
  <div class="spinner-container" *ngIf="showSpinner">
    <div class="new-spinner">
      <div class="outer-ring"></div>
      <div class="middle-ring"></div>
      <div class="inner-ring"></div>
    </div>
  </div>
</div>
