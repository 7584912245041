import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { SpectrumService } from 'src/@ccmc/services/spectrum.service';

@Component({
  selector: 'app-update-spectrum-credentials',
  templateUrl: './update-spectrum-credentials.component.html',
  styleUrls: ['./update-spectrum-credentials.component.scss']
})
export class UpdateSpectrumCredentialsComponent implements OnInit {
  spectrumUserCredentialItem: any;
  showPassword = false;
  localSpectrumUserCredentialItem: any;
  editedFlag = false;
  isNXTsoftOperations = false;
  isNXTsoftDev = false;
  isNXTsoftSales = false;
  isAdmin = false;
  isSuperAdmin = false;
  fiservUserTable: any[] = [];
  selectedRow: any;
  assetID: any[] = [];
  constructor(
    private amplifyService: AmplifyService,
    private ccmcApiService: CcmcApiService,
    private snackBarService: SnackbarService,
    private dialogRef: MatDialogRef<UpdateSpectrumCredentialsComponent>,
    private adminApiService: AdminApiService,
    private assetService: AssetService,
    private spectrumService: SpectrumService
  ) {}

  ngOnInit(): void {
    this.localSpectrumUserCredentialItem = {
      username: this.amplifyService.username,
      cognitoID: this.amplifyService.getCognitoId(),
      tellerID: '',
      assetID: []
    };
   this.selectedRow = {
      tellerID: '',
      cognitoID: ''
    };
    this.getCurrentCredentials();
    this.isNXTsoftOperations = this.amplifyService.isNXTsoftOperations;
    this.isNXTsoftSales = this.amplifyService.isNXTsoftSales;
    this.isNXTsoftDev = this.amplifyService.isNXTsoftDev;
    this.isAdmin = this.amplifyService.isAdmin;
    this.isSuperAdmin = this.amplifyService.isSuperAdmin;
  }

  getCurrentCredentials() {
    this.adminApiService
      .getDynamoDBItem(
        'fiserv_spectrum_users',
        this.amplifyService.getCognitoId(),
        'cognitoID'
      )
      .subscribe(result => {
        console.log('Get Current Credentials result ', result);
        if (result) {
          const parsedResult = JSON.parse(JSON.stringify(result));
          this.spectrumUserCredentialItem = parsedResult;
          console.log(this.spectrumUserCredentialItem)
          this.localSpectrumUserCredentialItem = JSON.parse(
            JSON.stringify(result)
          );
          console.log(this.localSpectrumUserCredentialItem);
          if (this.localSpectrumUserCredentialItem.tellerID !== '') {
            this.editedFlag = true;
          }
        }
      });
  }

  updateCredentials() {
    const variableKeys = Object.keys(this.localSpectrumUserCredentialItem);
    console.log('Get Selected Asset ID ', this.assetService.getSelectedAssetId());
    console.log('Local Spectrum Credentials ', this.localSpectrumUserCredentialItem);
    if(variableKeys.length === 2) {
      this.assetID.push(this.assetService.getSelectedAssetId());
      this.localSpectrumUserCredentialItem.assetID = this.assetID;
    } else if(this.localSpectrumUserCredentialItem.assetID.includes(this.assetService.getSelectedAssetId())) {
      console.log('assetID already exists in array');
    } else {
      // Some old Spectrum accounts have assetID saved as a string instead of an array
      // This accounts for those accounts and converts them to arrays 
      // or just confirms assetID is an array to prevent error
      this.localSpectrumUserCredentialItem = this.spectrumService.handleAssetIDs(this.localSpectrumUserCredentialItem);
    }

    console.log('AWS document', this.localSpectrumUserCredentialItem);
    
    if (this.editedFlag) {
      this.ccmcApiService
        .createDynamoDB(
          'test',
          'fiserv_spectrum_users',
          this.localSpectrumUserCredentialItem
        )
        .subscribe(result => {
          if (result) {
            console.log(result);
            this.snackBarService.openSnackBar(
              'Updated Spectrum Credentials',
              'Okay'
            );
            this.dialogRef.close(true);
          } else {
            this.snackBarService.openSnackBar(
              'Error Updated Credentials',
              'Error'
            );
          }
        });
    }
  }

  continue() {
    if (this.editedFlag) {
      this.ccmcApiService
        .createDynamoDB(
          'test',
          'fiserv_spectrum_users',
          this.selectedRow
        )
        .subscribe(result => {
          if (result) {
            
            this.dialogRef.close(true);
          }
        });
    }
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  checkForm() {
    this.editedFlag = true;
    // if (
    //   this.isEquivalent(
    //     this.localSpectrumUserCredentialItem,
    //     this.spectrumUserCredentialItem
    //   )
    // ) {
    //   this.editedFlag = false;
    // } 
  }

  isEquivalent(a: any, b: any) {
    console.log(a);
    console.log(b);
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];
      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
        return false;
      }
    }
    return true;
  }
}
