import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeystoneAccountSearchDialogComponent } from './keystone-account-search-dialog.component';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [KeystoneAccountSearchDialogComponent],
  imports: [AppMaterialModule, CommonModule, FormsModule]
})
export class KeystoneAccountSearchDialogModule {}
