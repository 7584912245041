import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CopyTextDialogComponent } from './copy-text-dialog.component';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';

@NgModule({
    declarations: [
        CopyTextDialogComponent
    ],
    imports: [
        MatDialogModule,
        AppMaterialModule,
        MatButtonModule,
        ClipboardModule
    ],
    entryComponents: [
        CopyTextDialogComponent
    ],
})
export class CCMCCopyTextDialogModule {
}
