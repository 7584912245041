import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DocumentConnectorService } from 'src/@ccmc/services/doc-connector.service';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';

@Component({
  selector: 'app-add-global-meta-dialog',
  templateUrl: './add-global-meta-dialog.component.html',
  styleUrls: ['./add-global-meta-dialog.component.scss']
})
export class AddGlobalMetaDialogComponent implements OnInit {
  tempGlobalMeta: any;
  tempDocument: any;
  supportValuesSub: Subscription;
  globalMetas: any;
  unsubscribe: Subject<any> = new Subject();
  @ViewChild('nameInput', { static: true }) nameInputEl: ElementRef;
  @ViewChild('indexInput', { static: true }) indexInputEl: ElementRef;
  @ViewChild('valueInput', { static: true }) valueInputEl: ElementRef;
  constructor(
    private dcService: DocumentConnectorService,
    private selectedFieldService: CCMCSelectedFieldService,
    private dialogRef: MatDialogRef<AddGlobalMetaDialogComponent>,
    private fieldEditedService: FieldEditedService,
    private snackBarService: SnackbarService
  ) {}

  /**
   * Initialize the component
   *
   * @memberof CCMCAddSupportValuesDialogComponent
   */
  ngOnInit() {
    this.getData();
  }

  /**
   * Unsubscribe from observables on destroy
   *
   * @memberof CCMCAddSupportValuesDialogComponent
   */
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  /**
   * Gets data needed for the component
   *
   * @memberof CCMCAddSupportValuesDialogComponent
   */
  getData() {
    // Subscribe to the document and grab the support values
    this.supportValuesSub = this.dcService.dcDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(document => {
        if (document) {
          this.tempDocument = document;
          this.globalMetas = document.globalMetas;
          // console.log(this.supportValuesObject);
          this.tempGlobalMeta = {
            name: '',
            index: '',
            value: '',
            path: ''
          };
        }
      });
  }

  addGlobalMeta() {
    //  Check if form is acceptable
    if (this.checkForm()) {
      // If there are already global metas present
      if (this.globalMetas) {
        // Check if tempGlobalMeta is already in the global metas object array
        if (!this.containsObject(this.tempGlobalMeta, this.globalMetas)) {
          this.tempGlobalMeta.edited = true;
          // Adds tempGlobalMeta to the current array
          this.globalMetas.push(this.tempGlobalMeta);
          this.fieldEditedService.losEdited.next(true);
          // Sets globalMetas array to new value
          this.tempDocument.globalMetas = this.globalMetas;
          // update dcDocument globally
          this.dcService.setDcDocumentSimple(this.tempDocument);
          this.closeDialog();
        } else {
          this.snackBarService.openSnackBar(
            'Error: This Global Meta already exists',
            'Okay'
          );
        }
        // If there are no globalMetas we can avoid any duplicate checks
      } else {
        this.fieldEditedService.losEdited.next(true);
        this.globalMetas = [
          {
            category: this.tempGlobalMeta.category,
            source: this.tempGlobalMeta.source,
            value: this.tempGlobalMeta.value,
            path: this.tempGlobalMeta.path,
            edited: true
          }
        ];
        //  Sets global metas array to new value
        this.tempDocument.supportValues = this.globalMetas;
        // Update dcdocument globally
        this.dcService.setDcDocumentSimple(this.tempDocument);
        this.closeDialog();
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  checkForm() {
    if (this.tempGlobalMeta.name.length < 1) {
      // Open snackbar with message
      this.snackBarService.openSnackBar('Error: Name cannot be blank', 'Okay');
      // Refocus name input
      this.nameInputEl.nativeElement.focus();
      // Return false to flag button as disabled
      return false;
    } else if (this.tempGlobalMeta.index.length < 1) {
      // Open snackbar with message
      this.snackBarService.openSnackBar('Error: Index cannot be blank', 'Okay');
      // Refocus input input
      this.indexInputEl.nativeElement.focus();
      // Return false to flag button as disabled
      return false;
    } else if (this.tempGlobalMeta.value.length < 1) {
      // Open snackbar with message
      this.snackBarService.openSnackBar('Error: Value cannot be blank', 'Okay');
      // Refocus value input
      this.valueInputEl.nativeElement.focus();
      // Return false to flag button as disabled
      return false;
    }
    // Return true to flag button as enabled
    return true;
  }

  containsObject(obj: any, objList: any) {
    // Loop through object list and check if the current obj is already in the list
    for (let i = 0; i < objList.length; i++) {
      if (objList[i].name === obj.name && objList[i].index === obj.index) {
        return true;
      }
    }
    return false;
  }
}
