import { NgModule } from '@angular/core';

import { KeysPipe } from './keys.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { FilterPipe } from './filter.pipe';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { UnderscoreToSpacePipe } from './underscore-to-space.pipe';
import { UnixtoDatePipe } from './unixToDate.pipe';
import { AddSpaceAfterCommaPipe } from './addSpaceAfterComma.pipe';
import { UppercaseFirstLetterPipe } from './uppercaseFirstLetter.pipe';
import { RemoveNegativeFromString } from './removeNegativeFromString.pipe';
import { RemoveCommas } from './removeCommas.pipe';
import { TimezoneConversionPipe } from './timezoneConversion.pipe';

@NgModule({
  declarations: [
    KeysPipe,
    GetByIdPipe,
    HtmlToPlaintextPipe,
    FilterPipe,
    CamelCaseToDashPipe,
    UnderscoreToSpacePipe,
    UnixtoDatePipe,
    AddSpaceAfterCommaPipe,
    UppercaseFirstLetterPipe,
    RemoveNegativeFromString,
    RemoveCommas,
    TimezoneConversionPipe
  ],
  imports: [],
  exports: [
    KeysPipe,
    GetByIdPipe,
    HtmlToPlaintextPipe,
    FilterPipe,
    CamelCaseToDashPipe,
    UnderscoreToSpacePipe,
    UnixtoDatePipe,
    AddSpaceAfterCommaPipe,
    UppercaseFirstLetterPipe,
    RemoveNegativeFromString,
    RemoveCommas,
    TimezoneConversionPipe
  ]
})
export class CCMCPipesModule {}
