import { Component, HostBinding, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CcmcApiService } from '../../../../services/ccmc-api.service';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'ccmc-nav-vertical-item',
  templateUrl: './nav-vertical-item.component.html',
  styleUrls: ['./nav-vertical-item.component.scss']
})
export class CCMCNavVerticalItemComponent {
  @HostBinding('class') classes = 'nav-item';
  @Input() item: any;

  constructor(
    public ccmcApiService: CcmcApiService,
    public router: Router,
    public clipboardModule: ClipboardModule,
    public matButtonModule: MatButtonModule
  ) {}
}
