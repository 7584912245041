import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { CustomErrorHandlerService } from '../../../../@ccmc/services/custom-error-handler.service';
import { Amplify, S3 } from 'aws-sdk';
import { APPCONSTANTS } from '../../../app.constants';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { environment } from'src/environments/environment';
const { Buffer } = require('buffer');

@Injectable({
  providedIn: 'root'
})
export class LoanFilesService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  acceptedFileList = [
    'bat',
    'bin',
    'csv',
    'dat',
    'dll',
    'doc',
    'docx',
    'if3',
    'jpeg',
    'jpg',
    'json',
    'key',
    'log',
    'lst',
    'mdb',
    'msg',
    'pdb',
    'pdf',
    'png',
    'reg',
    'sql',
    'svclog',
    'trc',
    'txt',
    'xls',
    'xlsx',
    'xml',
    'zip'
  ];

  constructor(
    private http: HttpClient,
    private customErrorHandlerService: CustomErrorHandlerService,
    private ccmcApiService: CcmcApiService,
    private amplifyService: AmplifyService
  ) {
    this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, PUT'
    );
    this.httpOptions.headers.append('Accept', 'application/json');
  }

  s3ListFiles(assetID: any, bucket: any) {
    const body = JSON.stringify({
      bucket: bucket,
      assetID: assetID
    });
    
    return this.http
      .post(`${Buffer.from(environment.environmentURL, "base64").toString()}/s3/v2/list-loans`, body, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  s3GetFile(params: any) {
    const body = JSON.stringify({
      bucket: params.bucket,
      key: params.key
    });
    
    return this.http
      .post(`${Buffer.from(environment.environmentURL, "base64").toString()}/s3/get-file`, body, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  
  s3DeleteFile(bucket: any, key: any, reasonForDelete: any) {
    const body = JSON.stringify({
      bucket: bucket,
      key: key,
      reason: reasonForDelete,
      user: this.amplifyService.username
    });
    
    return this.http
      .post(`${Buffer.from(environment.environmentURL, "base64").toString()}/s3/v2/delete-file`, body, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  uploadS3File(params: any) {
    console.log('upload file param', params);
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/s3/v2/upload-file`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }
}
