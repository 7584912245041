import { Clipboard, ClipboardModule } from '@angular/cdk/clipboard';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { fromEvent, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil
} from 'rxjs/operators';
import { AddNewSndMetaComponent } from 'src/@ccmc/components/add-new-snd-meta/add-new-snd-meta.component';
import { AddNewSourceDialogComponent } from 'src/@ccmc/components/add-new-source-dialog/add-new-source-dialog.component';
import { CCMCConfirmDialogComponent } from 'src/@ccmc/components/confirm-dialog/confirm-dialog.component';
import { EditSndGlobalMetasDialogComponent } from 'src/@ccmc/components/edit-snd-global-metas-dialog/edit-snd-global-metas-dialog.component';
import { EditSourceDialogComponent } from 'src/@ccmc/components/edit-source-dialog/edit-source-dialog.component';
import { SuccessDialogComponent } from 'src/@ccmc/components/success-dialog/success-dialog.component';
import { DocumentConnectorService } from 'src/@ccmc/services/doc-connector.service';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';

@Component({
  selector: 'app-sources',
  templateUrl: './sources.component.html',
  styleUrls: ['./sources.component.scss']
})
export class SourcesComponent implements OnInit {
  dcDocument: any;
  private selectedDestinationSub: Subscription;
  selectedDestination: any;
  localSelectedDestination: any;
  selectedMeta: any;
  selectedSource: any = [];
  sources: any;
  metas: any;
  metasDisplayedColumns = ['name', 'index', 'value', 'path', 'edit'];
  sourcesDisplayedColumns = ['source', 'remove'];
  @ViewChild('metafilterSearch', { static: true })
  metaFilterSearchEl: ElementRef;
  @ViewChild('sourcefilterSearch', { static: true })
  sourceFilterSearchEl: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  unsubscribe: Subject<any> = new Subject();
  private dcSub: Subscription;
  sourcesDataSource: any;
  metasDataSource: any;
  editedFlag = false;
  sourceSearchValue = '';
  filteredSources: any;
  constructor(
    private selectedFieldService: CCMCSelectedFieldService,
    private fieldEditedService: FieldEditedService,
    private dcService: DocumentConnectorService,
    private router: Router,
    private dialog: MatDialog,
    public clipboard: Clipboard,
    public clipboardModule: ClipboardModule
  ) {}

  ngOnInit(): void {
    this.getData();
    // Init filter search
    this.initMetaFilterSearch();
  }

  initMetaFilterSearch() {
    // Auto Focus filter search item
    this.metaFilterSearchEl.nativeElement.focus();
    fromEvent(this.metaFilterSearchEl.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // Time in milliseconds between key events
        debounceTime(1000),
        // If previous query is diffent from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((text: string) => {
        this.applyFilterOnMetas(text);
      });
  }

  applyFilterOnMetas(filterValue: string) {
    // Trim the data
    this.metasDataSource.filter = filterValue.trim().toLowerCase();
    // If there is data
    if (this.metasDataSource.filteredData[0]) {
      // Select the first instance
      this.onSelectMeta(this.metasDataSource.filteredData[0]);
    }
  }

  applyFilterOnSources(filterValue: string) {
    if (filterValue === '') {
      this.filteredSources = JSON.parse(
        JSON.stringify(this.selectedDestination.sources)
      );
    } else {
      this.filteredSources = JSON.parse(
        JSON.stringify(
          this.selectedDestination.sources.filter((o: any) => {
            return o.toLowerCase().includes(filterValue.toLowerCase());
          })
        )
      );
    }
    this.sourcesDataSource = new MatTableDataSource(this.filteredSources);
  }

  getData() {
    this.dcSub = this.dcService.dcDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dcDocument => {
        if (dcDocument) {
          console.log('Doc Connector Document', dcDocument);
          this.dcDocument = dcDocument;
        }
      });
    this.selectedDestinationSub =
      this.selectedFieldService.onDestinationFieldSelected
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(selected => {
          console.log('Selected Destination', selected);
          this.selectedDestination = selected;
          if (this.selectedDestination) {
            this.localSelectedDestination = JSON.parse(
              JSON.stringify(this.selectedDestination)
            );
          } else {
            this.localSelectedDestination = this.selectedDestination;
          }
          if (this.selectedDestination && this.selectedDestination.metas) {
            this.metas = this.selectedDestination.metas;
            this.metasDataSource = new MatTableDataSource(
              this.selectedDestination.metas
            );
          } else {
            this.metasDataSource = new MatTableDataSource([]);
          }
          if (this.selectedDestination && this.selectedDestination.sources) {
            this.sources = this.selectedDestination.sources;
            this.sourcesDataSource = new MatTableDataSource(
              this.selectedDestination.sources
            );
          } else {
            this.sourcesDataSource = new MatTableDataSource([]);
          }
        });
  }

  saveDestinationConfiguration() {
    // Set core edited to true
    this.fieldEditedService.losEdited.next(true);
    // update the selected support value
    this.selectedDestination.id = this.localSelectedDestination.id;
    this.selectedDestination.joinImages =
      this.localSelectedDestination.joinImages;
    this.selectedDestination.separatePages =
      this.localSelectedDestination.separatePages;
    this.selectedDestination.orderIndex =
      this.localSelectedDestination.orderIndex;
    this.selectedDestination.newFileName =
      this.localSelectedDestination.newFileName;
    this.selectedDestination.newFileType =
      this.localSelectedDestination.newFileType;
      this.selectedDestination.exportJob =
      this.localSelectedDestination.exportJob;

    console.log(this.selectedDestination);
    // Set support value edited flag to true
    this.selectedDestination.edited = true;
    // Set edited flag to false
    this.editedFlag = false;
    // Set FieldEdited to false
    this.fieldEditedService.fieldEdited.next(false);
  }

  onSelectMeta(meta: any) {
    console.log(meta);
    this.selectedMeta = meta;
  }

  multiSelectSources(selected: any, event: any) {
    if (event.shiftKey) {
      // Selection while holding shift
      let previousSelection = this.selectedSource[this.selectedSource.length - 1];
      let currentSelection = selected;
      let beginningOfSelection: any;
      let endOfSelection: any;
      let counter = 0;

      // loops through the dataSource array to find the top most selection and the bottom
      for (let field of this.sourcesDataSource.filteredData) {
        if (field === previousSelection || field === currentSelection) {
          if (beginningOfSelection === null || beginningOfSelection === undefined) {
            beginningOfSelection = counter;
          } else {
            endOfSelection = counter;
            break;
          }
        }
        counter++;
      }

      // fills selectedSource array with all the fields to highlight
      for (let index = beginningOfSelection; index <= endOfSelection; index++) {
        if (!this.selectedSource.includes(this.sourcesDataSource.filteredData[index])) {
          this.selectedSource.push(this.sourcesDataSource.filteredData[index]);
        }
      }
    } else if (event.ctrlKey) {
      // Selection while holding ctrl
      this.selectedSource.push(selected);
    } else {
      // Regular selection
      this.selectedSource = [];
      this.selectedSource.push(selected);
    }
  }

  addNewSource() {
    const addNewSourceDialogRef = this.dialog.open(
      AddNewSourceDialogComponent,
      {}
    );
    addNewSourceDialogRef.afterClosed().subscribe(data => {
      if (data) {
        const successDialogRef = this.dialog.open(SuccessDialogComponent, {
          data: {
            title: 'Success',
            message: `Successfully added new source item`
          }
        });
      }
    });
  }

  removeSource() {
    // Confirm with user to remove the selected sources
    const confirmDialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
      data: 'Are you sure you want to remove the selected sources?'
    });
    confirmDialogRef.afterClosed().subscribe(data => {
      if (data) {
        // Set core edited to true
        this.fieldEditedService.losEdited.next(true);
        for (let sourceItem of this.selectedSource) {
          // Remove the selected support value
          for (let i = 0; i < this.sources.length; i++) {
            if (this.sources[i] === sourceItem) {
              this.sources.splice(i, 1);
            }
          }
          this.selectedDestination.sources = this.sources;
          // Set document
          this.selectedFieldService.onDestinationFieldSelected.next(
            this.selectedDestination
          );
          this.selectedDestination.edited = true;
        }
        const successDialogRef = this.dialog.open(SuccessDialogComponent, {
          data: {
            title: 'Success',
            message: `Successfully removed selected sources from the list`
          }
        });
      }
    });
  }

  copySource(selection: any) {
    this.clipboard.copy(selection);
  }

  destinationFieldEdited() {
    // Set Edited Flag true
    this.editedFlag = true;
    // Set fieldEdited to true
    this.fieldEditedService.fieldEdited.next(true);
    // Checks to see if modified local selected variable is the same as the current fields
    if (
      this.isEquivalent(this.selectedDestination, this.localSelectedDestination)
    ) {
      this.editedFlag = false;
      this.fieldEditedService.fieldEdited.next(false);
    }
  }

  createExportJobField() {
    if (this.localSelectedDestination.exportJob === undefined) {
        this.localSelectedDestination.exportJob = true;
    } 
  }

  isEquivalent(a: any, b: any) {
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      // ignores the edited property since those values will not be the same
      if (aProps[i] !== 'edited') {
        const propName = aProps[i];

        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
          return false;
        }
      }
    }
    return true;
  }

  editGlobalMeta(globalMeta: any) {
    const editSNDGlobalMetaRef = this.dialog.open(
      EditSndGlobalMetasDialogComponent,
      {
        data: {
          globalMeta,
          destination: this.selectedDestination
        },
        disableClose: true
      }
    );
    editSNDGlobalMetaRef.afterClosed().subscribe(data => {
      if (data === 'removed') {
        // reload table data
        this.metasDataSource = new MatTableDataSource(
          this.selectedDestination.metas
        );
        const successDialogRef = this.dialog.open(SuccessDialogComponent, {
          data: {
            title: 'Success',
            message: 'Successfully removed Meta'
          }
        });
      } else if (data === 'edited') {
        const successDialogRef = this.dialog.open(SuccessDialogComponent, {
          data: {
            title: 'Success',
            message: 'Successfully edited the Meta'
          }
        });
      }
    });
  }

  addNewGlobalMeta() {
    const addNewSNDMetaDialog = this.dialog.open(AddNewSndMetaComponent, {
      data: this.selectedDestination,
      disableClose: true
    });
    addNewSNDMetaDialog.afterClosed().subscribe(data => {
      if (data) {
        const successDialogRef = this.dialog.open(SuccessDialogComponent, {
          data: {
            title: 'Success',
            message: 'Successfully added new meta.'
          }
        });
        this.metasDataSource.data = this.selectedDestination.metas;
      }
    });
  }

  openEditSourceDialog(selected: any) {
    const editSourceDialogRef = this.dialog.open(EditSourceDialogComponent, {
      data: selected
    });
    editSourceDialogRef.afterClosed().subscribe(data => {
      console.log('Data from edit', data);
      console.log('Selected Source', selected);
      if (data) {
        console.log(this.sources);
        let sourceIndex = this.sources.findIndex(
          (source: any) => selected === source
        );
        this.sources[sourceIndex] = data;
        this.sourcesDataSource = new MatTableDataSource(this.sources);
        this.selectedDestination.edited = true;
        this.fieldEditedService.losEdited.next(true);
        console.log(this.selectedDestination);
      }
    });
  }
}
