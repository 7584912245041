import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CCMCGCSettingsComponent } from './gc-settings.component';
import { Routes, RouterModule } from '@angular/router';
import { CCMCSharedModule } from '../../../../@ccmc/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from '../../../auth/auth.guard';

const routes: Routes = [
  {
    path: 'gc-settings',
    component: CCMCGCSettingsComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    CCMCSharedModule,
    ReactiveFormsModule
  ],
  declarations: [CCMCGCSettingsComponent],
  exports: [CCMCGCSettingsComponent]
})
export class CCMCGCSettingsModule {}
