import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PremierSearchValueComponent } from './premier-search-value.component';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [PremierSearchValueComponent],
  imports: [AppMaterialModule, CommonModule, FormsModule, ReactiveFormsModule],
  entryComponents: [PremierSearchValueComponent]
})
export class PremierSearchValueModule {}
