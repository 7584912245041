<!--full dialogbox-->
<div class="search">
    <div class="help-btn">
        <!--New Help Button with Tutorial carousel-->

        <!-- 
    <button  class="help-help-btn" mat-raised-button >
      <mat-icon style="color:white; padding-left: 6px;">help</mat-icon> &nbsp; Help </button>
  --></div>
    <!--top table-->
    <mat-table #searchSort="matSort" class="search__table" #searchTable [dataSource]="searchDataSource" matSort id="search-table">
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="cell-text-styling">Name</mat-header-cell>
            <mat-cell *matCellDef="let element">
                <div *ngIf="element.businessName !== ''">
                    {{ element.businessName }}
                </div>
                <div *ngIf="element.businessName === ''">
                    {{ element.name }}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="taxID">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="cell-text-styling">TIN</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.taxID }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="CIF">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="cell-text-styling">CIF</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.CIF }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="searchDisplayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: searchDisplayedColumns" [style.background]="selectedRow === row ? '#3FB2C6' : ''" (click)="select(row)"></mat-row>
    </mat-table>

    <!--center row of buttons-->
    <!--Search Button-->
    <div class="search__container">
        <div class="search__container--buttons">
            <button mat-raised-button [ngClass]="{ disabled: searchDisabled }" [disabled]="searchDisabled" (click)="manualSearch()" class="search-button">
        <mat-icon class="icon-style">person</mat-icon>
        <span class="button-text">&nbsp;Search</span>
      </button>

            <!--Select Button-->
            <button mat-raised-button [ngClass]="{ disabled: !selectedCif }" [disabled]="!selectedCif" (click)="populateCif()" class="select-button">
        <mat-icon class="icon-style">touch_app</mat-icon
        ><span class="button-text">&nbsp;Select</span>
      </button>

            <!--New Button-->
            <button mat-raised-button [ngClass]="{ disabled: newBtnDisabled}" [disabled]="!selectedRow || newBtnDisabled" (click)="setCifNew()" class="new-button" matTooltip="This will create a New customer information file (CIF)." matTooltipClass="tooltip-color">
        <mat-icon class="icon-style">add_circle_outline</mat-icon>
        <span class="button-text">&nbsp;New</span>
      </button>
        </div>
    </div>

    <!--bottom table-->
    <mat-table #resultsSort="matSort" class="search__table" #resultsTable [dataSource]="resultsDataSource" matSort id="bottom-table">
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="cell-text-styling">Name</mat-header-cell>
            <mat-cell *matCellDef="let element">
                <div *ngIf="element.businessName && element.businessName !== ''">
                    {{ element.businessName }}
                </div>
                <div *ngIf="!element.businessName || element.businessName === ''">
                    {{ element.name }}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="firstName">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="cell-text-styling">First</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.firstName }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="taxID">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="cell-text-styling">TIN</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.taxID }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="CIF">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="cell-text-styling">CIF</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.CIF }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="resultsDisplayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: resultsDisplayedColumns" [style.background]="selectedCif === row ? '#3FB2C6' : ''" (click)="selectCif(row)"></mat-row>
    </mat-table>

    <!--botton row of buttons-->
    <div class="continue-btn" id="bottom-button-container">
        <!--Cancel Button-->
        <button mat-stroked-button class="cancel-btn" (click)="closeDialog()" id="cancel-button">
      Cancel
    </button>

        <!--Continue Button-->
        <button mat-raised-button class="continue-btn" [ngClass]="{ disabled: !checkContinue() }" [disabled]="!checkContinue()" (click)="continue()" id="continue-button">
      <span class="button-text">Continue &nbsp;</span>
      <mat-icon class="continue-icon">forward </mat-icon>
    </button>
    </div>

    <div class="spinner-container" *ngIf="showSpinner">
        <div class="new-spinner">
            <div class="outer-ring"></div>
            <div class="middle-ring"></div>
            <div class="inner-ring"></div>
        </div>


    </div>
</div>