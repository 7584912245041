import {
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
  NgForm,
  FormGroupDirective,
  FormControl
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export const passwordMatchValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const newPassword = control.get('newPassword');
  const confirmPassword = control.get('confNewPassword');

  return newPassword &&
    confirmPassword &&
    newPassword.value !== confirmPassword.value
    ? { passwordMatch: true }
    : null;
};

export class ConfirmValidParentMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    return control!.parent!.invalid && control!.touched;
  }
}

export const errorMessages: { [key: string]: string } = {
  confNewPassword: 'passwords do not match'
};
