import { Injectable } from '@angular/core';
import { CcmcApiService } from './ccmc-api.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { catchError } from 'rxjs/operators';
import { AssetService } from './asset.service';
import { APPCONSTANTS } from 'src/app/app.constants';
import { AdminApiService } from './admin-api.service';
import { MatDialog } from '@angular/material/dialog';
import { DocConnectorHistoryResponseDialogComponent } from '../components/doc-connector-history-response-dialog/doc-connector-history-response-dialog.component';
import { SpinnerService } from './spinner.service';
import { Router } from '@angular/router';
import { AmplifyService } from './amplify.service';
const { Buffer } = require('buffer');
@Injectable({
  providedIn: 'root'
})
export class MCPService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  currentLoanKey: string;
  encompassAccessToken: string;
  constructor(
    private ccmcApiService: CcmcApiService,
    private http: HttpClient,
    private customErrorHandlerService: CustomErrorHandlerService,
    private assetService: AssetService,
    private dialog: MatDialog,
    private spinnerService: SpinnerService,
    private router: Router,
    private amplifyService: AmplifyService
  ) {
    this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, PUT'
    );
    this.httpOptions.headers.append('Accept', 'application/json');
  }

  /**
   * Set Access Token
   * @description Sets the access token for mcp environment
   * @param {*} token
   * @memberof MCPService
   */
  setAccessToken(token: any) {
    this.encompassAccessToken = token;
  }
  /**
   * Loan Lookup
   * @description looks up a loan in the mcp communicator
   * @param {*} params
   * @returns
   * @memberof MCPService
   */
  loanLookUp(params: any) {
    params.assetID = this.assetService.getSelectedAssetId();
    if (this.encompassAccessToken) {
      params.accessToken = this.encompassAccessToken;
    }

    let url: any;
    if (params.env === 'Client') {
      url = 'aHR0cHM6Ly9hcGkubnh0c29mdC5jb20vdjE=';
    } else if (params.env === 'Nxtsoft') {
      url = 'aHR0cHM6Ly9hcGktZGV2Lm54dHNvZnQuY29tL3Yy';
    } else {
      url = environment.environmentURL;
    }

    this.amplifyService.environmentOverride = true;

      return this.http
      .post(`${Buffer.from(url, "base64").toString()}/mcp/loan-search`, params, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  
  }

  /**
   * Get Loan
   * @description Retrieves selected loan from the encompass communicator
   * @param {*} params
   * @returns
   * @memberof MCPService
   */
  getLoan(params: any) {
    params.assetID = this.assetService.getSelectedAssetId();
    if (this.encompassAccessToken) {
      params.accessToken = this.encompassAccessToken;
    }
    return this.http
      .post(`${Buffer.from(environment.environmentURL, "base64").toString()}/mcp/get-loan`, params, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * Loan Booked
   * @description Defines what is done to the LOS after the loan has been booked
   * This is a required function, leave empty if nothing is required for the LOS
   * @param {*} result
   * @memberof MCPService
   */
  loanBooked(result: any, configurations: any) {
    console.log('configurations', configurations);
    if (!configurations.demoMode && !configurations.implementationMode) {
      // Record Sender
      this.ccmcApiService.recordSender().subscribe(result => {
        console.log(result);
      });
    }
  }
}