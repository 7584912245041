<div class="set-configuration-translator-container">
  <div style="overflow-y: scroll; max-height: 444px; background: white">
    <table
      mat-table
      matSort
      #table
      [dataSource]="columnsDataSource"
      CCMCPerfectScrollbar
      style="padding-bottom: 55px"
    >
      <ng-container matColumnDef="columnName">
        <th mat-header-cell *matHeaderCellDef>Column Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element.columnName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="columnID">
        <th mat-header-cell *matHeaderCellDef>ColumnID</th>
        <td mat-cell *matCellDef="let element">
          {{ element.columnID }}
        </td>
      </ng-container>

      <ng-container matColumnDef="vbScript">
        <th mat-header-cell *matHeaderCellDef>vbScript</th>
        <td mat-cell *matCellDef="let element">
          {{ element.vbScript }}
        </td>
      </ng-container>

      <ng-container matColumnDef="editColumn">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <mat-icon
            style="cursor: pointer"
            (click)="openEditColumnDialog(element)"
            >edit</mat-icon
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="edited">
        <th class="editHeader" mat-header-cell *matHeaderCellDef>Edited</th>

        <td mat-cell *matCellDef="let element" class="noselect">
          <mat-icon *ngIf="element.edited === true"> star</mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="onSelectColumn(row)"
        [ngClass]="{ 'accent-50-bg': row == selectedColumn }"
      ></tr>
    </table>
  </div>
  <div style="display: grid; grid-template-rows: 50px auto">
    <div
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
      "
    >
      <div class="selectOptions-title">Select Options</div>
      <div>
        <button
          mat-raised-button
          matTooltip="Saves any changes you make, within this section."
          matTooltipClass="toolTipStyle"
          color="accent"
          (click)="save()"
          [disabled]="!editedFlag"
          class="saveBtn-column"
          style="margin-right: 13px"
        >
          Save
        </button>
      </div>
    </div>
    <div
      class="CodeMirrorColumnContainer formContainer6"
      style="margin-bottom: 50px"
    >
      <ngx-codemirror
        *ngIf="localSelectedColumn"
        #codemirror
        [(ngModel)]="localSelectedColumn.vbScript"
        (ngModelChange)="fieldEdited($event)"
        [options]="codemirrorConfig"
        (keydown.control.s)="save()"
        style="padding-bottom: 50px"
      >
      </ngx-codemirror>
      <ngx-codemirror
        *ngIf="!localSelectedColumn"
        #codemirror
        [options]="codemirrorConfig"
        (keydown.control.s)="save()"
        style="padding-bottom: 50px"
      >
      </ngx-codemirror>
    </div>
  </div>
</div>
