<div class="confirm-dialog">
  <div mat-dialog-content class="confirm-dialog-message">
    {{ data.message }}
  </div>
  <div class="alignC">
    <span class="pad25">Total: {{ useTotal }}</span>
    <span class="pad25">Credit: {{ useCredit }}</span>
    <span>Debit: {{ useDebit }}</span>
  </div>
  <div class="alignR">
    <mat-checkbox [(ngModel)]="checkedOff">Send out of balance</mat-checkbox>
  </div>
  <div mat-dialog-actions class="pt-30" class="confirm-dialogBtn-Container">
    <button
      class="cancelBtn"
      mat-stroked-button
      (click)="closeDialog(false)"
    >
      Close
    </button>
    <button
      [disabled]="checkIfDisabled()"
      mat-raised-button
      class="mat-accent mr-16"
      (click)="closeDialog(true)"
    >
      Confirm
    </button>
  </div>
</div>
