import { Component, HostBinding, HostListener, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { CCMCAnimations } from '../../../../animations/index';
import { CCMCConfigService } from '../../../../services/config.service';
import { Config } from '../../../../models/config.model';

@Component({
    selector   : 'ccmc-nav-horizontal-collapse',
    templateUrl: './nav-horizontal-collapse.component.html',
    styleUrls  : ['./nav-horizontal-collapse.component.scss'],
    animations : CCMCAnimations
})
export class CCMCNavHorizontalCollapseComponent implements OnDestroy {
    onConfigChanged: Subscription;
    ccmcSettings: Config;
    isOpen = false;

    @HostBinding('class') classes = 'nav-item nav-collapse';
    @Input() item: any;

    @HostListener('mouseenter')
    open() {
        this.isOpen = true;
    }

    @HostListener('mouseleave')
    close() {
        this.isOpen = false;
    }

    constructor(
        private ccmcConfig: CCMCConfigService
    ) {
        this.onConfigChanged =
            this.ccmcConfig.onConfigChanged
                .subscribe(
                    (newSettings: Config) => {
                        this.ccmcSettings = newSettings;
                    }
                );
    }

    ngOnDestroy() {
        if (this.onConfigChanged) {
            this.onConfigChanged.unsubscribe();
        }
    }
}
