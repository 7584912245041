import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Collateral } from 'src/@ccmc/models/collateral.model';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { ConditionsService } from 'src/@ccmc/services/conditions.service';
import { HorizonXchangeService } from 'src/@ccmc/services/horizon-xchange.service';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
import { CCMCCollateralDetailsDialogComponent } from '../collateral-search-dialog/collateral-details-dialog/collateral-details-dialog.component';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { CollateralDetailDialogComponent } from './collateral-detail-dialog/collateral-detail-dialog.component';
import { PledgeValueDialogComponent } from './pledge-value-dialog/pledge-value-dialog.component';

@Component({
  selector: 'app-horizon-xchange-collateral-search-dialog',
  templateUrl: './horizon-xchange-collateral-search-dialog.component.html',
  styleUrls: ['./horizon-xchange-collateral-search-dialog.component.scss']
})
export class HorizonXchangeCollateralSearchDialogComponent implements OnInit {
  searchPartyNameData: any[] = [];
  collateralSearchDataSource: any;
  collateralAssignmentDataSource: any;
  @ViewChild('bottomTable') table: MatTable<Collateral>;
  collateralSubscription: any;
  topTableColumns = ['name', 'type', 'status', 'assignedParty', 'details'];
  bottomTableColumns = [
    'name',
    'type',
    'status',
    'assignedParty',
    'dollarOfValue',
    'percentOfValue',
    'sendOrder'
  ];
  @ViewChild('collateralSearchSort')
  collateralSearchSort: MatSort;
  selectedSearchCollateral: Array<any> = [];
  selectedAssignCollateral: Array<any> = [];
  assignPartyDisabled = true;
  continueDisabled = true;
  collateral: any[] = [];
  parties: any[] = [];
  showSpinner: boolean; // flag for the mat-spinner
  checkContinueDisabledBoolean = true;
  canOpenPledge: any;
  private spinnerSub: Subscription;
  constructor(
    private dialogRef: MatDialogRef<HorizonXchangeCollateralSearchDialogComponent>,
    public ccmcApiService: CcmcApiService,
    private horizonXchangeService: HorizonXchangeService,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private spinnerService: SpinnerService,
    private zone: NgZone,
    private assetService: AssetService,
    private conditionsService: ConditionsService,
    private amplifyService: AmplifyService
  ) {}

  ngOnInit(): void {
    // Init collateral to an empty array
    this.collateral = [];
    // Grabs the CIF for each borrower on the loan
    // this.partyCIFs();
    this.getParty();
    // Uses the collateral Search object to grab the collateral fields from the loan
    this.currentCollateral();
    // init sort
    // this.collateralSearchDataSource.sort = this.collateralSearchSort;
    // this.collateralAssignmentDataSource.sort = this.collateralAssignmentSort;
    this.spinnerSub = this.spinnerService.spinner.subscribe(spinner => {
      this.showSpinner = spinner;
    });
    if (this.parties[0]) {
      let searchValue = `${this.parties[0].CIF}`;
      this.searchExisting(searchValue);
    }
  }

  getParty() {
    this.parties = [];
    for (let i = 0; i < this.ccmcApiService.search.customerSearch.length; i++) {
      let borrower: any = {};
      // Set first name index
      const fnIndex = this.ccmcApiService.mapping.findIndex(
        (f: any) =>
          f.fieldID === this.ccmcApiService.search.customerSearch[i].firstName
      );
      // Set middle name index
      const mnIndex = this.ccmcApiService.mapping.findIndex(
        (f: any) =>
          f.fieldID === this.ccmcApiService.search.customerSearch[i].middleName
      );
      // Set last name index
      const lnIndex = this.ccmcApiService.mapping.findIndex(
        (f: any) => f.fieldID === this.ccmcApiService.search.customerSearch[i].lastName
      );
      // Set taxID index
      const taxIndex = this.ccmcApiService.mapping.findIndex(
        (f: any) => f.fieldID === this.ccmcApiService.search.customerSearch[i].taxID
      );
      // Set Business Name Index
      const bnIndex = this.ccmcApiService.mapping.findIndex(
        (f: any) =>
          f.fieldID ===
          this.ccmcApiService.search.customerSearch[i].businessName
      );
      // Set cif index
      const cifIndex = this.ccmcApiService.mapping.findIndex(
        (f: any) => f.fieldID === this.ccmcApiService.search.customerSearch[i].CIF
      );
      console.log(fnIndex);
      console.log(mnIndex);
      console.log(lnIndex);
      console.log(taxIndex);
      // console.log(bnIndex);
      console.log(cifIndex);
      console.log(this.ccmcApiService.mapping[fnIndex]);
      if (this.ccmcApiService.mapping[taxIndex] !== undefined) {
        // Set borrower
        borrower = {
          firstName:
            fnIndex > -1 ? this.ccmcApiService.mapping[fnIndex].fieldValue : '',
          middleName:
            mnIndex > -1 ? this.ccmcApiService.mapping[mnIndex].fieldValue : '',
          lastName:
            lnIndex > -1 ? this.ccmcApiService.mapping[lnIndex].fieldValue : '',
          taxID: this.ccmcApiService.mapping[taxIndex].fieldValue,
          businessName:
            bnIndex > -1 ? this.ccmcApiService.mapping[bnIndex].fieldValue : '',
          CIF:
            cifIndex > -1
              ? this.ccmcApiService.mapping[cifIndex].fieldValue
              : '',
          CIFFields: this.ccmcApiService.search.customerSearch[i].CIFFields,
          FNFields: this.ccmcApiService.search.customerSearch[i].firstName,
          isNew: this.ccmcApiService.search.customerSearch[i].isNew
        };
        console.log(borrower);
      }
      if (borrower.CIF === 'NEW') {
        borrower.CIF = `NEW - ${i + 1}`;
      }
      console.log('borrower: ', borrower);
      // If the borrower has a taxID push it to the party
      if (borrower.taxID !== '' && JSON.stringify(borrower) !== '{}') {
        this.parties.push(borrower);
      }
    }
  }

  partyCIFs() {
    // let nextBorrower = true;
    let borrowerCount = 1;
    this.parties = [];
    // temp hard coded count, since the cifs are not in sequence order in the mapping
    while (borrowerCount < 27) {
      let CIFField = '';
      if (borrowerCount < 10) {
        CIFField = this.ccmcApiService.search.collateralSearch.CIF.replace(
          '*',
          borrowerCount
        );
      } else {
        CIFField = this.ccmcApiService.search.collateralSearch.CIF.replace(
          '0*',
          borrowerCount
        );
      }
      const borrowerCIFIndex = this.ccmcApiService.mapping.findIndex(
        (obj: any) => obj.fieldID === CIFField
      );
      // If index is exists
      if (borrowerCIFIndex > -1) {
        // grab the CIF
        const CIF = this.ccmcApiService.mapping[borrowerCIFIndex].fieldValue;
        // If CIF is not empty push it to parties
        if (CIF !== '') {
          this.parties.push(CIF);
        } else {
          console.log(CIF);
        }
      } else {
        console.log(CIFField);
        // nextBorrower = false;
      }
      borrowerCount++;
    }
  }

  currentCollateral() {
        // init nextCollateral to true
    let nextCollateral = true;
    // init flag count to 1
    let collateralCount = 1;
    while (nextCollateral && collateralCount < 6) {
            // Replace * with count
      let collateralFields =
        this.ccmcApiService.search.collateralSearch.collateralFields.replace(
          /\*/g,
          collateralCount
        );
      // split collateral fields into array
      collateralFields = collateralFields.split(', ');
      console.log('collateral fields', collateralFields);
      // Replace * with collateral count
      const typeField =
        this.ccmcApiService.search.collateralSearch.type.replace(
          '*',
          collateralCount
        );
      console.log('typefield', typeField);
      // init collateralGroup
      const collateralGroup: any = {};

      collateralGroup.fields = this.ccmcApiService.mapping.filter((obj: any) => {
        return (
          obj.fieldID.includes(collateralFields[0]) ||
          obj.fieldID.includes(collateralFields[1])
        );
      });
      console.log(collateralGroup);
      // update collateralGroup name
      collateralGroup.name = `CollAdd~0${this.collateral.length + 1}`;
      // get index of typeField
      const typeIndex = collateralGroup.fields.findIndex(
        (obj: any) => obj.fieldID === typeField
      );
      console.log(typeIndex);
      // set type for collateralGroup
      console.log(collateralGroup);
      collateralGroup.type = collateralGroup.fields[typeIndex].fieldValue;

      // Set status for collateralGroup
      collateralGroup.status = 'NEW';
      collateralGroup.key =
        this.ccmcApiService.search.collateralSearch.key.replace(
          '*',
          collateralCount
        );
      console.log(collateralGroup.key);
      // get keyIndex
      const keyIndex = collateralGroup.fields.findIndex(
        (obj: any) =>
          obj.fieldID ===
          this.ccmcApiService.search.collateralSearch.key.replace(
            '*',
            collateralCount
          )
      );
      console.log(keyIndex);

      // set assigned party
      let rmsKeyFieldID = 'CT0*_0004';
      const rmsKeyIndex = collateralGroup.fields.findIndex(
        (obj: any) => obj.fieldID === rmsKeyFieldID.replace('*', `${collateralCount}`)
      );
      if (rmsKeyIndex > -1) {
        collateralGroup.assignedParty =
          collateralGroup.fields[rmsKeyIndex].fieldValue;
      } else {
        collateralGroup.assignedParty = '';
      }

      const dollarOfValueIndex = collateralGroup.fields.findIndex(
        (obj: any) =>
          obj.fieldID ===
          this.ccmcApiService.search.collateralSearch.pledgeFixedField.replace(
            '*',
            collateralCount
          )
      );
      console.log(dollarOfValueIndex);
            if (dollarOfValueIndex > -1) {
        // Set percent of value
        collateralGroup.dollarOfValue =
          collateralGroup.fields[dollarOfValueIndex].fieldValue;
      }
            const percentOfValueIndex = collateralGroup.fields.findIndex(
        (obj: any) =>
          obj.fieldID ===
          this.ccmcApiService.search.collateralSearch.pledgePercentField.replace(
            '*',
            collateralCount
          )
      );
      console.log(percentOfValueIndex);
      // Set assigned party
      if (percentOfValueIndex > -1) {
        collateralGroup.percentOfValue =
          collateralGroup.fields[percentOfValueIndex].fieldValue;
      }
      // Get Current Transaction
      let transactionIndex = this.ccmcApiService.transactions.findIndex(
        (transaction: any) => transaction.transactionID === collateralGroup.name
      );
      // If collateralGroup has type push it to collateral
      if (
        collateralGroup.type !== '' &&
        transactionIndex > -1 &&
        this.ccmcApiService.transactions[transactionIndex].display
      ) {
        // push to collateral
        this.collateral.push(collateralGroup);
        // update table display
        this.collateralSearchDataSource = new MatTableDataSource(
          this.collateral
        );
        // update table display
        this.collateralAssignmentDataSource = new MatTableDataSource([]);
      } else {
        // Set nextCollateral to false to finish loop
        nextCollateral = false;
      }
      if (
        collateralGroup.status === 'NEW' &&
        (collateralGroup.percentOfValue === undefined ||
          collateralGroup.percentOfValue === '')
      ) {
        collateralGroup.percentOfValue = 0;
      }
      // Update collateralCount
      collateralCount++;
    }
    if (this.collateral.length === 0) {
      console.log(this.collateral);
      this.collateralSearchDataSource = new MatTableDataSource(this.collateral);
      // update table display
      this.collateralAssignmentDataSource = new MatTableDataSource([]);
    }
      }

  async searchExisting(selectedParty: any) {
        // init searchCollateralrequest object
    const searchCollateralrequest = {
      configurations: this.ccmcApiService.configurations,
      customerKey: selectedParty,
      firstNext: 'F'
      // fields: this.collateral[0].fields
    };
    let userName = await this.amplifyService.getUserName();

    // Init request object
    const request = {
      content: searchCollateralrequest,
      assetID: this.assetService.getSelectedAssetId(),
      user: userName
    };
    // set spinner to true
    // console.log('Request: ', request);
    this.spinnerService.setShowSpinner(true);
    // Perform collateral search
    this.horizonXchangeService.searchCollateral(request).subscribe(result => {
      // set spinner to false
      this.spinnerService.setShowSpinner(false);
      // Parse result
            const parsedResult = JSON.parse(JSON.stringify(result));
      console.log('searchCollateral parsedResult', parsedResult);
      // Catch timeout
      if (parsedResult.status === 504) {
        const errorMessage = {
          message: 'Endpoint request timed out',
          title: 'Collateral Search Error'
        };
        const dialogRef1 = this.zone.run(() => {
          this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
        });
        // Unsuccessful search
      } else if (parsedResult.statusFlag === false) {
        const errorMessage = {
          message: parsedResult.statusMessage,
          title: 'Collateral Search Error'
        };
        // open error dialog with mesage
        const dialogRef1 = this.zone.run(() => {
          this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
        });
        // Successful search, but no results
      } else if (
        parsedResult.statusFlag === true &&
        parsedResult.content.length === 0
      ) {
        const errorMessage = {
          message: 'No Collateral Found',
          title: 'Collateral Search'
        };
        const dialogRef1 = this.zone.run(() => {
          this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
        });
        console.log(this.collateral);
        let fakeChxbox = {
          checked: true
        };
        for (let item of this.collateral) {
          this.selectingCollateral(fakeChxbox, item);
        }
        let searchValue = `${this.parties[0].CIF}`;
        this.addCollateral(searchValue);
      } else {
        // Display new collateral to screen
        let newCollateralArray = [];
        for (let [index, newCollateral] of parsedResult.content.entries()) {
          newCollateral.name = newCollateral.collateralID;
          newCollateral.assignedParty = '';
          newCollateral.status = 'EXISTING';
          newCollateral.type = newCollateral.collateralType;
          newCollateral.key = this.ccmcApiService.search.collateralSearch.key;
          newCollateral.dollarOfValue = '';
          newCollateral.percentOfValue = '';
          let newCollateralIndex = this.collateral.findIndex(
            coll => coll.name === newCollateral.name
          );
          // Collateral not in array
          if (newCollateralIndex === -1) {
            newCollateralArray.push(newCollateral);
          }
        }
        this.collateral = this.collateral.concat(newCollateralArray);
        console.log(this.collateral);
        // Evaluate Key Fields
        for (let [index, coll] of this.collateral.entries()) {
          coll.key = coll.key.replace(/\*/g, index + 1);
        }
        this.getFieldsForSearchDataCollateral();
        this.setFieldsForSearchDataCollateral();
        this.collateralSearchDataSource = new MatTableDataSource(
          this.collateral
        );
      }
    });
      }

  getFieldsForSearchDataCollateral() {
        for (let [index, collateralItem] of this.collateral.entries()) {
            let collateralFields =
        this.ccmcApiService.search.collateralSearch.collateralFields.replace(
          /\*/g,
          index + 1
        );
      collateralFields = collateralFields.split(', ');
      console.log('collateral fields', collateralFields);
      collateralItem.fields = JSON.parse(
        JSON.stringify(
          this.ccmcApiService.mapping.filter((obj: any) => {
            return (
              obj.fieldID.includes(collateralFields[0]) ||
              obj.fieldID.includes(collateralFields[1])
            );
          })
        )
      );
    }
    console.log(this.collateralSearchDataSource.data);
  }

  setFieldsForSearchDataCollateral() {
        // Set RMS as CollateralID
    let fieldIDRMS = 'CT0*_0004';
    let fieldIDcollateralID = 'CTA0*_0003';
    let fieldIDType = 'CT0*_0009';
    let fieldIDShortDescription = 'CT0*_0024';
    let fieldIDLongDescription = 'CT0*_0025';

    let fieldIDPledgeFixedAmount =
      this.ccmcApiService.search.collateralSearch.pledgeFixedField;
    let fieldIDPledgePercentAmount =
      this.ccmcApiService.search.collateralSearch.pledgePercentField;
    for (let [index, collateral] of this.collateral.entries()) {
            // Get rmsKey Index
      let rmsFieldIndex = collateral.fields.findIndex(
        (field: any) => field.fieldID === fieldIDRMS.replace('*', `${index + 1}`)
      );
      if (rmsFieldIndex > -1) {
        console.log('Replace RMS with', collateral.assignedParty);
        collateral.fields[rmsFieldIndex].fieldValue = collateral.assignedParty;
      }
      // Get shortDescription Index
      let shortDescriptionFieldIndex = collateral.fields.findIndex(
        (field: any) =>
          field.fieldID === fieldIDShortDescription.replace('*', `${index + 1}`)
      );
      if (shortDescriptionFieldIndex > -1) {
        console.log(
          'Replace Short Description with',
          collateral.collateralShortDescription
        );
        collateral.fields[shortDescriptionFieldIndex].fieldValue =
          collateral.collateralShortDescription;
      }

      // Get LongDescription Index
      let longDescriptionFieldIndex = collateral.fields.findIndex(
        (field: any) =>
          field.fieldID === fieldIDLongDescription.replace('*', `${index + 1}`)
      );
      if (longDescriptionFieldIndex > -1) {
        console.log(
          'Replace Long Description with',
          collateral.collateralLongDescription
        );
        collateral.fields[longDescriptionFieldIndex].fieldValue =
          collateral.collateralLongDescription;
      }

      if (collateral.collateralID) {
        // Get collateralID Index
        let collateralIDIndex = collateral.fields.findIndex(
          (field: any) =>
            field.fieldID === fieldIDcollateralID.replace('*', `${index + 1}`)
        );
        if (collateralIDIndex > -1) {
          console.log('Replace collateralID with', collateral.collateralID);
          collateral.fields[collateralIDIndex].fieldValue =
            collateral.collateralID;
        }
      }

      // Get collateral Type Index
      let collateralTypeIndex = collateral.fields.findIndex(
        (field: any) => field.fieldID === fieldIDType.replace('*', `${index + 1}`)
      );
      if (collateralTypeIndex > -1) {
        console.log('Replace type with', collateral.collateralType);
        collateral.fields[collateralTypeIndex].fieldValue = collateral.type;
      }

      // Get collateral Fixed Amount Index
      let collateralFixedAmountIndex = collateral.fields.findIndex(
        (field: any) =>
          field.fieldID ===
          fieldIDPledgeFixedAmount.replace('*', `${index + 1}`)
      );
      if (collateralFixedAmountIndex > -1) {
        console.log('Replace fixedAmount with', collateral.dollarOfValue);
        collateral.fields[collateralFixedAmountIndex].fieldValue =
          collateral.dollarOfValue;
      }

      // Get collateral Percent Amount Index
      let collateralPercentAmountIndex = collateral.fields.findIndex(
        (field: any) =>
          field.fieldID ===
          fieldIDPledgePercentAmount.replace('*', `${index + 1}`)
      );
      if (collateralPercentAmountIndex > -1) {
        console.log('Replace percentAmount with', collateral.percentOfValue);
        collateral.fields[collateralPercentAmountIndex].fieldValue =
          collateral.percentOfValue;
      }
    }
  }

  getFieldsForAssignmentCollateral() {
    for (let [
      index,
      collateralItem
    ] of this.collateralAssignmentDataSource.data.entries()) {
      let collateralFields =
        this.ccmcApiService.search.collateralSearch.collateralFields.replace(
          /\*/g,
          index + 1
        );
      collateralFields = collateralFields.split(', ');
      console.log('collateral fields', collateralFields);
      collateralItem.fields = JSON.parse(
        JSON.stringify(
          this.ccmcApiService.mapping.filter((obj: any) => {
            return (
              obj.fieldID.includes(collateralFields[0]) ||
              obj.fieldID.includes(collateralFields[1])
            );
          })
        )
      );
    }
    console.log(this.collateralAssignmentDataSource.data);
  }

  setFieldsForCollateral() {
    // Set RMS as CollateralID
    let fieldIDRMS = 'CT0*_0004';
    let fieldIDcollateralID = 'CTA0*_0003';
    let fieldIDType = 'CT0*_0009';
    let fieldIDPledgeFixedAmount =
      this.ccmcApiService.search.collateralSearch.pledgeFixedField;
    let fieldIDPledgePercentAmount =
      this.ccmcApiService.search.collateralSearch.pledgePercentField;
    for (let [
      index,
      collateral
    ] of this.collateralAssignmentDataSource.data.entries()) {
      console.log(collateral);
      // Get rmsKey Index
      let rmsFieldIndex = collateral.fields.findIndex(
        (field: any) => field.fieldID === fieldIDRMS.replace('*', `${index + 1}`)
      );
      if (rmsFieldIndex > -1) {
        console.log('Replace RMS with', collateral.assignedParty);
        collateral.fields[rmsFieldIndex].fieldValue = collateral.assignedParty;
      }

      if (collateral.collateralID) {
        // Get collateralID Index
        let collateralIDIndex = collateral.fields.findIndex(
          (field: any) =>
            field.fieldID === fieldIDcollateralID.replace('*', `${index + 1}`)
        );
        if (collateralIDIndex > -1) {
          console.log('Replace collateralID with', collateral.collateralID);
          collateral.fields[collateralIDIndex].fieldValue =
            collateral.collateralID;
        }
      }

      // Get collateral Type Index
      let collateralTypeIndex = collateral.fields.findIndex(
        (field: any) => field.fieldID === fieldIDType.replace('*', `${index + 1}`)
      );
      if (collateralTypeIndex > -1) {
        console.log('Replace type with', collateral.collateralType);
        collateral.fields[collateralTypeIndex].fieldValue = collateral.type;
      }

      // Get collateral Fixed Amount Index
      let collateralFixedAmountIndex = collateral.fields.findIndex(
        (field: any) =>
          field.fieldID ===
          fieldIDPledgeFixedAmount.replace('*', `${index + 1}`)
      );
      if (collateralFixedAmountIndex > -1) {
        console.log('Replace fixedAmount with', collateral.dollarOfValue);
        collateral.fields[collateralFixedAmountIndex].fieldValue =
          collateral.dollarOfValue;
      }

      // Get collateral Percent Amount Index
      let collateralPercentAmountIndex = collateral.fields.findIndex(
        (field: any) =>
          field.fieldID ===
          fieldIDPledgePercentAmount.replace('*', `${index + 1}`)
      );
      if (collateralPercentAmountIndex > -1) {
        console.log('Replace percentAmount with', collateral.percentOfValue);
        collateral.fields[collateralPercentAmountIndex].fieldValue =
          collateral.percentOfValue;
      }
    }
  }

  selectingCollateral(chkbx: any, checked: any) {
    console.log(checked);
    console.log(chkbx);
    // If checkbox is checked we push it to selected search collateral
    if (chkbx.checked) {
      return this.selectedSearchCollateral.push({
        value: checked,
        chkbx
      });
    }
    // remove from selected search collateral
    this.selectedSearchCollateral = this.removeFromArray(
      this.selectedSearchCollateral,
      checked
    );
  }

  checkCollateralAssignmentExists(element: any) {
    if (
      this.collateralAssignmentDataSource &&
      this.collateralAssignmentDataSource.data
    ) {
      console.log('element', element);
      console.log(this.collateralAssignmentDataSource.data);
      // Check if the element exists in collateral assignment
      return this.collateralAssignmentDataSource.data.findIndex(
        (obj: any) => obj.name === element.name
      );
    } else {
      return -1;
    }
  }

  checkCanPledge() {
    if (this.collateralAssignmentDataSource.data.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  checkContinueDisabled() {
    // console.log(this.collateralAssignmentDataSource);
    // Returns true or false depending on if there is an assigned party for every collateral assignment
    if (this.collateralAssignmentDataSource) {
      this.continueDisabled = this.collateralAssignmentDataSource.data.some(
        (item: any) => {
          if (!item.assignedParty) {
            this.checkContinueDisabledBoolean = true;
            return true;
          } else {
            if (
              (item.dollarOfValue || item.percentOfValue) &&
              (item.dollarOfValue.length > 0 || item.percentOfValue.length > 0)
            ) {
              this.checkContinueDisabledBoolean = false;
              return false;
            } else {
              this.checkContinueDisabledBoolean = true;
              return true;
            }
          }
        }
      );
    } else {
      return true;
    }
  }

  addCollateral(groupCIF: any) {
    // init tempArray
    const tempArr: any[] = [];
    console.log('add collateral');
    this.selectedSearchCollateral.forEach(collateral => {
      console.log(this.checkCollateralAssignmentExists(collateral.value));
      if (this.checkCollateralAssignmentExists(collateral.value) < 0) {
        collateral.value.assignedParty = groupCIF;
        console.log('if collateral',collateral);
        let tempColl = collateral.value;
        tempColl.sendOrder = tempArr.length + this.collateralAssignmentDataSource.data.length + 1;
        tempArr.push(tempColl);
      }
      collateral.chkbx.checked = false;
    });
    if (tempArr.length > 0) {
      this.getFieldsForAssignmentCollateral();
      this.setFieldsForCollateral();
      this.conditionsService.evaluateConditions();
      this.collateralAssignmentDataSource = new MatTableDataSource(
        this.collateralAssignmentDataSource.data.concat(tempArr)
      );
    }
    this.selectedSearchCollateral.length = 0;
  }

  addToAssign(chkbx: any, element: any) {
    chkbx.checked
      ? this.selectedAssignCollateral.push({ chkbx, value: element })
      : (this.selectedAssignCollateral = this.removeFromArray(
          this.selectedAssignCollateral,
          element
        ));
    this.checkAssignPartyDisabled();
  }

  sendLoan() {
    // checking to see that Send Order values are valid
    let collateralOrderArray: any = [];
    for (let coll of this.collateralAssignmentDataSource.data) {
      collateralOrderArray.push(coll.sendOrder);
    }
    const duplicates = collateralOrderArray.filter((item: any, index: any) => collateralOrderArray.indexOf(item) !== index);
    console.log('duplicates?', duplicates);
    if (duplicates.length > 0) {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          title: 'Error',
          message: 'Can not have duplicate Send Order values'
        }
      });
      return;
    }

    let collateralSendData: any = JSON.parse(JSON.stringify(this.collateralAssignmentDataSource.data));
    function compare(a: any, b: any) {
      if ( a.sendOrder < b.sendOrder ){
        return -1;
      }
      if ( a.sendOrder > b.sendOrder ){
        return 1;
      }
      return 0;
    }
    collateralSendData.sort(compare);

    console.log('collateral send', collateralSendData);
    this.collateralAssignmentDataSource.data = collateralSendData;

    this.getFieldsForAssignmentCollateral();
    this.setFieldsForCollateral();
    console.log(this.collateralAssignmentDataSource.data);
    for (let i = 0; i < this.collateralAssignmentDataSource.data.length; i++) {
      this.collateralAssignmentDataSource.data[i].fields.forEach((c: any) => {
        // Get index for fieldID
        const index = this.ccmcApiService.mapping.findIndex(
          (obj: any) => obj.fieldID === c.fieldID.replace('*', `${i + 1}`)
        );

        // Set fieldValue if available
        if (index > -1) {
          this.ccmcApiService.mapping[index].fieldValue = c.fieldValue;
        }
      });
    }
    // // Loop through assignment data  and get keys for collateral
    for (let i = 0; i < this.collateralAssignmentDataSource.data.length; i++) {
      console.log(
        this.collateralAssignmentDataSource.data[i].key.replace(
          /\*/g,
          `${i + 1}`
        )
      );
      // init keys
      const keys = this.collateralAssignmentDataSource.data[i].key
        .replace(/\*/g, `${i + 1}`)
        .split(', ');
      keys.forEach((k: any) => {
        // get index for each key
        const keyIndex = this.ccmcApiService.mapping.findIndex(
          (obj: any) => obj.fieldID === k
        );
        console.log(k);
        console.log(keyIndex);
        // if key exists set the value appropriate field value
        if (
          keyIndex > -1 &&
          this.collateralAssignmentDataSource.data[i].collateralID
        ) {
          this.ccmcApiService.mapping[keyIndex].fieldValue =
            this.collateralAssignmentDataSource.data[i].collateralID;
        }
      });
    }
    // Evaluate new values with conditions
    this.conditionsService.evaluateConditions();
    // close dialog
    this.closeDialog(true);
  }

  remove() {
    // loop through selected assign collateral
    this.selectedAssignCollateral.forEach(element => {
      // remove the element from the the collateral assignment data source
      this.collateralAssignmentDataSource.data = [
        ...this.removeFromArray(
          this.collateralAssignmentDataSource.data,
          element.value
        )
      ];
    });
    // Set length to 0
    this.selectedAssignCollateral.length = 0;
    this.checkAssignPartyDisabled();
  }

  removeAll() {
    // Set data to []
    this.collateralAssignmentDataSource.data = [];
    // disable assignparty button
    this.assignPartyDisabled = true;
  }

  showDetails() {
    this.dialog.open(CCMCCollateralDetailsDialogComponent, {
      data: this.collateralSearchDataSource.data,
      panelClass: 'collateral-details__dialog'
    });
  }

  checkAssignPartyDisabled() {
    this.assignPartyDisabled =
      this.selectedAssignCollateral.length === 0 ? true : false;
  }

  closeDialog(sendLoan: any) {
    this.dialogRef.close(sendLoan);
  }

  assignToSelected(value: any) {
    // set assign party disabled to false
    this.assignPartyDisabled = false;
    // loop through each selected assign collateral and set the assign party and check box
    this.selectedAssignCollateral.forEach(collateral => {
      collateral.value.assignedParty = value;
      collateral.chkbx.checked = false;
    });
    // Reset length to 0
    this.selectedAssignCollateral.length = 0;
    // evaluate Conditions
    this.conditionsService.evaluateConditions();
    // Check if Assign party disabled
    this.checkAssignPartyDisabled();
  }

  removeFromArray(arr: any, obj: any) {
    const indx = arr.findIndex((elem: any) => {
      if (elem.value) {
        return elem.value.name === obj.name;
      }
      return elem.name === obj.name;
    });
    if (indx > -1) {
      arr.splice(indx, 1);
      return arr;
    }
  }

  dropTable(event: CdkDragDrop<Collateral[]>) {
    const prevIndex = this.collateralAssignmentDataSource.data.findIndex(
      (d: any) => d === event.item.data
    );
    moveItemInArray(
      this.collateralAssignmentDataSource.data,
      prevIndex,
      event.currentIndex
    );
    this.table.renderRows();
  }

  ngOnDestroy() {
    if (this.collateralSubscription) {
      this.collateralSubscription.unsubscribe();
    }
    if (this.spinnerSub) {
      this.spinnerSub.unsubscribe();
    }
  }

  pledgeValue() {
    const pledgeValueDialogRef = this.dialog.open(PledgeValueDialogComponent, {
      panelClass: 'collateral-search__dialog',
      data: this.collateralAssignmentDataSource.data,
      disableClose: true
    });
    pledgeValueDialogRef.afterClosed().subscribe(assignPledge => {
      console.log(assignPledge);
      if (assignPledge) {
        this.setFieldsForCollateral();
      }
    });
  }

  openCollateralDetail(collateral: any) {
    this.dialog.open(CollateralDetailDialogComponent, {
      data: collateral,
      panelClass: 'collateral-details__dialog'
    });
  }

  zeroIfBlank(value: any) {
    if (value == '') {
      return 0;
    } else {
      return value;
    }
  }
}
