import { ScrollDispatcher } from '@angular/cdk/overlay';
import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { observable, Observable, Subject, Subscription } from 'rxjs';
import { debounce, debounceTime, takeUntil } from 'rxjs/operators';
import { ErrorDialogComponent } from 'src/@ccmc/components/error-dialog/error-dialog.component';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { ConditionsService } from 'src/@ccmc/services/conditions.service';
import { GeneralLedgerAccountingService } from 'src/@ccmc/services/general-ledger-accounting.service';
import { GlobalSearchService } from 'src/@ccmc/services/global-search.service';
import { IssuesService } from 'src/@ccmc/services/issues.service';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
import { glaNavigation, navigation } from 'src/app/navigation/navigation';

@Component({
  selector: 'app-gla-transactions',
  templateUrl: './gla-transactions.component.html',
  styleUrls: ['./gla-transactions.component.scss']
})
export class GlaTransactionsComponent implements OnInit {
  @ViewChild('searchbar') searchBarRef: ElementRef;
  @ViewChild(MatAutocompleteTrigger)
  autocomplete: MatAutocompleteTrigger;
  @ViewChild('arrow-right') arrowRightRef: MatIcon;
  private glaDocumentSub: Subscription;
  displayedColumns: any[] = [];
  displayedColumnNames: any[] = [];
  bottomDisplayedColumns: any[] = [];
  fieldTypePattern = '^[+-]?([0-9]*.?[0-9]+|[0-9]+.?[0-9]*)?$';
  dataSource: any;
  totalTableDataSource: any;
  transactionID: any;
  navigationSubscription: Subscription;
  currentTransactionFields: any[] = [];
  showSpinner: boolean; // flag for the mat-spinner
  private spinnerSub: Subscription;
  shortcuts: ShortcutInput[] = [];
  public selected: any;
  showGlobalSearchCancel = false;
  showNoResults = false;
  readOnlyArray: any[] = [];
  requiredArray: any[] = [];

  dynamicGLANav: any = glaNavigation;

  autoOptions: any[];
  filteredOptions: any;
  showDelay = new FormControl(1000);
  isEditable = false;
  fieldSearchPlaceholder = 'Search by Field...';
  globalSearchPlaceholder = 'Search Globally...';
  @ViewChild(MatSort) sort: MatSort;
  totalTableWidth: any;
  globalSearch = true;
  currentGlobalSearchField: any;
  otherGlobalSearchFields: any;
  private currentGlobalSearchFieldSub: Subscription;
  private getIssuesSub: Subscription;
  private currencyPipe: CurrencyPipe;
  currentGlobalFilterString: any;
  currentGlobalSearchIndex: any;
  globalSearchCounter: any;
  unsubscribe: Subject<any> = new Subject();
  totalAmount = 0;
  coreLayout: any;
  glaDocument: any;
  transactionsObject: any[] = [];
  columnWidths: any[] = [];
  glaSelectedSetSub: Subscription;
  selectedSetDocument: any;
  savedValues = [
    {
      name: 'Amount',
      width: '130px'
    }
  ];
  creditDebitColumnName: any;
  headerErrorFlag: any = {};
  errorCodeHandler: any = {};
  /**
   * Creates an instance of CCMCTransactionsComponent.
   * @param {CcmcApiService} ccmcApiService
   * @param {ActivatedRoute} route
   * @param {Router} router
   * @param {CCMCSelectedFieldService} selectedFieldService
   * @param {SpinnerService} spinnerService
   * @param {MatDialog} dialog
   * @param {ConditionsService} conditionsService
   * @param {GlobalSearchService} globalSearchService
   * @param {ChangeDetectorRef} cdRef
   * @param {ScrollDispatcher} scrollDispatcher
   * @param {IssuesService} issuesService
   * @param {SnackbarService} snackBarService
   * @memberof CCMCTransactionsComponent
   */
  constructor(
    private ccmcApiService: CcmcApiService,
    private route: ActivatedRoute,
    private router: Router,
    private selectedFieldService: CCMCSelectedFieldService,
    private spinnerService: SpinnerService,
    public dialog: MatDialog,
    private conditionsService: ConditionsService,
    private globalSearchService: GlobalSearchService,
    private cdRef: ChangeDetectorRef,
    private scrollDispatcher: ScrollDispatcher,
    private issuesService: IssuesService,
    private snackBarService: SnackbarService,
    private glaService: GeneralLedgerAccountingService
  ) {
    this.scrollDispatcher.scrolled().subscribe(() => {
      if (this.autocomplete) {
        this.autocomplete.closePanel();
      }
    });
  }

  ngOnInit() {
    // Subscribe to spinner
    this.spinnerSub = this.spinnerService.spinner.subscribe(spinner => {
      this.showSpinner = spinner;
      // if a dialog is open hide spinner so it isnt spinner behind dialog.
      if (this.dialog.openDialogs[0]) {
        this.showSpinner = false;
      }
    });
    this.getData();
  }

  setTableColumns(coreLayout: any) {
    this.displayedColumns = [];
    this.bottomDisplayedColumns = [];
    this.displayedColumns.push({
      name: 'Delete',
      id: '6'
    });
    this.displayedColumnNames.push('Delete');
    this.bottomDisplayedColumns.push('Delete' + '2');
    for (let column of coreLayout) {
      if (column.columnID > 0) {
        this.displayedColumns.push({
          name: column.columnName,
          id: column.columnID
        });
        this.displayedColumnNames.push(column.columnName);
        this.headerErrorFlag[column.columnName] = false;
        this.errorCodeHandler[column.columnName] = [];
        this.bottomDisplayedColumns.push(column.columnName + '2');
      }
    }
    console.log(this.displayedColumns);
    console.log(this.bottomDisplayedColumns);
    this.getTotalLengthOfColumns(coreLayout);
    return coreLayout;
  }

  getTotalLengthOfColumns(coreLayout: any) {
    let totalLength = 0;
    for (let core of coreLayout) {
      totalLength += core.length;
    }
    console.log('Total field Lengths', totalLength);
  }

  addTransactionArrayToCoreLayout(coreLayout: any) {
    for (let core of coreLayout) {
      core.transactions = [];
    }
  }

  getData() {
    // Subscribe to the document
    this.glaDocumentSub = this.glaService.selectedSetDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(glaDocument => {
        if (glaDocument) {
          // console.log(glaDocument);
          this.glaDocument = glaDocument;
          this.coreLayout = this.setTableColumns(this.glaDocument.coreLayout);
          this.creditDebitColumnName = this.getCreditDebitColumnName();
          // console.log(this.coreLayout);
          this.addTransactionArrayToCoreLayout(this.coreLayout);
          this.getTableData(this.glaDocument);
          // console.log(this.glaDocument.transactionTable);
          this.setTableData(this.glaDocument.transactionTable);
          this.calculateColumnWidth();
          this.evaluateTransactions();
        }
      });
    this.glaSelectedSetSub = this.glaService.selectedSetDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(selectedSetDocument => {
        if (selectedSetDocument) {
          this.selectedSetDocument = selectedSetDocument;
        }
      });
  }

  getTableData(glaDocument: any) {
    console.log(glaDocument.set.transactions);
    const transactionsTable = [];
    for (let transaction of glaDocument.set.transactions) {
      const columns = transaction.columns;
      let tempTransactionItem: any = {};
      for (let column of columns) {
        const columnIndex = glaDocument.coreLayout.findIndex(
          (col: any) => col.columnID === column.columnID
        );
        if (columnIndex > -1) {
          // console.log(
          //   'Current CoreLayout Item',
          //   glaDocument.coreLayout[columnIndex]
          // );
          // console.log(
          //   'Current Column Name',
          //   glaDocument.coreLayout[columnIndex].columnName
          // );
          tempTransactionItem[glaDocument.coreLayout[columnIndex].columnName] =
            column.fieldValue;
          // console.log('Temp Transaction Item', tempTransactionItem);
        }
      }
      transactionsTable.push(tempTransactionItem);
    }
    // console.log('Core Layout Data', glaDocument.coreLayout);
    // console.log('Transaction Table Data', transactionsTable);
    glaDocument.transactionTable = transactionsTable;
  }

  setTableData(transactions: any) {
    this.transactionsObject = transactions;
    const transactionKeys = Object.keys(transactions[0]);
    for (let entry of transactions) {
      for (let key of transactionKeys) {
        if (this.savedValues && this.savedValues.length) {
          let returnSize = entry[key].length * 15 + 'px';
          for (let col of this.savedValues) {
            const assetIndex = this.savedValues.findIndex(
              asset => asset.name == key
            );
            if (assetIndex > -1) {
              if (col.name === key) {
                let returnSizeNoPx = Number(returnSize.slice(0, -2));
                let widthNoPx = Number(col.width.slice(0, -2));
                if (returnSizeNoPx > widthNoPx) {
                  col.width = returnSize;
                  console.log('new width', col.width);
                }
              }
            } else {
              if (returnSize === '0px') {
                this.savedValues[this.savedValues.length] = {
                  name: key,
                  width: '100px'
                };
              } else {
                this.savedValues[this.savedValues.length] = {
                  name: key,
                  width: returnSize
                };
              }
            }
          }
        } else {
          let returnSize = entry[key].length * 15 + 'px';
          this.savedValues[0] = {
            name: key,
            width: returnSize
          };
        }
      }
    }
    console.log(this.savedValues);
    this.dataSource = new MatTableDataSource(transactions);
    this.totalAmount = this.getTotal(transactions);;
    this.spinnerService.setShowSpinner(false);
  }

  getCreditDebitColumnName() {
    let credDebColumnName =
      this.glaDocument.configurations.creditDebitColumnName;

    if (credDebColumnName) {
      return credDebColumnName;
    } else {
      return 'Credit/Debit Code';
    }
  }

  getTotal(transactions: any) {
    const debitCodes = this.glaDocument.configurations.debitCode.split(',');
    const creditCodes = this.glaDocument.configurations.creditCode.split(',');
    for (let debitCode of debitCodes) {
      debitCode = debitCode.trim();
    }
    for (let creditCode of creditCodes) {
      creditCode = creditCode.trim();
    }
    let amountByPolarity = false;
    if (
      debitCodes.includes('+') ||
      creditCodes.includes('+') ||
      debitCodes.includes('-') ||
      creditCodes.includes('-')
    ) {
      amountByPolarity = true;
    }
    let total = 0;
    if (amountByPolarity) {
      for (let transaction of this.glaDocument.transactionTable) {
        console.log('Amount', transaction['Amount']);
        if (transaction['Amount']) {
          if (transaction['Amount'].includes('$')) {
            transaction['Amount'] = transaction['Amount']
              .replace("$", "")
              .replaceAll(',', '');
          }
        } else {
          transaction['Amount'] = transaction['Amount'] = '';
        }
        total += +transaction['Amount'];
      }
    } else {
      for (let transaction of transactions) {
        if (transaction['Amount'].includes('$')) {
          transaction['Amount'] = transaction['Amount']
            .replace("$", "")
            .replaceAll(',', '');
        }
        if (creditCodes.includes(transaction[this.creditDebitColumnName])) {
          let numAmount = +transaction['Amount'];
          total -= numAmount;
        } else if (
          debitCodes.includes(transaction[this.creditDebitColumnName])
        ) {
          let numAmount = +transaction['Amount'];
          total += numAmount;
        }
      }
    }
    console.log('Total Amount From Get Total', total);
    // console.log('New Amount Total', total);
    return +total.toFixed(2);
  }

  ngAfterViewInit() {
    // Init shortcuts
    this.shortcuts.push({
      key: ['cmd + shift + d'],
      label: 'Demo Mode',
      description: 'Demo Mode Toggle',
      command: e => {
        this.setDemoMode();
      },
      preventDefault: true
    });
  }

  ngAfterViewChecked() {
    // Detect changes
    this.cdRef.detectChanges();
  }

  sortData() {
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    // Resetting error handling to defaults
    for (let column of this.displayedColumns) {
      this.headerErrorFlag[column.name] = false;
      this.errorCodeHandler[column.name] = [];
    }
  }

  ngOnDestroy() {
    if (this.spinnerSub) {
      this.spinnerSub.unsubscribe();
    }
  }

  onSelect(e: any, selected: any) {
    console.log(selected);
    this.selected = selected;
  }

  applyAutoFilter(filters: any) {
    if (filters === '') {
      this.filteredOptions = this.autoOptions;
    } else {
      this.filteredOptions = this.autoOptions.filter(o => {
        return o.displayName.toLowerCase().includes(filters.toLowerCase());
      });
    }
  }

  setDemoMode() {
    this.glaDocument.configurations.demoMode =
      !this.glaDocument.configurations.demoMode;
    this.snackBarService.openSnackBar(
      'Demo Mode: ' + this.glaDocument.configurations.demoMode,
      'Okay'
    );
  }

  clearSearchData() {
    this.searchBarRef.nativeElement.value = '';
  }

  evaluateTransactions(transaction?: any, column?: any) {
    console.log('Updated transactions object', this.transactionsObject);
    if (transaction && transaction['Credit/Debit Code'] === 'DEBIT') {
      if (column === 'Amount') {
        if (transaction['Amount'].includes('-')) {
          transaction['Amount of Funds'] = transaction['Amount'].replace('$', "")
            .replace(',', "");
        } else {
          transaction['Amount of Funds'] = '-' + transaction['Amount'].replace('$', "")
            .replace(',', "");
        }
      } else if (column === 'Amount of Funds') {
        transaction['Amount'] = transaction['Amount of Funds']
          .replace('-', "");
      }
    } else {
      if (column === 'Amount') {
        transaction['Amount of Funds'] = transaction['Amount'].replace('$', "")
          .replace(',', "");
      } else if (column === 'Amount of Funds') {
        transaction['Amount'] = transaction['Amount of Funds'];
      }
    }
    this.totalAmount = this.getTotal(this.transactionsObject);
    console.log(this.totalAmount);
    this.updateGlaDocument();
    this.updateExportObject(this.transactionsObject);
    this.checkIsGLABalanced();
  }

  updateGlaDocument() {
    // console.log(this.glaDocument.set.transactions);
    let transactions = this.glaDocument.set.transactions;
    // console.log('Core Layout', this.coreLayout);
    //console.log(this.coreLayout[6].columnID);

    for (let [index, transaction] of transactions.entries()) {
      // console.log(transaction);
      const keys = Object.keys(this.transactionsObject[index]);
      for (let key of keys) {
        const columnIndex = this.coreLayout.findIndex(
          (layout: any) => layout.columnName === key
        );
        if (columnIndex > -1) {
          const columnID = this.coreLayout[columnIndex].columnID;
          const transactionColumnIndex = transaction.columns.findIndex(
            (col: any) => col.columnID === columnID
          );
          if (transactionColumnIndex > -1) {
            transaction.columns[transactionColumnIndex].fieldValue =
              this.transactionsObject[index][key];
          }
        }
        // console.log(this.transactionsObject[index]);
      }
    }
  }

  updateExportObject(transactions: any) {
    const totalAmountItem = JSON.parse(JSON.stringify(transactions[0]));
    const transactionKeys = Object.keys(transactions[0]);
    for (let [index, key] of transactionKeys.entries()) {
      if (index == 0) {
        totalAmountItem[key] = 'Total Amount Row: ';
      } else {
        totalAmountItem[key] = '';
      }
      if (key === 'Amount') {
        totalAmountItem[key] = `${this.totalAmount}`;
      }
    }
    const exportDocument = JSON.parse(JSON.stringify(transactions));
    exportDocument.push(totalAmountItem);
    this.glaService.setExportDocument(exportDocument);
  }

  clearCoreLayoutTransactions() {
    for (let core of this.coreLayout) {
      core.transactions = [];
    }
  }

  calculateColumnWidth() {
    this.columnWidths = [];
    let columnPixels = [];
    let totalLength = 0;
    this.totalTableWidth = 0;
    for (let [index, coreLayout] of this.coreLayout.entries()) {
      this.columnWidths.push(coreLayout.length);
      totalLength += coreLayout.length;
    }
    for (let column of this.columnWidths) {
      if (column < 15) {
        columnPixels.push(
          (1 / this.columnWidths.length) * window.innerWidth + 'px'
        );
        this.totalTableWidth +=
          (1 / this.columnWidths.length) * window.innerWidth;
      }
      if (column > 15 && column < 31) {
        columnPixels.push(
          (2 / this.columnWidths.length) * window.innerWidth + 'px'
        );
        this.totalTableWidth +=
          (2 / this.columnWidths.length) * window.innerWidth;
      }
      if (column > 31) {
        columnPixels.push(
          (3 / this.columnWidths.length) * window.innerWidth + 'px'
        );
        this.totalTableWidth +=
          (3 / this.columnWidths.length) * window.innerWidth;
      }
    }
    this.columnWidths = columnPixels;
  }

  checkIsGLABalanced() {
    if (this.totalAmount === 0) {
      console.log("Set Is GLA Balanced As", true);
      this.glaService.setIsGLABalanced(true);
    } else {
      console.log("Set Is GLA Balanced As", false);
      this.glaService.setIsGLABalanced(false);
    }
  }

  getMaxLength(columnID: any) {
    let columnIndex = this.coreLayout.findIndex(
      (coreLayoutItem: any) => coreLayoutItem.columnID === columnID
    );
    if (columnIndex > -1) {
      return this.coreLayout[columnIndex].length;
    } else {
      return 6;
    }
  }
  deleteItem(item: any) {
    let itemIndex = this.transactionsObject.findIndex(
      each =>
        each['Account Number'] === item['Account Number'] &&
        each['Transaction Name'] === item['Transaction Name']
    );
    this.transactionsObject.splice(itemIndex, 1);
    this.totalAmount = this.getTotal(this.transactionsObject);
    this.selectedSetDocument.set.transactions.splice(itemIndex, 1);
    this.setTableData(this.transactionsObject);
    this.updateExportObject(this.transactionsObject);
    this.checkIsGLABalanced();
  }
  removeDollarSignFromAmount(event: any) {
    var k;
    let key = Number(event.key);
    if (event.key === '.' || event.key === '-') return true;
    else return !(isNaN(key) || event.key === null || event.key === ' ');
  }
  checkColName(name: any) {
    return 'max - content';
  }
  getMaxWidth(name: any, element: any) {
    if (name === 'Delete') {
      return '40px';
    } else {
      for (let col of this.savedValues) {
        if (col.name === name) {
          let colWidth = col.width.replace('px', '');
          if (+colWidth < 100) {
            return '100px';
          } else {
            return col.width;
          }
        }
      }
    }
  }
  getMaxMinWidth(name: any) {
    if (name === 'Delete') {
      return '40px';
    } else {
      for (let col of this.savedValues) {
        if (col.name === name) {
          let colWidth = col.width.replace('px', '');
          if (+colWidth < 100) {
            return '100px';
          } else {
            return col.width;
          }
        }
      }
    }
  }

  setBorderColor(disCol: any, element: any) {
    // Changes cell border to red and adds error code to error handler if applicable
    if (disCol.name !== 'Amount' &&
        disCol.name !== 'Send Flag' &&
        disCol.name !== 'Delete') {
          let errorFlag: any = false;
          if (element[disCol.name] && (element[disCol.name].length > this.getMaxLength(disCol.id))) {
            // Error code 1
            // Value exceeds max length
            this.headerErrorFlag[disCol.name] = true;
            errorFlag = true;
            !this.errorCodeHandler[disCol.name].includes(1) ? this.errorCodeHandler[disCol.name].push(1) : this.errorCodeHandler[disCol.name];
          }
          
          // Set border red if error
          if (errorFlag === true) {
            return '1px solid red';
          } else {
            return '1px solid rgb(165, 165, 165)';
          }
    } else {
      return '1px solid rgb(165, 165, 165)';
    }
  }

  checkErrorDisplay(headerName: any) {
    // Displays issue icon in table header when cells contain errors
    if (this.headerErrorFlag[headerName] === true) {
      return '';
    } else {
      return 'none';
    }
  }

  checkRemoveFlag(element: any, disCol: any) {
    // Removes red border and error codes for resolved cells
    if (disCol.name !== 'Amount' &&
        disCol.name !== 'Send Flag' &&
        disCol.name !== 'Delete') {
          let indexToRemove: any = -1;
          if (element[disCol.name].length <= this.getMaxLength(disCol.id)) {
            // Error code 1
            // Value exceeds max length
            this.headerErrorFlag[disCol.name] = false;
            indexToRemove = this.errorCodeHandler[disCol.name].indexOf(1);
            if (indexToRemove > -1) {
              this.errorCodeHandler[disCol.name].splice(indexToRemove, 1);
            }
            indexToRemove = -1;
          }
    }
  }

  errorDialog(headerName: any) {
    // Builds and displays error dialog based on column error codes
    let message: any = 'Highlighted field(s):\n';
    // Exceeds character limit
    this.errorCodeHandler[headerName].includes(1) ? 
      message = message +  '* Exceed character limit\n': message
    this.dialog.open(ErrorDialogComponent, {
      data: {
        title: 'Issue',
        message: message
      }
    });
  }
}
