import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ccmc-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CCMCNavigationComponent {
    @Input() layout = 'vertical';
    @Input() navigation: any;
    constructor() {

    }

}
