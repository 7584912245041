<mat-toolbar>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center"
    fxFlex
  >
    <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
    </div>
  </div>
</mat-toolbar>
