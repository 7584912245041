<div style="width: 40%; min-width: 400px; background-color: e1e1e1">
  <h1>Edit Column</h1>
  <div style="display: grid; grid-template-columns: 1fr; column-gap: 15px">
    <mat-form-field style="width: 100%">
      <mat-label>ColumnID</mat-label>
      <mat-select [(ngModel)]="localColumn.columnID" name="ColumnID" required>
        <mat-option
          *ngFor="let targetItem of targetLayout"
          [value]="targetItem.columnID"
        >
          {{ targetItem.columnID }} - {{ targetItem.columnName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div style="display: flex; justify-content: space-between">
    <button
      mat-raised-button
      matTooltip="Close Dialog"
      matTooltipClass="toolTipStyle"
      (click)="closeDialog()"
      style="text-align: left; max-width: 75px"
    >
      Cancel
    </button>
    <button
      mat-raised-button
      matTooltip="Update Column ID"
      matTooltipClass="toolTipStyle"
      color="accent"
      (click)="save()"
      style="text-align: right; max-width: 50px"
    >
      Save
    </button>
  </div>
</div>
