import {
    Config,
    Navigation,
    Mode,
    Animation,
    Footer,
    Toolbar
} from '../@ccmc/models/config.model';

/**
 * Default ccmc Configuration
 *
 * You can edit these options to change the default options. All these options also can be changed per component
 * basis. See `app/main/content/pages/authentication/login/login.component.ts` constructor method to learn more
 * about changing these options per component basis.
 */
export const ccmcConfig: Config = {
    layout          : {
        navigation      : Navigation.left, // 'right', 'left', 'top', 'none'
        navigationFolded: false, // true, false
        toolbar         : Toolbar.below, // 'above', 'below', 'none'
        footer          : Footer.below, // 'above', 'below', 'none'
        mode            : Mode.fullwidth // 'boxed', 'fullwidth'
    },
    colorClasses    : {
        toolbar: 'mat-white-500-bg',
        navbar : 'mat-ccmc-dccmc-700-bg',
        footer : 'mat-ccmc-dccmc-900-bg'
    },
    customScrollbars: true,
    routerAnimation : Animation.fadeIn // fadeIn, slideUp, slideDown, slideRight, slideLeft, none
};
