<div class="addExistinUserContainer">
  <div
    class="moveContainer"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  ></div>
  <div
    style="display: flex; flex-direction: row; justify-content: space-between"
  >
    <div>
      <h1>Add Asset to User</h1>
    </div>
    <div>
      <h1>
        <i class="material-icons noselect" (click)="closeDialog()"> close </i>
      </h1>
    </div>
  </div>
  <div style="display: flex; flex-direction: column">
    <div style="display: flex; justify-content: center">
      <mat-form-field class="example-chip-list">
        <mat-chip-list #assetList aria-label="Fruit selection">
          <mat-chip
            *ngFor="let asset of assets"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(asset)"
          >
            {{ asset.id }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input
            placeholder="Asset(s)"
            [matChipInputFor]="assetList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)"
          />
        </mat-chip-list>
      </mat-form-field>
    </div>
  </div>
  <div class="btn-container">
    <button class="cancelBtn" mat-stroked-button (click)="closeDialog()">
      Cancel
    </button>
    <button class="addBtn" mat-raised-button (click)="addAsset()">
      <mat-icon style="color: #3fb2c6">add_circle</mat-icon> &nbsp;Confirm
    </button>
  </div>
</div>
