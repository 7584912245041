import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';
import { FormsModule } from '@angular/forms';
import { CCMCAddSupportValuesDialogComponent } from './add-support-values-dialog.component';
@NgModule({
  declarations: [CCMCAddSupportValuesDialogComponent],
  imports: [AppMaterialModule, CommonModule, FormsModule],
  entryComponents: [CCMCAddSupportValuesDialogComponent]
})
export class CCMCAddSupportValuesDialogModule {}
