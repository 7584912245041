import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, BehaviorSubject } from 'rxjs';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { adminNavigation, docConnectorNavigation, glaNavigation, navigation } from 'src/app/navigation/navigation';

@Injectable()
export class CCMCNavigationService {
  flatNavigation: any[] = [];

  // Navigation Types
  // default - classic ground control navigation
  // admin - admin navigation for accessing admin components
  // doc - doc-connector navigation for accessing doc connector components
  public onNavChange: BehaviorSubject<any> = new BehaviorSubject('default');

  onItemCollapsed: Subject<any> = new Subject();
  onItemCollapseToggled: Subject<any> = new Subject();
  onVisibilityChange: Subject<any> = new Subject();
  onSortChange: Subject<any> = new Subject();

  private onBadgeCountChange: BehaviorSubject<number> = new BehaviorSubject(0);
  badgeCount = this.onBadgeCountChange.asObservable(); // allows the value of count to be subscribed to

  private showNavSpinner = new BehaviorSubject<boolean>(false); // emits the value of spinner visibility
  onSpinnerChange$ = this.showNavSpinner.asObservable(); // allows subscription to the spinner visibility
  dynamicNav: any = navigation;
  dynamicAdminNav: any = adminNavigation;
  dynamicGLANav: any = glaNavigation;
  dynamicDCNav: any = docConnectorNavigation;

  nonIndexedNavItems: number = 0;
  defaultInstance: any;

  public transactionHistoryToggle: BehaviorSubject<any> = new BehaviorSubject(false);

  constructor(private amplifyService: AmplifyService, private assetService: AssetService, 
    public router: Router) {}

  /**
   * Get flattened navigation array
   * @param navigation
   * @returns {any[]}
   */
  getFlatNavigation(navigation: any) {
    for (const navItem of navigation) {
      if (navItem.type === 'item') {
        this.flatNavigation.push({
          title: navItem.title,
          type: navItem.type,
          icon: navItem.icon || false,
          url: navItem.url
        });

        continue;
      }

      if (navItem.type === 'collapse' || navItem.type === 'group') {
        if (navItem.children) {
          this.getFlatNavigation(navItem.children);
        }
      }
    }

    return this.flatNavigation;
  }

  /**
   * Sets the updated count value
   * @param count
   *
   */
  setBadgeCount(count: number) {
    this.onBadgeCountChange.next(count);
  }

  /**
   * Emits the value of the spinner visibility
   * @param val
   */
  setShowNavSpinner(val: boolean) {
    this.showNavSpinner.next(val);
  }

  async updateNavigation(product: any, source: any, target: any, ebm: any) {
    console.log(this.dynamicNav);
    console.log(this.dynamicGLANav);
    console.log(this.dynamicAdminNav);
    // Get Navigation Item indexes

    const sourceGroupIndex = this.dynamicAdminNav.findIndex(
      (child: any) => child.id == 'source'
    );
    if (sourceGroupIndex == -1) {
      console.log(
        "Developer Intervention needed. Can't find source group id in navigation array."
      );
      return;
    }

    console.log(sourceGroupIndex);

    const fileSelectorIndex = this.dynamicNav[1].children.findIndex(
      (child: any) => child.id == 'file-selector'
    );
    if (fileSelectorIndex == -1) {
      console.log(
        "Developer Intervention needed. Can't find file-selector id in navigation array."
      );
      return;
    }
    const loanLookupIndex = this.dynamicNav[1].children.findIndex(
      (child: any) => child.id == 'loan-lookup'
    );
    if (loanLookupIndex == -1) {
      console.log(
        "Developer Intervention needed. Can't find loan-lookup id in navigation array."
      );
      return;
    }

    const targetCredsIndex = this.dynamicAdminNav[1].children.findIndex(
      (child: any) => child.id == 'target-credentials'
    );
    if (targetCredsIndex == -1) {
      console.log(
        "Developer Intervention needed. Can't find target-credentials id in navigation array."
      );
      return;
    }

    const glaFileSelectorIndex = this.dynamicGLANav[1].children.findIndex(
      (nav: any) => nav.id === 'file-selector'
    );
    if (glaFileSelectorIndex == -1) {
      console.log(
        "Developer Intervention needed. Can't find file-selector id in gla navigation array."
      );
      return;
    }

    const glaLoanLookupIndex = this.dynamicGLANav[1].children.findIndex(
      (nav: any) => nav.id === 'loan-lookup'
    );
    if (glaLoanLookupIndex == -1) {
      console.log(
        "Developer Intervention needed. Can't find loan-lookup id in gla navigation array."
      );
      return;
    }

    const ebmReportsIndex = this.dynamicAdminNav[1].children.findIndex(
      (child: any) => child.id == 'ebm-report'
    );
    if (ebmReportsIndex == -1) {
      console.log(
        "Developer Intervention needed. Can't find ebm-erport id in gla navigation array."
      );
      return;
    }

    const ebmLogsIndex = this.dynamicAdminNav[1].children.findIndex(
      (child: any) => child.id == 'ebm-logging'
    );
    if (ebmLogsIndex == -1) {
      console.log(
        "Developer Intervention needed. Can't find ebm-report id in gla navigation array."
      );
      return;
    }

    // const loggingIndex = this.dynamicAdminNav[1].children.findIndex(
    //   (child: any) => child.id == 'logging'
    // );
    // if (loggingIndex == -1) {
    //   console.log(
    //     "Developer Intervention needed. Can't find standard logging id in admin navigation array."
    //   );
    //   return;
    // }

    const configurationsIndex = this.dynamicAdminNav[1].children.findIndex(
      (child: any) => child.id == 'target-configurations'
    );
    if (configurationsIndex == -1) {
      console.log(
        "Developer Intervention needed. Can't find target configurations id in admin navigation array."
      );
      return;
    }

    const fieldOptionsIndex = this.dynamicAdminNav[1].children.findIndex(
      (child: any) => child.id == 'field-options'
    );
    if (fieldOptionsIndex == -1) {
      console.log(
        "Developer Intervention needed. Can't find field-options id in admin navigation array."
      );
      return;
    }

    const supportValuesIndex = this.dynamicAdminNav[1].children.findIndex(
      (child: any) => child.id == 'support-values'
    );
    if (supportValuesIndex == -1) {
      console.log(
        "Developer Intervention needed. Can't find support-values id in admin navigation array."
      );
      return;
    }

    const validationIndex = this.dynamicAdminNav[1].children.findIndex(
      (child: any) => child.id == 'validation'
    );
    if (validationIndex == -1) {
      console.log(
        "Developer Intervention needed. Can't find validation id in admin navigation array."
      );
      return;
    }
    const setConfigurationIndex = this.dynamicAdminNav[1].children.findIndex(
      (child: any) => child.id == 'set-configuration'
    );
    if (setConfigurationIndex == -1) {
      console.log(
        "Developer Intervention needed. Can't find set-configuration id in navigation array."
      );
      return;
    }
    const targetLayoutIndex = this.dynamicAdminNav[1].children.findIndex(
      (child: any) => child.id == 'target-layout'
    );
    if (targetLayoutIndex == -1) {
      console.log(
        "Developer Intervention needed. Can't find target-layout id in navigation array."
      );
      return;
    }
    const translatorsIndex = this.dynamicAdminNav[2].children.findIndex(
      (child: any) => child.id == 'translators'
    );
    if (translatorsIndex == -1) {
      console.log(
        "Developer Intervention needed. Can't find translators id in navigation array."
      );
      return;
    }
    const encompassLoanFilesDownloadIndex =
      this.dynamicAdminNav[2].children.findIndex(
        (child: any) => child.id == 'encompass-loan-search'
      );
    if (encompassLoanFilesDownloadIndex == -1) {
      console.log(
        "Developer Intervention needed. Can't find encompass-loan-file-download id in navigation array."
      );
      return;
    }
    const sourceConfigurationsIndex =
      this.dynamicAdminNav[2].children.findIndex(
        (child: any) => child.id == 'source-configurations'
      );
    if (sourceConfigurationsIndex == -1) {
      console.log(
        "Developer Intervention needed. Can't find source-configuration id in navigation array."
      );
      return;
    }
    const loanFilesIndex = this.dynamicAdminNav[2].children.findIndex(
      (child: any) => child.id == 'loan-files'
    );
    if (loanFilesIndex == -1) {
      console.log(
        "Developer Intervention needed. Can't find loan-files id in navigation array."
      );
      return;
    }

    const docConnectorLoggingIndex = this.dynamicAdminNav[3].children.findIndex(
      (child: any) => child.id == 'doc-connector/logs'
    );
    if (docConnectorLoggingIndex == -1) {
      console.log(
        "Developer Intervention needed. Can't find doc-connector/logs id in admin navigation array."
      );
      return;
    }

    const userConfigurationIndex = this.dynamicAdminNav[3].children.findIndex(
      (child: any) => child.id == 'users'
    );
    if (userConfigurationIndex == -1) {
      console.log(
        "Developer Intervention needed. Can't find users id in admin navigation array."
      );
      return;
    }

    const loggingGroupsIndex = this.dynamicAdminNav[3].children.findIndex(
      (child: any) => child.id == 'logging'
    );
    if (loggingGroupsIndex == -1) {
      console.log(
        "Developer Intervention needed. Can't find asset logging id in admin navigation array."
      );
      return;
    }

    const encompassImportIndex = this.dynamicNav[1].children.findIndex(
      (child: any) => child.id == 'import'
    );
    if (encompassImportIndex == -1) {
      console.log(
        "Developer Intervention needed. Can't find encompass import id in admin navigation array."
      );
      return;
    }

    // If Encompass Or BytePro
    if (source === 'encompass' || source === 'bytepro') {
      // Show encompass related routes
      // Admin Navigation Items
      // // Source Configurations
      // Turn on source configurations for NXTsoft
      if (await this.amplifyService.checkNXTsoftUserGroup()) {
        // // Encompass Loan Search
        this.dynamicAdminNav[2].children[
          encompassLoanFilesDownloadIndex
        ].visible = false;
      }
      // if(!await this.amplifyService.checkLoanServicingAdmin()) {
      this.dynamicAdminNav[2].children[sourceConfigurationsIndex].visible = true;
      // }
      this.dynamicNav[1].children[encompassImportIndex].visible = true;
      this.dynamicAdminNav[2].children[loanFilesIndex].visible = false;
      // Standard Navigation
      // // Loan Lookup
      this.dynamicNav[1].children[loanLookupIndex].visible = true;
      // General Ledger Account Navigation
      // // Loan Lookup
      this.dynamicGLANav[1].children[glaLoanLookupIndex].visible = true;
      if (ebm && source === 'encompass' && !product.includes('doc_connector')) {
        // Admin Navigation Items
        // // EBM Logs
        this.dynamicAdminNav[1].children[ebmLogsIndex].visible = true;
      }
    } else if (
      source === 'ncino' ||
      source === 'mortgagebot' ||
      source === 'linear' ||
      source === 'sageworks'
    ) {
      // Show ncino related routes or mortgagebot
      // Admin Navigation Items
      // // Source Configurations
      // // Loan Files Index
      // if(!await this.amplifyService.checkLoanServicingAdmin()) {
        this.dynamicAdminNav[2].children[sourceConfigurationsIndex].visible = true;
      // }
      this.dynamicAdminNav[2].children[loanFilesIndex].visible = true;
      this.dynamicAdminNav[2].children[
        encompassLoanFilesDownloadIndex
      ].visible = false;
      // Standard Navigation
      // // File Selector
      this.dynamicNav[1].children[fileSelectorIndex].visible = true;
      // General Ledger Account Navigation
      // // File Selector
      this.dynamicGLANav[1].children[glaFileSelectorIndex].visible = true;
    } else {
      // Hide special source related related routes
      // Admin Navigation Items
      // // Loan Files
      this.dynamicAdminNav[2].children[loanFilesIndex].visible = true;
      this.dynamicAdminNav[2].children[
        encompassLoanFilesDownloadIndex
      ].visible = false;

      // Standard Navigation
      // // File Selector
      this.dynamicNav[1].children[fileSelectorIndex].visible = true;
      // General Ledger Account Navigation
      // // File Selector
      this.dynamicGLANav[1].children[glaFileSelectorIndex].visible = true;
    }

    if (target === 'premier') {
      // Show Premier Related Routes
      // Admin Navigation Items
      // // Target Environment Configurations
      this.dynamicAdminNav[1].children[targetCredsIndex].visible = true;
    }

    if (ebm && !product.includes('doc_connector')) {
      // Admin Navigation Items
      // // EBM Report
      this.dynamicAdminNav[1].children[ebmReportsIndex].visible = true;
    }

    // Set Doc Connector Admin Nav Items
    if (product.includes('doc_connector')) {
      this.dynamicAdminNav[3].children[docConnectorLoggingIndex].visible = true;
      this.dynamicAdminNav[1].children[configurationsIndex].visible = true;
      this.dynamicAdminNav[3].children[loggingGroupsIndex].visible = false;
    } // Set General Ledger Accounting Nav items
    else if (product.includes('general_ledger_accounting')) {
      this.dynamicAdminNav[1].children[configurationsIndex].visible = true;
      // this.dynamicAdminNav[1].children[loggingIndex].visible = true;
      this.dynamicAdminNav[3].children[docConnectorLoggingIndex].visible =
        false;
      if(!await this.amplifyService.checkNXTsoftUserGroup()) {
        this.dynamicAdminNav[1].children[targetLayoutIndex].visible = false;
        this.dynamicAdminNav[2].children[translatorsIndex].visible = false;
        this.dynamicAdminNav[1].children[setConfigurationIndex].visible = false;
      } else {
        this.dynamicAdminNav[1].children[setConfigurationIndex].visible = true;
        this.dynamicAdminNav[1].children[targetLayoutIndex].visible = true;
        this.dynamicAdminNav[2].children[translatorsIndex].visible = true;
      }
      this.dynamicAdminNav[3].children[loggingGroupsIndex].visible = true;
    } // Set Standard Navigation Items
    else {
      this.dynamicAdminNav[3].children[docConnectorLoggingIndex].visible = false;
      // if(await this.amplifyService.checkLoanSystemsAdmin()) {
      //   this.dynamicAdminNav[1].children[configurationsIndex].visible = true;
      //   // this.dynamicAdminNav[1].children[fieldOptionsIndex].visible = true;
      //   // this.dynamicAdminNav[1].children[supportValuesIndex].visible = true;
      //   // this.dynamicAdminNav[1].children[validationIndex].visible = true;
      //   this.dynamicAdminNav[3].children[loggingGroupsIndex].visible = true;
      // } else if(await this.amplifyService.checkLoanServicingAdmin()) {
      //   // this.dynamicAdminNav[1].children[configurationsIndex].visible = true;
      //   this.dynamicAdminNav[1].children[fieldOptionsIndex].visible = true;
      //   this.dynamicAdminNav[1].children[supportValuesIndex].visible = true;
      //   this.dynamicAdminNav[1].children[validationIndex].visible = true;
      //   this.dynamicAdminNav[3].children[loggingGroupsIndex].visible = true;
      // } else {
        this.dynamicAdminNav[1].children[configurationsIndex].visible = true;
        this.dynamicAdminNav[1].children[fieldOptionsIndex].visible = true;
        this.dynamicAdminNav[1].children[supportValuesIndex].visible = true;
        this.dynamicAdminNav[1].children[validationIndex].visible = true;
        this.dynamicAdminNav[3].children[loggingGroupsIndex].visible = true;
      // }
      this.dynamicAdminNav[3].children[docConnectorLoggingIndex].visible =
        false;
    }
    let sourceGroupVisibility = this.dynamicAdminNav[2].children.map((item: any) => item.visible);
    console.log(sourceGroupVisibility)

    if (sourceGroupVisibility.every((item: any) => item === false)) {
      console.log('it gets to here');
      this.dynamicAdminNav[sourceGroupIndex].visible = false;
    // } else if (await this.amplifyService.checkLoanServicingAdmin()) {
    //   this.dynamicAdminNav[sourceGroupIndex].visible = false;
    } else {
      this.dynamicAdminNav[sourceGroupIndex].visible = true;
    }
    // Turn off transaction navigation until a loan is loaded
    this.dynamicNav[2].children[1].visible = false;
    let userGroups: any = await this.amplifyService.getUserGroupsForUser();
    this.hideRoutesWithInvalidRoles(this.dynamicNav, userGroups);
    // Hides children routes with invalid roles
    this.hideRoutesWithInvalidRoles(this.dynamicAdminNav, userGroups);
    // Hide nav item groups with no visible children
    this.hideGroupsWithoutVisibleChildren(this.dynamicAdminNav);
    this.hideRoutesWithInvalidRoles(this.dynamicGLANav, userGroups);
    this.hideRoutesWithInvalidRoles(this.dynamicDCNav, userGroups);
  }

  async hideRoutesWithInvalidRoles(navigation: any, userGroups: any) {
    console.log("Current Navigation To Modify", navigation);
    console.log('User Groups', userGroups);
    for(let navItem of navigation) {
      if (navItem.children) {
        for(let child of navItem.children) {
          if (child.roles) {
            let hasRole = false;
            for(let userGroup of userGroups) {
              if (child.roles.includes(userGroup)) {
                hasRole = true;
              }
            }
            // If Has Role is still false, turn route off
            if (!hasRole) {
              console.log(`Turn off ${child.title} since no role match found in userGroup`);
              child.visible = false;
            }
          }
        }
      }
    }
  }

  hideGroupsWithoutVisibleChildren(navigation: any) {
    for(let navItem of navigation) {
      if (navItem.children) {
        let turnOffNavItem = true;
        for(let child of navItem.children) {
          if (child.visible === true) {
            console.log(`Keep on ${child.title} since it has visible children`);
            turnOffNavItem = false;
          }
        }
        if (turnOffNavItem) {
          console.log(`Turn off ${navItem.title} since it has no visible children`);
          navItem.visible = false;
        }
      }
    }
  }

  resetNavigation() {
    // Turn off Admin Navigation Items
    for (let navItem of this.dynamicAdminNav[1].children) {
      navItem.visible = false;
    }
    // Turn off GLA Navigation Items
    for (let navItem of this.dynamicGLANav[1].children) {
      navItem.visible = false;
    }
    // Turn off Standard Navigation Items
    for (let navItem of this.dynamicNav[1].children) {
      navItem.visible = false;
    }
  }

  turnGLANavItemsOff() {
    this.dynamicGLANav[3].children[0].visible = false;
    this.dynamicGLANav[3].children[1].visible = false;
    this.dynamicGLANav[2].children = 0;
  }

  loadAssetInformation() {
    // Init children as empty array
    this.dynamicNav[0].children = [];
    this.dynamicGLANav[0].children = [];
    this.dynamicAdminNav[0].children = [];
    this.dynamicDCNav[0].children = [];
    // Set product navigation object
    const productNav = {
      id: 'product',
      title: 'Product: ',
      type: 'item',
      visible: true,
      desc: true,
      descValue: this.assetService.getSelectedProduct()
    };
    // Push onto navigation and admin navigation object
    this.dynamicNav[0].children.push(productNav);
    this.dynamicAdminNav[0].children.push(productNav);
    this.dynamicGLANav[0].children.push(productNav);
    this.dynamicDCNav[0].children.push(productNav);
    // Set financial institution navigation object
    const fINav = {
      id: 'FI',
      title: 'FI: ',
      type: 'item',
      visible: true,
      desc: true,
      descValue: this.assetService.getSelectedFI()
    };
    // Push onto navigation and admin navigation object
    this.dynamicNav[0].children.push(fINav);
    this.dynamicAdminNav[0].children.push(fINav);
    this.dynamicGLANav[0].children.push(fINav);
    this.dynamicDCNav[0].children.push(fINav);
    // Set AssetID navigation object
    const assetIDNav = {
      id: 'assetid',
      title: 'AssetID: ',
      type: 'item',
      visible: true,
      desc: true,
      descValue: this.assetService.getSelectedAssetId()
    };
    // Push onto navigation and admin navigation object
    this.dynamicNav[0].children.push(assetIDNav);
    this.dynamicAdminNav[0].children.push(assetIDNav);
    this.dynamicGLANav[0].children.push(assetIDNav);
    this.dynamicDCNav[0].children.push(assetIDNav);
  }

  navigateToFirstVisibleChild(product: any) {
    // console.log("Dynamic Navigation ", this.dynamicNav);
    // console.log("Dynamic Admin Navigation ", this.dynamicAdminNav);
    // console.log("Dynamic GLA Navigation ", this.dynamicGLANav);
    // console.log("Dynamic DC Navigation ", this.dynamicDCNav);

    // console.log("Nav onNavChange", this.onNavChange.value);
    const currentNavValue = this.onNavChange.value;
    if (currentNavValue === 'admin') {
      for(let navItem of this.dynamicAdminNav) {
        if (navItem.children) {
          for (let child of navItem.children) {
            if (child.visible === true && child.url) {
              console.log('Navigate to', child);
              this.router.navigate([child.id]);
              return;
            }
          }
        }
      }
    }
    else if (currentNavValue === 'default') {
      for(let navItem of this.dynamicNav) {
        if (navItem.children) {
          for (let child of navItem.children) {
            if (child.visible === true && child.url) {
              console.log('Navigate to', child);
              this.router.navigate([child.id]);
              return;
            }
          }
        }
      }
    }
    else if (currentNavValue === 'doc') {
      for(let navItem of this.dynamicDCNav) {
        if (navItem.children) {
          for (let child of navItem.children) {
            if (child.visible === true && child.url) {
              console.log('Navigate to', child);
              this.router.navigate([child.id]);
              return;
            }
          }
        }
      }
    }
  }
}
