import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ChangeDetectorRef,
  NgZone,
  Inject,
  LOCALE_ID
} from '@angular/core';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, Subscription } from 'rxjs';
import { Collateral } from '../../models/collateral.model';
import { CollateralSearchParty } from '../../models/collateral-search-party.model';
import { CcmcApiService } from '../../services/ccmc-api.service';
import { PrecisionService } from '../../services/precision.service';
import { SpinnerService } from '../../services/spinner.service';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { AssetService } from 'src/@ccmc/services/asset.service';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDragHandle
} from '@angular/cdk/drag-drop';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatTable } from '@angular/material/table';
import { CCMCCollateralSearchDialogComponent } from '../collateral-search-dialog/collateral-search-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';
import { RequestChangeService } from 'src/@ccmc/services/request-change.service';
import { CCMCConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { v4 as uuidv4 }  from 'uuid';
import { formatDate } from '@angular/common';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
interface Status {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-request-change-dialog',
  templateUrl: './request-change-dialog.component.html',
  styleUrls: ['./request-change-dialog.component.scss']
})
export class RequestChangeDialogComponent implements OnInit {
  selectedLoan: any;
  ergencys: Status[] = [
    { value: 'low', viewValue: 'Low Priority' },
    { value: 'medium', viewValue: 'Medium Priority' },
    { value: 'high', viewValue: 'High Priority' }
  ];
  LoanNums: Status[] = [
    { value: '', viewValue: 'Current Loan' },
    { value: '', viewValue: 'All Loans' }
  ];
  changeRequestID = uuidv4();
  selectedRow = {
    fieldID: '',
    fieldDisplay: '',
    currentValue: '',
    correctedValue: '',
    loanNumber: '',
    status: '',
    urgency: '',
    description: '',
    nxtsoftNotes: '',
    dateCreated: ''
  };
  changeRequestFieldsSub: Subscription;
  changeRequestDocSub: Subscription;
  unsubscribe: Subject<any> = new Subject();
  docFields: any;
  fulldoc: any;
  allowedFieldWidth: any;
  private spinnerSub: Subscription;
  showSpinner: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CCMCCollateralSearchDialogComponent>,
    private ccmcApiService: CcmcApiService,
    private precisionService: PrecisionService,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private spinnerService: SpinnerService,
    private selectedFieldService: CCMCSelectedFieldService,
    private zone: NgZone,
    private assetService: AssetService,
    private requestChangeService: RequestChangeService,
    private snackBarService: SnackbarService,
    private adminApiService: AdminApiService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.selectedRow = {
      fieldID: '',
      fieldDisplay: '',
      currentValue: '',
      correctedValue: '',
      loanNumber: '',
      status: '',
      urgency: '',
      description: '',
      nxtsoftNotes: '',
      dateCreated: formatDate(Date.now(),'MM-dd-yy',this.locale)
    };
  }

  ngOnInit(): void {
    this.LoanNums[0].value = this.ccmcApiService.loanNumber;
    // console.log(this.LoanNums);
    this.allowedFieldWidth = this.data.name.fieldWidth;
    this.selectedRow.fieldID = this.data.name.fieldID;
    this.selectedRow.fieldDisplay = this.data.name.fieldDisplay;
    this.selectedRow.currentValue = this.data.name.fieldValue;
    this.selectedRow.correctedValue = this.data.name.fieldValue;
    this.selectedRow.status = 'Open Items (NXTsoft)';
    this.selectedRow.urgency = 'low';
    this.selectedRow.loanNumber = this.LoanNums[0].value;
    this.selectedRow.description = '';
    // console.log(this.selectedRow);
    this.spinnerSub = this.spinnerService.spinner.subscribe(spinner => {
      this.showSpinner = spinner;
      // console.log(this.showSpinner);
    });
  }
  closeBox() {
    this.dialogRef.close();
  }
  
  async continue() {
    // console.log(this.selectedRow.fieldID);
    this.spinnerService.setShowSpinner(true);
    let remoteChangeReqCopy = {
      assetID: this.assetService.getSelectedAssetId(),
      changeRequestID: this.changeRequestID,
      assetItem: this.selectedRow
    }
    await this.requestChangeService.postDynamoFieldValues(remoteChangeReqCopy);
    this.adminApiService
      .getDynamoDBItemsByIndex('change_request_log', this.assetService.getSelectedAssetId(), 'assetID')
      .subscribe(result => {
        console.log(result);
        const parsedResult = JSON.parse(JSON.stringify(result));
        if (parsedResult.statusFlag === true) {
          this.requestChangeService.changeRequestDoc.next(parsedResult.content.Items);
        }
        this.dialogRef.close(true);
      });
  }
  ngOnDestroy() {
    // Unsubscribe from observables
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }
}
