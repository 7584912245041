import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { RequestChangeService } from 'src/@ccmc/services/request-change.service';

@Component({
  selector: 'app-change-request-dropdown',
  templateUrl: './change-request-dropdown.component.html',
  styleUrls: ['./change-request-dropdown.component.scss']
})
export class ChangeRequestDropdownComponent implements OnInit, AfterViewInit {
  statusCurrent: any;
  constructor(
    private fieldEditedService: FieldEditedService,
    private requestChangeService: RequestChangeService
  ) {}
  changeRequestStatusSub: Subscription;
  unsubscribe: Subject<any> = new Subject();
  statuses: any = [];
  showSpinner: boolean; // flag for the mat-spinner
  root = document.documentElement;
  @ViewChild('tabGroup') tabGroup: any;

  ngOnInit(): void {
    this.root.style.setProperty('--selectedChangeIndex', '0');
    this.changeRequestStatusSub = this.requestChangeService.changeRequestDocStatuses
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(result => {
        // console.log(check);
        this.statuses = result.statuses;
      });
  }

  ngAfterViewInit() {
    console.log('afterViewInit => ', this.tabGroup.selectedIndex);
    this.requestChangeService.currentSelectedTab.next(this.tabGroup.selectedIndex);
  }

  newTabSelect(a: any) {
    if (a.tab.textLabel === 'Implementation Status') {
      this.root.style.setProperty('--selectedChangeIndex', '0');
    } else {
      this.root.style.setProperty('--selectedChangeIndex', '1');
    }
    this.statusCurrent = a.tab.textLabel;
    this.fieldEditedService.newTableChecked.next(this.statusCurrent);
    this.requestChangeService.currentSelectedTab.next(this.tabGroup.selectedIndex);
  }
}
