import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigurationsComponent } from './configurations.component';
import { RouterModule, Routes } from '@angular/router';
import { CCMCSharedModule } from '../../../../@ccmc/shared.module';
import { AuthGuard } from '../../../auth/auth.guard';
import { Role } from 'src/@ccmc/models/role';

const routes: Routes = [
  {
    path: 'target-configurations',
    component: ConfigurationsComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: {
      roles: [
        Role.ClientAdmin,
        Role.NxtsoftSales,
        Role.NxtsoftOp,
        Role.NxtsoftDev
      ]
    }
  }
];
@NgModule({
  declarations: [ConfigurationsComponent],
  imports: [CommonModule, RouterModule.forChild(routes), CCMCSharedModule],
  exports: [ConfigurationsComponent]
})
export class ConfigurationsModule {}
