<div class="mapping-mappingOptionsBox">
  <div style="height: 12%">
    <span class="selectOptions-title">Select Options</span>
    <button
      class="buttonFR"
      mat-raised-button
      matTooltip="Saves any changes you make, within this section."
      matTooltipClass="toolTipStyle"
      color="accent"
      (click)="save()"
      [disabled]="!editedFlag"
    >
      Save
    </button>
  </div>
  <div style="margin-top: 30px">
    <mat-form-field class="form-field-displayMapping-mapping">
      <input
        class="input-length"
        matInput
        placeholder="Field Display"
        [(ngModel)]="localSelected.fieldDisplay"
        (ngModelChange)="fieldEdited()"
      />
    </mat-form-field>

   <mat-form-field *ngIf="localSelected.rowColor === 'FFFFFF' 
            || localSelected.rowColor === 'White' 
            || localSelected.rowColor === 'ffffff'
            || localSelected.rowColor === 'fffff'" class="form-field-row-Mapping-mapping ">
                <input  placeholder="Row Color" type="hidden" [(ngModel)]="localSelected.rowColor" (ngModelChange)="fieldEdited() " readonly />
                <input  matInput placeholder="Row Color" type="text"  value="White" readonly />
                <mat-icon matSuffix (click)="openColorPickerDialog() " style="cursor: pointer ">mode_edit</mat-icon>
            </mat-form-field>
            <mat-form-field *ngIf="localSelected.rowColor === 'F1CD7B' || localSelected.rowColor === 'Orange'" class="form-field-row-Mapping-mapping ">
                <input  placeholder="Row Color" type="hidden" [(ngModel)]="localSelected.rowColor" (ngModelChange)="fieldEdited() " readonly />
                <input  matInput placeholder="Row Color" type="text"  value="Orange" readonly />
                <mat-icon matSuffix (click)="openColorPickerDialog() " style="cursor: pointer ">mode_edit</mat-icon>
            </mat-form-field>
            <mat-form-field *ngIf="localSelected.rowColor === '97D3E7'|| localSelected.rowColor === 'Blue'" class="form-field-row-Mapping-mapping ">
                <input  placeholder="Row Color" type="hidden" [(ngModel)]="localSelected.rowColor" (ngModelChange)="fieldEdited() " readonly />
                <input  matInput placeholder="Row Color" type="text"  value="Blue" readonly />
                <mat-icon matSuffix (click)="openColorPickerDialog() " style="cursor: pointer ">mode_edit</mat-icon>
            </mat-form-field>
            <mat-form-field *ngIf="localSelected.rowColor === 'D1D1D1' || localSelected.rowColor === 'Grey (Review List)'" class="form-field-row-Mapping-mapping ">
                <input  placeholder="Row Color" type="hidden" [(ngModel)]="localSelected.rowColor" (ngModelChange)="fieldEdited() " readonly />
                <input  matInput placeholder="Row Color" type="text"  value="Grey (Review List)" readonly />
                <mat-icon matSuffix (click)="openColorPickerDialog() " style="cursor: pointer ">mode_edit</mat-icon>
            </mat-form-field>
            <mat-form-field *ngIf="localSelected.rowColor === 'BAB4F1'|| localSelected.rowColor === 'Purple'" class="form-field-row-Mapping-mapping ">
                <input  placeholder="Row Color" type="hidden" [(ngModel)]="localSelected.rowColor" (ngModelChange)="fieldEdited() " readonly />
                <input  matInput placeholder="Row Color" type="text"  value="Purple" readonly />

                <mat-icon matSuffix (click)="openColorPickerDialog() " style="cursor: pointer ">mode_edit</mat-icon>
            </mat-form-field>
    <mat-form-field class="form-field-displayMapping-mapping">
      <input
        class="input-length"
        matInput
        placeholder="Field Default"
        [(ngModel)]="localSelected.fieldDefault"
        (ngModelChange)="fieldEdited()"
      />
    </mat-form-field>
    <mat-form-field class="form-field-displayMapping-mapping">
      <input
        type="number"
        class="input-length"
        matInput
        placeholder="Order"
        [(ngModel)]="localSelected.order"
        (ngModelChange)="fieldEdited()"
      />
    </mat-form-field>
  </div>
  <mat-checkbox
    class="checkBoxSpacing"
    [(ngModel)]="localSelected.required"
    (ngModelChange)="fieldEdited()"
    >Required
  </mat-checkbox>
  <mat-checkbox
    class="checkBoxSpacing"
    [(ngModel)]="localSelected.display"
    (ngModelChange)="fieldEdited()"
    >Display
  </mat-checkbox>
</div>
