<div style="display: grid; grid-template-rows: 1fr 1fr; width: 100%">
    <div class="background-container">
        <div class="content-container">
            <div class="title-icon-container">
                <mat-icon
                    style=" width:42px;height:38px;font-size:32px;background-color:#3fb2c6;border-radius:5px;color:white;text-align:center;padding-top: 3px;">
                    search</mat-icon>
                <h1 style="font-size: 20pt; margin-left: 10px; position: relative; top: 5px">
                    Search Borrower
                </h1>
            </div>
            <div style="
                display: grid;
                grid-template-rows: 1fr 1fr 1fr;
                position: relative;
                top: 30px;
            ">
                <div style="
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    column-gap: 80px;
                ">
                    <mat-form-field class="form-field-two">
                        <input autocomplete="off" style="background-color: #f1f1f1" matInput placeholder="First Name"
                            [formControl]="firstNameFormControl" [errorStateMatcher]="matcher"
                            [(ngModel)]="customerLookupParams.customerBase.name.firstName" (ngModelChange)="checkForm()"
                            (keydown.enter)="searchUsers()" />
                        <mat-error *ngIf="firstNameFormControl.hasError('minlength')">
                            Length of 2 is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="form-field-threee">
                        <input autocomplete="off" style="background-color: #f1f1f1" matInput placeholder="Last Name"
                            [formControl]="lastNameFormControl" [errorStateMatcher]="matcher"
                            [(ngModel)]="customerLookupParams.customerBase.name.lastName" (ngModelChange)="checkForm()"
                            (keydown.enter)="searchUsers()" />
                        <mat-error *ngIf="lastNameFormControl.hasError('minlength')">
                            Length of 2 is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div style="
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 80px;
            padding-top: 10px;
          ">

                    <mat-form-field class="form-field-three">
                        <input autocomplete="off" style="background-color: #f1f1f1" matInput placeholder="TaxID"
                            [formControl]="taxIDFormControl" [errorStateMatcher]="matcher"
                            [(ngModel)]="customerLookupParams.customerBase.taxID" (ngModelChange)="checkForm()"
                            (keydown.enter)="searchUsers()" />
                        <mat-error *ngIf="taxIDFormControl.hasError('minlength')">
                            Length of 4 is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="form-field-one">
                        <input autocomplete="off" style="background-color: #f1f1f1" matInput placeholder="CIF"
                            [formControl]="cifFormControl" [errorStateMatcher]="matcher"
                            [(ngModel)]="customerLookupParams.customerBase.customerID" (ngModelChange)="checkForm()"
                            (keydown.enter)="searchUsers()" />
                        <mat-error *ngIf="cifFormControl.hasError('minlength')">
                            Length of 2 is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="button-container">
                    <button class="clear-button" mat-raised-button (click)="clear()">
                        Clear
                    </button>
                    <button class="search-button" mat-raised-button (click)="searchUsers()"
                        [disabled]="isSearchDisabled">
                        Search
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div style="background-color: white">
        <!--Core Table-->
        <div class="loan-table">
            <table mat-table #table [dataSource]="dataSource" matSort>
                <!-- Name Column -->
                <ng-container matColumnDef="borrowerName">
                    <th class="header-text" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Name
                    </th>
                    <div>
                        <td mat-cell *matCellDef="let element">
                            {{ element.customerBase.name.firstName }} {{ element.customerBase.name.lastName }}
                        </td>
                    </div>
                </ng-container>

                <!-- Account # Column -->
                <ng-container matColumnDef="taxId">
                    <th class="header-text" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Tax ID
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.customerBase.taxID }}</td>
                </ng-container>

                <!-- CIF Column -->
                <ng-container matColumnDef="cif">
                    <th class="header-text" mat-header-cell *matHeaderCellDef mat-sort-header>
                        CIF
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.customerBase.customerID }}</td>
                </ng-container>

                <!-- customerBase.assignedBorrowerType Column -->
                <ng-container matColumnDef="customerBase.assignedBorrowerType">
                    <th class="header-text" mat-header-cell *matHeaderCellDef mat-sort-header>

                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div style="width: 75%; float: left;" class="arrow-container">
                            <div style="width: 100%;">
                                <input type="search" style="background: none;" placeholder="Assign Borrower Type"
                                    matInput #valueField [(ngModel)]="element.customerBase.assignedBorrowerType"
                                    (ngModelChange)="selectLoan(element)" [matAutocomplete]="auto" />
                                <span class="drop-down-arrow"
                                    style="pointer-events: none; float: right;margin-top: -22px;height: 10px;">
                                    <mat-icon>keyboard_arrow_down</mat-icon>
                                </span>
                            </div>
                        </div>
                        <mat-autocomplete autoActiveFirstO33.0003ption #auto="matAutocomplete">
                            <mat-option *ngFor="let option of displayDropDown" [value]="option.value">
                                {{ option.view }}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-icon *ngIf="element.customerBase.assignedBorrowerType"
                            style="padding-left: 8%; font-size: 19px;" (click)="removeUser(element)">cancel
                        </mat-icon>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"
                    [ngClass]="{ 'selected-row': 'Borrower' === row.customerBase.assignedBorrowerType || 'Co-Borrower' === row.customerBase.assignedBorrowerType ? true : false }">
                </tr>
            </table>
        </div>
    </div>

    <!--Spinner-->
    <div class="spinner-container" *ngIf="showSpinner">
        <div class="new-spinner">
            <div class="outer-ring"></div>
            <div class="middle-ring"></div>
            <div class="inner-ring"></div>
        </div>
    </div>
</div>