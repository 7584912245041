import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetConfigurationComponent } from './set-configuration.component';
import { AuthGuard } from '../../../auth/auth.guard';
import { RouterModule, Routes } from '@angular/router';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { CCMCSharedModule } from '../../../../@ccmc/shared.module';
import { SetConfigurationColumnsComponent } from './set-configuration-columns/set-configuration-columns.component';
import { EditSetColumnDialogComponent } from 'src/@ccmc/components/edit-set-column-dialog/edit-set-column-dialog.component';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';

const routes: Routes = [
  {
    path: 'set-configuration',
    component: SetConfigurationComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
    // canDeactivate: [DeactivateMapping]
  }
];

@NgModule({
  declarations: [SetConfigurationComponent, SetConfigurationColumnsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CCMCSharedModule,
    CodemirrorModule,
    KeyboardShortcutsModule.forRoot()
  ],
  exports: [SetConfigurationComponent, SetConfigurationColumnsComponent],
  entryComponents: [EditSetColumnDialogComponent]
  // providers: [DeactivateMapping]
})
export class SetConfigurationModule {}
