import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GeneralLedgerAccountingService } from '../../services/general-ledger-accounting.service';

@Component({
  selector: 'app-edit-set-column-dialog',
  templateUrl: './edit-set-column-dialog.component.html',
  styleUrls: ['./edit-set-column-dialog.component.scss']
})
export class EditSetColumnDialogComponent implements OnInit {
  localColumn: any;
  targetLayoutSub: any;
  unsubscribe: Subject<any> = new Subject();
  targetLayout: any;
  constructor(
    public dialogRef: MatDialogRef<EditSetColumnDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public column: any,
    private glaService: GeneralLedgerAccountingService
  ) {}

  ngOnInit() {
    this.getTargetLayout();
    this.localColumn = JSON.parse(JSON.stringify(this.column));
    console.log(this.localColumn);
  }

  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  getTargetLayout() {
    this.targetLayoutSub = this.glaService.glaDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(glaDocument => {
        if (glaDocument) {
          this.targetLayout = glaDocument.coreLayout;
        }
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  save() {
    this.localColumn.edited = true;
    const jsonFields = Object.keys(this.localColumn);
    for (let field of jsonFields) {
      this.column[field] = this.localColumn[field];
    }
    console.log(this.column);
    this.dialogRef.close(true);
  }
}
