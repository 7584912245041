import { CanDeactivate } from '@angular/router';
import { ValidationComponent } from './validation.component';
import { Injectable } from "@angular/core";

/**
 * The Deactivate Validation guard is used to disable routing away from
 * validartion component when turned on
 *
 * @export
 * @class DeactivateValidation
 * @implements {CanDeactivate<CCMCMappingComponent>}
 */
@Injectable()
export class DeactivateValidation implements CanDeactivate<ValidationComponent> {

    /**
     * Returns mappings canDeactivate function that returns true or false
     *
     * @param {ValidationComponent} component
     * @returns
     * @memberof DeactivateValidation
     */
    async canDeactivate(component: ValidationComponent) {
        return component.canDeactivate();
    }
}
