import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

import { CCMCSharedModule } from '../../../@ccmc/shared.module';

import { CCMCFooterComponent } from '../../../app/main/footer/footer.component';

@NgModule({
    declarations: [
        CCMCFooterComponent
    ],
    imports     : [
        RouterModule,

        MatButtonModule,
        MatIconModule,
        MatToolbarModule,

        CCMCSharedModule
    ],
    exports     : [
        CCMCFooterComponent
    ]
})
export class CCMCFooterModule {
}
