import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { Asset } from 'src/app/main/content/users/users.component';
import { AdminApiService } from './admin-api.service';
import { AssetService } from './asset.service';
import { CcmcApiService } from './ccmc-api.service';
import { SpinnerService } from './spinner.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class RequestChangeService {
  assetID: string;
  changeRequestTemplate = {
    assetID: '',
    FI: '',
    statuses: [
      'Open Items (NXTsoft)',
      'Open Items (FI/Client/Institution)',
      'Items To Test',
      'Completed Items',
      'Custom Items'
    ],
    fields: []
  };
  assetFI: any;
  constructor(
    private ccmcApiService: CcmcApiService,
    private adminApiService: AdminApiService,
    private spinnerService: SpinnerService,
    private assetService: AssetService,
    private dialog: MatDialog,
  ) {}
  public changeRequestDoc: BehaviorSubject<any> = new BehaviorSubject({});

  public changeRequestDocStatuses: BehaviorSubject<any> = new BehaviorSubject({});

  public currentSelectedTab: BehaviorSubject<any> = new BehaviorSubject({});

  public editedLoanTypeField: BehaviorSubject<any> = new BehaviorSubject({});

  public changeRequestExport: BehaviorSubject<any> = new BehaviorSubject({});

  public onChangeRequestNewField: BehaviorSubject<any> = new BehaviorSubject(
    []
  );

  public onNewLoanTypeField: BehaviorSubject<any> = new BehaviorSubject(
    []
  );

  public onChangeRequestFields: BehaviorSubject<any> = new BehaviorSubject([]);

  public onChangeRequestStatuses: BehaviorSubject<any> = new BehaviorSubject(
    []
  );

  async getDynamoTable() {
    this.assetID = this.assetService.getSelectedAssetId();
    this.assetFI = this.assetService.getSelectedFI();
    this.adminApiService
      .getDynamoDBItemsByIndex('change_request_log', this.assetID, 'assetID')
      .subscribe(result => {
        console.log(result);
        const parsedResult = JSON.parse(JSON.stringify(result));
        if (parsedResult.statusFlag === true) {
          // console.log(result);
          this.changeRequestDoc.next(parsedResult.content.Items);
          this.spinnerService.setShowSpinner(false);
        } else {
          this.spinnerService.setShowSpinner(false);

          this.changeRequestTemplate.assetID = this.assetID;
          this.changeRequestTemplate.FI = this.assetFI;
          // this.postDynamoFieldValues(this.changeRequestTemplate);
        }
      });
    this.spinnerService.setShowSpinner(false);
  }

  async getChangeRequestDocStatuses() {
    this.adminApiService
   .getDynamoDBItem('change_request_log_configuration', this.assetID, 'assetID')
   .subscribe(result => {
        console.log(result);
        if (result) {
          // console.log(result);
          this.changeRequestDocStatuses.next(result);
          this.spinnerService.setShowSpinner(false);
        } else {
          // creating the missing default configurations for asset
          const newDefaultConfig = {
            assetID: this.assetID,
            FI: this.assetService.getSelectedFI(),
            loanTypes: [
            ],
            statuses: [
             "Open Items (NXTsoft)",
             "Open Items (FI/Client/Institution)",
             "Items To Test",
             "Completed Items",
             "Custom Items"
            ]
           }
          this.postDynamoChangeRequestStatuses(newDefaultConfig);
          this.changeRequestDocStatuses.next(newDefaultConfig);
          this.spinnerService.setShowSpinner(false);
        }
    })
}

  get changeRequestExportDoc() {
    return this.changeRequestExport.asObservable().pipe(
      map(changeRequestExport => {
        return changeRequestExport;
      })
    );
  }

  setChangeRequestExport(changeRequestExportDoc: any) {
    // console.log(changeRequestExportDoc);
    this.changeRequestExport.next(changeRequestExportDoc);
  }

  postDynamoFieldValues(document: any) {
    return new Promise(resolve => {
      this.ccmcApiService
        .createDynamoDB('prod', 'change_request_log', document)
        // .createDynamoDB('prod', 'change_requests', this.changeRequestTemplate) //use this line to reset a test
        .subscribe(result => {
          console.log(result);
          return resolve(result);
        });
    });
  }

  postDynamoChangeRequestStatuses(document: any) {
    this.ccmcApiService
    .createDynamoDB('prod', 'change_request_log_configuration', document)
    // .createDynamoDB('prod', 'change_requests', this.changeRequestTemplate) //use this line to reset a test
    .subscribe(result => {
      console.log(result);
    });
  }

  updateDocument(updatedItem: any, index: any) {

    const params = {
      tableName: 'change_request_log',
      primaryKey: this.assetID,
      primaryKeyAttribute: 'assetID',
      updatedItem: updatedItem,
      index: index
    }
    this.ccmcApiService.updateDynamoDBItem(params).subscribe(result => {
      const parsedResult = JSON.parse(JSON.stringify(result));
      if(parsedResult.statusMessage = false) {
        this.dialog.open(ErrorDialogComponent, {
          data: {
            title: 'Error',
            message: parsedResult.errorMessage
          }
        });
      }
    });
  }

  async getChangeRequestItem(assetID: any, changeRequestID: any) {
    return new Promise(resolve => {
      this.adminApiService
      .getChangeRequestDynamoDBItem('change_request_log', 'assetID', assetID, 'changeRequestID', changeRequestID)
      .subscribe(result => {
        if(result !== null) {
          return resolve(result);
        } else {
          this.changeRequestTemplate.assetID = this.assetID;
          this.changeRequestTemplate.FI = this.assetFI;
          // this.postDynamoFieldValues(this.changeRequestTemplate);
          return resolve(this.changeRequestTemplate);
        }
      });
    });
  }

  updateChangeRequestItem(document: any) {
    this.ccmcApiService
      .createDynamoDB('prod', 'change_request_log', document)
      // .createDynamoDB('prod', 'change_requests', this.changeRequestTemplate) //use this line to reset a test
      .subscribe(result => {
        console.log(result);
      });
  } 
}
