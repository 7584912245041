import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { AddValidationGroupDialogComponent } from './add-validation-group-dialog.component';

@NgModule({
  declarations: [AddValidationGroupDialogComponent],
  imports: [
    AppMaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule
  ],
  entryComponents: [AddValidationGroupDialogComponent]
})
export class AddValidationGroupDialogModule { }
