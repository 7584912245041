import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditSndGlobalMetasDialogComponent } from './edit-snd-global-metas-dialog.component';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [EditSndGlobalMetasDialogComponent],
  imports: [AppMaterialModule, CommonModule, FormsModule],
  entryComponents: [EditSndGlobalMetasDialogComponent]
})
export class EditSndGlobalMetasDialogModule {}
