<div class="editassetgroup">
    <div style="display: flex; justify-content: space-between">
        <h1>Edit User Assets</h1>
        <div *ngIf="selectedUser" style="position: relative; top: 15px; height: 50px">
            <button mat-raised-button matTooltip="Update user assets." matTooltipClass="toolTipStyle" color="accent" (click)="updateAssets()">
        Update Assets
      </button>
        </div>
    </div>

    <div>
        <mat-form-field style="width: 30%;" *ngIf="selectedUser">
            <input matInput placeholder="E-mail" [(ngModel)]="selectedUser.username">
        </mat-form-field>
    </div>
    <div *ngIf="selectedUser">
        <mat-form-field style="width: 100%;" *ngIf="!isNXTsoft && !isNXTsoftDev && !isNXTsoftOperations && !isNXTsoftSales">
            <mat-chip-list #chipList>
                <mat-chip *ngFor="let asset of assetsArray" [selectable]="selectable" [removable]="removable" (removed)="remove(asset)">
                    {{asset}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input placeholder="Asset(s)" #assetsInput [formControl]="assetsCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)" />
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedAssetOption($event)">
                <mat-option *ngFor="let asset of filteredAssets | async" [value]="asset">
                    {{ asset }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field style="width: 100%;" *ngIf="isNXTsoft || isNXTsoftDev || isNXTsoftOperations || isNXTsoftSales">
            <mat-chip-list #chipList>
                <mat-chip *ngFor="let asset of assetsArray" [selectable]="selectable" [removable]="removable" (removed)="remove(asset)">
                    {{asset}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input placeholder="Asset(s)" #assetsInput [formControl]="assetsCtrl" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)" />
            </mat-chip-list>
        </mat-form-field>
    </div>
</div>