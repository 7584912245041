import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CcmcApiService } from '../../services/ccmc-api.service';
import { FieldEditedService } from '../../services/field-edited.service';
import { GeneralLedgerAccountingService } from '../../services/general-ledger-accounting.service';
import { CCMCSelectedFieldService } from '../../services/selected-field.service';

@Component({
  selector: 'app-add-target-layout-column-dialog',
  templateUrl: './add-target-layout-column-dialog.component.html',
  styleUrls: ['./add-target-layout-column-dialog.component.scss']
})
export class AddTargetLayoutColumnDialogComponent implements OnInit, OnDestroy {
  unsubscribe: Subject<any> = new Subject();
  targetLayoutSub: Subscription;
  targetLayoutFields: any;
  newTargetLayoutColumn: any;
  targetLayout: any;
  targetLayoutTypes: any;
  constructor(
    private glaService: GeneralLedgerAccountingService,
    private selectedFieldService: CCMCSelectedFieldService,
    private dialogRef: MatDialogRef<AddTargetLayoutColumnDialogComponent>,
    private snackBar: MatSnackBar
  ) {}

  /**
   * Initialize the component
   *
   * @memberof AddTargetLayoutColumnDialogComponent
   */
  ngOnInit() {
    this.getData();
  }

  /**
   * Unusbscribe from observables on destroy
   *
   * @memberof AddTargetLayoutColumnDialogComponent
   */
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  /**
   * Get data needed for the add transactions component
   *
   * @memberof AddTargetLayoutColumnDialogComponent
   */
  getData() {
    this.targetLayoutSub = this.glaService.glaDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(document => {
        if (document) {
          if (document.coreLayout) {
            this.targetLayout = document.coreLayout;
            console.log(this.targetLayout);
            this.targetLayoutFields = Object.keys(document.coreLayout[0]);
            this.targetLayoutTypes = { ...this.targetLayoutFields };
            console.log(this.targetLayoutFields);
            for (let i = 0; i < this.targetLayoutFields.length; i++) {
              this.targetLayoutTypes[i] =
                typeof document.coreLayout[0][this.targetLayoutFields[i]];
            }
            this.newTargetLayoutColumn = {};
            for (let targetLayoutField of this.targetLayoutFields) {
              this.newTargetLayoutColumn[targetLayoutField] = '';
            }
            console.log(
              'New TargetLayout Column Initiated',
              this.newTargetLayoutColumn
            );
            this.newTargetLayoutColumn.edited = true;
          } else {
            this.targetLayoutFields = [];
          }
        }
      });
  }

  /**
   * Opens snackbar with msg and action
   *
   * @param {string} message
   * @param {string} action
   * @memberof AddTargetLayoutColumnDialogComponent
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000
    });
  }

  /**
   * Adds a transaction to the current transactions object
   *
   * @memberof AddTargetLayoutColumnDialogComponent
   */
  addTransaction() {
    // Check if form is acceptable
    if (this.checkForm()) {
      // If there are already transactions present
      if (this.targetLayout.length > 0) {
        // Checks if tempTransaction is already in the transactions object array
        if (
          !this.containsObject(this.newTargetLayoutColumn, this.targetLayout)
        ) {
          this.newTargetLayoutColumn.columnID =
            +this.newTargetLayoutColumn.columnID;
          this.newTargetLayoutColumn.displayOrder =
            +this.newTargetLayoutColumn.displayOrder;
          this.dialogRef.close(this.newTargetLayoutColumn);
        }
      } else {
        this.dialogRef.close(this.newTargetLayoutColumn);
      }
    }
  }

  /**
   * Checks if the form is valid
   *
   * @returns
   * @memberof AddTargetLayoutColumnDialogComponent
   */
  checkForm() {
    if (this.newTargetLayoutColumn.columnID.toString().length > 0) {
      if (this.newTargetLayoutColumn.displayOrder.toString().length > 0) {
        return true;
      } else {
        this.openSnackBar('Error: Display Order must not be blank.', 'Okay');
        return false;
      }
    } else {
      this.openSnackBar('Error: ColumnID must not be blank.', 'Okay');
      return false;
    }
  }

  /**
   * Checks if an obj is in the obj list
   *
   * @param {*} obj
   * @param {*} objList
   * @returns
   * @memberof AddTargetLayoutColumnDialogComponent
   */
  containsObject(obj: any, objList: any) {
    console.log(obj);
    console.log(objList);
    const columnIDIndex = objList.findIndex(
      (objIndex: any) => objIndex.columnID === +obj.columnID
    );
    const displayOrderIndex = objList.findIndex(
      (objIndex: any) => objIndex.displayOrder === +obj.displayOrder
    );
    console.log('column index', columnIDIndex);
    console.log('disply order index', displayOrderIndex);
    if (columnIDIndex > -1) {
      this.openSnackBar('Error: This ColumnID already exists', 'Okay');
      return true;
    }
    if (displayOrderIndex > -1) {
      this.openSnackBar('Error: This Display Order already exists', 'Okay');
      return true;
    }
    return false;
  }

  /**
   * Closes current dialog
   *
   * @memberof AddTargetLayoutColumnDialogComponent
   */
  closeDialog() {
    this.dialogRef.close();
  }
}
