import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CCMCMatchMediaService {
  activeMediaQuery: string;
  onMediaChange: BehaviorSubject<string> = new BehaviorSubject<string>('');

  /**
   * Creates an instance of CCMCMatchMediaService.
   * @param {MediaObserver} mediaObserver
   * @memberof CCMCMatchMediaService
   */
  constructor(private mediaObserver: MediaObserver) {
    this.activeMediaQuery = '';

    this.mediaObserver
      .asObservable()
      .pipe(map(changes => changes[0]))
      .subscribe((changes: MediaChange) => {
        if (this.activeMediaQuery !== changes.mqAlias) {
          this.activeMediaQuery = changes.mqAlias;
          this.onMediaChange.next(changes.mqAlias);
        }
      });
  }
}
