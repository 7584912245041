<div class="gla-translators-container">
  <!--filter Search section -->
  <div class="filterContainer">
    <mat-form-field class="filterField">
      <input matInput placeholder="Filter Search" #filterSearch />
    </mat-form-field>
  </div>
  <div class="table-container">
    <table
      class="table"
      mat-table
      #table
      [dataSource]="dataSource"
      CCMCPerfectScrollbar
      matSort
    >
      <ng-container matColumnDef="fieldName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>FieldName</th>
        <td
          mat-cell
          *matCellDef="let element"
          [matTooltipShowDelay]="showDelay.value"
          class="noselect"
          matTooltip="{{ element.fieldName }}"
          matTooltipClass="fullText"
          class="FieldName-col"
          style="width: 38%"
        >
          {{ element.fieldName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="evaluationMethod">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Evaluation Method
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="noselect"
          matTooltipClass="fullText"
          class="evaluationMethod-col"
          style="width: 3%"
        >
          {{ element.evaluationMethod }}
        </td>
      </ng-container>

      <ng-container matColumnDef="vbScript">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          style="width: 40%"
        >
          VBScript
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [matTooltipShowDelay]="showDelay.value"
          class="noselect"
          matTooltip="{{ element.vbScript }}"
          matTooltipClass="fullText"
          class="vbsrcipt-col"
        >
          {{ element.vbScript }}
        </td>
      </ng-container>

      <ng-container matColumnDef="edited">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          style="width: 5%; padding-left: 50px; padding-right: 81px"
        >
          Edited
          <div class="AddBtn-Container" style="padding-top: 0px">
            <button
              class="Add-btn"
              matTooltip="Add a New Translator"
              matTooltipClass="toolTipStyle"
              (click)="addGlaTranslator($event)"
            >
              <mat-icon style="color: #3fb2c6">add_circle</mat-icon>
            </button>
          </div>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          style="padding-left: 23px"
          class="noselect"
        >
          <mat-icon
            *ngIf="element.edited === true"
            class="editIcon"
            style="margin-left: 60px"
          >
            star</mat-icon
          >
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="onSelect(row)"
        [ngClass]="{ 'accent-50-bg': row == selected }"
      ></tr>
    </table>
    <!--paginator-->
    <div class="paginatorContainer">
      <mat-paginator
        [pageSizeOptions]="[100]"
        showFirstLastButtons
        class="paginator"
      ></mat-paginator>
    </div>
  </div>

  <!--TabBar-->
  <div class="tabBar">
    <div class="remove-button-container">
      <button
        class="remove-button"
        matTooltip="Remove Selected Translator"
        matTooltipClass="toolTipStyle"
        color="primary"
        (click)="removeTranslator()"
      >
        <mat-icon style="color: #7570d2">remove_circle</mat-icon>&nbsp;
      </button>
    </div>
    <mat-tab-group>
      <mat-tab label="Translators">
        <div *ngIf="translators && translators.length !== 0">
          <app-gla-translators-translators></app-gla-translators-translators>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<div class="spinner-container" *ngIf="showSpinner">
  <div class="new-spinner">
    <div style="position: relative; bottom: 100px" class="outer-ring"></div>
    <div
      style="position: relative; bottom: 693px;"
      class="middle-ring"
    ></div>
    <div
      style="position: relative; bottom: 1298px"
      class="inner-ring"
    ></div>
  </div>
</div>

<!-- <ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts> -->
