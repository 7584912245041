import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { BehaviorSubject } from 'rxjs';
import { SpinnerService } from './spinner.service';
import { GCSettingsService } from './gcsettings.service';
import { APPCONSTANTS } from '../../app/app.constants';
import { AssetService } from './asset.service';
import { ActiveCoreService } from './active-core.service';
import { CCMCSelectedFieldService } from './selected-field.service';
import { CcmcApiService } from './ccmc-api.service';
import { String } from 'aws-sdk/clients/appstream';
import { environment } from '../../environments/environment';
const { Buffer } = require('buffer');

@Injectable({
  providedIn: 'root'
})
export class AdminApiService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  /**
   * Backup document to restore to
   *
   * @memberof AdminApiService
   */
  documentBackup: any;

  /**
   * Core Settings
   *
   * @type {*}
   * @memberof AdminApiService
   */
  coreSettings: any;

  /**
   * Configurations
   *
   * @type {*}
   * @memberof AdminApiService
   */
  configurations: any;

  /**
   * Mapping
   *
   * @type {*}
   * @memberof AdminApiService
   */
  mapping: any;

  /**
   * Support Values
   *
   * @type {*}
   * @memberof AdminApiService
   */
  supportValues: any;

  /**
   * Document Core Behavior Subject
   *
   * @private
   * @type {BehaviorSubject<any>}
   * @memberof AdminApiService
   */
  private _documentCore: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  /**
   * Core Table
   *
   * @type {*}
   * @memberof AdminApiService
   */
  coreTable: any;

  /**
   * Users subject
   *
   * @private
   * @type {BehaviorSubject<any>}
   * @memberof AdminApiService
   */
  private _users: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  /**
   * Refresh Users
   *
   * @private
   * @type {BehaviorSubject<any>}
   * @memberof AdminApiService
   */
  private _refreshUsers: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  cluster: any;

  /**
   * Creates an instance of AdminApiService.
   * @param {HttpClient} http
   * @param {SpinnerService} spinnerService
   * @param {CustomErrorHandlerService} customErrorHandlerService
   * @param {GCSettingsService} gcSettingService
   * @param {AssetService} assetService
   * @param {ActiveCoreService} activeCoreService
   * @param {CCMCSelectedFieldService} selectedFieldService
   * @memberof AdminApiService
   */
  constructor(
    private http: HttpClient,
    private spinnerService: SpinnerService,
    private customErrorHandlerService: CustomErrorHandlerService,
    private gcSettingService: GCSettingsService,
    private assetService: AssetService,
    private activeCoreService: ActiveCoreService,
    private selectedFieldService: CCMCSelectedFieldService,
    private ccmcApiService: CcmcApiService
  ) {
    this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, PUT'
    );
    this.httpOptions.headers.append('Accept', 'application/json');
  }

  /**
   * Document Core
   *
   * @readonly
   * @memberof AdminApiService
   */
  get documentCore() {
    return this._documentCore.asObservable().pipe(
      map(document => {
        return document;
      })
    );
  }

  /**
   * Users
   *
   * @readonly
   * @memberof AdminApiService
   */
  get users() {
    return this._users.asObservable().pipe(
      map(users => {
        return users;
      })
    );
  }

  /**
   * Refresh Users
   *
   * @readonly
   * @memberof AdminApiService
   */
  get refreshUsers() {
    return this._refreshUsers.asObservable().pipe(
      map(users => {
        return users;
      })
    );
  }

  // Gets the documentcore from couchbase
  /**
   * Get Document Core
   * @description Get the document
   * @returns
   * @memberof AdminApiService
   */
  getDocumentCore(cluster: any) {
    this.cluster = cluster;
    const assetID = this.assetService.getSelectedAssetId();
    const bucket = this.activeCoreService.activeCore;
    if (cluster === undefined) {
      return;
    }
    const body = {
      cluster: cluster,
      assetID: assetID,
      bucket: bucket
    };
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/couchbase/get-document`,
        body,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  getMappingDocument(connectionInformation: any) {
    const cluster = connectionInformation.couchbaseConnectionString;
    const assetID = this.assetService.getSelectedAssetId();
    const bucket = connectionInformation.bucket;
    if (cluster === undefined) {
      return;
    }
    const body = {
      cluster: cluster,
      assetID: assetID,
      bucket: bucket
    };
    console.log(JSON.stringify(body));
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/couchbase/get-document`,
        body,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * Get DynamoDB
   * @description Retrieve DB with given table name
   * @param {string} tableName
   * @returns
   * @memberof AdminApiService
   */
  getDynamoDB(tableName: string) {
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/dynamodb/get-table`,
          { tableName: tableName },
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }

  getCoreConnection(core: any) {
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/dynamodb/get-core-connection`,
          { core: core },
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }

  getLosConnection(los: any) {
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/dynamodb/get-los-connection`,
          { los: los },
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }

  getDynamoDBItem(
    tableName: string,
    primaryKey: string,
    primaryKeyAttribute: string
  ) {
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/dynamodb/get-document`,
          {
            tableName: tableName,
            primaryKeyAttribute: primaryKeyAttribute,
            primaryKey: primaryKey
          },
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }

  getChangeRequestDynamoDBItem(
    tableName: string,
    primaryKeyAttribute: string,
    primaryKey: string,
    sortKeyAttribute: string,
    sortKey: string 
    ) {
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/dynamodb/get-document`,
          {
            tableName: tableName,
            primaryKeyAttribute: primaryKeyAttribute,
            primaryKey: primaryKey,
            sortKeyAttribute: sortKeyAttribute,
            sortKey: sortKey
          },
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }

  getDynamoDBItemsByIndex(
    tableName: string,
    primaryKey: string,
    primaryKeyAttribute: string
  ) {
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/dynamodb/get-documents-by-index`,
          {
            tableName: tableName,
            primaryKeyAttribute: primaryKeyAttribute,
            primaryKey: primaryKey
          },
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }

  /**
   * Get Cores
   * @description Gets cores list from dynamoDB
   * @memberof AdminApiService
   */
  getCores() {
    this.getDynamoDB('core_connection_data').subscribe(result => {
      this.coreTable = JSON.parse(JSON.stringify(result));
    });
  }

  /**
   * Set Document Core Simple
   * @description Simple set for documentCore, doesnt do the edited flag
   * @param {*} documentCore
   * @memberof AdminApiService
   */
  setDocumentCoreSimple(documentCore: any) {
    if (documentCore.supportValues) {
      this.selectedFieldService.onSupportValueFieldSelected.next(
        documentCore.supportValues[0]
      );
    }
    this._documentCore.next(documentCore);
  }

  /**
   * Set Document Core Data
   * @description Set the documentCore object
   * @param {*} documentCore
   * @memberof AdminApiService
   */
  setDocumentCoreData(documentCore: any) {
    if (documentCore.mapping) {
      for (let i = 0; i < documentCore.mapping.length; i++) {
        documentCore.mapping[i].edited = false;
      }
      this.selectedFieldService.onMappingFieldSelected.next(
        documentCore.mapping[0]
      );
    } else {
      this.selectedFieldService.onMappingFieldSelected.next({});
    }

    if (documentCore.supportValues) {
      for (let i = 0; i < documentCore.supportValues.length; i++) {
        // Add edited flag to support values
        documentCore.supportValues[i].edited = false;
      }
      // set field 1 as the currently selected supportValue
      this.selectedFieldService.onSupportValueFieldSelected.next(
        documentCore.supportValues[0]
      );
      // no support values for this document
    } else {
      this.selectedFieldService.onSupportValueFieldSelected.next({});
    }
    // set document backup
    this.documentBackup = JSON.parse(JSON.stringify(documentCore));
    // set behavior subject
    this._documentCore.next(documentCore);
  }

  /**
   * Create User
   * @description Creates a new cognito user
   * @param {*} params
   * @returns
   * @memberof AdminApiService
   */
  createUser(params: any) {
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/cognito/signup`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * Add Asset To User
   * @description Add asset to a given user
   * @param {string} user
   * @param {string} asset
   * @returns
   * @memberof AdminApiService
   */
  addAssetToUser(user: string, asset: string) {
    const email = user.toLowerCase();
    const assetIDs = this.assetService.getAssetIds();
    const splitAsset = asset.split(',');
    console.log(splitAsset);
    console.log(assetIDs);
    // Split assets into an array
    for (let i = 0; i < splitAsset.length; i++) {
      assetIDs.push(splitAsset[i]);
    }
    console.log(JSON.stringify(assetIDs));
    // allow nly unique assets
    const uniqueAssets = assetIDs.filter(function (elem: any, index: any, self: any) {
      return index === self.indexOf(elem);
    });
    // create new assetIDs strng
    let assetIDsString = uniqueAssets[0];
    for (let i = 1; i < uniqueAssets.length; i++) {
      assetIDsString = assetIDsString + ', ' + uniqueAssets[i];
    }
    console.log(assetIDsString);
    // init params for update user attributes call
    const params = {
      email: email,
      userAttributes: [
        {
          Name: 'custom:AssetID',
          Value: assetIDsString
        }
      ]
    };
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/cognito/update-user-attributes`,
          params,
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }

  /**
   * Set Users
   * @description sets the current user logged in
   * @param {*} users
   * @memberof AdminApiService
   */
  setUsers(users: any) {
    this._users.next(users);
  }

  /**
   * Update Assets
   * @description Update user assets
   * @param {*} username
   * @param {*} assets
   * @returns
   * @memberof AdminApiService
   */
  updateAssets(username: any, assets: any) {
    const email = username;
    console.log(email);
    let assetIDsString = assets[0];
    for (let i = 1; i < assets.length; i++) {
      assetIDsString = assetIDsString + ', ' + assets[i];
    }
    const params = {
      email: email,
      userAttributes: [
        {
          Name: 'custom:AssetID',
          Value: assetIDsString
        }
      ]
    };
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/cognito/update-user-attributes`,
          params,
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }

  /**
   * Get Users with Asset
   * @description Find users with current assetID
   * @param {*} assetID
   * @returns
   * @memberof AdminApiService
   */
  getUsersWithAsset(assetID: any) {
    const params = {
      assetID
    };
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/cognito/get-users`,
          params,
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }

  /**
   * Create Document Core
   * @description Create documentCore call to uploan new document versions
   * @returns
   * @memberof AdminApiService
   */
  createDocumentCore(coreConnectionData: any) {
    const assetID = this.assetService.getSelectedAssetId();
    // Get currentDoc
    const currentDoc = this._documentCore.getValue();
    // Parse document and create new backup
    this.documentBackup = JSON.parse(JSON.stringify(currentDoc));
    let tempDocumentArray: any[] = [];
    // Removes edited from conditions object
    currentDoc.conditions.forEach(function (item: any) {
      const tempItem = Object.assign({}, item);
      delete tempItem.edited;
      tempDocumentArray.push(tempItem);
    });
    currentDoc.conditions = tempDocumentArray;
    tempDocumentArray = [];
    // Removes edited from mapping object
    currentDoc.mapping.forEach(function (item: any) {
      const tempItem = Object.assign({}, item);
      delete tempItem.edited;
      delete tempItem.transactionName;
      tempDocumentArray.push(tempItem);
    });
    // Removes edited from validation in mapping
    for (let i = 0; i < tempDocumentArray.length; i++) {
      if (tempDocumentArray[i].validation) {
        for (let j = 0; j < tempDocumentArray[i].validation.length; j++) {
          delete tempDocumentArray[i].validation[j].edited;
        }
      }
    }
    // Set currentdoc mappinggit
    currentDoc.mapping = tempDocumentArray;
    tempDocumentArray = [];
    // Removes edited from supportValues object
    currentDoc.supportValues.forEach(function (item: any) {
      const tempItem = Object.assign({}, item);
      delete tempItem.edited;
      tempDocumentArray.push(tempItem);
    });
    currentDoc.supportValues = tempDocumentArray;
    tempDocumentArray = [];
    // Removes edited from transactions object
    currentDoc.transactions.forEach(function (item: any) {
      const tempItem = Object.assign({}, item);
      delete tempItem.edited;
      tempDocumentArray.push(tempItem);
    });
    currentDoc.transactions = tempDocumentArray;
    const body = {
      cluster: coreConnectionData.couchbaseConnectionString,
      assetID: assetID,
      bucket: coreConnectionData.bucket,
      document: { ...currentDoc }
    };
    console.log(body);
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/couchbase/create-document`,
        body,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * Add User To User Group
   * @description Adds given user to given usergroup
   * @param {*} username
   * @param {*} usergroup
   * @returns
   * @memberof AdminApiService
   */
  addUsertoGroup(username: any, usergroup: any) {
    const params = {
      username,
      usergroup
    };
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/cognito/add-user-to-group`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  removeUserFromGroup(username: any, groups: any) {
    const params = {
      username,
      groups
    };
    console.log(params);
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/cognito/remove-user-from-group`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  readEBMLogs(loggingParams: any) {
    console.log(loggingParams);
    const assetID = this.assetService.getSelectedAssetId();
    const params = {
      logGroupName: `ebm-logging` /* required */,
      filterPattern: '{$.assetId = ' + assetID + '}',
      startTime: loggingParams.startDate,
      endTime: loggingParams.endDate
    };
    console.log(params);
    // Call read logging service here
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/ground-control/read-ebm-logs`,
          params,
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }

  readEBMReports(loggingParams: any) {
    console.log(loggingParams);
    const assetID = this.assetService.getSelectedAssetId();
    const params = {
      logGroupName: `ebm-report` /* required */,
      filterPattern: '{$.assetId = ' + assetID + '}',
      startTime: loggingParams.startDate,
      endTime: loggingParams.endDate
    };
    console.log(params);
    // Call read logging service here
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/ground-control/read-ebm-logs`,
          params,
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }

  /**
   * Calls refresh Users
   *
   * @memberof AdminApiService
   */
  callRefreshUsers() {
    this._refreshUsers.next(true);
  }

  /**
   * updates the modifiedByUser and dateModified fields on the asset table
   *
   * @memberof AdminApiService
   */
  updateModifiedBy(username: any) {
    return new Promise(resolve => {
      const assetID = this.assetService.getSelectedAssetId();
      this.getDynamoDBItem('assets', assetID, 'assetId').subscribe(
        (dynamoDBResult: any) => {
          console.log(dynamoDBResult);
          dynamoDBResult['modifiedByUser'] = username;
          dynamoDBResult['dateModified'] = new Date();
          this.ccmcApiService
            .createDynamoDB('prod', 'assets', dynamoDBResult)
            .subscribe(result => {
              console.log(result);
              resolve(true);
            });
        }
      );
    });
  }

  async getLosConectionData() {
    return new Promise(resolve => {
      let los = this.assetService.getSelectedLos();
      this.getLosConnection(los).subscribe(result => {
        console.log(result);
        const parsedLosResult = JSON.parse(JSON.stringify(result));
        if (parsedLosResult.statusFlag == undefined) {
          const losConnectionResponse = {
            statusFlag: true,
            statusMessage: 'Successfully retrieved los connection data',
            content: parsedLosResult
          };
          return resolve(losConnectionResponse);
        } else {
          return resolve(parsedLosResult);
        }
      });
    });
  }

  async getCoreConnectionData() {
    return new Promise(resolve => {
      let core = this.assetService.getSelectedCore();
      this.getCoreConnection(core).subscribe(result => {
        console.log(result);
        const parsedCoreResult = JSON.parse(JSON.stringify(result));
        if (parsedCoreResult.statusFlag == undefined) {
          const coreConnectionResponse = {
            statusFlag: true,
            statusMessage: 'Successfully retrieved core connection data',
            content: parsedCoreResult
          };
          return resolve(coreConnectionResponse);
        } else {
          return resolve(parsedCoreResult);
        }
      });
    });
  }

  setBackupDocument() {
    this.documentBackup = JSON.parse(JSON.stringify(this._documentCore.getValue()));
  }
}
