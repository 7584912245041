import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DocumentConnectorService } from 'src/@ccmc/services/doc-connector.service';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { AddGlobalMetaDialogComponent } from '../add-global-meta-dialog/add-global-meta-dialog.component';
import { CCMCConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { GetPathDialogComponent } from '../get-path-dialog/get-path-dialog.component';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';

@Component({
  selector: 'app-edit-snd-global-metas-dialog',
  templateUrl: './edit-snd-global-metas-dialog.component.html',
  styleUrls: ['./edit-snd-global-metas-dialog.component.scss']
})
export class EditSndGlobalMetasDialogComponent implements OnInit {
  tempGlobalMeta: any;
  tempDocument: any;
  dcDocumentSub: Subscription;
  globalMetas: any;
  destinations: any;
  unsubscribe: Subject<any> = new Subject();
  sndGlobalMeta;
  localSNDGlobalMeta: any;
  currentDestination;
  editedFlag = false;
  @ViewChild('nameInput', { static: true }) nameInputEl: ElementRef;
  @ViewChild('indexInput', { static: true }) indexInputEl: ElementRef;
  @ViewChild('valueInput', { static: true }) valueInputEl: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    private dcService: DocumentConnectorService,
    private selectedFieldService: CCMCSelectedFieldService,
    public dialogRef: MatDialogRef<EditSndGlobalMetasDialogComponent>,
    private fieldEditedService: FieldEditedService,
    private snackBarService: SnackbarService,
    private dialog: MatDialog
  ) {
    this.sndGlobalMeta = inputData.globalMeta;
    this.currentDestination = inputData.destination;
  }

  ngOnInit(): void {
    this.localSNDGlobalMeta = JSON.parse(JSON.stringify(this.sndGlobalMeta));
    this.getData();
  }

  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  getData() {
    // Subscribe to the document and grab the support values
    this.dcDocumentSub = this.dcService.dcDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(document => {
        if (document) {
          this.tempDocument = document;
          this.globalMetas = document.globalMetas;
          this.destinations = document.destinations;
          // console.log(this.supportValuesObject);
          this.tempGlobalMeta = {
            name: '',
            index: '',
            value: '',
            path: ''
          };
        }
      });
  }

  async saveGlobalMeta() {
    if (this.checkForDuplicates()) {
      return;
    }
    const checkCorrectMetaIndexResponse =
      await this.checkCorrectGlobalMetaIndexs();
    if (checkCorrectMetaIndexResponse) {
      const confirmDialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
        data: `Duplicate index found, do you want to renumber your Global Metas starting at ${
          this.localSNDGlobalMeta.index + 1
        }?`
      });
      confirmDialogRef.afterClosed().subscribe(data => {
        if (data) {
          // Reorder global metas here
          this.fieldEditedService.losEdited.next(true);
          this.sndGlobalMeta.name = this.localSNDGlobalMeta.name;
          this.sndGlobalMeta.index = this.localSNDGlobalMeta.index;
          this.sndGlobalMeta.value = this.localSNDGlobalMeta.value;
          this.sndGlobalMeta.path = this.localSNDGlobalMeta.path;
          this.editedFlag = false;
          this.currentDestination.edited = true;
          this.reorderGlobalMetas();
          const successDialogRef = this.dialog.open(SuccessDialogComponent, {
            data: {
              title: 'Success',
              message: 'Successfully renumbered Global Metas'
            }
          });
          successDialogRef.afterClosed().subscribe(data2 => {
            this.dialogRef.close('edited');
          });
        }
      });
    } else {
      this.fieldEditedService.losEdited.next(true);
      this.sndGlobalMeta.name = this.localSNDGlobalMeta.name;
      this.sndGlobalMeta.index = this.localSNDGlobalMeta.index;
      this.sndGlobalMeta.value = this.localSNDGlobalMeta.value;
      this.sndGlobalMeta.path = this.localSNDGlobalMeta.path;
      this.editedFlag = false;
      this.currentDestination.edited = true;
      this.dialogRef.close('edited');
    }
  }

  checkForDuplicates() {
    for (let tempMeta of this.currentDestination.metas) {
      if (
        this.localSNDGlobalMeta.index === tempMeta.index &&
        this.sndGlobalMeta !== tempMeta
      ) {
        const errorDialogRef = this.dialog.open(ErrorDialogComponent, {
          data: {
            title: 'Duplicates',
            message: 'Duplicate index found.'
          }
        });
        return true;
      }
    }
    return false;
  }

  async checkCorrectGlobalMetaIndexs() {
    // prettier-ignore
    return new Promise((resolve) => {
      console.log('Global Metas', this.globalMetas);
      for (let globalMeta of this.globalMetas) {
        if (globalMeta.index === this.localSNDGlobalMeta.index) {
          resolve(true);
        }
      }
      resolve(false);
    });
  }

  removeSNDGlobalMeta() {
    const confirmDialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
      data: 'Are you sure you want to remove this meta?'
    });
    confirmDialogRef.afterClosed().subscribe(data => {
      if (data) {
        console.log('Local SND Global Meta', this.localSNDGlobalMeta);
        this.currentDestination.metas = this.currentDestination.metas.filter(
          (meta: any) =>
            !(
              meta.index == this.localSNDGlobalMeta.index &&
              meta.name == this.localSNDGlobalMeta.name
            )
        );
        this.currentDestination.edited = true;
        console.log('Current Destination Metas', this.currentDestination.metas);
        this.fieldEditedService.losEdited.next(true);
        this.dialogRef.close('removed');
      }
    });
  }

  reorderGlobalMetas() {
    let startingGlobalMetaIndex = 0;
    for (let globalMeta of this.currentDestination.metas) {
      if (globalMeta.index >= startingGlobalMetaIndex) {
        startingGlobalMetaIndex = globalMeta.index;
      }
    }
    // Handle case if new snd meta index is greater than the starting reorder index
    if (startingGlobalMetaIndex < this.localSNDGlobalMeta.index) {
      startingGlobalMetaIndex = this.localSNDGlobalMeta.index;
    }
    startingGlobalMetaIndex++;
    console.log('Max Index found in destination', startingGlobalMetaIndex);
    // loop through global metas and update their index
    const tempGlobalMetas = this.globalMetas.sort(function (a: any, b: any) {
      return a.index - b.index;
    });
    for (let [index, tempGlobalMeta] of tempGlobalMetas.entries()) {
      tempGlobalMeta.index = startingGlobalMetaIndex + index;
    }
    console.log('New Global Metas', tempGlobalMetas);
  }

  getEncompassPath() {
    const pathDialogRef = this.dialog.open(GetPathDialogComponent);
    pathDialogRef.afterClosed().subscribe(result => {
      if (result) {
        let tempResult = result.data;
        this.localSNDGlobalMeta.path = tempResult.replace('~#~', '0');
      }
    });
  }
}
