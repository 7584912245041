import { NgModule } from '@angular/core';
import { CCMCForgotPasswordComponent } from './forgot-password.component';
import { Routes, RouterModule } from '@angular/router';
import { CCMCSharedModule } from '../../../../@ccmc/shared.module';

const routes: Routes = [
  {
    path: 'forgot-password',
    component: CCMCForgotPasswordComponent
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes), CCMCSharedModule],
  declarations: [CCMCForgotPasswordComponent],
  exports: [CCMCForgotPasswordComponent]
})
export class CCMCForgotPasswordModule {}
