import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  NgZone
} from '@angular/core';
import { SpinnerService } from '../../../../@ccmc/services/spinner.service';
import { Subscription, Subject, fromEvent } from 'rxjs';
import {
  takeUntil,
  map,
  debounceTime,
  distinctUntilChanged
} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { AddUserPopupDialogComponent } from 'src/@ccmc/components/add-user-popup-dialog/add-user-popup-dialog.component';
import { EditUserPopupDialogComponent } from 'src/@ccmc/components/edit-user-popup-dialog/edit-user-popup-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { CCMCConfigService } from 'src/@ccmc/services/config.service';
import { AddNewUserDialogComponent } from 'src/@ccmc/components/add-new-user-dialog/add-new-user-dialog.component';
import { AddAssetToUserDialogComponent } from 'src/@ccmc/components/add-asset-to-user-dialog/add-asset-to-user-dialog.component';
import { AddUsergroupComponent } from 'src/@ccmc/components/add-usergroup/add-usergroup.component';
import { EditAssetDialogComponent } from 'src/@ccmc/components/edit-asset-dialog/edit-asset-dialog.component';

export interface Asset {
  id: string;
}

/**
 * This component lets you handle users
 *
 * @export
 * @class UsersComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
  showSpinner: boolean;
  private spinnerSub: Subscription;
  unsubscribe: Subject<any> = new Subject();
  displayedColumns = ['mfaEnabled', 'username', 'status', 'assets', 'edit'];
  dataSource: any;
  users: any;
  selectedUser: any;
  isUserNXTsoft: boolean;
  private usersSub: Subscription;
  refreshUsersSub: Subscription;
  filterSearch: string;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filterSearch', { static: true }) filterSearchEl: ElementRef;
  private selectedUserSub: Subscription;
  assetID: any;
  isNXTsoftDev: any;
  isNXTsoftOperations: any;
  assetUsers: any;
  showAdmin: any = false;
  constructor(
    private matDialog: MatDialog,
    private spinnerService: SpinnerService,
    private adminApiService: AdminApiService,
    private dialog: MatDialog,
    private ccmcApiService: CcmcApiService,
    private ccmcConfig: CCMCConfigService,
    private zone: NgZone,
    private snackBar: MatSnackBar,
    private assetService: AssetService,
    private selectedFieldService: CCMCSelectedFieldService,
    private amplifyService: AmplifyService
  ) {}

  /**
   * On InitgetAccountIDAssets
   *
   * @memberof UsersComponent
   */
  ngOnInit() {
    this.spinnerService.setShowSpinner(true);
    this.ccmcApiService.setUsers(undefined);
    // Sets if user is nxtsoft or not
    this.getAccountIDAssets();
    this.getIfNxtsoft();
    this.hideNavigation();

    this.initFilterSearch();
    this.assetID = this.assetService.getSelectedAssetId();
    console.log('finished start');
    // Subscribe to spinner
    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        // console.log(spinner);
        if (this.matDialog.openDialogs.length === 0) {
          this.showSpinner = spinner;
        }
      });

    if (this.isUserNXTsoft) {
      this.assetID = '';
    }
    if(!this.isUserNXTsoft) {
      this.getExternalView();
    } else {
      this.getInternalView();
    }

    // refreshes the users table from outside the component
    this.refreshUsersSub = this.ccmcApiService.refreshUsers
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.refreshUsers();
      });
    this.selectedUserSub = this.selectedFieldService.onUserFieldSelected
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(user => {
        if (user) {
          this.selectedUser = user;
        }
      });

     this.showAdmin = this.checkAdminPrivilege();
  }

  getExternalView() {
    this.ccmcApiService.getUsersByAccountID(this.amplifyService.getAccountID())
      .subscribe(assetUsers => {
        console.log(assetUsers);
        this.assetUsers = assetUsers;
        this.users = this.assetUsers.content.users.filter((obj: any) => {
          return (
            !obj.username.includes('@nxtsoft.com') &&
            !obj.username.includes('@ccmcinc.com') &&
            !obj.username.includes('kinective.io')
          );
        });
        this.dataSource = new MatTableDataSource(this.users);
          // initializes sort
          this.dataSource.sort = this.sort;
          console.log('selects first');
          this.onSelect(this.dataSource.data[0]);
          console.log('turn off spinner 1');
          this.spinnerService.setShowSpinner(false);
      });
  }

  getInternalView() {
    this.usersSub = this.ccmcApiService.users
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(users => {
        console.log(users);
        if (users) {
            this.users = users;
            this.dataSource = new MatTableDataSource(this.users);
            // initializes sort
            this.dataSource.sort = this.sort;
            console.log('selects first');
            this.onSelect(this.dataSource.data[0]);
            console.log('turn off spinner 1');
            this.spinnerService.setShowSpinner(false);
        } else {
          // Set spinner to true
          this.spinnerService.setShowSpinner(true);
          // Get users with current asset
          console.log(this.assetID);
          this.ccmcApiService
            .getUsersWithAsset(this.assetID)
            .subscribe(result => {
              console.log(result);
              const parsedResult = JSON.parse(JSON.stringify(result));
              // turn spinner off
              this.spinnerService.setShowSpinner(false);
              if (parsedResult.statusFlag === true) {
                console.log('parsedresult for users', parsedResult.content);
                // set users
                this.ccmcApiService.setUsers(parsedResult.content.users);
              }
            });
        }
      });
  }

  hideNavigation() {
    const assetID = this.assetService.getSelectedAssetId();
    if (!assetID) {
      this.ccmcConfig.setConfig({
        layout: {
          navigation: 'none'
        }
      });
    }
  }
  getAccountIDAssets() {
    let test = this.amplifyService.getAccountID();
    console.log(test);
    this.ccmcApiService
      .getAccountData(this.amplifyService.getAccountID())
      .subscribe(result => {
        this.spinnerService.setShowSpinner(true);
        console.log(result);
        if (result.content.StatusMessage == '"No assets found!"') {
          console.log('in the catch');
          return;
          // const parsedResult = JSON.parse(result.content.StatusMessage);
          // console.log(parsedResult);
          // for (let i = 0; parsedResult.Assets.length > i; i++) {
          //   console.log(parsedResult.Assets[i].SalesforceAssetId);
          //   this.adminApiService.setAccountAssets(
          //     parsedResult.Assets[i].SalesforceAssetId
          //   );
          // }
        } else {
          const parsedResult = JSON.parse(result.content.StatusMessage);
          console.log(parsedResult);
          for (let i = 0; parsedResult.Assets.length > i; i++) {
            console.log(parsedResult.Assets[i].SalesforceAssetId);
            this.ccmcApiService.setAccountAssets(
              parsedResult.Assets[i].SalesforceAssetId
            );
          }
        }
      });
  }
  /**
   * On Destroy
   *
   * @memberof UsersComponent
   */
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  /**
   * On Select
   * @description select a user
   * @param {*} selected
   * @memberof UsersComponent
   */
  onSelect(selected: any) {
    console.log(selected);
    this.selectedUser = selected;
    if (
      this.amplifyService.isNXTsoftDev ||
      this.amplifyService.isNXTsoftOperations
    ) {
      this.selectedFieldService.onUserFieldSelected.next(selected);
    }
  }

  /**
   * Refresh Users
   * @description Refreshes user list
   * @memberof UsersComponent
   */
  refreshUsers() {
    this.dataSource = new MatTableDataSource([]);
    this.onSelect(undefined);
    // Clear filter search input
    this.filterSearchEl.nativeElement.value = '';
    // Set spinner to true
    this.spinnerService.setShowSpinner(true);
    // Get users with assets
    if(!this.isUserNXTsoft) {
      this.getExternalView();
    } else {
      this.getInternalView();
    }
  }

  applyFilter(filterValue: string) {
    // trim and lowercase filter value
    this.dataSource.filter = filterValue.trim();
    // If data
    if (this.dataSource.filteredData[0]) {
      // select first instance
      this.selectedFieldService.onUserFieldSelected.next(
        this.dataSource.filteredData[0]
      );
    }
    // Set filter search
    this.filterSearch = filterValue;
  }

  /**
   * Get If NXTsoft
   * @description Sets the isUserNXTsot variable
   * @memberof UsersComponent
   */
  getIfNxtsoft() {
    this.isUserNXTsoft = this.amplifyService.isNXTsoft === true;
    console.log(this.isUserNXTsoft);

    if (
      this.amplifyService.isNXTsoftDev ||
      this.amplifyService.isNXTsoftOperations ||
      this.amplifyService.isNXTsoftSales
    ) {
      this.isUserNXTsoft = true;
    }
    this.isNXTsoftDev = this.amplifyService.isNXTsoftDev;
    this.isNXTsoftOperations = this.amplifyService.isNXTsoftOperations;
    console.log(this.isUserNXTsoft);
  }

  /**
   * Inits filter search
   *
   * @memberof UsersComponent
   */
  initFilterSearch() {
    // Auto Focus filter search item
    this.filterSearchEl.nativeElement.focus();
    fromEvent(this.filterSearchEl.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // Time in milliseconds between key events
        debounceTime(1000),
        // If previous query is diffent from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((text: string) => {
        this.applyFilter(text);
      });
  }
  editAssetBox(user: any) {
    console.log(user);
    this.selectedFieldService.onUserFieldSelected.next(user);

    const dialogRefBackup = this.dialog.open(EditUserPopupDialogComponent, {
      data: user,
      panelClass: 'edit-user-dialog__panel',
      disableClose: true
    });
    dialogRefBackup.afterClosed().subscribe(data => {
      if (data) {
        this.spinnerService.setShowSpinner(true);
        const finishedMessage = `User Updated`;
        this.openSnackBar(finishedMessage, 'Okay');
      }
    });
  }
  addAssetBox() {
    const dialogRefBackup = this.dialog.open(AddUserPopupDialogComponent, {
      panelClass: 'add-user-dialog__panel',
      disableClose: true
    });
    dialogRefBackup.afterClosed().subscribe(data => {
      if (data) {
        this.spinnerService.setShowSpinner(true);
        const finishedMessage = `User Added`;
        this.openSnackBar(finishedMessage, 'Okay');
      }
    });
  }
  openSnackBar(message: string, action: string) {
    this.zone.run(() => {
      setTimeout(() => {
        this.snackBar.open(message, action, {
          duration: 5000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }, 0);
    });
  }
  addNewUser() {
    // console.log('add new user');
    const addUserDialogRef = this.matDialog.open(AddNewUserDialogComponent);
  }

  /**
   * Opens a dialog to add an asset to an existing user
   *
   * @memberof UsersComponent
   */
  addAssetToUser() {
    // console.log('add asset to user');
    const addAssetToUserDialogRef = this.matDialog.open(
      AddAssetToUserDialogComponent
    );
  }

  addUserToUsergroup() {
    // console.log('add asset to user');
    const addAssetToUserDialogRef = this.matDialog.open(AddUsergroupComponent);
  }

  editUserAssets() {
    // console.log('add asset to user');
    const addAssetToUserDialogRef = this.matDialog.open(
      EditAssetDialogComponent
    );
  }

  checkAdminPrivilege() {
    if (this.checkNxtsoft() || this.checkAdmin() || this.checkLoanSystemsAdmin()) {
      return true;
    } else {
      return false;
    }
  }

  checkNxtsoft(): any {
    return this.amplifyService.isNXTsoftDev || this.amplifyService.isNXTsoftOperations || this.amplifyService.isNXTsoftSales;
  }

  checkAdmin(): any {
    return this.amplifyService.isAdmin || this.amplifyService.isSuperAdmin;
  }

  checkLoanSystemsAdmin(): any {
    return this.amplifyService.isLoanSystemsAdmin;
  }
}
