import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';

import { CCMCSharedModule } from '../../@ccmc/shared.module';
import {
  CCMCNavigationModule,
  CCMCSearchBarModule,
  CCMCShortcutsModule,
  CCMCSidebarModule
} from '../../@ccmc/components';

import { CCMCContentModule } from '../../app/main/content/content.module';
import { CCMCFooterModule } from '../../app/main/footer/footer.module';
import { CCMCNavbarModule } from '../../app/main/navbar/navbar.module';
import { CCMCQuickPanelModule } from '../../app/main/quick-panel/quick-panel.module';
import { CCMCToolbarModule } from '../../app/main/toolbar/toolbar.module';

import { CCMCMainComponent } from './main.component';

@NgModule({
  declarations: [CCMCMainComponent],
  imports: [
    RouterModule,

    MatSidenavModule,

    CCMCSharedModule,

    CCMCNavigationModule,
    CCMCSearchBarModule,
    CCMCShortcutsModule,
    CCMCSidebarModule,

    CCMCContentModule,
    CCMCFooterModule,
    CCMCNavbarModule,
    CCMCQuickPanelModule,
    CCMCToolbarModule
  ],
  exports: [CCMCMainComponent]
})
export class CCMCMainModule {}
