import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddNewSndMetaComponent } from './add-new-snd-meta.component';
import { FormsModule } from '@angular/forms';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';

@NgModule({
  declarations: [AddNewSndMetaComponent],
  imports: [AppMaterialModule, CommonModule, FormsModule],
  entryComponents: [AddNewSndMetaComponent]
})
export class AddNewSndMetaModule {}
