import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GCSettingsService } from '../../../../@ccmc/services/gcsettings.service';
import { AssetService } from 'src/@ccmc/services/asset.service';

@Component({
  selector: 'ccmc-gc-settings',
  templateUrl: './gc-settings.component.html',
  styleUrls: ['./gc-settings.component.scss']
})
export class CCMCGCSettingsComponent implements OnInit, OnDestroy {
  settingsForm: FormGroup;
  assetId: string;
  settingsSubscription: Subscription;

  /**
   * Creates an instance of CCMCGCSettingsComponent.
   * @param {FormBuilder} fb
   * @param {GCSettingsService} gcSettingsService
   * @param {AssetService} assetService
   * @memberof CCMCGCSettingsComponent
   */
  constructor(
    private fb: FormBuilder,
    private gcSettingsService: GCSettingsService,
    private assetService: AssetService
  ) {}

  /**
   * On Init
   * @description retrieves the product type, assetId, los, core, and loanPath
   */
  ngOnInit() {
    // Set assetId
    this.assetId = this.assetService.getSelectedAssetId();
    // Subscribe to gc settings
    this.subscribeToSettings();
    // init settings form
    this.settingsForm = this.fb.group({
      product: '',
      assetId: '',
      los: '',
      core: '',
      loanPath: ''
    });
  }

  /**
   * Chnage Settings
   * @description allows editing of the loanPath
   * @memberof CCMCGCSettingsComponent
   */
  changeSettings() {
    const settings: any = {};
    // Set loan path
    settings['loanPath'] = this.settingsForm.value['loanPath'];
    this.settingsForm.reset();
    // edit settings
    this.gcSettingsService.editSettings(settings).subscribe(
      response => {
        console.log('response ', response);
      },
      err => console.log(err)
    );
  }

  /**
   * On Destroy
   *
   * @memberof CCMCGCSettingsComponent
   */
  ngOnDestroy() {
    if (this.settingsSubscription) {
      this.settingsSubscription.unsubscribe();
    }
  }

  /**
   * Subscrie to Settings
   * @description Subscribes to the gc settings values
   * @memberof CCMCGCSettingsComponent
   */
  subscribeToSettings() {
    this.settingsSubscription = this.gcSettingsService
      .retrieveSettings(this.assetId)
      .subscribe(response => {
        this.settingsForm.controls['product'].setValue(
          (response || {}).product || ''
        );
        this.settingsForm.controls['assetId'].setValue(
          (response || {}).assetId || []
        );
        this.settingsForm.controls['los'].setValue((response || {}).los || '');
        this.settingsForm.controls['core'].setValue(
          (response || {}).core || ''
        );
        this.settingsForm.controls['loanPath'].setValue(
          (response || {}).loanPath || ''
        );
      });
  }
}
