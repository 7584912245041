import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription, Subject } from 'rxjs';
import {
  startWith,
  map,
  debounceTime,
  distinctUntilChanged,
  takeUntil
} from 'rxjs/operators';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-validation-dialog',
  templateUrl: './add-validation-dialog.component.html',
  styleUrls: ['./add-validation-dialog.component.scss']
})
export class AddValidationDialogComponent implements OnInit {
  tempValidation: any;
  validationObject: any;
  myControlFieldID = new FormControl();
  filteredOptionsFieldID: Observable<any[]>;
  private documentCoreSub: Subscription;
  unsubscribe: Subject<any> = new Subject();
  mappingObject: any;
  tempFieldID: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddValidationDialogComponent>,
    private adminApiService: AdminApiService,
    private snackBarService: SnackbarService,
    private snackBar: MatSnackBar
  ) {}

  /**
   * On Init
   * @description inits data needed for the component
   * @memberof AddValidationDialogComponent
   */
  ngOnInit() {
    // Set the validation object to the current one
    this.validationObject = this.data;
    // If validation object doesnt exist set it to an empty array
    if (!this.validationObject) {
      this.validationObject = [];
    }
    // Initialize the new validation item
    this.tempValidation = {
      displayName: '',
      value: '',
      fieldIDs: []
    };

    // Subscribe to data needed for the component
    setTimeout(() => {
      this.getData();
    }, 500);
    // Set initialize filteredOptions for autocomplete
    this.filteredOptionsFieldID = this.myControlFieldID.valueChanges.pipe(
      startWith(''),
      map(value => (value.length >= 3 ? this._filter(value) : [])),
      debounceTime(1000),
      // If previous query is diffent from current
      distinctUntilChanged()
    );
  }

  /**
   * On Destroy
   * @description unsubscribes from observables on destroy
   * @memberof AddValidationDialogComponent
   */
  ngOnDestroy() {
    // Unsubscribe from observables
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  /**
   * Get Data
   * @description Gets data needed for the component
   * @memberof AddValidationDialogComponent
   */
  getData() {
    // Subscribe to the mapping object
    this.documentCoreSub = this.adminApiService.documentCore
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(documentCore => {
        if (documentCore) {
          if (documentCore.mapping) {
            this.mappingObject = JSON.parse(
              JSON.stringify(documentCore.mapping)
            );
          }
        }
      });
  }

  /**
   * Filter
   * @description Filter on fieldID
   * @description filters the mapping to look for the given fieldID
   * @private
   * @param {string} value
   * @returns {string[]}
   * @memberof AddValidationDialogComponent
   */
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.mappingObject.filter((option: any) =>
      option.fieldID.toLowerCase().includes(filterValue)
    );
  }

  /**
   * Add Validation
   * @description Adds a new validation field to the mapping
   * @memberof AddValidationDialogComponent
   */
  addValidation() {
    let tempVal;
    // If tempValidation has both a display name and value
    let index1 = this.mappingObject.findIndex((mappingItem: any) => {
      return mappingItem.fieldID === this.tempFieldID;
    });
    if (
      (this.tempValidation.displayName.length > 0 ||
        this.tempValidation.value.length > 0) &&
      this.tempFieldID.length > 0
    ) {
      // If there is already validation
      if (this.validationObject) {
        if (index1 < 0) {
          this.openSnackBar('Error: FieldID does not exist in mapping', 'Okay');
          return false;
        } else {
          console.log('here');
          // Check if temp is already in the list
          if (
            !this.containsObject(this.tempValidation, this.validationObject)
          ) {
            console.log(this.validationObject);
            // set temp validation values
            tempVal = {
              displayName: this.tempValidation.displayName,
              value: this.tempValidation.value,
              fieldIDs: [this.tempFieldID],
              edited: true
            };
          } else {
            // Open snackbar with error message
            this.snackBarService.openSnackBar(
              'Error: This Validation already exists',
              'Okay'
            );
          }
        }
        // If current is empty we dont need to check if it exists
      } else {
        // Set temp validation values
        tempVal = {
          displayName: this.tempValidation.displayName,
          value: this.tempValidation.value,
          fieldIDs: [this.tempFieldID],
          edited: true
        };
      }
      // close dialog and return the new validation item
      this.dialogRef.close(tempVal);
    } else {
      // If display name is left blank
      if (this.tempValidation.displayName.length === 0) {
        this.snackBarService.openSnackBar(
          'Error: Display Name may not be blank',
          'Okay'
        );
      } else {
        this.snackBarService.openSnackBar(
          'Error: Value may not be blank',
          'Okay'
        );
      }
    }
  }

  /**
   * Contains Object
   * @description this function checks if the support value object given is already in the object array
   * @param {*} obj
   * @param {*} objList
   * @returns
   * @memberof AddValidationDialogComponent
   */
  containsObject(obj: any, objList: any) {
    // Loop through object list and search for obj
    for (let i = 0; i < objList.length; i++) {
      if (
        objList[i].displayName === obj.displayName &&
        objList[i].value === obj.value
      ) {
        return true;
      }
    }
    return false;
  }

  // close dialog
  closeDialog() {
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000
    });
  }
}
