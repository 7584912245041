import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-fieldids-dialog',
  templateUrl: './edit-fieldids-dialog.component.html',
  styleUrls: ['./edit-fieldids-dialog.component.scss']
})
export class EditFieldidsDialogComponent implements OnInit {
  localFieldIDs: any[] = [];
  finalFieldIDs: any[] = [];
  fieldIDsInput: any; 
  originalArrLength: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private dialogRef: MatDialogRef<EditFieldidsDialogComponent>) { }

  ngOnInit(): void {
    this.localFieldIDs = this.localFieldIDs.concat(this.data.fieldIDs);
    this.fieldIDsInput = this.localFieldIDs;
  }

  updateFieldIDs() {
      let trimVal = this.fieldIDsInput.split(',');
      for (let val of trimVal) {
        val = val.trim();
        this.finalFieldIDs.push(val);
      }
      this.dialogRef.close(this.finalFieldIDs);
    
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
