import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

import { CCMCMatchMediaService } from '../../../@ccmc/services/match-media.service';
import { CCMCNavigationService } from '../../../@ccmc/components/navigation/navigation.service';
import { CCMCConfigService } from '../../../@ccmc/services/config.service';
import { ShortcutItem } from '../../models/shortcutItem.model';

@Component({
  selector: 'ccmc-shortcuts',
  templateUrl: './shortcuts.component.html',
  styleUrls: ['./shortcuts.component.scss']
})
export class CCMCShortcutsComponent implements OnInit, OnDestroy {
  shortcutItems: ShortcutItem[] = [];
  navigationItems: any[];
  filteredNavigationItems: any[];
  searching = false;
  mobileShortcutsPanelActive = false;
  toolbarColor: string;
  matchMediaSubscription: Subscription;
  onConfigChanged: Subscription;

  @Input() navigation: any;

  @ViewChild('searchInput') searchInputField: any;
  @ViewChild('shortcuts') shortcutsEl: ElementRef;

  constructor(
    private renderer: Renderer2,
    private mediaObserver: MediaObserver,
    private ccmcMatchMedia: CCMCMatchMediaService,
    // tslint:disable-next-line:no-shadowed-variable
    private ccmcNavigationService: CCMCNavigationService,
    private ccmcConfig: CCMCConfigService,
    private cookieService: CookieService
  ) {
    this.onConfigChanged = this.ccmcConfig.onConfigChanged.subscribe(
      newSettings => {
        if (

          newSettings.colorClasses &&

          newSettings.colorClasses.toolbar

        ){

          this.toolbarColor = newSettings.colorClasses.toolbar;}
      }
    );
  }

  ngOnInit() {
    // Get the navigation items and flatten them
    this.filteredNavigationItems = this.navigationItems = this.ccmcNavigationService.getFlatNavigation(
      this.navigation
    );

    const cookieExists = this.cookieService.check('ccmc2.shortcuts');

    if (cookieExists) {
      this.shortcutItems = JSON.parse(
        this.cookieService.get('ccmc2.shortcuts')
      );
    } else {
      // User's shortcut items
      this.shortcutItems = [];
    }

    this.matchMediaSubscription = this.ccmcMatchMedia.onMediaChange.subscribe(
      () => {
        if (this.mediaObserver.isActive('gt-sm')) {
          this.hideMobileShortcutsPanel();
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.matchMediaSubscription) {
      this.matchMediaSubscription.unsubscribe();
    }

    if (this.onConfigChanged) {
      this.onConfigChanged.unsubscribe();
    }
  }

  search(event: any) {
    const value = event.target.value.toLowerCase();

    if (value === '') {
      this.searching = false;
      this.filteredNavigationItems = this.navigationItems;

      return;
    }

    this.searching = true;

    this.filteredNavigationItems = this.navigationItems.filter(
      navigationItem => {
        return navigationItem.title.toLowerCase().includes(value);
      }
    );
  }

  toggleShortcut(event: any, itemToToggle: any) {
    event.stopPropagation();

    for (let i = 0; i < this.shortcutItems.length; i++) {
      if (this.shortcutItems[i].url === itemToToggle.url) {
        this.shortcutItems.splice(i, 1);

        // Save to the cookies
        this.cookieService.set(
          'ccmc2.shortcuts',
          JSON.stringify(this.shortcutItems)
        );

        return;
      }
    }

    this.shortcutItems.push(itemToToggle);

    // Save to the cookies
    this.cookieService.set(
      'ccmc2.shortcuts',
      JSON.stringify(this.shortcutItems)
    );
  }

  isInShortcuts(navigationItem: any) {
    return this.shortcutItems.find(item => {
      return item.url === navigationItem.url;
    });
  }

  onMenuOpen() {
    setTimeout(() => {
      this.searchInputField.nativeElement.focus();
    });
  }

  showMobileShortcutsPanel() {
    this.mobileShortcutsPanelActive = true;
    this.renderer.addClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
  }

  hideMobileShortcutsPanel() {
    this.mobileShortcutsPanelActive = false;
    this.renderer.removeClass(
      this.shortcutsEl.nativeElement,
      'show-mobile-panel'
    );
  }
}
