<div class="environment-variables-container">
  <mat-card class="environment-variables-card">
    <div class="title-container">
      <h1>Target Credentials</h1>
      <div class="Btn-container">
        <button
          mat-raised-button
          matTooltip="Update the variables."
          [disabled]="!editedFlag"
          matTooltipClass="toolTipStyle"
          color="accent"
          (click)="updateVariables()"
          class="saveBtnTop"
        >
          Update
        </button>
      </div>
    </div>
    <div class="mainForm" *ngIf="variableKeys">
      <div *ngFor="let variable of variableKeys; let i = index">
        <div
          *ngIf="
            !variable.toLowerCase().includes('password') &&
            !variable.toLowerCase().includes('cognitoid')
          "
        >
          <mat-form-field class="mat-form-field1">
            <input
              matInput
              placeholder="{{ variable }}"
              [(ngModel)]="variables[variable]"
              (ngModelChange)="fieldEdited()"
            />
          </mat-form-field>
        </div>
        <div *ngIf="variable.toLowerCase().includes('password')">
          <mat-form-field class="mat-form-field1">
            <input
              matInput
              [type]="hideFields[i] ? 'password' : 'text'"
              placeholder="{{ variable }}"
              [(ngModel)]="variables[variable]"
              (ngModelChange)="fieldEdited()"
            />
            <mat-icon matSuffix (click)="hideFields[i] = !hideFields[i]">{{
              hideFields[i] ? 'visibility_off' : 'visibility'
            }}</mat-icon>
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-card>
</div>
