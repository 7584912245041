<div class="logging-container">
    <div class="filter-Logging">
      <mat-form-field style="position: sticky; top: 0; margin-bottom: 5px">
        <input matInput placeholder="Filter Search" #filterSearch />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          [matDatepicker]="startDatePicker"
          placeholder="Start date"
          [formControl]="startDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="startDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          [max]="maxDate"
          [matDatepicker]="endDatePicker"
          placeholder="End date"
          [formControl]="endDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="endDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
      <div></div>
      <div>
        <button
          mat-raised-button
          matTooltip="Retrieve and displays logs"
          matTooltipClass="toolTipStyle"
          color="accent"
          (click)="getLogs()"
          class="fetch-button"
        >
          Fetch Logs
        </button>
      </div>
    </div>
    <div #container class="table-container">
      <table
        mat-table
        class="table-TableContainer"
        #table
        [dataSource]="dataSource"
        CCMCPerfectScrollbar
        matSort
      >
        <!-- Date - Time Column -->
        <ng-container matColumnDef="timestamp">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            style="min-width: 150px; max-width: 150px"
          >
            Date - Time
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            style="min-width: 150px; max-width: 150px"
          >
            {{ element.time | date: 'MM/dd/yyyy, h:mm a' }}
          </td>
        </ng-container>
  
        <!-- User -->
        <ng-container matColumnDef="user">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            style="min-width: 175px; max-width: 175px"
          >
            User
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            style="min-width: 175px; max-width: 175px"
          >
            {{ element.user }}
          </td>
        </ng-container>
  
        <!-- Message -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
          <td mat-cell *matCellDef="let element">
            <div class="truncate">
              {{ element.action }}
            </div>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="onSelect(row)"
          [ngClass]="{ 'accent-50-bg': row == currentLog }"
        ></tr>
      </table>
    </div>
    <div class="paginatorContainer">
      <mat-paginator
        [pageSizeOptions]="[100]"
        showFirstLastButtons
        class="paginator-conditions"
        (click)="container.scrollTop = 0"
      >
      </mat-paginator>
    </div>
  
    <!--Logging Display Area-->
    <div class="tabBar">
      <mat-tab-group>
        <mat-tab label="Log">
          <div class="exception-message-container" *ngIf="currentLog">
            <!--Exceptions-->
            <div *ngIf="currentException" class="exception-container">
              <h3 class="exception-title">Exception</h3>
              <mat-form-field
                id="currentException"
                style="width: 100%; height: 33%"
              >
                <textarea
                  matInput
                  [(ngModel)]="currentException"
                  readonly
                  cdkTextareaAutosize
                  class="exception-textarea"
                ></textarea>
              </mat-form-field>
            </div>
            <div>
              <!--Messages-->
              <div class="message-container">
                <h3 class="message-title">Old Values</h3>
                <mat-form-field style="width: 100%; height: 33%">
                  <textarea
                    class="message-textarea"
                    matInput
                    readonly
                    [(ngModel)]="oldValues"
                  >
                  </textarea>
                </mat-form-field>
              </div>
              <div class="message-container2">
                <h3 class="message-title">New Values</h3>
                <mat-form-field style="width: 100%; height: 33%">
                  <textarea
                    style="background-color: white"
                    matInput
                    readonly
                    [(ngModel)]="newValues"
                    class="message-textarea"
                  >
                  </textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  
  <!--Spinner -->
  <div class="spinner-container" *ngIf="showSpinner">
    <div class="new-spinner">
      <div style="position: relative" class="outer-ring"></div>
      <div
        style="position: relative; bottom: 594px; left: 1px"
        class="middle-ring"
      ></div>
      <div
        style="position: relative; bottom: 1196px; left: 1px"
        class="inner-ring"
      ></div>
    </div>
  </div>
  