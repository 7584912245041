import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timezoneConversion'
})
export class TimezoneConversionPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let logdate = new Date(value);
    let offset = new Date().getTimezoneOffset();

    logdate.setMinutes(logdate.getMinutes() - offset);
    return logdate.toLocaleString();
  }

}
