import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocConnectorSourceAndDestinationComponent } from './doc-connector-source-and-destination.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { CCMCSharedModule } from 'src/@ccmc/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DestinationsComponent } from './destinations/destinations.component';
import { SourcesComponent } from './sources/sources.component';

const routes: Routes = [
  {
    path: 'doc-connector/source-and-destination',
    component: DocConnectorSourceAndDestinationComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [DocConnectorSourceAndDestinationComponent, DestinationsComponent, SourcesComponent],
  imports: [RouterModule.forChild(routes), CCMCSharedModule, MatButtonModule, MatIconModule],
  exports: [DocConnectorSourceAndDestinationComponent]
})
export class DocConnectorSourceAndDestinationModule {}
