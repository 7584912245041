import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CCMCSharedModule } from '../../../../@ccmc/shared.module';

import { CCMCLoginComponent } from './login.component';
import { CCMCForceChangePasswordModule } from '../../../../@ccmc/components/force-change-password/force-change-password.module';
import { CCMCForgotPasswordModule } from '../forgot-password/forgot-password.module';

const routes: Routes = [
  {
    path: 'login',
    component: CCMCLoginComponent
  }
];

@NgModule({
  declarations: [CCMCLoginComponent],
  imports: [
    RouterModule.forChild(routes),
    CCMCSharedModule,
    CCMCForceChangePasswordModule,
    CCMCForgotPasswordModule
  ],
  exports: [CCMCLoginComponent]
})
export class CCMCLoginModule {}
