import { NgModule } from '@angular/core';
import { CCMCUserProfileComponent } from './user-profile.component';
import { CCMCSharedModule } from '../../../../@ccmc/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AuthGuard } from '../../../auth/auth.guard';
import { EditAvatarDialogComponent } from './edit-avatar-dialog/edit-avatar-dialog.component';

const routes: Routes = [
  {
    path: 'user-profile',
    component: CCMCUserProfileComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    CCMCSharedModule,
    RouterModule.forChild(routes),
    MatSlideToggleModule
  ],
  declarations: [CCMCUserProfileComponent, EditAvatarDialogComponent],
  exports: [CCMCUserProfileComponent]
})
export class CCMCUserProfileModule {}
