import { Component, HostBinding, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { CCMCAnimations } from '../../../@ccmc/animations/index';
import { CCMCConfigService } from '../../../@ccmc/services/config.service';

@Component({
    selector   : 'ccmc-content',
    templateUrl: './content.component.html',
    styleUrls  : ['./content.component.scss'],
    animations : CCMCAnimations
})
export class CCMCContentComponent implements OnDestroy {
    onConfigChanged: Subscription;
    ccmcSettings: any;

    @HostBinding('@routerTransitionUp') routeAnimationUp = false;
    @HostBinding('@routerTransitionDown') routeAnimationDown = false;
    @HostBinding('@routerTransitionRight') routeAnimationRight = false;
    @HostBinding('@routerTransitionLeft') routeAnimationLeft = false;
    @HostBinding('@routerTransitionFade') routeAnimationFade = false;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private ccmcConfig: CCMCConfigService
    ) {
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute)
        ).subscribe((event) => {
            switch ( this.ccmcSettings.routerAnimation ) {
                case 'fadeIn':
                    this.routeAnimationFade = !this.routeAnimationFade;
                    break;
                case 'slideUp':
                    this.routeAnimationUp = !this.routeAnimationUp;
                    break;
                case 'slideDown':
                    this.routeAnimationDown = !this.routeAnimationDown;
                    break;
                case 'slideRight':
                    this.routeAnimationRight = !this.routeAnimationRight;
                    break;
                case 'slideLeft':
                    this.routeAnimationLeft = !this.routeAnimationLeft;
                    break;
            }
        });

        this.onConfigChanged =
            this.ccmcConfig.onConfigChanged
                .subscribe(
                    (newSettings) => {
                        this.ccmcSettings = newSettings;
                    }
                );
    }

    ngOnDestroy() {
        if (this.onConfigChanged) {
            this.onConfigChanged.unsubscribe();
        }
    }
}
