import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  NgZone
} from '@angular/core';
import { AmplifyService } from '../../services/amplify.service';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CcmcApiService } from '../../services/ccmc-api.service';
import { EncompassService } from '../../services/encompass.service';
import { SpinnerService } from '../../services/spinner.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AssetService } from 'src/@ccmc/services/asset.service';

/**
 * This component lets users add new users.
 *
 * @export
 * @class AddNewUserDialogComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-get-path-dialog',
  templateUrl: './get-path-dialog.component.html',
  styleUrls: ['./get-path-dialog.component.scss']
})
export class GetPathDialogComponent implements OnInit {
  fieldName: any;
  fieldPath: any;
  showSpinner: boolean;
  private spinnerSub: Subscription;
  unsubscribe: Subject<any> = new Subject();
  /**
   * Creates an instance of GetPathDialogComponent.
   * @param {MatDialogRef<GetPathDialogComponent>} dialogRef
   * @param {CcmcApiService} ccmcAPIService
   * @param {MatSnackBar} snackBar
   * @param {NgZone} zone
   * @memberof AddNewUserDialogComponent
   */
  constructor(
    private dialogRef: MatDialogRef<GetPathDialogComponent>,
    private ccmcAPIService: CcmcApiService,
    private snackBar: MatSnackBar,
    private zone: NgZone,
    private encompassService: EncompassService,
    private spinnerService: SpinnerService,
    private assetService: AssetService
  ) {}

  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  /**
   * Initializes the add new user dialog component
   *
   * @memberof GetPathDialogComponent
   */
  ngOnInit() {
    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        this.showSpinner = spinner;
      });
  }

  /**
   * Closes itself
   *
   * @memberof GetPathDialogComponent
   */
  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * Opens snackbar with given message and action
   *
   * @param {string} message
   * @param {string} action
   * @memberof GetPathDialogComponent
   */
  openSnackBar(message: string, action: string) {
    this.zone.run(() => {
      setTimeout(() => {
        this.snackBar.open(message, action, {
          duration: 5000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }, 0);
    });
  }

  getEncompassPath() {
    console.log(this.assetService.getSelectedAssetId());
    const params = {
      fieldName: this.fieldName,
      assetID: this.assetService.getSelectedAssetId()
    };
    this.spinnerService.setShowSpinner(true);
    this.encompassService.generatePath(params).subscribe(result => {
      console.log(result);
      const parsedResult = JSON.parse(JSON.stringify(result));
      this.spinnerService.setShowSpinner(false);
      if (parsedResult.statusFlag === true) {
        this.fieldPath = parsedResult.content.path;

        var fieldPath = this.fieldPath.replace('~#~', '0');
        console.log(fieldPath);
        this.dialogRef.close({ data: fieldPath });
      } else {
        if (parsedResult.error) {
          this.openSnackBar(parsedResult.statusMessage, 'Okay');
        }
        if (parsedResult.error && parsedResult.error.details) {
          this.openSnackBar(parsedResult.error.details, 'Okay');
        }
      }
    });
  }
}
