import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CCMCCollateralSearchDialogComponent } from './collateral-search-dialog.component';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';
import { CCMCCollateralDetailsDialogModule } from './collateral-details-dialog/collateral-details-dialog.module';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
    CCMCCollateralDetailsDialogModule,
    DragDropModule],
  declarations: [CCMCCollateralSearchDialogComponent],
  entryComponents: [CCMCCollateralSearchDialogComponent],
  exports: [CCMCCollateralDetailsDialogModule]
})
export class CCMCCollateralSearchDialogModule { }
