import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
  OnDestroy,
  ElementRef
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CcmcApiService } from '../../../../@ccmc/services/ccmc-api.service';
import {
  glaNavigation,
  navigation
} from '../../../../app/navigation/navigation';
import { SnackbarService } from '../../../../../src/@ccmc/services/snackbar.service';
import { Loans } from '../../../../@ccmc/models/loan.model';
import { CCMCNavigationService } from '../../../../@ccmc/components/navigation/navigation.service';
import { APPCONSTANTS } from '../../../app.constants';
import { Navigation } from '../../../../@ccmc/models/navigation.model';
import { Router } from '@angular/router';
import { ErrorDialogComponent } from '../../../../@ccmc/components/error-dialog/error-dialog.component';
import { GCSettingsService } from '../../../../@ccmc/services/gcsettings.service';
import { Subject, Subscription } from 'rxjs';
import { SpinnerService } from '../../../../@ccmc/services/spinner.service';
import { ActiveLosService } from '../../../../@ccmc/services/active-los.service';
import { ActiveCoreService } from '../../../../@ccmc/services/active-core.service';
import { CifSearchService } from '../../../../@ccmc/services/cif-search.service';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { SelectSetsDialogComponent } from 'src/@ccmc/components/select-sets-dialog/select-sets-dialog.component';
import { GeneralLedgerAccountingService } from 'src/@ccmc/services/general-ledger-accounting.service';
import { takeUntil } from 'rxjs/operators';
import { SuccessDialogComponent } from 'src/@ccmc/components/success-dialog/success-dialog.component';
import { CaptureDataService } from 'src/@ccmc/services/capture-data.service';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { CCMCConfirmDialogComponent } from 'src/@ccmc/components/confirm-dialog/confirm-dialog.component';
import { WriteLoggingService } from 'src/@ccmc/services/write-logging.service';

@Component({
  selector: 'ccmc-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss']
})
export class CCMCFileSelectorComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  loans: Loans[] = [];
  displayedColumns = [
    'name',
    'loanNumber',
    'product',
    'taxId',
    'date',
    'delete'
  ];
  dataSource: MatTableDataSource<Loans>;
  standardMapping: any;
  navigation: Navigation;
  spinnerColor = 'accent'; // variable for the mat-spinner's color
  showSpinner: boolean; // flag for the mat-spinner
  private spinnerSub: Subscription;
  impModeSub: Subscription;
  selectedRow: any;
  los = '';
  coreMapping: any;
  dynamicNav: any = navigation;
  dynamicGLANav: any = glaNavigation;
  losSubscription: Subscription;
  isGLA = false;
  @ViewChild(MatSort) sort: MatSort;
  unsubscribe: Subject<any> = new Subject();
  glaDocument: any;
  isOrigenate: any;
  constructor(
    private ccmcApiService: CcmcApiService,
    private navigationService: CCMCNavigationService,
    private matDialog: MatDialog,
    private router: Router,
    private gcSettingsService: GCSettingsService,
    private spinnerService: SpinnerService,
    private ref: ChangeDetectorRef,
    private activeCoreService: ActiveCoreService,
    private activeLosService: ActiveLosService,
    private cifSearchService: CifSearchService,
    private assetService: AssetService,
    private glaService: GeneralLedgerAccountingService,
    private successDialogReference: MatDialogRef<SuccessDialogComponent>,
    private captureDataService: CaptureDataService,
    private fieldEditedService: FieldEditedService,
    private snackBarService: SnackbarService,
    private dialog: MatDialog,
    private writeLoggingService: WriteLoggingService,
    private amplifyService: AmplifyService
  ) {}

  /**
   * Rorate
   * @description Rorate the refresh button the file selector table

   * @param {*} event
   * @memberof CCMCFileSelectorComponent
   */
  rotate(event: any) {
    event.srcElement.classList.remove('rotate');
    setTimeout(() => {
      event.srcElement.classList.add('rotate');
    }, 0);
  }

  /**
   * On Init
   *
   * @memberof CCMCFileSelectorComponent
   */
  ngOnInit() {
    this.activeLosService.selectLos();
    this.subscribeToLos();
    this.subscribeToSpinner();
    this.getGLADocument();
    this.turnGLANavItemsOff();
    this.refreshOnSuccess();
    this.isOrigenate = this.activeLosService.activeLos;
    console.log(this.isOrigenate);
  }

  getGLADocument() {
    this.glaService.glaDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(glaDocument => {
        if (glaDocument) {
          this.glaDocument = glaDocument;
        }
      });
  }

  /**
   * Subscribe To Lose
   * @description subscribe to the los, once complete get loan information
   * @memberof CCMCFileSelectorComponent
   */
  subscribeToLos() {
    this.losSubscription = this.activeLosService.losSubject
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        complete: () => {
          if (typeof this.activeLosService.activeLosService.retrieveFiles == 'function') {
            this.spinnerService.setShowSpinner(true);
            this.activeLosService.activeLosService.retrieveFiles(this);
          } else {
            console.log('No service found');
            const message = {
              title: 'LOS Error',
              message: 'Endpoint does not exist!'
            }
            const dialogRef1 = this.dialog.open(ErrorDialogComponent, {
                data: message
              });
          }
        }
      });
  }

  /**
   * Subscribe To Spinner
   * @description Subscribes to the loading spinner
   * @memberof CCMCFileSelectorComponent
   */
  subscribeToSpinner() {
    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        this.showSpinner = spinner;
      });
  }

  /**
   * Handle Retrieved Files
   * @description displays and sorts the loans
   * updates the badge count
   */
  handleRetrievedFiles(err: any, result: any) {
    console.log('loan results', result);
    if (result) {
      // Set loans
      this.loans = result;
      // Sort loans by name
      this.loans.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      // Set material datasource
      this.dataSource = new MatTableDataSource(this.loans);
      // Sort loans
      this.sortData();
      // Set spinnre to false
      this.spinnerService.setShowSpinner(false);
      // Set badge count for how many loans
      this.navigationService.setBadgeCount(this.loans.length);
      // Return for success
      return;
    }
    if (err) {
      // Open error dialog with given error
      const dialogRef2 = this.matDialog.open(ErrorDialogComponent, {
        data: err
      });
    } else {
      err = {
        title: 'Error',
        message: 'No loans found.'
      };
      // Open error dialog error
      const dialogRef2 = this.matDialog.open(ErrorDialogComponent, {
        data: err
      });
    }
  }

  /**
   * After View Init
   *
   * @memberof CCMCFileSelectorComponent
   */
  ngAfterViewInit() {
    // Detect changes fixes some change detection errors
    this.ref.detectChanges();
  }

  /**
   * On Destroy
   *
   * @memberof CCMCFileSelectorComponent
   */
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  /**
   * Refresh File Selector
   * @description Gets the latest file description
   * @memberof CCMCFileSelectorComponent
   */
  refreshFileSelector() {
    // Set spinner to true
    this.spinnerService.setShowSpinner(true);
    // Retrieve files
    this.activeLosService.activeLosService.retrieveFiles(this);
  }

  /**
   * Select Loan
   * @description Sends the currently selected loan details to the navigation
   * @param {*} row
   * @memberof CCMCFileSelectorComponent
   */
  selectLoan(row: any) {
    this.ccmcApiService.loanNumber = row.loanNumber;
    this.cifSearchService.cifSet = false;
    this.ccmcApiService.currentLoanNumber.next(row.loanNumber);
    this.captureDataService.setLoanNumber(row.loanNumber);
    this.loanSelected(row.fileName);
    // clear out internal Display Settings
    this.navigationService.onVisibilityChange.next(false);
    this.navigationService.onSortChange.next({button: 'numeric', alphaNumeric: 'numeric'});
    this.navigationService.defaultInstance = true;
    this.navigationService.setShowNavSpinner(true);
    this.spinnerService.setShowSpinner(true);
    this.navigationService.transactionHistoryToggle.next(false);
    // set loan number in navigation
    const loanDetails = navigation[2].children[0];
    loanDetails.badge!.title = row.loanNumber;
    glaNavigation[1].children[1].badge!.title = row.loanNumber;
    this.selectedRow = row;
  }

  /**
   * Loan Selected
   * @description sets the loan in the los service and clears navigation object
   * @param {*} key
   * @memberof CCMCFileSelectorComponent
   */
  loanSelected(key: any) {
    this.dynamicNav[2].children[1].children = [];
    this.activeLosService.activeLosService.loanSelected(key, this);
  }

  turnGLANavItemsOn() {
    this.dynamicGLANav[3].children[0].visible = true;
    this.dynamicGLANav[3].children[1].visible = true;
  }

  turnGLANavItemsOff() {
    this.dynamicGLANav[3].children[0].visible = false;
    this.dynamicGLANav[3].children[1].visible = false;
  }

  /**
   * @description get loan information, and returns the populated core mapping
   */
  /**
   * Handle Core Mapping Response
   * @description Handles the reponse from retrieve files
   * @param {*} err
   * @param {*} response
   * @returns
   * @memberof CCMCFileSelectorComponent
   */
  async handleCoreMappingResponse(err: any, response: any) {
    if (response) {
      this.spinnerService.setShowSpinner(false);
      // Set spinner to false
      console.log('ice result ', response);
      console.log(this.assetService.getGLA());
      if (this.assetService.getGLA()) {
        this.turnGLANavItemsOn();
        this.openSetsDialog(response.content);
        // new gla stuff here
      } else {
        // if data is valid
        if (response.content && response.content.data) {
          const result: any = response.content.data;
          // Set CCMC Api variables
          this.ccmcApiService.mapping = result.mapping;
          this.ccmcApiService.coreSettings = result.settings;
          this.ccmcApiService.conditions = result.conditions;
          this.ccmcApiService.supportValues = result.supportValues;
          this.ccmcApiService.search = result.search;
          this.ccmcApiService.transactions = result.transactions;
          this.ccmcApiService.configurations = result.configurations;
          this.ccmcApiService.ncinoWriteBack = result.ncinoWriteBack;
          // nav item for the issues list
          const navItem = {
            id: 'issueList',
            title: 'Issue List',
            type: 'item',
            icon: 'warning',
            visible: true,
            url: `/transactions/issueList`
          };
          this.dynamicNav[2].children[1].children.push(navItem);
          // nav item for the issues list
          const navItem2 = {
            id: 'reviewList',
            title: 'Review List',
            type: 'item',
            icon: 'rate_review',
            visible: true,
            url: `/transactions/reviewList`
          };
          this.dynamicNav[2].children[1].children.push(navItem2);
          const navItem3 = {
            id: 'changerequestList',
            title: 'Change Request List',
            type: 'item',
            icon: 'rules',
            visible: true,
            url: `/transactions/changereq`
          };

          this.impModeSub = this.fieldEditedService.impModechecked
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(show => {
              console.log(show);
              if (show) {
                this.dynamicNav[2].children[1].children.push(navItem3);
              }
            });

          this.ccmcApiService.transactions.sort((a: any, b: any) => a.order - b.order);
          /**
           * Sets the transaction nav children
           */
          this.ccmcApiService.transactions.forEach((t: any, index: any) => {
            let trimmedName = t.transactionName.trim();
            if (trimmedName[0] !== '-') {
              let collapsableItem: any = {
                id: t.transactionID,
                title: t.transactionName.replace(/([\_/])+/g, ' '),
                type: 'collapse',
                fieldIndex: this.ccmcApiService.mapping.indexOf(
                  (obj: any) => obj.fieldID === t.fieldID
                ),
                transactionIndex: index,
                additionalCost: t.additionalCost ? t.additionalCost : false,
                url: `/transactions/${t.transactionID}`,
                children: []
              };
              if (t.display === true) {
                collapsableItem.visible = true;
              }
              this.dynamicNav[2].children[1].children.push(collapsableItem);
            } else {
              // tslint:disable-next-line:no-shadowed-variable
              let navItem: any = {
                id: t.transactionID,
                title: t.transactionName.replace(/([\_/])+/g, ' '),
                type: 'item',
                fieldIndex: this.ccmcApiService.mapping.indexOf(
                  (obj: any) => obj.fieldID === t.fieldID
                ),
                transactionIndex: index,
                additionalCost: t.additionalCost ? t.additionalCost : false,
                url: `/transactions/${t.transactionID}`
              };
              if (t.display === true) {
                navItem.visible = true;
              }
              console.log('Main Item', JSON.parse(JSON.stringify(navItem)));
              // Calculate where to push
              let lastIndex = this.dynamicNav[2].children[1].children.length;
              console.log(this.dynamicNav[2].children[1].children);
              this.dynamicNav[2].children[1].children[
                lastIndex - 1
              ].children.push(navItem);
              this.navigation = this.dynamicNav;
            }
          });
          // Turn on transaction navigation
          this.dynamicNav[2].children[1].visible = true;
          // Perform product inquiry if signature
          if (
            this.activeCoreService.activeCore.toLowerCase() === 'signature' &&
            this.activeCoreService.activeService.checkProductInquiry()
          ) {
            await this.activeCoreService.activeService.productInquiry();
          }
          this.navigation = this.dynamicNav;
          this.navigationService.setShowNavSpinner(false);
          this.spinnerService.setShowSpinner(false);
          // Navigate to first transaction in the navigation object
          return this.router.navigateByUrl(
            `${this.dynamicNav[2].children[1].children[0].url}`
          );
        }
        // If we get here there has been a connection error
        this.matDialog.open(ErrorDialogComponent, {
          data: {
            message: 'Connection Error',
            title: 'Ice Error'
          }
        });
      }
    } else {
      // Open error dialog with given error
      const dialogRef2 = this.matDialog.open(ErrorDialogComponent, {
        data: err
      });
    }
  }

  /**
   * Sort Data
   * @description allows the data to be sortable
   * @memberof CCMCFileSelectorComponent
   */
  sortData() {
    this.dataSource.sort = this.sort;
  }

  /**
   * Apply Filter
   * @description filter the loan data according to user input
   * @param {string} filterValue
   * @memberof CCMCFileSelectorComponent
   */
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  handleGLACoreMappingResponse(err: any, response: any) {
    if (response) {
      this.spinnerService.setShowSpinner(false);
      console.log('ice result ', response);
      if (response.content) {
        this.glaService.setSelectedSetDocument(response.content);
        this.turnGLANavItemsOn();
        this.router.navigate([`gla/funding-ledger`]);
      } else {
        // connection error
        this.matDialog.open(ErrorDialogComponent, {
          data: {
            message: 'Connection Error',
            title: 'Ice Error'
          }
        });
      }
    } else {
      // Show error to user
      const dialogRef2 = this.matDialog.open(ErrorDialogComponent, {
        data: err
      });
    }
  }

  openSetsDialog(loan: any) {
    const selectSetsDialogRef = this.matDialog.open(SelectSetsDialogComponent, {
      data: this.glaDocument.sets,
      panelClass: 'select-sets-dialog'
    });
    selectSetsDialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
        const tempGLA = {
          set: result,
          coreLayout: this.glaDocument.coreLayout,
          translators: this.glaDocument.translators,
          configurations: this.glaDocument.configurations
        };
        this.glaService.setSelectedSetDocument(tempGLA);
        const iceReq: any = tempGLA;
        iceReq['loan'] = loan;
        this.glaService.ice(iceReq).subscribe(
          (response: any) => {
            console.log('Response from ice', response);
            // if success handle the mapping reponse
            if (response.statusFlag === true) {
              return this.handleGLACoreMappingResponse(null, response);
            } else if (response.statusMessage) {
              // Send error message to handle mapping repose
              const errorMessage = {
                message: response.statusMessage,
                title: 'Ice Error'
              };
              return this.handleGLACoreMappingResponse(errorMessage, null);
              // no message recieved
            } else {
              const errorMessage = {
                message: 'No Response found',
                title: 'Ice Error'
              };
              return this.handleGLACoreMappingResponse(errorMessage, null);
            }
          },
          // no message recieved
          error => {
            const errorMessage = {
              message: 'No Result Found',
              title: 'Ice Error'
            };
            return this.handleCoreMappingResponse(errorMessage, null);
          }
        );
      }
    });
  }
  deleteLoan(element: any) {
    console.log('clicked delete', element);
    const confirmDialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
      data: 'Are you sure you want to delete this loan file?'
    });
    confirmDialogRef.afterClosed().subscribe(async data => {
      if (data) {
        this.spinnerService.setShowSpinner(true);
        const deleteLoanRespose =
          await this.activeLosService.activeLosService.deleteLoan(
            element.fileName
          );
        console.log('delete loan response',deleteLoanRespose);
        this.spinnerService.setShowSpinner(false);
        this.snackBarService.openSnackBar('Sucessfully deleted loan', 'Okay');
        const assetID = this.assetService.getSelectedAssetId();
        const stream = assetID;
        this.writeLoggingService.setStream(stream);
        this.writeLoggingService
          .writeLog({
            username: this.amplifyService.username,
            action: 'Loan Deleted',
            time: new Date().toISOString(),
            log: `Loan: ${element.loanNumber} has been deleted.`
          })
          .subscribe(result => {
            // logging result
            console.log(result);
          });
        if (deleteLoanRespose.statusFlag === true) {
          this.refreshOnSuccess();
        }
      }
    });
  }
  refreshOnSuccess() {
    console.log('calling refresh');
    if (this.loans.length > 0) {
      this.refreshFileSelector();
    }
  }
}
