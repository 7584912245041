import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { DocumentConnectorService } from 'src/@ccmc/services/doc-connector.service';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';

@Component({
  selector: 'app-selected-loan',
  templateUrl: './selected-loan.component.html',
  styleUrls: ['./selected-loan.component.scss']
})
export class SelectedLoanComponent implements OnInit {
  dcDoc: any;
  selectedFile: any;
  selectedLoan: any;
  private selectedSub: Subscription;
  private dcSub: Subscription;
  selectedLoanDisplayedColumns = [
    'fileName',
    'source',
    'destination',
    'statusMessage'
  ];
  selectedLoanDataSource: any;
  unsubscribe: Subject<any> = new Subject();
  destinations: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(
    private dcService: DocumentConnectorService,
    private assetService: AssetService,
    private dialog: MatDialog,
    private selectedFieldService: CCMCSelectedFieldService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  onSelectFile(selectedFile: any) {
    this.selectedFile = selectedFile;
  }

  getData() {
    this.selectedSub = this.selectedFieldService.onHistoryLoanFieldSelected
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(selected => {
        console.log('Selected History Loan', selected);
        this.selectedLoan = selected;
        if (this.selectedLoan && this.selectedLoan.files) {
          this.selectedLoanDataSource = new MatTableDataSource(
            this.selectedLoan.files
          );
          // initializes sort
          this.selectedLoanDataSource.sort = this.sort;
          this.selectedLoanDataSource.sortingDataAccessor = (item: any, property: any) => {
            switch (property) {
              case 'fileName': return new String(item.fileName);
              case 'source': return new String(item.source);
              case 'destination': return new String(item.destination);
              case 'statusMessage': return new String(item.statusMessage);
              default: return item[property];
            }
          };
        } else {
          this.selectedLoanDataSource = new MatTableDataSource([]);
          // initializes sort
          this.selectedLoanDataSource.sort = this.sort;
          this.selectedLoanDataSource.sortingDataAccessor = (item: any, property: any) => {
            switch (property) {
              case 'fileName': return new String(item.fileName);
              case 'source': return new String(item.source);
              case 'destination': return new String(item.destination);
              case 'statusMessage': return new String(item.statusMessage);
              default: return item[property];
            }
          };
        }
      });

    this.dcSub = this.dcService.dcDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dcDocument => {
        if (dcDocument) {
          if (dcDocument.destinations) {
            this.destinations = dcDocument.destinations;
          }
        } else {
          this.destinations = undefined;
        }
      });
  }

  goToSelectedLoansDestination(selectedFile: any) {
    for (let destination of this.destinations) {
      if (selectedFile.destination === destination.id) {
        this.selectedFieldService.onDestinationFieldSelected.next(destination);
        this.router.navigate(['/doc-connector/source-and-destination']);
      }
    }
  }
}
