import { Injectable } from '@angular/core';
import { CcmcApiService } from './ccmc-api.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { catchError } from 'rxjs/operators';
import { AssetService } from './asset.service';
import { APPCONSTANTS } from 'src/app/app.constants';
import { AmplifyService } from './amplify.service';
const { Buffer } = require('buffer');

@Injectable({
  providedIn: 'root'
})
export class ByteproService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  currentLoanKey: string;
  byteproAccessToken: string;
  constructor(
    private ccmcApiService: CcmcApiService,
    private http: HttpClient,
    private customErrorHandlerService: CustomErrorHandlerService,
    private assetService: AssetService,
    private amplifyService: AmplifyService
  ) {
    this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, PUT'
    );
    this.httpOptions.headers.append('Accept', 'application/json');
  }

  /**
   * Loan Lookup
   * @description looks up a loan in the bytepro communicator
   * @param {*} params
   * @returns
   * @memberof ByteproService
   */
  loanLookUp(params: any) {
    params.assetID = this.assetService.getSelectedAssetId();

    let url: any;
    if (params.env === 'Client') {
      url = 'aHR0cHM6Ly9hcGkubnh0c29mdC5jb20vdjE=';
    } else if (params.env === 'Nxtsoft') {
      url = 'aHR0cHM6Ly9hcGktZGV2Lm54dHNvZnQuY29tL3Yy';
    } else {
      url = environment.environmentURL;
    }

    this.amplifyService.environmentOverride = true;
  
      return this.http
      .post(`${Buffer.from(url, "base64").toString()}/bytepro/loan-query`, params, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
     
  }

  /**
   * Get Loan
   * @description Retrieves selected loan from the bytepro communicator
   * @param {*} params
   * @returns
   * @memberof ByteproService
   */
  getLoan(params: any) {
    params.assetID = this.assetService.getSelectedAssetId();
    return this.http
      .post(`${Buffer.from(environment.environmentURL, "base64").toString()}/bytepro/get-loan`, params, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * Loan Booked
   * @description Defines what is done to the LOS after the loan has been booked
   * This is a required function, leave empty if nothing is required for the LOS
   * @param {*} result
   * @memberof ByteproService
   */
  loanBooked(result: any, configurations: any) {
    if (!configurations.demoMode && !configurations.implementationMode) {
      // Record Sender
      this.ccmcApiService.recordSender().subscribe(result => {
        // console.log(result);
      });
    }
  }
}
