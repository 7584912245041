import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuffixSearchDialogComponent } from './suffix-search-dialog.component';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [SuffixSearchDialogComponent],
  imports: [AppMaterialModule, CommonModule, MatCheckboxModule, FormsModule]
})
export class SuffixSearchDialogModule {}
