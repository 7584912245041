import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { EditValidationGroupDialogComponent } from './edit-validation-group-dialog.component';



@NgModule({
  declarations: [EditValidationGroupDialogComponent],
  imports: [
    AppMaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule
  ],
  entryComponents: [EditValidationGroupDialogComponent]
})
export class EditValidationGroupDialogModule { }
