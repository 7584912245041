import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule
} from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { ErrorDialogComponent } from '../../../../@ccmc/components/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CCMCConfigService } from '../../../../@ccmc/services/config.service';
import { LoggedInCredentials } from '../../../../@ccmc/models/loggedInCredentials.model';
import { ErrorDialogModal } from '../../../../@ccmc/models/errorDialog.model';
import { AuthService } from '../../../auth/auth.service';
import { GCSettingsService } from '../../../../@ccmc/services/gcsettings.service';
import { ActiveCoreService } from '../../../../@ccmc/services/active-core.service';
import { CcmcApiService } from '../../../../@ccmc/services/ccmc-api.service';
import { ActiveLosService } from '../../../../@ccmc/services/active-los.service';
import { AssetService } from 'src/@ccmc/services/asset.service';
import {
  navigation,
  adminNavigation,
  glaNavigation
} from '../../../../app/navigation/navigation';
import { AvatarService } from 'src/@ccmc/services/avatar.service';
import { CCMCNavigationService } from 'src/@ccmc/components/navigation/navigation.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { WriteLoggingService } from 'src/@ccmc/services/write-logging.service';
import { filter, takeUntil } from 'rxjs/operators';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { GeneralLedgerAccountingService } from 'src/@ccmc/services/general-ledger-accounting.service';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { AmplifyQrCodeDialogComponent } from 'src/@ccmc/components/amplify-qr-code-dialog/amplify-qr-code-dialog.component';
import { DocumentConnectorService } from 'src/@ccmc/services/doc-connector.service';
import { RequestChangeService } from 'src/@ccmc/services/request-change.service';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
import { v4 as uuidv4 } from 'uuid';
import { SalesforceService } from 'src/@ccmc/services/salesforce.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ccmc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class CCMCLoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  loginFormErrors: any;
  assetIds: Array<string> = [];
  dynamicNav: any = navigation;
  dynamicAdminNav: any = adminNavigation;
  dynamicGLANav: any = glaNavigation;
  errorMessage: ErrorDialogModal;
  token: string;
  loggedInCreds: LoggedInCredentials = {
    accessKey: '',
    secretKey: '',
    sessionToken: '',
    token: ''
  };

  formChangeSub: Subscription;
  submitEventSub: Subscription;
  gcSettingsSubscription: Subscription;
  unsubscribe: Subject<any> = new Subject();
  userSubscription: Subscription;
  username: any;
  isNXTsoft = false;
  isNXTsoftDev = false;
  isNXTsoftOperations = false;
  isNXTsoftSales = false;
  isAdmin = false;
  isSuperAdmin = false;
  isLoanSystemsAdmin = false;
  isLoanServicingAdmin = false;
  changeRequestFieldsSub: Subscription;
  changeRequestStatusesSub: Subscription;
  docFields: any;
  docStatuses: any;
  showSpinner: boolean;
  private spinnerSub: Subscription;
  parsedResult: any;
  user: any = {
    firstName: '',
    lastName: ''
  }
  companyName: any; 
  /**
   * Creates an instance of CCMCLoginComponent.
   * @param {CCMCConfigService} CCMCConfig
   * @param {FormBuilder} formBuilder
   * @param {MatDialog} matDialog
   * @param {Router} router
   * @param {GCSettingsService} gcsettingsService
   * @param {ActiveCoreService} activeCoreService
   * @param {ActiveLosService} activeLosService
   * @param {CcmcApiService} ccmcApiService
   * @param {AuthService} authService
   * @param {GCSettingsService} gcSettingsService
   * @param {AssetService} assetService
   * @param {NgZone} zone
   * @param {AvatarService} avatarService
   * @param {CCMCNavigationService} navigationService
   * @memberof CCMCLoginComponent
   */
  constructor(
    private CCMCConfig: CCMCConfigService,
    private formBuilder: FormBuilder,
    private matDialog: MatDialog,
    private router: Router,
    private gcsettingsService: GCSettingsService,
    private activeCoreService: ActiveCoreService,
    private activeLosService: ActiveLosService,
    private ccmcApiService: CcmcApiService,
    private authService: AuthService,
    private gcSettingsService: GCSettingsService,
    private assetService: AssetService,
    private zone: NgZone,
    private avatarService: AvatarService,
    private navigationService: CCMCNavigationService,
    private snackBarService: SnackbarService,
    private writeLoggingService: WriteLoggingService,
    private adminApiService: AdminApiService,
    private glaService: GeneralLedgerAccountingService,
    private amplifyService: AmplifyService,
    private dcService: DocumentConnectorService,
    private fieldEditedService: FieldEditedService,
    private requestChangeService: RequestChangeService,
    private spinnerService: SpinnerService,
    private salesforceService: SalesforceService
  ) {
    this.CCMCConfig.setConfig({
      layout: {
        navigation: 'none',
        toolbar: 'none',
        footer: 'none'
      }
    });
    this.loginFormErrors = {
      email: {},
      password: {}
    };
  }

  /**
   * Set Token
   * @description sets the JWT access token
   * @param {*} token
   * @memberof CCMCLoginComponent
   */
  setToken(token: any) {
    // update token locally
    this.token = token;
  }

  /**
   * Get Token
   * @description retrieves the JWT access token
   * @returns
   * @memberof CCMCLoginComponent
   */
  getToken() {
    return this.token;
  }

  /**
   * On Init
   *
   * @memberof CCMCLoginComponent
   */
  ngOnInit() {
    // init login form
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
    // clear user data
    this.amplifyService.logout();
    this.resetNavigation();
    // detect changes in form
    this.formChangeSub = this.loginForm.valueChanges.subscribe(
      () => {
        this.onLoginFormValuesChanged();
      },
      error => console.log(error)
    );

    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        this.showSpinner = spinner;
      });

    this.spinnerService.setShowSpinner(false);
  }

  resetNavigation() {
    // Turn off Admin Navigation Items
    for (let navItem of this.dynamicAdminNav[1].children) {
      navItem.visible = false;
    }
    // Turn off GLA Navigation Items
    for (let navItem of this.dynamicGLANav[1].children) {
      navItem.visible = false;
    }
    // Turn off Standard Navigation Items
    for (let navItem of this.dynamicNav[1].children) {
      navItem.visible = false;
    }
  }

  /**
   * On Login Form Values Changed
   * @description pushes form field errors to loginFormErrors
   * @memberof CCMCLoginComponent
   */
  onLoginFormValuesChanged() {
    for (const field in this.loginFormErrors) {
      if (!this.loginFormErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      this.loginFormErrors[field] = {};

      // Get the control
      const control = this.loginForm.get(field);

      if (control && control.dirty && !control.valid) {
        this.loginFormErrors[field] = control.errors;
      }
    }
  }

  /**
   * Login
   * @description Logs the user in with the given email and password
   * @memberof CCMCLoginComponent
   */
  async login() {
    this.spinnerService.setShowSpinner(true);
    await this.amplifyService.login(
      this.loginForm.value.email.toLowerCase(),
      this.loginForm.value.password,
      this
    );
  }

  /**
   * Handle Cognito
   * @description Handles the response from the cognito signin
   * @param {*} error
   * @param {*} result
   * @returns
   * @memberof CCMCLoginComponent
   */
  async handleCognito(error: any, result: any) {
    var companyResponse: any;
    var userResponse: any;
    // If there is an error
    if (error != null) {
      // error
      this.errorMessage = {
        message: 'Invalid Credentials',
        title: 'Login Error'
      };
      // Display error to screen
      const dialogRef = this.matDialog.open(ErrorDialogComponent, {
        data: this.errorMessage
      });
      dialogRef.afterClosed().subscribe(
        res => { },
        err => console.log(err)
      );
    } else {
      // success
      console.log(result);
      if (result) {
        this.parsedResult = JSON.parse(JSON.stringify(result));
        this.isNXTsoft = this.amplifyService.isNXTsoft;
        this.isNXTsoftDev = this.amplifyService.isNXTsoftDev;
        this.isNXTsoftOperations = this.amplifyService.isNXTsoftOperations;
        this.isNXTsoftSales = this.amplifyService.isNXTsoftSales;
        this.isAdmin = this.amplifyService.isAdmin;
        this.isSuperAdmin = this.amplifyService.isSuperAdmin;
        this.isLoanServicingAdmin = this.amplifyService.isLoanServicingAdmin;
        this.isLoanSystemsAdmin = this.amplifyService.isLoanSystemsAdmin;
        console.log(result);
        // Set logged in as true
        this.authService.isLoggedIn = true;
        // // Set cognitoid
        // const id = ((result || {}).accessToken || {}).payload.sub || '';
        // console.log(id);
      }
      // Set if user has admin permissions
      this.setAdmin(result.idToken.payload['cognito:groups']);
      // Set default navigation object
      this.navigationService.onNavChange.next('default');
      // Set JWT Token
      this.setToken(result.accessToken.jwtToken);
      // Set credentials token
      this.loggedInCreds.token = this.getToken();
      // Set assetIds and return count
      const count: any = this.setAssetIds(result.idToken.payload['custom:AssetID']);
      // Handle user avatar
      this.handleAvatar(result.idToken.payload['picture']);
      // Set assetIDs in asset service
      this.assetService.setAssetids(this.assetIds);
      userResponse = await this.getUserData();
      companyResponse = await this.getAccountData();
      (window as any).pendo.initialize({
        visitor: {
          id: result.idToken.payload.sub,
          email: result.idToken.payload.email,
          firstName: userResponse.firstName,
          lastName: userResponse.lastName,
          assetID: result.idToken.payload['custom:AssetID'],
        },
        account: {
          id: result.idToken.payload['custom:AccountID'],
          companyName: companyResponse
        } 
      });
      // If more than one asset go to asset selector
      if (count > 1) {
        console.log('do route to asset selector');
        return this.router.navigate(['assetid-selector']);
      } else if (
        // If user does not have asset to selected asset
        !this.amplifyService.checkUserAssets(
          result.idToken.payload['custom:AssetID']
        )
      ) {
        this.snackBarService.openSnackBar(
          'User does not have access to asset',
          'Okay'
        );
        this.amplifyService.logout();
      }
      // Else select the asset
      if (result.idToken.payload['custom:AssetID']) {
        // Get Settings for selected asset
        this.gcSettingsService
          .retrieveSettings(result.idToken.payload['custom:AssetID'].trim())
          .subscribe(async setting => {
            // Set Selected AssetId
            this.assetService.setSelectedAssetId(setting.assetId);
            // Set Active Core
            this.activeCoreService.setActiveCore(setting.core);
            // Set Active Los
            this.activeLosService.setActiveLos(setting.los);
            this.activeLosService.selectLos();
            // Set the selected Financial Institution
            this.assetService.setSelectedFI(setting.FI);
            // Set the selected loan path
            this.assetService.setSelectedPath(setting.loanPath);
            // Set the selected product
            this.assetService.setSelectedProduct(setting.product);
            // Sets the product Type for gc settings
            this.gcSettingsService.setProductType(setting.product);
            // Set if the selected has ebm
            this.assetService.setHasEBM(setting.ebm);
            // Set the selceted Core
            this.assetService.setSelectedCore(setting.core);
            // Set the selceted Core Company
            this.assetService.setSelectedCoreCompany(setting.coreCompany);
            // Set the selected LOS
            this.assetService.setSelectedLos(setting.los);
            // Set Can Capture Data
            this.assetService.setCanCaptureData(setting.captureData);
            // Loan Asset information in the navigation bar
            this.navigationService.loadAssetInformation();
            if (!environment.test) {
              await this.setChangeRequestIDs();
            }
            // Init the log stream
            this.getChangeRequestTable();
            this.clearEncompassToken(setting);
            console.log(setting);
            console.log(
              'Settings includes doc connector',
              setting.product.includes('doc_connector')
            );
            switch (setting.product !== undefined) {
              case setting.product.includes('general_ledger_accounting'):
                console.log('Do GLA Stuff here');
                this.adminApiService
                  .getCoreConnection(setting.core)
                  .subscribe(coreConnection => {
                    console.log(coreConnection);
                    if (coreConnection) {
                      const parsedCoreConnection = JSON.parse(
                        JSON.stringify(coreConnection)
                      );
                      if (parsedCoreConnection.statusFlag == false) {
                        return;
                      } else {
                        this.adminApiService
                          .getMappingDocument(parsedCoreConnection)!
                          .subscribe(glaDocument => {
                            console.log('GLA Document', glaDocument);
                            // Set tmpDoc as deep copy of document core
                            // copies without leaving a reference
                            const tmpDoc = JSON.parse(
                              JSON.stringify(glaDocument)
                            );
                            if (tmpDoc.statusFlag) {
                              this.ccmcApiService.checkForSpecificConfig(
                                tmpDoc.document.configurations,
                                'implementationMode',
                                setting
                              );
                              // Set document core
                              this.glaService.setGLAObject(tmpDoc.document);
                              // Assigns core service
                              this.activeCoreService.getActiveCore();
                              // Sets the los string
                              this.activeLosService.activeLos = setting.los;
                              // Update the Navigation depending if encompass
                              this.navigationService.updateNavigation(
                                setting.product,
                                setting.los,
                                setting.core,
                                setting.ebm
                              );
                              if (setting.los === 'encompass' ||
                                setting.los === 'bytepro') {
                                this.router.navigate(['loan-lookup']);
                              } else {
                                this.router.navigate(['file-selector']);
                              }
                            } else {
                              // Set document to empty object
                              this.adminApiService.setDocumentCoreData({});
                              // Open snackbar with error message
                              this.snackBarService.openSnackBar(
                                tmpDoc.statusMessage,
                                'Okay'
                              );
                            }
                          });
                      }
                    }
                  });
                // Pull Core Document
                break;
              case setting.product.includes('doc_connector'):
                console.log('Do Doc Connector Stuff here');
                // Retrieve Los Connection
                this.adminApiService
                  .getLosConnection(setting.los)
                  .subscribe(losConnection => {
                    console.log(losConnection);
                    if (losConnection) {
                      const parsedLosConnection = JSON.parse(
                        JSON.stringify(losConnection)
                      );
                      // If Parsed Los Connection includes statusFlag we know it failed
                      if (parsedLosConnection.statusFlag == false) {
                        return;
                      } else {
                        this.adminApiService
                          .getMappingDocument(parsedLosConnection)!
                          .subscribe(docConnectorDocument => {
                            console.log(
                              'Doc Connector Document',
                              docConnectorDocument
                            );
                            // Set tmpDoc as deep copy of document core
                            // copies without leaving a reference
                            const parsedDocumentResponse = JSON.parse(
                              JSON.stringify(docConnectorDocument)
                            );
                            this.spinnerService.setShowSpinner(false);
                            if (parsedDocumentResponse.statusFlag) {
                              // Set Document Connector Document
                              this.dcService.setDocumentConnectorDocument(
                                parsedDocumentResponse.document
                              );
                              // Assigns core service
                              this.activeCoreService.getActiveCore();
                              // Sets the los string
                              this.activeLosService.activeLos = setting.los;
                              // Update the Navigation depending if encompass
                              this.navigationService.updateNavigation(
                                setting.product,
                                setting.los,
                                setting.core,
                                setting.ebm
                              );
                              // Update base url for communicators and ice
                              this.router.navigate(['doc-connector/history']);
                            }
                          });
                      }
                    }
                  });
                break;
              default:
                console.log('Do Standard Stuff here');
                this.adminApiService
                  .getCoreConnection(setting.core)
                  .subscribe(coreConnection => {
                    console.log('Core Connection', coreConnection);
                    if (coreConnection) {
                      const parsedConnection = JSON.parse(
                        JSON.stringify(coreConnection)
                      );
                      if (parsedConnection.statusFlag == false) {
                        return;
                      } else {
                        // Get the document for the selected asset
                        this.adminApiService
                          .getMappingDocument(parsedConnection)!
                          .subscribe(documentCore => {
                            // Set tmpDoc as deep copy of document core
                            // copies without leaving a reference
                            let tmpDoc = JSON.parse(
                              JSON.stringify(documentCore)
                            );
                            // If document was successful
                            if (tmpDoc.statusFlag) {
                              this.ccmcApiService.configurations =
                                tmpDoc.document.configurations;
                              this.ccmcApiService.search = tmpDoc.document.search;
                              this.ccmcApiService.checkForSpecificConfig(
                                tmpDoc.document.configurations,
                                'implementationMode',
                                setting
                              );
                              this.fieldEditedService.impModechecked.next(
                                tmpDoc.document.configurations
                                  .implementationMode
                              );
                              // Set document core
                              this.adminApiService.setDocumentCoreData(
                                tmpDoc.document
                              );

                              this.fieldEditedService.copyNameCheck.next(
                                tmpDoc.document.configurations
                                  .overwriteBorrNamesWithSearchResults
                              );
                              // Assigns core service
                              this.activeCoreService.getActiveCore();
                              // Update the Navigation depending if encompass
                              this.navigationService.updateNavigation(
                                setting.product,
                                setting.los,
                                setting.core,
                                setting.ebm
                              );
                              // Update base url for communicators
                              
                              if (setting.los === 'encompass' ||
                                setting.los === 'bytepro') {
                                this.router.navigate(['loan-lookup']);
                              } else {
                                this.router.navigate(['file-selector']);
                              }
                            } else {
                              // Set document to empty object
                              this.adminApiService.setDocumentCoreData({});
                              // Open snackbar with error message
                              this.snackBarService.openSnackBar(
                                tmpDoc.statusMessage,
                                'Okay'
                              );
                            }
                          });
                      }
                    }
                  });
            }
          });
      }
    }
  }

  initLogger(username: any, assetFI: any) {
    const stream = assetFI + '-' + username;
    this.writeLoggingService.setStream(stream);
    this.writeLoggingService
      .writeLog({
        action: 'Logged in'
      })
      .subscribe(result => {
        // logging result
        console.log(result);
      });
  }

  testing() {
    this.router.navigate(['file-selector']);
  }

  /**
   * Set Assetids
   * @description returns the count for the total number of assetIds
   * @param {*} ids
   * @returns
   * @memberof CCMCLoginComponent
   */
  setAssetIds(ids: any) {
    let idCount = 0;
    if (ids) {
      ids.split(', ').forEach((splitId: any) => {
        idCount++;
        console.log(splitId);
        return this.assetIds.push(splitId);
      });
      return idCount;
    } else {
      const errorMessage = {
        message: 'No assets have been assigned to this user. \nContact your Ground Control administrator.',
        title: 'Asset Error'
      };
      const dialogRef1 = this.zone.run(() => {
        this.matDialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      });
    }
  }

  /**
   * Set Los
   * @description retrieves the los
   * @param {string} assetId
   * @memberof CCMCLoginComponent
   */
  setLos(assetId: string) {
    // retrieves selected assetIDs settings
    this.gcSettingsSubscription = this.gcsettingsService
      .retrieveSettings(assetId)
      .subscribe(
        settings => {
          this.gcsettingsService.Los = settings.los;
        },
        err => { },
        () => {
          if (this.activeLosService.activeLos === 'encompass' || this.activeLosService.activeLos === 'bytepro') {
            this.router.navigate(['loan-lookup']);
          } else {
            this.router.navigate(['file-selector']);
          }
        }
      );
  }

  /**
   * On Destroy
   * @memberof CCMCLoginComponent
   */
  ngOnDestroy() {
    if (this.gcSettingsSubscription) {
      this.gcSettingsSubscription.unsubscribe();
    }
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  /**
   * Handle Avatar
   * @description Handles avatar action and sets the users avatar
   * @param {*} avatar
   * @memberof CCMCLoginComponent
   */
  handleAvatar(avatar: any) {
    // If the user has an avatar
    if (avatar) {
      this.avatarService.setAvatar(avatar);
    } else {
      // If user doesnt set their avatar as basic for default
      const defaultAvatar = 'basic';
      // Update their avatar in cognito
      this.avatarService.updateAvatar(defaultAvatar).subscribe(result => {
        if (result) {
          // Set the avatar
          this.avatarService.setAvatar(defaultAvatar);
        }
      });
    }
  }

  setAdmin(groups: any) {
    if (groups) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < groups.length; i++) {
        if (groups[i] === 'nxtsoft') {
          this.amplifyService.isNXTsoft = true;
        }
        if (groups[i] === 'nxtsoft-sales') {
          this.amplifyService.isNXTsoftSales = true;
        }
        if (groups[i] === 'client-admin') {
          this.amplifyService.isAdmin = true;
        }
        if (groups[i] === 'nxtsoft-dev') {
          this.amplifyService.isNXTsoftDev = true;
        }
        if (groups[i] === 'nxtsoft-operations') {
          this.amplifyService.isNXTsoftOperations = true;
        }
        if (groups[i] === 'super-admin') {
          this.amplifyService.isSuperAdmin = true;
        }
        // if (groups[i] === 'loan-servicing-admin') {
        //   this.amplifyService.isLoanServicingAdmin = true;
        // }
        // if (groups[i] === 'loan-systems-admin') {
        //   this.amplifyService.isLoanSystemsAdmin = true;
        // }
      }
    } else {
      this.amplifyService.isNXTsoft = false;
      this.amplifyService.isAdmin = false;
      this.amplifyService.isNXTsoftDev = false;
      this.amplifyService.isNXTsoftOperations = false;
      this.amplifyService.isNXTsoftSales = false;
      this.amplifyService.isSuperAdmin = false;
      this.amplifyService.isLoanServicingAdmin = false;
      this.amplifyService.isLoanSystemsAdmin = false;
    }
  }

  getChangeRequestTable() {
    this.requestChangeService.getDynamoTable();
    this.changeRequestFieldsSub = this.requestChangeService.changeRequestDoc
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(doc => {
        this.docFields = doc.fields;
        this.requestChangeService.onChangeRequestFields.next(this.docFields);
      });
    this.changeRequestStatusesSub = this.requestChangeService.changeRequestDocStatuses
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(result => {
        this.docStatuses = result.statuses
      });
  }

  async setChangeRequestIDs() {
    return new Promise(resolve => {
      this.ccmcApiService
        .getDynamoDBItemsByIndex(
          'change_request_log',
          this.assetService.getSelectedAssetId(),
          'assetID'
        )
        .subscribe(changeReqRes => {
          let parsedChangeReqRes = JSON.parse(JSON.stringify(changeReqRes));
          if (parsedChangeReqRes) {
            console.log(parsedChangeReqRes);
            for(let changeRequestItem of parsedChangeReqRes.content.Items) {
              if (changeRequestItem.assetID) {
                  if (!changeRequestItem.changeRequestID) {
                      changeRequestItem.changeRequestID = uuidv4();
                    }
                  
                  this.ccmcApiService
                    .createDynamoDB('prod', 'change_request_log', changeRequestItem)
                    .subscribe(res => {
                      resolve(true);
                    });
              }
            }
          } else {
            resolve(true);
          }
        });
    });
  }

  clearEncompassToken(setting: any) {
    if (setting.los === 'encompass') {
      console.log('Clear Encompass Token');
      this.activeLosService.activeLosService.setAccessToken(undefined);
    }
  }

  getUserData() {
    this.salesforceService.setAccountID(this.parsedResult.idToken.payload['custom:AccountID']);
    return new Promise<any>((resolve, reject) => {
      this.salesforceService.getContactData().subscribe(result => {
        const parsedResult = JSON.parse(result.StatusMessage);
        for (let i = 0; i < parsedResult.length; i++) {
          if (parsedResult[i].contactEmail == this.parsedResult.idToken.payload.email) {
            this.user.firstName = parsedResult[i].contactFirstName;
            this.user.lastName = parsedResult[i].contactLastName;
          }
        }
        return resolve(this.user);
      });
    });
  }

  getAccountData() {
    return new Promise<any>((resolve, reject) => {
      this.salesforceService.getAccountData().subscribe(result => {
        const parsedResult = JSON.parse(result.StatusMessage);
        this.companyName = parsedResult.FIName;
        return resolve(this.companyName);
      });
    });
  }
}
