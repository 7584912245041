<div class="edit-global-meta-Container">
  <div
    class="moveContainer"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  ></div>
  <h1 matDialogTitle>Edit Destination Index</h1>
  <div class="formContainer">
    <div
      style="display: grid; grid-template-columns: 1fr 1fr; column-gap: 10px"
    >
      <mat-form-field style="padding-right: 30px">
        <input
          matInput
          #nameInput
          placeholder="Name"
          [(ngModel)]="localSNDGlobalMeta.name"
          (keyup.enter)="saveGlobalMeta()"
        />
      </mat-form-field>
      <mat-form-field style="padding-right: 30px">
        <input
          matInput
          #sourceInput
          type="number"
          placeholder="Order"
          [(ngModel)]="localSNDGlobalMeta.index"
          (keyup.enter)="saveGlobalMeta()"
        />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="width: 100%">
        <input
          matInput
          #valueInput
          placeholder="Value"
          [(ngModel)]="localSNDGlobalMeta.value"
          style="width: 100%"
          (keyup.enter)="saveGlobalMeta()"
        />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="width: 100%">
        <input
          matInput
          #valueInput
          style="width: 100%"
          placeholder="Path"
          [(ngModel)]="localSNDGlobalMeta.path"
          (keyup.enter)="saveGlobalMeta()"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="Btn-Container">
    <button
      class="cancelBtn-globalMeta"
      mat-stroked-button
      (click)="dialogRef.close('canceled')"
    >
      Cancel
    </button>
    <div>
      <button
        mat-raised-button
        matTooltip="Get Encompass path."
        matTooltipClass="toolTipStyle"
        (click)="getEncompassPath()"
        color="primary"
      >
        Get Path
      </button>
      &nbsp;
      <button
        class="add-global-metaBtn"
        mat-raised-button
        (click)="removeSNDGlobalMeta()"
        style="margin-right: 5px"
      >
        <mat-icon style="color: #3fb2c6">remove_circle</mat-icon> &nbsp; Remove
      </button>
      <button
        class="add-global-metaBtn"
        mat-raised-button
        (click)="saveGlobalMeta()"
      >
        <mat-icon style="color: #3fb2c6">add_circle</mat-icon> &nbsp; Save
      </button>
    </div>
  </div>
</div>
