import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { CCMCSharedModule } from 'src/@ccmc/shared.module';
import { DocConnectorGlobalMetasComponent } from './doc-connector-global-metas.component';
import { EditGlobalMetaComponent } from './edit-global-meta/edit-global-meta.component';

const routes: Routes = [
  {
    path: 'doc-connector/global-metas',
    component: DocConnectorGlobalMetasComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [DocConnectorGlobalMetasComponent, EditGlobalMetaComponent],
  imports: [RouterModule.forChild(routes), CCMCSharedModule],
  exports: [DocConnectorGlobalMetasComponent]
})
export class DocConnectorGlobalMetasModule {}
