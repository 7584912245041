import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { CCMCSearchBorrowerDialogComponent } from '../search-borrower-dialog/search-borrower-dialog.component';
import { UpdatePremierCredentialsComponent } from '../update-premier-credentials/update-premier-credentials.component';

@Component({
  selector: 'app-select-premier-credentials',
  templateUrl: './select-premier-credentials.component.html',
  styleUrls: ['./select-premier-credentials.component.scss']
})
export class SelectPremierCredentialsComponent implements OnInit {
  premierUserCredentialItem: any;
  showPassword = false;
  localPremierUserCredentialItem: any;
  editedFlag = false;
  isNXTsoftOperations = false;
  isNXTsoftDev = false;
  isNXTsoftSales = false;
  isAdmin = false;
  isSuperAdmin = false;
  fiservUserTable: any[] = [];
  finalPremierTable: any[] = [];
  selectedRow: any;
  assetID: any;
  fiservTableKeys: any;
  constructor(
    private amplifyService: AmplifyService,
    private ccmcApiService: CcmcApiService,
    private snackBarService: SnackbarService,
    private dialogRef: MatDialogRef<SelectPremierCredentialsComponent>,
    private adminApiService: AdminApiService,
    private dialog: MatDialog,
    private assetService: AssetService
  ) {}

  ngOnInit(): void {
    this.localPremierUserCredentialItem = {
      cognitoID: this.amplifyService.getCognitoId(),
      cStation: '',
      password: '',
      username: '',
      assetID: this.assetService.getSelectedAssetId()
    };
    this.selectedRow = {
      cStation: '',
      cognitoID: '',
      password: '',
      username: '',
      assetID: this.assetService.getSelectedAssetId()
    };
    this.getPremierCredentialsTable();
    this.isNXTsoftOperations = this.amplifyService.isNXTsoftOperations;
    this.isNXTsoftSales = this.amplifyService.isNXTsoftSales;
    this.isNXTsoftDev = this.amplifyService.isNXTsoftDev;
    this.isAdmin = this.amplifyService.isAdmin;
    this.isSuperAdmin = this.amplifyService.isSuperAdmin;
  }

  getPremierCredentialsTable() {
    this.adminApiService
      .getDynamoDB('fiserv_premier_users')
      .subscribe(result => {
        if (result) {
          const parsedResult = JSON.parse(JSON.stringify(result));
          this.fiservUserTable = parsedResult;
          for (let fiservUser of this.fiservUserTable) {
            if (fiservUser.assetID != undefined) {
              if (
                fiservUser.assetID.includes(
                  this.assetService.getSelectedAssetId()
                )
              ) {
                this.finalPremierTable.push(fiservUser);
              }
            }
          }
        }
      });
  }

  continue() {
    this.selectedRow.cognitoID = this.amplifyService.getCognitoId();
    if (this.editedFlag) {
      this.ccmcApiService
        .createDynamoDB('test', 'fiserv_premier_users', this.selectedRow)
        .subscribe(result => {
          if (result) {
            this.dialogRef.close(true);
          }
        });
    }
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  checkPremCreds() {
    this.editedFlag = true;
  }

  isEquivalent(a: any, b: any) {
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];
      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
        return false;
      }
    }
    return true;
  }

  openPremierCredentialsDialog() {
    const updatePremierCredentialsDialogRef = this.dialog.open(
      UpdatePremierCredentialsComponent,
      {
        panelClass: 'suffix-search__panel-dialog'
      }
    );
    updatePremierCredentialsDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dialog.closeAll();
        this.openSearchBorrowerDialog();
      }
    });
  }

  openSearchBorrowerDialog() {
    const searchBorowerDialogRef = this.dialog.open(
      CCMCSearchBorrowerDialogComponent,
      {
        data: {},
        panelClass: 'search__panel-dialog'
      }
    );
  }
  getUserAssets(assetID: any) {
    console.log(assetID);
  }
}
