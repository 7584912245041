import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FindAndReplaceDialogComponent } from './find-and-replace-dialog.component';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';
import { FormsModule } from '@angular/forms';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [FindAndReplaceDialogComponent],
  imports: [
    AppMaterialModule,
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    CodemirrorModule
  ],
  entryComponents: [FindAndReplaceDialogComponent]
})
export class FindAndReplaceDialogModule {}
