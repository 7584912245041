import { Pipe, PipeTransform } from '@angular/core';

/**
 * Adds spaces after commas
 *
 * @export
 * @class AddSpaceAfterCommaPipe
 * @implements {PipeTransform}
 */
@Pipe({ name: 'removeNegativeFromString' })
export class RemoveNegativeFromString implements PipeTransform {
  /**
   * Transforms camelcase to dashes
   *
   * @param {string} value
   * @param {any[]} [args=[]]
   * @returns
   * @memberof AddSpaceAfterCommaPipe
   */
  transform(value: string) {
    return value.replace('-', '');
  }
}
