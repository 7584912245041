import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatButtonModule } from '@angular/material/button';

import { CCMCNavigationComponent } from './navigation.component';
import { CCMCNavVerticalItemComponent } from './vertical/nav-item/nav-vertical-item.component';
import { CCMCNavVerticalCollapseComponent } from './vertical/nav-collapse/nav-vertical-collapse.component';
import { CCMCNavVerticalGroupComponent } from './vertical/nav-group/nav-vertical-group.component';
import { CCMCNavHorizontalItemComponent } from './horizontal/nav-item/nav-horizontal-item.component';
import { CCMCNavHorizontalCollapseComponent } from './horizontal/nav-collapse/nav-horizontal-collapse.component';
import { CCMCPipesModule } from '../../pipes/pipes.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CCMCPipesModule,
    MatIconModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    ClipboardModule,
    MatSlideToggleModule
  ],
  exports: [CCMCNavigationComponent],
  declarations: [
    CCMCNavigationComponent,
    CCMCNavVerticalGroupComponent,
    CCMCNavVerticalItemComponent,
    CCMCNavVerticalCollapseComponent,
    CCMCNavHorizontalItemComponent,
    CCMCNavHorizontalCollapseComponent
  ]
})
export class CCMCNavigationModule {}
