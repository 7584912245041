import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CCMCCollateralDetailsDialogComponent } from './collateral-details-dialog.component';
import { AppMaterialModule } from '../../../../app/app-material/app-material.module';

@NgModule({
  imports: [CommonModule, AppMaterialModule],
  declarations: [CCMCCollateralDetailsDialogComponent],
  entryComponents: [CCMCCollateralDetailsDialogComponent]
})
export class CCMCCollateralDetailsDialogModule {}
