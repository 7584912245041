export enum Navigation {
    left = 'left',
    right = 'right',
    top = 'top',
    none = 'none'
}

export enum Toolbar {
    above = 'above',
    below = 'below',
    none = 'none'
}

export enum Animation {
    fadeIn = 'fadeIn',
    slideUp = 'slideUp',
    slideDown = 'slideDown',
    slideRight = 'slideRight',
    slideLeft = 'slideLeft',
    none = 'none'
}

export enum Footer {
    below = 'below',
    above = 'above',
    none = 'none'
}

export enum Mode {
    fullwidth = 'fullwidth',
    boxed = 'boxed'
}

export interface Config {
    layout?: {
        navigation?: Navigation;
        navigationFolded: boolean;
        toolbar?: Toolbar;
        footer?: Footer;
        mode?: Mode;
    };
    colorClasses?: {
        toolbar: string;
        navbar: string;
        footer: string;
    };
    customScrollbars?: boolean;
    routerAnimation?: Animation;
}
