<div class="section" *ngIf="currentIndex !== 0">
  <div class="bottomTitle">
    Selection Details
    <button
      mat-raised-button
      class="titleSave"
      [disabled]="!editedFlag || showSpinner"
      (click)="save()"
    >
      Save
    </button>
  </div>
  <div
    [ngClass]="isNXTsoftDev || isNXTsoftOp ? 'fullView' : 'fullViewCustomer'"
  >
    <div class="columnssorting" *ngIf="isNXTsoftDev || isNXTsoftOp">
      <mat-form-field style="padding-right: 30px">
        <div *ngIf="isOrigenate == 'origenate'; else elseBlock">
          <input
            matInput
            #sourceInput
            placeholder="Application Number"
            [(ngModel)]="selectedRow.loanNumber"
            (ngModelChange)="fieldEdited()"
          />
        </div>
        <ng-template #elseBlock>
          <input
            matInput
            #sourceInput
            placeholder="Loan Number/File Number"
            [(ngModel)]="selectedRow.loanNumber"
            (ngModelChange)="fieldEdited()"
          />
        </ng-template>
      </mat-form-field>
      <mat-form-field style="padding-right: 30px">
        <input
          matInput
          #sourceInput
          placeholder="FieldID"
          [(ngModel)]="selectedRow.fieldID"
          (ngModelChange)="fieldEdited()"
        />
      </mat-form-field>
      <mat-form-field style="padding-right: 30px">
        <input
          matInput
          #sourceInput
          placeholder="Field Display"
          [(ngModel)]="selectedRow.fieldDisplay"
          (ngModelChange)="fieldEdited()"
        />
      </mat-form-field>
    </div>
    <div class="columnssorting">
      <mat-form-field style="padding-right: 30px">
        <input
          matInput
          #sourceInput
          placeholder="Field Value (corrected)"
          [(ngModel)]="selectedRow.correctedValue"
          (ngModelChange)="fieldEdited()"
        />
      </mat-form-field>
      <mat-form-field style="padding-right: 6%">
        <mat-label>Item Status</mat-label>
        <mat-select
          [(ngModel)]="selectedRow.status"
          name="status"
          (ngModelChange)="fieldEdited()"
        >
          <mat-option *ngFor="let status of statuses" [value]="status">
            {{ status }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field style="padding-right: 6%">
        <mat-label>Item Priority</mat-label>
        <mat-select
          [(ngModel)]="selectedRow.urgency"
          name="urgency"
          (ngModelChange)="fieldEdited()"
        >
          <mat-option *ngFor="let ergency of urgency" [value]="ergency.value">
            {{ ergency.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!-- Notes Section -->
  <div class="notes-section">
    <div class="table-container2">
      <!-- Notes table -->
      <table
      id="notes-table"
      mat-table
      [dataSource]="notesTable"
      style="width: 100%;table-layout: fixed;">
            <ng-container matColumnDef="notes">
              <th mat-header-cell *matHeaderCellDef style="text-align: left; font-size: 18px;">Notes</th>
              <td mat-cell *matCellDef="let element" style="display: flex;">
                <button
                mat-icon-button
                (click)="deleteNote(element.date)"
                style="transform: translate(20.5vw, -0.25em);"
                >
                  <mat-icon style="color: red">delete</mat-icon>
                </button>
                <div style="transform: translate(-3em, 0em);">
                  <span style="color: #009eb9">{{element.username}}</span> <br>({{element.date | date: 'M/dd/yy hh:mm:ss a'}}): <br>{{ element.notes }} 
                </div>
              </td>
          </ng-container>
          <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns2; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns2"></tr>
      </table>
    </div>
    <div>
      <!-- Input box -->
      <mat-form-field floatLabel = "never" id="currentException" style="width: 82%; height: 10em; float: left;">
        <mat-label style="display: flex; margin-top: .5em; margin-left: .5em;">Add notes or comments here</mat-label>
        <textarea 
          matInput
          style="
          width: 94%;
          height: 5em;
          padding: .5em 0.5em 0em 0.5em;
          border: 1px solid lightgrey;
          background-color: white;
          "
          [(ngModel)]="currentNote"
          [disabled]="!isRowSelected">
        </textarea>
      </mat-form-field>
    </div>
  </div>
  <div>
    <!-- Submit button -->
    <button 
    style="clear: both; float: right; margin-top: -10em;"
    mat-raised-button color="accent"
    matTooltip="Submit current note"
    (click)="onNotesSubmit(); save();"
    [disabled]="!isRowSelected"
    >
      <!-- <i class="fa-sharp fa-solid fa-paper-plane" style="color: white; font-size: 21px;"></i> -->
      <mat-icon style="color: white; font-size: 21px;">send</mat-icon>
    </button>
  </div>
  <!--Spinner-->
  <!-- <div class="spinner-container" *ngIf="showSpinner">
        <div class="new-spinner">
            <div class="outer-ring"></div>
            <div class="middle-ring"></div>
            <div class="inner-ring"></div>
        </div>
    </div> -->
</div>
