<div class="search">
    <div>
        <h1 style="color: white">Select a New Suffix</h1>
    </div>
    <mat-table class="suffix-table" #searchTable [dataSource]="suffixDataSource" matSort #searchSort="matSort">
        <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="cell-text-styling">Loan / Share</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.description }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="suffix">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="cell-text-styling">Suffix</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.suffix }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="diplayedSuffixsColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: diplayedSuffixsColumns" [style.background]="selectedRow === row ? '#3FB2C6' : ''" (click)="select(row)"></mat-row>
    </mat-table>
    <div class="checkbox-row">
        <div>
            <mat-checkbox type="checkbox" [(ngModel)]="showLoanSuffixes" (change)="filterSuffixes()">Show Loan Suffixes</mat-checkbox>
        </div>
        <div>
            <mat-checkbox type="checkbox" [(ngModel)]="showShareSuffixes" (change)="filterSuffixes()">Show Share Suffixes</mat-checkbox>
        </div>
    </div>
    <div class="new-suffix-row">
        <div>
            <mat-form-field style="width: 90%">
                <input matInput #sourceInput placeholder="New Account Suffix" [(ngModel)]="newAccountSuffix" />
            </mat-form-field>
        </div>
    </div>
    <!--botton row of buttons-->
    <div id="bottom-button-container">
        <!--Cancel Button-->
        <button mat-stroked-button class="cancel-btn" (click)="closeDialog()" id="cancel-button">
      Cancel
    </button>

        <!--Continue Button-->
        <div style="text-align: right">
            <button mat-raised-button class="continue-btn" (click)="continue()" id="continue-button">
        <span class="button-text">Continue &nbsp;</span>
        <mat-icon class="continue-icon">forward </mat-icon>
      </button>
        </div>
    </div>

    <!--Spinner-->
    <div class="spinner-container" *ngIf="showSpinner">
        <div class="new-spinner">
            <div class="outer-ring"></div>
            <div class="middle-ring"></div>
            <div class="inner-ring"></div>
        </div>
    </div>
</div>