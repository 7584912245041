<div style="height: 7%; background-color: #ffffff">
  <mat-form-field class="filter-FormField" style="width: 80%; padding-left: 1%">
    <input matInput placeholder="Filter Indexes" #metafilterSearch />
  </mat-form-field>
</div>
<mat-toolbar style="height: 56px">Indexes</mat-toolbar>
<div class="table-container">
  <table
    mat-table
    class="table-TableContainer"
    #table
    [dataSource]="metasDataSource"
    CCMCPerfectScrollbar
    matSort
  >
    <!-- Field ID Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td
        mat-cell
        *matCellDef="let element"
        class="noselect"
        style="width: 30%"
      >
        {{ element.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="index">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Order</th>

      <td
        mat-cell
        *matCellDef="let element"
        class="noselect"
        style="width: 10%"
      >
        {{ element.index }}
      </td>
    </ng-container>

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Value</th>

      <td
        mat-cell
        *matCellDef="let element"
        class="noselect"
        style="width: 30%"
      >
        {{ element.value }}
      </td>
    </ng-container>

    <ng-container matColumnDef="path">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Path</th>

      <td
        mat-cell
        *matCellDef="let element"
        class="noselect"
        style="width: 30%"
      >
        {{ element.path }}
      </td>
    </ng-container>

    <!-- Remove Column -->
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef>
        <div class="AddBtn-Container">
          <button
            class="Add-btn"
            matTooltip="Add a new Index"
            matTooltipClass="toolTipStyle"
            style="cursor: pointer"
            (click)="addNewGlobalMeta()"
          >
            <mat-icon style="color: #3fb2c6">add_circle</mat-icon>
          </button>
        </div>
      </th>
      <td mat-cell *matCellDef="let element" class="noselect">
        <mat-icon
          style="
            margin-top: 1px;
            margin-right: 8%;
            float: right;
            color: #aaaaaa;
            cursor: pointer;
          "
          (click)="editGlobalMeta(element)"
          >edit</mat-icon
        >
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="metasDisplayedColumns; sticky: true"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: metasDisplayedColumns"
      (click)="onSelectMeta(row)"
      [ngClass]="{ 'accent-bg': row == selectedMeta }"
    ></tr>
  </table>
</div>
<div class="paginatorContainer">
  <mat-paginator
    [pageSizeOptions]="[100]"
    showFirstLastButtons
    class="paginator"
  >
  </mat-paginator>
</div>
<div class="bottom-container" style="height: 59%">
  <div
    style="
      position: relative;
      overflow-y: auto;
      border: 1px solid #8d8d8d;
      background: #f7f7f7;
    "
  >
    <mat-toolbar style="background-color: white; height: 56px; display: block; padding-top: 0.5em;">
      Sources
      <div class="AddBtn-Container">
        <button
          class="Add-btn"
          matTooltip="Add a New Source"
          matTooltipClass="toolTipStyle"
          (click)="addNewSource()"
        >
          <mat-icon style="color: #3fb2c6">add_circle</mat-icon>
        </button>
      </div>
    </mat-toolbar
    >
    <table
      mat-table
      class="table-TableContainer"
      #table
      [dataSource]="sourcesDataSource"
      CCMCPerfectScrollbar
    >
      <!-- Field ID Column -->
      <ng-container matColumnDef="source">
        <th
          mat-header-cell
          *matHeaderCellDef
          style="font-size: 20px; color: black"
        >
          <mat-form-field class="filter-FormField">
            <input
              matInput
              placeholder="Filter Sources"
              ngModel
              (ngModelChange)="applyFilterOnSources($event)"
            />
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element" class="noselect">
          {{ element }}
        </td>
      </ng-container>

      <!-- Remove Column -->
      <ng-container matColumnDef="remove">
        <th mat-header-cell *matHeaderCellDef class="noselect">
          <mat-icon
            style="
              float: right;
              color: #aaaaaa;
            "
            (click)="removeSource()"
            matTooltip="Remove selected Sources"
            >remove_circle</mat-icon>
        </th>
        <td mat-cell *matCellDef="let element" class="noselect">
          <mat-icon
            style="
              margin-top: 1px;
              margin-right: 8%;
              float: right;
              color: gray;
              cursor: pointer;
            "
            (click)="copySource(element)"
            matTooltip="Copy Source Item"
            >content_copy</mat-icon>
        </td>
      </ng-container>
      <tr
        mat-header-row
        *matHeaderRowDef="sourcesDisplayedColumns; sticky: true"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: sourcesDisplayedColumns"
        (click)="multiSelectSources(row, $event)"
        (dblclick)="openEditSourceDialog(row)"
        [ngClass]="{ 'accent-bg': selectedSource.includes(row) }"
      ></tr>
    </table>
  </div>
  <div style="border: 1px solid #8d8d8d">
    <mat-toolbar style="height: 56px">Destination Configurations</mat-toolbar>
    <div style="padding: 15px">
      <div style="width: 100%">
        <button
          mat-raised-button
          matTooltip="Saves any changes you make, within this section."
          matTooltipClass="toolTipStyle"
          color="accent"
          (click)="saveDestinationConfiguration()"
          [disabled]="!editedFlag"
          style="margin-left: 90%; width: 90px; margin-top: 5px"
        >
          Save
        </button>
      </div>
      <div
        style="display: grid; grid-template-rows: 1fr 1fr; column-gap: 30px"
      ></div>
      <div
        style="
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          column-gap: 30px;
        "
      >
        <mat-form-field>
          <input
            matInput
            #nameInput
            placeholder="ID"
            (ngModelChange)="destinationFieldEdited()"
            [(ngModel)]="localSelectedDestination.id"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Join Images</mat-label>
          <mat-select
            [(value)]="localSelectedDestination.joinImages"
            (selectionChange)="destinationFieldEdited()"
          >
            <mat-option [value]="true"> True </mat-option>
            <mat-option [value]="false"> False </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Separate Pages</mat-label>
          <mat-select
            [(value)]="localSelectedDestination.separatePages"
            (selectionChange)="destinationFieldEdited()"
          >
            <mat-option [value]="true"> True </mat-option>
            <mat-option [value]="false"> False </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        style="
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          column-gap: 30px;
        "
      >
        <mat-form-field>
          <input
            matInput
            #nameInput
            type="number"
            placeholder="Order Index"
            (ngModelChange)="destinationFieldEdited()"
            [(ngModel)]="localSelectedDestination.orderIndex"
          />
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            #nameInput
            placeholder="New File Name"
            [(ngModel)]="localSelectedDestination.newFileName"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>New File Type</mat-label>
          <mat-select
            [(value)]="localSelectedDestination.newFileType"
            (selectionChange)="destinationFieldEdited()"
          >
            <mat-option [value]="'pdf'"> PDF </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        style="
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          column-gap: 30px;
        "
      >
      <mat-form-field>
        <mat-label>Export Job</mat-label>
        <mat-select
          [(value)]="localSelectedDestination.exportJob"
          (selectionChange)="createExportJobField();destinationFieldEdited()"
        >
          <mat-option [value]="true"> True </mat-option>
          <mat-option [value]="false"> False </mat-option>
        </mat-select>
      </mat-form-field>
      </div>
    </div>
  </div>
</div>
