<div class="new-global-meta-Container">
  <div
    class="moveContainer"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  ></div>
  <div
    style="display: flex; flex-direction: row; justify-content: space-between"
  >
    <div>
      <h1>New Global Index</h1>
    </div>
    <div>
      <h1>
        <i class="material-icons noselect" (click)="closeDialog()"> close </i>
      </h1>
    </div>
  </div>
  <div class="formContainer">
    <div>
      <mat-form-field style="padding-right: 30px">
        <input
          matInput
          #nameInput
          placeholder="Name"
          [(ngModel)]="tempGlobalMeta.name"
          (keyup.enter)="addGlobalMeta()"
        />
      </mat-form-field>
      <mat-form-field style="padding-right: 30px">
        <input
          matInput
          #sourceInput
          placeholder="Index"
          [(ngModel)]="tempGlobalMeta.index"
          (keyup.enter)="addGlobalMeta()"
        />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input
          matInput
          #valueInput
          placeholder="Value"
          [(ngModel)]="tempGlobalMeta.value"
          (keyup.enter)="addGlobalMeta()"
        />
      </mat-form-field>
    </div>
  </div>

  <div class="Btn-Container">
    <button
      class="cancelBtn-globalMeta"
      mat-stroked-button
      (click)="closeDialog()"
    >
      Cancel
    </button>

    <button
      class="add-global-metaBtn"
      mat-raised-button
      (click)="addGlobalMeta()"
    >
      <mat-icon style="color: #3fb2c6">add_circle</mat-icon> &nbsp; Add Global
      Index
    </button>
  </div>
</div>
