import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts word to Word
 *
 * @export
 * @class CamelCaseToDashPipe
 * @implements {PipeTransform}
 */
@Pipe({ name: 'uppercaseFirstLetterPipe' })
export class UppercaseFirstLetterPipe implements PipeTransform {
  /**
   * Transforms camelcase to dashes
   *
   * @param {string} value
   * @param {any[]} [args=[]]
   * @returns
   * @memberof CamelCaseToDashPipe
   */
  transform(value: string, args: any[] = []) {
    var finalResult = value.charAt(0).toUpperCase() + value.slice(1);
    return finalResult;
  }
}
