import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DocumentConnectorService } from 'src/@ccmc/services/doc-connector.service';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { AddGlobalMetaDialogComponent } from '../add-global-meta-dialog/add-global-meta-dialog.component';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';

@Component({
  selector: 'app-edit-source-dialog',
  templateUrl: './edit-source-dialog.component.html',
  styleUrls: ['./edit-source-dialog.component.scss']
})
export class EditSourceDialogComponent implements OnInit {
  tempSource;
  tempDocument: any;
  selectedDestinationSub: Subscription;
  unsubscribe: Subject<any> = new Subject();
  selectedDestination: any;
  sources: any;
  constructor(
    private dcService: DocumentConnectorService,
    private selectedFieldService: CCMCSelectedFieldService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AddGlobalMetaDialogComponent>,
    private fieldEditedService: FieldEditedService,
    private snackBarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public source: any
  ) {
    this.tempSource = JSON.parse(JSON.stringify(source));
  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  getData() {
    // Subscribe to the document and grab the support values
    this.selectedDestinationSub =
      this.selectedFieldService.onDestinationFieldSelected
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(destination => {
          if (destination) {
            console.log(destination);
            this.selectedDestination = destination;
            this.sources = this.selectedDestination.sources;
          }
        });
  }

  saveSource() {
    if (this.checkDuplicateSource()) {
      const errorDialogRef = this.dialog.open(ErrorDialogComponent, {
        data: {
          title: 'Duplicates',
          message: 'Duplicate source found.'
        }
      });
      return;
    } else {
      this.source = this.tempSource;
      this.dialogRef.close(this.source);
    }
  }

  checkDuplicateSource() {
    if (this.sources.includes(this.tempSource)) {
      return true;
    } else {
      return false;
    }
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
}
