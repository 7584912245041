import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditSetTransactionDialogComponent } from './edit-set-transaction-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';

@NgModule({
  declarations: [EditSetTransactionDialogComponent],
  imports: [AppMaterialModule, CommonModule, FormsModule, ReactiveFormsModule],
  entryComponents: [EditSetTransactionDialogComponent]
})
export class EditSetTransactionDialogModule {}
