import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { CcmcApiService } from '../../../../@ccmc/services/ccmc-api.service';
import { CCMCSelectedFieldService } from '../../../../@ccmc/services/selected-field.service';
import { FieldEditedService } from '../../../../@ccmc/services/field-edited.service';
import { SpinnerService } from '../../../../@ccmc/services/spinner.service';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { GeneralLedgerAccountingService } from 'src/@ccmc/services/general-ledger-accounting.service';
import { DocumentConnectorService } from 'src/@ccmc/services/doc-connector.service';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { V } from '@angular/cdk/keycodes';

/**
 * CCMC Configurations Component
 * This component lets the user edit the configurations object and the JSON as a whole.
 * @export
 * @class CCMCConfigurationsComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-configurations',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.scss']
})
export class ConfigurationsComponent implements OnInit, OnDestroy {
  private documentSub: Subscription;
  private fieldEditedSub: Subscription;
  private spinnerSub: Subscription;
  core: any;
  configurations: any;
  localConfigurations: any;
  documentCore: any;
  tempDocumentCore: any;
  configKeys: any;
  edited = false;
  saveConfigFlag: any;
  configTypes: any;
  configJSON: any;
  objKeys: any;
  jsonObject: any;
  unsubscribe: Subject<any> = new Subject();
  showSpinner: any;
  showHideFields: any;
  hideFields = true;
  globalMetas: any;
  localGlobalMetas: any;
  newConfigKeys1: any;
  newConfigKeys2: any;
  finalConfigKeys: any;
  /**
   * Creates an instance of CCMCConfigurationsComponent.
   * @param {CcmcApiService} ccmcApiService
   * @param {CCMCSelectedFieldService} selectedFieldService
   * @param {FieldEditedService} fieldEditedService
   * @param {SpinnerService} spinnerService
   * @param {MatDialog} dialog
   * @memberof CCMCConfigurationsComponent
   */
  constructor(
    private ccmcApiService: CcmcApiService,
    private selectedFieldService: CCMCSelectedFieldService,
    private fieldEditedService: FieldEditedService,
    private spinnerService: SpinnerService,
    private dialog: MatDialog,
    private adminApiService: AdminApiService,
    private glaService: GeneralLedgerAccountingService,
    private dcService: DocumentConnectorService,
    private assetService: AssetService
  ) {}

  /**
   * On Init
   * @description Init the configurations component
   * @memberof CCMCConfigurationsComponent
   */
  ngOnInit() {
    this.core = localStorage.getItem('selectedCore');
    this.getData();
  }

  /**
   * On Destroy
   * @description Unsubscribes from all current observables
   * @memberof CCMCConfigurationsComponent
   */
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  /**
   * Get Data
   * @description Gets data when initialized
   * @memberof CCMCConfigurationsComponent
   */
  getData() {
    const product = this.assetService.getSelectedProduct();
    console.log(product);
    if (product.includes('doc_connector')) {
      this.getDocConnectorDocument();
    } else if (product.includes('general_ledger_accounting')) {
      this.getGeneralLedgerAccountingDocument();
    } else {
      this.getStandardDocument();
    }

    // Subscribe to the field edited subject
    this.fieldEditedSub = this.fieldEditedService.onConfigurationsFieldEdited
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(configEdited => {
        this.saveConfigFlag = configEdited;
      });
    // Subscribe to the spinner sub
    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        // console.log(spinner);
        if (this.dialog.openDialogs.length === 0) {
          this.showSpinner = spinner;
        }
      });
  }

  getStandardDocument() {
    this.documentSub = this.adminApiService.documentCore
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(documentCore => {
        if (documentCore) {
          this.documentCore = documentCore;
          // Parse instance of documentCore
          this.objKeys = Object.keys(this.documentCore);
          this.jsonObject = JSON.parse(JSON.stringify(this.documentCore));
          delete this.jsonObject['mapping'];
          delete this.jsonObject['conditions'];
          delete this.jsonObject['supportValues'];
          delete this.jsonObject['transactions'];
          delete this.jsonObject['_id'];
          delete this.jsonObject['_version'];
          // initialize edited to false
          this.edited = false;
          //update the assetID to the correct name
          this.jsonObject['_id'] = this.assetService.getSelectedAssetId();
          // Assigns configurations
          if (documentCore.globalMetas) {
            this.globalMetas = documentCore.globalMetas;
          } else {
            this.configurations = documentCore.configurations;
          }
          localStorage.setItem(
            'configurationOption',
            JSON.stringify(this.configurations)
          );
          localStorage.setItem(
            'globalMetasOption',
            JSON.stringify(this.globalMetas)
          );
          console.log('target configurations',JSON.parse(JSON.stringify(this.configurations)));
          console.log(this.globalMetas);
          // Initalize json
          this.configJSON = JSON.stringify(this.jsonObject, null, 2);
          localStorage.setItem(
            'jsonConfiguration',
            JSON.stringify(this.jsonObject, null, 2)
          );
          // console.log(this.configJSON);
          if (this.globalMetas) {
            this.configKeys = Object.keys(this.globalMetas);
            this.configTypes = { ...this.configKeys };
            for (let i = 0; i < this.configKeys.length; i++) {
              this.configTypes[i] = typeof this.globalMetas[this.configKeys[i]];
            }
            this.localGlobalMetas = { ...this.globalMetas };
            console.log(this.localGlobalMetas);
          }
          if (this.configurations) {
            // Assigns config keys
            this.configKeys = Object.keys(this.configurations);
            this.configTypes = { ...this.configKeys };
            this.showHideFields = [];
            if (this.configKeys.indexOf('maskLoggingTags') > 0) {
              this.configKeys.splice(
                this.configKeys.indexOf('maskLoggingTags'),
                1
              );
              this.configKeys.unshift('maskLoggingTags');
            }
            if (this.configKeys.indexOf('maskLoggingMappingTags') > 0) {
              this.configKeys.splice(
                this.configKeys.indexOf('maskLoggingMappingTags'),
                1
              );
              this.configKeys.unshift('maskLoggingMappingTags');
            }
            if (this.configKeys.indexOf('logLevel') > 0) {
              this.configKeys.splice(this.configKeys.indexOf('logLevel'), 1);
              this.configKeys.unshift('logLevel');
            }
            if (this.configKeys.indexOf('implementationMode') > 0) {
              this.configKeys.splice(
                this.configKeys.indexOf('implementationMode'),
                1
              );
              this.configKeys.unshift('implementationMode');
            }
            if (this.configKeys.indexOf('demoMode') > 0) {
              this.configKeys.splice(this.configKeys.indexOf('demoMode'), 1);
              this.configKeys.unshift('demoMode');
            }

            for (let i = 0; i < this.configKeys.length; i++) {
              this.configTypes[i] =
                typeof this.configurations[this.configKeys[i]];
                this.showHideFields.push(true);
            }

            if (
              this.configKeys.includes('maskLoggingTags') ||
              this.configKeys.includes('maskLoggingMappingTags')
            ) {
              this.newConfigKeys1 = this.configKeys.slice(0, 4);
              console.log(this.newConfigKeys1);
              this.newConfigKeys2 = this.configKeys.slice(4);
              this.newConfigKeys2.sort(function (a: any, b: any) {
                return a.toLowerCase().localeCompare(b.toLowerCase());
              });
              this.finalConfigKeys = this.newConfigKeys1.concat(
                this.newConfigKeys2
              );
              this.configTypes = { ...this.finalConfigKeys };
              for (let i = 0; i < this.finalConfigKeys.length; i++) {
                const index = this.finalConfigKeys.indexOf(
                  'productInquirySettings'
                );
                if (index > -1) {
                  this.finalConfigKeys.splice(index, 1);
                }
                this.configTypes[i] =
                  typeof this.configurations[this.finalConfigKeys[i]];
                console.log(this.configTypes[i]);
              }
            } else {
              this.newConfigKeys1 = this.configKeys.slice(0, 3);
              console.log(this.newConfigKeys1);
              this.newConfigKeys2 = this.configKeys.slice(3);
              this.newConfigKeys2.sort(function (a: any, b: any) {
                return a.toLowerCase().localeCompare(b.toLowerCase());
              });
              this.finalConfigKeys = this.newConfigKeys1.concat(
                this.newConfigKeys2
              );
              for (let i = 0; i < this.finalConfigKeys.length; i++) {
                const index = this.finalConfigKeys.indexOf(
                  'productInquirySettings'
                );
                if (index > -1) {
                  this.finalConfigKeys.splice(index, 1);
                }
                this.configTypes[i] =
                  typeof this.configurations[this.finalConfigKeys[i]];
              }
            }
            this.localConfigurations = { ...this.configurations };
          }
        }
      });
  }

  getDocConnectorDocument() {
    // Subscribe to the document core
    this.documentSub = this.dcService.dcDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(documentCore => {
        console.log(documentCore);
        if (documentCore) {
          this.documentCore = documentCore;
          this.jsonObject = JSON.parse(JSON.stringify(this.documentCore));
          delete this.jsonObject['destinations'];
          delete this.jsonObject['globalMetas'];
          delete this.jsonObject['_id'];
          delete this.jsonObject['_version'];

          //update the assetID to the correct name
          this.jsonObject['_id'] = this.assetService.getSelectedAssetId();
          this.configJSON = JSON.stringify(this.jsonObject, null, 2);

          // Parse instance of documentCore
          this.objKeys = Object.keys(this.documentCore);
          // initialize edited to false
          this.edited = false;
          // Assigns configurations
          this.configurations = documentCore.configurations;
          localStorage.setItem(
            'configurationOption',
            JSON.stringify(this.configurations)
          );
          // Assigns config keys
          if (this.configurations) {
            this.configKeys = Object.keys(this.configurations);
            console.log('config keys', this.configKeys);
            this.configTypes = { ...this.configKeys };
            this.showHideFields = [];
            this.finalConfigKeys = JSON.parse(JSON.stringify(this.configKeys));
            // Show Fields array used to hide and show passwords
            for (let i = 0; i < this.configKeys.length; i++) {
              this.configTypes[i] =
                typeof this.configurations[this.configKeys[i]];
              // Hide all passwords by default
              this.showHideFields.push(true);
            }
            this.localConfigurations = { ...this.configurations };
            console.log('local config', this.localConfigurations);
            console.log('local config types', this.configTypes);
            console.log('final config keys', this.finalConfigKeys);
          }
        }
      });
  }

  getGeneralLedgerAccountingDocument() {
    // Subscribe to the document core
    this.documentSub = this.glaService.glaDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(documentCore => {
        if (documentCore) {
          console.log(documentCore);
          this.documentCore = documentCore;
          // Parse instance of documentCore
          this.objKeys = Object.keys(this.documentCore);
          this.jsonObject = JSON.parse(JSON.stringify(this.documentCore));
          delete this.jsonObject['mapping'];
          delete this.jsonObject['conditions'];
          delete this.jsonObject['supportValues'];
          delete this.jsonObject['transactions'];
          delete this.jsonObject['_id'];
          delete this.jsonObject['_version'];
          // initialize edited to false
          this.edited = false;
          //update the assetID to the correct name
          this.jsonObject['_id'] = this.assetService.getSelectedAssetId();
          // Assigns configurations
          this.configurations = documentCore.configurations;
          // Assigns config keys
          if (this.configurations) {
            this.configKeys = Object.keys(this.configurations);
            this.finalConfigKeys = JSON.parse(JSON.stringify(this.configKeys));
            this.configTypes = { ...this.configKeys };
            // Show Fields array used to hide and show passwords
            this.showHideFields = [];
            for (let i = 0; i < this.configKeys.length; i++) {
              this.configTypes[i] =
                typeof this.configurations[this.configKeys[i]];
              // Hide all passwords by default
              this.showHideFields.push(true);
            }
            this.localConfigurations = { ...this.configurations };
          }
        }
      });
  }

  /**
   * Field Edited
   * @description Checks whether the field has been edited
   * @memberof ConfigurationsComponent
   */
  fieldEdited() {
    this.edited = false;
    for (let i = 0; i < this.configKeys.length; i++) {
      if (
        this.localConfigurations[this.configKeys[i]] !==
        this.configurations[this.configKeys[i]]
      ) {
        this.edited = true;
      }
    }
  }
  /**
   * Save
   * @description Saves the configurations object
   * @memberof ConfigurationsComponent
   */
  save() {
    const product = this.assetService.getSelectedProduct();
    if (product.includes('doc_connector')) {
      this.fieldEditedService.losEdited.next(true);
    } else if (product.includes('general_ledger_accounting')) {
      // Set core edited to true
      this.fieldEditedService.onGLADocumentEdited.next(true);
    } else {
      this.fieldEditedService.coreEdited.next(true);
    }
    // Set configuration edited to true, this flags the edited star to appear
    this.fieldEditedService.onConfigurationsFieldEdited.next(true);
    //
    for (let i = 0; i < this.finalConfigKeys.length; i++) {
      this.configurations[this.finalConfigKeys[i]] =
        this.localConfigurations[this.finalConfigKeys[i]];
      // Convert value to boolean if applicable
      if (
        this.configurations[this.finalConfigKeys[i]] === 'true' ||
        this.configurations[this.finalConfigKeys[i]] === 'false'
      ) {
        this.configurations[this.finalConfigKeys[i]] =
          this.localConfigurations[this.finalConfigKeys[i]] === 'true';
      }
      // Convert to a number if applicable
      if (this.configTypes[i] === 'number') {
        this.configurations[this.finalConfigKeys[i]] =
          +this.configurations[this.finalConfigKeys[i]];
      }
    }
    // Set edited flag for button to false
    this.edited = false;
    if (this.localConfigurations) {
      const oldObject = JSON.parse(
        localStorage.getItem('configurationOption')!
      );
      const newObject = this.localConfigurations;
      const editedObject = { oldObject, newObject };
      const msg = {
        type: 'Configurations Options',
        editedObject
      };
      this.ccmcApiService.appendLogChanges(msg, 'Target');
    }
  }
}
