import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, Subscription } from 'rxjs';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
import { CCMCConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';

@Component({
  selector: 'app-keystone-account-search-dialog',
  templateUrl: './keystone-account-search-dialog.component.html',
  styleUrls: ['./keystone-account-search-dialog.component.scss']
})
export class KeystoneAccountSearchDialogComponent implements OnInit {
  showSpinner: boolean;
  private spinnerSub: Subscription;
  accountSearchDataSource: any;
  diplayedAccountColumns = [
    'accountNumber',
    'serial'
  ];
  selectedRow: any;
  unsubscribe: Subject<any> = new Subject();
  constructor(
    @Inject(MAT_DIALOG_DATA) public accountSearchResults: any,
    private dialogRef: MatDialogRef<KeystoneAccountSearchDialogComponent>,
    private spinnerService: SpinnerService,
    private ccmcApiService: CcmcApiService,
    private cd: ChangeDetectorRef,
    private snackbarService: SnackbarService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    console.log('Account Search Results', this.accountSearchResults);
    this.accountSearchDataSource = new MatTableDataSource(
      this.accountSearchResults
    );
  }

  onSelectAccount(account: any) {
    this.selectedRow = account;
  }

  continue() {
    const confirmDialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
      data: `Are you sure you want to assign account number: ${this.selectedRow.accountNumber}?`
    });
    confirmDialogRef.afterClosed().subscribe(data => {
      if (data) {
        if (
          this.updateAccountIDField(
            this.selectedRow.accountNumber,
            this.selectedRow.serial
          )
        ) {
          this.dialogRef.close(true);
        }
      }
    });
  }

  updateAccountIDField(accountID: any, serialID: any) {
    const keystoneAccountFieldID = 'Acct_1002';
    const keystoneSeriealFieldID = 'Acct_9999';
    console.log('Mapping', this.ccmcApiService.mapping);
    let accountIDFieldIndex = this.ccmcApiService.mapping.findIndex(
      (mapping: any) => mapping.fieldID === keystoneAccountFieldID
    );
    let serialIDFieldIndex = this.ccmcApiService.mapping.findIndex(
      (mapping: any) => mapping.fieldID === keystoneSeriealFieldID
    );
    // console.log('AccountID Field Index', accountIDFieldIndex);
    // console.log('SerialID Field Index', serialIDFieldIndex);
    if (accountIDFieldIndex > -1 && serialIDFieldIndex > -1) {
      this.ccmcApiService.mapping[accountIDFieldIndex].fieldValue = accountID;
      this.ccmcApiService.mapping[serialIDFieldIndex].fieldValue = serialID;
      return true;
    } else {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          title: 'Mapping Error',
          message: 'Error retrieving mapping field Acct_1002'
        }
      });
      return false;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
