<div style="display: grid; grid-template-rows: 1fr 1.5fr; width: 100%">
  <div class="background-container">
    <div class="content-container">
      <div class="title-icon-container">
        <img
          src="assets/images/logos/GC-icon.png"
          style="z-index: 10000; width: 50px; height: 50px; margin-right: 15px"
        />
        <h1 style="font-size: 20pt; position: relative; top: 5px">
          Encompass Loan Search
        </h1>
        <div style="padding-left: 100px">
          <mat-radio-group
            style="display: flex; flex-direction: row"
            [(ngModel)]="selectedEnvironment"
          >
            <mat-radio-button
              style="margin: 5px"
              *ngFor="let environment of environments"
              [value]="environment"
            >
              {{ environment }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div
        style="
          display: grid;
          grid-template-rows: 1fr 1fr 1fr;
          position: relative;
          top: 30px;
        "
      >
        <div
          style="
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 80px;
          "
        >
          <mat-form-field class="form-field-one">
            <input
              style="background-color: #f1f1f1"
              matInput
              placeholder="Loan Number"
              [formControl]="loanNumberFormControl"
              [errorStateMatcher]="matcher"
              (change)="
                loanLookupParams.loanNumber = loanLookupParams.loanNumber.trim()
              "
              [(ngModel)]="loanLookupParams.loanNumber"
              (ngModelChange)="checkForm()"
              (keydown.enter)="loanLookUp()"              
            />
            <mat-error *ngIf="loanNumberFormControl.hasError('minlength')">
              Length of 3 is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="form-field-three">
            <input
              style="background-color: #f1f1f1"
              matInput
              placeholder="TaxID"
              [formControl]="taxIDFormControl"
              [errorStateMatcher]="matcher"
              [(ngModel)]="loanLookupParams.taxID"
              (ngModelChange)="checkForm()"
              (keydown.enter)="loanLookUp()"
            />
            <mat-error *ngIf="taxIDFormControl.hasError('minlength')">
              Length of 3 is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div
          style="
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 80px;
            padding-top: 10px;
          "
        >
          <mat-form-field class="form-field-two">
            <input
              style="background-color: #f1f1f1"
              matInput
              placeholder="First Name"
              [formControl]="firstNameFormControl"
              [errorStateMatcher]="matcher"
              [(ngModel)]="loanLookupParams.firstName"
              (ngModelChange)="checkForm()"
              (keydown.enter)="loanLookUp()"
            />
            <mat-error *ngIf="firstNameFormControl.hasError('minlength')">
              Length of 2 is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="form-field-three">
            <input
              style="background-color: #f1f1f1"
              matInput
              placeholder="Last Name"
              [formControl]="lastNameFormControl"
              [errorStateMatcher]="matcher"
              [(ngModel)]="loanLookupParams.lastName"
              (ngModelChange)="checkForm()"
              (keydown.enter)="loanLookUp()"
            />
            <mat-error *ngIf="lastNameFormControl.hasError('minlength')">
              Length of 2 is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="button-container">
          <button class="clear-button" mat-raised-button (click)="clear()">
            Clear
          </button>
          <button
            class="search-button"
            mat-raised-button
            (click)="loanLookUp()"
            [disabled]="isSearchDisabled"
          >
            Search
          </button>
        </div>
      </div>
    </div>
  </div>

  <div style="background-color: white">
    <!--Core Table-->
    <div class="loan-table">
      <table mat-table #table [dataSource]="dataSource" matSort>
        <!-- Name Column -->
        <ng-container matColumnDef="borrowerName">
          <th
            class="header-text"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            Name
          </th>
          <td mat-cell *matCellDef="let element">{{ element.borrowerName }}</td>
        </ng-container>

        <!-- Loan # Column -->
        <ng-container matColumnDef="loanNumber">
          <th
            class="header-text"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            Loan Number
          </th>
          <td mat-cell *matCellDef="let element">{{ element.loanNumber }}</td>
        </ng-container>

        <!-- Loan # Column -->
        <ng-container matColumnDef="taxID">
          <th
            class="header-text"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            Tax ID
          </th>
          <td mat-cell *matCellDef="let element">{{ element.taxID }}</td>
        </ng-container>

        <!-- Last Modified Column -->
        <ng-container matColumnDef="lastModified">
          <th
            class="header-text"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            Last Modified
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.lastModified | date: 'MM/dd/yyyy hh:mm a' }}
          </td>
        </ng-container>

        <!-- Loan Guid Column -->
        <ng-container matColumnDef="loanGuid">
          <th
            class="header-text"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            Loan Guid
          </th>
          <td mat-cell *matCellDef="let element">{{ element.loanGuid }}</td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (dblclick)="selectLoan(row)"
          [ngClass]="{ 'selected-row': selectedLoan === row ? true : false }"
        ></tr>
      </table>
    </div>
  </div>

  <!--Spinner-->
  <div class="spinner-container" *ngIf="showSpinner">
    <div class="new-spinner">
      <div class="outer-ring"></div>
      <div class="middle-ring"></div>
      <div class="inner-ring"></div>
    </div>
  </div>
</div>
