import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  passwordMatchValidator,
  ConfirmValidParentMatcher,
  errorMessages
} from '../../../app/main/toolbar/change-password/validate-passwords.validators';

@Component({
  selector: 'app-force-change-password',
  templateUrl: './force-change-password.component.html',
  styleUrls: ['./force-change-password.component.scss']
})
export class CCMCForceChangePasswordComponent implements OnInit {
  forceChangePasswordForm: FormGroup;
  newPasswordType: any = 'password';
  confNewPasswordType: any = 'password';
  newPasswordVisibility: any = 'visibility';
  confNewPasswordVisibility: any = 'visibility';
  confirmValidParentMatcher = new ConfirmValidParentMatcher();
  errors = errorMessages;

  /**
   * Creates an instance of CCMCForceChangePasswordComponent.
   * @param {FormBuilder} fb
   * @param {MatDialogRef<CCMCForceChangePasswordComponent>} dialogRef
   * @memberof CCMCForceChangePasswordComponent
   */
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CCMCForceChangePasswordComponent>
  ) {}

  /**
   * On Init
   * @memberof CCMCForceChangePasswordComponent
   */
  ngOnInit() {
    // init fb group for newPassword and confirmPasswrod fields
    this.forceChangePasswordForm = this.fb.group(
      {
        newPassword: ['', Validators.required],
        confNewPassword: ['', Validators.required]
      },
      { validators: passwordMatchValidator }
    );
  }

  /**
   * Password Visibility
   * @description handles visibility for the password and confirm password fields
   * @param {HTMLInputElement} input
   * @memberof CCMCForceChangePasswordComponent
   */
  passwordVisibility(input: HTMLInputElement) {
    switch (input.name) {
      case 'newPassword':
        this.newPasswordVisibility =
          this.newPasswordVisibility === 'visibility'
            ? 'visibility_off'
            : 'visibility';
        this.newPasswordType =
          this.newPasswordType === 'text' ? 'password' : 'text';
        break;
      case 'confNewPassword':
        this.confNewPasswordVisibility =
          this.confNewPasswordVisibility === 'visibility'
            ? 'visibility_off'
            : 'visibility';
        this.confNewPasswordType =
          this.confNewPasswordType === 'text' ? 'password' : 'text';
        break;
    }
  }

  /**
   * Change Password
   * @description if form is valid returns new password to the cognito service
   * @memberof CCMCForceChangePasswordComponent
   */
  changePassword() {
    if (this.forceChangePasswordForm.status === 'VALID') {
      this.dialogRef.close({
        newPass: this.forceChangePasswordForm.controls['newPassword'].value
      });
    }
  }
}
