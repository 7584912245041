import { Injectable, NgZone } from '@angular/core';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CcmcApiService } from './ccmc-api.service';
import { CCMCCollateralSearchDialogComponent } from '../components/collateral-search-dialog/collateral-search-dialog.component';
import { Router } from '@angular/router';
import { navigation } from '../../app/navigation/navigation';
import { SpinnerService } from './spinner.service';
import { ActiveLosService } from './active-los.service';
import { SuccessDialogComponent } from '../components/success-dialog/success-dialog.component';
import { multicast, catchError, filter } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { AssetService } from './asset.service';
import { environment } from 'src/environments/environment';
import { CaptureDataService } from './capture-data.service';
import { AmplifyService } from './amplify.service';
import { GlobalSearchService } from './global-search.service';
import { TargetResponseDialogComponent } from '../components/target-response-dialog/target-response-dialog.component';
const { Buffer } = require('buffer');

@Injectable({
  providedIn: 'root'
})
export class MortgageServicerService {
  dynamicNav: any = navigation;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(
    private dialog: MatDialog,
    private ccmcApiService: CcmcApiService,
    public router: Router,
    private spinnerService: SpinnerService,
    private activeLosService: ActiveLosService,
    private zone: NgZone,
    private http: HttpClient,
    private customErrorHandlerService: CustomErrorHandlerService,
    private assetService: AssetService,
    private amplifyService: AmplifyService,
    private captureDataService: CaptureDataService,
    private globalSearchService: GlobalSearchService
  ) {
    this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, PUT'
    );
    this.httpOptions.headers.append('Accept', 'application/json');
  }

  async send() {
    this.spinnerService.setShowSpinner(true);
    let userName = await this.amplifyService.getUserName();
    for (let mapping of this.ccmcApiService.mapping) {
      mapping.fieldValue = mapping.fieldValue.replaceAll("&QUOT;", '"');
    }
    const body = {
      assetID: this.assetService.getSelectedAssetId(),
      content: {
        mapping: this.ccmcApiService.mapping,
        configurations: this.ccmcApiService.configurations,
        transactions: this.ccmcApiService.transactions
      },
      user: userName
    };
    (await this.export(body)).subscribe(async (res: any) => {
      this.spinnerService.setShowSpinner(false);
      console.log(res);
      const exportMessagesResponse = res;
      if (exportMessagesResponse.error) {
        const errorMessage = {
          statusMessage: "Ground Control failed to receive a response back from the communicator",
          statusFlag: false
        };
        const dialogRef2 = this.zone.run(() => {
          this.dialog.open(TargetResponseDialogComponent, {
            data: errorMessage
          });
        });
        await this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: this.getLoanNumber(),
          statusMessage: exportMessagesResponse.error,
          statusFlag: false
        });
      }
      if (exportMessagesResponse.statusFlag === true) {
        let loanNumber;
        if (exportMessagesResponse.loanNumber) {
          loanNumber = exportMessagesResponse.loanNumber;
        } else {
          loanNumber = this.getLoanNumber();
        }
        const resultMessage = {
          statusMessage: exportMessagesResponse.statusMessage,
          loanNumber: loanNumber,
          statusFlag: true
        };
        // DOWNLOAD FILE HER
        // If SendFile is true we dont download the file
        if (!this.ccmcApiService.configurations.sendFile) {
          const exportString = exportMessagesResponse.content.message;
          const blob = new Blob([exportString as BlobPart], {
            type: 'txt'
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = loanNumber;
          a.click();
        }
        await this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: loanNumber,
          statusMessage: exportMessagesResponse.statusMessage,
          statusFlag: exportMessagesResponse.statusFlag
        });
        this.captureDataService.uploadS3File(this.ccmcApiService.mapping);
        this.activeLosService.activeLosService.loanBooked(
          exportMessagesResponse,
          JSON.parse(JSON.stringify(this.ccmcApiService.configurations))
        );
        this.ccmcApiService.mapping = undefined;
        this.ccmcApiService.coreSettings = undefined;
        this.ccmcApiService.conditions = undefined;
        this.ccmcApiService.mapping = undefined;
        this.ccmcApiService.supportValues = undefined;
        this.ccmcApiService.search = undefined;
        this.ccmcApiService.transactions = undefined;
        this.ccmcApiService.configurations = undefined;
        if (this.dynamicNav[1].children[1]) {
          this.dynamicNav[1].children[1].children = [];
        }
        this.dynamicNav[1].children[0].badge.title = undefined;
        const dialogRef = this.dialog.open(TargetResponseDialogComponent, {
          data: resultMessage
        });
        dialogRef.afterClosed().subscribe(result => {
          if (this.activeLosService.activeLos === 'encompass' || this.activeLosService.activeLos === 'bytepro') {
            this.router.navigate(['loan-lookup']);
          } else {
            this.router.navigate(['file-selector']);
          }
        });
      }
      if (exportMessagesResponse.statusFlag === false) {
        if (exportMessagesResponse.content && exportMessagesResponse.content.mapping) {
          // Retrieve Validation From existing mapping object
          let existingMappingCopy = JSON.parse(
            JSON.stringify(this.ccmcApiService.mapping)
          );
          for (let mappingItem of exportMessagesResponse.content.mapping) {
            let existingMappingIndex = existingMappingCopy.findIndex(
              (obj: any) => obj.fieldID === mappingItem.fieldID
            );
            if (
              existingMappingIndex > -1 &&
              existingMappingCopy[existingMappingIndex].validation
            ) {
              mappingItem.validation =
                existingMappingCopy[existingMappingIndex].validation;
            }
          }
          this.ccmcApiService.mapping =exportMessagesResponse.content.mapping;
        }
        if (exportMessagesResponse.content && exportMessagesResponse.content.transactions) {
          this.ccmcApiService.transactions = exportMessagesResponse.content.transactions;
        }
        await this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: this.getLoanNumber(),
          statusMessage: exportMessagesResponse.statusMessage,
          statusFlag: exportMessagesResponse.statusFlag
        });
        this.globalSearchService.initData();
        // this.ccmcApiService.mapping = parsedResult.content.mapping;
        const errorMessage = {
          statusMessage: exportMessagesResponse.statusMessage,
          statusFlag: false
        };
        const dialogRef = this.dialog.open(TargetResponseDialogComponent, {
          data: errorMessage
        });
      }
    });
  }

  export(params: any) {
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/fics/mortgageservicer/export-messages`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  getLoanNumber() {
    console.log('get Loan number');
    let loanFieldIndex = this.ccmcApiService.mapping.findIndex(
      (obj: any) => obj.fieldID === 'LS_027'
    );
    if (loanFieldIndex > -1) {
      // Return loan number
      console.log(
        'loan number',
        this.ccmcApiService.mapping[loanFieldIndex].fieldValue
      );
      return this.ccmcApiService.mapping[loanFieldIndex].fieldValue;
    }
  }
}
