<div class="logs-page">
  <div class="filepath-container">
    <p class="headerText">Logs</p>
  </div>
  <div class="filter-Logs">
    <mat-form-field class="filter-FormField">
      <input matInput placeholder="Filter Search" #filterSearch />
    </mat-form-field>
    <mat-form-field style="margin-left: -10%">
      <input
        matInput
        [matDatepicker]="startDatePicker"
        placeholder="Start Date"
        [formControl]="startDate"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="startDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        [matDatepicker]="endDatePicker"
        placeholder="End Date"
        [formControl]="endDate"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="endDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>
    <div>
      <button
        mat-raised-button
        matTooltip="Export current doc connector logs into a csv"
        matTooltipClass="toolTipStyle"
        class="export-button"
        [disabled]="currentLogsLoaded === false"
        (click)="exportToCSV()"
      >
        Export
      </button>
      <button
        mat-raised-button
        matTooltip="Retrieve and displays logs"
        matTooltipClass="toolTipStyle"
        color="accent"
        (click)="getLogs()"
        class="fetch-button"
      >
        Fetch Logs
      </button>
    </div>
  </div>
  <div class="table-container">
    <table
      mat-table
      class="table-TableContainer"
      #table
      [dataSource]="dataSource"
      CCMCPerfectScrollbar
      matSort
    >
      <!-- Level -->
      <ng-container matColumnDef="loanNumber">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          style="min-width: 150px; max-width: 150px"
        >
          Loan Number
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          style="min-width: 150px; max-width: 150px"
        >
          {{ element.loanNumber }}
        </td>
      </ng-container>
      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          style="min-width: 150px; max-width: 150px"
        >
          Date
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          style="min-width: 150px; max-width: 150px"
        >
          {{ element.date | date: 'medium' }}
        </td>
      </ng-container>

      <!-- Service -->
      <ng-container matColumnDef="service">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Service</th>
        <td mat-cell *matCellDef="let element">
          <div class="truncate">
            {{ element.service }}
          </div>
        </td>
      </ng-container>

      <!-- fileType -->
      <ng-container matColumnDef="fileType">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          style="min-width: 175px; max-width: 175px"
        >
          File Type
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          style="min-width: 175px; max-width: 175px"
        >
          {{ element.fileType }}
        </td>
      </ng-container>

      <!-- Message -->
      <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Message</th>
        <td mat-cell *matCellDef="let element">
          <div class="truncate">
            {{ element.message }}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="onSelect(row)"
        [ngClass]="{ 'accent-50-bg': row == selected }"
      ></tr>
    </table>
  </div>
  <div class="paginatorContainer">
    <mat-paginator
      [pageSizeOptions]="[100]"
      showFirstLastButtons
      class="paginator-conditions"
    >
    </mat-paginator>
  </div>
  <div class="tabBar">
    <mat-tab-group>
      <mat-tab label="Logs">
        <!--Messages-->
        <div class="message-container">
          <h3 class="message-title">Message</h3>
          <mat-form-field id="currentMessage" style="width: 100%; height: 66%">
            <textarea
              matInput
              [(ngModel)]="currentMessage"
              readonly
              cdkTextareaAutosize
              class="message-textarea"
            ></textarea>
          </mat-form-field>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="spinner-container" *ngIf="showSpinner">
    <div class="new-spinner">
      <div class="outer-ring"></div>
      <div class="middle-ring"></div>
      <div class="inner-ring"></div>
    </div>
  </div>
</div>
