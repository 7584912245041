import {
  Component,
  NgZone,
  OnInit
} from '@angular/core';
import {
  MatDialog
} from '@angular/material/dialog';
import {
  Router
} from '@angular/router';
import {
  Subject,
  Subscription
} from 'rxjs';
import {
  takeUntil
} from 'rxjs/operators';
import {
  AdminApiService
} from 'src/@ccmc/services/admin-api.service';
import {
  AmplifyService
} from 'src/@ccmc/services/amplify.service';
import {
  AssetService
} from 'src/@ccmc/services/asset.service';
import {
  CcmcApiService
} from 'src/@ccmc/services/ccmc-api.service';
import {
  SnackbarService
} from 'src/@ccmc/services/snackbar.service';
import {
  SpinnerService
} from 'src/@ccmc/services/spinner.service';
import {
  WriteLoggingService
} from 'src/@ccmc/services/write-logging.service';
import {
  environment
} from 'src/environments/environment';

@Component({
  selector: 'app-source-configurations',
  templateUrl: './source-configurations.component.html',
  styleUrls: ['./source-configurations.component.scss']
})
export class SourceConfigurationsComponent implements OnInit {
  variables: any;
  oldVariables: any;
  variableKeys: any;
  unsubscribe: Subject < any > = new Subject();
  showSpinner: any;
  editedFlag = false;
  environmentType = 'test';
  private spinnerSub: Subscription;
  assetID: any;
  isNXTsoft = false;
  isNXTsoftDev = false;
  isNXTsoftOperations = false;
  isNXTsoftSales = false;
  localSelected = {
    username: '',
    password: '',
    path: 'C:\\'
  };
  losCheck: string;
  hideFields = true;
  folderPath: string;
  subPath: string;
  localVariables: any;
  constructor(
    private adminApiService: AdminApiService,
    private ccmcApiService: CcmcApiService,
    private spinnerService: SpinnerService,
    private dialog: MatDialog,
    private zone: NgZone,
    private snackBarService: SnackbarService,
    private router: Router,
    private amplifyService: AmplifyService,
    private assetService: AssetService,
    private writeLoggingService: WriteLoggingService
  ) {}
  ngOnInit() {
    this.losCheck = this.assetService.getSelectedLos();
    this.getAssetID();
    this.newPullEnvironmentVariables();
    console.log(this.assetID);
    const product = this.assetService.getSelectedProduct();
    if (product.includes('test')) {
      this.environmentType = 'test';
    } else {
      this.environmentType = 'prod';
    }
    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        // console.log(spinner);
        if (this.dialog.openDialogs.length === 0) {
          this.showSpinner = spinner;
        }
      });
    this.isNXTsoft = this.amplifyService.isNXTsoft;
    this.isNXTsoftDev = this.amplifyService.isNXTsoftDev;
    this.isNXTsoftOperations = this.amplifyService.isNXTsoftOperations;
    this.isNXTsoftSales = this.amplifyService.isNXTsoftSales;
  }

  async newPullEnvironmentVariables() {
    let environmentVariablesResponse: any;
    switch (this.losCheck) {
      case 'encompass':
        environmentVariablesResponse = await this.pullEncompassEnvironmentVariables(this.assetID);
        if (!environmentVariablesResponse['statusFlag']) {
          environmentVariablesResponse = await this.pullEncompassEnvironmentVariables('generic');
          environmentVariablesResponse['content'].assetID = this.assetID;
          if (!environmentVariablesResponse['statusFlag']) {
            return;
          }
        }
        this.variables = environmentVariablesResponse['content'];
        this.localVariables = JSON.parse(JSON.stringify(this.variables));
        this.variableKeys = Object.keys(this.localVariables);
        this.oldVariables = {
          ...this.variables
        };
        break;
      case 'mortgagebot':
        environmentVariablesResponse = await this.pullMortgageBotEnvironmentVariables(this.assetID);
        if (!environmentVariablesResponse['statusFlag']) {
          environmentVariablesResponse = await this.pullMortgageBotEnvironmentVariables('generic');
          environmentVariablesResponse['content'].assetID = this.assetID;
          if (!environmentVariablesResponse['statusFlag']) {
            return;
          }
        }
        this.variableKeys = ['assetID', 'guid', 'mortgageBotUrl'];
        let tempVariable: any = {};
        this.variableKeys.forEach((element: any) => {
          tempVariable[element] = '';
        });
        tempVariable['assetID'] = this.assetID;
        this.variables = tempVariable;
        this.variables['guid'] = environmentVariablesResponse['content'].loanExtractInfo[0].guid;
        this.variables['mortgageBotUrl'] =
          environmentVariablesResponse['content'].loanExtractInfo[0].mortgageBotUrl;
        this.oldVariables = {
            ...this.variables
          };
        break;
        case 'ncino':
        environmentVariablesResponse = await this.pullNcinoEnvironmentVariables(this.assetID);
        console.log(environmentVariablesResponse);
        if (!environmentVariablesResponse['statusFlag']) {
          // do variables stuff her    
          environmentVariablesResponse['content'].assetID = this.assetID;
          environmentVariablesResponse = await this.pullNcinoEnvironmentVariables('generic');
          if (!environmentVariablesResponse['statusFlag']) {
            return;
          }
        }
        this.variables = environmentVariablesResponse['content'];
        this.localVariables = JSON.parse(JSON.stringify(this.variables));
        this.variableKeys = Object.keys(this.localVariables);
        console.log(this.variableKeys);
        this.oldVariables = {
          ...this.variables
        };
        break;
      case 'mcp':
        environmentVariablesResponse = await this.pullMCPEnvironmentVariables(this.assetID);
        if (!environmentVariablesResponse['statusFlag']) {
          // do variables stuff her    
          environmentVariablesResponse['content'].assetID = this.assetID;
          environmentVariablesResponse = await this.pullMCPEnvironmentVariables('generic');
          if (!environmentVariablesResponse['statusFlag']) {
            return;
          }
        }
        this.variables = environmentVariablesResponse['content'];
        this.localVariables = JSON.parse(JSON.stringify(this.variables));
        this.variableKeys = Object.keys(this.localVariables);
        this.oldVariables = {
          ...this.variables
        };
        break;
      case 'bytepro':
        environmentVariablesResponse = await this.pullByteProEnvironmentVariables(this.assetID);
        if (!environmentVariablesResponse['statusFlag']) {
          // do variables stuff her    
          environmentVariablesResponse['content'].assetID = this.assetID;
          environmentVariablesResponse = await this.pullByteProEnvironmentVariables('generic');
          if (!environmentVariablesResponse['statusFlag']) {
            return;
          }
        }
        this.variables = environmentVariablesResponse['content'];
        this.localVariables = JSON.parse(JSON.stringify(this.variables));
        this.variableKeys = Object.keys(this.localVariables);
        this.oldVariables = {
          ...this.variables
        };
        break;
      default:
        break;
    }
  }

  getAssetID() {
    this.assetID = this.assetService.getSelectedAssetId();
  }

  // getEnvironmentalVariables() {
  //   console.log(this.losCheck);
  //   if (this.losCheck === 'encompass') {
  //     this.pullEnvironmentVariables('encompass_settings', 'assetID');
  //   } else if (this.losCheck === 'ncino') {
  //     this.pullEnvironmentVariables('nCino', 'assetId');
  //   } else if (this.losCheck === 'mortgagebot') {
  //     //this.pullingMortgageBotEnvironmentVariables();
  //     this.pullEnvironmentVariables('mbelos_extract', 'assetID');
  //   } else if (this.losCheck === 'bytepro') {
  //     this.pullEnvironmentVariables(
  //       'byte_software_bytepro_settings',
  //       'assetID'
  //     );
  //   }
  // }

  fieldEdited() {
    this.editedFlag = true;

    if (this.isEquivalent(this.variables, this.oldVariables)) {
      console.log('is equiv');
      this.editedFlag = false;
    }
  }

  isEquivalent(a: any, b: any) {
    console.log(a);
    console.log(b);
    // Create arrays of property names
    try {
      const aProps = Object.getOwnPropertyNames(a);
      const bProps = Object.getOwnPropertyNames(b);
      // If number of properties is different,
      // objects are not equivalent
      if (aProps.length !== bProps.length) {
        console.log();
        return false;
      }

      for (let i = 0; i < aProps.length; i++) {
        const propName = aProps[i];
        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
          return false;
        }
      }
      return true;
    } catch (e) {
      console.log(e);
    }
  }

  updateVariables() {

    switch (this.losCheck) {
      case 'encompass':
        this.updateEnvironmentVariables('encompass_settings');
        break;
      case 'ncino':
        this.updateEnvironmentVariables('nCino');
        break;
      case 'mortgagebot' :
        this.variables = {
          assetID: this.variables.assetID,
          loanExtractInfo: [
            {
              guid: this.variables.guid,
              mortgageBotUrl: this.variables.mortgageBotUrl
            }
          ]
        };
        this.updateEnvironmentVariables('mbelos_extract');
        break;
      case 'bytepro' :
        this.updateEnvironmentVariables('byte_software_bytepro_settings');
        break;
      case 'mcp' :
        this.updateEnvironmentVariables('mortgage_cadence_mcp_settings');
        break;
      default:
        break;
    }
    const assetID = this.assetService.getSelectedAssetId();
    const stream = assetID;
    let editedObject = {
      editedObject: {
        oldObject: this.oldVariables,
        newObject: this.variables
      }
    };
    let logMsg = [editedObject];
    console.log(editedObject);
    console.log(logMsg);
    this.writeLoggingService.setStream(stream);
    this.writeLoggingService
      .writeLog({
        username: this.amplifyService.username,
        action: 'Submit Source Updates',
        time: new Date().toISOString(),
        log: logMsg
      })
      .subscribe(result => {
        // logging result
        console.log(result);
      });
  }

  updateEnvironmentVariables(los: any) {
    this.ccmcApiService
      .createDynamoDB(this.environmentType, los, this.variables)
      .subscribe(result => {
        if (result) {
          this.snackBarService.openSnackBar(
            'Updated environmental variables',
            'Okay'
          );
          this.router
            .navigateByUrl('/RefrshComponent', {
              skipLocationChange: true
            })
            .then(() => this.router.navigate(['/source-configurations']));
        }
      });
  }
 
  // Able to delete after review
  async pullMortgageBotEnvironmentVariables(key: any) {
    return new Promise((resolve) => {
      this.adminApiService
        .getDynamoDBItem('mbelos_extract', key, 'assetID')
        .subscribe(result => {
          if (result) {
            const parsedResult = JSON.parse(JSON.stringify(result))
            const pullMortgagebotResponse = {
              statusFlag: true,
              statusMessage: "Successfully retrieved Mortgagebot Settings",
              content: parsedResult
            }
            return resolve(pullMortgagebotResponse);
        
          } else {
            const pullMortgagebotResponse = {
              statusFlag: false,
              statusMessage: "Failed to retrieve MCP Settings"
            }
            return resolve(pullMortgagebotResponse);
          }
        });
    })
  }

  async pullMCPEnvironmentVariables(key: any) {
    return new Promise((resolve) => {
      this.adminApiService
        .getDynamoDBItem('mortgage_cadence_mcp_settings', key, 'assetID')
        .subscribe(result => {
          if (result) {
            const parsedResult = JSON.parse(JSON.stringify(result));
            
            const pullMCPResponse = {
              statusFlag: true,
              statusMessage: "Successfully retrieved MCP Settings",
              content: parsedResult
            }
            return resolve(pullMCPResponse);
            console.log('wow');
          } else {
            const pullMCPResponse = {
              statusFlag: false,
              statusMessage: "Failed to retrieve MCP Settings"
            }
            return resolve(pullMCPResponse);
          }
        });
    })

  }

  async pullEncompassEnvironmentVariables(key: any) {
    return new Promise((resolve) => {
      this.adminApiService
        .getDynamoDBItem('encompass_settings', key, 'assetID')
        .subscribe(result => {
          if (result) {
            const parsedResult = JSON.parse(JSON.stringify(result))
            const pullEncompassResponse = {
              statusFlag: true,
              statusMessage: "Successfully retrieved Encompass Settings",
              content: parsedResult
            }
            return resolve(pullEncompassResponse);
          } else {
            const pullEncompassResponse = {
              statusFlag: false,
              statusMessage: "Failed to retrieve Encompass Settings"
            }
            return resolve(pullEncompassResponse);
          }
        });
    });
  }

  async pullNcinoEnvironmentVariables(key: any) {
    return new Promise((resolve) => {
      this.adminApiService
        .getDynamoDBItem('nCino', key, 'assetId')
        .subscribe(result => {
          if (result) {
            console.log(result);
            const parsedResult = JSON.parse(JSON.stringify(result))
            const pullNcinoResponse = {
              statusFlag: true,
              statusMessage: "Successfully retrieved Ncino Settings",
              content: parsedResult
            }
            return resolve(pullNcinoResponse);
          } else {
            const pullNcinoResponse = {
              statusFlag: false,
              statusMessage: "Failed to retrieve Ncino Settings"
            }
            return resolve(pullNcinoResponse);
          }
        });
    });
  }

  async pullByteProEnvironmentVariables(key: any) {
    return new Promise((resolve) => {
      this.adminApiService
        .getDynamoDBItem('byte_software_bytepro_settings', key, 'assetID')
        .subscribe(result => {
          if (result) {
            const parsedResult = JSON.parse(JSON.stringify(result))
            const pullByteproResponse = {
              statusFlag: true,
              statusMessage: "Successfully retrieved Bytepro Settings",
              content: parsedResult
            }
            return resolve(pullByteproResponse);
          } else {
            const pullByteproResponse = {
              statusFlag: false,
              statusMessage: "Failed to retrieve Bytepro Settings"
            }
            return resolve(pullByteproResponse);
          }
        });
    });
  }

  generateXML() {
    this.assetID = localStorage.getItem('selectedAsset');

    if (this.localSelected.path.charAt(this.localSelected.path.length - 1) === '\\' && this.localSelected.path.length === 3) {
      this.folderPath = this.localSelected.path;
      this.subPath = this.localSelected.path.slice(0, -1);
    } else if (this.localSelected.path.charAt(this.localSelected.path.length - 1) === '\\') {
      this.folderPath = this.localSelected.path;
      this.subPath = this.localSelected.path.slice(0, -1);
    } else {
      this.folderPath = this.localSelected.path + '\\';
      this.subPath = this.localSelected.path
    }

    this.ccmcApiService
      .encryptMessage(this.localSelected.username)
      .subscribe((encryptMessageResponse: any) => {
        console.log(encryptMessageResponse);
        if (encryptMessageResponse['successFlag']) {
          this.ccmcApiService
            .encryptMessage(this.localSelected.password)
            .subscribe((encryptMessageResponse2: any) => {
              console.log(encryptMessageResponse2);
              if (encryptMessageResponse2['successFlag']) {
                console.log(this.assetID);
                this.ccmcApiService
                  .encryptMessage(this.assetID)
                  .subscribe((encryptMessageResponse3: any) => {
                    console.log(encryptMessageResponse3);
                    if (encryptMessageResponse3['successFlag']) {
                      let xml = `<?xml version="1.0" encoding="utf-8" ?>
<Config>
  <Username>${encryptMessageResponse['content'].encryptedValue}</Username>
  <Password>${encryptMessageResponse2['content'].encryptedValue}</Password>
  <FileWatcherCollection>  
    <FileWatcher LogName="Directory Watcher 1">
      <FolderPath>${this.folderPath}</FolderPath>
      <SuccessFolderPath>${this.subPath}\\Success</SuccessFolderPath>
      <FailedFolderPath>${this.subPath}\\Failed</FailedFolderPath>
      <PostUrl>los-watcher-file-upload-prod</PostUrl>
      <FilePatternFilter>*.xml</FilePatternFilter>
      <AssetId>${encryptMessageResponse3['content'].encryptedValue}</AssetId>
      <LogLevel>INFO</LogLevel>
    </FileWatcher>
  </FileWatcherCollection>
</Config>`;
                      const blob = new Blob([xml as BlobPart], {
                        type: 'application/xml'
                      });
                      const url = window.URL.createObjectURL(blob);
                      const a = document.createElement('a');
                      a.href = url;
                      a.download = 'LosDirectoryWatcherServiceConfig.xml';
                      a.click();
                    }
                  });
              }
            });
        }
      });
  }
}
