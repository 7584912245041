import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ChangeDetectorRef,
  NgZone,
  Inject
} from '@angular/core';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, Subscription } from 'rxjs';
import { Collateral } from '../../models/collateral.model';
import { CollateralSearchParty } from '../../models/collateral-search-party.model';
import { CcmcApiService } from '../../services/ccmc-api.service';
import { PrecisionService } from '../../services/precision.service';
import { SpinnerService } from '../../services/spinner.service';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { AssetService } from 'src/@ccmc/services/asset.service';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDragHandle
} from '@angular/cdk/drag-drop';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatTable } from '@angular/material/table';
import { CCMCCollateralSearchDialogComponent } from '../collateral-search-dialog/collateral-search-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';
import { RequestChangeService } from 'src/@ccmc/services/request-change.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';

@Component({
  selector: 'app-new-status-dialog',
  templateUrl: './new-status-dialog.component.html',
  styleUrls: ['./new-status-dialog.component.scss']
})
export class NewStatusDialogComponent implements OnInit {
  currentTable: any;
  constructor(
    private requestChangeService: RequestChangeService,
    private snackBarService: SnackbarService,
    private dialogRef: MatDialogRef<CCMCCollateralSearchDialogComponent>,
    private assetService: AssetService
  ) {}
  newStatus: any = '';
  docFields: any;
  unsubscribe: Subject<any> = new Subject();
  docStatuses: any[] = [];
  changeRequestFieldsSub: Subscription;
  transationSelectedSub: Subscription;
  rowsSub: Subscription;
  statuses: any;

  ngOnInit(): void {
    this.changeRequestFieldsSub =
      this.requestChangeService.changeRequestDoc.subscribe(doc => {
        this.docStatuses = doc.statuses;
        this.currentTable = doc;
      });
    // console.log(this.docStatuses);
    // console.log(this.currentTable);
  }
  closeBox() {
    this.dialogRef.close();
  }
  async continue() {
    let index = 0;
    this.requestChangeService.changeRequestDocStatuses
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(result => {
      // console.log(check);
      console.log(result);
      this.statuses = result.statuses;
      if (index < 1) {
        if (this.newStatus !== '' && this.newStatus !== undefined) {
          this.statuses.push(this.newStatus);
          this.requestChangeService.postDynamoChangeRequestStatuses(result);
          // this.requestChangeService.changeRequestDoc.next(remoteChangeReqCopy);
          this.closeBox();
        } else {
          this.snackBarService.openSnackBar('Status can not be blank', 'Okay');
        }
      } 
    });
    
  }
}
