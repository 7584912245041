<ng-container
  *ngIf="item.visible === true || ((ccmcApiService.mapping !== undefined &&
  ccmcApiService.transactions[item.transactionIndex] &&
  ccmcApiService.transactions[item.transactionIndex].display === true) && item.eye === true)"
>
  <!-- normal collapse -->
  <a
    class="nav-link"
    *ngIf="!item.url && !item.function && item.title !== 'Transactions'"
    style="height: 30px"
    (click)="toggleOpen($event)"
    matRipple
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- Transaction collapse -->
  <a
    class="nav-link"
    *ngIf="!item.url && !item.function && item.title === 'Transactions'"
    style="display: table-row; height: 5em"
    (click)="toggleOpen($event)"
    matRipple
  >
    <div style="
    background-color: #252525; 
    display: grid;
    width: 180%;
    transform: translate(0px, -10px);"
    [ngStyle]="{'padding': isNXTsoftDev || isNXTsoftOperations ? '1em 0em .5em 2em' : '0em 0em .5em 1em' }"
    >
      <!-- Display Settings Slider -->
      <div 
      [ngStyle]="{'display': isNXTsoftDev || isNXTsoftOperations ? '' : 'none' }"
      (click)="$event.stopPropagation();$event.preventDefault()"
      >
        <mat-icon style="float: left; font-size: 22px;">visibility</mat-icon>
        <mat-slide-toggle labelPosition='before' (click)="displaySettings()" [checked]="setVisibility" style="font-size: 14px; float:left; margin-left: 1em;">
          Display Settings
        </mat-slide-toggle>
      </div>

      <!-- Transaction Sort Icon Buttons -->
      <div (click)="$event.stopPropagation();$event.preventDefault()" style="margin-left: 2em; margin-top: 1em;">
        <!-- Eye -->
        <button
        class="tooltip"
        (click)="fullEyeSort()"
        [ngStyle]="{
          'color': currentSort.button === 'eye' ? '#3fb2c6' : '#e6e6e6', 
          'display': setVisibility === false ? 'none' : ''
        }"
        >
          <mat-icon>visibility_off</mat-icon>
          <span class="tooltiptext">Sort Transactions by visibility</span>
        </button>
        <!-- Alpha -->
        <button
        class="tooltip"
        (click)="fullAlphaSort()"
        style="margin-left: 1em;"
        [ngStyle]="{'color': currentSort.button === 'alpha' ? '#3fb2c6' : '#e6e6e6' }"
        >
          <mat-icon>sort_by_alpha</mat-icon>
          <span class="tooltiptext">Sort Transactions by alphabetical order</span>
        </button>
        <!-- Numeric -->
        <button
        class="tooltip"
        (click)="fullNumericSort()"
        style="margin-left: 1em;"
        [ngStyle]="{'color': currentSort.button === 'numeric' ? '#3fb2c6' : '#e6e6e6' }"
        >
          <mat-icon>numbers</mat-icon>
          <span class="tooltiptext">Sort Transactions by default index order</span>
        </button>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item.url -->
  <a
    class="nav-link"
    *ngIf="item.url && !item.function"
    (click)="toggleOpen($event)"
    [routerLink]="[item.url]"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
    matRipple
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item.function -->
  <span
    class="nav-link"
    *ngIf="!item.url && item.function"
    (click)="toggleOpen($event); item.function()"
    matRipple
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </span>

  <!-- item.url && item.function -->
  <a
    class="nav-link"
    *ngIf="item.url && item.function"
    (click)="toggleOpen($event); item.function()"
    [routerLink]="[item.url]"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
    matRipple
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <ng-template #itemContent>
    <!-- Open Eye Icon -->
    <mat-icon 
    class="nav-link-icon" 
    *ngIf="item.eye === true" 
    [ngStyle]="{'display': setVisibility === false ? 'none' : ''}"
    (click)="blinkOfAnEye(item);$event.stopPropagation();$event.preventDefault()"
    >
      visibility
    </mat-icon>
    <!-- Slashed Eye Icon -->
    <mat-icon 
    class="nav-link-icon" 
    *ngIf="item.eye === false" 
    [ngStyle]="{'display': setVisibility === false ? 'none' : ''}"
    (click)="blinkOfAnEye(item);$event.stopPropagation();$event.preventDefault()" 
    style="color: red;"
    >
      visibility_off
    </mat-icon>
    <mat-icon class="nav-link-icon" *ngIf="item.icon" [ngStyle]="{'margin-left': item.title === 'Transactions' ? '1.75em' : '0em' }">{{ item.icon }}</mat-icon>
    <span
      class="nav-link-title"
      [translate]="item.translate"
      title="{{ item.title }}"
      style="width: 125px; font-size: small;"
      >{{ croppedTitle }}</span
    >
    <span
      class="nav-link-badge"
      *ngIf="item.badge"
      [translate]="item.badge.translate"
      [ngStyle]="{ 'background-color': item.badge.bg, color: item.badge.fg }"
    >
      {{ item.badge.title }}
    </span>
    <!-- Circle X Icon -->
    <i 
      class="fa-solid fa-circle-xmark nav-link-icon"
      style="color: #acacac; margin-left: 3.5em;"
      *ngIf="
        sentFlag === false && 
        item.title !== 'Transactions' && 
        !(receiptList === null || receiptList === undefined) && 
        transactionHistoryToggle === true" 
    >
    </i>
    <!-- Circle Check Icon -->
    <i 
      class="fa-solid fa-circle-check nav-link-icon"
      style="color: #00fe93; margin-left: 3.5em;"
      *ngIf="
        sentFlag === true && 
        item.title !== 'Transactions' && 
        !(receiptList === null || receiptList === undefined) &&
        transactionHistoryToggle === true" 
    >
    </i>
    <mat-icon
      class="collapse-arrow"
      style="float: right;"
      [ngStyle]="{'margin-left': item.title === 'Transactions' ? '2em' : transactionHistoryToggle === true ? '-3.5em' : '1.7em' }"
      *ngIf="item.children && item.children.length > 0"
      >keyboard_arrow_right</mat-icon
    >
  </ng-template>

  <div class="children" [@slideInOut]="isOpen">
    <div *ngIf="!showSpinner">
      <ng-container *ngFor="let item of item.children">
        <ccmc-nav-vertical-item
          *ngIf="item.type == 'item'"
          [item]="item"
        ></ccmc-nav-vertical-item>
        <ccmc-nav-vertical-collapse
          *ngIf="item.type == 'collapse'"
          [item]="item"
        ></ccmc-nav-vertical-collapse>
        <ccmc-nav-vertical-group
          *ngIf="item.type == 'group'"
          [item]="item"
        ></ccmc-nav-vertical-group>
      </ng-container>
    </div>
    <mat-spinner
      [style.display]="!showSpinner ? 'none' : 'block'"
      [color]="'accent'"
    >
    </mat-spinner>
  </div>
</ng-container>
