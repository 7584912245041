<div style="width: 100%">
  <!--Header-->
  <div class="header mat-accent-bg p-16 p-sm-24" id="header-container">
    <div class="hero-text">
      <!-- Header Image -->
      <img 
        src="assets/images/logos/GC-rocket-omni1.png"
        style="z-index: 10000"/>
    </div>
    
    <!--Search Bar-->
    <div class="search mat-white-bg mat-elevation-z7" id="search-bar-container">
      <form class="search-form" style="width: 100%; height: 29px">
        <button
          class="local-search-button"
          role="img"
          aria-hidden="true"
          style="border-radius: 4px">
        <mat-icon
          class="mat-icon material-icons"
          style="position: relative; top: 2px; left: 1px">search
        </mat-icon>
      </button>
        <input
          class="search-bar ng-untouched ng-pristine ng-valid"
          matInput
          #searchbar
          (keyup)="applyFilter($any($event.target).value)"
          type="search"
          autocomplete="off"
          placeholder="Filter by loan.."
          style="color: black; flex-grow: 2; height: 29px"/>
      </form>
    </div>
  </div>

  <div class="loan-files" style="width: 100%; margin-bottom: 15px">
    <table mat-table [dataSource]="dataSource" matSort>
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th
          class="header-text"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Name
        </th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <!-- Loan # Column -->
      <ng-container matColumnDef="loanNumber">
        <div *ngIf="isOrigenate == 'origenate'; else elseBlock">
          <th
            class="header-text"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            Application Number
          </th>
        </div>
        <ng-template #elseBlock>
          <th
            class="header-text"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            Loan Number
          </th></ng-template
        >
        <td mat-cell *matCellDef="let element">{{ element.loanNumber }}</td>
      </ng-container>

      <!-- Loan # Column -->
      <ng-container matColumnDef="taxId">
        <th
          class="header-text"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Tax ID
        </th>
        <td mat-cell *matCellDef="let element">{{ element.taxId }}</td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th
          class="header-text"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Last Modified
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.date | date: 'MM/dd/yyyy hh:mm a' }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="selectLoan(row)"
        [ngClass]="{ 'selected-row': selectedLoan === row ? true : false }"
      ></tr>
    </table>
  </div>
  <!--Tab Menu-->
  <mat-tab-group
    style="
      background-color: #dfdfdf;
      border-top: 1px solid #adaaaa;
      height: 400px;
      position: relative;
      bottom: 15px;
    "
  >
    <mat-tab label="Loan Options">
      <div style="padding: 5px">
        <button
          mat-raised-button
          matTooltip="Download the selected loan."
          matTooltipClass="toolTipStyle"
          color="primary"
          (click)="downloadFile()"
          style="margin-right: 10px"
        >
          Download
        </button>

        <button
          mat-flat-button
          color="accent"
          type="button"
          aria-label="Upload"
          class="upload-button"
          (click)="file.click()"
        >
          Upload
        </button>

        <button
          mat-outline-button
          matTooltip="Delete the selected loan."
          matTooltipClass="toolTipStyle"
          (click)="deleteFile(); file.value = ''"
          class="delete-button"
        >
          Delete
        </button>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div class="spinner-container" *ngIf="showSpinner">
    <div class="new-spinner">
      <div class="outer-ring"></div>
      <div class="middle-ring"></div>
      <div class="inner-ring"></div>
    </div>
  </div>
</div>

<input
  type="file"
  multiple="multiple"
  #file
  (change)="uploadFile($event)"
  hidden
  multiple
/>
