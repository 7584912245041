import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { fromEvent, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil
} from 'rxjs/operators';
import { AddGlobalMetaDialogComponent } from 'src/@ccmc/components/add-global-meta-dialog/add-global-meta-dialog.component';
import { CCMCConfirmDialogComponent } from 'src/@ccmc/components/confirm-dialog/confirm-dialog.component';
import { GetPathDialogComponent } from 'src/@ccmc/components/get-path-dialog/get-path-dialog.component';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { DocumentConnectorService } from 'src/@ccmc/services/doc-connector.service';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';

@Component({
  selector: 'app-doc-connector-global-metas',
  templateUrl: './doc-connector-global-metas.component.html',
  styleUrls: ['./doc-connector-global-metas.component.scss']
})
export class DocConnectorGlobalMetasComponent implements OnInit {
  shortcuts: ShortcutInput[] = [];
  private documentLosSub: Subscription;
  private selectedSub: Subscription;
  showSpinner: boolean;
  private spinnerSub: Subscription;
  private fieldEditedSub: Subscription;
  fieldEditedFlag = false;
  globalMetas: any;
  displayedColumns = ['name', 'index', 'value', 'path', 'edited', 'remove'];
  dataSource: any;
  tempDocument: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filterSearch', { static: true }) filterSearchEl: ElementRef;
  public selectedGlobalMeta: any;
  public selectedFilter: any;
  filterSearch: string;
  dialogRef: any;
  unsubscribe: Subject<any> = new Subject();
  filterSearchText: any;
  constructor(
    private ccmcApiService: CcmcApiService,
    private selectedFieldService: CCMCSelectedFieldService,
    private spinnerService: SpinnerService,
    private dialog: MatDialog,
    private fieldEditedService: FieldEditedService,
    private zone: NgZone,
    private snackbarService: SnackbarService,
    private dcService: DocumentConnectorService
  ) {}

  ngOnInit() {
    this.fieldEditedService.fieldEdited.next(false);
    this.selectedFilter = 'all';
    this.getData();
    this.filterSearchEl.nativeElement.focus();
    fromEvent(this.filterSearchEl.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // Time in milliseconds between key events
        debounceTime(1000),
        // If previous query is diffent from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((text: string) => {
        this.applyFilter(text);
      });
  }

  // ngAfterViewInit() {
  //   // this.shortcuts.push({
  //   //   key: ['cmd + h'],
  //   //   label: 'Find and Replace',
  //   //   description: 'Find and replace',
  //   //   command: e => {
  //   //     this.findAndReplace();
  //   //   },
  //   //   preventDefault: true
  //   // });
  // }

  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  getData() {
    this.selectedSub = this.selectedFieldService.onGlobalMetasFieldSelected
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(selected => {
        console.log(selected);
        this.selectedGlobalMeta = selected;
      });
    this.documentLosSub = this.dcService.dcDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dcDocument => {
        if (dcDocument) {
          console.log('Doc Connector Document', dcDocument);
          this.tempDocument = dcDocument;
          if (dcDocument.globalMetas) {
            this.globalMetas = dcDocument.globalMetas;
            if (this.globalMetas.length === 0) {
              return;
            }
            this.dataSource = new MatTableDataSource(this.globalMetas);
            // initializes pagination
            this.dataSource.paginator = this.paginator;
            // initializes sort
            this.dataSource.sort = this.sort;
            // initializes first index as selected
            if (
              this.selectedGlobalMeta === null ||
              this.selectedGlobalMeta === undefined
            ) {
              this.onSelectGlobalMeta(dcDocument.globalMetas[0]);
            }
          } else {
            this.globalMetas = undefined;
          }
        }
      });
    this.fieldEditedSub = this.fieldEditedService.fieldEdited
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(edited => {
        this.fieldEditedFlag = edited;
      });
  }

  applyFilter(filterValue: string) {
    // console.log(filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData[0]) {
      this.selectedFieldService.onSupportValueFieldSelected.next(
        this.dataSource.filteredData[0]
      );
    }
    this.filterSearch = filterValue;
  }

  onSelectGlobalMeta(selected: any) {
    this.selectedGlobalMeta = selected;
    this.selectedFieldService.onGlobalMetasFieldSelected.next(selected);
  }

  addGlobalMeta(event: any) {
    event.stopPropagation();
    const addGlobalMetaDialogRef = this.dialog.open(
      AddGlobalMetaDialogComponent,
      {}
    );
    addGlobalMetaDialogRef.afterClosed().subscribe(() => {
      this.selectedFieldService.onGlobalMetasFieldSelected.next(
        this.selectedGlobalMeta
      );
      if (this.filterSearch) {
        if (this.filterSearch.length > 0) {
          this.applyFilter(this.filterSearch);
        }
      }
    });
  }

  removeGlobalMeta(event: any, globalMeta: any) {
    event.stopPropagation();
    console.log('remove global meta');
    const confirmDialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
      data: 'Are you sure you want to remove this global meta?'
    });
    confirmDialogRef.afterClosed().subscribe(data => {
      if (data) {
        console.log('remove', globalMeta);
        this.fieldEditedService.losEdited.next(true);
        for (let i = 0; i < this.globalMetas.length; i++) {
          if (
            this.globalMetas[i].name === globalMeta.name &&
            this.globalMetas[i].index === globalMeta.index
          ) {
            this.globalMetas.splice(i, 1);
          }
        }
        this.tempDocument.globalMetas = this.globalMetas;
        this.dcService.setDcDocumentSimple(this.tempDocument);
        if (this.filterSearch && this.filterSearch.length > 0) {
          this.applyFilter(this.filterSearch);
        }
      }
    });
  }

  canDeactivate() {
    if (this.fieldEditedFlag) {
      this.snackbarService.openSnackBar('Please resolve changes', 'Okay');
    }
    return !this.fieldEditedFlag;
  }
  
  getEncompassPath() {
    console.log('open path dialog');
    const pathDialogRef = this.dialog.open(GetPathDialogComponent);
  }
}
