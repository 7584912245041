import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subject, Subscription, fromEvent } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl } from '@angular/forms';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from 'src/@ccmc/components/error-dialog/error-dialog.component';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { MatTableDataSource } from '@angular/material/table';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  takeUntil
} from 'rxjs/operators';
import { ReadLoggingService } from '../logging/read-logging.service';
import { CCMCConfirmDialogComponent } from 'src/@ccmc/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-ebm-logging',
  templateUrl: './ebm-logging.component.html',
  styleUrls: ['./ebm-logging.component.scss']
})
export class EbmLoggingComponent implements OnInit {
  unsubscribe: Subject<any> = new Subject();
  logs: any;
  private spinnerSub: Subscription;
  currentLog: any;
  currentLoanNumber: any;
  currentBorrower: any;
  currentMessage: any;
  currentException: any;
  displayedColumns = [
    'dateTime',
    'assetID',
    'loanNumber',
    'borrower',
    'message'
  ];
  dataSource: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('filterSearch', { static: true }) filterSearchEl: ElementRef;
  filterSearch: string;
  showSpinner: boolean;
  maxDate = new Date();
  endDate = new FormControl(new Date());
  startDate = new FormControl(
    new Date(this.endDate.value.getTime() - 24 * 60 * 60 * 1000)
  );
  loggingEnvironment: any;

  /**
   * Creates an instance of EbmLoggingComponent.
   * @param {SpinnerService} spinnerService
   * @param {AdminApiService} adminApiService
   * @param {AssetService} assetService
   * @param {Router} router
   * @param {MatDialog} matDialog
   * @memberof EbmLoggingComponent
   */
  constructor(
    private spinnerService: SpinnerService,
    private adminApiService: AdminApiService,
    private assetService: AssetService,
    private router: Router,
    private matDialog: MatDialog,
    private readLoggingService: ReadLoggingService,
    private ccmcApiService: CcmcApiService
  ) {}

  /**
   * On Init
   *
   * @memberof EbmLoggingComponent
   */
  ngOnInit() {
    this.spinnerService.setShowSpinner(true);
    // Subscribe to the spinner
    const product = this.assetService.getSelectedProduct();
    if (product.includes('prod')) {
      this.loggingEnvironment = 'prod';
    } else {
      this.loggingEnvironment = 'test';
    }
    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        this.showSpinner = spinner;
      });
    if (this.assetService.getHasEBM()) {
      // Init filter search
      this.initFilterSearch();
      this.getLogs();
    } else {
      const errorMessage = {
        message: 'EBM not available on this asset',
        title: 'Error'
      };
      // Display error to screen
      const dialogRef = this.matDialog.open(ErrorDialogComponent, {
        data: errorMessage
      });
      dialogRef.afterClosed().subscribe(result => {
        this.router.navigate(['field-options']);
      });
    }
  }

  /**
   * Destroy
   * @description unsubscribes from observables
   * @memberof EbmLoggingComponent
   */
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }
  /**
   * Get Logs
   * @description retrieves the logs
   * @memberof EbmLoggingComponent
   */
  getLogs() {
    this.spinnerService.setShowSpinner(true);
    // Set lower and upper bounds for times
    const finalStartDate = this.startDate.value.setHours(0, 0, 0, 0);
    const finalEndDate = this.endDate.value.setHours(23, 59, 59, 999);
    const loggingParams = {
      startDate: new Date(finalStartDate).getTime(),
      endDate: new Date(finalEndDate).getTime()
    };
    console.log(finalStartDate);
    console.log(finalEndDate);
    this.adminApiService.readEBMLogs(loggingParams).subscribe(result => {
      console.log(result);
      this.spinnerService.setShowSpinner(false);
      const parsedResult = JSON.parse(JSON.stringify(result));
      if (parsedResult.statusFlag) {
        console.log(parsedResult.statusMessage);
        if (parsedResult.content.logs.length === 0) {
          const errorMessage = {
            message: 'No Logs found.',
            title: 'Logging'
          };
          this.matDialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
        } else {
          // Set logs
          this.logs = parsedResult.content.logs;
          this.readLoggingService.ebmExportLogs.next(this.logs);
          console.log(this.logs);
          // Set table data
          this.dataSource = new MatTableDataSource(this.logs);
          // initializes pagination
          this.dataSource.paginator = this.paginator;
          // initializes sort
          this.dataSource.sort = this.sort;
          // Select Initial Log
          this.onSelect(this.dataSource.data[0]);
        }
      }
    });
  }

  /**
   * Apply Filter
   *
   * @param {string} filterValue
   * @memberof EbmLoggingComponent
   */
  applyFilter(filterValue: string) {
    // Trim the data
    this.dataSource.filter = filterValue.trim().toLowerCase();
    // If there is data
    if (this.dataSource.filteredData[0]) {
      // Select the first instance
      this.onSelect(this.dataSource.filteredData[0]);
    }
    // Set filter search
    this.filterSearch = filterValue;
  }

  /**
   * On Select
   *
   * @param {*} selected
   * @memberof EbmLoggingComponent
   */
  onSelect(selected: any) {
    // Set current log to selected
    this.currentLog = selected;
    // Clean XML
    this.currentLoanNumber = selected.loanNumber;
    this.currentBorrower = selected.borrower;
    this.currentMessage = selected.message;
  }

  /**
   * Filter Search
   *
   * @memberof EbmLoggingComponent
   */
  initFilterSearch() {
    // Auto Focus filter search item
    this.filterSearchEl.nativeElement.focus();
    fromEvent(this.filterSearchEl.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // Time in milliseconds between key events
        debounceTime(1000),
        // If previous query is diffent from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((text: string) => {
        this.applyFilter(text);
      });
  }
}
