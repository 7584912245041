import {
  Component,
  OnInit,
  NgZone,
  ElementRef,
  ViewChild
} from '@angular/core';
import { Subscription, Subject, Observable } from 'rxjs';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
import { takeUntil, startWith, map } from 'rxjs/operators';
import { ENTER, COMMA, SPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { WriteLoggingService } from 'src/@ccmc/services/write-logging.service';

@Component({
  selector: 'app-edit-user-assets',
  templateUrl: './edit-user-assets.component.html',
  styleUrls: ['./edit-user-assets.component.scss']
})
export class EditUserAssetsComponent implements OnInit {
  private selectedUserSub: Subscription;
  public selectedUser: any;
  adminAssets: any;
  assetsSplit: any;
  unsubscribe: Subject<any> = new Subject();
  assetsArray: any;
  initialAssetsArray: any;
  selectable = true;
  removable = true;
  addOnBlur = true;
  isNXTsoft = false;
  isNXTsoftDev = false;
  isNXTsoftOperations = false;
  isNXTsoftSales = false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  assetsCtrl = new FormControl();
  filteredAssets: Observable<any[]>;
  @ViewChild('assetsInput') assetsInput: ElementRef;

  /**
   * Creates an instance of EditUserAssetsComponent.
   * @param {CCMCSelectedFieldService} selectedFieldService
   * @param {SpinnerService} spinnerService
   * @param {AssetService} assetService
   * @param {AdminApiService} adminApiService
   * @param {Router} router
   * @param {SnackbarService} snackBarService
   * @memberof EditUserAssetsComponent
   */
  constructor(
    private selectedFieldService: CCMCSelectedFieldService,
    private spinnerService: SpinnerService,
    private assetService: AssetService,
    private adminApiService: AdminApiService,
    private router: Router,
    private snackBarService: SnackbarService,
    private amplifyService: AmplifyService,
    private writeLoggingService: WriteLoggingService
  ) {
    this.filteredAssets = this.assetsCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) =>
        fruit ? this.filter(fruit) : this.adminAssets.slice()
      )
    );
  }

  /**
   * On Init
   *
   * @memberof EditUserAssetsComponent
   */
  ngOnInit() {
    this.isNXTsoft = this.amplifyService.isNXTsoft;
    this.isNXTsoftDev = this.amplifyService.isNXTsoftDev;
    this.isNXTsoftOperations = this.amplifyService.isNXTsoftOperations;
    this.isNXTsoftSales = this.amplifyService.isNXTsoftSales;
    this.getData();
  }

  /**
   * On Destroy
   *
   * @memberof EditUserAssetsComponent
   */
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
    this.selectedUser = undefined;
  }

  /**
   * Get Data
   * @description subscribes to data needed in this component
   * @memberof EditUserAssetsComponent
   */
  getData() {
    // Get selected user
    this.selectedUserSub = this.selectedFieldService.onUserFieldSelected
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(selected => {
        if (selected && selected.username) {
          this.assetsArray = [];
          console.log(selected);
          this.selectedUser = selected;
          // Build selected user's assets array
          this.selectedUser.assets.split(', ').forEach((splitId: any) => {
            return this.assetsArray.push(splitId);
          });
          this.initialAssetsArray = JSON.parse(JSON.stringify(this.assetsArray));
          // Get admins asset array
          this.adminAssets = this.assetService.getAssetIds();
        }
      });
  }

  /**
   * Filter
   * @description filter the admin users assets
   * @param {string} asset
   * @returns
   * @memberof EditUserAssetsComponent
   */
  filter(asset: string) {
    return this.adminAssets.filter(
      (asset: any) => asset.toLowerCase().indexOf(asset.toLowerCase()) === 0
    );
  }

  /**
   * Add
   * @description Add Chip to chip list, checks if asset is not already in the list
   * @param {MatChipInputEvent} event
   * @memberof EditUserAssetsComponent
   */
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our asset
    if ((value || '').trim()) {
      if (this.assetsArray.includes(value.trim())) {
        console.log('already inside array');
        this.snackBarService.openSnackBar(
          'User already has that asset',
          'Okay'
        );
      } else if (
        this.isNXTsoft ||
        this.isNXTsoftDev ||
        this.isNXTsoftOperations ||
        this.isNXTsoftSales
      ) {
        this.assetsArray.push(value.trim());
      } else if (!this.adminAssets.includes(value.trim())) {
        this.snackBarService.openSnackBar(
          'You are not authorized to add an asset you do not have',
          'Okay'
        );
      } else {
        this.assetsArray.push(value.trim());
      }
    }
    input.value = '';
  }

  /**
   * Remove
   * @description Removes asset from asset list
   * @param {*} asset
   * @memberof EditUserAssetsComponent
   */
  remove(asset: any): void {
    const index = this.assetsArray.indexOf(asset);
    
    if (index >= 0) {
      this.assetsArray.splice(index, 1);
    }
  }

  /**
   * Selected Asset Option
   *
   * @param {MatAutocompleteSelectedEvent} event
   * @memberof EditUserAssetsComponent
   */
  selectedAssetOption(event: MatAutocompleteSelectedEvent): void {
    // Add our asset
    if ((event.option.viewValue || '').trim()) {
      if (this.assetsArray.includes(event.option.viewValue.trim())) {
        this.snackBarService.openSnackBar(
          'User already has that asset',
          'Okay'
        );
      } else if (
        this.isNXTsoft ||
        this.isNXTsoftDev ||
        this.isNXTsoftOperations ||
        this.isNXTsoftSales
      ) {
        this.assetsArray.push(event.option.viewValue);
        this.assetsCtrl.setValue(null);
      } else if (!this.adminAssets.includes(event.option.viewValue.trim())) {
        this.snackBarService.openSnackBar(
          'You are not authorized to add an asset you do not have',
          'Okay'
        );
      } else {
        this.assetsArray.push(event.option.viewValue);
        this.assetsCtrl.setValue(null);
      }
    }
    this.assetsInput.nativeElement.value = '';
  }

  /**
   * Updates Assets
   * @description Updates the selected user assets
   * @memberof EditUserAssetsComponent
   */
  updateAssets() {
    // Prevents null error
    if (this.assetsArray.length === 0) {
      this.assetsArray.push('');
    }
    this.adminApiService
      .updateAssets(this.selectedUser.username, this.assetsArray)
      .subscribe(result => {
        console.log(result);
        if (result) {
          // Show response
          this.snackBarService.openSnackBar(JSON.stringify(result), 'Okay');

          let logMessage = ``;
          let counter = 0;
          // Logging added assets
          if (this.assetsArray.length > 0) {
            logMessage = `Asset(s) `;
            for (let asset of this.assetsArray) {
              if (asset !== '' && !this.initialAssetsArray.includes(asset)) {
                logMessage = logMessage + asset + ' ';
                counter ++;
              }
            }
            logMessage = logMessage + `were successfully added to user: ${this.selectedUser.username}.`

            if (counter > 0) {
              const stream = this.assetService.getSelectedAssetId();
              this.writeLoggingService.setStream(stream);
              this.writeLoggingService
                .writeUserLog({
                  username: this.amplifyService.username,
                  action: 'Edited User Assets',
                  time: new Date().toISOString(),
                  log: logMessage
                })
                .subscribe(result => {
                  // logging result
                  console.log(result);
                });
            }
          }
          
          // Logging removed assets
          if (this.initialAssetsArray.length > 0) {
            logMessage = `Asset(s) `;
            counter = 0;
            for (let asset of this.initialAssetsArray) {
              if (asset !== '' && !this.assetsArray.includes(asset)) {
                logMessage = logMessage + asset + ' ';
                counter++;
              }
            }
            logMessage = logMessage + `were successfully removed from user: ${this.selectedUser.username}.`

            if (counter > 0) {
              const stream = this.assetService.getSelectedAssetId();
              this.writeLoggingService.setStream(stream);
              this.writeLoggingService
                .writeUserLog({
                  username: this.amplifyService.username,
                  action: 'Edited User Assets',
                  time: new Date().toISOString(),
                  log: logMessage
                })
                .subscribe(result => {
                  // logging result
                  console.log(result);
                });
            }
          }

          // Set selected user to null
          this.selectedFieldService.onUserFieldSelected.next(null);
          // Init asset array to empty array
          this.assetsArray = [];
          // Reload component
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
              this.router.navigate(['users']);
            });
        }
      });
  }
}
