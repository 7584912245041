import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { APPCONSTANTS } from 'src/app/app.constants';
import { environment } from 'src/environments/environment';
import { AssetService } from './asset.service';
import { CcmcApiService } from './ccmc-api.service';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { CCMCSelectedFieldService } from './selected-field.service';
const { Buffer } = require('buffer');

@Injectable({
  providedIn: 'root'
})
export class GeneralLedgerAccountingService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  mapping: any;
  configurations: any;
  private _glaDocument: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _selectedSetDocument: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  private _exportDocument: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  coreTable: any;
  glaDocumentBackup: any;
  private _isGLABalanced: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  constructor(
    private assetService: AssetService,
    private selectedFieldService: CCMCSelectedFieldService,
    private http: HttpClient,
    private customErrorHandlerService: CustomErrorHandlerService,
    private ccmcAPIService: CcmcApiService
  ) {}

  get glaDocument() {
    return this._glaDocument.asObservable().pipe(
      map(document => {
        return document;
      })
    );
  }
  setGLADocumentSimple(gladocument: any) {
    if (gladocument.translators) {
      this.selectedFieldService.onTranslatorFieldSelected.next(
        gladocument.translators[0]
      );
    }
    this._glaDocument.next(gladocument);
  }
  
  get exportDocument() {
    return this._exportDocument.asObservable().pipe(
      map(document => {
        return document;
      })
    );
  }
  updateGLADocument(glaDocument: any) {
    this._glaDocument.next(glaDocument);
  }
  get selectedSetDocument() {
    return this._selectedSetDocument.asObservable().pipe(
      map(document => {
        return document;
      })
    );
  }


  get isGLABalanced() {
    return this._isGLABalanced.asObservable().pipe(
      map(isGLABalanced => {
        return isGLABalanced;
      })
    );
  }

  setIsGLABalanced(isGLABalanced: boolean) {
    this._isGLABalanced.next(isGLABalanced);
  }

  setExportDocument(exportDocument: any) {
    this._exportDocument.next(exportDocument);
  }

  setGLAObject(glaDocument: any) {
    // set document backup
    this.glaDocumentBackup = JSON.parse(JSON.stringify(glaDocument));
    this._glaDocument.next(glaDocument);
  }

  setSelectedSetDocument(selectedSetDocument: any) {
    console.log('Set selected Document', selectedSetDocument);
    // console.log(JSON.parse(JSON.stringify(selectedSetDocument.coreLayout)));
    this.sortCoreLayout(selectedSetDocument.coreLayout);
    // console.log(JSON.parse(JSON.stringify(selectedSetDocument.coreLayout)));
    this._selectedSetDocument.next(selectedSetDocument);
  }

  sortCoreLayout(array: any) {
    array.sort(function (a: any, b: any) {
      return a.displayOrder - b.displayOrder;
    });
  }

  setGLADocumentCoreData(documentCore: any) {
    // set document backup
    this.glaDocumentBackup = JSON.parse(JSON.stringify(documentCore));
    // set behavior subject
    this._glaDocument.next(documentCore);
  }

  ice(loan: any) {
    const assetID = this.assetService.getSelectedAssetId();
    const body = JSON.stringify({
      assetID: assetID,
      content: {
        data: loan
      }
    });
    return this.http
      .post(`${Buffer.from(environment.environmentURL, "base64").toString()}/ice/data-conversion-gla`, body, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  createDocumentGLA(coreConnectionData: any) {
    return new Promise((resolve, reject) => {
      const currentDoc = this._glaDocument.getValue();
      this.ccmcAPIService
        .createDocumentGLA(currentDoc,coreConnectionData)
        .subscribe(result => resolve(result));
    });
  }
}
