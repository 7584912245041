<mat-toolbar class="p-0 mat-elevation-z1">
  <mat-progress-bar *ngIf="showLoadingBar" class="loading-bar" color="accent" mode="indeterminate"></mat-progress-bar>

  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center" class="button-container">
      <button mat-icon-button class="toggle-button-navbar" *ngIf="!noNav" (click)="toggleSidebarOpened('navbar')"
        fxHide.gt-md>
        <mat-icon>menu</mat-icon>
      </button>

      <div class="toolbar-separator" fxHide.gt-md></div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNav">
        <div class="logo ml-16">
          <img class="logo-icon" src="assets/images/logos/GC-rocket-omni-Sidebar.png" />
        </div>
      </div>
      <!--Save button-->
      <div *ngIf="router.url.includes('doc-connector/history')" class="px-8 px-mat-16" style="margin-right: 15px">
        <button mat-button class="Btn-send" (click)="onSendHistory()" [disabled]="dcSendButtonFlag === false"
          matTooltip="This button will send the selected loans through the Doc Connector process"
          matTooltipClass="tooltip-color">
          Send
        </button>
      </div>

      <!--Find button-->
      <div *ngIf="router.url.includes('transaction') && !isGLA()" class="px-8 px-mat-16" style="margin-right: 15px">
        <button mat-button class="Btn-fieldInfo" [disabled]="!selectedField?.fieldID" 
          [ngStyle]="{'color': !selectedField?.fieldID ? 'gray' : 'white'}"(click)="openDialog()"
          matTooltip="This button is used to get specific information on a selected field."
          matTooltipClass="tooltip-color">
          <mat-icon style="padding-left: 6px">info</mat-icon>
          &nbsp; Field Info
        </button>
      </div>

      <!--Search button -->
      <div *ngIf="
          router.url.includes('transaction') && showSearchButton && !isGLA()
        " class="px-8 px-mat-16" style="margin-left: 5px">
        <button mat-button class="Btn-search" (click)="search()" matTooltip="This button is used to Search Borrowers."
          matTooltipClass="tooltip-color">
          <mat-icon style="color: white; padding-left: 6px">person</mat-icon>
          &nbsp; Search
        </button>
      </div>

      <!--Submit button-->
      <div *ngIf="router.url.includes('import')" class="send-container px-8 px-mat-16">
        <button mat-button class="Btn-submit" (click)="importSend()" matTooltip="This button is used to import loans."
          matTooltipClass="tooltip-color" [disabled]="!checkImportArray">
          Submit&nbsp;&nbsp;<mat-icon style="color: white">input</mat-icon>
        </button>
      </div>

      <!--Submit button-->
      <div *ngIf="router.url.includes('transaction') && !isGLA()" class="send-container px-8 px-mat-16">
        <button mat-button class="Btn-submit" [disabled]="sendDisabled || showSpinner" (click)="send()"
          matTooltip="This button is used to export loan." matTooltipClass="tooltip-color">
          Submit&nbsp;&nbsp;<mat-icon style="color: white">input</mat-icon>
        </button>
      </div>
      <div *ngIf="router.url.includes('transaction') && !isGLA() && checkOverrideSubmitPermissions()
        " class="send-container px-8 px-mat-16">
        <button mat-button class="Btn-submit" [disabled]="!sendDisabled || showSpinner" (click)="override()">
          Override Submit
        </button>
      </div>
      <div *ngIf="
          (router.url.includes('gla/transactions') ||
          router.url.includes('funding-ledger'))
        " class="send-container px-8 px-mat-16">
        <button mat-button class="Btn-submit" (click)="processGLACheck()">
          Submit&nbsp;&nbsp;<mat-icon style="color: white">input</mat-icon>
        </button>
      </div>
      <div *ngIf="
          (router.url.includes('gla/transactions') ||
          router.url.includes('funding-ledger') ||
          router.url.includes('reviewList'))
        " class="send-container px-8 px-mat-16">
        <button mat-button class="Btn-submit" (click)="exportToCSV()">
          Export
        </button>
      </div>
      <div *ngIf="
          router.url.includes('request-change')
        " class="send-container px-8 px-mat-16">
        <button mat-button class="Btn-submit" (click)="exportToCSVChangeRequest()">
          Export
        </button>
      </div>
      <div *ngIf="
          router.url.includes('ebm-logging') || router.url.includes('ebm-report')
        " class="send-container px-8 px-mat-16">
        <button mat-button class="Btn-submit" (click)="exportEBM()">
          Export
        </button>
      </div>

      <!--Revert button-->
      <div *ngIf="
          currentNavigationType === 'admin' ||
          (isDocConnectorAsset() &&
            !router.url.includes('doc-connector/history') &&
            !router.url.includes('doc-connector/logs'))
        " class="send-container px-8 px-mat-16">
        <button mat-raised-button matTooltip="Reverts current changes to the document." matTooltipClass="tooltip-color"
          color="#3b3b3b" (click)="revertChanges()" class="revertBtn-toolbar" [disabled]="editedFlag">
          <mat-icon>undo</mat-icon> &nbsp; Revert
        </button>
      </div>

      <!--Back up button-->
      <div *ngIf="currentNavigationType === 'admin'" class="send-container px-8 px-mat-16">
        <button mat-raised-button matTooltip="Submits a backup of the document." matTooltipClass="tooltip-color"
          (click)="createBackup()" class="backupBtn-toolbar" color="#7570d2" [disabled]="editedFlag">
          <mat-icon>post_add</mat-icon> &nbsp; Backup
        </button>
      </div>

      <!-- Submit button-->
      <div *ngIf="currentNavigationType === 'admin'" class="send-container px-8 px-mat-16">
        <div *ngIf="coreEditFlag">
          <button mat-raised-button matTooltip="Update the Mapping Object, with all
      of changes you have made." (click)="onSubmitUpdateCore()" matTooltipClass="tooltip-color" [disabled]="editedFlag"
            class="CoreBtn-toolbar" color="accent">
            Update Core &nbsp;
            <mat-icon>cloud_done</mat-icon>
          </button>
        </div>
      </div>

      <!-- Export Users button-->
      <div *ngIf="currentNavigationType === 'admin' && router.url.includes('users')" class="send-container px-8 px-mat-16" style="margin-left: -1%;">
          <button mat-raised-button matTooltip="Export list of users" (click)="getUsers()" matTooltipClass="tooltip-color"
            class="exportBtn-toolbar" color="#7570d2">
            <mat-icon>cloud_download</mat-icon>
            &nbsp; Export Users
          </button>
      </div>

      <!-- Logging Groups Selector -->
      <mat-form-field *ngIf="router.url.includes('logging') && !router.url.includes('ebm')" class="send-container px-8 px-mat-16" style="min-width: 10em;" appearance="fill" [floatLabel]="'never'">
        <mat-select placeholder="Logging Group" [(ngModel)]="selectedLoggingGroup">
          <mat-option *ngFor="let group of loggingGroups" [value]="group.value" (click)="selectLoggingGroup(group.value)">
            {{group.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Update Doc Connector Document -->
      <div *ngIf="isDocConnectorAsset()" class="send-container px-8 px-mat-16">
        <div *ngIf="losEditFlag">
          <button mat-raised-button matTooltip="Update the Document Connector Schema, with all
      of changes you have made." (click)="onSubmitDocConnectorUpdates()" matTooltipClass="tooltip-color"
            [disabled]="editedFlag" class="CoreBtn-toolbar" color="accent">
            Update Schema &nbsp;
            <mat-icon>cloud_done</mat-icon>
          </button>
        </div>
      </div>

      <div *ngIf="isGLAAsset()" class="send-container px-8 px-mat-16">
        <div *ngIf="glaEditFlag">
          <button mat-raised-button matTooltip="Update the GLA Schema, with all
      of changes you have made." (click)="onSubmitUpdateGLA()" matTooltipClass="tooltip-color"
            [disabled]="editedFlag" class="CoreBtn-toolbar" color="accent">
            Update Schema &nbsp;
            <mat-icon>cloud_done</mat-icon>
          </button>
        </div>
      </div>

      <div *ngIf="isMortgageBot && router.url.includes('file-selector')" class="send-container px-8 px-mat-16">
        <button mat-raised-button matTooltip="Get Loans" (click)="getMortgageBotLoans()" matTooltipClass="tooltip-color"
          class="CoreBtn-toolbar" color="accent" [disabled]="getLoansButtonFlag === true">
          Get Loans &nbsp;
          <mat-icon>cloud_done</mat-icon>
        </button>
      </div>
    </div>

    <!--User Dropdown-->
    <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
      <div class="impSlider" *ngIf="checkNxtsoft()">
        Implementation Mode
        <mat-slide-toggle [(ngModel)]="impModechecked" (change)="onClickSwitch()"></mat-slide-toggle>&nbsp; &nbsp;&nbsp;
        &nbsp;&nbsp; &nbsp;
      </div>
      <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center">
        <i *ngIf="userIcon === 'user'" style="line-height: 33px;" class="fa-solid fa-user avatar-icon"></i>
        <i *ngIf="userIcon === 'key'" style="line-height: 32px;" class="fa-solid fa-key avatar-icon"></i>
        <i *ngIf="userIcon === 'cog'" style="line-height: 33px; font-size: 18px;" class="fa-solid fa-user-cog avatar-icon"></i>
        <i *ngIf="userIcon === 'pen'" style="line-height: 33px; font-size: 18px;" class="fa-solid fa-user-pen avatar-icon"></i>
        <i *ngIf="userIcon === 'rocket'" style="line-height: 37px;" class="fa-solid fa-rocket avatar-icon"></i>
          <!-- <img *ngIf="
              !isNXTsoftDev &&
              !isNXTsoftOperations &&
              !isAdmin &&
              !isNXTsoftSales &&
              !isSuperAdmin &&
              !isLoanSystemsAdmin &&
              !isLoanServicingAdmin
            " class="avatar" src="assets/images/avatars/{{ avatar }}.png" /> -->
          <span class="username mr-12" fxHide fxShow.gt-sm>{{ name }}</span>
          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>
      </button>

      <mat-menu #userMenu="matMenu">
        <button mat-menu-item class="" (click)="productSelection()">
          <mat-icon>touch_app</mat-icon>
          <span>Select Asset</span>
        </button>

        <button mat-menu-item (click)="goToProfile()">
          <mat-icon>account_circle</mat-icon>
          <span>My Profile</span>
        </button>

        <a href="https://omniportal.nxtsoft.com/#/account-lookup" mat-menu-item target="_blank">
          <img style="margin-top: 10px; max-width: 25px; min-width: 25px" src="assets/images/logos/Portal-icon.ico " />
          <button style="margin-left: 10px; color: rgba(0, 0, 0, 0.87)">
            OMNIPORTAL
          </button></a>

        <button mat-menu-item (click)="switchNavigation()" *ngIf="
            this.currentNavigationType === 'default' &&
            showAdmin === true
          ">
          <mat-icon>vpn_key</mat-icon>
          <span>Admin</span>
        </button>

        <button mat-menu-item (click)="switchNavigation()" *ngIf="
            this.currentNavigationType === 'admin'
          ">
          <mat-icon>home</mat-icon>
          <span>Ground Control</span>
        </button>

        <!--Settings option is not ready yet-->
        <!--
      <button mat-menu-item (click)="goToSettings()">
        <mat-icon>settings</mat-icon>
        <span>Settings</span>
      </button>-->

        <!--Support option is not ready yet-->
        <!--
      <button mat-menu-item (click)="goToHelp()">
        <mat-icon>help</mat-icon>
        <span>Support</span>
      </button>-->

        <button mat-menu-item class="" (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
      <div class="toolbar-separator"></div>
      <div class="toolbar-separator" fxHide fxShow.gt-xs></div>
    </div>
  </div>
</mat-toolbar>

<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
<a id="downloadJSONElem" style="display: none"></a>