import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateSpectrumCredentialsComponent } from './update-spectrum-credentials.component';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [UpdateSpectrumCredentialsComponent],
  imports: [
    AppMaterialModule,
    ReactiveFormsModule,
    CommonModule,
    MatCheckboxModule,
    FormsModule
  ]
})
export class UpdateSpectrumCredentialsModule {}
