import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Observable, EMPTY, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { AmplifyService } from '../services/amplify.service';
import { environment } from'src/environments/environment';
const { Buffer } = require('buffer');

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private amplifyService: AmplifyService,
    private dialog: MatDialog
  ) {}

  /**
   * @description calls to determine if new accessToken needed
   * redirects to login on error
   * otherwise allows the request after attaching the accessToken
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.checkTokenExpired(request)).pipe(
      switchMap((result: any) => {
        if (!result.success) {
          this.dialog.open(ErrorDialogComponent, {
            data: {
              title: 'Error',
              message: '401 Unauthorized'
            }
          });
          this.router.navigate(['login']);
          return EMPTY;
        }
        if (this.amplifyService.environmentOverride === true || request.url.includes(`${Buffer.from(environment.environmentURL, "base64").toString()}`)) {
          request = request.clone({
            headers: request.headers.set('Authorization', result.token)
          });
        }


        let parsedBody;
        // Parse body and check for assetID
        if (typeof request.body === 'string') {
          parsedBody = JSON.parse(request.body);
        } else {
          parsedBody = JSON.parse(JSON.stringify(request.body));
        }
        // If the request includes an assetID, make sure the user has access to that ID
        if (parsedBody.hasOwnProperty('assetID')) {
          if (!this.amplifyService.checkUserAssets(parsedBody.assetID)) {
            this.dialog.open(ErrorDialogComponent, {
              data: {
                title: 'Error',
                message: '401 Unauthorized'
              }
            });
            this.router.navigate(['login']);
          }
        }

        // If the parsedBody includes an assetID, make sure the user has access to that ID
        if (parsedBody.hasOwnProperty('assetId')) {
          if (!this.amplifyService.checkUserAssets(parsedBody.assetId)) {
            this.dialog.open(ErrorDialogComponent, {
              data: {
                title: 'Error',
                message: '401 Unauthorized'
              }
            });
            this.router.navigate(['login']);
          }
        }

        return next.handle(request);
      })
    );
  }

  /**
   * @description returns success or fail, new token if necessary
   */
  checkTokenExpired(request: HttpRequest<any>) {
    return this.amplifyService.checkToken(request);
  }
}
