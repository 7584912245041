import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  Inject,
  NgZone,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { CcmcApiService } from '../../services/ccmc-api.service';
import { AmplifyService } from '../../services/amplify.service';
import { CCMCSelectedFieldService } from '../../services/selected-field.service';
import { SpinnerService } from '../../services/spinner.service';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WriteLoggingService } from 'src/@ccmc/services/write-logging.service';
import { AssetService } from 'src/@ccmc/services/asset.service';

@Component({
  selector: 'app-edit-user-popup-dialog',
  templateUrl: './edit-user-popup-dialog.component.html',
  styleUrls: ['./edit-user-popup-dialog.component.scss']
})
export class EditUserPopupDialogComponent implements OnInit {
  selectable = true;
  removable = true;
  isUserClientAdmin = false;
  isUserSuperAdmin = false;
  isClientAdmin: any;
  isSuperAdmin: any;
  addOnBlur = true;
  isNXTsoft = false;
  isNXTsoftDev = false;
  isNXTsoftOperations = false;
  assetsCtrl = new FormControl();
  assetsLists = new FormControl();
  private selectedUserSub: Subscription;
  public selectedUser: any;
  adminAssets: any;
  assetsSplit: any;
  unsubscribe: Subject<any> = new Subject();
  assetsArray: any;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  filteredAssets: Observable<any[]>;
  @ViewChild('assetsInput', { static: false }) assetsInput: ElementRef;
  currentUsersView: any;
  assetsToOwn: any = [];
  assetsInDropDown: any = [];
  usersPinned: any;
  selectedUserAccountAssets: any = [];
  spinnerSub: Subscription;
  showSpinner: boolean = false;
  availableAssets: any = [];
  removedGroup: any;
  addedGroup: any;
  clientAdminObj = {
    value: 'client-admin',
    viewValue: 'Client Admin'
  }
  superAdminObj = {
    value: 'super-admin',
    viewValue: 'Super Admin'
  }
  initialAssetsArray: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private selectedFieldService: CCMCSelectedFieldService,
    private spinnerService: SpinnerService,
    private adminApiService: CcmcApiService,
    public dialogRef: MatDialogRef<EditUserPopupDialogComponent>,
    private router: Router,
    private matDialog: MatDialog,
    private amplifyService: AmplifyService,
    private snackBar: MatSnackBar,
    private zone: NgZone,
    private assetService: AssetService,
    private writeLoggingService: WriteLoggingService
  ) {}

  ngOnInit() {
    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        // //console.log(spinner);

        this.showSpinner = spinner;
      });
    this.spinnerService.setShowSpinner(true);
    //console.log('show spinner');
    this.getCurrentUserData();
    this.isNXTsoft = this.amplifyService.isNXTsoft;
    this.isNXTsoftDev = this.amplifyService.isNXTsoftDev;
    this.isNXTsoftOperations = this.amplifyService.isNXTsoftOperations;
    this.isClientAdmin = this.amplifyService.isAdmin;
    this.isSuperAdmin = this.amplifyService.isSuperAdmin;
    this.checkCurrentUser();
  }

  checkCurrentUser() {
    this.adminApiService
      .getUserGroupsForUser(this.data.username)
      .subscribe(result => {
        //grab the usergroups for the current selected user
        const parsedResult = JSON.parse(JSON.stringify(result));
        var usergroups = parsedResult.content;
        usergroups.forEach((usergroup:any) => {
        switch (usergroup.GroupName) {
          case 'client-admin':
            this.isUserClientAdmin = true;
            break;
          case 'super-admin':
            this.isUserSuperAdmin = true;
            break;
          default:
            break;
            }
        });
    });
  }

  toggleClientAdmin() {
    if (this.isUserClientAdmin) {
      this.addedGroup = this.clientAdminObj;
    } else {
      this.removedGroup = 'client-admin';
    }
  }

  toggleSuperAdmin() {
    if (this.isSuperAdmin) {
      this.addedGroup = this.superAdminObj;
    } else {
      this.removedGroup = 'super-admin';
    }
  }

  getCurrentUserData() {
    this.usersPinned = this.data.assets.split(' ', this.data.assets.length);
    // console.log('user pinned',this.usersPinned);
    //I have two for loops because the assets come in with a comma
    //the only one that doesnt is the last one or if there is only one asset
    if (this.usersPinned.length > 0) {
      if (this.usersPinned.length > 1) {
        for (let i = 0; this.usersPinned.length - 1 > i; i++) {
          //console.log(this.usersPinned[i]);
          let tempAsset = this.usersPinned[i].slice('', -1);
          // console.log('temp asset',tempAsset);
          this.adminApiService
            .getDynamoDBItem('assets', tempAsset, 'assetId')
            .subscribe((result: any) => {
              if (result) {
                result['SalesforceAssetId'] = tempAsset;
                result['AssetName'] =
                  result['FI'] + '-' + result['los'] + '-' + result['core'];
                console.log('get current user data result',result);
                this.assetsToOwn.push(result);
                this.assetsInDropDown.push(result);
                this.initialAssetsArray.push(tempAsset);
              }
            });
        }
      }
      this.adminApiService
        .getDynamoDBItem(
          'assets',
          this.usersPinned[this.usersPinned.length - 1],
          'assetId'
        )
        .subscribe((result: any) => {
          if (result) {
            result['SalesforceAssetId'] =
              this.usersPinned[this.usersPinned.length - 1];
            result['AssetName'] =
              result['FI'] + '-' + result['los'] + '-' + result['core'];
            console.log('get current user data result',result);
            this.assetsToOwn.push(result);
            this.assetsInDropDown.push(result);
            this.initialAssetsArray.push(this.usersPinned[this.usersPinned.length - 1]);
          }
          this.getData();
        });
      //console.log(this.assetsToOwn);
    } else {
      this.spinnerService.setShowSpinner(false);
    }
  }
  getData() {
    // Get assetsInDropDown user
    this.selectedUserSub = this.selectedFieldService.onUserFieldSelected
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(assetsInDropDown => {
        if (assetsInDropDown && assetsInDropDown.username && assetsInDropDown.accountID !== '') {
          console.log('assets in dropdown',assetsInDropDown);
          this.spinnerService.setShowSpinner(true);
          this.adminApiService
            .getAccountData(assetsInDropDown.accountID)
            .subscribe(result => {
              console.log('get account data result', result);
              if (result && result.content && result.content.StatusFlag) {
                const parsedResult = JSON.parse(result.content.StatusMessage);
                console.log('parsed result',parsedResult);
                // console.log(parsedResult.Assets.length);
                let i = 0;
                for (let asset of parsedResult.Assets) {
                  console.log('asset',asset);
                  this.adminApiService
                    .getDynamoDBItem(
                      'assets',
                      asset.SalesforceAssetId,
                      'assetId'
                    )
                    .subscribe(result => {
                      console.log('dynamo assets result',result);
                      if (result) {
                        this.availableAssets.push(asset);
                      }
                      if (i == parsedResult.Assets.length - 1) {
                        // console.log('last run through');
                        // console.log(this.avalibleAssets.length);
                        if (this.availableAssets.length == 0) {
                          this.spinnerService.setShowSpinner(false);

                          this.snackBar.open(
                            `No Assets from ${assetsInDropDown.accountID} AccountID exist `,
                            'Okay'
                          );
                        } else {
                          this.spinnerService.setShowSpinner(false);
                          for (let entry of this.availableAssets) {
                            const assetValue = this.assetsInDropDown.findIndex(
                              (asset: any) =>
                                asset.SalesforceAssetId ===
                                entry['SalesforceAssetId']
                            );
                            console.log(assetValue);
                            if (assetValue < 0) {
                              this.assetsInDropDown.push(entry);
                            }
                          }
                        }
                      }
                      i++;
                      // console.log(i);
                    });
                }
              } else {
                this.spinnerService.setShowSpinner(false);
              }
            });
        } else {
          this.spinnerService.setShowSpinner(false);
          this.selectedUser = undefined;
          this.snackBar.open('User may not have an AccountID', 'Okay');
        }
      });
    //console.log(this.assetsInDropDown);
  }
  setPinnedAssets() {
    this.currentUsersView = this.adminApiService.getAccountAssets();

    if (this.currentUsersView.length == 0) {
      let assetFromStorage: any = localStorage.getItem('assetIds');
      const myAssets = JSON.parse(assetFromStorage).forEach((assetId: any) => {
        this.assetsInDropDown.push(assetId);
        //console.log(this.assetsInDropDown);
      });
      //console.log('hide spinner');

      this.spinnerService.setShowSpinner(false);
    } else if (this.currentUsersView.length > 1) {
      for (let i = 0; i < this.currentUsersView.length; i++) {
        let tempWord = this.currentUsersView[i].replace(/['"]+/g, '');
        // //console.log(tempWord);
        if (tempWord != '') {
          this.assetsInDropDown.push(tempWord);
        }
      }
      //console.log('hide spinner');

      this.spinnerService.setShowSpinner(false);
    }
  }
  filter(asset: string) {
    return this.adminAssets.filter(
      (asset: any) => asset.toLowerCase().indexOf(asset.toLowerCase()) === 0
    );
  }

  /**
   * Updates Assets
   * @description Updates the assetsInDropDown user assets
   * @memberof EditUserAssetsComponent
   */
  updateAssets() {
    this.assetsArray = [];
    for (let asset of this.assetsToOwn) {
      this.assetsArray.push(asset.SalesforceAssetId);
    }
    this.adminApiService
      .updateAssets(this.data.username, this.assetsArray)
      .subscribe(result => {
        console.log(result);
        if (result) {
          this.amplifyService.user$.pipe(filter(user => !!user)).subscribe(
            user => {
              this.zone.run(() => {
                if (this.data.username === user.username) {
                  localStorage.setItem(
                    'assetIds',
                    JSON.stringify(this.assetsArray)
                  );
                }
              });
            },
            err => console.log(err)
          );
          if (this.data.username === this.amplifyService.getCurrentUser())
            // Show response
            this.snackBar.open(JSON.stringify(result), 'Okay');

            let logMessage = ``;
            let counter = 0;
            // Logging added assets
            if (this.assetsArray.length > 0) {
              logMessage = `Asset(s) `;
              for (let asset of this.assetsArray) {
                if (asset !== '' && !this.initialAssetsArray.includes(asset)) {
                  logMessage = logMessage + asset + ' ';
                  counter ++;
                }
              }
              logMessage = logMessage + `were successfully added to user: ${this.data.username}.`
  
              if (counter > 0) {
                const stream = this.assetService.getSelectedAssetId();
                this.writeLoggingService.setStream(stream);
                this.writeLoggingService
                  .writeUserLog({
                    username: this.amplifyService.username,
                    action: 'Edited User Assets',
                    time: new Date().toISOString(),
                    log: logMessage
                  })
                  .subscribe(result => {
                    // logging result
                    console.log(result);
                  });
              }
            }
            
            // Logging removed assets
            if (this.initialAssetsArray.length > 0) {
              logMessage = `Asset(s) `;
              counter = 0;
              for (let asset of this.initialAssetsArray) {
                if (asset !== '' && !this.assetsArray.includes(asset)) {
                  logMessage = logMessage + asset + ' ';
                  counter++;
                }
              }
              logMessage = logMessage + `were successfully removed from user: ${this.data.username}.`
  
              if (counter > 0) {
                const stream = this.assetService.getSelectedAssetId();
                this.writeLoggingService.setStream(stream);
                this.writeLoggingService
                  .writeUserLog({
                    username: this.amplifyService.username,
                    action: 'Edited User Assets',
                    time: new Date().toISOString(),
                    log: logMessage
                  })
                  .subscribe(result => {
                    // logging result
                    console.log(result);
                  });
              }
            }
          
          // Set assetsInDropDown user to null
          this.selectedFieldService.onUserFieldSelected.next(null);
          // Init asset array to empty array
          this.assetsArray = [];
          this.adminApiService.callRefreshUsers();
        }
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  save() {
    // //console.log(localStorage.getItem('cognitoID'));
    //console.log(this.assetsToOwn);
    this.assetsArray = this.assetsToOwn;
    this.updateAssets();
    this.dialogRef.close();

    if (this.addedGroup) {
      this.adminApiService
        .addUserToGroup(this.data.username, this.addedGroup.value)
        .subscribe(result => {
          if (result) {
            console.log('Adding user group result',result);
            // Creating Log
            const stream = this.assetService.getSelectedAssetId();
            this.writeLoggingService.setStream(stream);
            this.writeLoggingService
              .writeUserLog({
                username: this.amplifyService.username,
                action: 'Added User to User Group',
                time: new Date().toISOString(),
                log: `User: ${this.data.username} was successfully added to ${this.addedGroup.value.toString()}.`
              })
              .subscribe(result => {
                // logging result
                console.log('Adding user group logging result',result);
              });
          }
        });
    }
    if (this.removedGroup) {
      let tempArray: any = [];
      tempArray.push(this.removedGroup);
      this.adminApiService
      .removeUserFromGroup(this.data.username, tempArray)
      .subscribe(result => {
        if (result) {
          console.log('Removing user group result',result);
          // Creating Log
          const stream = this.assetService.getSelectedAssetId();
          this.writeLoggingService.setStream(stream);
          this.writeLoggingService
            .writeUserLog({
              username: this.amplifyService.username,
              action: 'Removed User from User Group',
              time: new Date().toISOString(),
              log: `User: ${this.data.username} was successfully removed from ${this.removedGroup.toString()}.`
            })
            .subscribe(result => {
              // logging result
              console.log('Removing user group logging result',result);
            });
        }
      });
    }
    
    this.dialogRef.afterClosed().subscribe(res => {
      this.router
        .navigateByUrl('/RefrshComponent', {
          skipLocationChange: true
        })
        .then(() => this.router.navigate(['users']));
    });
  }
  enableActivation(username: any) {
    this.spinnerService.setShowSpinner(true);
    //console.log('show spinner');
    this.amplifyService
      .cognitoEnableUser(username)
      .subscribe((enableUserResponse: any) => {
        //console.log(enableUserResponse);
        if (enableUserResponse['statusFlag']) {
          //console.log('hide spinner');

          this.spinnerService.setShowSpinner(false);
          this.snackBar.open('User successfully enabled', 'Okay');
          // Creating Log
          const stream = this.assetService.getSelectedAssetId();
          this.writeLoggingService.setStream(stream);
          this.writeLoggingService
            .writeUserLog({
              username: this.amplifyService.username,
              action: 'Activated User Account',
              time: new Date().toISOString(),
              log: `User: ${username} was successfully Activated.`
            })
            .subscribe(result => {
              // logging result
              console.log('Activating user logging result',result);
            });
          this.adminApiService.callRefreshUsers();
          this.router
              .navigateByUrl('/RefrshComponent', {
                skipLocationChange: false
              })
              .then(() => this.router.navigate(['/users']));
          
          this.dialogRef.close();  
        } else {
          //console.log('hide spinner');

          this.spinnerService.setShowSpinner(false);
          this.snackBar.open(`${enableUserResponse['statusMessage']}`, 'Okay');
        }
      });
  }
  disableActivation(username: any) {
    this.spinnerService.setShowSpinner(true);
    //console.log('show spinner');
    this.amplifyService
      .cognitoDisableUser(username)
      .subscribe((disableUserResponse: any) => {
        console.log(disableUserResponse);
        if (disableUserResponse['statusFlag']) {
          //console.log('hide spinner');

          this.spinnerService.setShowSpinner(false);
          this.snackBar.open('User succesfully disabled', 'Okay');
          // Creating Log
          const stream = this.assetService.getSelectedAssetId();
          this.writeLoggingService.setStream(stream);
          this.writeLoggingService
            .writeUserLog({
              username: this.amplifyService.username,
              action: 'Deactivated User Account',
              time: new Date().toISOString(),
              log: `User: ${username} was successfully Deactivated.`
            })
            .subscribe(result => {
              // logging result
              console.log('Deactivating user logging result',result);
            });
          this.adminApiService.callRefreshUsers();
          this.router
              .navigateByUrl('/RefrshComponent', {
                skipLocationChange: false
              })
              .then(() => this.router.navigate(['/users']));
          this.dialogRef.close();
        } else {
          //console.log('hide spinner');

          this.spinnerService.setShowSpinner(false);
          this.snackBar.open(`${disableUserResponse['statusMessage']}`, 'Okay');
        }
      });
  }
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }
}
