<div
  [ngClass]="
    isNXTsoftDev || isNXTsoftOperations
      ? 'nxt-users-container'
      : 'users-container'
  "
>
  <div class="filterContainer">
    <mat-form-field class="filterField">
      <input matInput placeholder="Filter Search" #filterSearch />
    </mat-form-field>
    <button
      mat-raised-button
      matTooltip="Refresh users"
      matTooltipClass="toolTipStyle"
      color="accent"
      (click)="refreshUsers()"
      style="max-height: 35px; margin: 2px; position: relative; top: 5px"
    >
      Refresh
    </button>
  </div>
  <div>
    <div class="table-container" style="overflow-y: scroll; max-height: 100%">
      <table
        class="table"
        mat-table
        #table
        [dataSource]="dataSource"
        style="width: 100%"
        CCMCPerfectScrollbar
        matSort
      >
      <!-- MFA Column -->
      <ng-container matColumnDef="mfaEnabled" >
        <th mat-header-cell *matHeaderCellDef>MFA</th>
        <td
          mat-cell
          *matCellDef="let element"
          style="padding-right: 90px"
          class="noselect"
        >
        <i *ngIf="element.mfaEnabled === 'active'" 
        matTooltip="MFA Enabled"
        matTooltipClass="toolTipStyle"  
        class="fa fa-shield" 
        style="font-size: 23px;" 
        aria-hidden="true"></i>
        <i *ngIf="!element.mfaEnabled" 
        matTooltip="MFA Disabled"
        matTooltipClass="toolTipStyle"
        id="disabledMFA" 
        class="fa fa-shield" 
        style="font-size: 23px;" 
        aria-hidden="true"></i>
        </td>
      </ng-container>
        <!-- Username Column -->
        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Username</th>
          <td
            mat-cell
            *matCellDef="let element"
            style="padding-right: 90px"
            class="noselect"
          >
            {{ element.username }}
          </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            style="width: 540px"
          >
            Status
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            style="padding-right: 90px"
            class="noselect"
          >
            <div
              style="float: left"
              *ngIf="element.status == 'CONFIRMED' && element.enabled == true"
            >
              ENABLED:
            </div>
            <div
              style="float: left"
              *ngIf="element.status == 'CONFIRMED' && element.enabled == false"
            >
              DISABLED:
            </div>
            {{ element.status }}
          </td>
        </ng-container>

        <!-- Assets Column -->
        <ng-container matColumnDef="assets">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Assets</th>
          <td
            mat-cell
            *matCellDef="let element"
            style="padding-right: 90px"
            class="noselect"
          >
            {{ element.assets }}
          </td>
        </ng-container>

        <ng-container
          *ngIf="!isNXTsoftDev && !isNXTsoftOperations"
          matColumnDef="edit"
        >
          <th mat-header-cell *matHeaderCellDef class="editButton">
            <mat-icon
              (click)="addAssetBox()"
              (readonly)="(showSpinner)"
              style="color: #3fb2c6; cursor: pointer"
              >add_circle</mat-icon
            >
          </th>
          <td mat-cell class="editButton" *matCellDef="let element">
            <mat-icon
              aria-hidden="false"
              style="cursor: pointer; float: right"
              aria-label="icon to tell if pinned"
              (click)="editAssetBox(element)"
              >create</mat-icon
            >
          </td>
        </ng-container>
        <ng-container
          *ngIf="isNXTsoftDev || isNXTsoftOperations"
          matColumnDef="edit"
          ><th mat-header-cell *matHeaderCellDef class="editButton"></th>
          <td mat-cell class="editButton" *matCellDef="let element"></td
        ></ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="onSelect(row)"
          [ngClass]="{ 'accent-50-bg': row == selectedUser }"
        ></tr>
      </table>
    </div>
  </div>
  <mat-tab-group
    *ngIf="isNXTsoftDev || isNXTsoftOperations"
    style="
      background-color: #dfdfdf;
      border-top: 1px solid #adaaaa;
      height: 400px;
      position: relative;
      bottom: 15px;
    "
  >
    <mat-tab label="Edit User Assets">
      <div style="padding: 5px">
        <app-edit-user-assets></app-edit-user-assets>
      </div>
    </mat-tab>
    <mat-tab label="Add New User">
      <div style="padding: 5px">
        <app-add-new-user></app-add-new-user>
      </div>
    </mat-tab>
    <mat-tab label="Edit User Group" *ngIf="isUserNXTsoft">
      <div style="padding: 5px">
        <app-edit-users-group></app-edit-users-group>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<!--Spinner-->
<div class="spinner-container" *ngIf="showSpinner">
  <div class="new-spinner">
    <div class="outer-ring"></div>
    <div class="middle-ring"></div>
    <div class="inner-ring"></div>
  </div>
</div>
