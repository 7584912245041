import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditFieldidsDialogComponent } from './edit-fieldids-dialog.component';



@NgModule({
  declarations: [EditFieldidsDialogComponent],
  imports: [
    AppMaterialModule, CommonModule, FormsModule, ReactiveFormsModule
  ],
  exports: [
    EditFieldidsDialogComponent
  ]
})
export class EditFieldidsDialogModule { }
