// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { MetadataService } from 'aws-sdk';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DocumentConnectorService } from 'src/@ccmc/services/doc-connector.service';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { CCMCConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { PasteFromExcelDialogComponent } from '../paste-from-excel-dialog/paste-from-excel-dialog.component';

@Component({
  selector: 'app-add-new-destination-dialog',
  templateUrl: './add-new-destination-dialog.component.html',
  styleUrls: ['./add-new-destination-dialog.component.scss']
})
export class AddNewDestinationDialogComponent implements OnInit {
  dcDocumentSub: Subscription;
  unsubscribe: Subject<any> = new Subject();
  tempDocument: any;
  destinations: any;
  newDestination: any;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  destinationIDColumns = ['id', 'remove'];
  destinationIDDataSource: any;
  metasDisplayedColumns = ['name', 'index', 'value', 'path', 'remove'];
  metasDataSource: any;
  sourcesDisplayedColumns = ['source', 'remove'];
  sourcesDataSource: any;
  selectedMeta: any;
  newSource = '';
  bulkDestinationIDs: any = [];
  completeReviewTableColumns = ['id', 'remove'];
  completeReviewDataSource: any;
  hideMetaStep = false;
  hideSourceStep = false;
  tempDestinationID: any;
  constructor(
    private docConnectorService: DocumentConnectorService,
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AddNewDestinationDialogComponent>,
    private fieldEditedService: FieldEditedService
  ) {}

  ngOnInit(): void {
    this.getData();
    this.initFormGroup();
  }

  initFormGroup() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.thirdFormGroup = this._formBuilder.group({
      thirdControl: ['', Validators.required]
    });
  }

  getData() {
    // Subscribe to the document and grab the support values
    this.dcDocumentSub = this.docConnectorService.dcDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(document => {
        if (document) {
          this.tempDocument = document;
          this.destinations = document.destinations;
          console.log(this.destinations);
          this.initNewDestination();
        }
      });
    // Set DestinationID Table datasource
    this.completeReviewDataSource = new MatTableDataSource(
      this.bulkDestinationIDs
    );
  }

  initNewDestination() {
    // Init new destination
    if (this.destinations.length === 0) {
      this.newDestination = {};
      this.newDestination.id = '';
      this.newDestination.orderIndex = 1;
      this.newDestination.joinImages = true;
      this.newDestination.separatePages = false;
      this.newDestination.newFileName = '%loanNumber%-%fileName%';
      this.newDestination.newFileType = 'PDF';
      this.newDestination.metas = [];
      this.newDestination.sources = [];
    } else {
      this.newDestination = JSON.parse(JSON.stringify(this.destinations[0]));
      this.newDestination.id = '';
      for (let meta of this.newDestination.metas) {
        if (meta.name === 'DocumentType') {
          meta.value = '';
        }
      }
      console.log(this.newDestination);
    }
    this.metasDataSource = new MatTableDataSource(this.newDestination.metas);
    if (this.newDestination.metas.length > 0) {
      this.onSelectMeta(this.newDestination.metas[0]);
    }
    this.sourcesDataSource = new MatTableDataSource(
      this.newDestination.sources
    );
  }

  verifyConfigurationFields(stepper: any) {
    if (this.bulkDestinationIDs.length > 0) {
      if (
        this.newDestination.joinImages === true ||
        this.newDestination.joinImages === false
      ) {
        if (
          this.newDestination.separatePages === true ||
          this.newDestination.separatePages === false
        ) {
          if (JSON.stringify(this.newDestination.orderIndex).length > 0) {
            if (this.newDestination.newFileName.length > 0) {
              if (this.newDestination.newFileType.length > 0) {
                stepper.selected.completed = true;
                stepper.next();
                return true;
              } else {
                this.dialog.open(ErrorDialogComponent, {
                  data: {
                    title: 'Error',
                    message: 'New File Type must not be blank.'
                  }
                });
                return false;
              }
            } else {
              this.dialog.open(ErrorDialogComponent, {
                data: {
                  title: 'Error',
                  message: 'New File Name must not be blank.'
                }
              });
              return false;
            }
          } else {
            this.dialog.open(ErrorDialogComponent, {
              data: {
                title: 'Error',
                message: 'Order Index must not be blank.'
              }
            });
            return false;
          }
        } else {
          this.dialog.open(ErrorDialogComponent, {
            data: {
              title: 'Error',
              message: 'Separate Pages must not be blank.'
            }
          });
          return false;
        }
      } else {
        this.dialog.open(ErrorDialogComponent, {
          data: {
            title: 'Error',
            message: 'Join Images must not be blank.'
          }
        });
        return false;
      }
    } else {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          title: 'Error',
          message: 'Must have at least one ID entered.'
        }
      });
      return false;
    }
  }

  verifyNewDestinationID(destinationID: any) {
    for (let dest of this.tempDocument.destinations) {
      if (destinationID === dest.id) {
        return false;
      }
    }
    return true;
  }

  verifyMetas(stepper: any) {
    if (this.newDestination.metas.length === 0) {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          title: 'Error',
          message: 'Metas must not be blank.'
        }
      });
      return false;
    }
    for (let meta of this.newDestination.metas) {
      if (meta.value.length === 0) {
        this.dialog.open(ErrorDialogComponent, {
          data: {
            title: 'Error',
            message: 'Meta Value must not be blank.'
          }
        });
        return false;
      }
    }
    stepper.selected.completed = true;
    stepper.next();
  }

  verifySources(stepper: any) {
    if (this.newDestination.sources.length === 0) {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          title: 'Error',
          message: 'Sources must not be blank.'
        }
      });
      return false;
    }
    for (let source of this.newDestination.sources) {
      if (source.length === 0) {
        this.dialog.open(ErrorDialogComponent, {
          data: {
            title: 'Error',
            message: 'Source must not be blank.'
          }
        });
        return false;
      }
    }
    stepper.selected.completed = true;
    stepper.next();
  }

  onSelectMeta(meta: any) {
    console.log(meta);
    this.selectedMeta = meta;
  }

  // goToNextStep() {}

  removeSource(sourceItem: any) {
    // Confirm with user to remove the support vlaue
    const confirmDialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
      data: 'Are you sure you want to remove this source?'
    });
    confirmDialogRef.afterClosed().subscribe(data => {
      if (data) {
        console.log('remove', sourceItem);
        // Set core edited to true
        // Remove the selected support value
        for (let i = 0; i < this.newDestination.sources.length; i++) {
          if (this.newDestination.sources[i] === sourceItem) {
            this.newDestination.sources.splice(i, 1);
          }
        }
        this.sourcesDataSource.data = this.newDestination.sources;
      }
    });
  }

  removeMeta(metaItem: any) {
    // Confirm with user to remove the support vlaue
    const confirmDialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
      data: 'Are you sure you want to remove this meta?'
    });
    confirmDialogRef.afterClosed().subscribe(data => {
      if (data) {
        console.log('remove', metaItem);
        // Set core edited to true
        // Remove the selected support value
        for (let i = 0; i < this.newDestination.metas.length; i++) {
          if (this.newDestination.metas[i] === metaItem) {
            this.newDestination.metas.splice(i, 1);
          }
        }
        this.metasDataSource.data = this.newDestination.metas;
      }
    });
  }

  addNewSource() {
    if (this.newSource.length > 0) {
      this.newDestination.sources.push(this.newSource);
      this.sourcesDataSource.data = this.newDestination.sources;
      this.newSource = '';
    } else {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          title: 'Error',
          message: 'Source must not be blank.'
        }
      });
    }
  }

  completeNewDestination() {
    if (this.bulkDestinationIDs.length > 0) {
      // Handle bulk adds
      for (let destID of this.bulkDestinationIDs) {
        // Verify Unique Destination ID
        let isNewDest = this.verifyNewDestinationID(destID);
        if (isNewDest == false) {
          this.dialog.open(ErrorDialogComponent, {
            data: {
              title: 'Error',
              message: `ID: ${destID} already exists in Destinations.`
            }
          });
          return;
        }
        // Create new tempDest Item
        let tempDest = JSON.parse(JSON.stringify(this.newDestination));
        // Update id with current item in array
        tempDest.id = destID;
        console.log('Temp Destination Item To add', tempDest);
        // Push destination to destinations array
        this.tempDocument.destinations.push(tempDest);
      }

      // Update doc connector observable
      this.docConnectorService.setDcDocumentSimple(this.tempDocument);
      // Close dialog with true for success
      this.fieldEditedService.losEdited.next(true);
      this.dialogRef.close(true);
    } else {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          title: 'Error',
          message: 'No destinations to add.'
        }
      });
    }
  }

  close() {
    this.dialogRef.close(false);
  }

  async openPasteFromExcelDialog() {
    let text = await navigator.clipboard.readText();
    console.log(JSON.stringify(text));
    let splitDestinationIDs = JSON.stringify(text)
      .slice(0, -1)
      .replace('"', '')
      .split('\\r\\n');
    console.log(splitDestinationIDs);
    let newDestinationIDs = [];
    for (let [index, splitItem] of splitDestinationIDs.entries()) {
      console.log('Split Item', splitItem);
      if (splitItem.length === 0) {
        continue;
      }
      let tempDestinationID = splitItem;
      if (splitItem.includes('/') || splitItem.includes('\\')) {
        tempDestinationID = splitItem.replace(/\//g, '_');
        tempDestinationID = tempDestinationID.replace(/\\/g, '_');
      }
      let isNewDest = this.verifyNewDestinationID(splitItem);
      if (isNewDest == false) {
        this.dialog.open(ErrorDialogComponent, {
          data: {
            title: 'Error',
            message: `Destination ID must be unique: ${splitItem}`
          }
        });
        return;
      }
      newDestinationIDs.push(tempDestinationID);
    }
    this.bulkDestinationIDs = newDestinationIDs;
    this.newDestination.id = JSON.stringify(newDestinationIDs);
    this.completeReviewDataSource.data = this.bulkDestinationIDs;
    // Hide Meta and Source step on bulk add
    this.hideMetaStep = true;
    this.hideSourceStep = true;
    console.log(this.bulkDestinationIDs);
  }

  removeDestinationFromComplete(destinationID: any) {
    // Confirm with user to remove the support vlaue
    const confirmDialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
      data: `Are you sure you want to remove this destination?: ${destinationID}`
    });
    confirmDialogRef.afterClosed().subscribe(data => {
      if (data) {
        console.log('remove', destinationID);
        // Set core edited to true
        // Remove the selected support value
        for (let i = 0; i < this.bulkDestinationIDs.length; i++) {
          if (this.bulkDestinationIDs[i] === destinationID) {
            this.bulkDestinationIDs.splice(i, 1);
          }
        }
        this.completeReviewDataSource.data = this.bulkDestinationIDs;
      }
    });
  }

  addToDestinationIDList() {
    let isNewDest = this.verifyNewDestinationID(this.tempDestinationID);
    if (isNewDest == false) {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          title: 'Error',
          message: 'Destination ID must be unique.'
        }
      });
      return false;
    }
    if (
      this.tempDestinationID.includes('/') ||
      this.tempDestinationID.includes('\\')
    ) {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          title: 'Error',
          message: `Destination ID must not include '/' or '\\'`
        }
      });
      return false;
    }
    this.bulkDestinationIDs.push(this.tempDestinationID);
    this.completeReviewDataSource.data = this.bulkDestinationIDs;
    this.tempDestinationID = '';

    if (this.bulkDestinationIDs.length > 1) {
      // Hide Meta and Source step on bulk add
      this.hideMetaStep = true;
      this.hideSourceStep = true;
    }
  }
}
