import { I } from '@angular/cdk/keycodes';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Navigation, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ErrorDialogComponent } from 'src/@ccmc/components/error-dialog/error-dialog.component';
import { CCMCNavigationService } from 'src/@ccmc/components/navigation/navigation.service';
import { ActiveCoreService } from 'src/@ccmc/services/active-core.service';
import { ActiveLosService } from 'src/@ccmc/services/active-los.service';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { CaptureDataService } from 'src/@ccmc/services/capture-data.service';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { DocumentConnectorService } from 'src/@ccmc/services/doc-connector.service';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { GeneralLedgerAccountingService } from 'src/@ccmc/services/general-ledger-accounting.service';
import { ImportService } from 'src/@ccmc/services/import.service';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
import { glaNavigation, navigation } from 'src/app/navigation/navigation';
import { MyErrorStateMatcher } from '../loan-lookup/loan-lookup.component';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit {
  changedLoans: any[] = [];
  freshchangedLoan: any;
  currentLos: string;
  loanGetParams: {};
  displayDropDown: any;
  usersSelected: any[] = []
  searchResults: any[] = []
  dcDocument: any;
  constructor(private activeLosService: ActiveLosService,
    private spinnerService: SpinnerService,
    private navigationService: CCMCNavigationService,
    private assetService: AssetService,
    private ccmcApiService: CcmcApiService,
    private activeCoreService: ActiveCoreService,
    private router: Router,
    private dcService: DocumentConnectorService,
    private dialog: MatDialog,
    private glaService: GeneralLedgerAccountingService,
    private captureDataService: CaptureDataService,
    private fieldEditedService: FieldEditedService,
    private importService: ImportService,
    private adminApiService: AdminApiService) { }
  matcher = new MyErrorStateMatcher();
  customerLookupParams: any;
  losSubscription: Subscription;
  impModeSub: Subscription;
  loans: any;
  dynamicNav: any = navigation;
  dynamicGLANav: any = glaNavigation;
  displayedColumns: any[] = [];
  encompassUseColumns = [
    'borrowerName',
    'taxId',
    'cif',
    'customerBase.assignedBorrowerType'
  ];
  encompassDropdown = [
    {
      view: 'Borrower',
      value: 'Borrower'
    },
    {
      view: 'Co-Borrower',
      value: 'Co-Borrower'
    }
  ]
  dataSource: any;
  showSpinner: boolean; // flag for the mat-spinner
  private spinnerSub: Subscription;
  selectedLoan: any;
  glaDocumentSub: Subscription;
  dcDocumentSub: Subscription;
  navigation: Navigation;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  cifFormControl = new FormControl('', [Validators.minLength(2)]);
  taxIDFormControl = new FormControl('', [Validators.minLength(4)]);
  firstNameFormControl = new FormControl('', [Validators.minLength(2)]);
  lastNameFormControl = new FormControl('', [Validators.minLength(2)]);
  isSearchDisabled = true;
  glaDocument: any;
  unsubscribe: Subject<any> = new Subject();
  showChangeRequest = false;
  
  ngOnInit(): void {
    this.importService.setUserArray(undefined)
    this.searchResults = []
    this.usersSelected = []
    this.customerLookupParams = {
      customerBase: {
        name: {
          lastName: '',
          firstName: ''
        },
        customerID: '',
        taxID: ''
      }
    };
    this.currentLos = 'encompass';
    this.displayedColumns = this.encompassUseColumns;
    this.displayDropDown = this.encompassDropdown;
    // this.thisIsForTesting()
    this.checkForm()
    this.glaDocumentSub = this.glaService.glaDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(glaDocument => {
        if (glaDocument) {
          this.glaDocument = glaDocument;
        }
      });

    this.dcDocumentSub = this.dcService.dcDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dcDocument => {
        if (dcDocument) {
          this.dcDocument = dcDocument;
        }
      });
    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        // //console.log(spinner);

        this.showSpinner = spinner;
      });
  }
  searchUsers() {
    this.spinnerService.setShowSpinner(true);
    console.log(this.customerLookupParams)
    this.importService.customerSearch(this.customerLookupParams, this.glaDocument, this.dcDocument).pipe(takeUntil(this.unsubscribe))
      .subscribe(result => {
        console.log(result)
        if (result.statusFlag) {
          this.searchResults = result.content
          this.dataSource = new MatTableDataSource(undefined);
          this.createDataTable()
        }
        else {
          this.dialog.open(ErrorDialogComponent, {
            data: {
              title: 'Error',
              message: 'No search results found'
            }
          });
          // if (result.statusMessage) {
          //   this.dialog.open(ErrorDialogComponent, {
          //     data: {
          //       title: 'Error',
          //       message: result.statusMessage
          //     }
          //   });
          // }
          // else if (result.message) {
          //   this.dialog.open(ErrorDialogComponent, {
          //     data: {
          //       title: 'Error',
          //       message: result.error.statusMessage
          //     }
          //   });
          // }
          // else {
          //   this.dialog.open(ErrorDialogComponent, {
          //     data: {
          //       title: 'Error',
          //       message: 'Please see logs for error'
          //     }
          //   });
          // }
          this.searchResults = []
          this.dataSource = new MatTableDataSource(undefined);
          this.createDataTable()
        }
      });
  }
  createDataTable() {
    let data = []
    console.log(this.searchResults)
    console.log(this.usersSelected)
    data = [...this.usersSelected]
    console.log(data)
    if (this.searchResults) {
      this.searchResults.forEach(element => {
        data.push(element)
      });
    }
    console.log(data)
    this.dataSource = new MatTableDataSource(data);
    this.spinnerService.setShowSpinner(false);
  }
  thisIsForTesting() {
    let temp = {
      borrowerName: 'name',
      cif: 'cif',
      taxID: 'number',
      customerBase: {
        customerID: 'test'

      }
    }
    let tempArray = []
    tempArray.push(temp)
    this.searchResults = tempArray
    this.dataSource = new MatTableDataSource(tempArray);
  }
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }
  selectLoan(row: any) {
    console.log(row)
    console.log(this.searchResults)
    console.log(this.usersSelected)
    if (row.customerBase.assignedBorrowerType === 'Borrower' || row.customerBase.assignedBorrowerType === 'Co-Borrower') {
      let index = this.searchResults.findIndex(user => row.customerBase.customerID === user.customerBase.customerID)
      console.log(index)
      this.searchResults.splice(index, 1)
      this.usersSelected.push(row)
    }
    else if (row.customerBase.assignedBorrowerType === '') {
      let index = this.usersSelected.findIndex(user => row.customerBase.customerID === user.customerBase.customerID)
      this.usersSelected.splice(index, 1)
      this.searchResults.push(row)
    }
    this.importService.setUserArray(this.usersSelected)
  }
  clear() {
    this.dataSource = new MatTableDataSource(undefined);
    this.searchResults = []
    this.customerLookupParams.customerBase.customerID = ''
    this.customerLookupParams.customerBase.name.firstName = ''
    this.customerLookupParams.customerBase.name.lastName = ''
    this.customerLookupParams.customerBase.taxID = ''
    this.createDataTable()
  }
  checkForm() {
    if (this.customerLookupParams.customerBase.customerID || this.customerLookupParams.customerBase.name.firstName || this.customerLookupParams.customerBase.name.lastName || this.customerLookupParams.customerBase.taxID) {
      this.isSearchDisabled = false
    }
    else {
      this.isSearchDisabled = true
    }
  }
  removeUser(element: any) {
    element.customerBase.assignedBorrowerType = ''
    this.selectLoan(element)
  }
}
