import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateSetTransactionDialogComponent } from './create-set-transaction-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';

@NgModule({
  declarations: [CreateSetTransactionDialogComponent],
  imports: [AppMaterialModule, CommonModule, FormsModule, ReactiveFormsModule],
  entryComponents: [CreateSetTransactionDialogComponent]
})
export class CreateSetTransactionDialogModule {}
