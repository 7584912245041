import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditSetDialogComponent } from './edit-set-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';

@NgModule({
  declarations: [EditSetDialogComponent],
  imports: [AppMaterialModule, CommonModule, FormsModule, ReactiveFormsModule],
  entryComponents: [EditSetDialogComponent]
})
export class EditSetDialogModule {}
