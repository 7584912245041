import { Injectable, NgZone } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { CookieService } from 'ngx-cookie-service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Amplify, Auth, Hub, Logger } from 'aws-amplify';
import { CryptrService } from 'src/@ccmc/services/cryptr.service';
import { environment } from 'src/environments/environment';
import { ErrorDialogComponent } from 'src/@ccmc/components/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { adminNavigation, navigation } from '../../app/navigation/navigation';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
// import { dateInputsHaveChanged } from '@angular/material/datepicker/datepicker-input-base';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  jwtHelper;
  constructor(
    private authService: AuthService,
    private router: Router,
    private cookieService: CookieService,
    private ccmcApiService: CcmcApiService,
    private cryptrService: CryptrService,
    private dialog: MatDialog,
    private zone: NgZone,
    private amplifyService: AmplifyService,
    private spinnerService: SpinnerService
  ) {
    this.jwtHelper = new JwtHelperService();
  }

  /**
   * @description determines whether route can be accessed
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkActiveSession(route);
  }

  checkActiveSession(route: any) {
    return this.amplifyService
      .getCurrentUser()
      .then(async result => {
        const parsedResult = JSON.parse(JSON.stringify(result));
        let user = await Auth.currentAuthenticatedUser()
          .then()
          .catch(e => {
            console.log(e);
            // removes the tokens set by login and redirects to the login page
            this.amplifyService.logout();
            this.authService.isLoggedIn = false;
            this.removeData();
            this.router.navigate(['login']);
            return false;
          });
        console.log(user);
        if (
          parsedResult.success &&
          this.checkNXTsoftUserGroup(route.data.roles, parsedResult.idToken) &&
          user
        ) {
          return true;
        } else {
          // removes the tokens set by login and redirects to the login page
          this.amplifyService.logout();
          this.authService.isLoggedIn = false;
          this.removeData();
          this.router.navigate(['login']);
          this.dialog.open(ErrorDialogComponent, {
            data: {
              title: 'Login Error',
              message: 'Invalid Login Credentials'
            }
          });
          return false;
        }
      })
      .catch(result => {
        // removes the tokens set by login and redirects to the login page
        this.amplifyService.logout();
        this.authService.isLoggedIn = false;
        this.removeData();
        this.router.navigate(['login']);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            title: 'Login Error',
            message: 'Something went wrong when trying to log you in'
          }
        });
        return false;
      });
  }
  removeData() {
    this.spinnerService.setShowSpinner(false);
    this.ccmcApiService.mapping = undefined;
    this.ccmcApiService.coreSettings = undefined;
    this.ccmcApiService.conditions = undefined;
    this.ccmcApiService.search = undefined;
    this.ccmcApiService.transactions = undefined;
    this.ccmcApiService.configurations = undefined;
    this.ccmcApiService.mapping = undefined;
    this.ccmcApiService.supportValues = undefined;
    // Clears out loan number from sidebar navigation
    navigation[2].children[0].badge!.title = undefined!;
    // Clears out transactions from sidebar navigation
    navigation[2].children[1].children = undefined;
    navigation[0].children = [];
  }

  checkNXTsoftUserGroup(validRoles: any, token: any) {
    console.log(token);
    let decodedIdToken;
    // decode token if necessary
    if (token && token['cognito:username']) {
      decodedIdToken = token;
    } else {
      decodedIdToken = this.jwtHelper.decodeToken(token);
    }
    // If dev only nxtsoft can access any route
    if (!environment.production && !environment.test) {
      if (decodedIdToken['cognito:groups']) {
        const userGroups = decodedIdToken['cognito:groups'];
        if (JSON.stringify(userGroups).includes('nxtsoft')) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
    if (validRoles) {
      if (decodedIdToken['cognito:groups']) {
        const userGroups = decodedIdToken['cognito:groups'];
        for (let validRole of validRoles) {
          // If the user has a valid usergroup we can return true
          if (userGroups.includes(validRole)) {
            return true;
          }
        }
      }
      // If there is no user group the are not a NXTsoft user
      const errorMessage = {
        message: 'Invalid usergroup',
        title: 'Unauthorized'
      };
      const dialogRef2 = this.dialog.open(ErrorDialogComponent, {
        data: errorMessage
      });
      // User not part of valid group
      return false;
    } else {
      // If no validRoles we can assume any user can access
      return true;
    }
  }
}
