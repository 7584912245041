<div class="confirm-dialog">
  <h1 matDialogTitle><mat-icon>error_outline</mat-icon> Premier Error</h1>
  <div mat-dialog-content class="confirm-dialog-message">
    Would you like to proceed with sending the GL file anyway?
  </div>
  <div
    mat-dialog-actions
    class="pt-30"
    class="confirm-dialogBtn-Container"
    style="justify-content: space-between"
  >
    <button
      class="cancelBtn"
      mat-stroked-button
      (click)="dialogRef.close(false)"
    >
      No
    </button>
    <button
      mat-raised-button
      class="mat-accent mr-16"
      (click)="dialogRef.close(true)"
    >
      Yes
    </button>
  </div>
</div>
