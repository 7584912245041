import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FundingLedgerComponent } from './funding-ledger.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { CCMCSharedModule } from 'src/@ccmc/shared.module';

const routes: Routes = [
  {
    path: 'gla/funding-ledger',
    component: FundingLedgerComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [FundingLedgerComponent],
  imports: [RouterModule.forChild(routes), CCMCSharedModule],
  exports: [FundingLedgerComponent]
})
export class FundingLedgerModule {}
