import { Injectable, NgZone } from '@angular/core';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CcmcApiService } from './ccmc-api.service';
import { Router } from '@angular/router';
import { navigation } from '../../app/navigation/navigation';
import { SpinnerService } from './spinner.service';
import { ActiveLosService } from './active-los.service';
import { SuccessDialogComponent } from '../components/success-dialog/success-dialog.component';
import { AssetService } from './asset.service';
import { CaptureDataService } from './capture-data.service';
import { FieldEditedService } from './field-edited.service';
import { Subscription } from 'rxjs';
import { AmplifyService } from './amplify.service';
import { GeneralLedgerAccountingService } from './general-ledger-accounting.service';
import { GlobalSearchService } from './global-search.service';
import { TargetResponseDialogComponent } from '../components/target-response-dialog/target-response-dialog.component';
import { DebitCeditCheckDialogComponent } from '../components/debit-cedit-check-dialog/debit-cedit-check-dialog.component';
import { InvokeCommand, InvokeCommandInput, LambdaClient } from '@aws-sdk/client-lambda';
import { APPCONSTANTS } from 'src/app/app.constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { CustomErrorHandlerService } from './custom-error-handler.service';
const { Buffer } = require('buffer');

/* Constants Section */
const FISERV_DNA_RESOURCE = '/fiserv/dna';
const FISERV_DNA_LAMBDA_NAME = 'fiserv-dna-communicator';
const MONETARY_GL_FIELD = 'MSG_005';
const CREDIT_DEBIT_FIELD = 'MONGL*_004';
const GL_AMOUNT_FIELD = 'MONGL*_007';

@Injectable({
  providedIn: 'root'
})
export class DNAService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  dynamicNav: any = navigation;
  
  changeName: boolean;
  changeNameSub: Subscription;
  resultTitle = 'Fiserv/DNA Result';
  finalParty: any;


  constructor(
    private dialog: MatDialog,
    private ccmcApiService: CcmcApiService,
    public router: Router,
    private spinnerService: SpinnerService,
    private activeLosService: ActiveLosService,
    private zone: NgZone,
    private fieldEditedService: FieldEditedService,
    private assetService: AssetService,
    private amplifyService: AmplifyService,
    private captureDataService: CaptureDataService,
    private glaService: GeneralLedgerAccountingService,
    private globalSearchService: GlobalSearchService,
    private http: HttpClient,
    private customErrorHandlerService: CustomErrorHandlerService
  ) {
  }

  /**
   * Send
   * @description Export Loan
   * @memberof DNAService
   */
  async send() {
    if (!await this.dnaBalanceCheck()) {
      this.spinnerService.setShowSpinner(false);
      return;
    }

    this.getLoanNumber();
    //   turn spinner on
    this.spinnerService.setShowSpinner(true);
    let userName = await this.amplifyService.getUserName();
    const exportObject = {
      content: {
        mapping: this.ccmcApiService.mapping,
        configurations: this.ccmcApiService.configurations,
        transactions: this.ccmcApiService.transactions
      },
      user: userName,
      assetID: this.assetService.getSelectedAssetId()
    };
    (await this.exportMessages(exportObject)).subscribe((result:any) => {
      const exportMessagesResponse = result;
      if (exportMessagesResponse.error) {
        console.log('error response handling');
        const errorMessage = {
          statusMessage: exportMessagesResponse.error.message,
          statusFlag: false
        };
        const dialogRef = this.dialog.open(TargetResponseDialogComponent, {
          data: errorMessage
        });
        dialogRef.afterClosed().subscribe(data => {
          if (exportMessagesResponse.loanNumber && this.getLoanNumber() === '') {
            this.setLoanNumber(exportMessagesResponse.loanNumber);
            const loanDialogRef = this.dialog.open(ErrorDialogComponent, {
              data: {
                title: 'Alert',
                message: 'An account number was automatically generated (' + exportMessagesResponse.loanNumber + ') take note before closing loan!'
              }
            });
          }
        });
      } else if (exportMessagesResponse.statusFlag === false) {
        console.log('status flag false response handling');
        if (exportMessagesResponse.content && exportMessagesResponse.content.mapping) {
          // Retrieve Validation From existing mapping object
          let existingMappingCopy = JSON.parse(
            JSON.stringify(this.ccmcApiService.mapping)
          );
          for (let mappingItem of exportMessagesResponse.content.mapping) {
            let existingMappingIndex = existingMappingCopy.findIndex(
              (obj: any) => obj.fieldID === mappingItem.fieldID
            );
            if (
              existingMappingIndex > -1 &&
              existingMappingCopy[existingMappingIndex].validation
            ) {
              mappingItem.validation =
                existingMappingCopy[existingMappingIndex].validation;
            }
          }
          this.ccmcApiService.mapping = exportMessagesResponse.content.mapping;
        } else if (exportMessagesResponse.payload && exportMessagesResponse.payload.mapping) {
          // Retrieve Validation From existing mapping object
          let existingMappingCopy = JSON.parse(
            JSON.stringify(this.ccmcApiService.mapping)
          );
          for (let mappingItem of exportMessagesResponse.payload.mapping) {
            let existingMappingIndex = existingMappingCopy.findIndex(
              (obj: any) => obj.fieldID === mappingItem.fieldID
            );
            if (
              existingMappingIndex > -1 &&
              existingMappingCopy[existingMappingIndex].validation
            ) {
              mappingItem.validation =
                existingMappingCopy[existingMappingIndex].validation;
            }
          }
          this.ccmcApiService.mapping = exportMessagesResponse.payload.mapping;
        }
        
        this.globalSearchService.initData();
        const errorMessage = {
          statusMessage: exportMessagesResponse.statusMessage,
          statusFlag: false
        };
        const dialogRef = this.dialog.open(TargetResponseDialogComponent, {
          data: errorMessage
        });
        dialogRef.afterClosed().subscribe(data => {
          if (this.getLoanNumber() === '') {
            this.setLoanNumber(exportMessagesResponse.loanNumber);
            const loanDialogRef = this.dialog.open(ErrorDialogComponent, {
              data: {
                title: 'Alert',
                message: 'An account number was automatically generated (' + exportMessagesResponse.loanNumber + ') take note before closing loan!'
              }
            });
          }
        });
  
      } else if (exportMessagesResponse.statusFlag === true) {
        console.log('success response handling');
        let loanNumber;
        if (exportMessagesResponse.loanNumber) {
          loanNumber = exportMessagesResponse.loanNumber;
        } else {
          loanNumber = this.getLoanNumber();
        }
        const resultMessage = {
          statusMessage: exportMessagesResponse.statusMessage,
          loanNumber: loanNumber,
          statusFlag: true
        };
        this.captureDataService.uploadS3File(this.ccmcApiService.mapping);
        this.activeLosService.activeLosService.loanBooked(
          exportMessagesResponse,
          JSON.parse(JSON.stringify(this.ccmcApiService.configurations))
        );
        this.ccmcApiService.mapping = undefined;
        this.ccmcApiService.coreSettings = undefined;
        this.ccmcApiService.conditions = undefined;
        this.ccmcApiService.mapping = undefined;
        this.ccmcApiService.supportValues = undefined;
        this.ccmcApiService.search = undefined;
        this.ccmcApiService.transactions = undefined;
        this.ccmcApiService.configurations = undefined;
        if (this.dynamicNav[1].children[1]) {
          this.dynamicNav[1].children[1].children = [];
        }
        this.dynamicNav[1].children[0].badge.title = undefined;
        const dialogRef = this.dialog.open(TargetResponseDialogComponent, {
          data: resultMessage
        });
        dialogRef.afterClosed().subscribe(result => {
          if (this.activeLosService.activeLos === 'encompass' || this.activeLosService.activeLos === 'bytepro') {
            this.router.navigate(['loan-lookup']);
          } else {
            this.router.navigate(['file-selector']);
          }
        });
      } else {
        console.log('default response handling');
        if (exportMessagesResponse.content) {
          delete exportMessagesResponse.content;
        }
        const loanDialogRef = this.dialog.open(ErrorDialogComponent, {
          data: {
            title: 'Uncaught Error',
            message: JSON.stringify(exportMessagesResponse)
          }
        });
      }
    });
  }

  /**
   * Customer Search
   * @description Performs the customer search
   * @param {*} borrower
   * @param {*} callback
   * @memberof DNAService
   */
  async customerSearch(borrower: any, callback: any) {
    this.spinnerService.setShowSpinner(true);
    let userName = await this.amplifyService.getUserName();
    borrower.cognitoID = this.amplifyService.getCognitoId();
    const borrowerRequest = {
      content: borrower,
      assetID: this.assetService.getSelectedAssetId(),
      user: userName
    };
    let searchCustomerResponse: any;
    (await this.searchCustomer(borrowerRequest)).subscribe((result:any)=> {
      searchCustomerResponse = result;
      if (searchCustomerResponse.statusFlag === true) {
        this.spinnerService.setShowSpinner(false);
        if (searchCustomerResponse.content.length > 0) {
          callback(searchCustomerResponse.content);
        } else {
          const noResult = [
            {
              firstName: 'No Result',
              lastName: '',
              taxID: '',
              CIF: '',
              address: ''
            }
          ];
          callback(noResult);
        }
      } else {
        const errorMessage = {
          message: searchCustomerResponse.statusMessage,
          title: 'Search Error'
        };
        this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      }
    });
  }

  async autoCustomerSearch(borrower: any, callback: any) {
    this.spinnerService.setShowSpinner(true);
    let userName = await this.amplifyService.getUserName();
    borrower.cognitoID = this.amplifyService.getCognitoId();
    const borrowerRequest = {
      content: borrower,
      assetID: this.assetService.getSelectedAssetId(),
      user: userName
    };
    let searchCustomerResponse: any;
    (await this.searchCustomer(borrowerRequest)).subscribe((result:any)=> {
      searchCustomerResponse = result;
      this.spinnerService.setShowSpinner(false);
    if (searchCustomerResponse.error) {
      const errorMessage = {
        message: JSON.stringify(searchCustomerResponse.error.message),
        title: 'DNA Result'
      };

      const dialogRef = this.dialog.open(ErrorDialogComponent, {
        data: errorMessage
      });
    } else if (searchCustomerResponse.statusFlag === true) {
      if (searchCustomerResponse.content.length > 0) {
        callback(searchCustomerResponse.content);
      } else {
        const noResult = [
          {
            firstName: 'No Result',
            lastName: '',
            taxID: '',
            CIF: '',
            address: ''
          }
        ];
        callback(noResult);
      }
    } else if (searchCustomerResponse.statusFlag === false) {
      if (
        searchCustomerResponse.statusMessage
          .toLowerCase()
          .includes('no customer record found')
      ) {
        const noResult = [
          {
            firstName: 'No Result',
            lastName: '',
            taxID: '',
            CIF: '',
            address: ''
          }
        ];
        console.log(noResult);
        callback(noResult);
      } else {
        const errorMessage = {
          message: searchCustomerResponse.statusMessage,
          title: 'Search Error'
        };
        this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
        callback(searchCustomerResponse);
      }
    }
    });
    
  }

  /**
   * Assign Borrowers
   * @description set the field value to the customer name
   * @param {*} party
   * @memberof DNAService
   */
  assignBorrowers(party: any) {
    this.changeNameSub = this.fieldEditedService.copyNameCheck.subscribe(
      name => {
        if (name) {
          this.changeName = name;
        } else {
          this.changeName = false;
        }
      }
    );
    // this.changeName = true;
    console.log(this.changeName);
    for (let i = 0; i < party.length; i++) {
      const CIFFields = party[i].CIFFields.split(',');
      CIFFields.forEach((cf: any) => {
        const cifIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) => f.fieldID === cf.trim()
        );
        this.ccmcApiService.mapping[cifIndex].fieldValue = party[i].CIF;
      });
      if (this.changeName == true) {
        const CIFFirstName = party[i].FNFields.split(',');
        CIFFirstName.forEach((cf: any) => {
          const cifIndexFN = this.ccmcApiService.mapping.findIndex(
            (f: any) => f.fieldID === cf.trim()
          );
          this.ccmcApiService.mapping[cifIndexFN].fieldValue =
            party[i].firstName;
          console.log(cifIndexFN);
          let cifIndexNN = cifIndexFN; // .slice(0, -1); 'code without 6 to denote firstname'
          const cifIndexMIN = cifIndexNN + 1;
          const cifIndexLN = cifIndexNN + 2;
          const cifIndexMN = cifIndexNN + 3;
          this.ccmcApiService.mapping[cifIndexMN].fieldValue =
            party[i].middleName;
          this.ccmcApiService.mapping[cifIndexLN].fieldValue =
            party[i].lastName;
          this.ccmcApiService.mapping[cifIndexMIN].fieldValue =
            party[i].middleName.charAt(0);
        });
      }
    }
  }

  getLoanNumber() {
    let loanFieldIndex = this.ccmcApiService.mapping.findIndex(
      (obj: any) => obj.fieldID === 'LN01_004'
    );
    if (loanFieldIndex > -1) {
      // Return loan number
      console.log(
        'loan number',
        this.ccmcApiService.mapping[loanFieldIndex].fieldValue
      );
      return this.ccmcApiService.mapping[loanFieldIndex].fieldValue;
    }
  }

  setLoanNumber(loanNumber: any) {
    let loanFieldIndex = this.ccmcApiService.mapping.findIndex(
      (obj: any) => obj.fieldID === 'LN01_004'
    );
    if (loanFieldIndex > -1) {
      // Set loan number
      this.ccmcApiService.mapping[loanFieldIndex].fieldValue = loanNumber;
      console.log(
        'set loan number',
        this.ccmcApiService.mapping[loanFieldIndex].fieldValue
      );
    }
  }

  async processGeneralLedgerAccounting(params: any) {
    return this.http
    .post(
      `${Buffer.from(environment.environmentURL, "base64").toString()}/fiserv/dna/process-gla`,
      params,
      this.httpOptions
    )
    .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  async processGLA(glaDocument: any) {
    this.spinnerService.setShowSpinner(true);
    let userName = await this.amplifyService.getUserName();
    let sortedCoreLayout = JSON.parse(JSON.stringify(glaDocument.coreLayout));
    this.glaService.sortCoreLayout(sortedCoreLayout);
    const exportObject = {
      content: {
        set: this.removeBlankAmountsFromSet(glaDocument.set, sortedCoreLayout),
        configurations: glaDocument.configurations,
        coreLayout: sortedCoreLayout
      },
      user: userName,
      assetID: this.assetService.getSelectedAssetId(),
      loanNumber: this.captureDataService.loanNumber
    };
    console.log(JSON.stringify(exportObject));
    let processGLAResponse: any;
    (await this.processGeneralLedgerAccounting(exportObject)).subscribe((result:any)=> {
      processGLAResponse = result;
      if (processGLAResponse.error) {
        const errorMessage = {
          message: processGLAResponse.error.message,
          title: this.resultTitle
        };
        const dialogRef2 = this.zone.run(() => {
          this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
        });
      }
      if (processGLAResponse.statusFlag === true) {
        const resultMessage = {
          message: processGLAResponse.statusMessage,
          title: this.resultTitle
        };
        this.activeLosService.activeLosService.loanBooked(
          processGLAResponse,
          JSON.parse(JSON.stringify(glaDocument.configurations))
        );
        this.glaService.setGLAObject({});
        const dialogRef = this.dialog.open(SuccessDialogComponent, {
          data: resultMessage
        });
        dialogRef.afterClosed().subscribe(result => {
          if (this.activeLosService.activeLos === 'encompass' || this.activeLosService.activeLos === 'bytepro') {
            this.router.navigate(['loan-lookup']);
          } else {
            this.router.navigate(['file-selector']);
          }
        });
      }
      if (processGLAResponse.statusFlag === false) {
        if (processGLAResponse.content && processGLAResponse.content.mapping) {
          this.ccmcApiService.mapping = processGLAResponse.content.mapping;
        }
        if (processGLAResponse.content && processGLAResponse.content.transactions) {
          this.ccmcApiService.transactions = processGLAResponse.content.transactions;
        }
        const errorMessage = {
          message: processGLAResponse.statusMessage,
          title: this.resultTitle
        };
  
        const dialogRef = this.zone.run(() => {
          this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
        });
      }
    });
    
  }

  removeBlankAmountsFromSet(set: any, targetLayout: any) {
    // Initial temp set
    let tempSet = JSON.parse(JSON.stringify(set));
    // Find Amount Index in targetlayout
    let amountIndex = targetLayout.findIndex(
      (targetItem: any) => targetItem.columnName === 'Amount'
    );
    // Set columnID
    let amountColumnID = targetLayout[amountIndex].columnID;
    // Initial cleaned transactions
    let cleanedTransactions = [];

    for (let transaction of tempSet.transactions) {
      let hasAmount = false;
      for (let col of transaction.columns) {
        // If columnId is amount and value is longer than 0 we keep
        if (col.columnID === amountColumnID && col.fieldValue.length > 0) {
          hasAmount = true;
        }
      }
      // If has amount push to new array
      if (hasAmount) {
        cleanedTransactions.push(transaction);
      }
    }
    // Reassign transactions
    tempSet.transactions = cleanedTransactions;
    // Return new set
    return tempSet;
  }

  async dnaBalanceCheck() {
    return new Promise((resolve) => {
      const zeroPad = (num: any, places: any) => String(num).padStart(places, '0')
      // Initialize counters to zero
      let creditCounter = 0, debitCounter = 0, totalCounter = 0;

      // Find index of a particular element in an array
      const msgIndex = this.ccmcApiService.mapping.findIndex(
        (msg: any) => msg.fieldID === MONETARY_GL_FIELD
      );
      // If element not found, exit function and return true
      if (msgIndex === -1) {
        return resolve(true);
      }
      // Check value of a field and set boolean variable accordingly
      const sendMonetaryGL = this.ccmcApiService.mapping[msgIndex].fieldValue === 'Y' ? true : false;

      // If value of previous field was Y, do some calculations
      if (sendMonetaryGL) {
        // Initialize variables to keep track of indexes and transaction information
        let glInstance = 1;
        let paddedGLInstance = zeroPad(glInstance, 2);
        let creditDebitInstance = CREDIT_DEBIT_FIELD.replace('*', `${paddedGLInstance}`);
        let creditDebitInstanceIndex = this.ccmcApiService.mapping.findIndex(
          (msg: any) => msg.fieldID === creditDebitInstance
        );
        let glAmountInstance = GL_AMOUNT_FIELD.replace('*', `${paddedGLInstance}`);
        let glAmountInstanceIndex = this.ccmcApiService.mapping.findIndex(
          (msg: any) => msg.fieldID === glAmountInstance
        );
        let currentGLTransactionID = this.ccmcApiService.mapping[creditDebitInstanceIndex].transactionID;
        let transactionIndex = this.ccmcApiService.transactions.findIndex((transaction: any) => transaction.transactionID === currentGLTransactionID)
        let currentTransaction = this.ccmcApiService.transactions[transactionIndex];

        // Loop until there are no more transactions or fields to process
        while (creditDebitInstanceIndex > -1 && glAmountInstanceIndex > -1) {
          // Get values of the credit/debit field and the amount field
          const creditOrDebitField = this.ccmcApiService.mapping[creditDebitInstanceIndex].fieldValue.toLowerCase();
          const amountField = this.ccmcApiService.mapping[glAmountInstanceIndex].fieldValue;
          const amountValue = +amountField;
          // Add to the appropriate counter based on credit/debit value
          if (creditOrDebitField.charAt(0) === 'd' && currentTransaction.display) {
            debitCounter += Math.abs(amountValue)
          } else if (creditOrDebitField.charAt(0) === 'c' && currentTransaction.display) {
            creditCounter += Math.abs(amountValue)
          }
          // Increment instance and update indexes for next loop iteration
          glInstance++;
          paddedGLInstance = zeroPad(glInstance, 2);
          creditDebitInstance = CREDIT_DEBIT_FIELD.replace('*', `${paddedGLInstance}`);
          creditDebitInstanceIndex = this.ccmcApiService.mapping.findIndex(
            (msg: any) => msg.fieldID === creditDebitInstance
          );
          if (creditDebitInstanceIndex > -1) {
            glAmountInstance = GL_AMOUNT_FIELD.replace('*', `${paddedGLInstance}`);
            glAmountInstanceIndex = this.ccmcApiService.mapping.findIndex(
              (msg: any) => msg.fieldID === glAmountInstance
            );
            currentGLTransactionID = this.ccmcApiService.mapping[creditDebitInstanceIndex].transactionID;
          }
          if (transactionIndex > -1) {
            transactionIndex = this.ccmcApiService.transactions.findIndex((transaction: any) => transaction.transactionID === currentGLTransactionID)
            currentTransaction = this.ccmcApiService.transactions[transactionIndex];
          }
        }

        // If credits and debits don't balance, open a dialog box for confirmation
        if (this.ccmcApiService.configurations.showGLBalancePrompt) {
          totalCounter = creditCounter - debitCounter;
          const message =
            'Are you sure you want to continue with the following Debit and Credit?';
          const dialogRef2 = this.dialog.open(DebitCeditCheckDialogComponent, {
            data: {
              message: message,
              total: totalCounter,
              credit: creditCounter,
              debit: debitCounter
            },
            panelClass: 'debit-credit__dialog'
          });

          // If user confirms, return true. Otherwise return false.
          dialogRef2.afterClosed().subscribe(data => {
            if (data === true) {
              return resolve(true);
            } else {
              return resolve(false)
            }
          });
        }
        // If credits and debits balance or there are no transactions to process, exit function and return true
        else {
          return resolve(true);
        }
      }
      // If value of previous field was not Y, exit function and return true
      else {
        return resolve(true);
      }
    })
  }

  async searchCustomer(params: any) {
    return this.http
    .post(
      `${Buffer.from(environment.environmentURL, "base64").toString()}/fiserv/dna/search-customer`,
      params,
      this.httpOptions
    )
    .pipe(catchError(this.customErrorHandlerService.handleError));
  }


  async exportMessages(params: any) {
    return this.http
    .post(
      `${Buffer.from(environment.environmentURL, "base64").toString()}/fiserv/dna/export-messages`,
      params,
      this.httpOptions
    )
    .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  retrieveInvokeEnvironment(url: string) {
    if (url.includes('dev')) {
      return 'dev';
    } else if (url.includes('test')) {
      return 'test';
    } else {
      return 'prod';
    }
  }
}
