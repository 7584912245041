import {
  Component,
  OnInit,
  OnDestroy,
  NgZone,
  AfterViewInit,
  ChangeDetectorRef,
  LOCALE_ID,
  Inject
} from '@angular/core';
import {
  NavigationEnd,
  NavigationStart,
  Router
} from '@angular/router';

import {
  CCMCConfigService
} from '../../../@ccmc/services/config.service';
import {
  CCMCSidebarService
} from '../../../@ccmc/components/sidebar/sidebar.service';

import {
  adminNavigation,
  glaNavigation,
  navigation
} from '../../../app/navigation/navigation';
import {
  Navigation
} from '../../../@ccmc/models/navigation.model';
import {
  Languages
} from '../../../@ccmc/models/languages.model';
import {
  UserStatus
} from '../../../@ccmc/models/userStatus.model';
import {
  Subscription,
  Subject
} from 'rxjs';
import {
  MatDialog
} from '@angular/material/dialog';
import {
  CCMCSearchBorrowerDialogComponent
} from '../../../@ccmc/components/search-borrower-dialog/search-borrower-dialog.component';
import {
  CCMCFieldHelpDialogComponent
} from '../../../@ccmc/components/field-help-dialog/field-help-dialog.component';
import {
  CCMCSelectedFieldService
} from '../../../@ccmc/services/selected-field.service';
import {
  CifSearchService
} from '../../../@ccmc/services/cif-search.service';
import {
  AuthService
} from '../../auth/auth.service';
import {
  CCMCCollateralSearchDialogComponent
} from '../../../@ccmc/components/collateral-search-dialog/collateral-search-dialog.component';
import {
  CcmcApiService
} from '../../../@ccmc/services/ccmc-api.service';
import {
  SpinnerService
} from '../../../@ccmc/services/spinner.service';
import {
  ActiveCoreService
} from '../../../@ccmc/services/active-core.service';
import {
  ActiveLosService
} from '../../../@ccmc/services/active-los.service';
import {
  ShortcutInput
} from 'ng-keyboard-shortcuts';
import {
  filter,
  findIndex,
  takeUntil
} from 'rxjs/operators';
import {
  AssetService
} from 'src/@ccmc/services/asset.service';
import {
  AvatarService
} from 'src/@ccmc/services/avatar.service';
import {
  CCMCNavigationService
} from 'src/@ccmc/components/navigation/navigation.service';
import {
  CCMCConfirmDialogComponent
} from 'src/@ccmc/components/confirm-dialog/confirm-dialog.component';
import {
  AdminApiService
} from 'src/@ccmc/services/admin-api.service';
import {
  FieldEditedService
} from 'src/@ccmc/services/field-edited.service';
import {
  MortgageBotService
} from 'src/@ccmc/services/mortgagebot.service';
import {
  ErrorDialogComponent
} from 'src/@ccmc/components/error-dialog/error-dialog.component';
import {
  SuccessDialogComponent
} from 'src/@ccmc/components/success-dialog/success-dialog.component';
import {
  WriteLoggingService
} from 'src/@ccmc/services/write-logging.service';
import {
  SnackbarService
} from 'src/@ccmc/services/snackbar.service';
import {
  SuffixSearchDialogComponent
} from 'src/@ccmc/components/suffix-search-dialog/suffix-search-dialog.component';
import {
  GeneralLedgerAccountingService
} from 'src/@ccmc/services/general-ledger-accounting.service';
import {
  UpdatePremierCredentialsComponent
} from 'src/@ccmc/components/update-premier-credentials/update-premier-credentials.component';
import {
  MatSliderModule
} from '@angular/material/slider';
import {
  MatSlideToggleModule
} from '@angular/material/slide-toggle';
import {
  IbsCredentialComponent
} from 'src/@ccmc/components/ibs-credential/ibs-credential.component';
import {
  AmplifyService
} from 'src/@ccmc/services/amplify.service';
import {
  DocumentConnectorService
} from 'src/@ccmc/services/doc-connector.service';
import {
  DebitCeditCheckDialogComponent
} from 'src/@ccmc/components/debit-cedit-check-dialog/debit-cedit-check-dialog.component';
import {
  SelectPremierCredentialsComponent
} from 'src/@ccmc/components/select-premier-credentials/select-premier-credentials.component';
import {
  FileService
} from 'src/@ccmc/services/file.service';
import {
  UpdateMiserCredentialsComponent
} from 'src/@ccmc/components/update-miser-credentials/update-miser-credentials.component';
import {
  ImportService
} from 'src/@ccmc/services/import.service';
import {
  CopyTextDialogComponent
} from 'src/@ccmc/components/copy-text-dialog/copy-text-dialog.component';
import {
  RequestChangeService
} from 'src/@ccmc/services/request-change.service';
import {
  formatDate
} from '@angular/common';
import {
  v4 as uuidv4
} from 'uuid';
import {
  SendDocConnectorDialogComponent
} from 'src/@ccmc/components/send-doc-connector-dialog/send-doc-connector-dialog.component';
import {
  MatSnackBar
} from '@angular/material/snack-bar';
import {
  DialogHandlerService
} from 'src/@ccmc/services/dialogHandler.service';
import { ReadLoggingService } from '../content/logging/read-logging.service';
import { DNAService } from 'src/@ccmc/services/dna.service';
import { UpdateSpectrumCredentialsComponent } from 'src/@ccmc/components/update-spectrum-credentials/update-spectrum-credentials.component';
import { SelectSpectrumCredentialsComponent } from 'src/@ccmc/components/select-spectrum-credentials/select-spectrum-credentials.component';
import { SpectrumService } from 'src/@ccmc/services/spectrum.service';


/*
  DEV NOTES TODO:
  // Implement work flow for clients to designate if they want to restrict which user group can submit loans
*/
@Component({
  selector: 'ccmc-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class CCMCToolbarComponent implements OnInit, OnDestroy, AfterViewInit {
  showLoadingBar: boolean;
  dynamicAdminNav: any = adminNavigation;
  horizontalNav: boolean;
  noNav: boolean;
  navigation: Navigation[];
  name: string;
  userSubscription: Subscription;
  selectedField: any;
  rowSubscription: Subscription;
  sendDisabled = true;
  cifSetSubscription: Subscription;
  hasIssuesSubscription: Subscription;
  avatarSubscription: Subscription;
  navigationTypeSub: Subscription;
  importCheck: Subscription;
  fieldEditedSubscription: Subscription;
  glaDocumentSub: Subscription;
  glaSelectedSetSub: Subscription;
  assetDocumentSub: Subscription;
  glaExportDocumentSub: Subscription;
  selectedLoansToSendSub: Subscription;
  dcDocument: any;
  dcDocumentSub: any;
  currentNavigationType: any;
  currentNavigationTypeLog: any;
  hasIssues = true;
  isCifSet = false;
  hasMultipleProducts = false;
  shortcuts: ShortcutInput[] = [];
  avatar: any;
  coreEditFlag = false;
  losEditFlag = false;
  glaEditFlag = false;
  coreEditSub: Subscription;
  losEditSub: Subscription;
  glaEditSub: Subscription;
  unsubscribe: Subject < any > = new Subject();
  backupDialogRef: any;
  editedFlag = false;
  showNavigationSwitchButtons = false;
  isCalyx = false;
  isMortgageBot = false;
  los: any;
  showSpinner: boolean;
  private spinnerSub: Subscription;
  showSearchButton = true;
  changeRequestFieldsSub: Subscription;
  noSearchCores = [
    'cenlar',
    'dovenmuehle',
    'cleartouch',
    'mortgageservicer',
    'defiservicing',
    'symitar',
    'synergent-symitar',
    'ultradata',
    'loanserv'
  ];
  noSearchLos = ['kasasa'];
  glaDocument: any;
  exportDocument: any;
  selectedSetDocument: any;
  impModechecked: any;
  isImpMode: boolean;
  checkedDocConnectorLoans: any = [];
  localPremierUserCredentialItem: any;
  checkImportArray: any;
  docFields: any;
  changeRequestExportSub: Subscription;
  reviewListSub: Subscription;
  exportChangeRequestDocument: any;
  currentDate: any;
  selectedCore: any;
  loggingGroups = [
    { value: 'asset', viewValue: 'Asset' },
    { value: 'core', viewValue: 'Core' },
    { value: 'user', viewValue: 'User' }
  ];
  selectedLoggingGroup: any;
  localSpectrumUserCredentialItem: any;
  isGLABalanced: boolean = false;
  getLoansButtonFlag: boolean = false;
  userIcon: any;
  coreDocument: any;
  dynamicNav: any = navigation;
  dynamicGLANav: any = glaNavigation;
  dcSendButtonFlag: any = false;
  showAdmin: any = false;
  private usersSub: Subscription;
  isUserNXTsoft: boolean;
  users: any;
  /**
   * Creates an instance of CCMCToolbarComponent.
   * @param {Router} router
   * @param {CCMCConfigService} ccmcConfig
   * @param {CCMCSidebarService} sidebarService
   * @param {NgZone} zone
   * @param {MatDialog} dialog
   * @param {CCMCSelectedFieldService} selectedFieldService
   * @param {CifSearchService} cifSearchService
   * @param {AuthService} authService
   * @param {CcmcApiService} ccmcApiService
   * @param {ActiveCoreService} activeCoreService
   * @param {ActiveLosService} activeLosService
   * @param {ChangeDetectorRef} cdRef
   * @param {AssetService} assetService
   * @param {AvatarService} avatarService
   * @param {CCMCNavigationService} navigationService
   * @param {AdminApiService} adminApiService
   * @param {FieldEditedService} fieldEditedService
   * @param {SpinnerService} spinnerService
   * @param {MortgageBotService} mortgageBotService
   * @param {WriteLoggingService} writeLoggingService
   * @param {SnackbarService} snackBarService
   * @memberof CCMCToolbarComponent
   */
  constructor(
    public router: Router,
    private ccmcConfig: CCMCConfigService,
    private sidebarService: CCMCSidebarService,
    private zone: NgZone,
    public dialog: MatDialog,
    private selectedFieldService: CCMCSelectedFieldService,
    private cifSearchService: CifSearchService,
    private authService: AuthService,
    private ccmcApiService: CcmcApiService,
    private activeCoreService: ActiveCoreService,
    private activeLosService: ActiveLosService,
    private cdRef: ChangeDetectorRef,
    private importService: ImportService,
    private assetService: AssetService,
    private avatarService: AvatarService,
    private navigationService: CCMCNavigationService,
    private adminApiService: AdminApiService,
    private fieldEditedService: FieldEditedService,
    private spinnerService: SpinnerService,
    private mortgageBotService: MortgageBotService,
    private writeLoggingService: WriteLoggingService,
    private snackBarService: SnackbarService,
    private glaService: GeneralLedgerAccountingService,
    private amplifyService: AmplifyService,
    private dcService: DocumentConnectorService,
    private fileService: FileService,
    private requestChangeService: RequestChangeService,
    private snackBar: MatSnackBar,
    private readLoggingService: ReadLoggingService,
    @Inject(LOCALE_ID) private locale: string,
    private dialogHandlerService: DialogHandlerService,
    private _cd: ChangeDetectorRef,
    private dnaService: DNAService,
    private documentConnectorService: DocumentConnectorService,
    private spectrumService: SpectrumService
  ) {

    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.showLoadingBar = true;
      }
      if (event instanceof NavigationEnd) {
        this.showLoadingBar = false;
      }
    });

    this.readLoggingService.selectedLoggingGroup.subscribe(loggingGroup => {
      this.selectedLoggingGroup = loggingGroup;
      console.log('Selected Logging Group ', this.selectedLoggingGroup);
    });

    // this.ccmcConfig.onConfigChanged
    //   .pipe(takeUntil(this.unsubscribe))
    //   .subscribe(settings => {
    //     this.horizontalNav = settings.layout.navigation === 'top';
    //     this.noNav = settings.layout.navigation === 'none';
    //   });

    this.documentConnectorService.sendButtonFlag.subscribe((flag: any) => {
      this.dcSendButtonFlag = flag;
    });

    this.navigation = navigation;
  }

  getSpinnerStatus() {
    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        this.showSpinner = spinner;
        if (!this.showSpinner) {
          this.isSendDisabled();
        }
      });
    this.currentDate = formatDate(Date.now(), 'MM-dd-yyyy', this.locale);
  }

  /**
   * On Init
   *
   * @memberof CCMCToolbarComponent
   */
  ngOnInit() {

    // Clear selectedField Data when route changes
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((_event) => {
      this.selectedField = {};
    });

    // get los
    this.los = this.activeLosService.getActiveLos();
    this.getSpinnerStatus();

    // set core edited to false
    this.fieldEditedService.coreEdited.next(false);
    // set row subscription
    this.rowSubscription = this.cifSearchService.selectedRow$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(row => {}); // subscribe to the selected row
    this.selectedFieldService.onFieldSelected
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(selected => {
        this.selectedField = selected;
      });
    // get avatar
    this.getAvatar();
    // set user
    this.setUser();
    // set if user should see admin option in toolbar
    this.showAdmin = this.checkAdminPrivilege();
    // subscribe to isseus
    this.checkCurrentMode();
    this.hasIssuesSubscription = this.ccmcApiService.hasIssues$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(issuesExist => {
        this.hasIssues = issuesExist;
        this.isSendDisabled();
      });
    // subscribe to cif set
    this.cifSetSubscription = this.cifSearchService.cifSet$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.isCifSet = res;
        this.isSendDisabled();
      });
    // subscribe to field edited subscription
    this.fieldEditedSubscription = this.fieldEditedService.fieldEdited
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(edited => {
        this.editedFlag = edited;
        this.cdRef.detectChanges();
      });
    // subscribe to navigation type subscription
    this.navigationTypeSub = this.navigationService.onNavChange
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(result => {
        if (result) {
          this.currentNavigationType = result;
        }
        console.log(this.currentNavigationType);
      });

    this.importCheck = this.importService.checkUserArray
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(result => {
        console.log(result);
        if (result === undefined || result.length === 0) {
          this.checkImportArray = false;
          console.log(this.checkImportArray);
        } else if (result) {
          this.checkImportArray = true;
        }
        console.log(this.checkImportArray);
      });


    this.glaExportDocumentSub = this.glaService.exportDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(exportDocument => {
        if (exportDocument) {
          this.exportDocument = exportDocument;
        }
      });

    this.glaService.isGLABalanced
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(isGLABalanced => {
        console.log("Is GLA Balanced", this.isGLABalanced);
        this.isGLABalanced = isGLABalanced;
      });

    this.glaSelectedSetSub = this.glaService.selectedSetDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(selectedSetDocument => {
        if (selectedSetDocument) {
          this.selectedSetDocument = selectedSetDocument;
        }
      });

    this.glaDocumentSub = this.glaService.glaDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(glaDocument => {
        if (glaDocument) {
          this.glaDocument = glaDocument;
        }
      });

    this.dcDocumentSub = this.dcService.dcDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dcDocument => {
        if (dcDocument) {
          this.dcDocument = dcDocument;
        }
      });

    this.changeRequestExportSub =
      this.requestChangeService.changeRequestExportDoc
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(doc => {
        // console.log(doc);
        this.exportChangeRequestDocument = doc;
      });

    // check if multiple assets
    if (this.assetService.getAssetIds()) {
      this.hasMultipleProducts = this.assetService.getAssetIds().includes(',');
    }
    // Subscribe to core edited
    this.coreEditSub = this.fieldEditedService.coreEdited
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(edited => {
        // console.log(edited);
        this.coreEditFlag = edited;
      });

    // Subscribe to core edited
    this.losEditSub = this.fieldEditedService.losEdited
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(edited => {
        // console.log(edited);
        this.losEditFlag = edited;
      });

    this.glaEditSub = this.fieldEditedService.onGLADocumentEdited
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(edited => {
        // console.log(edited);
        this.glaEditFlag = edited;
      });
    this.reviewListSub = this.ccmcApiService.exportDocument
    .pipe(takeUntil(this.unsubscribe))
      .subscribe(exportDocument => {
        if (exportDocument) {
          console.log(exportDocument);
          this.exportDocument = exportDocument;
        }
      });
    // check if mortage bot
    this.checkMortageBot();
    // Checks if search button is shown
    this.isSearchShown();

    // console.log('testing soon');
    if (this.impModechecked == true) {
      // console.log('testing');
      this.checkedImpMode();
    }

    this.ccmcConfig.onConfigChanged
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(settings => {
        this.horizontalNav = settings.layout.navigation === 'top';
        this.noNav = settings.layout.navigation === 'none';
      });

    if (this.isDocConnectorAsset()) {
      // Subscribe to the dcDocument
      this.selectedLoansToSendSub =
        this.selectedFieldService.checkedHistoryLoans
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(historyLoansToSend => {
          if (historyLoansToSend) {
            this.checkedDocConnectorLoans = JSON.parse(
              JSON.stringify(historyLoansToSend)
            );
          }
        });
    }

    if (this.activeCoreService.activeCore === 'ibs') {
      this.adminApiService.documentCore
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(documentCore => {
          if (documentCore) {
            this.coreDocument = documentCore;
          }
        });
    }

    this.localPremierUserCredentialItem = {
      cognitoID: this.amplifyService.getCognitoId(),
      cStation: '',
      password: '',
      username: ''
    };

    this.localSpectrumUserCredentialItem = {
      cognitoID: this.amplifyService.getCognitoId(),
      tellerID: ''
    };

    this.determineUserIcon();
  }

  checkCurrentMode() {
    this.assetDocumentSub = this.fieldEditedService.impModechecked
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(check => {
        console.log(check);
        this.impModechecked = check; {
          // console.log('testing');
          this.checkedImpMode();
        }
      });
  }

  /**
   * After View Init
   *
   * @memberof CCMCToolbarComponent
   */
  ngAfterViewInit() {
    this.shortcuts.push({
      key: ['cmd + \\'],
      label: 'Download document',
      description: 'Download document',
      command: e => {
        this.downloadDatabaseFile();
      },
      preventDefault: true
    });
    this.cdRef.detectChanges();
  }

  /**
   * Is Send Disabled
   * @description Check if cif is set and there are no issues
   * @memberof CCMCToolbarComponent
   */
  isSendDisabled() {
    if (!this.isGLA()) {
      this.sendDisabled = this.isCifSet && !this.hasIssues ? false : true;
      if (
        this.noSearchCores.includes(this.activeCoreService.activeCore) ||
        this.noSearchLos.includes(this.activeLosService.activeLos) ||
        this.runCustomerSearch()
      ) {
        this.sendDisabled = !this.hasIssues ? false : true;
      }
    }
    
    let core = this.activeCoreService.activeCore
    if ((core.toLowerCase() === 'portico' || core.toLowerCase() === 'spectrum') && this.ccmcApiService.mapping) {
      const memberNumberMappingIndex = this.ccmcApiService.mapping.findIndex((mappingItem: any) => mappingItem.fieldID === 'BL01_001');
      if (memberNumberMappingIndex > -1) {
        if (this.ccmcApiService.mapping[memberNumberMappingIndex].fieldValue.length >= 6) {
          this.sendDisabled = false;
        }
      }
    }
  }

  isSearchShown() {
    // Hide search on cenlar
    if (
      this.noSearchCores.includes(this.activeCoreService.activeCore) ||
      this.noSearchLos.includes(this.activeLosService.activeLos)
    ) {
      this.showSearchButton = false;
    } else {
      this.showSearchButton = true;
    }
  }

  runCustomerSearch() {
    if (this.ccmcApiService && this.ccmcApiService.configurations && this.ccmcApiService.configurations.runCustomerSearch !== undefined) {
      /*
        // This code is a boolean expression that evaluates whether the property `runCustomerSearch` 
        // of an object located in a variable called `ccmcApiService` is truthy or falsy. 

        // The following line checks if `this.ccmcApiService.configurations.runCustomerSearch` exists and 
        // can be evaluated as `true`. It uses the optional chaining operator to safely access nested properties.
        !(Boolean(this.ccmcApiService?.configurations?.runCustomerSearch))

        // Here's what each part of the expression does:
        // - `this.ccmcApiService` is a variable that contains an object
        // - `?.`  Optional chaining operator that allows safely accessing the nested properties
        // - `configurations` is a property of `ccmcApiService`
        // - `runCustomerSearch` is a property of the `configurations` object

        // The `Boolean()` function coerces any value into a boolean by checking if the value is truthy or falsy.
        // The resulting expression will be either `true` or `false`, depending on the existence and value of
        // `this.ccmcApiService.configurations.runCustomerSearch`.

        // The outer negation operator `!` inverts the result of the previous step.
        // So the final expression returns `true` if `this.ccmcApiService.configurations.runCustomerSearch` is falsy,
        // and `false` if it's truthy. 
    */
      return !(Boolean(this.ccmcApiService ?. configurations ?. runCustomerSearch))
    } else {
      return false;
    }
  }

  checkCIFFieldsSet() {
    if (this.ccmcApiService.mapping) {
      // Get CIF Fields
      for (let customerSearchItem of this.ccmcApiService.search.customerSearch) {
        const cifFields = customerSearchItem.CIF.split(',');
        for (let cifField of cifFields) {
          const cifMappingIndex = this.ccmcApiService.mapping.findIndex((mappingItem: any) => mappingItem.fieldID === cifField);
          if (cifMappingIndex > -1) {
            console.log(this.ccmcApiService.mapping[cifMappingIndex]);
            const currentMapping = this.ccmcApiService.mapping[cifMappingIndex];
            const transactionIDIndex = this.ccmcApiService.transactions.findIndex((transaction: any) => transaction.transactionID === currentMapping.transactionID);
            if (transactionIDIndex > -1) {
              const currentTransaction = this.ccmcApiService.transactions[transactionIDIndex];
              if (currentTransaction.display) {
                if (currentMapping.fieldValue.length === 0) {
                  this.isCifSet = false;
                  return;
                } else {
                  this.isCifSet = true;
                }
              } else {
                return;
              }
            }
          } else {
            return;
          }
        }
      }
    }

  }

  /**
   * Set User
   * @description retrieves the user's attributes from cognito
   * @memberof CCMCToolbarComponent
   */

  async setUser() {
    this.name = await this.amplifyService.getUserName();
  }

  /**
   * Toggle Sidebar Open
   * @description opens and closes the sidebar
   * @param {*} key
   * @memberof CCMCToolbarComponent
   */
  toggleSidebarOpened(key: any) {
    this.sidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Send
   * @description Export loan
   * @memberof CCMCToolbarComponent
   */
  send() {
    this.spinnerService.setShowSpinner(true);
    this.navigationService.transactionHistoryToggle.next(true);
    this.sendDisabled = true;
    this.activeCoreService.activeService.send();
  }

  override() {
    if (this.sendDisabled) {
      this.sendDisabled = false;
    } else {
      this.sendDisabled = true;
    }
  }
  processGLACheck() {
    console.log("Is GLA Balanced Set to", this.isGLABalanced);
    if (this.isGLABalanced) {
      return this.processGLA();
    }
    this.balanceCheck();
  }

  balanceCheck() {
    const message =
      'Are you sure you want to save with Debit and Credit not balanced?';
    const dialogRef2 = this.dialog.open(CCMCConfirmDialogComponent, {
      data: message
    });
    dialogRef2.afterClosed().subscribe(data => {
      if (data === true) {
        this.processGLA();
      }
    });
  }
  processGLA() {
    this.sendDisabled = true;
    this.activeCoreService.activeService.processGLA(this.selectedSetDocument);
  }

  /**
   * Search
   * @description opens borrower search dialog
   * @memberof CCMCToolbarComponent
   */
  search() {
    if (this.activeCoreService.activeCore === 'premier') {
      this.adminApiService
        .getDynamoDBItem(
          'fiserv_premier_users',
          this.amplifyService.getCognitoId(),
          'cognitoID'
        )
        .subscribe(async result => {
          console.log(result);
          if (result) {
            const parsedResult = JSON.parse(JSON.stringify(result));
            console.log('Username exists', parsedResult.username);
            console.log(parsedResult);
            if (
              parsedResult.username &&
              parsedResult.password &&
              parsedResult.cStation
            ) {
              if (await this.amplifyService.checkNXTsoftOperationsUserGroup()) {
                this.openSelectPremierCredentialsDialog();
              } else {
                this.localPremierUserCredentialItem = JSON.parse(
                  JSON.stringify(result)
                );
                const assetID = this.assetService.getSelectedAssetId();
                const assetArray = [];
                const variableKeys = Object.keys(parsedResult);
                if (variableKeys.length === 4) {
                  assetArray.push(assetID);
                  this.localPremierUserCredentialItem.assetID = assetID;
                } else if (parsedResult.assetID.includes(assetID)) {
                  console.log('assetID already exists in array');
                } else {
                  this.localPremierUserCredentialItem.assetID.push(assetID);
                }
                this.ccmcApiService
                  .createDynamoDB(
                    'test',
                    'fiserv_premier_users',
                    this.localPremierUserCredentialItem
                  )
                  .subscribe(result => {
                    if (result) {
                      console.log(result);
                    }
                  });
                this.openSearchBorrowerDialog();
              }
            } else {
              if (await this.amplifyService.checkNXTsoftOperationsUserGroup()) {
                this.openSelectPremierCredentialsDialog();
              } else {
                this.openPremierCredentialsDialog();
              }
            }
          } else {
            if (await this.amplifyService.checkNXTsoftOperationsUserGroup()) {
              this.openSelectPremierCredentialsDialog();
            } else {
              this.openPremierCredentialsDialog();
            }
          }
        });
    } else if (this.activeCoreService.activeCore === 'spectrum') {
      this.adminApiService
      .getDynamoDBItem(
        'fiserv_spectrum_users',
        this.amplifyService.getCognitoId(),
        'cognitoID'
      )
      .subscribe(async result => {
        console.log(JSON.parse(JSON.stringify(result)));
        if (result) {
          // Accounts for tellerIDs that were created before assetID was associated with them
          let temp: any = result;
          if (!temp.assetID) {
            let assetID: any[] = [];
            assetID.push(this.assetService.getSelectedAssetId());
            temp.assetID = assetID;
          }
          if (!temp.username) {
            temp.username = this.amplifyService.username;
          }
          const parsedResult = JSON.parse(JSON.stringify(temp));
          console.log(parsedResult);
          if (parsedResult.tellerID) {
            if (this.amplifyService.isNXTsoftOperations) {
              this.openSelectSpectrumCredentialsDialog();
            } else {
              this.localSpectrumUserCredentialItem = JSON.parse(
                JSON.stringify(result)
              );
              const assetID = this.assetService.getSelectedAssetId();
              const username = this.amplifyService.username;
              const variableKeys = Object.keys(parsedResult);
              let skip: any = false;
              if (variableKeys.length === 4) {
                this.localSpectrumUserCredentialItem = this.spectrumService.handleAssetIDs(this.localSpectrumUserCredentialItem);
              } else if (parsedResult.assetID.includes(assetID)) {
                console.log('assetID already exists in array');
              } else {
                skip = true;
                this.openSpectrumCredentialsDialog();
              }
              if (skip === false) {
                this.localSpectrumUserCredentialItem.username = username;
                this.ccmcApiService
                  .createDynamoDB(
                    'test',
                    'fiserv_spectrum_users',
                    this.localSpectrumUserCredentialItem
                  )
                  .subscribe(result => {
                    if (result) {
                      console.log(result);
                    }
                  });
                this.openSearchBorrowerDialog();
              }
            }
          } else {
            if (await this.amplifyService.checkNXTsoftOperationsUserGroup()) {
              this.openSelectSpectrumCredentialsDialog();
            } else {
              this.openSpectrumCredentialsDialog();
            }
          }
        } else {
          if (await this.amplifyService.checkNXTsoftOperationsUserGroup()) {
            this.openSelectSpectrumCredentialsDialog();
          } else {
            this.openSpectrumCredentialsDialog();
          }
        }
      });
    } else if (this.activeCoreService.activeCore === 'miser') {
      this.adminApiService
        .getDynamoDBItem(
          'fis_miser_users',
          this.amplifyService.getCognitoId(),
          'cognitoID'
        )
        .subscribe(result => {
          console.log(result);
          if (result) {
            const parsedResult = JSON.parse(JSON.stringify(result));
            // To Do---------- check for required fields ------------------------------
            if (
              parsedResult.stationName &&
              parsedResult.tellerNumber &&
              parsedResult.pin &&
              parsedResult.cashBoxNumber
            ) {
              this.ccmcApiService.configurations.saveCredentials = true;
              this.openSearchBorrowerDialog();
            } else {
              this.openMiserCredentialsDialog();
            }
          } else {
            this.openMiserCredentialsDialog();
          }
        });
    } else if (this.activeCoreService.activeCore === 'ibs') {
      if (!(this.coreDocument.configurations.username && this.coreDocument.configurations.password)) {
        console.log('no target credentials found');
        this.adminApiService
          .getDynamoDBItem(
            'fis_ibs_users',
            this.amplifyService.getCognitoId(),
            'cognitoID'
          )
          .subscribe(result => {
            console.log(result);
            if (result) {
              const parsedResult = JSON.parse(JSON.stringify(result));
              if (parsedResult.username && parsedResult.password) {
                this.openSearchBorrowerDialog();
              } else {
                this.openIbsCredentialsDialog();
              }
            } else {
              this.openIbsCredentialsDialog();
            }
          });
      } else {
        this.openSearchBorrowerDialog();
      }
    } else {
      this.openSearchBorrowerDialog();
    }
  }

  openIbsCredentialsDialog() {
    const updateIbsCredentialsDialogRef = this.dialog.open(
      IbsCredentialComponent, {
        panelClass: 'suffix-search__panel-dialog'
      }
    );
    updateIbsCredentialsDialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.openSearchBorrowerDialog();
      }
    });
  }

  openPremierCredentialsDialog() {
    const updatePremierCredentialsDialogRef = this.dialog.open(
      UpdatePremierCredentialsComponent, {
        panelClass: 'suffix-search__panel-dialog'
      }
    );
    updatePremierCredentialsDialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.openSearchBorrowerDialog();
      }
    });
  }

  openSelectPremierCredentialsDialog() {
    const updateSelectPremierCredentialsDialogRef = this.dialog.open(
      SelectPremierCredentialsComponent, {
        panelClass: 'suffix-search__panel-dialog'
      }
    );
    updateSelectPremierCredentialsDialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.openSearchBorrowerDialog();
      }
    });
  }
  openMiserCredentialsDialog() {
    const updateMiserCredentialsDialogRef = this.dialog.open(
      UpdateMiserCredentialsComponent, {
        panelClass: 'suffix-search__panel-dialog'
      }
    );
    updateMiserCredentialsDialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.openSearchBorrowerDialog();
      }
    });
  }

  openSpectrumCredentialsDialog() {
    const updateSpectrumCredentialsDialogRef = this.dialog.open(
      UpdateSpectrumCredentialsComponent,
      {
        panelClass: 'suffix-search__panel-dialog'
      }
    );
    updateSpectrumCredentialsDialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.openSearchBorrowerDialog();
      }
    });
  }

  openSelectSpectrumCredentialsDialog() {
    const updateSelectSpectrumCredentialsDialogRef = this.dialog.open(
      SelectSpectrumCredentialsComponent,
      {
        panelClass: 'suffix-search__panel-dialog'
      }
    );
    updateSelectSpectrumCredentialsDialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.openSearchBorrowerDialog();
      }
    });
  }

  openSearchBorrowerDialog() {
    const searchBorowerDialogRef = this.dialog.open(
      CCMCSearchBorrowerDialogComponent, {
        data: {},
        panelClass: 'search__panel-dialog'
      }
    );
    searchBorowerDialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result && this.activeCoreService.activeCore === 'dna') {
        for (let borrower of this.dnaService.finalParty) {
          let mappingIndex = -1;
          switch (borrower.CIFFields) {
            case 'REL1_005':
              // Account Title Line 1
              // mappingIndex = this.locateMapping('LN01_002');
              // if (mappingIndex > -1) {
              //   this.ccmcApiService.mapping[mappingIndex].fieldValue = borrower.firstName.concat(' ', borrower.lastName);
              // }
              // First Name
              mappingIndex = this.locateMapping('PER1_006');
              if (mappingIndex > -1) {
                this.ccmcApiService.mapping[mappingIndex].fieldValue = borrower.firstName;
              }
              // Middle Name
              mappingIndex = this.locateMapping('PER1_009');
              if (mappingIndex > -1 && borrower.middleName !== '') {
                this.ccmcApiService.mapping[mappingIndex].fieldValue = borrower.middleName;
              }
              // Last Name
              mappingIndex = this.locateMapping('PER1_008');
              if (mappingIndex > -1) {
                this.ccmcApiService.mapping[mappingIndex].fieldValue = borrower.lastName;
              }
              // Full Name
              mappingIndex = this.locateMapping('REL1_002');
              if (mappingIndex > -1) {
                if (borrower.middleName !== '') {
                  this.ccmcApiService.mapping[mappingIndex].fieldValue = borrower.firstName.concat(' ', borrower.middleName, ' ', borrower.lastName);
                } else {
                  this.ccmcApiService.mapping[mappingIndex].fieldValue = borrower.firstName.concat(' ', borrower.lastName);
                }
              }
              break;
            case 'REL2_005':
              // Account Title Line 1
              // mappingIndex = this.locateMapping('LN01_003');
              // if (mappingIndex > -1) {
              //   this.ccmcApiService.mapping[mappingIndex].fieldValue = borrower.firstName.concat(' ', borrower.lastName);
              // }
              // First Name
              mappingIndex = this.locateMapping('PER2_006');
              if (mappingIndex > -1) {
                this.ccmcApiService.mapping[mappingIndex].fieldValue = borrower.firstName;
              }
              // Middle Name
              mappingIndex = this.locateMapping('PER2_009');
              if (mappingIndex > -1 && borrower.middleName !== '') {
                this.ccmcApiService.mapping[mappingIndex].fieldValue = borrower.middleName;
              }
              // Last Name
              mappingIndex = this.locateMapping('PER2_008');
              if (mappingIndex > -1) {
                this.ccmcApiService.mapping[mappingIndex].fieldValue = borrower.lastName;
              }
              // Full Name
              mappingIndex = this.locateMapping('REL2_002');
              if (mappingIndex > -1) {
                if (borrower.middleName !== '') {
                  this.ccmcApiService.mapping[mappingIndex].fieldValue = borrower.firstName.concat(' ', borrower.middleName, ' ', borrower.lastName);
                } else {
                  this.ccmcApiService.mapping[mappingIndex].fieldValue = borrower.firstName.concat(' ', borrower.lastName);
                }
              }
              break;
            case 'REL3_005':
              // First Name
              mappingIndex = this.locateMapping('PER3_006');
              if (mappingIndex > -1) {
                this.ccmcApiService.mapping[mappingIndex].fieldValue = borrower.firstName;
              }
              // Middle Name
              mappingIndex = this.locateMapping('PER3_009');
              if (mappingIndex > -1 && borrower.middleName !== '') {
                this.ccmcApiService.mapping[mappingIndex].fieldValue = borrower.middleName;
              }
              // Last Name
              mappingIndex = this.locateMapping('PER3_008');
              if (mappingIndex > -1) {
                this.ccmcApiService.mapping[mappingIndex].fieldValue = borrower.lastName;
              }
              // Full Name
              mappingIndex = this.locateMapping('REL3_002');
              if (mappingIndex > -1) {
                if (borrower.middleName !== '') {
                  this.ccmcApiService.mapping[mappingIndex].fieldValue = borrower.firstName.concat(' ', borrower.middleName, ' ', borrower.lastName);
                } else {
                  this.ccmcApiService.mapping[mappingIndex].fieldValue = borrower.firstName.concat(' ', borrower.lastName);
                }
              }
              break;
            case 'REL4_005':
              // First Name
              mappingIndex = this.locateMapping('PER4_006');
              if (mappingIndex > -1) {
                this.ccmcApiService.mapping[mappingIndex].fieldValue = borrower.firstName;
              }
              // Middle Name
              mappingIndex = this.locateMapping('PER4_009');
              if (mappingIndex > -1 && borrower.middleName !== '') {
                this.ccmcApiService.mapping[mappingIndex].fieldValue = borrower.middleName;
              }
              // Last Name
              mappingIndex = this.locateMapping('PER4_008');
              if (mappingIndex > -1) {
                this.ccmcApiService.mapping[mappingIndex].fieldValue = borrower.lastName;
              }
              // Full Name
              mappingIndex = this.locateMapping('REL4_002');
              if (mappingIndex > -1) {
                if (borrower.middleName !== '') {
                  this.ccmcApiService.mapping[mappingIndex].fieldValue = borrower.firstName.concat(' ', borrower.middleName, ' ', borrower.lastName);
                } else {
                  this.ccmcApiService.mapping[mappingIndex].fieldValue = borrower.firstName.concat(' ', borrower.lastName);
                }
              }
              break;
          }
        }
      }
    });
  }

  locateMapping(mappingSearch: any) {
    const columnIndex = this.ccmcApiService.mapping.findIndex(
      (mapping: any) => mapping.fieldID === mappingSearch
    );
    return columnIndex;
  }

  /**
   * Search Collateral
   * @description Opens collateral search dialog
   * @memberof CCMCToolbarComponent
   */
  searchCollateral() {
    this.dialog.open(CCMCCollateralSearchDialogComponent, {
      panelClass: 'collateral-search__dialog'
    });
  }

  /**
   * Go To Profile
   * @description navigate to user profile page
   * @memberof CCMCToolbarComponent
   */
  goToProfile() {
    this.currentNavigationTypeLog = 'home';
    this.router.navigate(['user-profile']);
  }

  /**
   * Logout
   * @description logs the user out
   * @memberof CCMCToolbarComponent
   */
  logout() {
    // removes the tokens set by login and redirects to the login page
    this.amplifyService.logout();
    this.authService.isLoggedIn = false;
    this.removeData();
    this.amplifyService.clearUserGroups();
    this.router.navigate(['login']);
  }

  /**
   * Remove Data
   * @description Removes ccmc api data
   * @memberof CCMCToolbarComponent
   */
  removeData() {
    this.ccmcApiService.mapping = undefined;
    this.ccmcApiService.coreSettings = undefined;
    this.ccmcApiService.conditions = undefined;
    this.ccmcApiService.search = undefined;
    this.ccmcApiService.transactions = undefined;
    this.ccmcApiService.configurations = undefined;
    this.ccmcApiService.mapping = undefined;
    this.ccmcApiService.supportValues = undefined;
    this.ccmcApiService.loanNumber
    this.ccmcApiService.transactionReceiptList.next(null);
    this.ccmcApiService.currentLoanNumber.next(0);
    // Clears out internal Display Settings
    this.navigationService.onVisibilityChange.next(false);
    this.navigationService.onSortChange.next({ button: 'numeric', alphaNumeric: 'numeric' });
    this.navigationService.defaultInstance = true;
    this.navigationService.transactionHistoryToggle.next(false);
    // Clears out loan number from sidebar navigation
    this.updateLoanCount(0);
    // Clearing out fetched logs
    this.readLoggingService.clearData();
    // Clears out transactions from sidebar navigation
    navigation[2].children[0].badge!.title = 0;
    navigation[2].children[1].children = undefined;
    navigation[0].children = [];
  }

  /**
   * Go To Settings
   * @description Go to ground control Settings
   * @memberof CCMCToolbarComponent
   */
  goToSettings() {
    this.router.navigate(['gc-settings']);
  }

  /**
   * On Destroy
   * @memberof CCMCToolbarComponent
   */
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  /**
   * Open Dialog
   * @description Opens the Field Help Dialog
   * @memberof CCMCToolbarComponent
   */
  openDialog() {
    const dialogRef = this.dialog.open(CCMCFieldHelpDialogComponent, {
      width: '250px',
      data: this.selectedField
    });
  }

  /**
   * Product Selection
   * @description navigate to asset selector
   * @memberof CCMCToolbarComponent
   */
  productSelection() {
    this.currentNavigationTypeLog = 'home';
    this.removeData();
    this.router.navigate(['assetid-selector']);
  }

  /**
   * Check Mortgage Bot
   * @description Flag if los is mortgagebot
   * @memberof CCMCToolbarComponent
   */
  checkMortageBot() {
    if (this.activeLosService.activeLos === 'mortgagebot') {
      this.isMortgageBot = true;
    } else {
      this.isMortgageBot = false;
    }
  }

  /**
   * Create Backup
   * @description This cerates a json backup of the document object
   * @memberof CCMCToolbarComponent
   */
  async createBackup() {
    this.spinnerService.setShowSpinner(true);
    if (
      (this.router.url.includes('transactions') &&
        this.amplifyService.isNXTsoft) ||
      this.amplifyService.isNXTsoftDev ||
      this.amplifyService.isNXTsoftOperations ||
      this.amplifyService.isNXTsoftSales ||
      this.currentNavigationType === 'admin'
    ) {
      const product = this.assetService.getSelectedProduct();
      const asset = this.assetService.getSelectedAssetId();
      console.log(product);
      let documentJSON;
      if (product.includes('doc_connector')) {
        documentJSON = this.dcDocument;
        documentJSON['_id'] = asset;
        if (documentJSON) {
          const date = new Date().toISOString();
          const coreParams = {
            bucket: 'nxtsoft-database-backups',
            key: `${asset}/DC/${date}`,
            file: JSON.stringify(documentJSON)
          };
          let uploadTargetBackupResponse: any;
            (await this.fileService.uploadBackuptoS3(coreParams)).subscribe(async response => {
              uploadTargetBackupResponse = response;
              console.log(uploadTargetBackupResponse);
              if (uploadTargetBackupResponse['statusFlag']) {
                const backupSuccessDialog = this.dialog.open(SuccessDialogComponent, {
                  data: {
                    title: 'Success',
                    message: 'Successfully created backup'
                  }
                });
              }
            });
            this.spinnerService.setShowSpinner(false);
          console.log(uploadTargetBackupResponse);
        }
      } else if (product.includes('general_ledger_accounting')) {
        console.log('GLA Document', this.glaDocument);
        documentJSON = this.glaDocument;
        documentJSON['_id'] = asset;
        const date = new Date().toISOString();
        const coreParams = {
          bucket: 'nxtsoft-database-backups',
          key: `${asset}/GLA/${date}`,
          file: JSON.stringify(documentJSON)
        };
        let uploadGLABackupResponse: any =
          await this.fileService.uploadBackuptoS3(coreParams);
        console.log(uploadGLABackupResponse);
        this.spinnerService.setShowSpinner(false);
        if (uploadGLABackupResponse['statusFlag']) {
          const backupSuccessDialog = this.dialog.open(SuccessDialogComponent, {
            data: {
              title: 'Success',
              message: 'Successfully created backup'
            }
          });
        }
      } else {
        this.adminApiService.setBackupDocument();
        documentJSON = {
          _id: asset,
          mapping: this.adminApiService.documentBackup.mapping,
          conditions: this.adminApiService.documentBackup.conditions,
          configurations: this.adminApiService.documentBackup.configurations,
          search: this.adminApiService.documentBackup.search,
          supportValues: this.adminApiService.documentBackup.supportValues,
          transactions: this.adminApiService.documentBackup.transactions
        };
        const date = new Date().toISOString();
        const coreParams = {
          bucket: 'nxtsoft-database-backups',
          key: `${asset}/Target/${date}`,
          file: JSON.stringify(documentJSON)
        };
        let uploadTargetBackupResponse: any;
          (await this.fileService.uploadBackuptoS3(coreParams)).subscribe(async response => {
            uploadTargetBackupResponse = response;
            console.log(uploadTargetBackupResponse);
            if (uploadTargetBackupResponse['statusFlag']) {
              const backupSuccessDialog = this.dialog.open(SuccessDialogComponent, {
                data: {
                  title: 'Success',
                  message: 'Successfully created backup'
                }
              });
            }
          });
          this.spinnerService.setShowSpinner(false);
        console.log(uploadTargetBackupResponse);
      }
    }
  }

  async downloadDatabaseFile() {
    // If route matches or in admin view && NXTSOFT USER
    if (
      (this.router.url.includes('transactions') || this.currentNavigationType === 'admin') &&
      await this.amplifyService.checkNXTsoftUserGroup()
    ) {
      const product = this.assetService.getSelectedProduct();
      const asset = this.assetService.getSelectedAssetId();
      console.log(product);
      let documentJSON;
      if (product.includes('doc_connector')) {
        documentJSON = this.dcDocument;
        documentJSON['_id'] = asset;
      } else if (product.includes('general_ledger_accounting')) {
        console.log('GLA Document', this.glaDocument);
        documentJSON = this.glaDocument;
        documentJSON['_id'] = asset;
      } else {
        documentJSON = {
          mapping: this.ccmcApiService.mapping,
          conditions: this.ccmcApiService.conditions,
          configurations: this.ccmcApiService.configurations,
          search: this.ccmcApiService.search,
          supportValues: this.ccmcApiService.supportValues,
          transactions: this.ccmcApiService.transactions,
          _id: asset
        };
      }

      if (documentJSON) {
        var dataStr =
          'data:text/json;charset=utf-8,' +
          encodeURIComponent(JSON.stringify(documentJSON));
        var dlJSONElem: any = document.getElementById('downloadJSONElem');
        dlJSONElem.setAttribute('href', dataStr);
        dlJSONElem.setAttribute('download', `${asset}.json`);
        dlJSONElem.click();
      }
    }
  }
  /**
   * Get Avatar
   * @description Gets the users avatar
   * @memberof CCMCToolbarComponent
   */
  getAvatar() {
    this.avatarSubscription = this.avatarService.avatar
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(avatar => {
        this.avatar = avatar;
        if (avatar) {
          this.avatar = avatar;
        }
      });
  }

  /**
   * Switch Navigation
   * @description switches between the users navigation
   * @memberof CCMCToolbarComponent
   */
  switchNavigation() {
    const product = this.assetService.getSelectedProduct();
    this.currentNavigationTypeLog = 'home';
    if (this.currentNavigationType === 'default') {
      window.setTimeout(() => {
        this.navigationService.onNavChange.next('admin');
        this.navigationService.navigateToFirstVisibleChild(product);
      }, 2000);
    } else {
      this.navigationService.onNavChange.next('default');
      this.navigationService.navigateToFirstVisibleChild(product);
    }
  }

  /**
   * Revert Changes
   * @description Revert current changes to the document
   * @memberof CCMCToolbarComponent
   */
  revertChanges() {
    const message = 'Are you sure you want to Revert changes?';
    const dialogRef2 = this.dialog.open(CCMCConfirmDialogComponent, {
      data: message
    });
    dialogRef2.afterClosed().subscribe(data => {
      if (data === true) {
        if (this.isDocConnectorAsset()) {
          const backupDocSchema = JSON.parse(
            JSON.stringify(this.dcService.documentBackup)
          );
          this.dcService.setDocumentConnectorDocument(backupDocSchema);
        } else if (this.isGLAAsset()) {
          console.log(this.glaService.glaDocumentBackup);
          const backupGLa = JSON.parse(
            JSON.stringify(this.glaService.glaDocumentBackup)
          );
          this.glaService.setGLADocumentCoreData(backupGLa);
        } else {
          const backupCore = JSON.parse(
            JSON.stringify(this.adminApiService.documentBackup)
          );
          // update the document
          this.adminApiService.setDocumentCoreData(backupCore);
        }
        // turn flags off
        this.fieldEditedService.fieldEdited.next(false);
        this.fieldEditedService.coreEdited.next(false);
        this.fieldEditedService.losEdited.next(false);
        this.fieldEditedService.onConfigurationsFieldEdited.next(false);
        this.snackBarService.openSnackBar('Reverted Changes', 'Okay');
        if (this.assetService.getSelectedProduct().includes('doc_connector')) {
          this.selectedFieldService.onHistoryLoanFieldSelected.next(undefined);
          this.selectedFieldService.onHistoryLoanFileFieldSelected.next(
            undefined
          );
          this.selectedFieldService.onGlobalMetasFieldSelected.next(undefined);
          this.selectedFieldService.onDestinationFieldSelected.next(undefined);
          this.router.navigate(['doc-connector/history']);
        } else if (
          this.assetService
          .getSelectedProduct()
          .includes('general_ledger_accounting')
        ) {
          // this.router.navigate(['field-options']);
        } else {
          this.router.navigate(['field-options']);
        }
      }
    });
  }

  /**
   * On Submit Update Core
   * @description Updates the core document
   * @memberof CCMCToolbarComponent
   */
  onSubmitUpdateCore() {
    const errorMessage =
      'Did you create a backup? Are you sure you want to Submit your changes?';
    const dialogRef2 = this.dialog.open(CCMCConfirmDialogComponent, {
      data: errorMessage
    });
    dialogRef2.afterClosed().subscribe(async data => {
      if (data === true) {
        // Turn spinner on
        this.spinnerService.setShowSpinner(true);
        const coreConnectionData: any =
          await this.adminApiService.getCoreConnectionData();
        if (coreConnectionData['statusFlag']) {
          // Create document call
          this.adminApiService
            .createDocumentCore(coreConnectionData['content'])
            .subscribe(async (res: any) => {
              // Turn core flag off
              this.fieldEditedService.coreEdited.next(false);
              this.fieldEditedService.onConfigurationsFieldEdited.next(false);
              const sentLog = JSON.parse(
                JSON.stringify(this.ccmcApiService.logTargetChangesArray)
              );
              const assetID = this.assetService.getSelectedAssetId();
              const stream = assetID;
              this.writeLoggingService.setStream(stream);
              this.writeLoggingService
                .writeLog({
                  username: this.amplifyService.username,
                  action: 'Submit Target Updates',
                  time: new Date().toISOString(),
                  log: sentLog
                })
                .subscribe(result => {
                  // logging result
                  console.log(result);
                });
              this.ccmcApiService.clearLogChanges('Target');
              this.snackBarService.openSnackBar(res['statusMessage'], 'Okay');
              await this.adminApiService.updateModifiedBy(this.name);
              // Turn spinner off
              this.spinnerService.setShowSpinner(false);
              // reroute to refresh object
              this.router
                .navigateByUrl('/RefrshComponent', {
                  skipLocationChange: true
                })
                .then(() => this.router.navigate(['field-options']));
            });
        } else {
          const errorMessage = 'Error Retrieving Connection Data';
          const dialogRef2 = this.dialog.open(CCMCConfirmDialogComponent, {
            data: errorMessage
          });
          return;
        }
      }
    });
  }

  onSubmitUpdateGLA() {
    const errorMessage = 'Are you sure you want to Submit your changes?';
    const notation = 'GLA';
    const dialogRef2 = this.dialog.open(CCMCConfirmDialogComponent, {
      data: errorMessage,
    });
    dialogRef2.afterClosed().subscribe((data) => {
      if (data === true) {
        this.spinnerService.setShowSpinner(true);
        this.selectedCore = this.assetService.getSelectedCore();

        this.ccmcApiService
          .getCoreConnection(this.selectedCore)
          .subscribe((coreConnectionData: any) => {
            console.log(coreConnectionData);
            if (
              coreConnectionData &&
              coreConnectionData['couchbaseConnectionString']
            ) {
              this.glaService
                .createDocumentGLA(coreConnectionData)
                .then(async (res) => {
                  let result = JSON.parse(JSON.stringify(res));
                  this.fieldEditedService.onGLADocumentEdited.next(false);
                  this.fieldEditedService.onGLAConfigurationFieldEdited.next(
                    false
                  );
                  this.openSnackBar(result.statusMessage, 'Okay');
                  const assetID = this.assetService.getSelectedAssetId();
                  const stream = assetID;
                  this.writeLoggingService.setStream(stream);
                  this.writeLoggingService
                    .writeLog({
                      username: this.amplifyService.username,
                      action: 'Submit GLA Updates',
                      time: new Date().toISOString(),
                      log: this.ccmcApiService.logGLAChangesArray
                    })
                    .subscribe(result => {
                      // logging result
                      console.log(result);
                    });
                  this.ccmcApiService.clearLogChanges('GLA');
                  // unlock gcai
                  await this.ccmcApiService.updateModifiedBy(this.name);

                  this.spinnerService.setShowSpinner(false);

                  this.selectedFieldService.onSetSelected.next(null);
                  this.selectedFieldService.onGLATransactionFieldSelected.next(
                    null
                  );
                  this.selectedFieldService.onTransactionColumnFieldSelected.next(
                    null
                  );
                  this.selectedFieldService.onGLATranslatorFieldSelected.next(
                    null
                  );
                  this.selectedFieldService.onTargetLayoutFieldSelected.next(
                    null
                  );
                  // reroute to refresh object
                  this.router
                    .navigateByUrl('/RefrshComponent', {
                      skipLocationChange: true,
                    })
                    .then(() => this.router.navigate(['set-configuration']));
                });
            }
          });
      } else {
        this.spinnerService.setShowSpinner(false);
      }
    });
  }
  /**
   * Get Mortgage Bot Loans
   * @description set assetID, thne get mortage bot loans
   * @memberof CCMCToolbarComponent
   */
  getMortgageBotLoans() {

    this.spinnerService.setShowSpinner(true);
    if (this.assetService.getHasEBM()) {
      const resultMessage = {
        message: "EBM process has started, please check logs for run time information",
        title: 'EBM Process'
      };

      const dialogRef2 = this.zone.run(() => {
        this.dialog.open(SuccessDialogComponent, {
          data: resultMessage
        });
      });
    }
    this.getLoansButtonFlag = true;
    const assetID = this.assetService.getSelectedAssetId();
    return this.mortgageBotService.getMortgageBotLoans(assetID).subscribe((result: any) => {
      console.log(result);
      const parsedResult = JSON.parse(JSON.stringify(result));
      if (parsedResult.successFlag === true) {
        const resultMessage = {
          message: parsedResult.message,
          title: 'Get Loans Result'
        };

        const dialogRef2 = this.zone.run(() => {
          this.dialog.open(SuccessDialogComponent, {
            data: resultMessage
          });
        });
      }

      if (parsedResult.error) {
        const errorMessage = {
          message: parsedResult.error.message,
          title: 'Loan Error'
        };

        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      } else if (parsedResult.successFlag === false) {
        const errorMessage = {
          message: parsedResult.message,
          title: 'Loan Error'
        };

        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      }
      this.spinnerService.setShowSpinner(false);
      this.getLoansButtonFlag = false;
    });
  }

  isGLA() {
    return this.assetService.getGLA();
  }

  exportToCSV() {
    const dialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
      data: 'Continue exporting transactions to CSV?'
    });
    console.log('export to csv 703');
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Export to CSV');
        this.spinnerService.setShowSpinner(true);
        if (this.exportDocument) {
          console.log(this.exportDocument);
          this.ccmcApiService
            .convertJSONToCSV(this.exportDocument)
            .subscribe(result => {
              console.log('export to csv 713');
              this.spinnerService.setShowSpinner(false);
              console.log(result);
              if (result) {
                const parsedResult = JSON.parse(JSON.stringify(result));
                if (parsedResult.statusFlag) {
                  var blob = new Blob([parsedResult.content], {
                    type: 'text/csv;charset=utf-8;'
                  });
                  var link = document.createElement('a');
                  if (link.download !== undefined) {
                    // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    if(this.router.url.includes('reviewList')) {
                      link.setAttribute(
                        'download',
                        `${this.ccmcApiService.loanNumber}_reviewList.csv`
                      );
                    } else {
                      link.setAttribute(
                        'download',
                        `${this.ccmcApiService.loanNumber}.csv`
                      );
                    }
                    
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }
                } else {
                  const errorDialogRef = this.dialog.open(
                    ErrorDialogComponent, {
                      data: {
                        title: 'Export Error',
                        message: parsedResult.statusMessage
                      }
                    }
                  );
                }
              }
            });
        }
      }
    });
  }
  exportToCSVChangeRequest() {
    const dialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
      data: 'Continue exporting change request log to CSV?'
    });
    console.log('export to csv 703');
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Export to CSV');
        this.spinnerService.setShowSpinner(true);
        if (this.exportChangeRequestDocument) {
          console.log(this.exportChangeRequestDocument);
          this.ccmcApiService
            .convertJSONToCSV(this.exportChangeRequestDocument)
            .subscribe(result => {
              console.log('export to csv 713');
              this.spinnerService.setShowSpinner(false);
              console.log(result);
              if (result) {
                const parsedResult = JSON.parse(JSON.stringify(result));
                if (parsedResult.statusFlag) {
                  var blob = new Blob([parsedResult.content], {
                    type: 'text/csv;charset=utf-8;'
                  });
                  var link = document.createElement('a');
                  if (link.download !== undefined) {
                    // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    link.setAttribute(
                      'download',
                      `${this.assetService.getSelectedFI()}-${this.assetService.getSelectedAssetId()}-${this.currentDate
                      }.csv`
                    );
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }
                } else {
                  const errorDialogRef = this.dialog.open(
                    ErrorDialogComponent, {
                      data: {
                        title: 'Export Error',
                        message: parsedResult.statusMessage
                      }
                    }
                  );
                }
              }
            });
        }
      }
    });
  }
  importSend() {
    let users = this.importService.getUserArray();
    users.forEach((user: any) => {
      user.customerBase.assignedBorrowerType =
        user.customerBase.assignedBorrowerType.toLowerCase();
      if (user.customerBase.assignedBorrowerType.includes('-')) {
        user.customerBase.assignedBorrowerType =
          user.customerBase.assignedBorrowerType.replace('-', '');
      }
    });
    let params = {
      content: users,
      assetID: this.assetService.getSelectedAssetId()
    };
    console.log(params);
    if (params.content) {
      this.importService
        .sendUsers(params)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(result => {
          console.log(result);
          if (result) {
            this.dialog.open(CopyTextDialogComponent, {
              data: {
                title: 'Success',
                messageBefore: `Loan Created: `,
                messageCopy: `${result.loanNumber}`,
                messageAfter: ``
              }
            });
          }
        });
    }
  }
  checkedImpMode() {
    // console.log('here');
    const requestChangeNavItem = this.navigation[1].children.findIndex(
      child => child.id == 'change-request-log'
    );
    if (requestChangeNavItem == -1) {
      console.log(
        "Developer Intervention needed. Can't find change-request-log id in navigation array."
      );
      return;
    }
    // console.log(this.impModechecked);
    if (this.impModechecked === undefined) {
      this.navigation[1].children[requestChangeNavItem].visible = false;
    } else {
      this.navigation[1].children[requestChangeNavItem].visible =
        this.impModechecked;
    }
    // this.fieldEditedService.impModechecked.next(this.impModechecked);
    // console.log(this.fieldEditedService.impModechecked);
    // this.fieldEditedService.impModechecked.next(this.impModechecked);
  }
  onClickSwitch() {
    if (this.impModechecked === true) {
      this.fieldEditedService.impModechecked.next(this.impModechecked);
    } else {
      this.fieldEditedService.impModechecked.next(this.impModechecked);
    }
  }

  isDocConnectorAsset() {
    let currentProduct = this.assetService.getSelectedProduct();
    if (currentProduct && currentProduct.includes('doc_connector')) {
      return true;
    } else {
      return false;
    }
  }

  isGLAAsset() {
    let currentProduct = this.assetService.getSelectedProduct();
    if (
      currentProduct &&
      currentProduct.includes('general_ledger_accounting')
    ) {
      return true;
    } else {
      return false;
    }
  }

  async onSendHistory() {
    console.log('Send Loans');
    const sendDocConnectorDialogResponse: any = await this.dialogHandlerService.handleDialog(SendDocConnectorDialogComponent, {
      data: this.checkedDocConnectorLoans[0].loanNumber
    })
    console.log({
      sendDocConnectorDialogResponse
    });
    if (!sendDocConnectorDialogResponse.statusFlag) {
      console.log('User canceled send request');
    } else if (sendDocConnectorDialogResponse.resetBeforeSend) {
      this.spinnerService.setShowSpinner(true);
      let encompassSettings: any;
       await this.activeLosService.activeLosService.retrieveEncompassSettings(this.assetService.getSelectedAssetId())
      .then((res: any) => {
        encompassSettings = res;
      })
      .catch((err: any) => {
        console.log(err);
      });
      console.log(encompassSettings);
      if (!encompassSettings!.instanceID) {
        return;
      }

      // Delete S3 Files associated with loan guid
      await this.deleteDocLoanFiles(
        this.checkedDocConnectorLoans[0].loanGUID
      );
      this.checkedDocConnectorLoans[0].files = [];
      const updateHistoryResponse = await this.updateDocConnectorHistory();
      console.log(updateHistoryResponse);
      // Call send to ice
      // Call Regular
      // Set Doc Connector Listener Request
      const docListenerRequest = {
        "eventId": uuidv4(),
        "eventTime": new Date().toLocaleString(),
        "eventType": "change",
        "meta": {
          "userId": await this.amplifyService.getUserName(),
          "resourceType": "Loan",
          "resourceId": this.checkedDocConnectorLoans[0].loanGUID,
          "instanceId": encompassSettings!.instanceID,
          "resourceRef": `/encompass/v1/loans/${this.checkedDocConnectorLoans[0].loanGUID}`
        }
      }
      let sendToIceResponse: any;
      (await this.dcService.sendToDocListener(docListenerRequest)).subscribe(async (res: any) => {
          console.log(res);
          sendToIceResponse = res;
          this.spinnerService.setShowSpinner(false);
      console.log(sendToIceResponse);
      if (sendToIceResponse.statusFlag) {
        await this.dialogHandlerService.handleDialog(SuccessDialogComponent, {
          data: {
            title: 'Success',
            message: sendToIceResponse.statusMessage
          }
        })
        this.router
          .navigateByUrl('/RefreshComponent', {
            skipLocationChange: true
          })
          .then(() => this.router.navigate(['doc-connector/history']));
      } else {
        await this.dialogHandlerService.handleDialog(ErrorDialogComponent, {
          data: {
            title: 'Error',
            message: sendToIceResponse.error.message
          }
        });
        // reroute to refresh object
        this.router
          .navigateByUrl('/RefreshComponent', {
            skipLocationChange: true
          })
          .then(() => this.router.navigate(['doc-connector/history']));
      }
        });
    
      // Turn spinner off
    } else {
      this.spinnerService.setShowSpinner(true);
      let encompassSettings: any;
       await this.activeLosService.activeLosService.retrieveEncompassSettings(this.assetService.getSelectedAssetId())
      .then((res: any) => {
        encompassSettings = res;
      })
      .catch((err: any) => {
        console.log(err);
      });
      console.log(encompassSettings);
      if (!encompassSettings.instanceID) {
        return;
      }

      // Set Doc Connector Listener Request
      const docListenerRequest = {
        "eventId": uuidv4(),
        "eventTime": new Date().toLocaleString(),
        "eventType": "change",
        "meta": {
          "userId": await this.amplifyService.getUserName(),
          "resourceType": "Loan",
          "resourceId": this.checkedDocConnectorLoans[0].loanGUID,
          "instanceId": encompassSettings.instanceID,
          "resourceRef": `/encompass/v1/loans/${this.checkedDocConnectorLoans[0].loanGUID}`
        }
      }
      let sendToIceResponse: any;
      (await this.dcService.sendToDocListener(docListenerRequest)).subscribe(async (res: any) => {
          console.log(res);
          sendToIceResponse = res;
          // Turn spinner off
      this.spinnerService.setShowSpinner(false);
      console.log(sendToIceResponse);
      if (sendToIceResponse.statusFlag) {
        await this.dialogHandlerService.handleDialog(SuccessDialogComponent, {
          data: {
            title: 'Success',
            message: sendToIceResponse.statusMessage
          }
        })
        this.router
          .navigateByUrl('/RefreshComponent', {
            skipLocationChange: true
          })
          .then(() => this.router.navigate(['doc-connector/history']));
        this._cd.detectChanges();
      } else {
        await this.dialogHandlerService.handleDialog(ErrorDialogComponent, {
          data: {
            title: 'Error',
            message: sendToIceResponse.error.message
          }
        });
        // reroute to refresh object
        this.router
          .navigateByUrl('/RefreshComponent', {
            skipLocationChange: true
          })
          .then(() => this.router.navigate(['doc-connector/history']));
        this._cd.detectChanges();
      }
        });
    
      
    }
  }

  async deleteDocLoanFiles(loanGUID: string) {
    return new Promise(resolve => {
      let loanKey = `${this.assetService.getSelectedAssetId()}/${loanGUID}`;
      // deletes the loan from S3
      this.ccmcApiService
        .s3DeleteFolder(
          'nxtsoft-doc-connector-files',
          loanKey,
          'delete for resend'
        )
        .subscribe(response => {
          console.log('response ', response);
          return resolve(true);
        });
    });
  }

  async updateDocConnectorHistory() {
    return new Promise(resolve => {
      this.ccmcApiService
        .createDynamoDB(
          'prod',
          'encompass_document_connector_history',
          this.checkedDocConnectorLoans[0]
        )
        .subscribe(res => {
          console.log(res);
          return resolve(res);
        });
    });
  }

  async deleteDynamoDBDocItem(loanGUID: string) {
    return new Promise(resolve => {
      this.ccmcApiService
        .deleteDynamoDBItem(
          'loanGUID',
          loanGUID,
          'encompass_document_connector_history'
        )
        .subscribe(result => {
          if (result) {
            console.log(result);
            return resolve(true);
          }
        });
    });
  }

  onSubmitDocConnectorUpdates() {
    const errorMessage =
      'Did you create a backup? Are you sure you want to Submit your changes?';
    const dialogRef2 = this.dialog.open(CCMCConfirmDialogComponent, {
      data: errorMessage
    });
    dialogRef2.afterClosed().subscribe(async data => {
      if (data === true) {
        // Turn spinner on
        this.spinnerService.setShowSpinner(true);
        const losConnectionData: any =
          await this.adminApiService.getLosConectionData();
        if (losConnectionData['statusFlag']) {
          this.dcService
            .createDocument(losConnectionData['content'])
            .subscribe(async (res: any) => {
              // Turn core flag off
              this.fieldEditedService.losEdited.next(false);
              this.fieldEditedService.onConfigurationsFieldEdited.next(false);
              this.snackBarService.openSnackBar(res['statusMessage'], 'Okay');
              const sentLog = JSON.parse(
                JSON.stringify(this.ccmcApiService.logDCChangesArray)
              );
              const msg = {
                username: this.name,
                action: `${this.name} has submittted updates to the schema`,
                time: new Date().toISOString(),
                log: sentLog
              };
              // Write log
              this.writeLoggingService.writeLog(msg).subscribe(result => {
                console.log(result);
                // console.log(result);
              });
              await this.adminApiService.updateModifiedBy(this.name);
              // Turn spinner off
              this.spinnerService.setShowSpinner(false);
              // reroute to refresh object
              this.router
                .navigateByUrl('/RefrshComponent', {
                  skipLocationChange: true
                })
                .then(() => this.router.navigate(['doc-connector/history']));
            });
        } else {
          const errorMessage = 'Error Retrieving Connection Data';
          const dialogRef2 = this.dialog.open(CCMCConfirmDialogComponent, {
            data: errorMessage
          });
          return;
        }
      }
    });
  }

  async getEncompassInstanceID() {
    return new Promise(resolve => {
      this.adminApiService
        .getDynamoDBItem(
          'encompass_settings',
          this.assetService.getSelectedAssetId(),
          'assetID'
        )
        .subscribe(result => {
          const parsedResult = JSON.parse(JSON.stringify(result));
          console.log('Encompass Settings', parsedResult);
          if (parsedResult && parsedResult.instanceID) {
            const instanceIDResponse = {
              statusFlag: true,
              statusMessage: 'Successfully retrieved Encompass InstanceID',
              content: parsedResult.instanceID
            };
            return resolve(instanceIDResponse);
          } else {
            const instanceIDError = {
              statusFlag: true,
              statusMessage: `Unable to pull instanceID for assetID: ${this.assetService.getSelectedAssetId()}`,
              content: parsedResult.instanceID
            };
            return resolve(instanceIDError);
          }
        });
    });
  }

  selectLoggingGroup(group: any) {
    console.log('Group ', group);
    this.readLoggingService.clearData();
    this.readLoggingService.selectedLoggingGroup.next(group);
  }

  exportEBM() {
    let logs: any;
    let data: any;
    if (this.router.url.includes('ebm-logging')) {
      this.readLoggingService.ebmExportLogs
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((logging: any) => {
          if (logging) {
            logs = logging;
            data = 'Continue exporting EBM logs to CSV?'
          }
        });
    } else {
      this.readLoggingService.ebmExportReports
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((reports: any) => {
        if (reports) {
          logs = reports;
          data = 'Continue exporting EBM reports to CSV?'
        }
      });
    }
    const dialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
      data: data
    });
    console.log('export to csv 703');
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Export to CSV');
        this.spinnerService.setShowSpinner(true);
        if (logs) {
          console.log(logs);
          this.ccmcApiService
            .convertJSONToCSV(logs)
            .subscribe(result => {
              console.log('export to csv 713');
              this.spinnerService.setShowSpinner(false);
              console.log(result);
              if (result) {
                const parsedResult = JSON.parse(JSON.stringify(result));
                if (parsedResult.statusFlag) {
                  var blob = new Blob([parsedResult.content], {
                    type: 'text/csv;charset=utf-8;'
                  });
                  var link = document.createElement('a');
                  if (link.download !== undefined) {
                    // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    link.setAttribute(
                      'download',
                      `${this.assetService.getSelectedAssetId()}-logs.csv`
                    );
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }
                } else {
                  const errorDialogRef = this.dialog.open(
                    ErrorDialogComponent,
                    {
                      data: {
                        title: 'Export Error',
                        message: parsedResult.statusMessage
                      }
                    }
                  );
                }
              }
            });
        }
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  checkNxtsoft(): any {
    return this.amplifyService.isNXTsoftDev || this.amplifyService.isNXTsoftOperations || this.amplifyService.isNXTsoftSales;
  }
  checkNxtsoftDev(): any {
    return this.amplifyService.isNXTsoftDev;
  }

  checkNxtsoftOperations(): any {
    return this.amplifyService.isNXTsoftOperations;
  }

  checkAdmin(): any {
    return this.amplifyService.isAdmin || this.amplifyService.isSuperAdmin;
  }

  checkOperationsOrSales(): any {
    return this.amplifyService.isNXTsoftOperations || this.amplifyService.isNXTsoftSales;
  }

  checkLoanSystemsAdmin(): any {
    return this.amplifyService.isLoanSystemsAdmin;
  }
  // checkLoanServicingAdmin(): any {
  //   return this.amplifyService.isLoanServicingAdmin;
  // }

  checkLoanProcessPermissions() {
    //  || this.checkLoanServicingAdmin() add this to the return below!
    return this.checkNxtsoft() || this.checkAdmin();
  }

  checkAdminPrivilege() {
    if (this.checkNxtsoft() || this.checkAdmin() || this.checkOperationsOrSales() || this.checkLoanSystemsAdmin()) {
      return true;
    } else {
      return false;
    }
  }

  determineUserIcon() {
    if (this.checkNxtsoftDev()) {
      this.userIcon = 'rocket';
    // } else if (this.checkLoanServicingAdmin()) {
    //   this.userIcon = 'pen';
    // } else if (this.checkLoanSystemsAdmin()) {
    //   this.userIcon = 'cog';
    } else if (this.checkOperationsOrSales()) {
      this.userIcon = 'user';
    } else if (this.checkAdmin()) {
      this.userIcon = 'key';
    }
  }

  /*
    The checkOverrideSubmitPermissions() function in TypeScript checks if the current user has any of the three permissions - development, operations, or admin. 
    It does so by executing three different functions, namely checkNxtsoftDev(), checkNxtsoftOperations(), and checkAdmin(), in an OR condition. 
    If at least one of these three functions returns true, then the checkOverrideSubmitPermissions() function will also return true; 
    otherwise, it will return false.
  */
  checkOverrideSubmitPermissions() {
    return this.checkNxtsoftDev() || this.checkNxtsoftOperations() || this.checkAdmin()
  }

  updateLoanCount(count: any) {
    this.dynamicNav[1].children[1].badge.title = count;
    this.dynamicGLANav[1].children[1].badge.title = count;
  }
  getUsers() {
    // subscribe to current users
    this.usersSub = this.ccmcApiService.users
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(users => {
        if (users) {
          if (!this.isUserNXTsoft) {
            this.users = users.filter((obj: any) => {
              return (
                !obj.username.includes('@nxtsoft.com') &&
                !obj.username.includes('@ccmcinc.com') &&
                !obj.username.includes('kinective.io')
              );
            });
          } else {
            this.users = users;
          }
          this.exportToCSVUsers(JSON.parse(JSON.stringify(this.users)));
        } 
      });
  }

  exportToCSVUsers(users: any) {
    users.forEach((user: any) => {
      this.ccmcApiService
      .getUserGroupsForUser(user.username)
        .subscribe(result => {
      //grab the usergroups for the current selected user
      const parsedResult = JSON.parse(JSON.stringify(result));
      var usergroups = parsedResult.content;
      let adminIndex = usergroups.findIndex((usergroup: any) => usergroup.GroupName === 'client-admin' || 
      usergroup.GroupName.includes('nxtsoft') ||
      usergroup.GroupName == 'super-admin');
      if (adminIndex > -1) {
        user.isAdmin = true;
      } else {
        user.isAdmin = false;
      }
      delete user.accountID
      delete user.cognitoID
      delete user.contactID
      delete user.enabled
      delete user.padID
      delete user.status
    });
  });
    const dialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
      data: 'Continue exporting users to CSV?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Export to CSV');
        this.spinnerService.setShowSpinner(true);
        if (users) {
          this.ccmcApiService
            .convertJSONToCSV(users)
            .subscribe(result => {
              this.spinnerService.setShowSpinner(false);
              if (result) {
                const parsedResult = JSON.parse(JSON.stringify(result));
                if (parsedResult.statusFlag) {
                  var blob = new Blob([parsedResult.content], {
                    type: 'text/csv;charset=utf-8;'
                  });
                  var link = document.createElement('a');
                  if (link.download !== undefined) {
                    // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    link.setAttribute(
                      'download',
                      `${this.assetService.getSelectedAssetId()} Users.csv`
                    );
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }
                } else {
                  const errorDialogRef = this.dialog.open(
                    ErrorDialogComponent, {
                      data: {
                        title: 'Export Error',
                        message: parsedResult.statusMessage
                      }
                    }
                  );
                }
              }
            });
        }
      }
    });
  }
}
