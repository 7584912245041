import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { ErrorDialogComponent } from './error-dialog.component';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';

@NgModule({
    declarations: [
        ErrorDialogComponent
    ],
    imports: [
        MatDialogModule,
        AppMaterialModule,
        MatButtonModule
    ],
    entryComponents: [
        ErrorDialogComponent
    ],
})
export class CCMCErrorDialogModule {
}
