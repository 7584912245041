import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pledge-value-dialog',
  templateUrl: './pledge-value-dialog.component.html',
  styleUrls: ['./pledge-value-dialog.component.scss']
})
export class PledgeValueDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Array<any>,
    private dialogRef: MatDialogRef<PledgeValueDialogComponent>
  ) {}
  selectedCollateral: any;
  nextBtnDisabled: any;
  prevBtnDisabled: any;
  selectedIndex = 0;
  @ViewChild('fixedAmountInput', { static: true })
  fixedAmountInputEl: ElementRef;
  @ViewChild('percentAmountInput', { static: true })
  percentAmountInputEl: ElementRef;
  ngOnInit(): void {
    console.log(this.data);
    // Assign selected collateral
    this.selectedCollateral = this.data[this.selectedIndex];
    // Init prev button as disabled
    this.prevBtnDisabled = true;
    // Determine if next button is disabled
    if (this.data.length > 1) {
      this.nextBtnDisabled = false;
    } else {
      this.nextBtnDisabled = true;
    }
    for (let collateral of this.data) {
      if (
        collateral.percentOfValue !== '' &&
        collateral.percentOfValue !== undefined
      ) {
        collateral.percentOfValue = collateral.percentOfValue * 100;
      } else {
        // collateral.percentOfValue = 0;
      }
    }

    this.setSelectedCollateralPledgeType();
  }

  next() {
    this.selectedIndex++;
    // Disable next if user is at the end of list
    console.log(this.selectedIndex);
    console.log(this.selectedCollateral.length);
    if (this.selectedIndex === this.data.length - 1) {
      this.nextBtnDisabled = true;
    }
    if (this.selectedIndex > 0) {
      this.prevBtnDisabled = false;
    }
    this.selectedCollateral = this.data[this.selectedIndex];
    this.setSelectedCollateralPledgeType();
  }

  previous() {
    this.selectedIndex--;
    // Disable next if user is at the end of list
    if (this.selectedIndex === 0) {
      this.prevBtnDisabled = true;
      this.nextBtnDisabled = false;
    }
    this.selectedCollateral = this.data[this.selectedIndex];
    this.setSelectedCollateralPledgeType();
  }

  checkContinue() {
    for (let collateral of this.data) {
      if (collateral.dollarOfValue === '' && collateral.percentOfValue === '') {
        return false;
      }
    }

    return true;
  }

  continue() {
    for (let collateral of this.data) {
      if (
        collateral.percentOfValue !== '' &&
        collateral.percentOfValue !== undefined
      ) {
        collateral.percentOfValue = collateral.percentOfValue / 100;
      }
    }
    this.dialogRef.close(true);
  }

  closeDialog() {
    for (let collateral of this.data) {
      if (
        collateral.percentOfValue !== '' &&
        collateral.percentOfValue !== undefined
      ) {
        collateral.percentOfValue = collateral.percentOfValue / 100;
      }
    }
    this.dialogRef.close();
  }

  focusInput(event: any) {
    console.log(event);
    if (event.value === 'fixed') {
      this.selectedCollateral.percentOfValue = '0.000';
      this.fixedAmountInputEl.nativeElement.focus();
    } else if (event.value === 'percent') {
      this.selectedCollateral.dollarOfValue = '0.00';
      this.percentAmountInputEl.nativeElement.focus();
    }
    console.log(this.selectedCollateral.pledgeType);
  }

  setTwoNumberDecimal(value: any) {
    value = parseFloat(value).toFixed(2);
  }

  checkFixedAmount(): boolean {
    let value: boolean = this.selectedCollateral.dollarOfValue != '' && 
    this.selectedCollateral.dollarOfValue != undefined && 
    this.selectedCollateral.dollarOfValue > 0;
    return value;
  }

  checkPercentAmount(): boolean {
    let value: boolean = this.selectedCollateral.percentOfValue != '' && 
    this.selectedCollateral.percentOfValue != undefined && 
    this.selectedCollateral.percentOfValue > 0;
    return value
  }

  setSelectedCollateralPledgeType() {
    if (
      this.selectedCollateral.pledgeType != undefined &&
      this.selectedCollateral.pledgeType != ''
    ) {
      console.log('Pledge type is already set, return');
      return;
    }

    if (this.checkFixedAmount()) {
      console.log('Set pledge type to fixed');
      this.selectedCollateral.pledgeType = 'fixed';
    } else if (this.checkPercentAmount()) {
      console.log('Set pledge type to percent');
      this.selectedCollateral.pledgeType = 'percent';
    }
  }
}
