import {
  Component,
  OnInit,
  NgZone,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { Subscription, Subject, Observable, fromEvent } from 'rxjs';
import { CcmcApiService } from '../../../../@ccmc/services/ccmc-api.service';
import {
  takeUntil,
  startWith,
  map,
  debounceTime,
  distinctUntilChanged
} from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { CCMCConfirmDialogComponent } from '../../../../@ccmc/components/confirm-dialog/confirm-dialog.component';
import { FieldEditedService } from '../../../../@ccmc/services/field-edited.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { MatTableDataSource } from '@angular/material/table';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
import { AddValidationDialogComponent } from 'src/@ccmc/components/add-validation-dialog/add-validation-dialog.component';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { WriteLoggingService } from 'src/@ccmc/services/write-logging.service';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import * as _ from "lodash";
import { MatSnackBar } from '@angular/material/snack-bar';
import { EditValidationGroupDialogComponent } from 'src/@ccmc/components/edit-validation-group-dialog/edit-validation-group-dialog.component';
import { AddValidationGroupDialogComponent } from 'src/@ccmc/components/add-validation-group-dialog/add-validation-group-dialog.component';
// import { AddValidationDialogComponent } from '../../../../@ccmc/components/add-validation-dialog/add-validation-dialog.component';
@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss']
})
export class ValidationComponent implements OnInit {
  // fieldEdited;
  private documentCoreSub: Subscription;
  private editedObjectSub: Subscription;
  unsubscribe: Subject<any> = new Subject();
  tempDocument: any;
  mappingObject: any;
  validationObject: any[] = [];
  showSpinner: boolean;
  private spinnerSub: Subscription;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: any;
  displayedColumns = ['conditionalCheckBox', 'validationGroup', 'fieldIDs', 'value', 'displayName', 'edited'];
  fieldIDsDataSource: any;
  fieldIDsDisplayedColumns = ['fieldID'];
  selectedValidation: any = [];
  currentSingularSelection: any;
  localSelectedValidation = {
    displayName: '',
    value: '',
    fieldIDs: [''],
    validationGroup: '',
    edited: false
  };
  selectedValidationMappingFieldIDs: any;
  selectedFieldID: any;
  newFieldID: any;
  myControlFieldID = new FormControl();
  filteredOptionsFieldID: Observable<any[]>;
  editedFlag = false;
  fieldIDsWithAddedOrRemovedValidation: any[] = [];
  filterSearch: string;
  @ViewChild('filterSearch', { static: true }) filterSearchEl: ElementRef;
  @ViewChild('filterValidationGroupSearch', { static: true }) filterValidationGroupEl: ElementRef;
  @ViewChild('dataSourceSort', { static: false }) set sort(value: MatSort) {
    this.dataSource.sort = value;
  }
  @ViewChild('fieldIDsDataSourceSort', { static: false }) set fieldIDSort(
    value: MatSort
  ) {
    this.fieldIDsDataSource.sort = value;
  }
  editedObjectsArray : any[];
  tableHeaderCheckBox: boolean;
  validationGroups: any[] = [];
  selectedValidationGroup: any;
  newAddedValidationGroup: any;
  extraFilter: any;
  validationGroupEvent: any;
  sortedData: any;
  originalValidationItem: any;

  /**
   * Creates an instance of ValidationComponent.
   * @param {CcmcApiService} ccmcApiService
   * @param {MatDialog} dialog
   * @param {FieldEditedService} fieldEditedService
   * @param {AdminApiService} adminApiService
   * @param {SpinnerService} spinnerService
   * @param {SnackbarService} snackBarService
   * @memberof ValidationComponent
   */
  constructor(
    private ccmcApiService: CcmcApiService,
    public dialog: MatDialog,
    private fieldEditedService: FieldEditedService,
    private adminApiService: AdminApiService,
    private spinnerService: SpinnerService,
    private snackBarService: SnackbarService,
    private assetService: AssetService,
    private writeLoggingService: WriteLoggingService,
    private amplifyService: AmplifyService,
    private snackBar: MatSnackBar,
    private zone: NgZone,
  ) {}

  /**
   * On Init
   *
   * @memberof ValidationComponent
   */
  ngOnInit() {
    // Turn spinner on
    this.spinnerService.setShowSpinner(true);
    // Get data
    setTimeout(() => {
      this.getData();
    }, 500);
    // Init filtered options for fieldID
    this.filteredOptionsFieldID = this.myControlFieldID.valueChanges.pipe(
      startWith(''),
      map(value => (value.length >= 3 ? this._filter(value) : [])),
      debounceTime(1000),
      // If previous query is different from current
      distinctUntilChanged()
    );
    // subscribe to spinner
    this.spinnerSub = this.spinnerService.spinner.subscribe(spinner => {
      this.showSpinner = spinner;
    });
    // Init filter search
    this.initFilterSearch();
    this.selectedValidationGroup = 'all';
  }

  sortData(sort: Sort) {
    this.fieldIDsDataSource.data = this.fieldIDsDataSource.data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      return this._compare(a[sort.active], b[sort.active], isAsc);
    });
  }
  private _compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  AfterViewInit() {
    this.fieldIDsDataSource.sort = this.fieldIDSort;
    // this.shortcuts.push({
    //   key: ['cmd + s'],
    //   label: 'Saving',
    //   description: 'saving',
    //   command: e => {
    //     this.save();
    //   }
    // });
  }

  /**
   * On Destroy
   *
   * @memberof ValidationComponent
   */
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  /**
   * Get Data
   * @description Retrieves data needed for validation
   * @memberof ValidationComponent
   */
  getData() {
    // Subscribe to document
    this.editedObjectSub = this.fieldEditedService.editedObjects
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => {
        this.editedObjectsArray = value;
        //console.log(this.editedObjectsArray);
      });
    this.documentCoreSub = this.adminApiService.documentCore
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(documentCore => {
        if (documentCore) {
          // Init Validation object
          this.validationObject = [];
          // Init fieldIDs with added or removed validation
          this.fieldIDsWithAddedOrRemovedValidation = [];
          // Set temporary document
          this.tempDocument = documentCore;
          // Init Validation Groups dataset
          this.validationGroups = documentCore.validationGroups;
          // Set mapping object
          if (documentCore.mapping) {
            this.mappingObject = JSON.parse(
              JSON.stringify(documentCore.mapping)
            );
            console.log('Mapping object',this.mappingObject);
            if (this.mappingObject.length === 0) {
              return;
            }
            // Retrieve validation
            for (let i = 0; i < this.mappingObject.length; i++) {
              if (this.mappingObject[i].validation) {
                for (
                  let j = 0;
                  j < this.mappingObject[i].validation.length;
                  j++
                ) {
                  const tempValidator = {
                    displayName:
                      this.mappingObject[i].validation[j].displayName,
                    value: this.mappingObject[i].validation[j].value,
                    fieldIDs: [this.mappingObject[i].fieldID],
                    validationGroup: this.mappingObject[i].validation[j].validationGroup,
                    edited: this.mappingObject[i].validation[j].edited
                  };
                  const validatorIndex = this.validationObject.findIndex(
                    v =>
                      v.displayName === tempValidator.displayName &&
                      v.value === tempValidator.value
                  );
                  if (validatorIndex > -1) {
                    this.validationObject[validatorIndex].fieldIDs.push(
                      this.mappingObject[i].fieldID
                    );
                  } else {
                    this.validationObject.push(tempValidator);
                  }
                }
              }
            }
            // Double checking that validation groups should exist
            if (this.validationGroups === undefined && this.validationObject.length > 0) {
              this.validationGroups = [];
              for (let validation of this.validationObject) {
                if (validation.validationGroup !== 'n/a' && !this.validationGroups.includes(validation.validationGroup)) {
                  this.validationGroups.push(validation.validationGroup);
                }
              }
            }
            this.validationGroups = this.validationGroups.sort();
            // turn spinner off
            this.spinnerService.setShowSpinner(false);
            console.log('validation object',JSON.parse(JSON.stringify(this.validationObject)));
            // assign data source as mapping
            if (this.validationObject.length === 0) {
              this.dataSource = new MatTableDataSource([]);
            } else {
              // assign data source as mapping
              for(let validation of this.validationObject) {
                if(validation.validationGroup === undefined) {
                  validation.validationGroup = 'n/a';
                }
              }
              console.log('validation object',JSON.parse(JSON.stringify(this.validationObject)));
              if(this.validationGroupEvent) {
                if(this.validationGroupEvent.value.toLowerCase() === 'all') {
                  this.dataSource = new MatTableDataSource(this.validationObject);
                  this.basicSelectValidation(this.dataSource.data[0]);
                } else {
                  let filteredData = _.filter(this.validationObject, (item) => {
                    return item.validationGroup.toLowerCase() == this.validationGroupEvent.value.toLowerCase();
                  })
                  this.dataSource = new MatTableDataSource(filteredData);
                  this.basicSelectValidation(this.dataSource.data[0]);
                }
              } else {
                this.dataSource = new MatTableDataSource(this.validationObject);
              }

              // initializes pagination
              this.dataSource.paginator = this.paginator;
              // initializes sort
              this.dataSource.sort = this.sort;
              // Select first instance of the validation object
              for (let row of this.dataSource.filteredData) {
                row.updateField = false;
              }
              this.basicSelectValidation(this.validationObject[0]);
            }
          } else {
            this.validationObject = undefined!;
          }
        }
      });
  }

  /**
   * Can Deactivate
   * @description Controls if the user can navigate away from the component
   * @returns
   * @memberof ValidationComponent
   */
  canDeactivate() {
    if (this.editedFlag) {
      this.snackBarService.openSnackBar('Please resolve changes', 'Okay');
    }
    return !this.editedFlag;
  }

  /**
   * On Select Validation
   * @description Selects a validation item
   * @param {*} selected
   * @memberof ValidationComponent
   */
  // onSelectValidation(selected: any) {
  //   if (selected) {
  //     this.selectedValidation = selected;
  //     this.localSelectedValidation = JSON.parse(JSON.stringify(selected));
  //     this.selectedValidationMappingFieldIDs =
  //       this.localSelectedValidation.fieldIDs;
  //     this.fieldIDsDataSource = new MatTableDataSource(
  //       this.selectedValidationMappingFieldIDs
  //     );
  //     this.onSelectFieldID(this.fieldIDsDataSource.data[0]);
  //   }
  // }
  basicSelectValidation(selected: any) {
    this.selectedValidation.push(selected);
    this.originalValidationItem = JSON.parse(JSON.stringify(selected));
    this.currentSingularSelection = selected;
    this.localSelectedValidation = JSON.parse(JSON.stringify(selected));
    this.selectedValidationMappingFieldIDs = this.changetoObject(
      this.localSelectedValidation.fieldIDs
    );
    this.fieldIDsDataSource = new MatTableDataSource(
      this.selectedValidationMappingFieldIDs
    );
    this.fieldIDsDataSource.sort = this.fieldIDSort;
    this.onSelectFieldID(this.fieldIDsDataSource.data[0]);
  }

  onSelectValidation(selected: any, event: any) {
    this.originalValidationItem = JSON.parse(JSON.stringify(selected));
        // Updates the checkAllBox at the top of the column based on the current selection
       if (selected.updateField === true || selected.updateField === false) {
          this.tableHeaderCheckBox = selected.updateField;
        }
    
        if (event.shiftKey) {
          // Selection while holding shift
          let previousSelection = this.selectedValidation[this.selectedValidation.length - 1];
          let currentSelection = selected;
          let beginningOfSelection: any;
          let endOfSelection: any;
          let counter = 0;
    
          this.basicSelectValidation(selected);
    
          // loops through the dataSource array to find the top most selection and the bottom
          for (let field of this.dataSource.filteredData) {
            if (field === previousSelection || field === currentSelection) {
              if (beginningOfSelection === null || beginningOfSelection === undefined) {
                beginningOfSelection = counter;
              } else {
                endOfSelection = counter;
                break;
              }
            }
            counter++;
          }
          // fills selectedItem array with all the fields to highlight
          for (let index = beginningOfSelection; index <= endOfSelection; index++) {
            if (!this.selectedValidation.includes(this.dataSource.filteredData[index])) {
              this.selectedValidation.push(this.dataSource.filteredData[index]);
            }
          }
        } else if (event.ctrlKey) {
          // Selection while holding ctrl
          this.basicSelectValidation(selected);
        } else {
          // Regular selection
         this.selectedValidation = [];
          // this.selectedFieldService.onConditionsFieldSelected.next(selected);
    
          this.basicSelectValidation(selected);
        }
      }
    
      checkAllBox(event: any) {
        // Updates the checkbox at the top of the table
        this.tableHeaderCheckBox = event.checked;
        // If only one row has been selected, it will check / uncheck all
        // If more than one row is selected, it will check / uncheck only the selection
        if (this.selectedValidation.length === 1) {
          for (let row of this.dataSource.filteredData) {
            this.setUpdateFieldByFieldID(row, event);
          }
        } else {
         for (let row of this.selectedValidation) {
            this.setUpdateFieldByFieldID(row, event);
          }
        }
      }
    
      setUpdateFieldByFieldID(selection: any, event: any) {
        let checked: boolean;
        let compareDocumentIndex: any;
        // Accounts for if the event variable is a checkbox event or a true / false directly input into the function
        if (event.checked === true || event.checked === false) {
          checked = event.checked;
        } else {
          checked = event;
        }
        compareDocumentIndex = this.dataSource.filteredData.findIndex((obj: any) => obj === selection);
    
        // If index was found, the selection's updateField will be assigned true or false
        if (compareDocumentIndex > -1) {
          let currentCompareDocumentItem =  this.dataSource.filteredData[compareDocumentIndex];
          currentCompareDocumentItem.updateField = checked;
        }
      }

  /**
   * On Select FieldID
   * @description Selects a fieldID item
   * @param {*} selected
   * @memberof ValidationComponent
   */
  onSelectFieldID(selected: any) {
    this.selectedFieldID = selected;
  }

  /**
   * Filter
   * @description Filters the mapping object for fieldID autocomplete
   * @private
   * @param {string} value
   * @returns {string[]}
   * @memberof ValidationComponent
   */
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.mappingObject.filter((option: any) =>
      option.fieldID.toLowerCase().includes(filterValue)
    );
  }

  /**
   * Add FieldID TO Validation
   * @description Adds fieldID to validation
   * @returns
   * @memberof ValidationComponent
   */
  addFieldIDToValidation() {
    const mappingFieldIDs = this.tempDocument.mapping.map((field: any) => field.fieldID);
    console.log('mapping fieldIDs',mappingFieldIDs);
    if (mappingFieldIDs.includes(this.newFieldID)) {
      for (let i = 0; i < this.selectedValidationMappingFieldIDs.length; i++) {
        if (this.newFieldID === this.selectedValidationMappingFieldIDs[i].fieldID) {
          this.openSnackBar('Cannot enter duplicate field IDs', 'Okay');
          return;
        }
      }
      this.selectedValidationMappingFieldIDs.push({ fieldID: this.newFieldID });
      this.fieldIDsWithAddedOrRemovedValidation.push({
        fieldID: this.newFieldID
      });
      this.newFieldID = '';
      this.editedFlag = true;
      this.fieldIDsDataSource = new MatTableDataSource(
        this.selectedValidationMappingFieldIDs
      );
    } else {
      this.openSnackBar('Please enter a valid field ID', 'Okay');
    }
  }

  /**
   * Remove FieldID From Validation
   * @description
   * @memberof ValidationComponent
   */
  removeFieldIDFromValidation() {
    const confirmDialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
      data: 'Are you sure you want to remove this field ID?'
    });
    confirmDialogRef.afterClosed().subscribe(data => {
      if (data) {
        // Keep fields that do not have the same fieldID
        this.selectedValidationMappingFieldIDs =
          this.selectedValidationMappingFieldIDs.filter(
            (fieldID: any) => fieldID !== this.selectedFieldID
          );
        // Push the fieldID to the list
        this.fieldIDsWithAddedOrRemovedValidation.push(this.selectedFieldID);
        // set fieldID data source
        this.fieldIDsDataSource = new MatTableDataSource(
          this.selectedValidationMappingFieldIDs
        );
        // Select first instance of fieldIDs
        this.onSelectFieldID(this.fieldIDsDataSource.data[0]);
        // Check fieldEdited
        this.fieldEdited();
      }
    });
  }

  /**
   * Field Edited
   * @description Checks if the validation has been edited
   * @memberof ValidationComponent
   */
  fieldEdited() {
    this.editedFlag = false;
    if (
      this.currentSingularSelection.displayName !==
      this.localSelectedValidation.displayName
    ) {
      this.editedFlag = true;
    } else if (
      this.currentSingularSelection.value !== this.localSelectedValidation.value
    ) {
      this.editedFlag = true;
    } else if (
      this.currentSingularSelection.fieldIDs !== this.localSelectedValidation.fieldIDs
    ) {
      this.editedFlag = true;
    } else if (
      this.currentSingularSelection.validationGroup !== this.localSelectedValidation.validationGroup
    ) {
      this.editedFlag = true;
    }
  }

  /**
   * Save
   * @description saves the validation edits
   * @memberof ValidationComponent
   */
  save() {
    const stringSelectedValidationMappingFieldIDs = this.changetoString(
      this.selectedValidationMappingFieldIDs
    );
    this.localSelectedValidation.fieldIDs =
      stringSelectedValidationMappingFieldIDs;
    const oldValidation = JSON.parse(JSON.stringify(this.currentSingularSelection));
    const newValidation = JSON.parse(
      JSON.stringify(this.localSelectedValidation)
    );
    this.fieldEditedService.coreEdited.next(true);
    // If only the fieldIDs are changed we don't set the edited flag
    if (
      this.currentSingularSelection.displayName !==
        this.localSelectedValidation.displayName ||
      this.currentSingularSelection.value !== this.localSelectedValidation.value || 
      this.currentSingularSelection.validationGroup !== this.localSelectedValidation.validationGroup ||
      this.originalValidationItem.fieldIDs.sort().join(',') !== this.localSelectedValidation.fieldIDs.sort().join(',')
    ) {
      this.currentSingularSelection.edited = true;
    }
    this.currentSingularSelection.displayName = this.localSelectedValidation.displayName;
    this.currentSingularSelection.value = this.localSelectedValidation.value;
    let valGroupIndex = this.validationObject.findIndex((item) => item.validationGroup === this.localSelectedValidation.validationGroup);
    if (valGroupIndex > -1 && this.localSelectedValidation.validationGroup !== 'n/a') {
      this.currentSingularSelection.fieldIDs = this.validationObject[valGroupIndex].fieldIDs;
    } else  {
      this.currentSingularSelection.fieldIDs = this.localSelectedValidation.fieldIDs;
    }
    if (this.originalValidationItem.fieldIDs.sort().join(',') !== this.localSelectedValidation.fieldIDs.sort().join(',')) {
      this.currentSingularSelection.validationGroup = 'n/a';
      this.currentSingularSelection.fieldIDs = this.localSelectedValidation.fieldIDs;
    } else {
      this.currentSingularSelection.validationGroup = this.localSelectedValidation.validationGroup;
    }
    this.editedFlag = false;
    this.fieldEditedService.fieldEdited.next(false);
    this.setValidation();
    this.setEditedFlagForAddedOrRemovedValidation();
    this.tempDocument.mapping = this.mappingObject;
    const oldObject = { oldValidation };
    const newObject = { newValidation };
    const editedObject = { oldObject, newObject };
    const msg = {
      type: 'Validation',
      editedObject
    };
    this.ccmcApiService.appendLogChanges(msg, 'Target');
  }

  /**
   * Set Validation
   * @description Rebuilds the validation in the mapping object
   * @memberof ValidationComponent
   */
  setValidation() {
    // Delete the current validation
    for (let i = 0; i < this.mappingObject.length; i++) {
      if (this.mappingObject[i].validation) {
        delete this.mappingObject[i].validation;
      } else {
        continue;
      }
    }
    // Assign new validation
    for (let i = 0; i < this.validationObject.length; i++) {
      for (let j = 0; j < this.validationObject[i].fieldIDs.length; j++) {
        // Find the index for the validation
        const fieldIDIndex = this.mappingObject.findIndex(
          (obj: any) => obj.fieldID === this.validationObject[i].fieldIDs[j]
        );
        if (fieldIDIndex > -1) {
          // temporary validation object to push
          const tempValidation = {
            displayName: this.validationObject[i].displayName,
            value: this.validationObject[i].value,
            validationGroup: this.validationObject[i].validationGroup,
            edited: this.validationObject[i].edited
          };
          // if item already has validation, push it to its current array
          if (this.mappingObject[fieldIDIndex].validation) {
            this.mappingObject[fieldIDIndex].validation.push(tempValidation);
          } else {
            this.mappingObject[fieldIDIndex].validation = [tempValidation];
          }
          if (tempValidation.edited) {
            this.mappingObject[fieldIDIndex].edited = true;
          }
        }
      }
    }
  }

  /**
   * Set Edited Flag For Added Or Removed Validation
   * @description If a fieldID has been removed from validation we must set its edited flag to true in the mapping to show that its validation has been changed
   * @memberof ValidationComponent
   */
  setEditedFlagForAddedOrRemovedValidation() {
    for (let i = 0; i < this.fieldIDsWithAddedOrRemovedValidation.length; i++) {
      const fieldIDIndex = this.mappingObject.findIndex(
        (obj: any) => obj.fieldID === this.fieldIDsWithAddedOrRemovedValidation[i].fieldID
      );
      if (fieldIDIndex > -1) {
        this.mappingObject[fieldIDIndex].edited = true;
      }
    }
  }

  /**
   * Add Validation
   * @description Add Validation
   * @memberof ValidationComponent
   */
  addValidation() {
    const addValidationDialogRef = this.dialog.open(
      AddValidationDialogComponent,
      {
        data: this.validationObject
      }
    );
    addValidationDialogRef.afterClosed().subscribe(data => {
      if (data) {
        console.log('after close data',data);
        if (!data.validationGroup) {
          data.validationGroup = 'n/a'
        }
        // push new validation to the validation object
        this.validationObject.push(data);
        // Update datasource
        this.dataSource = new MatTableDataSource(this.validationObject);
        // initializes pagination
        this.dataSource.paginator = this.paginator;
        // initializes sort
        this.dataSource.sort = this.sort;
        // push fieldId to fieldIdsWithAddedOrRemovedValidation
        this.fieldIDsWithAddedOrRemovedValidation.push(data.fieldIDs[0]);
        // Set core edited to true
        this.fieldEditedService.coreEdited.next(true);
        // Set validation
        this.setValidation();
        // Set flag
        this.setEditedFlagForAddedOrRemovedValidation();
        // Update mapping
        this.tempDocument.mapping = this.mappingObject;
      }
    });
  }

  /**
   * Remove Validation
   * @description removes a validation from the validation object
   * @memberof ValidationComponent
   */
  removeValidation() {
    const confirmDialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
      data: 'Are you sure you want to remove this validation?'
    });
    confirmDialogRef.afterClosed().subscribe(data => {
      if (data) {
        // Turn the mapping fields associated with this validation on
        let tempSelection: any = [];
        for (let row of this.dataSource.filteredData) {
          if (row.updateField === true) {
            // Turn the mapping fields associated with this validation on
            for (let i = 0; i < row.fieldIDs.length; i++) {
              const fieldIDIndex = this.mappingObject.findIndex(
              (obj: any) => obj.fieldID === row.fieldIDs[i]
              );
              this.mappingObject[fieldIDIndex].edited = true;
            }
            this.validationObject = this.validationObject.filter(
              (validation: any) =>
                validation.displayName !== row.displayName ||
                validation.value !== row.value
            );
            tempSelection.push(row);
          }
         }
        const validationObject = JSON.parse(
          JSON.stringify(tempSelection)
        );
        const oldObject = { validationObject };
        const newObject = '';
        const editedObject = { oldObject, newObject };
        const msg = {
          type: 'Validation',
          editedObject
        };
        this.ccmcApiService.appendLogChanges(msg, 'Target');

        if(this.selectedValidationGroup === 'all') {
          this.dataSource = new MatTableDataSource(this.validationObject);
        } else {
          let filteredData = _.filter(this.validationObject, (item) => {
            return item.validationGroup.toLowerCase() == this.validationGroupEvent.value.toLowerCase();
          })
          this.dataSource = new MatTableDataSource(filteredData);
        }
          // initializes pagination
        this.dataSource.paginator = this.paginator;
        // initializes sort
        this.dataSource.sort = this.sort;
        this.basicSelectValidation(this.validationObject[0]);
        this.fieldEditedService.coreEdited.next(true);
        this.setValidation();
        this.setEditedFlagForAddedOrRemovedValidation();
        this.tempDocument.mapping = this.mappingObject;

        this.selectedValidationMappingFieldIDs = [];
        this.fieldIDsDataSource = new MatTableDataSource(
          this.selectedValidationMappingFieldIDs
        );
        this.selectedValidationMappingFieldIDs = [];
        this.localSelectedValidation.displayName = '';
        this.localSelectedValidation.value = '';
        this.localSelectedValidation.validationGroup = '';

        this.applyFilter(this.filterSearch);
      }
    });
  }

  /**
   * Apply Filter
   * @description Apply filter on datasource
   * @param {string} filterValue
   * @memberof ValidationComponent
   */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if(this.dataSource.filteredData[0]) {
      this.basicSelectValidation(this.dataSource.filteredData[0]);
    }
    this.filterSearch = filterValue;
  }

  /**
   * Init Filter Search
   * @description Inits the filter search
   * @memberof ValidationComponent
   */
  initFilterSearch() {
    // Auto Focus filter search item
    this.filterSearchEl.nativeElement.focus();
    fromEvent(this.filterSearchEl.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // Time in milliseconds between key events
        debounceTime(1000),
        // If previous query is diffent from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((text: string) => {
        this.applyFilter(text);
      });
  }

  changetoObject(arrayofStrings: any) {
    let arrayofObjects: any[] = [];
    for (let fieldID of arrayofStrings) {
      arrayofObjects.push({ fieldID });
    }
    return arrayofObjects;
  }

  changetoString(arrayofObjects: any) {
    {
      let arrayofStrings: any[] = [];
      for (let fieldID of arrayofObjects) {
        arrayofStrings.push(fieldID.fieldID);
      }
      return arrayofStrings;
    }
  }

  addValidationGroup(event: any) {
    event.stopPropagation();
    const addValidationGroupDialogRef = this.dialog.open(
      AddValidationGroupDialogComponent,
      {
        data: this.validationObject,
        panelClass: 'add-validation__dialog',
        disableClose: true
      }
    );
    addValidationGroupDialogRef.afterClosed().subscribe((data: any) => {
      console.log('after close data',data);
      if (data.length > 0) {
        this.spinnerService.setShowSpinner(true);
        for (const val of data) {
          for (let i = 0; i < val.fieldIDs.length; i++) {
            this.fieldIDsWithAddedOrRemovedValidation.push({
              fieldID: val.fieldIDs[i]
            });
          }
          let validationGroupExists = this.tempDocument.validationGroups.findIndex((group: any) => group === val.validationGroup);
          if(validationGroupExists > -1) {
            this.validationGroups = this.tempDocument.validationGroups;
            } else {
              this.tempDocument.validationGroups.push(val.validationGroup);
              this.validationGroups = this.tempDocument.validationGroups;
            }
          this.fieldEditedService.coreEdited.next(true);
          this.setValidation();
          this.setEditedFlagForAddedOrRemovedValidation();
          console.log('mapping object',this.mappingObject);
          console.log('temp document',this.tempDocument);
          this.tempDocument.mapping = this.mappingObject;
          const oldObject = '';
          const newObject = { data };
          const editedObject = { oldObject, newObject };
          const msg = {
            type: 'Validation',
            editedObject
          };
          this.ccmcApiService.appendLogChanges(msg, 'Target');
        }
        // initializes pagination
        this.dataSource.paginator = this.paginator;
        // initializes sort
        this.dataSource.sort = this.sort;
        this.adminApiService.setDocumentCoreSimple(this.tempDocument);
        this.openSnackBar('Added new validation group', 'Okay');
      } else {
        this.fieldEditedService.coreEdited.next(true);
        this.adminApiService.setDocumentCoreSimple(this.tempDocument);
        this.openSnackBar('Added new validation group', 'Okay');
      }
    });
  }

  editValidationGroup() {
    const editValidationGroupRef = this.dialog.open(EditValidationGroupDialogComponent, {
      data: {
        selectedValidationGroup: this.selectedValidationGroup,
        validationGroups: this.validationGroups,
        validationObject: this.validationObject
      },
      panelClass: 'edit-validation-group__dialog',
      disableClose: true
    });

    editValidationGroupRef.afterClosed().subscribe(async (data) => {
      if(data) {
        let editGroupObject = JSON.parse(JSON.stringify(data));
        for (let validation of this.validationObject) {
          if (validation.validationGroup === editGroupObject.validationGroup) {
            validation.fieldIDs = editGroupObject.updatedFieldIDs
          }
        }
        this.setValidation();
        this.setEditedFlagForAddedOrRemovedValidation();
        this.fieldEditedService.coreEdited.next(true);
        this.tempDocument.mapping = this.mappingObject;
        const oldObject = '';
        const newObject = { data };
        const editedObject = { oldObject, newObject };
        const msg = {
          type: 'Validation',
          editedObject
        };
        this.ccmcApiService.appendLogChanges(msg, 'Target');
        this.dataSource.sort = this.sort;
        this.adminApiService.setDocumentCoreSimple(this.tempDocument);
      }
    })
  }  

  onChange(event: any) {
    this.validationGroupEvent = event;
    if(event.value.toLowerCase() === 'all') {
      this.dataSource = new MatTableDataSource(this.validationObject);
      this.dataSource.paginator = this.paginator;
      this.selectedValidation = [];
      this.basicSelectValidation(this.dataSource.data[0]);
    } else {
      console.log('validation object', JSON.parse(JSON.stringify(this.validationObject)));
      let filteredData = _.filter(this.validationObject, (item: any) => {
        return item.validationGroup.toLowerCase() == event.value.toLowerCase();
      })
      this.dataSource = new MatTableDataSource(filteredData);
      this.basicSelectValidation(this.dataSource.data[0]);
      this.dataSource.paginator = this.paginator;
    }
  }

  openSnackBar(message: string, action: string) {
    this.zone.run(() => {
      setTimeout(() => {
        this.snackBar.open(message, action, {
          duration: 5000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }, 0);
    });
  }
}
