<div class="add-new-user-container">
    <div style="display: flex; justify-content: space-between">
        <h1>Add New User</h1>
        <div class="btn-container">
            <button class="addBtn" [disabled]="!tmpUser.contactFirstName || !tmpUser.contactLastName || !tmpUser.email || !tmpUser.assets || assets.length == 0"
                mat-raised-button (click)="createUser()" color="accent">
                <mat-icon style="color: #ffffff">add_circle</mat-icon> &nbsp;Create User
            </button>
        </div>
    </div>
    <div class="row-one">
        <mat-form-field class="field1">
            <input matInput placeholder="E-mail" [(ngModel)]="tmpUser.email" />
        </mat-form-field>
        <mat-form-field class="field2">
            <input matInput placeholder="Password" [(ngModel)]="tmpUser.password" />
        </mat-form-field>
        <mat-form-field class="field3">
            <input matInput placeholder="First Name" [(ngModel)]="tmpUser.contactFirstName" />
        </mat-form-field>
        <mat-form-field class="field4">
            <input matInput placeholder="Last Name" [(ngModel)]="tmpUser.contactLastName" />
        </mat-form-field>
    </div>
    <div class="row-two" *ngIf="isNXTsoft || isNXTsoftDev || isNXTsoftOperations || isNXTsoftSales">
        <mat-form-field style="width: 100%">
            <input matInput placeholder="AccountID" [(ngModel)]="tmpUser.accountID" />
        </mat-form-field>
        <mat-form-field class="example-chip-list" style="width: 100%">
            <mat-chip-list #assetList aria-label="Fruit selection">
                <mat-chip *ngFor="let asset of assets" [selectable]="selectable" [removable]="removable"
                    (removed)="remove(asset)">
                    {{ asset.id }}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input placeholder="Asset(s)" [matChipInputFor]="assetList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="add($event)" [(ngModel)]="tmpUser.assets" />
            </mat-chip-list>
        </mat-form-field>
    </div>
    <div class="row-three" *ngIf="!isNXTsoft && !isNXTsoftDev && !isNXTsoftOperations && !isNXTsoftSales ">
        <mat-form-field class="example-chip-list" style="width: 100%">
            <mat-chip-list #assetList aria-label="Fruit selection">
                <mat-chip *ngFor="let asset of assets" [selectable]="selectable" [removable]="removable"
                    (removed)="remove(asset)">
                    {{ asset.id }}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input placeholder="Asset(s)" [matChipInputFor]="assetList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="add($event)" [(ngModel)]="tmpUser.assets" />
            </mat-chip-list>
        </mat-form-field>
    </div>
</div>