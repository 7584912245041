import { NgModule } from '@angular/core';
import { CCMCChangePasswordComponent } from './change-password.component';
import { CCMCSharedModule } from '../../../../@ccmc/shared.module';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../../auth/auth.guard';
import { CCMCDirectivesModule } from '../../../../@ccmc/directives/directives';

const routes: Routes = [
  {
    path: 'change-password',
    component: CCMCChangePasswordComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    CCMCSharedModule,
    RouterModule.forChild(routes),
    CCMCDirectivesModule
  ],
  declarations: [CCMCChangePasswordComponent],
  exports: [CCMCChangePasswordComponent]
})
export class CCMCChangePasswordModule {}
