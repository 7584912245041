import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlaTranslatorsComponent } from './gla-translators.component';
import { AuthGuard } from '../../../auth/auth.guard';
import { Routes, RouterModule } from '@angular/router';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { CCMCSharedModule } from '../../../../@ccmc/shared.module';
import { DeactivateGlaTranslators } from './deactivate-gla-translators';
import { GlaTranslatorsTranslatorsComponent } from './gla-translators-translators/gla-translators-translators.component';

const routes: Routes = [
  {
    path: 'gla/translators',
    component: GlaTranslatorsComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    canDeactivate: [DeactivateGlaTranslators]
  }
];

@NgModule({
  declarations: [GlaTranslatorsComponent, GlaTranslatorsTranslatorsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CCMCSharedModule,
    KeyboardShortcutsModule.forRoot()
  ],
  providers: [DeactivateGlaTranslators]
})
export class GlaTranslatorsModule {}
