import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { CCMCPerfectScrollbarDirective } from '../../../@ccmc/directives/ccmc-perfect-scrollbar/ccmc-perfect-scrollbar.directive';
import { CCMCSidebarService } from '../../../@ccmc/components/sidebar/sidebar.service';

import {
  docConnectorNavigation,
  navigation
} from '../../navigation/navigation';
import { adminNavigation } from '../../navigation/navigation';
import { glaNavigation } from '../../navigation/navigation';
import { CCMCNavigationService } from '../../../@ccmc/components/navigation/navigation.service';
import { CCMCSidebarComponent } from '../../../@ccmc/components/sidebar/sidebar.component';
import { Navigation } from '../../../@ccmc/models/navigation.model';
import { AssetService } from 'src/@ccmc/services/asset.service';

@Component({
  selector: 'ccmc-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CCMCNavbarComponent implements OnInit, OnDestroy {
  private ccmcPerfectScrollbar: CCMCPerfectScrollbarDirective;

  @ViewChild(CCMCPerfectScrollbarDirective) set directive(
    theDirective: CCMCPerfectScrollbarDirective
  ) {
    if (!theDirective) {
      return;
    }

    this.ccmcPerfectScrollbar = theDirective;

    this.navigationServiceWatcher =
      this.navigationService.onItemCollapseToggled.subscribe(() => {
        this.ccmcPerfectScrollbarUpdateTimeout = setTimeout(() => {
          this.ccmcPerfectScrollbar.update();
        }, 310);
      });
  }

  @Input() layout;
  navigation: Navigation[];
  navigationServiceWatcher: Subscription;
  ccmcPerfectScrollbarUpdateTimeout: any;
  badgeCountSubscription: Subscription;
  navigationTypeSub: Subscription;

  constructor(
    private sidebarService: CCMCSidebarService,
    private navigationService: CCMCNavigationService,
    private router: Router,
    private assetService: AssetService
  ) {
    // Navigation data
    this.navigation = navigation;

    // Updates the badgeCount
    this.badgeCountSubscription = this.navigationService.badgeCount.subscribe(
      count => {
        this.navigation[1].children[0].badge!.title = count;
      }
    );

    // Default layout
    this.layout = 'vertical';
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.sidebarService.getSidebar('navbar')) {
          this.sidebarService.getSidebar('navbar').close();
        }
      }
    });

    // Watch for on nav change events to switch the view
    this.navigationTypeSub = this.navigationService.onNavChange.subscribe(
      result => {
        if (result) {
          if (result === 'default') {
            let currentProduct = this.assetService.getSelectedProduct();
            if (currentProduct) {
              if (currentProduct.includes('general_ledger')) {
                this.navigation = glaNavigation;
              } else if (currentProduct.includes('doc_connector')) {
                this.navigation = docConnectorNavigation;
              } else {
                this.navigation = navigation;
              }
            }
          } else {
            this.navigation = adminNavigation;
          }
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.ccmcPerfectScrollbarUpdateTimeout) {
      clearTimeout(this.ccmcPerfectScrollbarUpdateTimeout);
    }

    if (this.navigationServiceWatcher) {
      this.navigationServiceWatcher.unsubscribe();
    }

    if (this.badgeCountSubscription) {
      this.badgeCountSubscription.unsubscribe();
    }

    if (this.navigationTypeSub) {
      this.navigationTypeSub.unsubscribe();
    }
  }

  toggleSidebarOpened() {
    this.sidebarService.getSidebar('navbar').toggleOpen();
  }

  toggleSidebarFolded() {
    this.sidebarService.getSidebar('navbar').toggleFold();
  }
}
