<div
  style="
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    padding: 10px;
    width: 600px;
  "
>
  <div
    style="display: flex; flex-direction: row; justify-content: space-between"
  >
    <div>
      <h1>Edit User Assets</h1>
    </div>
    <div>
      <h1>
        <i class="material-icons noselect" (click)="closeDialog()"> close </i>
      </h1>
    </div>
  </div>

  <div>
    <mat-form-field style="width: 100%">
      <input
        matInput
        placeholder="Username (Case Sensative)"
        [(ngModel)]="username"
      />
    </mat-form-field>
  </div>
  <div *ngIf="canEditAssets">
    <mat-form-field style="width: 100%">
      <mat-chip-list #chipList>
        <mat-chip
          *ngFor="let asset of assetsArray"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="remove(asset)"
        >
          {{ asset }}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          placeholder="Asset(s)"
          #assetsInput
          [formControl]="assetsCtrl"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)"
        />
      </mat-chip-list>
    </mat-form-field>
  </div>
  <div>
    <button
      mat-raised-button
      matTooltip="Get user assets."
      matTooltipClass="toolTipStyle"
      color="accent"
      (click)="getUsersAssets()"
    >
      Get Assets
    </button>
    <button
      mat-raised-button
      matTooltip="Update user assets."
      matTooltipClass="toolTipStyle"
      color="accent"
      (click)="updateAssets()"
      *ngIf="canEditAssets"
      style="margin-left: 50px"
    >
      Update Assets
    </button>
  </div>
</div>
