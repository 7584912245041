<div class="force-change-password">
  <h2 class="force-change-password__title">New Password</h2>
  <span class="force-change-password__sub-title"
    >Please enter your new password below.</span
  >
  <form
    class="force-change-password__form"
    [formGroup]="forceChangePasswordForm"
    (ngSubmit)="changePassword()"
    ccmcPasswordMatcher
  >
    <div
      class="force-change-password__form__new-password force-change-password__form--password-container"
    >
      <mat-form-field>
        <input
          matInput
          #newPassword
          [type]="newPasswordType"
          class="force-change-password__form__new-password--input force-change-password__form--input"
          formControlName="newPassword"
          placeholder="New Password"
          name="newPassword"
        />
        <mat-error
          *ngIf="
            forceChangePasswordForm.get('newPassword')?.touched &&
            forceChangePasswordForm.get('newPassword')?.errors?.required
          "
        >
          new password is required
        </mat-error>
      </mat-form-field>
      <button type="button" (click)="passwordVisibility(newPassword)">
        <mat-icon class="force-change-password__form--visibility s-20">
          {{ newPasswordVisibility }}
        </mat-icon>
      </button>
    </div>

    <div
      class="force-change-password__form__conf-new-password force-change-password__form--password-container"
    >
      <mat-form-field>
        <input
          matInput
          #confNewPassword
          [type]="confNewPasswordType"
          class="change__password--form__new-password--input change__password--form--input"
          name="confNewPassword"
          formControlName="confNewPassword"
          placeholder="Confirm New Password"
          [errorStateMatcher]="confirmValidParentMatcher"
        />
        <mat-error
          *ngIf="
            forceChangePasswordForm.get('confNewPassword')?.touched &&
            forceChangePasswordForm.get('confNewPassword')?.errors?.required
          "
        >
          confirm password is required
        </mat-error>
        <mat-error>
          {{ errors.confNewPassword }}
        </mat-error>
      </mat-form-field>
      <button type="button" (click)="passwordVisibility(confNewPassword)">
        <mat-icon class="force-change-password__form--visibility s-20">
          {{ confNewPasswordVisibility }}
        </mat-icon>
      </button>
    </div>

    <div class="force-change-password__button-container">
      <button
        [disabled]="
          forceChangePasswordForm.get('newPassword')?.value !==
            forceChangePasswordForm.get('confNewPassword')?.value ||
          forceChangePasswordForm.invalid
        "
        [class.force-change-password__button--disabled]="
          forceChangePasswordForm.get('newPassword')?.value !==
            forceChangePasswordForm.get('confNewPassword')?.value ||
          forceChangePasswordForm.invalid
        "
        type="submit"
        mat-button
        class="force-change-password__button"
      >
        Change Password
      </button>
    </div>
  </form>
</div>
