<div style="width: 350px; background-color: e1e1e1">
    <h1>Create A New Set</h1>
    <div *ngIf="newSet">
        <mat-form-field style="width: 100%">
            <input matInput placeholder="Set Name" [(ngModel)]="newSet.setName" style="width: 100%" (keydown.enter)="create()" />
        </mat-form-field>
    </div>
    <div style="display: flex; justify-content: space-between">
        <button mat-raised-button matTooltip="Close Dialog" matTooltipClass="toolTipStyle" (click)="closeDialog()" style="text-align: left; max-width: 75px">
      Cancel
    </button>
        <button mat-raised-button matTooltip="Create Set. Update the transactions next." matTooltipClass="toolTipStyle" color="accent" (click)="create()" style="text-align: right; max-width: 125px">
      Create Set
    </button>
    </div>
</div>