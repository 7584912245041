import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog
} from '@angular/material/dialog';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DocumentConnectorService } from 'src/@ccmc/services/doc-connector.service';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { CCMCConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';

@Component({
  selector: 'app-add-new-snd-meta',
  templateUrl: './add-new-snd-meta.component.html',
  styleUrls: ['./add-new-snd-meta.component.scss']
})
export class AddNewSndMetaComponent implements OnInit {
  tempSNDMeta: any;
  tempDocument: any;
  dcDocumentSub: Subscription;
  globalMetas: any;
  destinations: any;
  unsubscribe: Subject<any> = new Subject();
  sndGlobalMeta: any;
  currentDestination: any;
  editedFlag = false;
  @ViewChild('nameInput', { static: true }) nameInputEl: ElementRef;
  @ViewChild('indexInput', { static: true }) indexInputEl: ElementRef;
  @ViewChild('valueInput', { static: true }) valueInputEl: ElementRef;
  newSNDMetaIndex = 0;
  constructor(
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    private dcService: DocumentConnectorService,
    private selectedFieldService: CCMCSelectedFieldService,
    public dialogRef: MatDialogRef<AddNewSndMetaComponent>,
    private fieldEditedService: FieldEditedService,
    private snackBarService: SnackbarService,
    private dialog: MatDialog
  ) {

  }

  ngOnInit(): void {
    this.cInstance()
    this.getData();
  }
  cInstance() {
    this.currentDestination = this.inputData;
    this.newSNDMetaIndex = 0;
    for (let meta of this.currentDestination.metas) {
      if (meta.index > this.newSNDMetaIndex) {
        this.newSNDMetaIndex = meta.index;
      }
    }
    this.newSNDMetaIndex++;
  }


  getData() {
    // Subscribe to the document and grab the support values
    this.dcDocumentSub = this.dcService.dcDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(document => {
        if (document) {
          this.tempDocument = document;
          this.globalMetas = document.globalMetas;
          this.destinations = document.destinations;
          this.tempSNDMeta = {
            name: '',
            index: this.newSNDMetaIndex,
            value: '',
            path: ''
          };
        }
      });
  }

  async createSNDMeta() {
    if (this.checkForDuplicates()) {
      return;
    }
    const checkCorrectMetaIndexResponse =
      await this.checkCorrectGlobalMetaIndexs();
    console.log(
      'Check Correct Meta Index Response',
      checkCorrectMetaIndexResponse
    );
    if (checkCorrectMetaIndexResponse) {
      const confirmDialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
        data: `Duplicate index found, do you want to renumber your Global Metas starting at ${
          this.tempSNDMeta.index + 1
        }?`
      });
      confirmDialogRef.afterClosed().subscribe(data => {
        if (data) {
          // Reorder global metas here
          this.reorderGlobalMetas();
          const successDialogRef = this.dialog.open(SuccessDialogComponent, {
            data: {
              title: 'Success',
              message: 'Successfully renumbered Global Metas'
            }
          });
          successDialogRef.afterClosed().subscribe(data2 => {
            this.currentDestination.edited = true;
            this.fieldEditedService.losEdited.next(true);
            this.currentDestination.metas.push(this.tempSNDMeta);
            this.dialogRef.close(true);
          });
        }
      });
    } else {
      this.currentDestination.edited = true;
      this.fieldEditedService.losEdited.next(true);
      this.currentDestination.metas.push(this.tempSNDMeta);
      this.dialogRef.close(true);
    }
  }

  checkForDuplicates() {
    for (let tempMeta of this.currentDestination.metas) {
      if (this.tempSNDMeta.index === tempMeta.index) {
        const errorDialogRef = this.dialog.open(ErrorDialogComponent, {
          data: {
            title: 'Duplicates',
            message: 'Duplicate index found.'
          }
        });
        return true;
      }
    }
    return false;
  }

  async checkCorrectGlobalMetaIndexs() {
    // prettier-ignore
    return new Promise((resolve) => {
      console.log('Global Metas', this.globalMetas);
      for (let globalMeta of this.globalMetas) {
        if (globalMeta.index === this.tempSNDMeta.index) {
          resolve(true);
        }
      }
      resolve(false);
    });
  }

  reorderGlobalMetas() {
    let startingGlobalMetaIndex = 0;
    // Check destination current metas for highest index
    for (let globalMeta of this.currentDestination.metas) {
      if (globalMeta.index >= startingGlobalMetaIndex) {
        startingGlobalMetaIndex = globalMeta.index;
      }
    }
    // Handle case if new snd meta index is greater than the starting reorder index
    if (startingGlobalMetaIndex < this.tempSNDMeta.index) {
      startingGlobalMetaIndex = this.tempSNDMeta.index;
    }
    // Set next Index
    startingGlobalMetaIndex++;
    console.log('Max Index found in destination', startingGlobalMetaIndex);
    // loop through global metas and update their index
    const tempGlobalMetas = this.globalMetas.sort(function (a: any, b: any) {
      return a.index - b.index;
    });
    // Set index for each meta
    for (let [index, tempGlobalMeta] of tempGlobalMetas.entries()) {
      tempGlobalMeta.index = startingGlobalMetaIndex + index;
    }
    console.log('New Global Metas', tempGlobalMetas);
  }
}
