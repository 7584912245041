import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  NgZone
} from '@angular/core';
import { CcmcApiService } from '../../services/ccmc-api.service';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

/**
 * Asset Interface
 *
 * @export
 * @interface Asset
 */
export interface Asset {
  /**
   * Asset ID
   *
   * @type {string}
   * @memberof Asset
   */
  id: string;
}

/**
 * Select item class
 *
 * @export
 * @class SelectItem
 */
export class SelectItem {
  /**
   * Value
   *
   * @type {string}
   * @memberof SelectItem
   */
  value: string;

  /**
   * View Value
   *
   * @type {string}
   * @memberof SelectItem
   */
  viewValue: string;
}

/**
 * The AddAssetToUserDialogComponent lets the user add an asset to an existing user
 *
 * @export
 * @class AddAssetToUserDialogComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-add-asset-to-user-dialog',
  templateUrl: './add-asset-to-user-dialog.component.html',
  styleUrls: ['./add-asset-to-user-dialog.component.scss']
})
export class AddAssetToUserDialogComponent implements OnInit {
  /**
   * Asset list
   *
   * @type {Asset[]}
   * @memberof AddAssetToUserDialogComponent
   */
  assets: Asset[] = [];

  /**
   * Asset string reference
   *
   * @type {ElementRef}
   * @memberof AddAssetToUserDialogComponent
   */
  @ViewChild('assetString', { static: true }) filterSearchEl: ElementRef;

  /**
   * Environments
   *
   * @type {SelectItem[]}
   * @memberof AddAssetToUserDialogComponent
   */
  environments: SelectItem[] = [
    { value: 'prod', viewValue: 'Prod' },
    { value: 'test', viewValue: 'Test' }
  ];

  /**
   * Asset Environment
   *
   * @memberof AddAssetToUserDialogComponent
   */
  assetEnvironment = 'test';

  /**
   * Keywords to trigger chip
   *
   * @type {number[]}
   * @memberof AddAssetToUserDialogComponent
   */
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  /**
   * Selectable chip
   *
   * @memberof AddAssetToUserDialogComponent
   */
  selectable = true;

  /**
   * Removable chip
   *
   * @memberof AddAssetToUserDialogComponent
   */
  removable = true;

  /**
   * Add on blur chip
   *
   * @memberof AddAssetToUserDialogComponent
   */
  addOnBlur = true;

  /**
   * Creates an instance of AddAssetToUserDialogComponent.
   * @param {CcmcApiService} ccmcAPIService
   * @param {NgZone} zone
   * @param {MatSnackBar} snackBar
   * @param {MatDialogRef<AddAssetToUserDialogComponent>} dialogRef
   * @memberof AddAssetToUserDialogComponent
   */
  constructor(
    private ccmcAPIService: CcmcApiService,
    private zone: NgZone,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<AddAssetToUserDialogComponent>
  ) {}

  /**
   * initialize component
   *
   * @memberof AddAssetToUserDialogComponent
   */
  ngOnInit() {}

  /**
   * Add asset to asset list
   *
   * @param {MatChipInputEvent} event
   * @memberof AddAssetToUserDialogComponent
   */
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our asset
    if ((value || '').trim()) {
      this.assets.push({ id: value.trim() });
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  /**
   * Remove asset from chip list
   *
   * @param {Asset} asset
   * @memberof AddAssetToUserDialogComponent
   */
  remove(asset: Asset): void {
    const index = this.assets.indexOf(asset);

    if (index >= 0) {
      this.assets.splice(index, 1);
    }
  }

  /**
   * Add asset to user
   *
   * @memberof AddAssetToUserDialogComponent
   */
  addAsset() {
    let tmpAsset = '';
    for (let i = 0; i < this.assets.length; i++) {
      if (i === 0) {
        tmpAsset = this.assets[i].id;
      } else {
        tmpAsset = tmpAsset + ',' + this.assets[i].id;
      }
    }
    this.ccmcAPIService.addAssetToUser(tmpAsset).subscribe(res => {
      console.log(res);
      this.openSnackBar(res.toString(), 'Okay');
      this.closeDialog();
    });
  }

  /**
   * Opens snackbar with given message and action
   *
   * @param {string} message
   * @param {string} action
   * @memberof AddAssetToUserDialogComponent
   */
  openSnackBar(message: string, action: string) {
    this.zone.run(() => {
      setTimeout(() => {
        this.snackBar.open(message, action, {
          duration: 5000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }, 0);
    });
  }

  /**
   * Close the AddAssetTouserDialogComponent
   *
   * @memberof AddAssetToUserDialogComponent
   */
  closeDialog() {
    this.dialogRef.close();
  }
}
