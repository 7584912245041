import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestChangeComponent } from './request-change.component';

import { CCMCSharedModule } from '../../../../@ccmc/shared.module';

import { AuthGuard } from '../../../auth/auth.guard';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RouterModule, Routes } from '@angular/router';
import { SelectedChangeComponent } from './selected-change/selected-change.component';
import { BrowserModule } from '@angular/platform-browser';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { LoanTypesComponent } from './loan-types/loan-types.component';

@NgModule({
  declarations: [RequestChangeComponent, SelectedChangeComponent, LoanTypesComponent],
  imports: [
    CommonModule,
    CCMCSharedModule,
    KeyboardShortcutsModule.forRoot(),
    // tslint:disable-next-line: deprecation
    ScrollingModule,
    BrowserModule,
    MatInputModule,
    MatTableModule,
    MatSortModule
  ],
  exports: [RequestChangeComponent, SelectedChangeComponent, LoanTypesComponent]
})
export class RequestChangeModule {}
