import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CCMCFieldHelpDialogComponent } from '../../../@ccmc/components/field-help-dialog/field-help-dialog.component';

@NgModule({
    declarations: [
        CCMCFieldHelpDialogComponent
    ],
    imports: [
        MatDialogModule,
        MatButtonModule
    ],
    entryComponents: [
        CCMCFieldHelpDialogComponent
    ],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} }
    ]
})
export class CCMCFieldHelpDialogModule {
}
