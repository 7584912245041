<ng-container *ngIf="
    item.visible === true ||
    (ccmcApiService.mapping !== undefined &&
      ccmcApiService.transactions[item.transactionIndex] &&
      ccmcApiService.transactions[item.transactionIndex].display === true)
  ">
    <!-- item.url -->
    <a class="nav-link" *ngIf="item.url && !item.function" [routerLink]="[item.url]" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: item.exactMatch || false }" matRipple>
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.function -->
    <span class="nav-link" *ngIf="!item.url && item.function" (click)="item.function()" matRipple>
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </span>

    <!-- item.url && item.function -->
    <a class="nav-link" *ngIf="item.url && item.function" (click)="item.function()" [routerLink]="[item.url]" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: item.exactMatch || false }" matRipple>
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.loan -->
    <span class="nav-link" *ngIf="item.loan && item.badge.title">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </span>

    <a style="cursor: default" class="nav-link" *ngIf="item.desc">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <ng-template #itemContent>
        <mat-icon class="nav-link-icon" *ngIf="item.icon">{{ item.icon }}</mat-icon>
        <span class="nav-link-title truncate-text" [translate]="item.translate" *ngIf="!item.additionalCost || router.url.includes(item.id)">{{ item.title }}</span
    >
    <span
      class="nav-link-title truncate-text additional-cost-transaction"
      [translate]="item.translate"
      *ngIf="item.additionalCost && !router.url.includes(item.id)"
      >{{ item.title }}</span
    >
    <span
      class="nav-link-description truncate-text"
      *ngIf="item.descValue"
      [translate]="item.descValue"
    >
      <button
        style="color: currentColor; cursor: copy"
        [cdkCopyToClipboard]="item.descValue"
      >
        {{ item.descValue | underscoreToSpace }}
      </button>
    </span>
        <span class="nav-link-badge" *ngIf="item.badge" [translate]="item.badge.translate" [ngStyle]="{ 'background-color': item.badge.bg, color: item.badge.fg }">
      {{ item.badge.title }}
    </span>
    </ng-template>
</ng-container>