import { Injectable, NgZone } from '@angular/core';
import { navigation } from 'src/app/navigation/navigation';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { CcmcApiService } from './ccmc-api.service';
import { Router } from '@angular/router';
import { SpinnerService } from './spinner.service';
import { ActiveLosService } from './active-los.service';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { AssetService } from './asset.service';
import { filter, catchError } from 'rxjs/operators';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { SuccessDialogComponent } from '../components/success-dialog/success-dialog.component';
import { environment } from 'src/environments/environment';
import { CaptureDataService } from './capture-data.service';
import { AmplifyService } from './amplify.service';
import { GlobalSearchService } from './global-search.service';
import { TargetResponseDialogComponent } from '../components/target-response-dialog/target-response-dialog.component';
import * as AWS from 'aws-sdk';
import { APPCONSTANTS } from 'src/app/app.constants';
const { Buffer } = require('buffer');
@Injectable({
  providedIn: 'root'
})
export class UltraDataService {
  dynamicNav: any = navigation;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(
    private dialog: MatDialog,
    private ccmcApiService: CcmcApiService,
    public router: Router,
    private spinnerService: SpinnerService,
    private activeLosService: ActiveLosService,
    private zone: NgZone,
    private http: HttpClient,
    private customErrorHandlerService: CustomErrorHandlerService,
    private assetService: AssetService,
    private amplifyService: AmplifyService,
    private captureDataService: CaptureDataService,
    private globalSearchService: GlobalSearchService
  ) {
    this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, PUT'
    );
    this.httpOptions.headers.append('Accept', 'application/json');
  }
  async send() {
    //   turn spinner on
    this.spinnerService.setShowSpinner(true);
    // init username
    let userName = await this.amplifyService.getUserName();
    // init export object
    const exportObject = {
      content: {
        mapping: this.ccmcApiService.mapping,
        configurations: this.ccmcApiService.configurations,
        transactions: this.ccmcApiService.transactions
      },
      user: userName,
      assetID: this.assetService.getSelectedAssetId()
    };
    // Call export
    (await this.export(exportObject)).subscribe(async (result: any) => {
      const exportMessagesResponse = result;
      if (exportMessagesResponse.error) {
        const errorMessage = {
          statusMessage: "Ground Control failed to receive a response back from the communicator",
          statusFlag: false
        };
        const dialogRef2 = this.zone.run(() => {
          this.dialog.open(TargetResponseDialogComponent, {
            data: errorMessage
          });
        });
        await this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: this.getLoanNumber(),
          statusMessage: exportMessagesResponse.error,
          statusFlag: false
        });
      }
      if (exportMessagesResponse.statusFlag === true) {
        let loanNumber;
        if (exportMessagesResponse.loanNumber) {
          loanNumber = exportMessagesResponse.loanNumber;
        } else {
          loanNumber = this.getLoanNumber();
        }
        const resultMessage = {
          statusMessage: exportMessagesResponse.statusMessage,
          loanNumber: loanNumber,
          statusFlag: true
        };

        await this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: loanNumber,
          statusMessage: exportMessagesResponse.statusMessage,
          statusFlag: exportMessagesResponse.statusFlag
        });

        this.captureDataService.uploadS3File(this.ccmcApiService.mapping);
        this.activeLosService.activeLosService.loanBooked(
          exportMessagesResponse,
          JSON.parse(JSON.stringify(this.ccmcApiService.configurations))
        );
        this.ccmcApiService.mapping = undefined;
        this.ccmcApiService.coreSettings = undefined;
        this.ccmcApiService.conditions = undefined;
        this.ccmcApiService.mapping = undefined;
        this.ccmcApiService.supportValues = undefined;
        this.ccmcApiService.search = undefined;
        this.ccmcApiService.transactions = undefined;
        this.ccmcApiService.configurations = undefined;
        if (this.dynamicNav[1].children[1]) {
          this.dynamicNav[1].children[1].children = [];
        }
        this.dynamicNav[1].children[0].badge.title = undefined;
        const dialogRef = this.dialog.open(TargetResponseDialogComponent, {
          data: resultMessage
        });
        dialogRef.afterClosed().subscribe(result => {
          if (this.activeLosService.activeLos === 'encompass' || this.activeLosService.activeLos === 'bytepro') {
            this.router.navigate(['loan-lookup']);
          } else {
            this.router.navigate(['file-selector']);
          }
        });
      }
      if (exportMessagesResponse.statusFlag === false) {
        if (exportMessagesResponse.content && exportMessagesResponse.content.mapping) {
          // Retrieve Validation From existing mapping object
          let existingMappingCopy = JSON.parse(
            JSON.stringify(this.ccmcApiService.mapping)
          );
          for (let mappingItem of exportMessagesResponse.content.mapping) {
            let existingMappingIndex = existingMappingCopy.findIndex(
              (obj: any) => obj.fieldID === mappingItem.fieldID
            );
            if (
              existingMappingIndex > -1 &&
              existingMappingCopy[existingMappingIndex].validation
            ) {
              mappingItem.validation =
                existingMappingCopy[existingMappingIndex].validation;
            }
          }
          this.ccmcApiService.mapping = exportMessagesResponse.content.mapping;
        }
        if (exportMessagesResponse.content && exportMessagesResponse.content.transactions) {
          this.ccmcApiService.transactions = exportMessagesResponse.content.transactions;
        }

        await this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: this.getLoanNumber(),
          statusMessage: exportMessagesResponse.statusMessage,
          statusFlag: exportMessagesResponse.statusFlag
        });
        this.globalSearchService.initData();
        const errorMessage = {
          statusMessage: exportMessagesResponse.statusMessage,
          statusFlag: false
        };
        const dialogRef = this.dialog.open(TargetResponseDialogComponent, {
          data: errorMessage
        });
      }
    });
  }
  customerSearch(borrower: any, callback: any) {
    // init username
    let userName = this.amplifyService.username;
    // init request
    const request = {
      content: borrower,
      assetID: this.assetService.getSelectedAssetId(),
      user: userName
    };
    console.log('Request: \n', JSON.stringify(request));
    request.content.cognitoID = this.amplifyService.getCognitoId();
    // call search customer
    this.searchCustomer(request).subscribe(res => {
      console.log('Customer search response: ', res);
      const exportMessagesResponse = JSON.parse(JSON.stringify(res));
      console.log(exportMessagesResponse);
      if (exportMessagesResponse.error) {
        const errorMessage = {
          message: JSON.stringify(exportMessagesResponse.error.message),
          title: 'UltraData Result'
        };
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      }
      if (exportMessagesResponse.statusFlag === true) {
        //   turn spinner off
        this.spinnerService.setShowSpinner(false);
        const payload = exportMessagesResponse.content;
        if (payload.length > 0) {
          const searchResult = payload;
          console.log(searchResult);
          callback(searchResult);
        } else {
          const noResult = [
            {
              firstName: 'No Result',
              lastName: '',
              taxID: '',
              CIF: '',
              address: ''
            }
          ];
          const searchResult = noResult;
          console.log(searchResult);
          callback(searchResult);
        }
      } else if (exportMessagesResponse.statusFlag === false) {
        const errorMessage = {
          message: exportMessagesResponse.statusMessage,
          title: 'Search Error'
        };
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      }
    });
  }
  autoCustomerSearch(borrower: any, callback: any) {
    let userName = this.amplifyService.username;
    const request = {
      content: borrower,
      assetID: this.assetService.getSelectedAssetId(),
      user: userName
    };
    console.log('Request: \n', JSON.stringify(request));
    request.content.cognitoID = this.amplifyService.getCognitoId();
    this.searchCustomer(request).subscribe(res => {
      console.log('Customer search response: ', res);
      const exportMessagesResponse = JSON.parse(JSON.stringify(res));
      console.log(exportMessagesResponse);
      if (exportMessagesResponse.error) {
        const errorMessage = {
          message: JSON.stringify(exportMessagesResponse.error.message),
          title: 'UltraData Result'
        };
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      }
      if (exportMessagesResponse.statusFlag === true) {
        this.spinnerService.setShowSpinner(false);
        const payload = exportMessagesResponse.content;
        if (payload.length > 0) {
          const searchResult = payload;
          console.log(searchResult);
          callback(searchResult);
        } else {
          const noResult = [
            {
              firstName: 'No Result',
              lastName: '',
              taxID: '',
              CIF: '',
              address: ''
            }
          ];
          const searchResult = noResult;
          console.log(searchResult);
          callback(searchResult);
        }
      } else if (exportMessagesResponse.statusFlag === false) {
        this.spinnerService.setShowSpinner(false);
        if (
          exportMessagesResponse.statusMessage
            .toLowerCase()
            .includes('no customer record found')
        ) {
          const noResult = [
            {
              firstName: 'No Result',
              lastName: '',
              taxID: '',
              CIF: '',
              address: ''
            }
          ];
          const searchResult = noResult;
          console.log(searchResult);
          callback(searchResult);
        } else {
          const errorMessage = {
            message: exportMessagesResponse.statusMessage,
            title: 'Search Error'
          };
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
          callback(exportMessagesResponse);
        }
      }
    });
  }
  assignBorrowers(party: any) {
    for (let i = 0; i < party.length; i++) {
      const CIFFields = party[i].CIFFields.split(',');
      CIFFields.forEach((cf: any) => {
        const cifIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) => f.fieldID === cf.trim()
        );
        this.ccmcApiService.mapping[cifIndex].fieldValue = party[i].CIF;
      });
    }
  }
  searchCustomer(params: any) {
    console.log(params);
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/finastra/ultradata/search-customer`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }
  // export(params) {
  //   
  //   console.log(params);
  //   return this.http
  //     .post(
  //       `${baseUrl}/finastra/ultradata/export-messages`,
  //       params,
  //       this.httpOptions
  //     )
  //     .pipe(catchError(this.customErrorHandlerService.handleError));
  // }
  export(params: any) {
    
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/finastra/ultradata/export-messages`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }
  getLoanNumber() {
    console.log('get Loan number');
    let loanFieldIndex;
    loanFieldIndex = this.ccmcApiService.mapping.findIndex(
      (obj: any) => obj.fieldID === 'LC01_0002'
    );
    if (loanFieldIndex > -1) {
      // Return loan number
      console.log(
        'loan number',
        this.ccmcApiService.mapping[loanFieldIndex].fieldValue
      );
      return this.ccmcApiService.mapping[loanFieldIndex].fieldValue;
    }
  }
}
