import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts dateTime from logs and gives back a cleaner string to view
 *
 * @export
 * @class PrettyDateTimePipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'unixToDate'
})
export class UnixtoDatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    var date = new Date(value);
    // Month
    var month = date.getMonth() + 1;
    // Day of the month
    var day = date.getDate();
    // Year
    var year = date.getFullYear();
    // Hours part from the timestamp
    var hours = date.getHours();
    // Minutes part from the timestamp
    var minutes = '0' + date.getMinutes();
    // Seconds part from the timestamp
    var seconds = '0' + date.getSeconds();

    // Will display time in 10:30:23 format
    var formattedTime =
      month +
      '/' +
      day +
      '/' +
      year +
      ' ' +
      hours +
      ':' +
      minutes.substr(-2) +
      ':' +
      seconds.substr(-2);
    return formattedTime;
  }
}
