import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-send-doc-connector-dialog',
  templateUrl: './send-doc-connector-dialog.component.html',
  styleUrls: ['./send-doc-connector-dialog.component.scss']
})
export class SendDocConnectorDialogComponent implements OnInit {
  resetBeforeSend = false;
  constructor(
    public dialogRef: MatDialogRef<SendDocConnectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public loanNumber: any
  ) {}

  ngOnInit(): void {}
}
