import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddRemoveColumnsDialogComponent } from './add-remove-columns-dialog.component';
import { CCMCSharedModule } from '../../shared.module';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';

@NgModule({
  declarations: [AddRemoveColumnsDialogComponent],
  imports: [CommonModule, AppMaterialModule],
  entryComponents: [AddRemoveColumnsDialogComponent]
})
export class AddRemoveColumnsDialogModule {}
