import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActiveCoreService } from 'src/@ccmc/services/active-core.service';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
import { CCMCConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-suffix-search-dialog',
  templateUrl: './suffix-search-dialog.component.html',
  styleUrls: ['./suffix-search-dialog.component.scss']
})
export class SuffixSearchDialogComponent implements OnInit {
  showSpinner: boolean;
  private spinnerSub: Subscription;
  suffixDataSource: any;
  diplayedSuffixsColumns = ['description', 'suffix'];
  selectedRow: any;
  showLoanSuffixes = true;
  showShareSuffixes = true;
  suffixArray: any[] = [];
  newAccountSuffix = '';
  unsubscribe: Subject<any> = new Subject();
  constructor(
    @Inject(MAT_DIALOG_DATA) public party: any,
    private dialogRef: MatDialogRef<SuffixSearchDialogComponent>,
    private spinnerService: SpinnerService,
    private ccmcApiService: CcmcApiService,
    private activeCoreSerivce: ActiveCoreService,
    private cd: ChangeDetectorRef,
    private snackbarService: SnackbarService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.spinnerService.setShowSpinner(true);
    console.log(this.party);
    // Call search when data ready
    this.search();
    this.getSpinnerStatus();
  }

  /**
   * Gets loading spinner status
   *
   * @memberof SuffixSearchDialogComponent
   */
  getSpinnerStatus() {
    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        this.showSpinner = spinner;
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  /**
   * Suffix Search
   *
   * @memberof SuffixSearchDialogComponent
   */
  search() {
    console.log(this.party);
    // Set configurations
    this.party.configurations = this.ccmcApiService.configurations;
    this.activeCoreSerivce.activeService.suffixSearch(
      this.party,
      (result: any) => {
        this.spinnerService.setShowSpinner(false);
        // init suffix array used to display all suffixes
        this.suffixArray = [];
        console.log('search result: ', result);
        // If result and success
        if (result && result.statusFlag) {
          this.newAccountSuffix = 'L00';
          // push loan suffixes
          for (let loanSuffix of result.suffixes.loans) {
            // Create json object compatible to the table
            // Add flag for loan
            let tempSuffix = {
              description: loanSuffix.loanDescription,
              suffix: loanSuffix.loanSuffix,
              suffixType: 'loan'
            };
            if (tempSuffix.suffixType === 'loan') {
              this.newAccountSuffix = this.newAccountSuffix.slice(1,3) < tempSuffix.suffix.slice(1,3) ? tempSuffix.suffix : this.newAccountSuffix;
            }
            // push to table array
            this.suffixArray.push(tempSuffix);
          }
          // push share suffixes
          for (let shareSuffix of result.suffixes.shares) {
            // Create json object compatible to the table
            // Add flag for share
            let tempSuffix = {
              description: shareSuffix.shareDescription,
              suffix: shareSuffix.shareSuffix,
              suffixType: 'share'
            };
            // Push to table array
            this.suffixArray.push(tempSuffix);
          }
          console.log('Suffix Array ',this.suffixArray);
          if (Number(this.newAccountSuffix.slice(1,3)) < 9) {
            this.newAccountSuffix = 'L0' + (Number(this.newAccountSuffix.slice(1,3)) + 1);
          } else {
            this.newAccountSuffix = 'L' + (Number(this.newAccountSuffix.slice(1,3)) + 1);
          }
          // Set datasource to generate table data to the user
          this.suffixDataSource = new MatTableDataSource(this.suffixArray);
        } else {
          // error
        }
        // Init change detection
        this.cd.detectChanges();
      },
      (error: any) => {}
    );
  }

  /**
   * Select suffix
   *
   * @param {*} row
   * @memberof SuffixSearchDialogComponent
   */
  select(row: any) {
    // console.log(row);
    this.selectedRow = this.selectedRow !== row ? row : '';
    this.newAccountSuffix = '';
    if (this.selectedRow) {
      console.log(row);
      this.newAccountSuffix = row.suffix;
      // this.searchDisabled = false;
    }
    // console.log('selectedCif ', this.selectedCif);
  }

  filterSuffixes() {
    if (this.showLoanSuffixes && this.showShareSuffixes) {
      this.suffixDataSource = new MatTableDataSource(this.suffixArray);
    } else if (this.showLoanSuffixes && !this.showShareSuffixes) {
      const tempSuffixArray = this.suffixArray.filter(
        suffix => suffix.suffixType == 'loan'
      );
      this.suffixDataSource = new MatTableDataSource(tempSuffixArray);
    } else if (!this.showLoanSuffixes && this.showShareSuffixes) {
      const tempSuffixArray = this.suffixArray.filter(
        suffix => suffix.suffixType == 'share'
      );
      this.suffixDataSource = new MatTableDataSource(tempSuffixArray);
    } else {
      this.snackbarService.openSnackBar(
        'Error: Must have either loan or share options selected.',
        'Okay'
      );
      setTimeout(() => {
        this.suffixDataSource = new MatTableDataSource(this.suffixArray);
        this.showLoanSuffixes = true;
        this.showShareSuffixes = true;
      }, 500);
    }
  }

  continue() {
    if (this.newAccountSuffix == '') {
      const confirmDialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
        data: 'Do you want to continue assigning the suffix as blank?'
      });
      confirmDialogRef.afterClosed().subscribe(result => {
        if (!result) {
          return;
        } else {
          console.log('do assigning here');
          this.assignSuffix();
          this.closeDialog();
        }
      });
    } else {
      this.assignSuffix();
      this.closeDialog();
    }
  }

  assignSuffix() {
    const suffixFieldsArray = this.ccmcApiService.configurations.suffixFields.split(
      ','
    );
    console.log(suffixFieldsArray);
    for (let suffixField of suffixFieldsArray) {
      console.log(suffixField);
      if (suffixField.includes('%02d')) {
        // loop through available suffix field options
        const suffixFIeldIDArray = suffixField.split('%02d');
        console.log(suffixFIeldIDArray);
        let multiIndex = 1;
        let fieldIndex = 1;
        do {
          console.log('in loop');
          let formatedMultiIndex = '';
          if (multiIndex < 10) {
            formatedMultiIndex = '0' + multiIndex;
          } else {
            formatedMultiIndex = multiIndex.toString();
          }
          const tempFieldID =
            suffixFIeldIDArray[0] + formatedMultiIndex + suffixFIeldIDArray[1];
          let fieldIndex = this.ccmcApiService.mapping.findIndex(
            (map: any) => map.fieldID == tempFieldID
          );
          if (fieldIndex > -1) {
            this.ccmcApiService.mapping[
              fieldIndex
            ].fieldValue = this.newAccountSuffix;
            console.log(this.ccmcApiService.mapping[fieldIndex]);
          } else {
            break;
          }
          multiIndex++;
        } while (fieldIndex > -1);
      } else {
        // find index of suffixField
        const fieldIndex = this.ccmcApiService.mapping.findIndex(
          (map: any) => map.fieldID == suffixField
        );
        // If suffixField exists we update the field value
        if (fieldIndex > -1) {
          this.ccmcApiService.mapping[
            fieldIndex
          ].fieldValue = this.newAccountSuffix.slice(1,3);
        }
        console.log(this.ccmcApiService.mapping[fieldIndex]);
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
