<div style="position: relative; height: 100%; width: 100%">
  <div style="height: 100%; width: 100%">
    <div style="display: grid; grid-template-columns: 1fr 1fr; width: 100%">
      <mat-toolbar
        style="
          background-color: #9e9e9e;
          color: white;
          border-right: 1px solid rgba(0, 0, 0);
        "
        >Debits</mat-toolbar
      >
      <mat-toolbar style="background-color: #9e9e9e; color: white"
        >Credits</mat-toolbar
      >
    </div>
    <div
      style="
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        height: 86.5%;
      "
    >
      <div
        style="
          border-right: 1px solid rgba(0, 0, 0);
          width: 100%;
          height: 100%;
          overflow-y: auto;
        "
      >
        <table
          mat-table
          #table
          [dataSource]="debitsDataSource"
          class="table"
          CCMCPerfectScrollbar
          matSort
          style="max-height: 100%; overflow: scroll; padding-bottom: 15px"
        >
          <!-- Description Column -->
          <ng-container matColumnDef="description">
            <th
              class="cell-text"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
            >
              Debits Description
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.description }}
            </td>
          </ng-container>

          <!-- Amount Column -->
          <ng-container matColumnDef="amount">
            <th
              class="cell-text"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
            >
              Amount
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.amount | currency }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <div style="width: 100%; max-height: 100%; overflow: auto">
        <table
          mat-table
          #table
          [dataSource]="creditsDataSource"
          class="table"
          CCMCPerfectScrollbar
          matSort
          style="max-height: 100%; overflow: scroll; padding-bottom: 15px"
        >
          <!-- Description Column -->
          <ng-container matColumnDef="description">
            <th
              class="cell-text"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
            >
              Credits Description
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.description }}
            </td>
          </ng-container>

          <!-- Paid By Column -->
          <ng-container matColumnDef="amount">
            <th
              class="cell-text"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
            >
              Amount
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.amount.replace("$", '') | currency }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
    <div style="display: grid; grid-template-columns: 1fr 1fr; width: 100%">
      <div class="bottom">
        <mat-toolbar
          style="
            background-color: #9e9e9e;
            color: white;
            display: grid;
            border-right: 1px solid rgba(0, 0, 0);
            grid-template-columns: 3fr 1fr;
            column-gap: 5px;
            height: 50px;
            font-size: 14px;
            font-weight: 400;
            margin-right: 18px;
          "
        >
          <div style="text-align: right">Total Debits</div>
          <div style="height: 50px">
            <div
              style="
                background-color: white;
                color: black;
                border: 1px #424242 solid;
                height: 30px;
                margin-top: 7.5px;
              "
            >
              <div style="text-align: left; font-weight: 500">
                {{ debitsTotal | currency }}
              </div>
            </div>
          </div>
        </mat-toolbar>
      </div>
      <div class="bottom">
        <mat-toolbar
          style="
            background-color: #9e9e9e;
            color: white;
            display: grid;
            border-right: 1px solid rgba(0, 0, 0);
            grid-template-columns: 3fr 1fr;
            column-gap: 5px;
            height: 50px;
            font-size: 14px;
            font-weight: 400;
            margin-right: 18px;
          "
        >
          <div style="text-align: right">Total Credits</div>
          <div style="height: 50px">
            <div
              style="
                background-color: white;
                color: black;
                border: 1px #424242 solid;
                height: 30px;
                margin-top: 7.5px;
              "
            >
              <div style="text-align: left; font-weight: 500">
                {{ creditsTotal | currency }}
              </div>
            </div>
          </div>
        </mat-toolbar>
      </div>
    </div>
  </div>
  <!--Spinner-->
  <div class="spinner-container" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
  </div>
</div>
