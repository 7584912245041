<div id="body" fxLayout="column">
  <!--Header-->
  <div class="header mat-accent-bg p-16 p-sm-24" id="header-container">
    <div class="search mat-white-bg mat-elevation-z7" id="search-bar-container">
      <form class="search-form" style="width: 100%">
        <input
          class="search-bar ng-untouched ng-pristine ng-valid"
          matInput
          #searchbar
          (keyup)="applyFilter($any($event.target).value)"
          (keyup.esc)="clearSearchData()"
          type="search"
          autocomplete="off"
          placeholder="Filter Transactions..."
          style="border: none; color: black; flex-grow: 2; padding-left: 10px"
        />
      </form>
    </div>
  </div>
  <!--Core Table-->
  <div class="content" cdkScrollable>
    <mat-table
      #table
      [dataSource]="dataSource"
      class="mat-elevation-z8"
      style="
        padding-left: 12px;
        overflow-x: auto;
        padding-right: 12px;
        width: max-content;
      "
    >
      <ng-container
        *ngFor="let disCol of displayedColumns; let colIndex = index"
        matColumnDef="{{ disCol.name }}"
      >
        <mat-header-cell
          *matHeaderCellDef
          [style.min-width]="getMaxMinWidth(disCol.name)"
          [style.max-width]="getMaxMinWidth(disCol.name)"
          style="margin: 0px; padding: 0px"
        >
        {{ disCol.name }}
        <mat-icon
          *ngIf="headerErrorFlag[disCol.name] === true"
          style="cursor: pointer; color: red; margin-left: 5px;"
          aria-label="icon to tell if pinned"
          color="accent"
          matTooltip="Click for error details"
          (click)="errorDialog(disCol.name)"
          [style.display]="checkErrorDisplay(disCol.name)"
          >error</mat-icon
        >
        </mat-header-cell>

        <mat-cell
          *matCellDef="let element"
          style="
            margin: 0px;
            padding: 0px;
            height: 30px;
            font-size: 14px;
          "
          [style.max-width]="getMaxWidth(disCol.name, element)"
          [style.min-width]="getMaxWidth(disCol.name, element)"
          [style.border]="setBorderColor(disCol, element)"
        >
          <input
            *ngIf="
              disCol.name !== 'Amount' &&
              disCol.name !== 'Send Flag' &&
              disCol.name !== 'Delete'
            "
            [(ngModel)]="element[disCol.name]"
            (ngModelChange)="
              element[disCol.name] = $event;
              evaluateTransactions(element,disCol.name);
              checkRemoveFlag(element, disCol);
              "
            [ngModelOptions]="{ updateOn: 'blur' }"
            matInput
            type="search"
            [required]="coreLayout[colIndex].required"
            [maxlength]="getMaxLength(disCol.id)"
            [disabled]="showSpinner"
            style="padding: 3px; height: 30px; width: 100%"
          />
          <input
            *ngIf="
              disCol.name === 'Amount' &&
              disCol.name !== 'Send Flag' &&
              disCol.name !== 'Delete'
            "
            [ngModel]="element[disCol.name] | removeCommas | currency"
            [ngModelOptions]="{ updateOn: 'blur' }"
            (ngModelChange)="
              element[disCol.name] = $event;
              evaluateTransactions(element,disCol.name);
              checkRemoveFlag(element, disCol);
            "
            matInput
            type="search"
            autocomplete="off"
            [required]="coreLayout[colIndex].required"
            [maxlength]="getMaxLength(disCol.id)"
            [disabled]="showSpinner"
            (keypress)="removeDollarSignFromAmount($event)"
            style="padding: 3px; width: 100%; height: 30px"
          />
          <mat-icon
            *ngIf="disCol.name !== 'Amount' && disCol.name === 'Delete'"
            (width)="getMaxMinWidth(disCol.id)"
            style="cursor: pointer; margin-left: 7px"
            aria-hidden="false"
            aria-label="icon to tell if pinned"
            (click)="deleteItem(element)"
            color="accent"
            >remove_circle</mat-icon
          >
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumnNames; sticky: true"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumnNames"></mat-row>
    </mat-table>
  </div>
  <div
    style="
      height: 40px;
      text-align: right;
      margin-right: 30px;
      font-weight: 600;
      font-size: 14px;
    "
  >
    Total Amount: {{ totalAmount | currency }}
  </div>

  <!--Spinner-->
  <div class="spinner-container" *ngIf="showSpinner">
    <div class="new-spinner">
      <div class="outer-ring"></div>
      <div class="middle-ring"></div>
      <div class="inner-ring"></div>
    </div>
  </div>
</div>

<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
