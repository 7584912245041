<div>
  <h1>Update Miser Credentials</h1>
  <div
    style="
      display: grid;
      grid-template-rows: 1fr 1fr 1fr;
      row-gap: 15px;
      padding: 15px;
      background-color: #e0e0e0;
      border-radius: 5px;
      margin-bottom: 10px;
    "
  >
    <div>
      <mat-form-field style="width: 100%">
        <input
          style="width: 100%"
          matInput
          placeholder="Teller Number"
          autocomplete="off"
          [(ngModel)]="localMiserUserCredentialItem.tellerNumber"
          (keydown.enter)="updateCredentials()"
          (ngModelChange)="checkForm()"
        />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="width: 100%">
        <input
          style="width: 100%"
          matInput
          placeholder="Station Name"
          autocomplete="off"
          [(ngModel)]="localMiserUserCredentialItem.stationName"
          (keydown.enter)="updateCredentials()"
          (ngModelChange)="checkForm()"
        />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="width: 100%">
        <input
          style="width: 100%"
          matInput
          placeholder="Cash Box Number"
          autocomplete="off"
          [(ngModel)]="localMiserUserCredentialItem.cashBoxNumber"
          (keydown.enter)="updateCredentials()"
          (ngModelChange)="checkForm()"
        />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="width: 100%">
        <input
          style="width: 100%"
          matInput
          type="password"
          placeholder="PIN"
          autocomplete="off"
          [(ngModel)]="localMiserUserCredentialItem.pin"
          (keydown.enter)="updateCredentials()"
          (ngModelChange)="checkForm()"
        />
      </mat-form-field>
    </div>
  </div>
  <!--botton row of buttons-->
  <div id="bottom-button-container">
    <!--Cancel Button-->
    <button
      mat-stroked-button
      class="cancel-btn"
      (click)="closeDialog()"
      id="cancel-button"
    >
      Close
    </button>

    <!--Continue Button-->
    <div style="text-align: right; display: flex; justify-content: space-between;">
        <mat-checkbox style="margin-top: 7%;" 
                [(ngModel)]="checked"
                (change)="checkboxChange($event)">Save Credentials
        </mat-checkbox>
      <button
        mat-raised-button
        class="continue-btn"
        (click)="updateCredentials()"
        id="continue-button"
        [disabled]="editedFlag === false"
      >
        <span class="button-text">Submit &nbsp;</span>
      </button>
    </div>
  </div>
</div>
