<p>Enter the New Status Here</p>
<div>
    <mat-form-field style="width: 100%">
        <input matInput #sourceInput placeholder="New Status" [(ngModel)]="newStatus" />
    </mat-form-field>
</div>
<button mat-raised-button class="cancel-btn" (click)="closeBox()">
  <span>Cancel</span>
</button>
<button mat-raised-button class="continue-btn" (click)="continue()">
  <span>Submit &nbsp;</span>
  <mat-icon class="continue-icon">forward </mat-icon>
</button>