import { NgModule } from '@angular/core';

import { CCMCIfOnDomDirective } from '../../@ccmc/directives/ccmc-if-on-dom/ccmc-if-on-dom.directive';
import { CCMCPerfectScrollbarDirective } from '../../@ccmc/directives/ccmc-perfect-scrollbar/ccmc-perfect-scrollbar.directive';
import {
  CCMCMatSidenavHelperDirective,
  CCMCMatSidenavTogglerDirective
} from '../../@ccmc/directives/ccmc-mat-sidenav/ccmc-mat-sidenav.directive';
import { PasswordMatcherDirective } from '../../app/main/toolbar/change-password/password-matcher.directive';

@NgModule({
  declarations: [
    CCMCIfOnDomDirective,
    CCMCMatSidenavHelperDirective,
    CCMCMatSidenavTogglerDirective,
    CCMCPerfectScrollbarDirective,
    PasswordMatcherDirective
  ],
  imports: [],
  exports: [
    CCMCIfOnDomDirective,
    CCMCMatSidenavHelperDirective,
    CCMCMatSidenavTogglerDirective,
    CCMCPerfectScrollbarDirective,
    PasswordMatcherDirective
  ]
})
export class CCMCDirectivesModule { }
