import {
  Directive,
  Input,
  OnInit,
  HostListener,
  OnDestroy,
  HostBinding
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

import { CCMCMatchMediaService } from '../../../@ccmc/services/match-media.service';
import { CCMCMatSidenavHelperService } from '../../../@ccmc/directives/ccmc-mat-sidenav/ccmc-mat-sidenav.service';

@Directive({
  selector: '[ccmcMatSidenavHelper]'
})
export class CCMCMatSidenavHelperDirective implements OnInit, OnDestroy {
  matchMediaSubscription: Subscription;
  @HostBinding('class.mat-is-locked-open') isLockedOpen = true;
  // tslint:disable-next-line:no-input-rename
  @Input('ccmcMatSidenavHelper') id: string;
  // tslint:disable-next-line:no-input-rename
  @Input('mat-is-locked-open') matIsLockedOpenBreakpoint: string = ' ';

  constructor(
    private ccmcMatSidenavService: CCMCMatSidenavHelperService,
    private ccmcMatchMedia: CCMCMatchMediaService,
    private mediaObserver: MediaObserver,
    private matSidenav: MatSidenav
  ) {}

  ngOnInit() {
    this.ccmcMatSidenavService.setSidenav(this.id, this.matSidenav);
    if (this.mediaObserver.isActive(this.matIsLockedOpenBreakpoint)) {
      this.isLockedOpen = true;
      this.matSidenav.mode = 'side';
      this.matSidenav.toggle(true);
    } else {
      this.isLockedOpen = false;
      this.matSidenav.mode = 'over';
      this.matSidenav.toggle(false);
    }
    this.matchMediaSubscription = this.ccmcMatchMedia.onMediaChange.subscribe(
      () => {
        if (this.mediaObserver.isActive(this.matIsLockedOpenBreakpoint)) {
          this.isLockedOpen = true;
          this.matSidenav.mode = 'side';
          this.matSidenav.toggle(true);
        } else {
          this.isLockedOpen = false;
          this.matSidenav.mode = 'over';
          this.matSidenav.toggle(false);
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.matchMediaSubscription) {
      this.matchMediaSubscription.unsubscribe();
    }
  }
}

@Directive({
  selector: '[ccmcMatSidenavToggler]'
})
export class CCMCMatSidenavTogglerDirective {
  // tslint:disable-next-line:no-input-rename
  @Input('ccmcMatSidenavToggler') id: any;

  constructor(private ccmcMatSidenavService: CCMCMatSidenavHelperService) {}

  @HostListener('click')
  onClick() {
    this.ccmcMatSidenavService.getSidenav(this.id).toggle();
  }
}
