import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  NgZone,
  AfterViewInit
} from '@angular/core';
import { Subscription, fromEvent, Subject } from 'rxjs';
import { CcmcApiService } from '../../../../@ccmc/services/ccmc-api.service';
import { CCMCSelectedFieldService } from '../../../../@ccmc/services/selected-field.service';
import { CCMCConfirmDialogComponent } from '../../../../@ccmc/components/confirm-dialog/confirm-dialog.component';
import { FieldEditedService } from '../../../../@ccmc/services/field-edited.service';
import { FormControl } from '@angular/forms';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  takeUntil
} from 'rxjs/operators';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { FindAndReplaceDialogComponent } from '../../../../@ccmc/components/find-and-replace-dialog/find-and-replace-dialog.component';
import { SpinnerService } from '../../../../@ccmc/services/spinner.service';
import { EncompassService } from '../../../../@ccmc/services/encompass.service';
import { GetPathDialogComponent } from '../../../../@ccmc/components/get-path-dialog/get-path-dialog.component';
import { GeneralLedgerAccountingService } from '../../../../@ccmc/services/general-ledger-accounting.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AddGlaTranslatorsDialogComponent } from 'src/@ccmc/components/add-gla-translators-dialog/add-gla-translators-dialog.component';

/**
 * The Translators component lets the user see the translators for an asset
 *
 * @export
 * @class TranslatorsComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @implements {AfterViewInit}
 */

@Component({
  selector: 'app-gla-translators',
  templateUrl: './gla-translators.component.html',
  styleUrls: ['./gla-translators.component.scss']
})
export class GlaTranslatorsComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  /**
   * Shortcuts for keybindings
   *
   * @type {ShortcutInput[]}
   * @memberof TranslatorsComponent
   */
  shortcuts: ShortcutInput[] = [];

  /**
   * Delay for tooltip
   *
   * @memberof TranslatorsComponent
   */
  showDelay = new FormControl(1200);

  /**
   * Show spinner boolean
   *
   * @type {boolean}
   * @memberof TranslatorsComponent
   */
  showSpinner: boolean;
  /**
   * Subscription for spinner sub
   *
   * @private
   * @type {Subscription}
   * @memberof TranslatorsComponent
   */
  private spinnerSub: Subscription;

  /**
   * Document los subscription
   *
   * @private
   * @type {Subscription}
   * @memberof TranslatorsComponent
   */
  private documentLosSub: Subscription;

  /**
   * Selected translator subscription
   *
   * @private
   * @type {Subscription}
   * @memberof TranslatorsComponent
   */
  private selectedSub: Subscription;

  /**
   * Field edited subscription
   *
   * @private
   * @type {Subscription}
   * @memberof TranslatorsComponent
   */
  private fieldEditedSub: Subscription;

  /**
   * Field edited flag
   *
   * @memberof TranslatorsComponent
   */
  fieldEditedFlag = false;

  /**
   * Translators object
   *
   * @memberof TranslatorsComponent
   */
  translators: any;

  /**
   * Temporary document
   *
   * @private
   * @memberof TranslatorsComponent
   */
  private tempDocument: any;

  /**
   * Selected translator
   *
   * @memberof TranslatorsComponent
   */
  selected: any;

  /**
   * Displayed columns for the translator table
   *
   * @memberof TranslatorsComponent
   */
  displayedColumns = ['fieldName', 'evaluationMethod', 'vbScript', 'edited'];

  /**
   * Datasource used in the Angular Material Table
   *
   * @type {*}
   * @memberof TranslatorsComponent
   */
  dataSource: any;

  /**
   * Material paginator reference
   *
   * @type {MatPaginator}
   * @memberof TranslatorsComponent
   */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /**
   * Material sort reference
   *
   * @type {MatSort}
   * @memberof TranslatorsComponent
   */
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /**
   * Filter search reference
   *
   * @type {ElementRef}
   * @memberof TranslatorsComponent
   */
  @ViewChild('filterSearch', { static: true }) filterSearchEl: ElementRef;

  /**
   * Filter search
   *
   * @type {string}
   * @memberof TranslatorsComponent
   */
  filterSearch: string;

  /**
   * Unsubscribe from observables
   *
   * @type {Subject<any>}
   * @memberof TranslatorsComponent
   */
  unsubscribe: Subject<any> = new Subject();

  /**
   * Dialog reference for find and replace
   * turned off for now
   *
   * @memberof TranslatorsComponent
   */
  dialogRef: any;
  sets: any;
  vbScriptEdited: any;
  transactionsDataSource: any;
  selectedTransaction: any;
  private glaDocumentSub: Subscription;

  constructor(
    private ccmcApiService: CcmcApiService,
    private selectedFieldService: CCMCSelectedFieldService,
    private dialog: MatDialog,
    private fieldEditedService: FieldEditedService,
    private zone: NgZone,
    private snackBar: MatSnackBar,
    private spinnerService: SpinnerService,
    private encompassService: EncompassService,
    private glaService: GeneralLedgerAccountingService
  ) {}
  /**
   * Initialize the translators component
   *
   * @memberof TranslatorsComponent
   */
  ngOnInit() {
    this.fieldEditedService.fieldEdited.next(false);
    this.getData();
    this.filterSearchEl.nativeElement.focus();

    fromEvent(this.filterSearchEl.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // Time in milliseconds between key events
        debounceTime(1000),
        // If previous query is diffent from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((text: string) => {
        this.applyFilter(text);
      });
  }
  /**
   * Unsubscribe from observables on destroy
   *
   * @memberof TranslatorsComponent
   */
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  /**
   * Load the shortcuts after init
   *
   * @memberof TranslatorsComponent
   */
  ngAfterViewInit() {
    this.shortcuts.push({
      key: ['cmd + h'],
      label: 'Find and Replace',
      description: 'Find and replace',
      command: e => {
        this.findAndReplace();
      },
      preventDefault: true
    });
  }

  /**
   * Gets all the data needed for the translator component
   *
   * @memberof TranslatorsComponent
   */
  getData() {
    // Initialize selectedSub

    this.selectedSub = this.selectedFieldService.onGLATranslatorFieldSelected
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(selected => {
        console.log(selected);
        if (selected) {
          this.selected = selected;
        }
        if (selected) {
          if (
            selected.evaluationMethod === '' ||
            selected.evaluationMethod === ' '
          ) {
            this.selected.evaluationMethod = 'path';
          }
        }
      });

    this.glaDocumentSub = this.glaService.glaDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(glaDocument => {
        if (glaDocument) {
          this.tempDocument = glaDocument;
          console.log('GLA Document', glaDocument);
          // TODO: Init set at index 0 as starter set
          if (glaDocument.translators) {
            this.translators = glaDocument.translators;
          } else {
            this.translators = [];
          }
          this.dataSource = new MatTableDataSource(this.translators);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;

          if (
            this.selected === null ||
            this.selected === undefined 
          ) {
            this.onSelect(this.translators[0]);
          } else if (this.translators.length > 0) {
            let x = this.translators.length - 1;
            this.onSelect(this.translators[x]);
          }
        }
      });
    this.fieldEditedSub = this.fieldEditedService.fieldEdited
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(edited => {
        this.fieldEditedFlag = edited;
      });

    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        // console.log(spinner);
        if (this.dialog.openDialogs.length === 0) {
          this.showSpinner = spinner;
        }
      });
  }

  /**
   * Applies the filter to the dataSource
   *
   * @param {string} filterValue
   * @memberof TranslatorsComponent
   */
  applyFilter(filterValue: string) {
    // console.log(filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData[0]) {
      this.selectedFieldService.onGLATranslatorFieldSelected.next(
        this.dataSource.filteredData[0]
      );
    }
    this.filterSearch = filterValue;
  }

  /**
   * When user selects a translator
   *
   * @param {*} selected
   * @memberof TranslatorsComponent
   */
  onSelect(selected: any) {
    this.selected = selected;
    this.selectedFieldService.onGLATranslatorFieldSelected.next(selected);
  }

  /**
   * Opens dialog to add a new translator
   *
   * @memberof TranslatorsComponent
   */
  addGlaTranslator(event: any) {
    event.stopPropagation();
    const addGlaTranslatorDialogRef = this.dialog.open(
      AddGlaTranslatorsDialogComponent,
      {}
    );

    addGlaTranslatorDialogRef.afterClosed().subscribe(() => {
      this.selectedFieldService.onGLATranslatorFieldSelected.next(
        this.selected
      );
      if (this.filterSearch) {
        if (this.filterSearch.length > 0) {
          this.applyFilter(this.filterSearch);
        }
      }
    });
  }

  /**
   * Removes current translator selected
   *
   * @memberof TranslatorsComponent
   */
  removeTranslator() {
    const confirmDialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
      data: 'Are you sure you want to remove this translator?'
    });
    confirmDialogRef.afterClosed().subscribe(data => {
      if (data) {
        console.log('remove', this.selected);
        this.fieldEditedService.onGLADocumentEdited.next(true);

        for (let i = 0; i < this.translators.length; i++) {
          if (this.translators[i].fieldName === this.selected.fieldName) {
            this.translators.splice(i, 1);
          }
        }

        this.tempDocument.translators = this.translators;
        console.log(this.tempDocument);
        this.glaService.setGLADocumentSimple(this.tempDocument);
        if (this.filterSearch && this.filterSearch.length > 0) {
          this.applyFilter(this.filterSearch);
        }
      }
      console.log(this.translators.length);
      if (this.translators.length === 0) {
        this.onSelect({});
      } else {
        this.onSelect(this.translators[0]);
      }
    });
  }

  /**
   * Controls if the user can navigate away from the component
   *
   * @returns
   * @memberof TranslatorsComponent
   */
  canDeactivate() {
    if (this.fieldEditedFlag) {
      this.openSnackBar('Please resolve changes', 'Okay');
    }
    return !this.fieldEditedFlag;
  }

  /**
   * Opens snackbar with given message and action
   *
   * @param {string} message
   * @param {string} action
   * @memberof TranslatorsComponent
   */
  openSnackBar(message: string, action: string) {
    this.zone.run(() => {
      setTimeout(() => {
        this.snackBar.open(message, action, {
          duration: 5000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }, 0);
    });
  }

  /**
   * Find and replace dialog
   *
   * @returns
   * @memberof TranslatorsComponent
   */
  findAndReplace() {
    if (this.dialogRef) {
      return;
    } else {
      this.dialogRef = this.dialog.open(FindAndReplaceDialogComponent, {
        data: 'translators'
      });
      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = undefined;
      });
    }
  }

  /**
   * Get open dialog for encompass path
   *
   * @returns
   * @memberof TranslatorsComponent
   */
  getEncompassPath() {
    console.log('open path dialog');
    const pathDialogRef = this.dialog.open(GetPathDialogComponent);
  }
}
