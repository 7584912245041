import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CognitoUserInterface,
  AuthState
} from '@aws-amplify/ui-components';
import { Amplify, Auth } from 'aws-amplify';
import { AmplifyQrCodeDialogComponent } from '../components/amplify-qr-code-dialog/amplify-qr-code-dialog.component';
import { HttpRequest, HttpClient, HttpHeaders } from '@angular/common/http';
import { CheckToken } from '../models/check-token.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';
import { ErrorDialogModal } from '../models/errorDialog.model';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { catchError } from 'rxjs/operators';
import { FieldEditedService } from './field-edited.service';
import { CCMCForceChangePasswordComponent } from '../components/force-change-password/force-change-password.component';
import { Router } from '@angular/router';
import { SpinnerService } from './spinner.service';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { environment } from 'src/environments/environment';
const { Buffer } = require('buffer');

@Injectable({
  providedIn: 'root'
})
export class AmplifyService {
  private user: CognitoUserInterface | undefined;
  private authState: AuthState | undefined;
  jwtHelper;
  isNXTsoft: any;
  isNXTsoftDev: any;
  isNXTsoftOperations: any;
  isNXTsoftSales: any;
  isAdmin: any;
  isSuperAdmin: any;
  isLoanServicingAdmin: any;
  isLoanSystemsAdmin: any;
  username: any;
  cognitoID: any;
  accountID: any;
  private userSource = new BehaviorSubject<any>('');
  user$ = this.userSource.asObservable();
  errorMessage: ErrorDialogModal;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  environmentOverride: any = false;
  constructor(
    private matDialog: MatDialog,
    private fieldEditedService: FieldEditedService,
    private router: Router,
    private spinnerService: SpinnerService,
    private http: HttpClient,
    private customErrorHandlerService: CustomErrorHandlerService
  ) {
    this.jwtHelper = new JwtHelperService();
  }
  // this was in the app.component.ts that accompanied the HTML below
  amplifyConfigure() {
    Amplify.configure({
      Auth: {
        region: 'us-west-2',
        userPoolId: 'us-west-2_u566WmrPK',
        userPoolWebClientId: 'fmaluhhukj96dktichu4s44jk'
      }
    });

    // onAuthUIStateChange((authState, authData) => {

    //   this.authState = authState;
    //   this.user = authData as CognitoUserInterface;
    // });
  }

  async login(email: any, password: any, callback: any) {
    try {
      const user = await Auth.signIn(email, password);
      this.username = user.username;
      console.log('Current Authenticated User =>', user);
      if (
        user.challengeName === 'SMS_MFA' ||
        user.challengeName === 'SOFTWARE_TOKEN_MFA'
      ) {
        //sets that the user used MFA to login
        this.fieldEditedService.activeMFA.next(true);
        //need to get the 6 digit code here
        const dialogRef1 = this.matDialog.open(AmplifyQrCodeDialogComponent, {
          disableClose: true,
          panelClass: 'amplify__dialog',
          data: user
        });
        dialogRef1.afterClosed().subscribe(data => {
          console.log(data);
          this.setCognitoId(user.signInUserSession.accessToken.payload.sub);
          callback.handleCognito(null, user.signInUserSession);
        });
      } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        console.log(user.challengeParam.userAttributes);
        if (user.challengeParam.userAttributes.email_verified !== undefined) {
          delete user.challengeParam.userAttributes.email_verified;
          delete user.challengeParam.userAttributes.email;
        }
        this.matDialog
          .open(CCMCForceChangePasswordComponent, {
            disableClose: true,
            panelClass: 'force-password__dialog'
          })
          .afterClosed()
          .subscribe(async val => {
            const loggedUser = Auth.completeNewPassword(
              user, // the Cognito User Object
              val.newPass, // the new password
              // OPTIONAL, the required attributes
              {
                email
              }
            ).then(
              (newPasswordResult:any) => {
                console.log('New Password Result', newPasswordResult);
                callback.handleCognito(
                  null,
                  newPasswordResult.signInUserSession
                );
              },
              err => {
                console.log(err);
                callback.handleCognito(err, null);
              }
            )
          });
      } else if (user.challengeName === 'MFA_SETUP') {
      } else {
        //sets that the user did not use MFA to login
        this.fieldEditedService.activeMFA.next(false);
        console.log(user);
        this.setCognitoId(user.signInUserSession.accessToken.payload.sub);
        callback.handleCognito(null, user.signInUserSession);
      }
    } catch (error: any) {
      if (error.message === 'Pending sign-in attempt already in progress') {
        console.log('another session attempting to sign in', error);
        return error;
      }
      this.errorMessage = {
        message: error.message,
        title: 'Login Error'
      };
      // Display error to screen
      const dialogRef = this.matDialog.open(ErrorDialogComponent, {
        data: this.errorMessage
      });
      dialogRef.afterClosed().subscribe(
        res => { },
        err => console.log(err)
      );

      console.log('error signing in', error);
      return error;
    }
  }

  callTOTP(user: any): Promise<any> {
    return new Promise(resolve => {
      console.log(user);
      Auth.setupTOTP(user).then(
        code => {
          console.log(code);
          const setupTOTPResponse = {
            statusFlag: true,
            statusMessage:
              'Successfully retrieved Multi-Factor Authentication code',
            content: code
          };
          console.log(setupTOTPResponse);
          resolve(setupTOTPResponse);
        },
        err => {
          console.log(err);
          const setupTOTPError = {
            statusFlag: false,
            statusMessage: 'Multi-Factor Authentication code retrieval failed',
            content: err
          };
          resolve(setupTOTPError);
        }
      );
    });
  }

  async getCurrentUser() {
    return new Promise(async resolve => {
      try {
        let user = await Auth.currentAuthenticatedUser()
          .then()
          .catch(e => {
            console.log(e);
          });
        console.log(user);
        this.accountID = user.attributes['custom:AccountID'];
        this.username = user.username;
        this.setCognitoId(user.attributes.sub);
        const accessToken = user.signInUserSession
          .getAccessToken()
          .getJwtToken();
        console.log(accessToken);
        const idToken = user.signInUserSession.getIdToken().getJwtToken();
        const refreshToken = user.signInUserSession
          .getRefreshToken()
          .getToken();
        const isExpired = this.jwtHelper.isTokenExpired(accessToken);
        if (!isExpired) {
          const decodedIdToken = this.jwtHelper.decodeToken(idToken);
          // Get User profile data
          const tokenPayload = this.jwtHelper.decodeToken(idToken);
          return resolve({
            success: true,
            accessToken: accessToken,
            idToken: idToken,
            refreshToken: refreshToken
          });
        } else {
          // // Generate new valid tokens
          // const RefreshToken = new CognitoRefreshToken({
          //   RefreshToken: refreshToken
          // });
          user.refreshSession(refreshToken, (err: any, session: any) => {
            if (err) {
              return resolve({ success: false });
            }
            user.setSignInUserSession(session);
            return resolve({
              success: true,
              accessToken: user.signInUserSession
                .getAccessToken()
                .getJwtToken(),
              idToken: user.signInUserSession.getIdToken().getJwtToken(),
              refreshToken: user.signInUserSession.getRefreshToken().getToken()
            });
          });
        }
      } catch (e) {
        console.log(this.router.url);
        // console.log(
        //   "if Error says Cannot read properties of undefined (reading 'username') This means the user has not logged in"
        // );
        // console.log(e);
        if (this.router.url !== '/login') {
          this.router.navigate(['login']);
        }
      }
    });
  }

  async getUserName() {
    let user = await Auth.currentAuthenticatedUser()
      .then()
      .catch(e => console.log(e));
    if (user) {
      console.log('Current Authenticated User =>', user);
      return user.username;
    } else {
      return 'No User Found';
    }
  }

  checkToken(request: HttpRequest<any>): Promise<CheckToken> {
    return new Promise((resolve, reject) => {
      let cognitoUser: CognitoUserInterface;
      if (
        this.environmentOverride === false && 
        !request.url.includes(`${Buffer.from(environment.environmentURL, "base64").toString()}`)
        ) {
        return resolve({ success: true });
      }
      Auth.currentSession().then(
        session => {
          console.log(session);
          const idToken = session.getIdToken().getJwtToken();
          if (this.jwtHelper.isTokenExpired(idToken)) {
            console.log('Token is expired');
            cognitoUser.refreshSession(
              session.getRefreshToken(),
              (err: any, session: any) => {
                if (err) {
                  console.log(err);
                  return resolve({ success: false });
                } else {
                  Auth.currentSession().then(
                    session => { },
                    getCurrentSessionError => {
                      console.log(getCurrentSessionError);
                      const token = session.getAccessToken().getJwtToken();
                      return resolve({ success: true, token });
                    }
                  );
                }
              }
            );
          } else {
            console.log('ID token is fresh');
            const token = session.getAccessToken().getJwtToken();
            return resolve({ success: true, token });
          }
        },
        err => {
          console.log(err);
          return resolve({ success: false });
        }
      );
    });
  }

  async getAccessToken() {
    return new Promise((resolve, reject) => {
      let cognitoUser: CognitoUserInterface;
      Auth.currentSession().then(
        session => {
          console.log(session);
          const accessToken = session.getAccessToken().getJwtToken();
          return resolve({ statusFlag: true, accessToken });
        },
        err => {
          console.log(err);
          return resolve({ statusFlag: false });
        }
      );
    });
  }

  checkUserAssets(assetID: any) {
    return new Promise(resolve => {
      let cognitoUser: CognitoUserInterface;
      try {
        let user = Auth.currentAuthenticatedUser().then(
          currentAuthenticatedUser => {
            console.log(currentAuthenticatedUser);
            const idToken = currentAuthenticatedUser.signInUserSession
              .getIdToken()
              .getJwtToken();
            const decodedIdToken = this.jwtHelper.decodeToken(idToken);
            const assetIDS = decodedIdToken['custom:AssetID'];
            console.log(assetIDS);
            console.log(assetID);
            if (assetIDS.includes(assetID)) return resolve(true);
            else return resolve(false);
          }
        );
      } catch (e) {
        console.log(e);
        return resolve(false);
      }
    });
  }

  getCognitoId() {
    return this.cognitoID;
  }

  setCognitoId(cognitoID: any) {
    this.cognitoID = cognitoID;
  }
  getAccountID() {
    return this.accountID;
  }
  logout() {
    Auth.signOut();
  }

  async resetCookies() {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser()
        .then()
        .catch(e => {
          console.log(e);
        });
      const currentSession: any = await Auth.currentSession();
      cognitoUser.refreshSession(
        currentSession['refreshToken'],
        (err: any, session: any) => {
          console.log('session', err, session);
          const { idToken, refreshToken, accessToken } = session;
        }
      );
    } catch (e) {
      console.log('Unable to refresh Token', e);
    }
  }

  forgotPassword(email: any, callback: any) {
    Auth.forgotPassword(email).then(
      forgotPasswordResponse => {
        return callback.requestResult(
          null,
          `Recovery code has been sent to the following email address: ${forgotPasswordResponse.CodeDeliveryDetails.Destination}`
        );
      },
      err => {
        return callback.requestResult(err.message, null);
      }
    );
  }

  resetPasswordWithCode(email: any, code: any, newPassword: any, callback: any) {
    Auth.forgotPasswordSubmit(email.toLowerCase(), code, newPassword).then(
      forgotPasswordSubmit => {
        return callback.requestResult(
          null,
          'Password has been successfully reset!'
        );
      },
      err => {
        return callback.requestResult(err.message, null);
      }
    );
  }

  async changePassword(oldPassword: any, newPassword: any, callback: any) {
    let cognitoUser: CognitoUserInterface;
    let user = await Auth.currentAuthenticatedUser()
      .then()
      .catch(e => {
        console.log(e);
      });
    Auth.changePassword(user, oldPassword, newPassword).then(
      changePasswordResponse => {
        return callback.passwordUpdated();
      },
      err => {
        return callback.cognitoCallback(err);
      }
    );
  }

  verifyTotpCode(user: any, code: any) {
    return new Promise(resolve => {
      Auth.verifyTotpToken(user, code)
        .then(verifyTotpTokenResponse => {
          // don't forget to set TOTP as the preferred MFA method
          Auth.setPreferredMFA(user, 'TOTP');
          const verifyTOTPResponse = {
            statusFlag: true,
            statusMessage:
              'Sucessfully verified Multi-Factor Authentication token'
          };
          resolve(verifyTOTPResponse);
        })
        .catch(e => {
          console.log(e);
          const verifyTOTPError = {
            statusFlag: false,
            statusMessage: 'Failed to verify Multi-Factor Authentication token',
            content: e
          };
          resolve(verifyTOTPError);
        });
    });
  }

  async setAuthSigninSessionConfirmed(user: any, code: any) {
    return new Promise(async resolve => {
      try {
        await Auth.confirmSignIn(user, code, 'SOFTWARE_TOKEN_MFA');
        const confirmSignInResponse = {
          statusFlag: true,
          statusMessage: 'Successfully confirmed signin'
        };
        resolve(confirmSignInResponse);
        return true;
      } catch (e) {
        const confirmSignInError = {
          statusFlag: false,
          statusMessage: 'Failed to confirm signin for user',
          content: e
        };
        resolve(confirmSignInError);
      }
    });
  }

  cognitoDisableUser(username: string) {
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/cognito/disable-user`,
          {
            username: username
          },
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }

  cognitoEnableUser(username: string) {
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/cognito/enable-user`,
          {
            username: username
          },
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }

  getUserGroupsForUser() {
    return  new Promise(async (resolve: any) => {
      try {
        let user = await Auth.currentAuthenticatedUser()
          .then()
          .catch(e => {
            console.log(e);
            return resolve(false);
          });
        if (user) {
          let userGroups = user.signInUserSession.idToken.payload['cognito:groups'];
          return resolve(userGroups);
        } else {
          return resolve([]);
        }
      } catch (e) {
        return resolve([]);
      }
    })
  }

  checkNXTsoftUserGroup() {
    return  new Promise(async (resolve: any) => {
      try {
        let user = await Auth.currentAuthenticatedUser()
          .then()
          .catch(e => {
            console.log(e);
            return resolve(false);
          });
        if (user) {
          let userGroups = user.signInUserSession.idToken.payload['cognito:groups'];
          if (JSON.stringify(userGroups).includes('nxtsoft')) {
            return resolve(true);
          } else {
            return resolve(false);
          }
        }
      } catch (e) {
        return resolve(false);
      }
    })
  }

  async checkNXTsoftOperationsUserGroup() {
    return new Promise(async (resolve: any) => {
      try {
        let user = await Auth.currentAuthenticatedUser()
          .then()
          .catch(e => {
            console.log(e);
            return resolve(false);
          });
        if (user) {
          let userGroups = user.signInUserSession.idToken.payload['cognito:groups'];
          if (JSON.stringify(userGroups).includes('nxtsoft-operations')) {
            return resolve(true);
          } else {
            return resolve(false);
          }
        }
      } catch (e) {
        return resolve(false);
      }
    })
  }

  async checkAdmin() {
    return new Promise(async (resolve: any) => {
      try {
        let user = await Auth.currentAuthenticatedUser()
          .then()
          .catch(e => {
            console.log(e);
            return resolve(false);
          });
        if (user) {
          console.log(user);
          let userGroups = user.signInUserSession.idToken.payload['cognito:groups'];
          console.log(userGroups);
          console.log(JSON.stringify(userGroups));
          if (JSON.stringify(userGroups).includes('client-admin')) {
            console.log('it returns true');
            return resolve(true);
          } else {
            console.log('it returns false');
            return resolve(false);
          }
        }
      } catch (e) {
        console.log(e);
        console.log('it goes to here');
        return resolve(false);
      }
    })
  }

  async checkSuperAdmin() {
    return new Promise(async (resolve: any) => {
      try {
        let user = await Auth.currentAuthenticatedUser()
          .then()
          .catch(e => {
            console.log(e);
            return resolve(false);
          });
        if (user) {
          let userGroups = user.signInUserSession.idToken.payload['cognito:groups'];
          if (JSON.stringify(userGroups).includes('super-admin') || JSON.stringify(userGroups).includes('client-admin')) {
            return resolve(true);
          } else {
            return resolve(false);
          }
        }
      } catch (e) {
        return resolve(false);
      }
    })
  }

  // async checkLoanSystemsAdmin() {
  //   return new Promise(async (resolve: any) => {
  //     try {
  //       let user = await Auth.currentAuthenticatedUser()
  //         .then()
  //         .catch(e => {
  //           console.log(e);
  //           return resolve(false);
  //         });
  //       if (user) {
  //         let userGroups = user.signInUserSession.idToken.payload['cognito:groups'];
  //         if (JSON.stringify(userGroups).includes('loan-systems-admin')) {
  //           return resolve(true);
  //         } else {
  //           return resolve(false);
  //         }
  //       }
  //     } catch (e) {
  //       return resolve(false);
  //     }
  //   })
  // }

  // async checkLoanServicingAdmin() {
  //   return new Promise(async (resolve: any) => {
  //     try {
  //       let user = await Auth.currentAuthenticatedUser()
  //         .then()
  //         .catch(e => {
  //           console.log(e);
  //           return resolve(false);
  //         });
  //       if (user) {
  //         let userGroups = user.signInUserSession.idToken.payload['cognito:groups'];
  //         if (JSON.stringify(userGroups).includes('loan-servicing-admin')) {
  //           return resolve(true);
  //         } else {
  //           return resolve(false);
  //         }
  //       }
  //     } catch (e) {
  //       return resolve(false);
  //     }
  //   })
  // }

  retrieveUserGroupName() {
    return new Promise(async (resolve: any) => {
      try {
        let user = await Auth.currentAuthenticatedUser()
          .then()
          .catch(e => {
            console.log(e);
            return resolve("");
          });
        if (user) {
          let userGroups = user.signInUserSession.idToken.payload['cognito:groups'];
          if (userGroups) {
            return resolve(userGroups[0]);
          } else {
            return resolve("");
          }
        }
      } catch (e) {
        return resolve("");
      }
    })
  }

  clearUserGroups() {
    this.isNXTsoft = '';
    this.isNXTsoftDev = '';
    this.isNXTsoftOperations = '';
    this.isNXTsoftSales = '';
    this.isAdmin = '';
    this.isSuperAdmin = '';
    this.isLoanServicingAdmin = '';
    this.isLoanSystemsAdmin = '';
  }
}
