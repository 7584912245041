import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationComponent } from './validation.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../../auth/auth.guard';
import { DeactivateValidation } from './deactivate-validation';
import { CCMCSharedModule } from '../../../../@ccmc/shared.module';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { Role } from 'src/@ccmc/models/role';

const routes: Routes = [
  {
    path: 'validation',
    component: ValidationComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    canDeactivate: [DeactivateValidation],
    data: {
      roles: [
        Role.ClientAdmin,
        Role.NxtsoftSales,
        Role.NxtsoftOp,
        Role.NxtsoftDev
      ]
    }
  }
];

@NgModule({
  declarations: [ValidationComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CCMCSharedModule,
    KeyboardShortcutsModule.forRoot()
  ],
  providers: [DeactivateValidation]
})
export class ValidationModule {}
