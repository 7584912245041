import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-send-doc-connector-dialog',
  templateUrl: './resend-doc-connector-dialog.component.html',
  styleUrls: ['./resend-doc-connector-dialog.component.scss']
})
export class ResendDocConnectorDialogComponent implements OnInit {
  resetBeforeSend = false;
  constructor(
    public dialogRef: MatDialogRef<ResendDocConnectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public loanNumber: any,
  ) {}

  ngOnInit(): void {}
}
