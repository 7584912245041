<div class="history-container">
  <div>
    <!--Header-->
    <div class="header mat-accent-bg p-16 p-sm-24">
      <div class="header-image">
        <!-- Header Image -->
        <img
          src="assets/images/logos/doc-connector-omni.png"
          style="z-index: 10000"
        />
      </div>
    </div>
  </div>

  <div>
    <div class="navigation-bar">
      <div class="navigation-text">
        <div style="width: 25px; display: inline-block">
          <mat-icon
            *ngIf="selectedLoan"
            style="font-size: 14pt; color: #8883e2"
            (click)="clearSelectedLoan()"
            >arrow_back</mat-icon
          >
        </div>
        <a
          (click)="clearSelectedLoan(); exportButtonSwitch = exportButtonSwitch === false ? true : false;"
          class="history-a"
          [ngStyle]="selectedLoan && { color: '#8883e2' }"
          >History
        </a>
        <div *ngIf="selectedLoan" class="loan-number-text">
          / {{ selectedLoan?.loanNumber }}
        </div>
        <div class="send-container px-8 px-mat-16">
          <div class="btn-container">
            <button 
            *ngIf="checkedFiles && checkedFiles.size > 0 && selectedLoan"
             mat-button class="Btn-submit"
             (click)="onRetrySend($event)" 
             style="margin-right: 12px;"
             [disabled]="!checkedFiles || checkedFiles.size == 0"
            >
              Retry
            </button>
            <button 
              mat-button 
              class="Btn-submit" 
              (click)="getHistory()" 
              [disabled]="isFetchingHistory"
              style="background-color: white; margin-right: 12px; border: 1px solid #ccc; font-size: 21px;"
              [matTooltip]="isFetchingHistory ? 'Loading...' : ''"
            >
            <i class="fa fa-refresh"></i>
          </button>
            <button mat-button class="Btn-submit" (click)="exportToCSVStatusReport()">
              Export Report
            </button>
        </div>
      </div>
    </div>
    <!-- History View -->
    <div
      class="history-table-section"
      [ngStyle]="{ display: selectedLoan ? 'none' : 'inherit' }"
    >
      <div class="history-table-filter-container">
        <mat-form-field class="filter-form-field">
          <input
            matInput
            class="filter-input"
            [(ngModel)]="filterSearchText"
            placeholder="Filter Search"
            (keyup)="applyFilter($any($event.target).value)"
            (keyup.esc)="clearSearchData()"
            #filterSearch
          />
        </mat-form-field>
      </div>
      <div #container class="table-container">
        <table
          class="table"
          mat-table
          matSort
          #table
          [dataSource]="dataSource"
          CCMCPerfectScrollbar
        >
          <!-- number Column -->
          <ng-container matColumnDef="checkBox">
            <th
              mat-sort-header
              mat-header-cell
              *matHeaderCellDef
              class="checkbox"
            ></th>
            <td mat-cell *matCellDef="let element" class="noselect">
              <mat-checkbox
                [(ngModel)]="element.checkToSend"
                (change)="updateCheckedLoans($event, element)"
              ></mat-checkbox>
            </td>
            <td mat-footer-cell *matFooterCellDef> </td>
          </ng-container>

          <!-- LoanName Column -->
          <ng-container matColumnDef="loanNumber">
            <th mat-sort-header mat-header-cell *matHeaderCellDef class="thcss">
              Loan Number
            </th>
            <td mat-cell *matCellDef="let element" class="noselect">
              {{ element.loanNumber }}
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <!-- Date Column -->
          <ng-container matColumnDef="date">
            <th mat-sort-header mat-header-cell *matHeaderCellDef class="thcss">
              Date
            </th>
            <td mat-cell *matCellDef="let element" class="noselect">
              {{ element.date | unixToDate }}
            </td> 
            <td mat-footer-cell *matFooterCellDef>
              <div 
                (click)="getMoreHistory()" 
                *ngIf="lastEvaluatedKey && !isFetchingHistory" 
                style="color: #3FB2C6; cursor:pointer" 
                class="no-selectable">
                Get More Data
              </div>
              <div *ngIf="isFetchingHistory" style="color: #3FB2C6; font-style: italic;">
                Loading...
              </div>
            </td>
          </ng-container>
          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-sort-header mat-header-cell *matHeaderCellDef class="thcss">
              Status
            </th>
            <td mat-cell *matCellDef="let element" class="noselect" [matTooltip]="getDetailedStatus(element)">
              {{ getStatus(element) }}
            </td>
            <td mat-footer-cell *matFooterCellDef> </td>
          </ng-container>
          <!-- Processed Column -->
          <ng-container matColumnDef="processed">
            <th mat-header-cell *matHeaderCellDef class="thcss" style="color: #3fb2c6; padding-right: 1em;">
              Processed
            </th>
            <td mat-cell *matCellDef="let element" class="noselect">
              {{ element.files.length }}
            </td>
            <td mat-footer-cell *matFooterCellDef> </td>
          </ng-container>
          <!-- Transmitted Column -->
          <ng-container matColumnDef="transmitted">
            <th mat-header-cell *matHeaderCellDef class="thcss" style="color: darkgreen; padding-right: 1em;">
              Transmitted
            </th>
            <td mat-cell *matCellDef="let element" class="noselect">
              {{ transmittedFileCount(element) }}
            </td>
            <td mat-footer-cell *matFooterCellDef> </td>
          </ng-container>
          <ng-container matColumnDef="pending">
            <th mat-header-cell *matHeaderCellDef class="thcss" style="color: rgb(100, 77, 0); padding-right: 1em;">
              Pending
            </th>
            <td mat-cell *matCellDef="let element" class="noselect">
              {{ pendingFileCount(element) }}
            </td>
            <td mat-footer-cell *matFooterCellDef> </td>
          </ng-container>
          <!-- Failed Column -->
          <ng-container matColumnDef="failed">
            <th mat-header-cell *matHeaderCellDef class="thcss" style="color: red; padding-right: 1em;">
              Failed
            </th>
            <td mat-cell *matCellDef="let element" class="noselect">
              {{ failedFileCount(element) }}
            </td>
            <td mat-footer-cell *matFooterCellDef> </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (dblclick)="onSelectLoan(row); exportButtonSwitch = exportButtonSwitch === false ? true : false; resetCheckedFiles()"
            matTooltip="Double Click to see files"
            matTooltipClass="tooltip-color"
            [ngClass]="{ 'accent-50-bg': row == selectedLoan }"
            matTooltip="Double click on the highlighted loan to view loan documents"
            matTooltipClass="tooltip-color"
          ></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>
      </div>
        <mat-paginator [length]="history.length" ></mat-paginator>
    </div>
    <!-- Selected Loan View -->
    <div
      class="selected-loan-container"
      [ngStyle]="{ display: !selectedLoan ? 'none' : 'inherit' }"
    >
      <app-selected-loan [checkedFiles]="checkedFiles" 
        (checkedFilesChanged)="updateCheckedFiles($event)"
        [(isAllBoxesChecked)]="isAllSelectLoanBoxesChecked"
        (loanSelectVisibleChange)="updateLoanSelectVisibility($event)"
        [(settings)]="settings"
        ></app-selected-loan>
    </div>
  </div>

  <div
    style="
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 999999;
    "
    *ngIf="showSpinner"
  >
    <mat-spinner></mat-spinner>
  </div>
</div>
