import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { values } from 'lodash';
import { Subject, takeUntil } from 'rxjs';
import { RequestChangeService } from 'src/@ccmc/services/request-change.service';

interface LoanType {
  value: string;
  viewValue: string;
}

type LoanTypeObj = {
  selLoanType: any,
  values: any[]
};

@Component({
  selector: 'app-new-loan-type-dialog',
  templateUrl: './new-loan-type-dialog.component.html',
  styleUrls: ['./new-loan-type-dialog.component.scss']
})
export class NewLoanTypeDialogComponent implements OnInit {
  
  newLoanType: any;
  newProductName: any;
  newCoreValue: any;
  selectedLoanType: any;
  unsubscribe: Subject<any> = new Subject();
  newLoanTypeObj: LoanTypeObj = {
    selLoanType: '',
    values: []
  }
  newLoanTypeValues = {
    ignored: false,
    displayName: '',
    value: '',
    businessLogic: '',
  }
  changeRequestDoc: any;

  loanTypesArr: any[] = [];
  valuesArr: any[] = [];
  constructor(
    private dialogRef: MatDialogRef<NewLoanTypeDialogComponent>, 
    private requestChangeService: RequestChangeService) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.requestChangeService.changeRequestDocStatuses
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(fields => {
      console.log(fields);
      // console.log(check);
      this.changeRequestDoc = fields;
      if (fields.loanTypes) {
        this.loanTypesArr = fields.loanTypes;
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  submitNewLoanType() {
    this.newLoanTypeValues = {
      ignored: false,
      displayName: this.newProductName,
      value: this.newCoreValue,
      businessLogic: '',
    }
    this.valuesArr.push(this.newLoanTypeValues);
    this.newLoanTypeObj = {
      selLoanType: this.selectedLoanType,
      values: this.valuesArr
    };
    let loanTypeIndex = this.loanTypesArr.findIndex((loanType: any) => {
      return loanType.selLoanType === this.selectedLoanType;
    })
    if(loanTypeIndex > -1 && this.loanTypesArr[loanTypeIndex].values.length > 0) {
      console.log('it gets here');
        this.loanTypesArr[loanTypeIndex].values.push(this.newLoanTypeValues);
    } else if (loanTypeIndex > -1 && this.loanTypesArr[loanTypeIndex].values.length === 0) {
      this.loanTypesArr.splice(loanTypeIndex,1);
      this.loanTypesArr.push(this.newLoanTypeObj);
    } else {
        console.log('else');
        this.loanTypesArr.push(this.newLoanTypeObj);
      }
     this.changeRequestDoc.loanTypes = this.loanTypesArr;
     this.requestChangeService.postDynamoChangeRequestStatuses(this.changeRequestDoc);
     this.dialogRef.close(this.loanTypesArr);
  }

  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }
}
