import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { DocumentConnectorService } from 'src/@ccmc/services/doc-connector.service';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { STATUS_MESSAGES, STATUS_VALUES } from '../constants';

@Component({
  selector: 'app-selected-loan',
  templateUrl: './selected-loan.component.html',
  styleUrls: ['./selected-loan.component.scss']
})
export class SelectedLoanComponent implements OnInit {
  private _isAllBoxesChecked = false;
  private _settings: any = {};
  private selectedSub: Subscription;
  private dcSub: Subscription;
  private isNXTSoftUser = false;
  @Input() 
  dcDoc: any;
  selectedFile: any;
  selectedLoan: any;
  selectedLoanDisplayedColumns = [
    'checkBox',
    'fileName',
    'source',
    'destination',
    'statusMessage'
  ];
  showDetailedStatusMessage = false;
  selectedLoanDataSource: any;
  unsubscribe: Subject<any> = new Subject();
  destinations: any;
  @Input() 
  checkedFiles: Map<string, any> = new Map<string, any>();
  @Output() 
  checkedFilesChanged: EventEmitter<Map<string, any>> = new EventEmitter<Map<string, any>>();

  @Input() 
  isLoanSelectVisible: boolean = false;
  @Output() 
  loanSelectVisibleChange = new EventEmitter<boolean>();

  @Input() 
  set settings(value: any) {
    this._settings = value;
    this.settingsChange.emit(this._settings);
  }

  get settings(): any {
    return this._settings;
  }

  @Output() 
  settingsChange = new EventEmitter<any>();

  @Input() 
  set isAllBoxesChecked(value: boolean) {
    this._isAllBoxesChecked = value;
    this.isAllBoxesCheckedChange.emit(this._isAllBoxesChecked);
  }

  get isAllBoxesChecked(): boolean {
    return this._isAllBoxesChecked;
  }

  @Output() 
  isAllBoxesCheckedChange = new EventEmitter<boolean>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(
    private dcService: DocumentConnectorService,
    private assetService: AssetService,
    private dialog: MatDialog,
    private selectedFieldService: CCMCSelectedFieldService,
    private amplifyService: AmplifyService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  @HostListener('document:keydown', ['$event'])
  handleUndo(event: KeyboardEvent) {
    if (!this.isNXTSoftUser) {
      return;
    }
    if (event.ctrlKey && event.key === 'z') {
      this.showDetailedStatusMessage = !this.showDetailedStatusMessage;
      this.refreshTable();
    }
  }

  refreshTable() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.getData();
    this.isLoanSelectVisible = true;
    this.loanSelectVisibleChange.emit(true);
    this.validateNXTSoftUser();
  }

  isCheckBox(element: any) {
    if (this.checkedFiles.has(element.fileName)) {
      return true;
    }
    return false;
  }

  getCheckBoxToolTipMsg(element:any) {
    if (!this.getStatus(element)?.toLowerCase()?.includes('failed')) {
      return "";
    }

    if (this.isAllBoxesChecked) {
      return "Right click to unselect all";
    } 
    return "Right click to select all";
  }

  onRightClick(event: any): void {
    event.preventDefault();
    if (!this.isAllBoxesChecked) {
      if (this.selectedLoan && this.selectedLoan.files) {
        for (const file of this.selectedLoan.files) {
          if (this.getStatus(file)?.toLowerCase()?.includes('failed')) {
            if (!this.checkedFiles.has(file.fileName)) {
              this.checkedFiles.set(file.fileName, file);
            }
          }
        }
      }
    } else {
      this.checkedFiles.clear();
    }
    
    this.isAllBoxesChecked = !this.isAllBoxesChecked;
  }

  onCheckboxChange(event: any, element: any) {
    if (event.checked) {
      if (!this.checkedFiles.has(element.fileName)) {
        this.checkedFiles.set(element.fileName, element);
      }
    } else {
      this.checkedFiles.delete(element.fileName);
    }
     this.checkedFilesChanged.emit(this.checkedFiles);
  }
  
  isSomeSelected(): boolean {
    return this.selectedLoanDataSource.data.length > 0 && this.selectedLoanDataSource.data.some((element: any) => 
      this.getStatus(element)?.toLowerCase()?.includes('failed') && 
      this.checkedFiles.has(element?.fileName)
    ) && !this.isAllBoxesChecked;
  }
  
  toggleSelectAll(checked: boolean): void {
    if (checked) {
      if (this.selectedLoan && this.selectedLoan.files) {
        for (const file of this.selectedLoan.files) {
          if (this.getStatus(file)?.toLowerCase()?.includes('failed')) {
            if (!this.checkedFiles.has(file.fileName)) {
              this.checkedFiles.set(file.fileName, file);
            }
          }
        }
      }
    } else {
      this.checkedFiles.clear();
    }
    
    this.isAllBoxesChecked = !this.isAllBoxesChecked;
  }

  private async validateNXTSoftUser() {
    if (await this.amplifyService.checkNXTsoftUserGroup()) {
      this.isNXTSoftUser = true;
    }
  }

  onSelectFile(selectedFile: any) {
    this.selectedFile = selectedFile;
  }

  getData() {
    this.selectedSub = this.selectedFieldService.onHistoryLoanFieldSelected
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(selected => {
        console.log('Selected History Loan', selected);
        this.selectedLoan = selected;
        if (this.selectedLoan && this.selectedLoan.files) {
          this.selectedLoanDataSource = new MatTableDataSource(
            this.selectedLoan.files
          );
          // initializes sort
          this.selectedLoanDataSource.sort = this.sort;
          this.selectedLoanDataSource.sortingDataAccessor = (item: any, property: any) => {
            switch (property) {
              case 'fileName': return new String(item.fileName);
              case 'source': return new String(item.source);
              case 'destination': return new String(item.destination);
              case 'statusMessage': return new String(item.statusMessage);
              default: return item[property];
            }
          };
        } else {
          this.selectedLoanDataSource = new MatTableDataSource([]);
          // initializes sort
          this.selectedLoanDataSource.sort = this.sort;
          this.selectedLoanDataSource.sortingDataAccessor = (item: any, property: any) => {
            switch (property) {
              case 'fileName': return new String(item.fileName);
              case 'source': return new String(item.source);
              case 'destination': return new String(item.destination);
              case 'statusMessage': return new String(item.statusMessage);
              default: return item[property];
            }
          };
        }
      });

    this.dcSub = this.dcService.dcDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dcDocument => {
        if (dcDocument) {
          if (dcDocument.destinations) {
            this.destinations = dcDocument.destinations;
          }
        } else {
          this.destinations = undefined;
        }
      });
  }

  isRetry(element: any): boolean {
    const retryTimestampStr = element?.retryTimestamp;
    if (retryTimestampStr) { 
      const retryTimestamp = Number(retryTimestampStr); // Convert string to number
      if (!isNaN(retryTimestamp)) { // Ensure the conversion was successful
        const fiveMinutesAgo = Date.now() - 5 * 60 * 1000;
        return retryTimestamp > fiveMinutesAgo; // Return true if it's within the last 5 minutes
      }
    }
    return false; 
  }

  getStatus(element: any): string {
    const statusMessage = element.statusMessage || "";

    const lowerCaseStatusMessage = statusMessage.toLowerCase();
    // Additional conditions for checking specific messages after one hour
    const isStatusAllowedAfterHour =
    lowerCaseStatusMessage.includes(STATUS_MESSAGES.LOAN_FILE_EXPORTED);

    if (this.isRetry(element) && !lowerCaseStatusMessage.includes(STATUS_MESSAGES.LOAN_FILE_EXPORTED) && !lowerCaseStatusMessage.includes("fail")) {
      return "Retrying File Transfer";
    }
  
    // Check if the status message matches the relevant conditions
    const isRelevantMessage =
      lowerCaseStatusMessage.includes(STATUS_MESSAGES.DOCUMENT_RETRIEVED) ||
      lowerCaseStatusMessage.includes(STATUS_MESSAGES.PROCESSED_FILE) ||
      lowerCaseStatusMessage.includes(STATUS_MESSAGES.SENDING_FILE);
  
    // Convert selectedLoan.date to a Date object if it's a string, or use it directly if it's a timestamp
    const loanDateInMs = typeof this.selectedLoan.date === 'string' ? new Date(this.selectedLoan.date).getTime() : this.selectedLoan.date;
    const oneHourAgo = Date.now() - 60 * 60 * 1000; // Calculate the time one hour ago in milliseconds
  
    // Check if the loan date is older than one hour
    const isOlderThanOneHour = loanDateInMs < oneHourAgo;

    if (isStatusAllowedAfterHour) {
      return statusMessage;
    }

    // Determine the status to return
    if (!isRelevantMessage || isOlderThanOneHour && isRelevantMessage || this.selectedLoan?.status == STATUS_VALUES.UNSUCCESSFUL) {
      return 'Failed to transfer loan file. Please review logs for more details.';
    }

    return statusMessage;
  }

  getDetailedStatus(element: any): string {
    if (this.showDetailedStatusMessage) {
      return "";
    }

    if (!this.isNXTSoftUser || this.isRetry(element) && !this.getStatus(element).toLowerCase().includes("fail")) {
      return "";
    }

    const statusMessage = element.statusMessage || "";
    const lowerCaseStatusMessage = statusMessage.toLowerCase();
  
    // Check if the status message matches the relevant conditions
    const isRelevantMessage =
      lowerCaseStatusMessage.includes(STATUS_MESSAGES.DOCUMENT_RETRIEVED) ||
      lowerCaseStatusMessage.includes(STATUS_MESSAGES.PROCESSED_FILE) ||
      lowerCaseStatusMessage.includes(STATUS_MESSAGES.SENDING_FILE);

    if (lowerCaseStatusMessage.includes(STATUS_MESSAGES.LOAN_FILE_EXPORTED) || lowerCaseStatusMessage.includes('please review logs')) {
      return "";
    }
  
    // Convert selectedLoan.date to a Date object if it's a string, or use it directly if it's a timestamp
    const loanDateInMs = typeof this.selectedLoan.date === 'string' ? new Date(this.selectedLoan.date).getTime() : this.selectedLoan.date;
    const oneHourAgo = Date.now() - 60 * 60 * 1000; // Calculate the time one hour ago in milliseconds
  
     // Check if the loan date is older than one hour
     const isOlderThanOneHour = loanDateInMs < oneHourAgo;

     if (!isRelevantMessage) {
      return statusMessage;
     }

    if (!isRelevantMessage || isOlderThanOneHour && isRelevantMessage || this.selectedLoan?.status == STATUS_VALUES.UNSUCCESSFUL) {
      return `Failed to update DynamoDB or the Lambda function may have timed out. Last recorded status message: "${statusMessage}"`;
    }
  
    return "";
  }
  

  goToSelectedLoansDestination(selectedFile: any) {
    for (let destination of this.destinations) {
      if (selectedFile.destination === destination.id) {
        this.selectedFieldService.onDestinationFieldSelected.next(destination);
        this.router.navigate(['/doc-connector/source-and-destination']);
      }
    }
  }
}
