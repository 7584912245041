import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { CCMCSharedModule } from 'src/@ccmc/shared.module';
import { LoggingComponent } from './logging.component';
import { AssetGroupComponent } from './asset-group/asset-group.component';
import { CoreGroupComponent } from './core-group/core-group.component';
import { UserGroupComponent } from './user-group/user-group.component';

const routes: Routes = [
  {
    path: 'logging',
    component: LoggingComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    LoggingComponent,
    AssetGroupComponent,
    CoreGroupComponent,
    UserGroupComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CCMCSharedModule,
    KeyboardShortcutsModule.forRoot()
  ],
  exports: [LoggingComponent]
})
export class LoggingModule { }
