import { CanDeactivate } from '@angular/router';
import { GlaTranslatorsComponent } from './gla-translators.component';

/**
 * Deactivate Translators Route
 *
 * @export
 * @class DeactivateTranslators
 * @implements {CanDeactivate<GlaTranslatorsComponent>}
 */

export class DeactivateGlaTranslators
  implements CanDeactivate<GlaTranslatorsComponent>
{
  /**
   * Controls if user can route away from the translators component
   *
   * @param {TranslatorsComponent} component
   * @returns
   * @memberof DeactivateTranslators
   */
  async canDeactivate(component: GlaTranslatorsComponent) {
    return component.canDeactivate();
  }
}
