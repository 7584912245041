import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { DocumentConnectorService } from 'src/@ccmc/services/doc-connector.service';
import { takeUntil, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-doc-connector-source',
  templateUrl: './doc-connector-source.component.html',
  styleUrls: ['./doc-connector-source.component.scss']
})
export class DocConnectorSourceComponent implements OnInit {
  @ViewChild('filterSearch', { static: true }) filterSearchEl: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns = ['name', 'index', 'vbScript'];
  sourceCol = ['sources'];
  fieldEdited: any;
  unsubscribe: Subject<any> = new Subject();
  public selected: any;
  filterSearch: string;
  testNumber = 100;
  testNumber2 = 30;
  testData = {
    name: 'name',
    index: 1,
    vbScript: 'test'
  }
  testData2 = {
    sources: 'source'
  }
  dataSource:any;
  testDocument: any;
  private dcSub: Subscription;
  dataArray : any;
  dataArray2: any;
  filterSearchText: string;
  constructor(
    private dcService: DocumentConnectorService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getData();
    this.generateSampleData(this.testNumber);
    this.generateSourceData(this.testNumber2);
  }

  onSelect(selected: any) {
    this.selected = selected;
  }

  getData(){
    this.dcSub = this.dcService.dcDocument
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(data => {
        if(data){
          this.testDocument = JSON.parse(JSON.stringify(data));
        }
    });
  }

  generateSampleData(testNumber: any){
    this.dataArray = [];
    for(let i = 0; i < testNumber; i++){
      this.testData.index  = i;
      let tempData = {...this.testData};
      this.dataArray.push(tempData);
      }
      this.dataSource = new MatTableDataSource(this.dataArray);
      this.dataSource.paginator = this.paginator;
  }

  generateSourceData(testNumber2: any){
    this.dataArray2 = [];
    for(let i = 0; i < testNumber2; i++){
      let tempData2 = {...this.testData2};
      this.dataArray2.push(tempData2);
      }
  }
  
}
