import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { fromEvent, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  takeUntil
} from 'rxjs/operators';
import { CCMCConfirmDialogComponent } from 'src/@ccmc/components/confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from 'src/@ccmc/components/error-dialog/error-dialog.component';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { DocumentConnectorService } from 'src/@ccmc/services/doc-connector.service';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
import { DocConnectorLogsService } from './doc-connector-logs.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-doc-connector-logs',
  templateUrl: './doc-connector-logs.component.html',
  styleUrls: ['./doc-connector-logs.component.scss']
})
export class DocConnectorLogsComponent implements OnInit {
  displayedColumns = ['loanNumber', 'fileType', 'date', 'service', 'message'];
  logs: any;
  unsubscribe: Subject<any> = new Subject();
  dataSource: any;
  private spinnerSub: Subscription;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('filterSearch', { static: true }) filterSearchEl: ElementRef;
  filterSearch: string;
  showSpinner: boolean;
  testNumber = 100;
  selected: any;
  currentLog: any;
  currentMessage: any;
  maxDate = new Date();
  endDate = new FormControl(new Date());
  startDate = new FormControl(
    new Date(this.endDate.value.getTime() - 24 * 60 * 60 * 1000)
  );
  filterChanged: Subject<string> = new Subject<string>();
  currentLogsLoaded: any = false;
  tempLogs: any[] = [];
  assetID: any;
  constructor(
    private spinnerService: SpinnerService,
    private dcService: DocumentConnectorService,
    private matDialog: MatDialog,
    private dcLogsService: DocConnectorLogsService,
    private ccmcApiService: CcmcApiService,
    private assetService: AssetService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  getData() {
    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        this.showSpinner = spinner;
      });

    this.initFilterSearch();
  }
  initFilterSearch() {
    // Auto Focus filter search item
    this.filterSearchEl.nativeElement.focus();
    fromEvent(this.filterSearchEl.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // Time in milliseconds between key events
        debounceTime(1000),
        // If previous query is diffent from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((text: string) => {
        this.applyFilter(text);
      });
  }

  applyFilter(filterValue: string) {
    // trim and lowercase filter value
    this.dataSource.filter = filterValue.trim().toLowerCase();
    // Set filter search
    this.filterSearch = filterValue;
  }

  async getLogs() {
    this.spinnerService.setShowSpinner(true);
    this.openSnackBar('Fetching doc connector logs, this may take a moment', 'Okay');
    this.assetID = this.assetService.getSelectedAssetId();
    // Catch invalid date parameter
    if (this.endDate.value === null) {
      this.matDialog.open(ErrorDialogComponent, {
        data: {
          title: 'Error',
          message: 'Please enter a valid date.'
        }
      });
      return;
    }
    // Set lower and upper bounds for times
    const finalStartDate = this.startDate.value.setHours(0, 0, 0, 0);
    const finalEndDate = this.endDate.value.setHours(23, 59, 59, 999);
    // const loggingParams = {
    //   startDate: new Date(finalStartDate).getTime(),
    //   endDate: new Date(finalEndDate).getTime()
    // };

    // Init logging params
    let loggingParams = {
      startDate: new Date(finalStartDate).getTime(),
      endDate: new Date(finalEndDate).getTime()
    };
    let getLogsResponse: any;
    let logs: any = [];
    let previousLogCount = 0;
    let count = 1;

    // Get logs
    getLogsResponse = await this.getCloudWatchLogs(loggingParams);
    if (!getLogsResponse.statusFlag) {
      this.matDialog.open(ErrorDialogComponent, {
        data: {
          title: 'Error',
          message: getLogsResponse.statusMessage
        }
      });
      return;
    }
    if (getLogsResponse.content.nextToken) {
      let currentNextToken: any = getLogsResponse.content.nextToken;
      let getMoreLogsResponse: any;
      logs = [...logs, ...getLogsResponse.content.events];
      // Loop getting more logs until we get a duplicate next token
      do {
        if (getMoreLogsResponse && getMoreLogsResponse.content.nextToken) {
          currentNextToken = getMoreLogsResponse.content.nextToken;
        }

        let moreLoggingParams = {
          startDate: new Date(finalStartDate).getTime(),
          endDate: new Date(finalEndDate).getTime(),
          nextToken: currentNextToken
        };
        // Get More Logs
        getMoreLogsResponse = await this.getCloudWatchLogs(moreLoggingParams);
        // End log fetching process when either the next token is repeated
        // or the max number of loops has been reached to conserve resources.
        if ((getMoreLogsResponse && getMoreLogsResponse.content.nextToken === currentNextToken) || count === 30) {
          console.log("No more logs to fetch");
          if (count === 30) {
            this.matDialog.open(ErrorDialogComponent, {
              data: {
                title: 'Error',
                message: 'Process ended early and may not have retrieved all logs, please shorten date range.'
              }
            });
          }
          break;
        }
        if (!getMoreLogsResponse.statusFlag) {
          this.matDialog.open(ErrorDialogComponent, {
            data: {
              title: 'Error',
              message: getMoreLogsResponse.statusMessage
            }
          });
          return;
        }

        logs = [...logs, ...getMoreLogsResponse.content.events];
        console.log('Current Token', currentNextToken);
        if (logs && logs.length > 0 && previousLogCount !== logs.length) {
          // To prevent spamming the same message to the user
          // it will only display when there is an update to report.
          this.openSnackBar('Fetching doc connector logs, current total: ' + logs.length, 'Okay');
          previousLogCount = logs.length;
        }
        console.log(`current number of loops`, count);
        count++;
      } while (currentNextToken);
      this.openSnackBar('Finished fetching doc connector logs', 'Okay');
      this.spinnerService.setShowSpinner(false);

      // Set logs
      this.currentLogsLoaded = true;
      this.tempLogs = [];
      for (let log of logs) {
        let tempLog = JSON.parse(log.message);
        if (tempLog.loanNumber === '') {
          tempLog.loanNumber = 'Not Assigned';
        }
        tempLog.date = new Date(log.timestamp);
        tempLog.date = tempLog.date.toLocaleString();
        this.tempLogs.push(tempLog);
      }
      this.logs = logs;
      console.log(this.logs);
      // Set table data
      this.dataSource = new MatTableDataSource(this.tempLogs);
      // initializes pagination
      this.dataSource.paginator = this.paginator;
      // initializes sort
      this.dataSource.sort = this.sort;
      // Select Initial Log
      this.onSelect(this.dataSource.data[0]);
    } else {
      this.spinnerService.setShowSpinner(false);
      const errorMessage = {
        message: 'No Logs found.',
        title: 'Logging'
      };
      this.matDialog.open(ErrorDialogComponent, {
        data: errorMessage
      });
    }
  }

  onSelect(selected: any) {
    this.selected = selected;
    this.currentMessage = selected.message || '';
  }

  async getCloudWatchLogs(body: any) {
    return new Promise((resolve) => {
      this.dcLogsService.getDCLogs(body).subscribe((result: any) => {
        const getLogsResponse = JSON.parse(JSON.stringify(result));
        console.log('get doc connector logs response', getLogsResponse);
        return resolve(getLogsResponse);
      });
    });
  }

  exportToCSV() {
    const dialogRef = this.matDialog.open(CCMCConfirmDialogComponent, {
      data: 'Continue exporting doc connector logs to CSV?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinnerService.setShowSpinner(true);
        if (this.tempLogs) {
          let tempLogsCopy = JSON.parse(JSON.stringify(this.tempLogs));
          tempLogsCopy.forEach((log:any) => {
            log.date = new Date(log.date).toLocaleString();
          })
          this.ccmcApiService
            .convertJSONToCSV(tempLogsCopy)
            .subscribe(result => {
              this.spinnerService.setShowSpinner(false);
              console.log(result);
              if (result) {
                const parsedResult = JSON.parse(JSON.stringify(result));
                if (parsedResult.statusFlag) {
                  var blob = new Blob([parsedResult.content], {
                    type: 'text/csv;charset=utf-8;'
                  });
                  var link = document.createElement('a');
                  if (link.download !== undefined) {
                    // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    if(this.startDate.value.toLocaleDateString() !== this.endDate.value.toLocaleDateString()) {
                      link.setAttribute(
                        'download',
                        `${this.assetID}-${this.startDate.value.toLocaleDateString()}-${this.endDate.value.toLocaleDateString()}-docconnectorlogs.csv`
                      );
                    } else {
                      link.setAttribute(
                        'download',
                        `${this.assetID}-${this.startDate.value.toLocaleDateString()}-docconnectorlogs.csv`
                      );
                    }
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }
                } else {
                  const errorDialogRef = this.matDialog.open(
                    ErrorDialogComponent,
                    {
                      data: {
                        title: 'Export Error',
                        message: parsedResult.statusMessage
                      }
                    }
                  );
                }
              }
            });
        }
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
