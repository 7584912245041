<div style="width: 40%; min-width: 400px; background-color: e1e1e1">
  <h1>Create A New Set Transaction</h1>
  <div *ngIf="newTransaction">
    <mat-form-field style="width: 100%">
      <input matInput placeholder="Transaction Name" [(ngModel)]="newTransaction.Name" />
    </mat-form-field>
  </div>
  <div style="display: flex; justify-content: space-between">
    <button mat-raised-button matTooltip="Close Dialog" matTooltipClass="toolTipStyle" (click)="closeDialog()"
      style="text-align: left; max-width: 75px">
      Cancel
    </button>
    <button mat-raised-button matTooltip="Create Set. Update the transactions next." matTooltipClass="toolTipStyle"
      color="accent" (click)="create()" style="text-align: right; max-width: 175px">
      Create Transaction
    </button>
  </div>
</div>