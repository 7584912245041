import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ccmc-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class CCMCConfirmDialogComponent {
  public confirmMessage: string;

  /**
   * Creates an instance of CCMCConfirmDialogComponent.
   * @param {MatDialogRef<CCMCConfirmDialogComponent>} dialogRef
   * @param {*} message
   * @memberof CCMCConfirmDialogComponent
   */
  constructor(
    public dialogRef: MatDialogRef<CCMCConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: any
  ) {}
}
