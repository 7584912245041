<ng-container *ngIf="!item.hidden">

    <div class="group-title" *ngIf="item.visible === true">
        <span *ngIf="item.visible === true" class="hint-text" [translate]="item.translate">{{ item.title }}</span>
        <span *ngIf="item.visible === false" style="display: none;" [translate]="item.translate">{{ item.title }}</span>
    </div>
    <div class="group-title" *ngIf="item.visible === false" style="display: none">
        <span *ngIf="item.visible === false" class="hint-text" [translate]="item.translate">{{ item.title }}</span>
    </div>

    <div class="group-items">
        <ng-container *ngFor="let item of item.children">
            <ccmc-nav-vertical-group *ngIf="item.type=='group'" [item]="item"></ccmc-nav-vertical-group>
            <ccmc-nav-vertical-collapse *ngIf="item.type=='collapse'" [item]="item"></ccmc-nav-vertical-collapse>
            <ccmc-nav-vertical-item *ngIf="item.type=='item'" [item]="item"></ccmc-nav-vertical-item>
        </ng-container>
    </div>

</ng-container>