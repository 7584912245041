import { Injectable, NgZone } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, filter } from 'rxjs/operators';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { CcmcApiService } from './ccmc-api.service';
import { AmplifyService } from './amplify.service';
import { environment } from 'src/environments/environment';
const { Buffer } = require('buffer');
@Injectable({
  providedIn: 'root'
})
export class AvatarService {
  public avatar: BehaviorSubject<any> = new BehaviorSubject({});
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private customErrorHandlerService: CustomErrorHandlerService,
    private zone: NgZone,
    private ccmcApiService: CcmcApiService,
    private amplifyService: AmplifyService
  ) {
    this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, PUT'
    );
    this.httpOptions.headers.append('Accept', 'application/json');
  }

  /**
   * Set Avatar
   * @description sets the avatar for the user
   * @param {string} avatar
   * @memberof AvatarService
   */
  setAvatar(avatar: string) {
    console.log('Set Avatar to: ', avatar);
    this.avatar.next(avatar);
  }

  /**
   * Get Avatar
   * @description gets the avatar for the user
   * @returns
   * @memberof AvatarService
   */
  getAvatar() {
    console.log(this.avatar);
    return this.avatar;
  }

  /**
   * Update Avatar
   * @description Updates the avatar with new given string
   * @param {string} avatar
   * @returns
   * @memberof AvatarService
   */
  updateAvatar(avatar: string) {
    let userName = this.amplifyService.username;
    if (userName) {
      userName.toLowerCase();
    }
    const params = {
      email: userName,
      userAttributes: [
        {
          Name: 'picture',
          Value: avatar
        }
      ]
    };
    console.log(params);
    
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/cognito/update-user-attributes`,
          params,
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }
}
