import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HorizonXchangeCollateralSearchDialogComponent } from './horizon-xchange-collateral-search-dialog.component';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CCMCCollateralDetailsDialogModule } from '../collateral-search-dialog/collateral-details-dialog/collateral-details-dialog.module';
import { PledgeValueDialogComponent } from './pledge-value-dialog/pledge-value-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { CollateralDetailDialogComponent } from './collateral-detail-dialog/collateral-detail-dialog.component';

@NgModule({
  declarations: [
    HorizonXchangeCollateralSearchDialogComponent,
    PledgeValueDialogComponent,
    CollateralDetailDialogComponent
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    CCMCCollateralDetailsDialogModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule
  ],
  entryComponents: [HorizonXchangeCollateralSearchDialogComponent],
  exports: [HorizonXchangeCollateralSearchDialogComponent]
})
export class HorizonXchangeCollateralSearchDialogModule {}
