<h1>Set Spectrum Credentials</h1>
  <div
    style="
      row-gap: 15px;
      padding: 15px;
      background-color: #e0e0e0;
      border-radius: 5px;
      margin-bottom: 10px;
    "
  >
  <form>
    <div>
      <mat-form-field style="width: 100%">
        <input
          type="text"
          style="width: 100%"
          matInput
          placeholder="Teller ID"
          name="tellerID"
          [(ngModel)]="localSpectrumUserCredentialItem.tellerID"
          (keydown.enter)="updateCredentials()"
          (ngModelChange)="checkForm()"
        />
      </mat-form-field>
    </div>
  </form>
  </div>
  <!--bottom row of buttons-->
  <div id="bottom-button-container">
    <!--Cancel Button-->
    <button
      mat-stroked-button
      class="cancel-btn"
      (click)="closeDialog()"
      id="cancel-button"
    >
      Close
    </button>

    <!--Continue Button-->
    <div style="text-align: right">
      <button
        mat-raised-button
        class="continue-btn"
        (click)="updateCredentials()"
        id="continue-button"
        [disabled]="editedFlag === false"
      >
        <span class="button-text">Submit &nbsp;</span>
      </button>
    </div>
  </div>

