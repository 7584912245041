import { Injectable, NgZone } from '@angular/core';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CcmcApiService } from './ccmc-api.service';
import { CCMCCollateralSearchDialogComponent } from '../components/collateral-search-dialog/collateral-search-dialog.component';
import { Router } from '@angular/router';
import { navigation } from '../../app/navigation/navigation';
import { SpinnerService } from './spinner.service';
import { ActiveLosService } from './active-los.service';
import { SuccessDialogComponent } from '../components/success-dialog/success-dialog.component';
import { multicast, catchError, filter } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { AssetService } from './asset.service';
import { environment } from 'src/environments/environment';
import { GCSettingsService } from './gcsettings.service';
import { BasePortalOutlet } from '@angular/cdk/portal';
import { CaptureDataService } from './capture-data.service';
import { AmplifyService } from './amplify.service';
import { GlobalSearchService } from './global-search.service';
import { TargetResponseDialogComponent } from '../components/target-response-dialog/target-response-dialog.component';
const { Buffer } = require('buffer');

@Injectable({
  providedIn: 'root'
})
export class PrecisionService {
  dynamicNav: any = navigation;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private dialog: MatDialog,
    private ccmcApiService: CcmcApiService,
    public router: Router,
    private spinnerService: SpinnerService,
    private activeLosService: ActiveLosService,
    private zone: NgZone,
    private http: HttpClient,
    private customErrorHandlerService: CustomErrorHandlerService,
    private assetService: AssetService,
    private amplifyService: AmplifyService,
    private gcSettingsService: GCSettingsService,
    private captureDataService: CaptureDataService,
    private globalSearchService: GlobalSearchService
  ) {
    this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, PUT'
    );
    this.httpOptions.headers.append('Accept', 'application/json');
  }

  /**
   * Send
   * @description sets loan up for export
   * @memberof PrecisionService
   */
  send() {
    this.createShortnames();
  }

  /**
   * Collateral Search
   * @description searches for collateral
   * books the loan and returns the response
   * sends the booking results back to nCino
   *
   * @memberof PrecisionService
   */
  collateralSearch() {
    // retrieve the index where the transactionid is 'coll-01'
    const CIFTransactionIndex = this.ccmcApiService.transactions.findIndex(
      (obj: any) => obj.transactionID.toLowerCase() === 'coll-01'
    );

    // determines if the display of the selected transaction is 'Y'
    const containsCollateral =
      this.ccmcApiService.transactions[CIFTransactionIndex].display === true;

    if (containsCollateral) {
      this.spinnerService.setShowSpinner(false);
      const dialogRef = this.dialog.open(CCMCCollateralSearchDialogComponent, {
        panelClass: 'collateral-search__dialog'
      });

      dialogRef.afterClosed().subscribe(send => {
        if (send === true) {
          this.spinnerService.setShowSpinner(true);
          this.export().subscribe(async result => {
            const parsedResult = JSON.parse(JSON.stringify(result));
            console.log(parsedResult);
            if (parsedResult.error) {
              const errorMessage = {
                statusMessage: "Ground Control failed to receive a response back from the communicator",
                statusFlag: false
              };
              const dialogRef2 = this.zone.run(() => {
                this.dialog.open(TargetResponseDialogComponent, {
                  data: errorMessage
                });
              });
              await this.ccmcApiService.logExportResponse({
                assetID: this.assetService.getSelectedAssetId(),
                loanNumber: this.getLoanNumber(),
                statusMessage: parsedResult.error,
                statusFlag: false
              });
            }
            if (parsedResult.statusFlag === false) {
              if (parsedResult.content && parsedResult.content.mapping) {
                // Retrieve Validation From existing mapping object
                let existingMappingCopy = JSON.parse(
                  JSON.stringify(this.ccmcApiService.mapping)
                );
                for (let mappingItem of parsedResult.content.mapping) {
                  let existingMappingIndex = existingMappingCopy.findIndex(
                    (obj: any) => obj.fieldID === mappingItem.fieldID
                  );
                  if (
                    existingMappingIndex > -1 &&
                    existingMappingCopy[existingMappingIndex].validation
                  ) {
                    mappingItem.validation =
                      existingMappingCopy[existingMappingIndex].validation;
                  }
                }
                this.ccmcApiService.mapping = parsedResult.content.mapping;
              }
              if (parsedResult.content && parsedResult.content.transactions) {
                this.ccmcApiService.transactions =
                  parsedResult.content.transactions;
              }
              this.globalSearchService.initData();
              const errorMessage = {
                statusMessage: parsedResult.statusMessage,
                statusFlag: false
              };
              const dialogRef = this.dialog.open(
                TargetResponseDialogComponent,
                {
                  data: errorMessage
                }
              );
              await this.ccmcApiService.logExportResponse({
                assetID: this.assetService.getSelectedAssetId(),
                loanNumber: this.getLoanNumber(),
                statusMessage: parsedResult.statusMessage,
                statusFlag: parsedResult.statusFlag
              });
            }
            if (parsedResult.statusFlag === true) {
              const loanNumber = this.getLoanNumber();
              const resultMessage = {
                statusMessage: parsedResult.statusMessage,
                loanNumber: loanNumber,
                statusFlag: true
              };
              const dialogRef = this.dialog.open(
                TargetResponseDialogComponent,
                {
                  data: resultMessage
                }
              );
              await this.ccmcApiService.logExportResponse({
                assetID: this.assetService.getSelectedAssetId(),
                loanNumber: loanNumber,
                statusMessage: parsedResult.statusMessage,
                statusFlag: parsedResult.statusFlag
              });
                         
              if (parsedResult.statusFlag === true) {
                this.activeLosService.activeLosService.loanBooked(
                  parsedResult,
                  JSON.parse(JSON.stringify(this.ccmcApiService.configurations))
                );
                this.ccmcApiService.mapping = undefined;
                this.ccmcApiService.coreSettings = undefined;
                this.ccmcApiService.conditions = undefined;
                this.ccmcApiService.mapping = undefined;
                this.ccmcApiService.supportValues = undefined;
                this.ccmcApiService.search = undefined;
                this.ccmcApiService.transactions = undefined;
                this.ccmcApiService.configurations = undefined;
                if (this.dynamicNav[1].children[1]) {
                  this.dynamicNav[1].children[1].children = [];
                }
                this.dynamicNav[1].children[0].badge.title = undefined;
                if (this.activeLosService.activeLos === 'encompass' || this.activeLosService.activeLos === 'bytepro') {
                  this.router.navigate(['loan-lookup']);
                } else {
                  this.router.navigate(['file-selector']);
                }
              }
            }
          });
        }
      });
    } else {
      this.spinnerService.setShowSpinner(true);
      this.export().subscribe(async result => {
        const parsedResult = JSON.parse(JSON.stringify(result));
        console.log(parsedResult);
        if (parsedResult.error) {
          const errorMessage = {
            statusMessage: "Ground Control failed to receive a response back from the communicator",
            statusFlag: false
          };
          const dialogRef2 = this.zone.run(() => {
            this.dialog.open(TargetResponseDialogComponent, {
              data: errorMessage
            });
          });
          await this.ccmcApiService.logExportResponse({
            assetID: this.assetService.getSelectedAssetId(),
            loanNumber: this.getLoanNumber(),
            statusMessage: parsedResult.error,
            statusFlag: false
          });
        }
        if (parsedResult.statusFlag === false) {
          const errorMessage = {
            statusMessage: parsedResult.statusMessage,
            statusFlag: false
          };
          const dialogRef = this.dialog.open(TargetResponseDialogComponent, {
            data: errorMessage
          });
          await this.ccmcApiService.logExportResponse({
            assetID: this.assetService.getSelectedAssetId(),
            loanNumber: this.getLoanNumber(),
            statusMessage: parsedResult.statusMessage,
            statusFlag: parsedResult.statusFlag
          });
        }
        if (parsedResult.statusFlag === true) {
          let loanNumber;
          if (parsedResult.loanNumber) {
            loanNumber = parsedResult.loanNumber;
          } else {
            loanNumber = this.getLoanNumber();
          }
          const resultMessage = {
            statusMessage: parsedResult.statusMessage,
            loanNumber: loanNumber,
            statusFlag: true
          };
          this.captureDataService.uploadS3File(this.ccmcApiService.mapping);
          this.activeLosService.activeLosService.loanBooked(
            parsedResult,
            JSON.parse(JSON.stringify(this.ccmcApiService.configurations))
          );

          await this.ccmcApiService.logExportResponse({
            assetID: this.assetService.getSelectedAssetId(),
            loanNumber: loanNumber,
            statusMessage: parsedResult.statusMessage,
            statusFlag: parsedResult.statusFlag
          });
          
          this.ccmcApiService.mapping = undefined;
          this.ccmcApiService.coreSettings = undefined;
          this.ccmcApiService.conditions = undefined;
          this.ccmcApiService.mapping = undefined;
          this.ccmcApiService.supportValues = undefined;
          this.ccmcApiService.search = undefined;
          this.ccmcApiService.transactions = undefined;
          this.ccmcApiService.configurations = undefined;
          if (this.dynamicNav[1].children[1]) {
            this.dynamicNav[1].children[1].children = [];
          }
          this.dynamicNav[1].children[0].badge.title = undefined;
          const dialogRef = this.dialog.open(TargetResponseDialogComponent, {
            data: resultMessage
          });
          dialogRef.afterClosed().subscribe(result => {
            if (this.activeLosService.activeLos === 'encompass' || this.activeLosService.activeLos === 'bytepro') {
              this.router.navigate(['loan-lookup']);
            } else {
              this.router.navigate(['file-selector']);
            }
          });
        }
      });
    }
  }

  /**
   * @description
   */
  /**
   * Customer Search
   * @description sets data up to perforom customer search in precision communicator
   * @param {*} borrower
   * @param {*} callback
   * @memberof PrecisionService
   */
  customerSearch(borrower: any, callback: any) {
    console.log(borrower);
    let userName = this.amplifyService.username;
    const request = {
      content: borrower,
      assetID: this.assetService.getSelectedAssetId(),
      user: userName
    };
    this.searchCustomer(request).subscribe(res => {
      console.log(res);
      const parsedResult = JSON.parse(JSON.stringify(res));
      if (parsedResult.error) {
        const errorMessage = {
          message: parsedResult.error.message,
          title: 'Precision Result'
        };
        const dialogRef2 = this.zone.run(() => {
          this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
        });
      }
      if (parsedResult.statusFlag === true) {
        this.spinnerService.setShowSpinner(false);
        const payload = parsedResult.content;
        if (payload.length > 0) {
          const searchResult = payload;
          console.log(searchResult);
          callback(searchResult);
        } else {
          const noResult = [
            {
              firstName: 'No Result',
              lastName: '',
              taxID: '',
              CIF: '',
              address: ''
            }
          ];
          const searchResult = noResult;
          console.log(searchResult);
          callback(searchResult);
        }
      } else if (parsedResult.statusFlag === false) {
        const errorMessage = {
          message: parsedResult.statusMessage,
          title: 'Search Error'
        };
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      }
    });
  }

  /**
   * Assign Borrowers
   * @description set the field value to the customer name
   * @param {*} party
   * @memberof PrecisionService
   */
  assignBorrowers(party: any) {
    console.log(party);
    for (let i = 0; i < party.length; i++) {
      const isNewIndex = this.ccmcApiService.mapping.findIndex(
        (obj: any) => obj.fieldID === party[i].isNew
      );
      if (party[i].CIF.toUpperCase() === 'NEW') {
        this.ccmcApiService.mapping[isNewIndex].fieldValue = 'true';
      } else {
        this.ccmcApiService.mapping[isNewIndex].fieldValue = 'false';
      }

      const CIFFields = party[i].CIFFields.split(', ');
      CIFFields.forEach((cf: any) => {
        const cifIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) => f.fieldID === cf
        );
        this.ccmcApiService.mapping[cifIndex].fieldValue = party[i].CIF;
      });
    }
  }

  /**
   * Create Shortnames
   * Performs the generate shortnames
   * @memberof PrecisionService
   */
  createShortnames() {
    console.log('creating Shortnames');
    this.spinnerService.setShowSpinner(true);
    let userName = this.amplifyService.username;
    const generateShortNameRequest: any = {
      configurations: this.ccmcApiService.configurations,
      searchParties: [],
      user: userName
    };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.ccmcApiService.search.customerSearch.length; i++) {
      const fnIndex = this.ccmcApiService.mapping.findIndex(
        (f: any) =>
          f.fieldID === this.ccmcApiService.search.customerSearch[i].firstName
      );
      const mnIndex = this.ccmcApiService.mapping.findIndex(
        (f: any) =>
          f.fieldID === this.ccmcApiService.search.customerSearch[i].middleName
      );
      const lnIndex = this.ccmcApiService.mapping.findIndex(
        (f: any) => f.fieldID === this.ccmcApiService.search.customerSearch[i].lastName
      );
      const taxIndex = this.ccmcApiService.mapping.findIndex(
        (f: any) => f.fieldID === this.ccmcApiService.search.customerSearch[i].taxID
      );
      const cifIndex = this.ccmcApiService.mapping.findIndex(
        (f: any) => f.fieldID === this.ccmcApiService.search.customerSearch[i].CIF
      );

      if ((fnIndex && mnIndex && lnIndex && taxIndex && cifIndex) > -1) {
        const borrower = {
          firstName: this.ccmcApiService.mapping[fnIndex].fieldValue,
          middleName: this.ccmcApiService.mapping[mnIndex].fieldValue,
          lastName: this.ccmcApiService.mapping[lnIndex].fieldValue,
          taxID: this.ccmcApiService.mapping[taxIndex].fieldValue,
          businessName: '',
          CIF: this.ccmcApiService.mapping[cifIndex].fieldValue,
          CIFFields: this.ccmcApiService.search.customerSearch[i].CIFFields
        };
        if (borrower.taxID !== '') {
          generateShortNameRequest.searchParties.push(borrower);
        }
      }
    }

    const newCIFIndex = generateShortNameRequest.searchParties.findIndex(
      (obj: any) => obj.CIF.toUpperCase() === 'NEW'
    );

    if (newCIFIndex > -1) {
      console.log('newCIFIndex greater than -1 ', newCIFIndex);
      let userName = this.amplifyService.username;
      const request = {
        content: generateShortNameRequest,
        assetID: this.assetService.getSelectedAssetId(),
        user: userName
      };
      this.generateShortNames(request).subscribe(result => {
        console.log(result);
        const parsedResult = JSON.parse(JSON.stringify(result));
        if (parsedResult.error) {
          const errorMessage = {
            message: parsedResult.error.message,
            title: 'Create CIF Error'
          };
          const dialogRef2 = this.zone.run(() => {
            this.dialog.open(ErrorDialogComponent, {
              data: errorMessage
            });
          });
        }
        console.log('shortnames results ', result);
        if (parsedResult.statusFlag === false) {
          const errorMessage = {
            message: parsedResult.statusMessage,
            title: 'Create CIF Error'
          };

          const dialogRef = this.zone.run(() => {
            this.dialog.open(ErrorDialogComponent, {
              data: errorMessage
            });
          });
        } else {
          // tslint:disable-next-line: prefer-for-of
          for (
            let i = 0;
            i < generateShortNameRequest.searchParties.length;
            i++
          ) {
            if (
              generateShortNameRequest.searchParties[i].CIF.toUpperCase() ===
              'NEW'
            ) {
              const CIFFields =
                generateShortNameRequest.searchParties[i].CIFFields.split(', ');
              CIFFields.forEach((c: any) => {
                const CIFIndex = this.ccmcApiService.mapping.findIndex(
                  (obj: any) => obj.fieldID === c
                );
                this.ccmcApiService.mapping[CIFIndex].fieldValue =
                  parsedResult.content[i].CIF;
              });
            }
          }
          this.collateralSearch();
        }
      });
    } else {
      this.collateralSearch();
    }
  }

  /**
   * Search Customer
   * Calls the search customer api in the precision communicator
   * @param {*} params
   * @returns
   * @memberof PrecisionService
   */
  searchCustomer(params: any) {
    
    console.log(params);
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/fiserv/precision/search-customer`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * Export
   * @description calls the export messages api in the precision communicator
   * @returns
   * @memberof PrecisionService
   */
  export() {
    
    const assetID = this.assetService.getSelectedAssetId();
    let userName = this.amplifyService.username;
    const body = {
      assetID: assetID,
      content: {
        mapping: this.ccmcApiService.mapping,
        configurations: this.ccmcApiService.configurations,
        transactions: this.ccmcApiService.transactions
      },
      user: userName
    };
    // console.log(body);
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/fiserv/precision/export-messages`,
        body,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * Generate Shortnames
   * @description calls the generate short name api in the precision communicator
   * @param {*} params
   * @returns
   * @memberof PrecisionService
   */
  generateShortNames(params: any) {
    
    console.log(params);
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/fiserv/precision/generate-short-name`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * Search Collateral
   * @description Calls the search collateral api in the precision communicator
   * @param {*} params
   * @returns
   * @memberof PrecisionService
   */
  searchCollateral(params: any) {
    
    console.log(params);
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/fiserv/precision/search-collateral`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * auto Customer Search
   * @description performs the auto customers search
   * @param {*} borrower
   * @param {*} callback
   * @memberof PrecisionService
   */
  autoCustomerSearch(borrower: any, callback: any) {
    let userName = this.amplifyService.username;
    const request = {
      content: borrower,
      assetID: this.assetService.getSelectedAssetId(),
      user: userName
    };
    console.log('Request: \n', JSON.stringify(request));
    request.content.cognitoID = this.amplifyService.getCognitoId();
    this.searchCustomer(request).subscribe(res => {
      console.log('Customer search response: ', res);
      const parsedResult = JSON.parse(JSON.stringify(res));
      console.log(parsedResult);
      if (parsedResult.error) {
        const errorMessage = {
          message: JSON.stringify(parsedResult.error.message),
          title: 'Precision Result'
        };

        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      }
      console.log(parsedResult);
      if (parsedResult.statusFlag === true) {
        this.spinnerService.setShowSpinner(false);
        const payload = parsedResult.content;
        console.log('Payload', payload);
        if (payload.length > 0) {
          const searchResult = payload;
          console.log(searchResult);
          callback(searchResult);
        } else {
          const noResult = [
            {
              firstName: 'No Result',
              lastName: '',
              taxID: '',
              CIF: '',
              address: ''
            }
          ];
          const searchResult = noResult;
          console.log(searchResult);
          callback(searchResult);
        }
      } else if (parsedResult.statusFlag === false) {
        this.spinnerService.setShowSpinner(false);
        if (
          parsedResult.statusMessage
            .toLowerCase()
            .includes('no customer record found')
        ) {
          const noResult = [
            {
              firstName: 'No Result',
              lastName: '',
              taxID: '',
              CIF: '',
              address: ''
            }
          ];
          const searchResult = noResult;
          console.log(searchResult);
          callback(searchResult);
        } else {
          const errorMessage = {
            message: parsedResult.statusMessage,
            title: 'Search Error'
          };

          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
          callback(parsedResult);
        }
      }
    });
  }

  getLoanNumber() {
    console.log('get Loan number');
    let loanFieldIndex = this.ccmcApiService.mapping.findIndex(
      (obj: any) => obj.fieldID === 'LNA_001'
    );
    if (loanFieldIndex > -1) {
      // Return loan number
      console.log(
        'loan number',
        this.ccmcApiService.mapping[loanFieldIndex].fieldValue
      );
      return this.ccmcApiService.mapping[loanFieldIndex].fieldValue;
    }
  }
}
