import { Injectable } from '@angular/core';
// import * as CryptoJS from 'crypto-js';
import { APPCONSTANTS } from 'src/app/app.constants';
@Injectable({
  providedIn: 'root'
})
export class CryptrService {
  constructor() {}

  /**
   * Encrypt Data
   * @description Encrypts given data using cryptojs aes encryption
   * @param {*} data
   * @returns
   * @memberof CryptrService
   */
  encryptData(data: any) {
    try {
      return true;
      // return CryptoJS.AES.encrypt(
      //   JSON.stringify(data),
      //   this.encryptSecretKey
      // ).toString();
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Decrypt Data
   * @description decrypts given data using cryptojs aes decryption
   * @param {*} data
   * @returns
   * @memberof CryptrService
   */
  decryptData(data: any) {
    try {
      const bytes: any = undefined!;
      // CryptoJS.AES.decrypt(data, this.encryptSecretKey);
      if (bytes.toString()) {
        return true;
        // return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }
}
