import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from 'src/environments/environment';
const { Buffer } = require('buffer');

@Injectable({
  providedIn: 'root'
})
export class DatadogRUMService {
  constructor() {}

  initDatadog() {
    let env = 'dev';
    if (environment.production) {
      env = 'prod';
    }
    datadogRum.init({
      applicationId: '76984a21-8a9e-49d3-b1cc-a09107fdb6f2',
      clientToken: 'pub0447b1f333e0d9ee4f774994d2185ac0',
      site: 'datadoghq.com',
      service: 'ground-control',
      env: env,
      version: Buffer.from(environment.packageVERSION, "base64").toString(),
      sampleRate: 100,
      premiumSampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input'
    });
    datadogRum.startSessionReplayRecording();
  }
}
