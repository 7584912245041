import { Injectable, NgZone } from '@angular/core';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CcmcApiService } from './ccmc-api.service';
import { CCMCCollateralSearchDialogComponent } from '../components/collateral-search-dialog/collateral-search-dialog.component';
import { Router } from '@angular/router';
import { navigation } from '../../app/navigation/navigation';
import { SpinnerService } from './spinner.service';
import { ActiveLosService } from './active-los.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { SuccessDialogComponent } from '../components/success-dialog/success-dialog.component';
import { AssetService } from './asset.service';
import { GeneralLedgerAccountingService } from './general-ledger-accounting.service';
import { Subject } from 'rxjs';
import * as AWS from 'aws-sdk';
import { APPCONSTANTS } from 'src/app/app.constants';
import { CaptureDataService } from './capture-data.service';
import { AmplifyService } from './amplify.service';
import { GlobalSearchService } from './global-search.service';
import { TargetResponseDialogComponent } from '../components/target-response-dialog/target-response-dialog.component';
const { Buffer } = require('buffer');
import { environment } from'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SymxchangeService {
  dynamicNav: any = navigation;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  resultTitle = 'SymxChange Result';
  unsubscribe: Subject<any> = new Subject();
  /**
   * Creates an instance of symxchangeService.
   * @param {MatDialog} dialog
   * @param {CcmcApiService} ccmcApiService
   * @param {Router} router
   * @param {SpinnerService} spinnerService
   * @param {ActiveLosService} activeLosService
   * @param {NgZone} zone
   * @param {HttpClient} http
   * @param {CustomErrorHandlerService} customErrorHandlerService
   * @param {AssetService} assetService
   * @param {CognitoService} cognitoService
   * @memberof SymxchangeService
   */
  constructor(
    private dialog: MatDialog,
    private ccmcApiService: CcmcApiService,
    public router: Router,
    private spinnerService: SpinnerService,
    private activeLosService: ActiveLosService,
    private zone: NgZone,
    private http: HttpClient,
    private customErrorHandlerService: CustomErrorHandlerService,
    private assetService: AssetService,
    private amplifyService: AmplifyService,
    private glaService: GeneralLedgerAccountingService,
    private captureDataService: CaptureDataService,
    private globalSearchService: GlobalSearchService
  ) {
    this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, PUT'
    );
    this.httpOptions.headers.append('Accept', 'application/json');
  }

  /**
   * Send
   * @description Sends the loan to the symxchange communicator
   * @memberof SymxchangeService
   */
  send() {
    if (this.assetService.getSelectedAssetId() == 'sales777') {
      this.resultTitle = 'Generic Result';
    } else {
      this.resultTitle = 'SymxChange Result';
    }
    this.spinnerService.setShowSpinner(true);
    let userName = this.amplifyService.username;
    // this.amplifyService.user$.pipe(filter(user => !!user)).subscribe(
    //   user => {
    //     this.zone.run(() => {
    //       userName = user.username;
    //     });
    //   },
    //   err => console.log(err)
    // );
    const exportObject = {
      content: {
        mapping: this.ccmcApiService.mapping,
        configurations: this.ccmcApiService.configurations,
        transactions: this.ccmcApiService.transactions
      },
      user: userName,
      assetID: this.assetService.getSelectedAssetId()
    };
    // console.log(exportObject);
    this.export(exportObject).subscribe(res => {
      console.log(res);
      const parsedResult = JSON.parse(JSON.stringify(res));
  
      if (parsedResult.error) {
        const errorMessage = {
          message: parsedResult.error.message,
          title: this.resultTitle
        };
        const dialogRef2 = this.zone.run(() => {
          this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
        });
      } else if (parsedResult.statusFlag === true) {
        const resultMessage = {
          message: parsedResult.statusMessage,
          title: this.resultTitle
        };
  
        const dialogRef = this.zone.run(() => {
          this.dialog.open(SuccessDialogComponent, {
            data: resultMessage
          });
        });
  
        this.activeLosService.activeLosService.loanBooked(
          parsedResult,
          JSON.parse(JSON.stringify(this.ccmcApiService.configurations))
        );
        this.ccmcApiService.mapping = undefined;
        this.ccmcApiService.coreSettings = undefined;
        this.ccmcApiService.conditions = undefined;
        this.ccmcApiService.mapping = undefined;
        this.ccmcApiService.supportValues = undefined;
        this.ccmcApiService.search = undefined;
        this.ccmcApiService.transactions = undefined;
        this.ccmcApiService.configurations = undefined;
        if (this.dynamicNav[1].children[1]) {
          this.dynamicNav[1].children[1].children = [];
        }
        this.dynamicNav[1].children[0].badge.title = undefined;
        if (this.activeLosService.activeLos === 'encompass' || this.activeLosService.activeLos === 'bytepro') {
          this.router.navigate(['loan-lookup']);
        } else {
          this.router.navigate(['file-selector']);
        }
      } else if (parsedResult.statusFlag === false) {
        if (parsedResult && parsedResult.content) {
          this.ccmcApiService.mapping = parsedResult.content.mapping;
        }
        const errorMessage = {
          message: parsedResult.statusMessage,
          title: this.resultTitle
        };
  
        const dialogRef = this.zone.run(() => {
          this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
        });
      } else {
        const dialogRef = this.zone.run(() => {
          this.dialog.open(ErrorDialogComponent, {
            data: 'Unknown error occurred, please refer to logs.'
          });
        });
      }
      });
  }

  processGLA(glaDocument: any) {
    this.spinnerService.setShowSpinner(true);
    let userName = this.amplifyService.username;

    // this.amplifyService.user$.pipe(filter(user => !!user)).subscribe(
    //   user => {
    //     this.zone.run(() => {
    //       userName = user.username;
    //     });
    //   },
    //   err => console.log(err)
    // );
    let sortedCoreLayout = JSON.parse(JSON.stringify(glaDocument.coreLayout));
    this.glaService.sortCoreLayout(sortedCoreLayout);
    const exportObject = {
      content: {
        set: this.removeBlankAmountsFromSet(glaDocument.set, sortedCoreLayout),
        configurations: glaDocument.configurations,
        coreLayout: sortedCoreLayout
      },
      user: userName,
      assetID: this.assetService.getSelectedAssetId(),
      loanNumber: this.captureDataService.loanNumber
    };
    console.log(JSON.stringify(exportObject));
    this.sendProcessGLA(exportObject).subscribe(res => {
      console.log(res);
      const parsedResult = JSON.parse(JSON.stringify(res));

      if (parsedResult.error) {
        const errorMessage = {
          message: parsedResult.error.message,
          title: this.resultTitle
        };
        const dialogRef2 = this.zone.run(() => {
          this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
        });
        this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: this.captureDataService.loanNumber,
          statusMessage: parsedResult.error.message,
          statusFlag: parsedResult.statusFlag
        });
      }
      if (parsedResult.statusFlag === true) {
        const resultMessage = {
          message: parsedResult.statusMessage,
          title: this.resultTitle
        };
        this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: this.captureDataService.loanNumber,
          statusMessage: parsedResult.statusMessage,
          statusFlag: parsedResult.statusFlag
        });
        this.glaService.setGLAObject({});
        this.activeLosService.activeLosService.loanBooked(
          parsedResult,
          JSON.parse(JSON.stringify(glaDocument.configurations))
        );
        const dialogRef = this.dialog.open(SuccessDialogComponent, {
          data: resultMessage
        });
        dialogRef.afterClosed().subscribe(result => {
          if (this.activeLosService.activeLos === 'encompass' || this.activeLosService.activeLos === 'bytepro') {
            this.router.navigate(['loan-lookup']);
          } else {
            this.router.navigate(['file-selector']);
          }
        });
      }
      if (parsedResult.statusFlag === false) {
        if (parsedResult.content && parsedResult.content.mapping) {
          this.ccmcApiService.mapping = parsedResult.content.mapping;
        }
        if (parsedResult.content && parsedResult.content.transactions) {
          this.ccmcApiService.transactions = parsedResult.content.transactions;
        }
        const errorMessage = {
          message: parsedResult.statusMessage,
          title: this.resultTitle
        };
        this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: this.captureDataService.loanNumber,
          statusMessage: parsedResult.statusMessage,
          statusFlag: parsedResult.statusFlag
        });
        const dialogRef = this.zone.run(() => {
          this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
        });
      }
    });
  }

  /**
   * Assign Borrowers
   * @description set the field value to the customer name
   * @param {*} party
   * @memberof SymxChangeService
   */
  assignBorrowers(party: any) {
    for (let i = 0; i < party.length; i++) {
      const CIFFields = party[i].CIFFields.split(',');
      CIFFields.forEach((cf: any) => {
        const cifIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) => f.fieldID === cf.trim()
        );
        this.ccmcApiService.mapping[cifIndex].fieldValue = party[i].CIF;
      });
    }
  }

  /**
   * Export
   * @desciption call the export api
   * @param {*} params
   * @returns
   * @memberof SymxChangeService
   */
  export(params: any) {
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/jack-henry/symxchange/export-messages`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  handleExport(error: any, response: any) {
    if (error) {
      const dialogRef2 = this.zone.run(() => {
        this.dialog.open(ErrorDialogComponent, {
          data: error
        });
      });
    } else {
    }
  }

  sendProcessGLA(params: any) {
    
    console.log(params);
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/jack-henry/symxchange/process-gla`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  getLoanNumber() {
    console.log('get Loan number');
    let loanFieldIndex;
    if (this.assetService.getSelectedCore() === 'symitar') {
      loanFieldIndex = this.ccmcApiService.mapping.findIndex(
        (obj: any) => obj.fieldID === 'LOAN_001'
      );
    }

    if (loanFieldIndex > -1) {
      // Return loan number
      console.log(
        'loan number',
        this.ccmcApiService.mapping[loanFieldIndex].fieldValue
      );
      return this.ccmcApiService.mapping[loanFieldIndex].fieldValue;
    }
  }

  removeBlankAmountsFromSet(set: any, targetLayout: any) {
    // Initial temp set
    let tempSet = JSON.parse(JSON.stringify(set));
    // Find Amount Index in targetlayout
    let amountIndex = targetLayout.findIndex(
      (targetItem: any) => targetItem.columnName === 'Amount'
    );
    // Set columnID
    let amountColumnID = targetLayout[amountIndex].columnID;
    // Initial cleaned transactions
    let cleanedTransactions = [];

    for (let transaction of tempSet.transactions) {
      let hasAmount = false;
      for (let col of transaction.columns) {
        // If columnId is amount and value is longer than 0 we keep
        if (col.columnID === amountColumnID && col.fieldValue.length > 0) {
          hasAmount = true;
        }
      }
      // If has amount push to new array
      if (hasAmount) {
        cleanedTransactions.push(transaction);
      }
    }
    // Reassign transactions
    tempSet.transactions = cleanedTransactions;
    // Return new set
    return tempSet;
  }
}
