import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { DeactivateSupportValues } from './deactivate-support-values';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { CCMCSharedModule } from 'src/@ccmc/shared.module';
import { SupportValuesComponent } from './support-values.component';
import { Role } from 'src/@ccmc/models/role';

const routes: Routes = [
  {
    path: 'support-values',
    component: SupportValuesComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    canDeactivate: [DeactivateSupportValues],
    data: {
      roles: [
        Role.ClientAdmin,
        Role.NxtsoftSales,
        Role.NxtsoftOp,
        Role.NxtsoftDev
      ]
    }
  }
];

@NgModule({
  declarations: [SupportValuesComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CCMCSharedModule,
    KeyboardShortcutsModule.forRoot()
  ],
  providers: [DeactivateSupportValues]
})
export class SupportValuesModule {}
