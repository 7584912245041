import { Component, OnInit } from '@angular/core';
import { AvatarService } from 'src/@ccmc/services/avatar.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';

@Component({
  selector: 'app-edit-avatar-dialog',
  templateUrl: './edit-avatar-dialog.component.html',
  styleUrls: ['./edit-avatar-dialog.component.scss']
})
export class EditAvatarDialogComponent implements OnInit {
  currentAvatar: any;
  selectedAvatar: any;
  avatarOptions = [
    'bank',
    'basic',
    'female1',
    'female2',
    'male1',
    'male2',
    'face',
    'skyscraper',
  ];
  canSave = false;
  avatarSubscription: Subscription;

  constructor(
    private avatarService: AvatarService,
    private dialogRef: MatDialogRef<EditAvatarDialogComponent>,
    private snackBarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.getAvatar();
    this.selectedAvatar = this.currentAvatar;
    console.log(this.currentAvatar);
  }

  ngOnDestroy() {
    if (this.avatarSubscription) {
      this.avatarSubscription.unsubscribe();
    }
  }

  selectAvatar(avatar: any) {
    this.selectedAvatar = avatar;
    if (this.selectedAvatar !== this.currentAvatar) {
      this.canSave = true;
    } else {
      this.canSave = false;
    }
  }

  save() {
    this.avatarService.updateAvatar(this.selectedAvatar).subscribe(result => {
      if (result) {
        this.snackBarService.openSnackBar('Updated Profile Image', 'Okay');
        this.avatarService.setAvatar(this.selectedAvatar);
        this.canSave = false;
      }
    });
  }

  close() {
    this.dialogRef.close({ data: false });
  }

  getAvatar() {
    this.avatarSubscription = this.avatarService.avatar.subscribe(avatar => {
      this.currentAvatar = avatar;
    });
  }
}
