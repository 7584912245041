<div class="confirm-dialog">
  <div mat-dialog-content class="confirm-dialog-message">
    {{ message }}
  </div>
  <div mat-dialog-actions class="pt-30" class="confirm-dialogBtn-Container">
    <button
      class="cancelBtn"
      mat-stroked-button
      (click)="dialogRef.close(false)"
    >
      Close
    </button>
    <button
      mat-raised-button
      class="mat-accent mr-16"
      (click)="dialogRef.close(true)"
    >
      Confirm
    </button>
  </div>
</div>
