import { Component, OnInit, Inject, ChangeDetectorRef, AfterContentInit, AfterContentChecked } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { FormControl, Validators } from '@angular/forms';
import { Observable, Subscription, Subject } from 'rxjs';
import {
  takeUntil
} from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';
import { SnackbarService } from '../../services/snackbar.service';
import { FieldEditedService } from '../../services/field-edited.service';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { SpinnerService } from '../../services/spinner.service';
import { MatStepper } from '@angular/material/stepper';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';

@Component({
  selector: 'app-add-validation-group-dialog',
  templateUrl: './add-validation-group-dialog.component.html',
  styleUrls: ['./add-validation-group-dialog.component.scss'],
})
export class AddValidationGroupDialogComponent implements OnInit {
  tempValidation: any = {
    displayName: '',
    value: '',
    fieldIDs: [],
    validationGroup: ''
  };
  validationObject: any;
  myControlFieldID = new FormControl();
  filteredOptionsFieldID: Observable<string[]>;
  private documentCoreSub: Subscription;
  unsubscribe: Subject<any> = new Subject();
  mappingObject: any;
  tempFieldID: any;
  dataSource: any;
  displayedColumns : any[] = [];
  displayedColumns2: any[] = [];
  validations: any[] = [];
  tempFieldIDs: any;
  tempDocument:any;
  indexArr: any = [];
  valArray: any = [];
  calledTimes = 0;
  pastedFieldIDs: any;
  fieldIDsArr: any = [];
  showSpinner: boolean;
  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  selectedOption: any;
  isLinear = false;
  validationGroup: any;
  private spinnerSub: Subscription;
  selectedValidationGroup: any;
  newValidationGroup: any;
  validationGroups: any[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddValidationGroupDialogComponent>,
    private snackBar: MatSnackBar,
    private snackbarService: SnackbarService,
    private fieldEditedService: FieldEditedService,
    private dialog: MatDialog,
    private spinnerService: SpinnerService,
    private _formBuilder: FormBuilder,
    private adminApiService: AdminApiService
  ) {}

  ngOnInit() {
    console.log('initializing add to validation group dialog');
    this.validationObject = this.data;
    if (!this.validationObject) {
      this.validationObject = [];
    }
    setTimeout(() => {
      this.getData();
    }, 500);
    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((spinner) => {
        this.showSpinner = spinner;
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  getData() {
    console.log('should be getting data');
    this.documentCoreSub = this.adminApiService.documentCore
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((documentCore) => {
      if (documentCore) {
        console.log('document core', documentCore);
        this.tempDocument = documentCore;
        this.validationGroups = this.tempDocument.validationGroups
        if (documentCore.mapping) {
          this.mappingObject = JSON.parse(
            JSON.stringify(documentCore.mapping)
          );
          if(!documentCore.validationGroups) {
            this.tempDocument.validationGroups = [];
          }
        }
      }
    });
  }

  async pasteNewValidations() {
    this.tempValidation.value = '';
    this.tempValidation.fieldIDs = [];
    this.tempValidation.displayName = '';
    let skippedDuplicated = false;
    let validationsWithError = false;
    let text = await navigator.clipboard.readText();
    let splitText = JSON.stringify(text)
      .slice(0, -1)
      .replace('"', '')
      .split('\\r\\n');
    for (let splitItem of splitText) {
      let splitValidation = splitItem.split('\\t');
      let trimVal = splitValidation[0].split(',');
      let newVal: any[] = [];
      for (let val of trimVal) {
        val = val.trim();
        newVal.push(val);
      }
      if (splitValidation.length === 3) {
        let tempValidation = {
          displayName: splitValidation[2],
          value: splitValidation[1],
          fieldIDs: newVal,
          validationGroup: this.validationGroup
        };
        let existingValidationIndex = this.validations.findIndex(
          (val) =>
          val.value === this.tempValidation.value &&
          val.displayName === this.tempValidation.displayName &&
          val.fieldIDs.every((validationItem: any) => this.tempValidation.fieldIDs.includes(validationItem)) &&
          val.fieldIDs.length === this.tempValidation.fieldIDs.length
        );
        if (existingValidationIndex === -1) {
          this.validations.push(tempValidation);
        } else {
          skippedDuplicated = true;
        }
      } else if (splitValidation[0] !== '' && splitValidation[1] !== '') {
        const errorMessage = {
          title: 'Parsing Error',
          message:
            'Clipboard format not valid. Please try copying your columns directly from Excel. \nExample Format: \nEXMPL_1	ExampleCode1 ExCode1 \n EXMPL_2	ExampleCode2 Excode2',
        };
        this.dialog.open(ErrorDialogComponent, {
          data: errorMessage,
        });
        return;
      }
    }
    if (validationsWithError) {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          title: 'Error Inputing Validation',
          message: `One or more Validation fields had errors. `,
        },
      });
    } else if (skippedDuplicated) {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          title: 'Duplicates Skipped',
          message: `One or more validations were duplicates and skipped in the entry process. `,
        },
      });
    }
    this.dataSource = [...this.validations];
  }

  async addNewValidation() {
    this.tempValidation.validationGroup = this.validationGroup;
    if(this.selectedOption !== 'Add Validation to an existing group') {
      this.tempFieldIDs = this.tempFieldIDs.toUpperCase();
      let fieldIDArray: any[] = [];
    fieldIDArray = this.tempFieldIDs.split(',');
    if (this.tempValidation.displayName.length > 0) {
      for (let fieldID of fieldIDArray) {
        fieldID = fieldID.trim();
        await this.tempValidation.fieldIDs.push(fieldID);
      }
      if (this.tempValidation.value.length > 0) {
        if (this.tempValidation.fieldIDs.length > 0) {
          console
          let validationIndex = this.validations.findIndex(
            (val) =>
              val.value === this.tempValidation.value &&
              val.displayName === this.tempValidation.displayName &&
              val.fieldIDs.every((validationItem: any) => this.tempValidation.fieldIDs.includes(validationItem)) &&
              val.fieldIDs.length === this.tempValidation.fieldIDs.length
          );
          if (validationIndex > -1) {
            this.snackbarService.openSnackBar(
              'Duplicate Validation',
              'Okay'
            );
            this.tempValidation.fieldIDs = [];
            return;
          } else {
            this.validations.push(
              JSON.parse(JSON.stringify(this.tempValidation))
            );
            this.tempValidation.displayName = '';
            this.tempValidation.value = '';
            this.tempFieldIDs = '';
            this.tempValidation.fieldIDs = [];
            this.dataSource = [...this.validations];
          }
        } else {
          this.snackbarService.openSnackBar(
            'Field ID must not be blank.',
            'Okay'
          );
        }
      } else {
        this.snackbarService.openSnackBar('Value must not be blank.', 'Okay');
      }
    } else {
      this.snackbarService.openSnackBar(
        'Display Name must not be blank.',
        'Okay'
      );
    }
    } else {
      let valGroupIndex = this.validationObject.findIndex((validation: any) => validation.validationGroup === this.validationGroup);
      if(valGroupIndex > -1) {
        if (this.tempValidation.displayName.length > 0) {
          if (this.tempValidation.value.length > 0) {
              let validationIndex = this.validations.findIndex(
                (val) =>
                  val.value === this.tempValidation.value &&
                  val.displayName === this.tempValidation.displayName 
              );
              if (validationIndex > -1) {
                this.snackbarService.openSnackBar(
                  'Duplicate Validation',
                  'Okay'
                );
                return;
              } else {
                this.tempValidation.fieldIDs = this.validationObject[valGroupIndex].fieldIDs;
                this.validations.push(
                  JSON.parse(JSON.stringify(this.tempValidation))
                );
                this.tempValidation.displayName = '';
                this.tempValidation.value = '';
                this.tempValidation.fieldIDs = [];
                console.log(this.validations);
                this.dataSource = [...this.validations];
                console.log(this.dataSource);
              }
          } else {
            this.snackbarService.openSnackBar('Value must not be blank.', 'Okay');
          }
        } else {
          this.snackbarService.openSnackBar(
            'Display Name must not be blank.',
            'Okay'
          );
        }
      }
      
    }
  }

  removeNewValidation(element: any) {
    let validationIndex = this.validations.findIndex(
      (val) =>
        val.fieldIDs === element.fieldIDs &&
        val.value === element.value &&
        val.displayName === element.displayName
    );
    if (validationIndex > -1) {
      this.validations.splice(validationIndex, 1);
    } else {
      console.log('not a valid validation index');
    }
    this.dataSource = [...this.validations];
  }

  submitNewValidations() {
    if (this.validationObject) {
      if (this.validations.length > 0) {
        // Compares each submitted validation with mapping to see if the fieldIDs are valid
        let flaggedFieldIDs: any = [];
        this.validations.forEach((validation) => {
          validation.fieldIDs.forEach((fieldID: any) => {
            let index1 = this.mappingObject.findIndex((mappingItem: any) => {
              return mappingItem.fieldID === fieldID;
            });
            this.indexArr.push(index1);
            if (index1 === -1) {
              flaggedFieldIDs.push(fieldID);
            }
          });
        });
        if (this.indexArr.includes(-1)) {
          // There is at least one invalid validation
          // Producing error message
          let counter = 0;
          let tempMessage: any;
          for (let fieldID of flaggedFieldIDs) {
            if (counter === 0) {
              tempMessage = fieldID;
            } else {
              tempMessage = tempMessage + ' ' + fieldID;
            }
            counter++;
          }
          const errorMessage = {
            title: 'Validation FieldIDs Error',
            message:
              'The following FieldIDs can not be used because they do not exist in mapping:\n' + tempMessage,
          };
          this.dialog.open(ErrorDialogComponent, {
            data: errorMessage,
          });
          this.indexArr = [];
        } else {
          // Comparing the entered validations with the existing ones to flag duplicates
          let duplicateValidations: any = [];
              this.validations.forEach((validation) => {
                if (!this.containsObject(validation, this.validationObject)) {
                  validation.edited = true;
                  this.validationObject.push(validation);
                  this.fieldEditedService.coreEdited.next(true);
                } else {
                  duplicateValidations.push(validation.displayName);
                }
              });
              if (duplicateValidations.length < 1) {
                // Prevents validations window from closing so user can correct errors
                this.dialogRef.close(this.validations);
              } else {
                  // At least one duplicate was found
                  // Producing error message
                  let counter = 0;
                  let tempMessage: any;
                  for (let fieldName of duplicateValidations) {
                    if (counter === 0) {
                      tempMessage = fieldName;
                    } else {
                      tempMessage = tempMessage + ' ' + fieldName;
                    }
                    counter++;
                  }
                  const errorMessage = {
                    title: 'Duplicate Validations',
                    message:
                      'The following Validations already exist:\n' + tempMessage,
                  };
                  this.dialog.open(ErrorDialogComponent, {
                    data: errorMessage,
                  }); 
              }
           

            this.indexArr = [];
            
        }
      } else {
        if(this.selectedOption === 'Create Validation Group') {
            this.dialogRef.close([]);
        }
      } 
    } else {
      if (this.validations) {
        // Compares each submitted validation with mapping to see if the fieldIDs are valid
        let flaggedFieldIDs: any = [];
        this.validations.forEach((validation) => {
          for (let fieldID of validation.fieldIDs) {
            let index1 = this.mappingObject.findIndex((mappingItem: any) => {
              return mappingItem.fieldID === fieldID;
            });
            this.indexArr.push(index1);
            if (this.indexArr === -1) {
              flaggedFieldIDs.push(validation.fieldIDs[0]);
            }
          }
        });
        if (this.indexArr.includes(-1)) {
          // There is at least one invalid validation
          // Producing error message
          let counter = 0;
          let tempMessage: any;
          for (let fieldID of flaggedFieldIDs) {
            if (counter === 0) {
              tempMessage = fieldID;
            } else {
              tempMessage = tempMessage + ' ' + fieldID;
            }
            counter++;
          }
          const errorMessage = {
            title: 'Validation FieldIDs Error',
            message:
              'The following FieldIDs can not be used because they do not exist in mapping:\n' + tempMessage,
          };
          this.dialog.open(ErrorDialogComponent, {
            data: errorMessage,
          });
          this.indexArr = [];
        } else {
          this.validations.forEach((validation) => {
            validation.edited = true;
            this.validationObject.push(validation);
            this.fieldEditedService.coreEdited.next(true);
          });
          this.dialogRef.close(this.validations);
        }
        this.indexArr = [];
      }
    }
  }

  containsObject(obj: any, objList: any) {
    for (let i = 0; i < objList.length; i++) {
      if (objList[i].value === obj.value && objList[i].displayName === obj.displayName) {
        for (let fieldID of obj.fieldIDs) {
          if (objList[i].fieldIDs.includes(fieldID)) {
            return true;
          }
        }
      }
    }
    return false;
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
  closeDialog() {
    this.dialogRef.close();
  }

  nextStep(stepper: MatStepper) {
    this.dataSource = [];
    this.validations = [];
    
      if(this.selectedValidationGroup !== undefined || this.newValidationGroup !== undefined) {
        if(this.selectedOption === 'Create Validation Group') {
          this.displayedColumns =  ['fieldIDs', 'value', 'displayName'];
          this.displayedColumns2 = ['fieldIDs', 'value', 'displayName'];
          this.validationGroup = this.newValidationGroup;
          let valGroupIndex = this.tempDocument.validationGroups.findIndex((group: any) => group === this.validationGroup);
          if (valGroupIndex === -1) {
            stepper.next();
          } else {
            this.snackbarService.openSnackBar(
              'Validation Group already exists',
              'Okay'
            );
          }
        } else {
          this.displayedColumns =  ['value', 'displayName'];
          this.displayedColumns2 = ['value', 'displayName'];
          this.validationGroup = this.selectedValidationGroup;
          stepper.next();
      }
    } else {
      this.validationGroup = undefined;
    }
  }
}
