import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewChild,
  ElementRef,
  NgZone
} from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { CcmcApiService } from '../../services/ccmc-api.service';
import { takeUntil } from 'rxjs/operators';
import { SpinnerService } from '../../services/spinner.service';
import { FieldEditedService } from '../../services/field-edited.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';

/**
 * The find and replace dialog component lets the user find and replace strings in an object
 *
 * @export
 * @class FindAndReplaceDialogComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-find-and-replace-dialog',
  templateUrl: './find-and-replace-dialog.component.html',
  styleUrls: ['./find-and-replace-dialog.component.scss']
})
export class FindAndReplaceDialogComponent implements OnInit, OnDestroy {
  /**
   * Object to find and replace on
   *
   * @memberof FindAndReplaceDialogComponent
   */
  object;

  /**
   * Find string
   *
   * @memberof FindAndReplaceDialogComponent
   */
  findStr: any;

  /**
   * Replace string
   *
   * @memberof FindAndReplaceDialogComponent
   */
  replaceStr: any;

  /**
   * Find object
   *
   * @memberof FindAndReplaceDialogComponent
   */
  findObj: any;

  /**
   * Find object keys
   *
   * @memberof FindAndReplaceDialogComponent
   */
  findObjKeys: any;

  /**
   * Filtered object
   *
   * @memberof FindAndReplaceDialogComponent
   */
  filteredObj: any;

  /**
   * Index of find and replace
   *
   * @memberof FindAndReplaceDialogComponent
   */
  index = 0;

  /**
   * Datasource for filtering for the find string
   *
   * @type {*}
   * @memberof FindAndReplaceDialogComponent
   */
  dataSource: any;

  /**
   * Current item
   *
   * @memberof FindAndReplaceDialogComponent
   */
  currentItem: any = '';

  /**
   * Selected filter
   *
   * @memberof FindAndReplaceDialogComponent
   */
  selectedFilter: any;

  /**
   * Total items found with string
   *
   * @memberof FindAndReplaceDialogComponent
   */
  total: any;

  /**
   * Document subscription
   *
   * @private
   * @type {Subscription}
   * @memberof FindAndReplaceDialogComponent
   */
  private documentSub: Subscription;

  /**
   * Exact Match
   * Tells the find and replace dialog whether to look for exact match of findStr
   *
   * @memberof FindAndReplaceDialogComponent
   */
  exactMatch: boolean = false;

  /**
   * Code mirror config object
   *
   * @memberof FindAndReplaceDialogComponent
   */
  codemirrorConfig = {
    mode: { name: 'javascript', json: true },
    theme: 'material',
    lineNumbers: true,
    extraKeys: { 'Ctrl-Space': 'autocomplete' }
  };

  /**
   * unsubscribe from observables
   *
   * @type {Subject<any>}
   * @memberof FindAndReplaceDialogComponent
   */
  unsubscribe: Subject<any> = new Subject();

  /**
   * Field input reference
   *
   * @type {ElementRef}
   * @memberof FindAndReplaceDialogComponent
   */
  @ViewChild('findInput', { static: true }) findInputEl: ElementRef;

  /**
   * Creates an instance of FindAndReplaceDialogComponent.
   * @param {*} data
   * @param {CcmcApiService} ccmcApiService
   * @param {NgZone} zone
   * @param {MatSnackBar} snackBar
   * @param {MatDialogRef<FindAndReplaceDialogComponent>} dialogRef
   * @param {SpinnerService} spinnerService
   * @param {FieldEditedService} fieldEditedService
   * @memberof FindAndReplaceDialogComponent
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ccmcApiService: CcmcApiService,
    private zone: NgZone,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<FindAndReplaceDialogComponent>,
    private spinnerService: SpinnerService,
    private fieldEditedService: FieldEditedService
  ) {
    this.object = data;
  }

  /**
   * Initialize the component
   *
   * @memberof FindAndReplaceDialogComponent
   */
  ngOnInit() {
    this.selectedFilter = 'fieldID';
    this.getData();
  }

  /**
   * Unsubscribe from observables on destroy
   *
   * @memberof FindAndReplaceDialogComponent
   */
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  /**
   * Get data
   *
   * @memberof FindAndReplaceDialogComponent
   */
  getData() {
    if (this.object === 'translators') {
      this.documentSub = this.ccmcApiService.documentLos
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(documentLos => {
          if (documentLos) {
            this.findObj = documentLos.loan;
            this.findObjKeys = Object.keys(this.findObj[0]);
            this.dataSource = new MatTableDataSource(this.findObj);
          }
        });
    } else {
      this.documentSub = this.ccmcApiService.documentCore
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(documentCore => {
          if (documentCore) {
            this.findObj = documentCore[this.object];
            this.findObjKeys = Object.keys(this.findObj[0]);
            this.dataSource = new MatTableDataSource(this.findObj);
          }
        });
    }
  }

  /**
   * Apply filter to the object
   *
   * @param {string} filterString
   * @memberof FindAndReplaceDialogComponent
   */
  applyFilter(filterString: string) {
    this.index = 0;
    this.dataSource.filter = filterString;
    if (this.dataSource.filteredData[this.index]) {
      this.total = this.dataSource.filteredData.length;
      this.currentItem = JSON.stringify(
        this.dataSource.filteredData[this.index],
        null,
        2
      );
    } else {
      this.currentItem = null;
      this.total = 0;
      this.openSnackBar('No Results Found', 'Okay');
    }
  }

  /**
   * Get next item
   *
   * @memberof FindAndReplaceDialogComponent
   */
  getNextItem() {
    this.index++;
    if (this.index === this.dataSource.filteredData.length) {
      this.index = 0;
    }
    this.currentItem = JSON.stringify(
      this.dataSource.filteredData[this.index],
      null,
      2
    );
    // console.log(this.currentItem);
  }

  /**
   * Get previous item
   *
   * @memberof FindAndReplaceDialogComponent
   */
  getPrevItem() {
    this.index--;
    if (this.index < 0) {
      this.index = 0;
    }
    this.currentItem = JSON.stringify(
      this.dataSource.filteredData[this.index],
      null,
      2
    );
  }

  /**
   * Change filter predicate to filter by
   *
   * @param {*} selectedFilter
   * @memberof FindAndReplaceDialogComponent
   */
  changeFilterPredicate(selectedFilter: any) {
    this.dataSource.filterPredicate = function (
      data: any,
      filter: string
    ): boolean {
      // tslint:disable-next-line: max-line-length
      return data[selectedFilter].toString().includes(filter);
    };
  }

  /**
   * Replace single item
   *
   * @memberof FindAndReplaceDialogComponent
   */
  replaceItem() {
    // If the string is found inside the datasource
    if (
      this.dataSource.filteredData[this.index][this.selectedFilter]
        .toString()
        .includes(this.findStr)
    ) {
      // Set temp to current index
      let temp = this.dataSource.filteredData[this.index][this.selectedFilter];
      if (typeof temp === 'number') {
        temp = temp.toString();
        // Check exact match flag
        if (this.exactMatch) {
          // If item is an exact match replace it with replaceString
          if (temp === this.findStr) {
            this.dataSource.filteredData[this.index][this.selectedFilter] =
              +this.replaceStr;
            this.dataSource.filteredData[this.index].edited = true;
            // Reset current item so user can see the result
            this.currentItem = JSON.stringify(
              this.dataSource.filteredData[this.index],
              null,
              2
            );
            // Turn core edited flag to true
            this.fieldEditedService.coreEdited.next(true);
          }
        } else {
          // If exact match flag is false, we can replace the findStr with replaceStr
          // converts item back to number to reassign
          temp = temp.replace(this.findStr, this.replaceStr);
          this.dataSource.filteredData[this.index][this.selectedFilter] = +temp;
          this.dataSource.filteredData[this.index].edited = true;
          // Reset current item so user can see the result
          this.currentItem = JSON.stringify(
            this.dataSource.filteredData[this.index],
            null,
            2
          );
          // Turn core edited flag to true
          this.fieldEditedService.coreEdited.next(true);
        }
      } else if (typeof temp === 'string') {
        // Check exact match flag
        if (this.exactMatch) {
          if (temp === this.findStr) {
            this.dataSource.filteredData[this.index][this.selectedFilter] =
              this.replaceStr;
            this.dataSource.filteredData[this.index].edited = true;
            // Reset current item so user can see the result
            this.currentItem = JSON.stringify(
              this.dataSource.filteredData[this.index],
              null,
              2
            );
            // Turn core edited flag to true
            this.fieldEditedService.coreEdited.next(true);
          }
        } else {
          // If exact match flag is false, we can replace the findStr with replaceStr
          temp = temp.replace(this.findStr, this.replaceStr);
          this.dataSource.filteredData[this.index][this.selectedFilter] = temp;
          this.dataSource.filteredData[this.index].edited = true;
          // Reset current item so user can see the result
          this.currentItem = JSON.stringify(
            this.dataSource.filteredData[this.index],
            null,
            2
          );
          // Turn core edited flag to true
          this.fieldEditedService.coreEdited.next(true);
        }
      } else if (typeof temp === 'boolean') {
        temp = temp.toString();
        temp = temp.replace(this.findStr, this.replaceStr);
        this.dataSource.filteredData[this.index][this.selectedFilter] =
          temp === 'true';
        this.dataSource.filteredData[this.index].edited = true;
        this.currentItem = JSON.stringify(
          this.dataSource.filteredData[this.index],
          null,
          2
        );
        this.fieldEditedService.coreEdited.next(true);
      }
    }
  }

  /**
   * Replace all items that match
   *
   * @memberof FindAndReplaceDialogComponent
   */
  replaceAllItems() {
    this.index = 0;
    this.spinnerService.setShowSpinner(true);
    for (
      this.index = 0;
      this.index < this.dataSource.filteredData.length;
      this.index++
    ) {
      this.replaceItem();
    }
    this.spinnerService.setShowSpinner(false);
    this.index--;
  }

  /**
   * Close the find and replace dialog component
   *
   * @memberof FindAndReplaceDialogComponent
   */
  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * Open material snackbar with given message and action
   *
   * @param {string} message
   * @param {string} action
   * @memberof FindAndReplaceDialogComponent
   */
  openSnackBar(message: string, action: string) {
    this.zone.run(() => {
      setTimeout(() => {
        this.snackBar.open(message, action, {
          duration: 5000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }, 0);
    });
  }
}
