import { Injectable } from '@angular/core';
import { Subscribable, Subscription, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ErrorDialogComponent } from '../../../../@ccmc/components/error-dialog/error-dialog.component';
import { SpinnerService } from '../../../../@ccmc/services/spinner.service';
import { MatDialog } from '@angular/material/dialog';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CustomErrorHandlerService } from 'src/@ccmc/services/custom-error-handler.service';
import { Credentials, CloudWatchLogs } from 'aws-sdk';
import { APPCONSTANTS } from 'src/app/app.constants';
import { environment } from 'src/environments/environment';
const { Buffer } = require('buffer');

@Injectable({
  providedIn: 'root'
})
export class ReadLoggingService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  /**
   * Amazon Web Services Credentials
   *
   * @memberof ReadLoggingService
   */
  awsCredentials: any;

  /**
   * Amazon Web Services CloudWatch Logs
   *
   * @memberof ReadLoggingService
   */
  awsCloudWatchLogs: any;

  /**
   * Current Core
   *
   * @memberof ReadLoggingService
   */
  core: any;

  /**
   * Current AssetID
   *
   * @memberof ReadLoggingService
   */
  assetID: any;

  /**
   * Next token for grabbing next batch of logs from cloudwatch
   *
   * @memberof ReadLoggingService
   */
  nextToken: any;

  /**
   * Logstream events
   *
   * @memberof ReadLoggingService
   */
  currentLogStreamEvents: any;

  /**
   * Configuration Observables
   *
   * @type {Subscription}
   * @memberof ReadLoggingService
   */
  configurationsObs: Subscription;

  /**
   * Logs Subscription
   *
   * @type {BehaviorSubject<any>}
   * @memberof ReadLoggingService
   */
  logsSub: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  /**
   * Log Group Name object
   *
   * @memberof ReadLoggingService
   */
  logGroupName = {
    silverlake: 'jackhenry_jxchange_lambda_test',
    precision: 'fiserv_precision_lambda'
  };

  selectedLoggingGroup: BehaviorSubject<any> = new BehaviorSubject<any>('asset');
  ebmExportLogs: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  ebmExportReports: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  /**
   * Creates an instance of ReadLoggingService.
   * @param {MatDialog} dialog
   * @memberof ReadLoggingService
   */
  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private spinnerService: SpinnerService,
    private assetService: AssetService,
    private customErrorHandlerService: CustomErrorHandlerService
  ) {}

  /**
   * Get logs from cloudwatch
   *
   * @param {*} loggingParams
   * @memberof ReadLoggingService
   */
  getLogs(loggingParams: any) {
    this.assetID = this.assetService.getSelectedAssetId();
    let params: any = {
      logGroupName: `/aws/lambda/${loggingParams.lambdaName}-${loggingParams.environment}` /* required */,
      filterPattern: '{$.assetID = ' + this.assetID + '}',
      startTime: loggingParams.startDate,
      endTime: loggingParams.endDate
    };
    if (loggingParams.nextToken) {
      params.nextToken = loggingParams.nextToken;
    }
    if (loggingParams.core === 'premier') {
      params.logGroupName = `premier-web-api-${loggingParams.environment}`;
    } else if (loggingParams.core === 'premierco') {
      params.logGroupName = `/aws/lambda/fiserv-premier-open-communicator-${loggingParams.environment}`;
    }
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/cloudwatch/get-logs`,
          params,
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }

  /**
   * Format logs to be user friendly
   *
   * @param {*} logs
   * @memberof ReadLoggingService
   */
  cleanLogs(logs: any) {
    const tempLogs = [];
    console.log(logs);
    for (let i = 0; i < logs.length; i++) {
      let logMessage;
      try {
        logMessage = JSON.parse(logs[i].message);
        // Handling Portico Logging
        if (logMessage && 
          logMessage.loanType && 
          logMessage.loanType !== 'Not Assigned' && 
          logMessage.loanType !== '' &&
          logMessage.memberNumber !== 'Not Assigned' && 
          logMessage.memberNumber !== '') {
          logMessage.loanNumber = logMessage.memberNumber + '-' + logMessage.loanType + '-' + logMessage.loanNumber;
        }
        const tempLog = {
          level: logMessage.level,
          dateTime: new Date(logMessage.longDate)
            .toLocaleString()
            .replace(/,/g, ''),
          message: logMessage.message,
          user: logMessage.user,
          loanNumber: logMessage.loanNumber,
          exception: logMessage.exception,
          timestamp: logs[i].timestamp
        };
        tempLogs.push(tempLog);
      } catch (e) {
        console.log(logs[i].message);
        console.log(e);
      }
    }
    if (tempLogs.length > 0) {
      this.logsSub.next(tempLogs);
      this.spinnerService.setShowSpinner(false);
    } else {
      this.spinnerService.setShowSpinner(false);
      const errorMessage = {
        message: 'No Logs found.',
        title: 'Logging'
      };
      this.dialog.open(ErrorDialogComponent, {
        data: errorMessage
      });
      this.logsSub.next([]);
    }
  }

  getAssetLogs(loggingParam: any) {
    var params: any = {
      logGroupName: 'gcadmininternal' /* required */,
      logStreamName: loggingParam.assetID /* required */,
      endTime: loggingParam.endDate,
      startTime: loggingParam.startDate
    };

    if (loggingParam.nextToken) {
      params.nextToken = loggingParam.nextToken;
    } else {
      params.startFromHead = true;
    }

    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/cloudwatch/get-asset-logs`,
          params,
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }

  cleanAssetLogs(logs: any) {
    const tempLogs = [];
    console.log(logs);
    for (let i = 0; i < logs.length; i++) {
      let logMessage;
      try {
        logMessage = JSON.parse(logs[i].message);
        const tempLog = {
          user: logMessage.username,
          action: logMessage.action,
          log: logMessage.log,
          time: logMessage.time
        };
        tempLogs.push(tempLog);
      } catch (e) {
        console.log(logs[i].message);
        console.log(e);
      }
    }
    if (tempLogs.length > 0) {
      this.logsSub.next(tempLogs);
      this.spinnerService.setShowSpinner(false);
    } else {
      this.spinnerService.setShowSpinner(false);
      const errorMessage = {
        message: 'No Logs found.',
        title: 'Logging'
      };
      this.dialog.open(ErrorDialogComponent, {
        data: errorMessage
      });
      this.logsSub.next([]);
    }
  }

  getUserLogs(loggingParam: any) {
    var params: any = {
      logGroupName: 'gcuserlogs', /* required */
      logStreamName: loggingParam.accountID, /* required */
      endTime: loggingParam.endDate,
      startTime: loggingParam.startDate,
    };

    if (loggingParam.nextToken) {
      params.nextToken = loggingParam.nextToken;
    } else {
      params.startFromHead = true;
    }

    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/cloudwatch/get-asset-logs`,
          params,
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }

  cleanUserLogs(logs: any) {
    const tempLogs = [];
    if (logs) {
      for (let i = 0; i < logs.length; i++) {
        let logMessage;
        try {
          logMessage = JSON.parse(logs[i].message);
          const tempLog = {
            user: logMessage.username,
            action: logMessage.action,
            log: logMessage.log,
            time: logMessage.time
          };
          tempLogs.push(tempLog);
        } catch (e) {
          console.log(logs[i].message);
          console.log(e);
        }
      }
    }
    if (tempLogs.length > 0) {
      this.logsSub.next(tempLogs);
      this.spinnerService.setShowSpinner(false);
    } else {
      this.spinnerService.setShowSpinner(false);
      const errorMessage = {
        message: 'No Logs found.',
        title: 'Logging'
      };
      this.dialog.open(ErrorDialogComponent, {
        data: errorMessage
      });
      this.logsSub.next([]);
    }
  }

  /**
   * Clear log data
   *
   * @memberof ReadLoggingService
   */
  clearData() {
    this.awsCredentials = undefined;
    this.awsCloudWatchLogs = undefined;
    this.core = undefined;
    this.nextToken = undefined;
    this.currentLogStreamEvents = undefined;
    this.logsSub.next([]);
  }

  /**
   * Getter for logs
   *
   * @readonly
   * @memberof ReadLoggingService
   */
  get logs() {
    return this.logsSub.asObservable().pipe(
      map(logs => {
        return logs;
      })
    );
  }
}
