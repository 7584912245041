<h1 matDialogTitle>
    <mat-icon>check_circle_outline</mat-icon> {{ msg.title }}
</h1>

<h3 mat-dialog-content>{{msg.messageBefore }}{{msg.messageCopy }}<span style=" cursor: copy"
        [cdkCopyToClipboard]="msg.messageCopy">
        <i class="material-icons"
            matTooltip="Copy loan number and paste it in Encompass to view this loan.">content_copy</i>
    </span>{{msg.messageAfter }}</h3>

<div mat-dialog-actions class="pt-24 error-actions" style="padding-bottom:35px;">
    <button class="ok-button" mat-raised-button color="accent" (click)="dialogRef.close(true)">OK</button>
</div>