import { Navigation } from '../../@ccmc/models/navigation.model';

export const navigation: Navigation[] = [
  {
    id: 'asset information',
    title: 'Asset Information',
    type: 'group',
    visible: true,
    children: []
  },
  {
    id: 'applications',
    title: 'Applications',
    type: 'group',
    visible: true,
    children: [
      {
        id: 'file-selector',
        title: 'File Selector',
        type: 'item',
        icon: 'folder',
        url: '/file-selector',
        visible: true,
        badge: {
          title: 25,
          bg: '#3FB2C6',
          fg: '#FFFFFF'
        },
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-servicing-admin', 'loan-systems-admin']
      },
      {
        id: 'loan-lookup',
        title: 'Loan Lookup',
        type: 'item',
        icon: 'folder',
        url: '/loan-lookup',
        visible: false,
        badge: {
          title: 0,
          bg: '#3FB2C6',
          fg: '#FFFFFF'
        },
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-servicing-admin', 'loan-systems-admin']
      },
      {
        id: 'change-request-log',
        title: 'Change Request Log',
        type: 'item',
        icon: 'assignment',
        url: '/request-change',
        visible: false,
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-servicing-admin', 'loan-systems-admin']
      },
      {
        id: 'import',
        title: 'CIF Import',
        type: 'item',
        icon: 'assignment',
        url: '/import',
        visible: false,
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-servicing-admin', 'loan-systems-admin']
      }
    ]
  },
  {
    id: 'loanDetails',
    title: 'Loan Details',
    type: 'group',
    visible: true,
    children: [
      {
        id: 'loan-number',
        title: 'Loan Number',
        type: 'item',
        loan: true,
        visible: true,
        badge: {
          title: 0
        },
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-servicing-admin', 'loan-systems-admin']
      },
      {
        id: 'transactions',
        title: 'Transactions',
        type: 'collapse',
        icon: 'view_list',
        visible: false,
        children: [],
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-servicing-admin', 'loan-systems-admin']
      }
    ]
  }
];

export const glaNavigation: Navigation[] = [
  {
    id: 'asset information',
    title: 'Asset Information',
    type: 'group',
    visible: true,
    children: []
  },
  {
    id: 'applications',
    title: 'Applications',
    type: 'group',
    visible: true,
    children: [
      {
        id: 'file-selector',
        title: 'File Selector',
        type: 'item',
        icon: 'folder',
        url: '/file-selector',
        visible: true,
        badge: {
          title: 25,
          bg: '#3FB2C6',
          fg: '#FFFFFF'
        },
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-servicing-admin', 'loan-systems-admin']
      },
      {
        id: 'loan-lookup',
        title: 'Loan Lookup',
        type: 'item',
        icon: 'folder',
        url: '/loan-lookup',
        visible: false,
        badge: {
          title: 0,
          bg: '#3FB2C6',
          fg: '#FFFFFF'
        },
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-servicing-admin', 'loan-systems-admin']
      }
    ]
  },
  {
    id: 'details',
    title: 'Details',
    type: 'group',
    visible: true,
    children: []
  },
  {
    id: 'transactions',
    title: 'Transactions',
    type: 'group',
    visible: true,
    children: [
      {
        id: 'funding-ledger',
        title: 'Funding Ledger',
        type: 'item',
        icon: 'folder',
        url: '/gla/funding-ledger',
        visible: false,
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-servicing-admin', 'loan-systems-admin']
      },
      {
        id: 'transactions',
        title: 'Transactions',
        type: 'item',
        icon: 'folder',
        url: '/gla/transactions',
        visible: false,
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-servicing-admin', 'loan-systems-admin']
      }
    ]
  }
];

export const adminNavigation: Navigation[] = [
  {
    id: 'asset information',
    title: 'Asset Information',
    type: 'group',
    visible: true,
    children: []
  },
  {
    id: 'target',
    title: 'Target',
    type: 'group',
    visible: true,
    children: [
      {
        id: 'field-options',
        title: 'Field Options',
        type: 'item',
        icon: 'place',
        url: '/field-options',
        visible: false,
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-servicing-admin', 'loan-systems-admin']
      },
      {
        id: 'support-values',
        title: 'Support Values',
        type: 'item',
        icon: 'notes',
        url: '/support-values',
        visible: false,
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-servicing-admin', 'loan-systems-admin']
      },
      {
        id: 'validation',
        title: 'Validation',
        type: 'item',
        icon: 'check',
        url: '/validation',
        visible: false,
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-servicing-admin', 'loan-systems-admin']
      },
      {
        id: 'ebm-report',
        title: 'EBM Report',
        type: 'item',
        icon: 'report',
        url: '/ebm-report',
        visible: false,
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-servicing-admin', 'loan-systems-admin']
      },
      {
        id: 'ebm-logging',
        title: 'EBM Logging',
        type: 'item',
        icon: 'report',
        url: '/ebm-logging',
        visible: false,
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-servicing-admin', 'loan-systems-admin']
      },
      {
        id: 'set-configuration',
        title: 'Set Configuration',
        type: 'item',
        icon: 'segment',
        url: '/set-configuration',
        visible: false,
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-servicing-admin', 'loan-systems-admin']
      },
      {
        id: 'target-layout',
        title: 'Target Layout',
        type: 'item',
        icon: 'segment',
        url: '/target-layout',
        visible: false,
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-servicing-admin', 'loan-systems-admin']
      },
      {
        id: 'target-configurations',
        title: 'Target Configurations',
        type: 'item',
        icon: 'tune',
        url: '/target-configurations',
        visible: false,
        roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-systems-admin']
      },
      {
        id: 'target-credentials',
        title: 'Target Credentials',
        type: 'item',
        icon: 'tune',
        url: '/target-credentials',
        visible: false,
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-servicing-admin', 'loan-systems-admin']
      }
    ]
  },
  {
    id: 'source',
    title: 'Source',
    type: 'group',
    visible: true,
    children: [
      {
        id: 'loan-files',
        title: 'Loan Files',
        type: 'item',
        icon: 'rounded_corner',
        url: '/loan-files',
        visible: false,
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-servicing-admin', 'loan-systems-admin']
      },
      {
        id: 'encompass-loan-search',
        title: 'Encompass Loan Search',
        type: 'item',
        icon: 'rounded_corner',
        url: '/encompass-loan-search',
        visible: false,
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-servicing-admin', 'loan-systems-admin']
      },
      {
        id: 'translators',
        title: 'GL Translators',
        type: 'item',
        icon: 'transform',
        url: '/gla/translators',
        visible: false,
        roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales']
      },
      {
        id: 'source-configurations',
        title: 'Source Configurations',
        type: 'item',
        icon: 'track_changes',
        url: '/source-configurations',
        visible: false,
        roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-systems-admin']
      }
    ]
  },
  {
    id: 'settings',
    title: 'Settings',
    type: 'group',
    visible: true,
    children: [
      {
        id: 'users',
        title: 'Users',
        type: 'item',
        icon: 'person_add',
        url: '/users',
        visible: true,
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-servicing-admin', 'loan-systems-admin']
      },
      {
        id: 'logging',
        title: 'Logging',
        type: 'item',
        icon: 'rounded_corner',
        url: '/logging',
        visible: false,
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-servicing-admin', 'loan-systems-admin']
      },
      {
        id: 'doc-connector/logs',
        title: 'Doc Connector Logs',
        type: 'item',
        icon: 'list',
        url: '/doc-connector/logs',
        visible: false,
        roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-systems-admin']
      }
    ]
  }
];

export const docConnectorNavigation: Navigation[] = [
  {
    id: 'asset information',
    title: 'Asset Information',
    type: 'group',
    visible: true,
    children: []
  },
  {
    id: 'applications',
    title: 'Applications',
    type: 'group',
    visible: true,
    children: [
      {
        id: 'doc-connector/history',
        title: 'History',
        type: 'item',
        icon: 'history',
        url: '/doc-connector/history',
        visible: true,
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-servicing-admin', 'loan-systems-admin']
      },
      {
        id: 'doc-connector/global-metas',
        title: 'Global Indexes',
        type: 'item',
        icon: 'format_indent_increase',
        url: '/doc-connector/global-metas',
        visible: true,
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-servicing-admin', 'loan-systems-admin']
      },
      {
        id: 'doc-connector/source-and-destination',
        title: 'Destination',
        type: 'item',
        icon: 'multiple_stop',
        url: '/doc-connector/source-and-destination',
        visible: true,
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin']
        // roles: ['nxtsoft-dev', 'nxtsoft-operations', 'nxtsoft-sales', 'super-admin', 'client-admin', 'loan-servicing-admin', 'loan-systems-admin']
      }
    ]
  }
];
