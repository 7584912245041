import { Injectable } from '@angular/core';
import { CcmcApiService } from './ccmc-api.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmpowerService {
  currentLoanKey: string;
  constructor(private ccmcApiService: CcmcApiService) {}

  /**
   * Retrieve Files
   * @description displays and sorts the loans, updates the badge count
   * @param {*} callback
   * @memberof empowerService
   */
  retrieveFiles(callback: any) {
    this.ccmcApiService.s3ListFiles('empower-loans').subscribe((res: any) => {
      // console.log(res);
      if (res) {
        return callback.handleRetrievedFiles(null, res.body);
      }
      const errorMessage = {
        message: 'No files found.',
        title: 'No Results'
      };
      return callback.handleRetrievedFiles(errorMessage, null);
    });
  }

  /**
   * Loan Selected
   * @description gets the loan infomration and returns the populated core mapping
   * @param {*} key
   * @param {*} callback
   * @memberof empowerService
   */
  loanSelected(key: any, callback: any) {
    this.currentLoanKey = key;
    this.ccmcApiService
      .s3GetFile('empower-loans', key)
      .subscribe((res: any) => {
        if (res.statusFlag) {
          console.log('s3getFile result ', res);
          this.ccmcApiService.ice(res.content).subscribe(
            (response: any) => {
              console.log(response);
              if (response.statusFlag === true) {
                return callback.handleCoreMappingResponse(null, response);
              } else if (response.statusMessage) {
                const errorMessage = {
                  message: response.statusMessage,
                  title: 'Ice Error'
                };
                return callback.handleCoreMappingResponse(errorMessage, null);
              } else {
                const errorMessage = {
                  message: response.errorMessage,
                  title: 'Ice Error'
                };
                return callback.handleCoreMappingResponse(errorMessage, null);
              }
            },
            error => {
              console.log(error);
              const errorMessage = {
                message: error,
                title: 'Ice Error'
              };
              return callback.handleCoreMappingResponse(errorMessage, null);
            }
          );
        } else {
          const errorMessage = {
            message: 'Error Retrieving Loan',
            title: 'Loan Error'
          };
          return callback.handleCoreMappingResponse(errorMessage, null);
        }
      });
  }

  /**
   * Loan Booked
   * @description Defines what is done to the LOS after the loan has been booked
   * This is a required function, leave empty if nothing is required for the LOS
   * @param {*} result
   * @memberof empowerService
   */
  loanBooked(result: any, configurations: any) {
    if (!configurations.demoMode && !configurations.implementationMode) {
      // Record Sender
      this.ccmcApiService.recordSender().subscribe(result => {
        console.log(result);
      });
      if (environment.production) {
        // deletes the loan from S3
        this.ccmcApiService
          .s3DeleteFile('empower-loans', this.currentLoanKey, 'loan-booked')
          .subscribe(response => console.log('response ', response));
      }
    }
  }
  async deleteLoan(loanPath: any) {
    return new Promise(resolve => {
      this.ccmcApiService
        .s3DeleteFile('empower-loans', loanPath, 'user-deletion')
        .subscribe(response => {
          console.log('response ', response);
          resolve(response);
        });
    });
  }
}
