import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { CcmcApiService } from '../../../services/ccmc-api.service';
// import 'codemirror/addon/display/autorefresh';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CCMCEdgeService } from 'src/@ccmc/services/edge.service';
@Component({
  selector: 'app-edit-snippets-dialog',
  templateUrl: './edit-snippets-dialog.component.html',
  styleUrls: ['./edit-snippets-dialog.component.scss']
})
export class EditSnippetsDialogComponent implements OnInit {
  editedFlag = false;
  snippet: any;
  codemirrorConfig = {
    mode: 'vbscript',
    lineNumbers: true,
    theme: 'material',
    extraKeys: { 'Ctrl-Space': 'autocomplete' },
    lineWrapping: true,
    foldGutter: true,
    gutters: [
      'CodeMirror-linenumbers',
      'CodeMirror-foldgutter',
      'CodeMirror-lint-markers'
    ],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true
  };
  translators: any;
  private documentLosSub: Subscription;
  unsubscribe: Subject<any> = new Subject();
  constructor(
    public dialogRef: MatDialogRef<EditSnippetsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public oldSnippet: any,
    private ccmcApiService: CcmcApiService,
    private zone: NgZone,
    private snackBar: MatSnackBar,
    private edgeService: CCMCEdgeService
  ) {}

  ngOnInit() {
    this.getData();
    this.snippet = { ...this.oldSnippet };
    this.snippet.snippet = this.snippet.snippet.replace(/(\r)/gm, '');
    console.log(this.snippet);
  }

  getData() {
    // Subscribe to the document LOS to get the translators
    this.documentLosSub = this.ccmcApiService.documentLos
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(documentLos => {
        if (documentLos) {
          this.translators = documentLos.loan;
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  fieldEdited() {
    this.editedFlag = true;

    if (this.isEquivalent(this.snippet, this.oldSnippet)) {
      console.log('is equiv');
      this.editedFlag = false;
    }
  }

  saveSnippet() {
    this.compileSnippet();
  }

  updateSnippet() {
    this.ccmcApiService
      .createDynamoDB('test', 'code_snippets', this.snippet)
      .subscribe(result => {
        if (result) {
          this.openSnackBar('Updated Code Snippet', 'Okay');
          this.dialogRef.close({
            data: true
          });
        }
      });
  }

  closeDialog() {
    this.dialogRef.close({ data: false });
  }

  openSnackBar(message: string, action: string) {
    this.zone.run(() => {
      setTimeout(() => {
        this.snackBar.open(message, action, {
          duration: 5000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }, 0);
    });
  }

  isEquivalent(a: any, b: any) {
    console.log(a);
    console.log(b);
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      console.log();
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];
      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
        return false;
      }
    }
    return true;
  }

  compileSnippet() {
    let multEnd;
    // Initialize vbscript errors to empty array
    let vbScriptErrors: any[] = [];
    // Pass vbscript to the compile engine
    // this.edgeService.compileEngine(
    //   this.snippet.snippet,
    //   async (error: any, result: any) => {
    //     // splits the error message into an array
    //     if (result.indexOf('\r\n') !== -1) {
    //       vbScriptErrors = result.split('\r\n');
    //     }
    //     // If vbscript errors exist
    //     if (vbScriptErrors.length > 0) {
    //       for (let i = 0; i < vbScriptErrors.length - 1; i++) {
    //         // checks to see if the error message is because of an undeclared variable
    //         if (vbScriptErrors[i].indexOf('BC30451') > 0) {
    //           const tmp = vbScriptErrors[i].match(/'(.*)'/);
    //           let tempTranslator: any;
    //           if (tmp[1].length > 0 && tmp[1] !== 'MXR') {
    //             tempTranslator = tmp[1];
    //             if (
    //               this.translators.findIndex(
    //                 (t: any) => t.fieldName === tempTranslator
    //               ) == -1
    //             ) {
    //               console.log(tempTranslator);
    //               let lastChar = tempTranslator[tempTranslator.length - 1];
    //               // If last character is a number remove it
    //               if (lastChar >= '0' && lastChar <= '9') {
    //                 multEnd = +lastChar;
    //                 tempTranslator = tempTranslator.slice(0, -1);
    //               } else {
    //                 multEnd = null;
    //               }
    //               lastChar = tempTranslator[tempTranslator.length - 1];
    //               if (lastChar >= '0' && lastChar <= '9') {
    //                 multEnd = multEnd! + 10 * +lastChar;
    //                 tempTranslator = tempTranslator.slice(0, -1);
    //               }
    //               const tmpIndex = this.translators.findIndex(
    //                 (obj: any) => obj.fieldName === tempTranslator
    //               );
    //               if (multEnd && tmpIndex > -1) {
    //                 if (multEnd > this.translators[tmpIndex].multEnd) {
    //                   console.log(this.translators[tmpIndex]);
    //                   this.openSnackBar(
    //                     'Exceeds Mult End ' +
    //                       this.translators[tmpIndex].multEnd +
    //                       '.',
    //                     'Okay'
    //                   );
    //                   return;
    //                 }
    //               }
    //               // If index is less than 1 the translator doesnt exist
    //               if (tmpIndex < 0) {
    //                 const msg = vbScriptErrors[i];
    //                 this.openSnackBar(msg, 'Okay');
    //                 return;
    //               }
    //             }
    //             // If we get here we know the translator exists and we can remove this error from the
    //             // errors list
    //             vbScriptErrors.splice(i, 1);
    //             i--;
    //           }
    //         }
    //       }
    //     }
    //     // Remove MXR Error here
    //     for (let i = 0; i < vbScriptErrors.length; i++) {
    //       const tmp = vbScriptErrors[i].match(/'(.*)'/);
    //       if (tmp && tmp[1] == 'MXR') {
    //         vbScriptErrors.splice(i, 1);
    //         i--;
    //       }
    //     }
    //     // If there are errors display the error
    //     if (vbScriptErrors.length > 1) {
    //       const msg = vbScriptErrors[0];
    //       this.openSnackBar(msg, 'Okay');
    //       return;
    //     } else {
    //       this.updateSnippet();
    //     }
    //   }
    // );
  }
}
