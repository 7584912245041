<div class="new-source-Container">
  <div
    class="moveContainer"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  ></div>
  <div
    style="display: flex; flex-direction: row; justify-content: space-between"
  >
    <div>
      <h1>New Source</h1>
    </div>
    <div>
      <h1>
        <i class="material-icons noselect" (click)="closeDialog()"> close </i>
      </h1>
    </div>
  </div>
  <div class="formContainer">
    <div>
      <mat-form-field>
        <input
          matInput
          #valueInput
          placeholder="Source"
          [(ngModel)]="tempSource"
          (keyup.enter)="addNewSource()"
        />
      </mat-form-field>
    </div>
  </div>

  <div class="Btn-Container">
    <button class="cancelBtn" mat-stroked-button (click)="closeDialog()">
      Cancel
    </button>

    <button class="add-Btn" mat-raised-button (click)="addNewSource()">
      <mat-icon style="color: #3fb2c6">add_circle</mat-icon> &nbsp; Add Source
    </button>
  </div>
</div>
