<div class="colorPickerContainer">
    <div class="moveContainer" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle></div>
    <div style="display: flex; flex-direction: row">
        <div style="margin: auto">
            <h1 style="margin: auto; padding-bottom: 10px; padding-top: 10px">
                Row Color Selector
            </h1>
        </div>
    </div>

    <mat-radio-group class="color-radio-group" [(ngModel)]="localSelected.rowColor">
        <mat-radio-button class="color-radio-button" *ngFor="let color of selectionColor, let i = index" [value]="color.value">
            {{ color.viewValue }}
        </mat-radio-button>
    </mat-radio-group>

    <div class="btn-container" readonly>
        <button class="cancelBtn" mat-stroked-button (click)="closeDialog()">
      Cancel
    </button>
        <button class="addBtn" mat-raised-button (click)="updateRowColor()">
      <mat-icon style="color: #3fb2c6">add_circle</mat-icon> &nbsp;Update
    </button>
    </div>
</div>