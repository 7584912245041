import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-debit-cedit-check-dialog',
  templateUrl: './debit-cedit-check-dialog.component.html',
  styleUrls: ['./debit-cedit-check-dialog.component.scss']
})
export class DebitCeditCheckDialogComponent implements OnInit {
  checkedOff = false;
  useTotal: any;
  useCredit: any;
  useDebit: any;
  constructor(
    public dialogRef: MatDialogRef<DebitCeditCheckDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.useTotal = parseFloat(this.data.total).toFixed(2);
    this.useCredit = parseFloat(this.data.credit).toFixed(2);
    this.useDebit = parseFloat(this.data.debit).toFixed(2);
  }

  closeDialog(dataBoolean: any) {
    this.dialogRef.close(dataBoolean);
  }
  checkIfDisabled() {
    if (this.data.total === 0 || this.checkedOff) {
      return false;
    } else {
      return true;
    }
  }
}
