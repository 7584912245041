import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  AfterViewInit
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';

interface CollateralDetails {
  name: string;
  value: string;
}

@Component({
  selector: 'ccmc-collateral-details-dialog',
  templateUrl: './collateral-details-dialog.component.html',
  styleUrls: ['./collateral-details-dialog.component.scss']
})
export class CCMCCollateralDetailsDialogComponent implements OnInit {
  tableColumns: Array<string> = ['name', 'value'];
  // tableData: Array<CollateralDetails> = [
  //   { name: 'Collateral City', value: 'Sanford' },
  //   { name: 'Collateral State', value: 'FL' },
  //   { name: 'Collateral Zip Code', value: '43701' },
  //   { name: 'Number of Units', value: '1' },
  //   { name: 'Collateral County', value: 'MUSKINGUM' },
  //   { name: 'Occupancy Type', value: 'RO' },
  //   { name: 'APN Number', value: '#555-88-999-93939' },
  //   { name: 'Flood Hazard Status', value: '0' }
  // ];
  tableData: Array<CollateralDetails> = [];
  currentIterator = 0;
  nextBtnDisabled = false;
  previousBtnDisabled = false;
  collateralDetailsDataSource: MatTableDataSource<any>;
  @ViewChild('collateralDetailsSort')
  collateralDetailsSort: MatSort;
  @ViewChild('collateralDetailsTable') table: MatTable<any>;

  /**
   * Creates an instance of CCMCCollateralDetailsDialogComponent.
   * @param {Array<any>} data
   * @param {MatDialogRef<CCMCCollateralDetailsDialogComponent>} dialogRef
   * @memberof CCMCCollateralDetailsDialogComponent
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Array<any>,
    private dialogRef: MatDialogRef<CCMCCollateralDetailsDialogComponent>
  ) {}

  /**
   * On Init
   *
   * @memberof CCMCCollateralDetailsDialogComponent
   */
  ngOnInit() {
    // Check if next button is disabled
    this.checkDisableNext();
    // Check if previous button is disabledd
    this.checkDisablePrevious();
    // get data needed for component
    this.getData();
    // Set collateralDetails table
    this.collateralDetailsDataSource = new MatTableDataSource(this.tableData);
  }

  /**
   * Get Data
   * @description sets the property name and value for each piece of collateral data
   * @memberof CCMCCollateralDetailsDialogComponent
   */
  getData() {
    // Get name and value for current data
    console.log(this.data);
    for (let i = 0; i < this.data[this.currentIterator].fields.length; i++) {
      if (this.data[this.currentIterator].fields[i].fieldValue !== '') {
        this.tableData.push({
          name: this.data[this.currentIterator].fields[i].fieldDisplay,
          value: this.data[this.currentIterator].fields[i].fieldValue
        });
      }
    }
  }

  /**
   * Update Navigation Buttons
   * @description updates the current view for buttons, rendering the data
   * @memberof CCMCCollateralDetailsDialogComponent
   */
  updateNavBtns() {
    // Check if previous should be disabled
    this.checkDisablePrevious();
    // Check if next button should be disabled
    this.checkDisableNext();
    // init length for reset
    this.tableData.length = 0;
    // Get name and value for each data
    this.getData();
    // Render current rows
    this.table.renderRows();
  }

  /**
   * Check Disable Next
   * @description determine if the next button should be enabled
   * @memberof CCMCCollateralDetailsDialogComponent
   */
  checkDisableNext() {
    this.nextBtnDisabled =
      this.currentIterator === this.data.length - 1 ? true : false;
  }

  /**
   * Next
   * @description goes to the next piece of collateral
   * @memberof CCMCCollateralDetailsDialogComponent
   */
  next() {
    this.currentIterator++;
    this.updateNavBtns();
  }

  /**
   * Check Disabled Previous
   * @description determine if previous is disabled
   * @memberof CCMCCollateralDetailsDialogComponent
   */
  checkDisablePrevious() {
    this.previousBtnDisabled = this.currentIterator === 0 ? true : false;
  }

  /**
   * Previous
   * @description goes to the previous piece of collateral
   * @memberof CCMCCollateralDetailsDialogComponent
   */
  previous() {
    this.currentIterator--;
    this.updateNavBtns();
  }

  /**
   * Close dialog
   * @description closes the dialog
   * @memberof CCMCCollateralDetailsDialogComponent
   */
  close() {
    this.dialogRef.close();
  }
}
