import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CCMCSharedModule } from '../../../@ccmc/shared.module';

import { CCMCToolbarComponent } from '../../../app/main/toolbar/toolbar.component';
import {
  CCMCSearchBarModule,
  CCMCShortcutsModule
} from '../../../@ccmc/components';
import { CCMCSearchBorrowerDialogModule } from '../../../@ccmc/components/search-borrower-dialog/search-borrower-dialog.module';
import { CCMCFieldHelpDialogModule } from '../../../@ccmc/components/field-help-dialog/field-help-dialog.module';
import { CCMCUserProfileModule } from './user-profile/user-profile.module';
import { CCMCChangePasswordModule } from './change-password/change-password.module';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { SelectSpectrumCredentialsModule } from 'src/@ccmc/components/select-spectrum-credentials/select-spectrum-credentials.module';
import { UpdateSpectrumCredentialsModule } from 'src/@ccmc/components/update-spectrum-credentials/update-spectrum-credentials.module';
// const routes: Routes = [
//     {
//         path: ''
//     }
// ];
@NgModule({
  declarations: [CCMCToolbarComponent],
  imports: [
    RouterModule,

    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    MatToolbarModule,
    KeyboardShortcutsModule.forRoot(),
    CCMCSharedModule,
    CCMCSearchBarModule,
    CCMCShortcutsModule,
    CCMCSearchBorrowerDialogModule,
    CCMCFieldHelpDialogModule,
    CCMCUserProfileModule,
    CCMCChangePasswordModule,
    MatSliderModule,
    MatSlideToggleModule,
    SelectSpectrumCredentialsModule,
    UpdateSpectrumCredentialsModule
  ],
  exports: [CCMCToolbarComponent]
})
export class CCMCToolbarModule {}
