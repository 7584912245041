<div class="send-doc-connector-dialog">
  <div mat-dialog-content class="send-doc-connector-dialog-message">
    <div>Send {{ loanNumber }} through the doc connector process?</div>
    <div>
      <mat-checkbox [(ngModel)]="resetBeforeSend"
        >Reset Before Send</mat-checkbox
      >
    </div>
  </div>

  <div
    mat-dialog-actions
    class="pt-30"
    class="send-doc-connector-dialogBtn-Container"
  >
    <button
      class="cancelBtn"
      mat-stroked-button
      (click)="
        dialogRef.close({
          statusFlag: false,
          statusMessage: 'Canceled Request'
        })
      "
    >
      Cancel
    </button>
    <button
      mat-raised-button
      class="mat-accent mr-16"
      (click)="
        dialogRef.close({
          statusFlag: true,
          resetBeforeSend,
          statusMessage: 'Process Request'
        })
      "
    >
      Confirm
    </button>
  </div>
</div>
