<div class="edit-global-metas-container">
  <div class="bottomTitle">
    Selection Details
    <div class="btn-container">
      <button
        mat-raised-button
        matTooltip="Get Encompass path."
        matTooltipClass="toolTipStyle"
        (click)="getEncompassPath()"
        color="primary"
      >
        Get Path
      </button>
      &nbsp;
      <button
        mat-raised-button
        matTooltip="Saves any changes you make, within this section."
        matTooltipClass="toolTipStyle"
        color="accent"
        (click)="save()"
        [disabled]="!editedFlag"
      >
        Save
      </button>
    </div>
  </div>
  <div class="textFields">
    <div class="textInputs">
      <mat-form-field class="inputText">
        <input
          matInput
          #sourceInput
          placeholder="Name"
          [(ngModel)]="localSelectedGlobalMeta.name"
          (ngModelChange)="fieldEdited()"
        /> </mat-form-field
      ><mat-form-field class="inputText">
        <input
          matInput
          #sourceInput
          type="number"
          placeholder="Order"
          [(ngModel)]="localSelectedGlobalMeta.index"
          (ngModelChange)="fieldEdited()"
        />
      </mat-form-field>
      <mat-form-field class="inputText">
        <input
          matInput
          #sourceInput
          placeholder="Value"
          [(ngModel)]="localSelectedGlobalMeta.value"
          (ngModelChange)="fieldEdited()"
        />
      </mat-form-field>
    </div>

    <mat-form-field style="width: 100%" id="path">
      <mat-label style="padding: 10px">Path</mat-label>
      <textarea
        class="textareanotes"
        matInput
        placeholder="Path"
        [(ngModel)]="localSelectedGlobalMeta.path"
        (ngModelChange)="fieldEdited()"
      ></textarea>
    </mat-form-field>
  </div>
</div>
