import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EditSetColumnDialogComponent } from '../../../../../@ccmc/components/edit-set-column-dialog/edit-set-column-dialog.component';
import { FieldEditedService } from '../../../../../@ccmc/services/field-edited.service';
import { GeneralLedgerAccountingService } from '../../../../../@ccmc/services/general-ledger-accounting.service';
import { CCMCSelectedFieldService } from '../../../../../@ccmc/services/selected-field.service';

@Component({
  selector: 'app-set-configuration-columns',
  templateUrl: './set-configuration-columns.component.html',
  styleUrls: ['./set-configuration-columns.component.scss']
})
export class SetConfigurationColumnsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  selectedColumn: any;
  localSelectedColumn: any;
  selectedTransaction: any;
  selectedColumnSubscription: Subscription;
  selectedTransactionSubscription: Subscription;
  glaDocumentSubscription: Subscription;
  fieldEditedSubscription: Subscription;
  columnEditedFlag: any;
  displayedColumns = ['columnID', 'columnName', 'vbScript', 'edited'];
  columnsDataSource: any;
  editColumnsDisplayedColumns = ['columnID', 'columnName', 'active'];
  editColumnsDataSource: any;
  unsubscribe: Subject<any> = new Subject();
  coreLayout: any;
  codemirrorConfig = {
    mode: 'vbscript',
    lineNumbers: true,
    theme: 'material',
    extraKeys: {
      'Ctrl-Space': 'autocomplete',
      'Ctrl-S': function () {
        // this.save();
      }
    }
  };
  editedFlag = false;
  shortcuts: ShortcutInput[] = [];
  constructor(
    private selectedFieldService: CCMCSelectedFieldService,
    private glaService: GeneralLedgerAccountingService,
    private fieldEditedService: FieldEditedService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getData();
  }

  //make shortcuts into a array to append shortcuts into ngAfterViewInit
  //shortcuts: ShortcutInput[] = [];

  //ngAfterViewinit method that calls the cmd+s shortcut
  ngAfterViewInit() {
    this.shortcuts.push({
      key: ['cmd + s'],
      label: 'Saving',
      description: 'saving',
      command: e => {
        this.save();
      }
    });
  }

  save() {
    console.log('save here');
    console.log('Local Selected Column', this.localSelectedColumn);
    this.editedFlag = false;
    this.selectedTransaction.edited = true;
    this.fieldEditedService.onGLADocumentEdited.next(true);
    this.localSelectedColumn.edited = true;
    // this.selectedColumn = JSON.parse(JSON.stringify(this.localSelectedColumn));
    const jsonFields = Object.keys(this.localSelectedColumn);
    for (let field of jsonFields) {
      this.selectedColumn[field] = this.localSelectedColumn[field];
    }
    console.log(this.selectedColumn);
    this.fieldEditedService.fieldEdited.next(false);
  }

  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  getData() {
    this.fieldEditedService.fieldEdited.next(false);
    this.glaDocumentSubscription = this.glaService.glaDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(glaDocument => {
        if (glaDocument) {
          this.coreLayout = glaDocument.coreLayout;
        }
      });
    // Subscribe to the selected transaction
    this.selectedTransactionSubscription =
      this.selectedFieldService.onGLATransactionFieldSelected
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(selectedTransaction => {
          console.log(selectedTransaction);
          if (selectedTransaction) {
            this.selectedTransaction = selectedTransaction;
            if (selectedTransaction.columns) {
              this.getColumnNames(selectedTransaction.columns);
            }
          } else {
            console.log('clear column stuff here');
            this.selectedColumn = undefined;
            this.localSelectedColumn = undefined;
            this.columnsDataSource = new MatTableDataSource([]);
          }
        });

    // Subscribe to the selected column
    this.selectedColumnSubscription =
      this.selectedFieldService.onTransactionColumnFieldSelected
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(selectedColumn => {
          console.log(selectedColumn);
          if (selectedColumn) {
            this.selectedColumn = selectedColumn;
            this.localSelectedColumn = JSON.parse(
              JSON.stringify(selectedColumn)
            );
          }
        });
  }

  getColumnNames(columns: any) {
    for (let column of columns) {
      const coreLayoutIndex = this.coreLayout.findIndex(
        (layout: any) => layout.columnID === column.columnID
      );
      if (coreLayoutIndex > -1) {
        column.columnName = this.coreLayout[coreLayoutIndex].columnName;
      } else {
        column.columnName = '';
      }
    }
    console.log(columns);
    this.columnsDataSource = new MatTableDataSource(columns);
    this.columnsDataSource.paginator = this.paginator;
    this.columnsDataSource.sort = this.sort;
    this.sort.active = 'columnID';
    this.sort.direction = 'asc';
    this.onSelectColumn(columns[0]);
  }

  onSelectColumn(column: any) {
    console.log(column);
    this.selectedFieldService.onTransactionColumnFieldSelected.next(column);
  }

  fieldEdited(event: any) {
    console.log(event);
    // Checks to see if modified local selected variable is the same as the current fields
    if (this.isEquivalent(this.selectedColumn, this.localSelectedColumn)) {
      this.editedFlag = false;
      this.fieldEditedService.fieldEdited.next(false);
    } else {
      this.editedFlag = true;
      this.fieldEditedService.fieldEdited.next(true);
    }
  }

  isEquivalent(a: any, b: any) {
    if (a.vbScript.replace(/(\r)/gm, '') !== b.vbScript) {
      // this.vbScriptFlag = true;
      return false;
    } else if (a.disabled !== b.disabled) {
      return false;
    }
    // this.vbScriptFlag = false;
    return true;
  }

  openEditColumnDialog(column: any) {
    const editColumnDialogRef = this.dialog.open(EditSetColumnDialogComponent, {
      data: column
    });
    editColumnDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedTransaction.edited = true;
        this.getColumnNames(this.selectedTransaction.columns);
      }
    });
  }
}
