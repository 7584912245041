import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';

@Component({
  selector: 'app-update-miser-credentials',
  templateUrl: './update-miser-credentials.component.html',
  styleUrls: ['./update-miser-credentials.component.scss']
})
export class UpdateMiserCredentialsComponent implements OnInit {
  miserUserCredentialItem: any;
  showPassword = false;
  localMiserUserCredentialItem: any;
  editedFlag = false;
  checked: any = false;
  constructor(
    private amplifyService: AmplifyService,
    private ccmcApiService: CcmcApiService,
    private snackBarService: SnackbarService,
    private dialogRef: MatDialogRef<UpdateMiserCredentialsComponent>,
    private adminApiService: AdminApiService
  ) {}

  ngOnInit(): void {
    this.localMiserUserCredentialItem = {
      cognitoID: this.amplifyService.getCognitoId(),
      tellerNumber: '',
      stationName: '',
      cashBoxNumber: '',
      pin: '',
      assetID: ''
    };
    this.getCurrentCredentials();
  }

  getCurrentCredentials() {
    this.adminApiService
      .getDynamoDBItem(
        'fis_miser_users',
        this.amplifyService.getCognitoId(),
        'cognitoID'
      )
      .subscribe(result => {
        if (result) {
          const parsedResult = JSON.parse(JSON.stringify(result));
          this.miserUserCredentialItem = parsedResult;
          this.localMiserUserCredentialItem = JSON.parse(
            JSON.stringify(result)
          );
        }
      });
  }

  updateCredentials() {
    if (this.editedFlag) {
      if (this.checked === true) {
        this.ccmcApiService
          .createDynamoDB(
            'test',
            'fis_miser_users',
            this.localMiserUserCredentialItem
          )
        .subscribe(result => {
          if (result) {
            this.snackBarService.openSnackBar(
              'Updated Miser Credentials',
              'Okay'
            );
            this.dialogRef.close(true);
          } else {
            this.snackBarService.openSnackBar(
              'Error Updated Credentials',
              'Error'
            );
          }
        });
        this.ccmcApiService.configurations.saveCredentials = true;
      } else {
        this.ccmcApiService.setOriginalCashBoxNumber(this.ccmcApiService.configurations.miserCashBoxNumber);
        this.ccmcApiService.configurations.tellerNumber = this.localMiserUserCredentialItem.tellerNumber;
        this.ccmcApiService.configurations.miserCashBoxNumber = this.localMiserUserCredentialItem.cashBoxNumber;
        this.ccmcApiService.configurations.pin = this.localMiserUserCredentialItem.pin;
        this.ccmcApiService.configurations.stationName = this.localMiserUserCredentialItem.stationName;
        this.ccmcApiService.configurations.saveCredentials = false;
        this.dialogRef.close(true);
      }  
    }
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  checkForm() {
    this.editedFlag = true;
    if (
      this.isEquivalent(
        this.localMiserUserCredentialItem,
        this.miserUserCredentialItem
      )
    ) {
      this.editedFlag = false;
    }
  }

  isEquivalent(a: any, b: any) {
    console.log(a);
    console.log(b);
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      console.log();
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];
      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
        return false;
      }
    }
    return true;
  }

  checkboxChange(event: any) {
    if(event.checked === true) {
      this.checked = true;
    }
  }
}
