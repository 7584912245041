import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';

@Component({
  selector: 'app-update-premier-credentials',
  templateUrl: './update-premier-credentials.component.html',
  styleUrls: ['./update-premier-credentials.component.scss']
})
export class UpdatePremierCredentialsComponent implements OnInit {
  premierUserCredentialItem: any;
  showPassword = false;
  localPremierUserCredentialItem: any;
  editedFlag = false;
  isNXTsoftOperations = false;
  isNXTsoftDev = false;
  isNXTsoftSales = false;
  isAdmin = false;
  isSuperAdmin = false;
  fiservUserTable: any[] = [];
  selectedRow: any;
  assetID: any[] = [];
  constructor(
    private amplifyService: AmplifyService,
    private ccmcApiService: CcmcApiService,
    private snackBarService: SnackbarService,
    private dialogRef: MatDialogRef<UpdatePremierCredentialsComponent>,
    private adminApiService: AdminApiService,
    private assetService: AssetService
  ) {}

  ngOnInit(): void {
    this.localPremierUserCredentialItem = {
      cognitoID: this.amplifyService.getCognitoId(),
      cStation: '',
      password: '',
      username: '',
    };
   this.selectedRow = {
      cStation: '',
      cognitoID: '',
      password: '',
      username: '',
    };
    this.getCurrentCredentials();
    this.isNXTsoftOperations = this.amplifyService.isNXTsoftOperations;
    this.isNXTsoftSales = this.amplifyService.isNXTsoftSales;
    this.isNXTsoftDev = this.amplifyService.isNXTsoftDev;
    this.isAdmin = this.amplifyService.isAdmin;
    this.isSuperAdmin = this.amplifyService.isSuperAdmin;
  }

  getCurrentCredentials() {
    this.adminApiService
      .getDynamoDBItem(
        'fiserv_premier_users',
        this.amplifyService.getCognitoId(),
        'cognitoID'
      )
      .subscribe(result => {
        if (result) {
          const parsedResult = JSON.parse(JSON.stringify(result));
          this.premierUserCredentialItem = parsedResult;
          console.log(this.premierUserCredentialItem)
          this.localPremierUserCredentialItem = JSON.parse(
            JSON.stringify(result)
          );
          console.log(this.localPremierUserCredentialItem);
        }
      });
  }

  updateCredentials() {
    const variableKeys = Object.keys(this.localPremierUserCredentialItem);
    if(variableKeys.length === 4) {
      this.assetID.push(this.assetService.getSelectedAssetId());
      this.localPremierUserCredentialItem.assetID = this.assetID;
    } else if(this.localPremierUserCredentialItem.assetID.includes(this.assetService.getSelectedAssetId())) {
      console.log('assetID already exists in array');
    } else {
      this.localPremierUserCredentialItem.assetID.push(this.assetService.getSelectedAssetId());
    }
    
    if (this.editedFlag) {
      this.ccmcApiService
        .createDynamoDB(
          'test',
          'fiserv_premier_users',
          this.localPremierUserCredentialItem
        )
        .subscribe(result => {
          if (result) {
            console.log(result);
            this.snackBarService.openSnackBar(
              'Updated Premier Credentials',
              'Okay'
            );
            this.dialogRef.close(true);
          } else {
            this.snackBarService.openSnackBar(
              'Error Updated Credentials',
              'Error'
            );
          }
        });
    }
  }

  continue() {
    if (this.editedFlag) {
      this.ccmcApiService
        .createDynamoDB(
          'test',
          'fiserv_premier_users',
          this.selectedRow
        )
        .subscribe(result => {
          if (result) {
            
            this.dialogRef.close(true);
          }
        });
    }
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  checkForm() {
    this.editedFlag = true;
    if (
      this.isEquivalent(
        this.localPremierUserCredentialItem,
        this.premierUserCredentialItem
      )
    ) {
      this.editedFlag = false;
    } 
  }

  checkPremCreds() {
    this.editedFlag = true;
  }

  isEquivalent(a: any, b: any) {
    console.log(a);
    console.log(b);
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      console.log();
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];
      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
        return false;
      }
    }
    return true;
  }
}
