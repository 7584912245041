<div>
    <h1 matDialogTitle>
        <mat-icon>error_outline</mat-icon> {{ error.title }}
    </h1>

    <div mat-dialog-content>
        <p>{{ error.message }}</p>
    </div>

    <div mat-dialog-actions class="pt-24 error-actions" style="padding-bottom:35px;">
        <!-- <button mat-raised-button class="mat-accent mr-16 " (click)="dialogRef.close(true) ">OK</button> -->
        <button class="ok-button" mat-raised-button color="accent" (click)="dialogRef.close(true)">OK</button>
    </div>
</div>