import { NgModule } from '@angular/core';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';
import { CommonModule } from '@angular/common';
import { CCMCSearchBorrowerDialogComponent } from './search-borrower-dialog.component';

@NgModule({
  imports: [AppMaterialModule, CommonModule],
  declarations: [CCMCSearchBorrowerDialogComponent],
  entryComponents: [CCMCSearchBorrowerDialogComponent]
})
export class CCMCSearchBorrowerDialogModule {}
