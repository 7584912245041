import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { debounceTime, distinctUntilChanged, fromEvent, map, Subject, Subscription, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NewLoanTypeDialogComponent } from 'src/@ccmc/components/new-loan-type-dialog/new-loan-type-dialog.component';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { RequestChangeService } from 'src/@ccmc/services/request-change.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EditLoanTypeDialogComponent } from 'src/@ccmc/components/edit-loan-type-dialog/edit-loan-type-dialog.component';
import { CCMCConfirmDialogComponent } from 'src/@ccmc/components/confirm-dialog/confirm-dialog.component';
import { isUndefined } from 'lodash';

@Component({
  selector: 'app-loan-types',
  templateUrl: './loan-types.component.html',
  styleUrls: ['./loan-types.component.scss']
})
export class LoanTypesComponent implements OnInit {
  isLoanTypesTab = true;
  public selectedFilter: any;
  extraFilter = '';
  dataSourceChangepage: any;
  isNXTsoft = this.amplifyService.isNXTsoft;
  isNXTsoftDev = this.amplifyService.isNXTsoftDev;
  isNXTsoftOp = this.amplifyService.isNXTsoftOperations;
  isNXTsoftSales = this.amplifyService.isNXTsoftSales;
  dataSource: any;
  displayedColumns = ['loanType', 'productName', 'coreValue', 'approver', 'approvedDate', 'edit', 'status'];
  displayedColumnsClients = ['loanType', 'productName', 'coreValue', 'approver', 'approvedDate', 'status'];
  showSpinner: boolean;
  unsubscribe: Subject<any> = new Subject();
  loanTypeCompleted = false;
  filterSearch: string;
  addedLoanTypeSub: Subscription;
  loanTypeObj = {
    loanType: '',
    productName: '',
    coreValue: '',
    approver: '',
    approvedDate: '',
    edit: '',
    status: false
  }
  loanTypesDataArr: any[] = [];
  newLoanTypesDataArr: any[] = [];
  loanTypesArr: any[] = [];
  updatedLoanTypesArr: any[] = [];
  changeRequestDoc: any;
  @ViewChild('filterSearch', { static: true }) filterSearchEl: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private amplifyService: AmplifyService,
    private requestChangeService: RequestChangeService,
    private dialog: MatDialog,
    private snackBarService: SnackbarService
    ) {}

  ngOnInit(): void {
    this.selectedFilter = 'all';
    this.getData();
    this.filterSearchEl.nativeElement.focus();
    fromEvent(this.filterSearchEl.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // Time in milliseconds between key events
        debounceTime(1000),
        // If previous query is diffent from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((text: string) => {
        this.applyFilter(text);
      });
  }

  getData() {
    this.requestChangeService.changeRequestDocStatuses
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(fields => {
      // console.log(check);
      this.changeRequestDoc = fields;
      this.loanTypesArr = fields.loanTypes;
      if (this.loanTypesArr !== undefined && this.loanTypesArr !== null) {
        this.loanTypesArr.forEach((loanType: any) => {
          loanType.values.forEach((item: any) => {
            if(item.status === undefined || item.status === false) {
              this.loanTypeObj = {
                loanType: loanType.selLoanType,
                productName: item.displayName,
                coreValue: item.value,
                approver: item.approvedBy,
                approvedDate: item.approvedDate,
                edit: '',
                status: false
              };
          } else {
            this.loanTypeObj = {
              loanType: loanType.selLoanType,
              productName: item.displayName,
              coreValue: item.value,
              approver: item.approvedBy,
              approvedDate: item.approvedDate,
              edit: '',
              status: true
            };
          }
            this.loanTypesDataArr.push(this.loanTypeObj);
          });
        });
      }
    });
    this.dataSource = new MatTableDataSource(this.loanTypesDataArr);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  changeFilterPredicate(predicate: string) {
    this.extraFilter = '';
    this.dataSource.filter = '';
    console.log(predicate);
    // console.log(this.dataSourceChangepage.filterPredicate);
    if (predicate === 'all') {
      // tslint:disable-next-line: no-shadowed-variable
      this.dataSource.filterPredicate = function (
        data: any,
        filter: string
      ): boolean {
        // tslint:disable-next-line: max-line-length

        return (
          data.loanType.toLowerCase().includes(filter) ||
          data.productName.toLowerCase().includes(filter) ||
          data.coreValue.toLowerCase().includes(filter)
        );
      };
    } else if (predicate === 'loanType') {
      // tslint:disable-next-line: no-shadowed-variable
      this.dataSource.filterPredicate = function (
        data: any,
        filter: string
      ): boolean {
        return (
          data.loanType.toLowerCase().includes(filter) ||
          data.loanType.includes(filter)
        );
      };
    } else if (predicate === 'productName') {
      // tslint:disable-next-line: no-shadowed-variable
      this.dataSource.filterPredicate = function (
        data: any,
        filter: string
      ): boolean {
        return (
          data.productName.toLowerCase().includes(filter) ||
          data.productName.includes(filter)
        );
      };
    } else if (predicate === 'coreValue') {
      // tslint:disable-next-line: no-shadowed-variable
      this.dataSource.filterPredicate = function (
        data: any,
        filter: string
      ): boolean {
        return (
          data.coreValue.toLowerCase().includes(filter) ||
          data.coreValue.includes(filter)
        );
      };
    } 
  }

  applyFilter(filterValue: string) {
    let filteredValueLower = filterValue.toLocaleLowerCase();
    this.dataSource.data = this.loanTypesDataArr;

   if (filterValue.length > 0) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    } else if (filterValue.length === 0) {
      this.dataSource.filter = '';
      this.filterSearch = '';
      return;
    }
    this.filterSearch = filterValue;
  }

  addNewLoanType() {
    this.newLoanTypesDataArr = [];
    const newLoanTypeRef = this.dialog.open(
      NewLoanTypeDialogComponent,
      {
        panelClass: 'new-loan-type__panel-dialog',
        disableClose: true
      }
    );

    newLoanTypeRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataSource = '';
        let loanTypesArr = result;
        loanTypesArr.forEach((loanType: any) => {
          loanType.values.forEach((item: any) => {
            if(item.status === undefined || item.status === false) {
              this.loanTypeObj = {
                loanType: loanType.selLoanType,
                productName: item.displayName,
                coreValue: item.value,
                approver: item.approvedBy,
                approvedDate: item.approvedDate,
                edit: '',
                status: false
              };
          } else {
            this.loanTypeObj = {
              loanType: loanType.selLoanType,
              productName: item.displayName,
              coreValue: item.value,
              approver: item.approvedBy,
              approvedDate: item.approvedDate,
              edit: '',
              status: true
            };
          }
            this.newLoanTypesDataArr.push(this.loanTypeObj);
          });
        });
        this.updatedLoanTypesArr = this.newLoanTypesDataArr;
        this.dataSource = [...this.newLoanTypesDataArr];
        this.snackBarService.openSnackBar('Added new loan type item', 'Okay');
      }
    });
  } 

  confirmComplete(element: any) {
    let userName = this.amplifyService.username;
    if(element.status === false) {
      element.status = true;
      element.approver = userName;
      element.approvedDate = new Date().toISOString();
      let pairedLoanTypeIndex = this.loanTypesArr.findIndex((item: any) => {
        return item.selLoanType === element.loanType
      });

      if(pairedLoanTypeIndex > -1) {
        let pairedValuesIndex = this.loanTypesArr[pairedLoanTypeIndex].values.findIndex((value: any) => {
            return value.value === element.coreValue && value.displayName === element.productName
        });

        if(pairedValuesIndex > -1) {
            this.loanTypesArr[pairedLoanTypeIndex].values[pairedValuesIndex].status = element.status;
            this.loanTypesArr[pairedLoanTypeIndex].values[pairedValuesIndex].approvedBy = element.approver;
            this.loanTypesArr[pairedLoanTypeIndex].values[pairedValuesIndex].approvedDate = element.approvedDate;
        }
      }
      this.changeRequestDoc.loanTypes = this.loanTypesArr;
      this.requestChangeService.postDynamoChangeRequestStatuses(this.changeRequestDoc);
      this.snackBarService.openSnackBar('Completed Loan Type item', 'Okay');
    } else {
      element.status = false;
      element.approver = undefined;
      element.approvedDate = undefined
      let pairedLoanTypeIndex = this.loanTypesArr.findIndex((item: any) => {
        return item.selLoanType === element.loanType
      });

      if(pairedLoanTypeIndex > -1) {
        let pairedValuesIndex = this.loanTypesArr[pairedLoanTypeIndex].values.findIndex((value: any) => {
            return value.value === element.coreValue && value.displayName === element.productName
        });

        if(pairedValuesIndex > -1) {
            this.loanTypesArr[pairedLoanTypeIndex].values[pairedValuesIndex].status = element.status;
            this.loanTypesArr[pairedLoanTypeIndex].values[pairedValuesIndex].approvedBy = element.approver;
            this.loanTypesArr[pairedLoanTypeIndex].values[pairedValuesIndex].approvedDate = element.approvedDate;
        }
      }
      this.changeRequestDoc.loanTypes = this.loanTypesArr;
      this.requestChangeService.postDynamoFieldValues(this.changeRequestDoc);
      this.snackBarService.openSnackBar('Marked Loan Type incomplete', 'Okay');
    }
  }

  editLoanTypeItem(element: any) {
    this.newLoanTypesDataArr = [];
    const editLoanTypeRef = this.dialog.open(
      EditLoanTypeDialogComponent,
      {
        panelClass: 'new-loan-type__panel-dialog',
        disableClose: true
      }
    )
    this.requestChangeService.editedLoanTypeField.next(element);
    editLoanTypeRef.afterClosed().subscribe(result => {
      if (result) {
       let loanTypeArr = result;
        loanTypeArr.forEach((loanType: any) => { 
          loanType.values.forEach((item: any) => {
            if(item.status === undefined || item.status === false) {
              this.loanTypeObj = {
                loanType: loanType.selLoanType,
                productName: item.displayName,
                coreValue: item.value,
                approver: item.approvedBy,
                approvedDate: item.approvedDate,
                edit: '',
                status: false
              };
          } else {
            this.loanTypeObj = {
              loanType: loanType.selLoanType,
              productName: item.displayName,
              coreValue: item.value,
              approver: item.approvedBy,
              approvedDate: item.approvedDate,
              edit: '',
              status: true
            };
          }
            this.newLoanTypesDataArr.push(this.loanTypeObj);
          });
        });
        this.updatedLoanTypesArr = this.newLoanTypesDataArr;
        this.dataSource = [...this.newLoanTypesDataArr];
        this.snackBarService.openSnackBar('Successfully edited loan type item', 'Okay');
      }
    });
  }

  deleteLoanTypeItem(element: any) {
    if(this.updatedLoanTypesArr.length === 0) {
      this.updatedLoanTypesArr = this.dataSource.data;
    }
    const confirmDialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
      data: 'Are you sure you want to delete this loan type item?'
    });
    confirmDialogRef.afterClosed().subscribe(data => {
      if (data) {
        let deletedLoanTypeItemIndex = this.updatedLoanTypesArr.findIndex((item: any) =>{
          return item === element;
        });
        if(deletedLoanTypeItemIndex > -1) {
          this.updatedLoanTypesArr.splice(deletedLoanTypeItemIndex, 1);
          this.requestChangeService.changeRequestDocStatuses
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(fields => {
              console.log(fields);
              // console.log(check);
              this.changeRequestDoc = fields;
              this.loanTypesArr = fields.loanTypes;

              let pairedLoanTypeIndex = this.loanTypesArr.findIndex((item: any) => {
                return item.selLoanType === element.loanType
              });
        
              if(pairedLoanTypeIndex > -1) {
                let pairedValuesIndex = this.loanTypesArr[pairedLoanTypeIndex].values.findIndex((value: any) => {
                    return value.value === element.coreValue && value.displayName === element.productName
                });
        
                if(pairedValuesIndex > -1) {
                    this.loanTypesArr[pairedLoanTypeIndex].values.splice(pairedValuesIndex, 1);
                }
              }
              this.changeRequestDoc.loanTypes = this.loanTypesArr;
              this.requestChangeService.postDynamoChangeRequestStatuses(this.changeRequestDoc);
            });
          this.dataSource = [...this.updatedLoanTypesArr];
          this.snackBarService.openSnackBar('Deleted loan type item', 'Okay');
        }
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }
}
