<div
  class="miniscreenzoom"
  style="background-color: #ececec; height: 100%; overflow: hidden"
>
  <div class="filter-container">
    <mat-form-field
      class="filter-FormField"
      style="width: 55%; margin-left: 2%"
    >
      <input
        matInput
        style="background-color: inherit"
        placeholder="Filter Search"
        #filterSearch
      />
    </mat-form-field>
    <mat-form-field style="width: 20%; margin-left: 2%">
      <mat-label>Filter Options</mat-label>
      <mat-select [(ngModel)]="selectedFilter">
        <mat-option value="all" (click)="changeFilterPredicate('all')"
          >Filter All</mat-option
        >
        <mat-option
          value="loanType"
          (click)="changeFilterPredicate('loanType')"
          >Loan Type</mat-option
        >
        <mat-option value="productName" (click)="changeFilterPredicate('productName')"
          >Product Name</mat-option
        >
        <mat-option
          value="coreValue"
          (click)="changeFilterPredicate('coreValue')"
          >Core Value</mat-option
        >
      </mat-select>
    </mat-form-field>
    <button
      *ngIf="isNXTsoftDev || isNXTsoftOp || isNXTsoftSales"
      mat-raised-button
      style="margin-right: 0.5%"
      class="titleSave"
      (click)="addNewLoanType()"   
    >
      New Loan Type
    </button>
  </div>
  <div class="users-container">
    <div #container class="table-container">
      <table
        class="table"
        mat-table
        matSort
        #table
        [dataSource]="dataSource"
        CCMCPerfectScrollbar
        *ngIf="isNXTsoftDev || isNXTsoftOp|| isNXTsoftSales"
      >
        <ng-container matColumnDef="loanType" >
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="loan-type-header"
            >
              Loan Type
            </th>
          >
          <td mat-cell *matCellDef="let element" class="noselect" class="loan-type-row">
                {{ element.loanType }}
          </td>
        </ng-container>
        <!-- Username Column -->
        <ng-container matColumnDef="productName">
          <th mat-sort-header mat-header-cell *matHeaderCellDef class="loan-type-header">
            Product Name
          </th>
          <td mat-cell *matCellDef="let element" class="loan-type-row" style="padding-right: 10px;">
            {{ element.productName }}
          </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="coreValue">
          <th mat-sort-header mat-header-cell *matHeaderCellDef class="loan-type-header">
            Core Value
          </th>
          <td mat-cell *matCellDef="let element" class="noselect" class="loan-type-row">
            {{ element.coreValue }}
          </td>
        </ng-container>

        <ng-container
          matColumnDef="approver"
        >
          <th mat-sort-header mat-header-cell *matHeaderCellDef class="loan-type-header">
            Approver
          </th>
          <td mat-cell *matCellDef="let element" class="noselect" class="loan-type-row">
            {{ element.approver }}
          </td>
        </ng-container>

        <ng-container matColumnDef="approvedDate">
          <th mat-sort-header mat-header-cell *matHeaderCellDef  class="loan-type-header">
            Approved Date
          </th>
          <td mat-cell *matCellDef="let element" class="noselect" class="loan-type-row">
            {{ element.approvedDate | date: 'MM/dd/yy' }}
          </td>
        </ng-container>

        <ng-container
          matColumnDef="edit"
          
        >
          <th id="editButton"mat-header-cell *matHeaderCellDef style="min-width: 25px; max-width: 25px; padding: 11px;">
            Edit
          </th>

          <td mat-cell *matCellDef="let element" class="noselect" style="min-width: 25px; max-width: 25px">
            <mat-icon
                aria-hidden="false"
                style="cursor: pointer"
                aria-label="icon to tell if pinned"
                matTooltip="Edit loan type item"
                matTooltipClass="toolTipStyle"
                (click)="editLoanTypeItem(element)"
            >create
            </mat-icon>
            <mat-icon 
            style="color: red;cursor: pointer" 
            matTooltip="Delete loan type item"
            matTooltipClass="toolTipStyle"
            (click)="deleteLoanTypeItem(element)">
            delete_forever
            </mat-icon>
          </td>
        </ng-container>
        <ng-container
          matColumnDef="status">
          <th id="statusButton" mat-header-cell *matHeaderCellDef style="text-align: center !important;">
            Status
          </th>

          <td mat-cell *matCellDef="let element" style="min-width: 50px; max-width: 50px;text-align: center !important;">
            <button
            mat-raised-button
            style="height: 82%;margin-top: 4%;"
            [ngStyle]="{
            'background-color': element.status ? '#009EB9' : 'white',
            'color': element.status ? 'white' : '#009EB9',
            'border': element.status ? 'none' : '3px solid #009EB9'
          }"
            (click)="confirmComplete(element)"
        >
        
        <span *ngIf="element.status === false">
          Complete
        </span>
        <span *ngIf="element.status === true">
          Completed
        </span>
        </button>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          
          
        ></tr>
      </table>
      <table
        class="table"
        mat-table
        matSort
        #table
        [dataSource]="dataSource"
        CCMCPerfectScrollbar
        *ngIf="!isNXTsoftDev && !isNXTsoftOp && !isNXTsoftSales"
      >
        <ng-container matColumnDef="loanType" >
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="loan-type-header"
            >
              Loan Type
            </th>
          >
          <td mat-cell *matCellDef="let element" class="noselect" class="loan-type-row">
                {{ element.loanType }}
          </td>
        </ng-container>
        <!-- Username Column -->
        <ng-container matColumnDef="productName">
          <th mat-sort-header mat-header-cell *matHeaderCellDef class="loan-type-header">
            Product Name
          </th>
          <td mat-cell *matCellDef="let element" class="loan-type-row" style="padding-right: 10px;">
            {{ element.productName }}
          </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="coreValue">
          <th mat-sort-header mat-header-cell *matHeaderCellDef class="loan-type-header">
            Core Value
          </th>
          <td mat-cell *matCellDef="let element" class="noselect" class="loan-type-row">
            {{ element.coreValue }}
          </td>
        </ng-container>

        <ng-container
          matColumnDef="approver"
        >
          <th mat-sort-header mat-header-cell *matHeaderCellDef class="loan-type-header">
            Approver
          </th>
          <td mat-cell *matCellDef="let element" class="noselect" class="loan-type-row">
            {{ element.approver }}
          </td>
        </ng-container>

        <ng-container matColumnDef="approvedDate">
          <th mat-sort-header mat-header-cell *matHeaderCellDef class="loan-type-header">
            Approved Date
          </th>
          <td mat-cell *matCellDef="let element" class="noselect" class="loan-type-row">
            {{ element.approvedDate | date: 'MM/dd/yy' }}
          </td>
        </ng-container>
        <ng-container
          matColumnDef="status">
          <th id="statusButton" mat-header-cell *matHeaderCellDef style="text-align: center !important;">
            Status
          </th>

          <td mat-cell *matCellDef="let element" style="min-width: 50px; max-width: 50px;text-align: center !important;">
            <button
            mat-raised-button
            style="height: 82%;margin-top: 4%;"
            [ngStyle]="{
            'background-color': element.status ? '#009EB9' : 'white',
            'color': element.status ? 'white' : '#009EB9',
            'border': element.status ? 'none' : '3px solid #009EB9'
          }"
            (click)="confirmComplete(element)"
        >
        
        <span *ngIf="element.status === false">
          Complete
        </span>
        <span *ngIf="element.status === true">
          Completed
        </span>
        </button>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumnsClients; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumnsClients"
          
          
        ></tr>
      </table>
    </div>
  </div>

  <!--paginator-->
  <div class="paginatorContainer">
    <mat-paginator
      [pageSizeOptions]="[25]"
      showFirstLastButtons
      class="paginator-conditions"
      (click)="container.scrollTop = 0"
    >
    </mat-paginator>
  </div>

  <!--Spinner-->
  <div class="spinner-container" *ngIf="showSpinner">
    <div class="new-spinner">
      <div class="outer-ring"></div>
      <div class="middle-ring"></div>
      <div class="inner-ring"></div>
    </div>
  </div>
</div>
