import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditUserPopupDialogComponent } from './edit-user-popup-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CCMCSharedModule } from '../../shared.module';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [EditUserPopupDialogComponent],
  imports: [
    AppMaterialModule,
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatSlideToggleModule
  ],
  entryComponents: [EditUserPopupDialogComponent]
})
export class EditUserPopupDialogModule {}
