import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GeneralLedgerAccountingService } from '../../services/general-ledger-accounting.service';

@Component({
  selector: 'app-add-remove-columns-dialog',
  templateUrl: './add-remove-columns-dialog.component.html',
  styleUrls: ['./add-remove-columns-dialog.component.scss']
})
export class AddRemoveColumnsDialogComponent implements OnInit {
  dataSource: any;
  displayedColumns = ['columnID', 'columnName', 'active'];
  transactionColumns: any;
  unsubscribe: Subject<any> = new Subject();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  glaDocumentSubscription: Subscription;
  targetLayout: any;
  @ViewChild('title', { static: true }) title: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<AddRemoveColumnsDialogComponent>,
    private glaService: GeneralLedgerAccountingService,
    @Inject(MAT_DIALOG_DATA) public selectedSet: any
  ) {}

  ngOnInit() {
    this.title.nativeElement.focus();
    this.getData();
  }

  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getData() {
    this.glaDocumentSubscription = this.glaService.glaDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(glaDocument => {
        if (glaDocument) {
          this.targetLayout = glaDocument.coreLayout;
          this.calculateActiveColumns(this.selectedSet.transactions[0].columns);
        }
      });
  }

  calculateActiveColumns(columns: any) {
    this.transactionColumns = [];
    for (let targetLayoutColumn of this.targetLayout) {
      let tempColumn = {
        columnID: targetLayoutColumn.columnID,
        columnName: targetLayoutColumn.columnName,
        active: false
      };
      const columnIndex = columns.findIndex(
        (col: any) => col.columnID == targetLayoutColumn.columnID
      );
      if (columnIndex > -1) {
        tempColumn.active = true;
      }
      this.transactionColumns.push(tempColumn);
    }
    this.dataSource = new MatTableDataSource(this.transactionColumns);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.sort.active = 'columnID';
    this.sort.direction = 'asc';
    console.log(this.transactionColumns);
  }

  updateColumns() {
    for (let transaction of this.selectedSet.transactions) {
      console.log(
        'Current Transaction To Update',
        JSON.parse(JSON.stringify(transaction))
      );
      // Init temp column array
      const tempColumnArray = [];
      // Loop through target layout
      for (let transactionColumnItem of this.transactionColumns) {
        if (transactionColumnItem.active) {
          // Find column index of columnID
          const columnIndex = transaction.columns.findIndex(
            (col: any) => col.columnID == transactionColumnItem.columnID
          );
          if (columnIndex > -1) {
            // Create new column using old column data
            const tempColumn = {
              columnID: transaction.columns[columnIndex].columnID,
              vbScript: transaction.columns[columnIndex].vbScript,
              edited: false
            };
            // Push to temp column array
            tempColumnArray.push(tempColumn);
          } else {
            // Create new column using target layout columnID
            const tempColumn = {
              columnID: transactionColumnItem.columnID,
              vbScript: '',
              edited: true
            };
            // Transaction has been edited here
            transaction.edited = true;
            // Push to temp column array
            tempColumnArray.push(tempColumn);
          }
        }
      }
      // If lengths don't match we know the transaction has been edited
      if (transaction.columns.length !== tempColumnArray.length) {
        transaction.edited = true;
      }
      console.log(tempColumnArray);
      // Update selected transaction columns object
      transaction.columns = tempColumnArray;
    }
    this.dialogRef.close(true);
  }
}
