<h1 style="position: relative; bottom: 10px; text-align: center; ">Select A Profile Image</h1>

<div class="dialog-container">
    <div class="container-border">
        <div class="avatar-row-one">
            <div>
                <img (click)="selectAvatar(avatarOptions[0])" class="user__profile--container--image" [ngClass]="{ 'user__profile--container--current-avatar': selectedAvatar === avatarOptions[0] ? true : false }" src='assets/images/avatars/{{avatarOptions[0]}}.png' />
            </div>
            <div>
                <img (click)="selectAvatar(avatarOptions[1])" class="user__profile--container--image" [ngClass]="{ 'user__profile--container--current-avatar': selectedAvatar === avatarOptions[1] ? true : false }" src='assets/images/avatars/{{avatarOptions[1]}}.png' />
            </div>
            <div>
                <img (click)="selectAvatar(avatarOptions[2])" class="user__profile--container--image" [ngClass]="{ 'user__profile--container--current-avatar': selectedAvatar === avatarOptions[2] ? true : false }" src='assets/images/avatars/{{avatarOptions[2]}}.png' />
            </div>
            <div>
                <img (click)="selectAvatar(avatarOptions[3])" class="user__profile--container--image" [ngClass]="{ 'user__profile--container--current-avatar': selectedAvatar === avatarOptions[3] ? true : false }" src='assets/images/avatars/{{avatarOptions[3]}}.png' />
            </div>
        </div>
        <div class="avatar-row-two">
            <div>
                <img (click)="selectAvatar(avatarOptions[4])" class="user__profile--container--image" [ngClass]="{ 'user__profile--container--current-avatar': selectedAvatar === avatarOptions[4] ? true : false }" src='assets/images/avatars/{{avatarOptions[4]}}.png' />
            </div>
            <div>
                <img (click)="selectAvatar(avatarOptions[5])" class="user__profile--container--image" [ngClass]="{ 'user__profile--container--current-avatar': selectedAvatar === avatarOptions[5] ? true : false }" src='assets/images/avatars/{{avatarOptions[5]}}.png' />
            </div>
            <div>
                <img (click)="selectAvatar(avatarOptions[6])" class="user__profile--container--image" [ngClass]="{ 'user__profile--container--current-avatar': selectedAvatar === avatarOptions[6] ? true : false }" src='assets/images/avatars/{{avatarOptions[6]}}.png' />
            </div>
            <div>
                <img (click)="selectAvatar(avatarOptions[7])" class="user__profile--container--image" [ngClass]="{ 'user__profile--container--current-avatar': selectedAvatar === avatarOptions[7] ? true : false }" src='assets/images/avatars/{{avatarOptions[7]}}.png' />
            </div>
        </div>

    </div>
    <div class="bottom-button-container" id="bottom-button-container">
        <!--Cancel-Button"-->
        <button class="cancel-button" mat-stroked-button (click)="close()" id="cancel-button" style="height: 40px;">Close</button>

        <!--Send Button-->
        <button class="save-button" color="accent" [disabled]="!canSave" (click)="save()" mat-raised-button matTooltipClass="tooltip-color" style="height: 40px;">
          <span class="button-text">Save&nbsp;</span>
          <mat-icon class="send-button-icon">save</mat-icon>
        </button>
    </div>
</div>