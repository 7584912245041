import { Component, ElementRef, HostBinding, Inject, OnDestroy, Renderer2, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { Subscription } from 'rxjs';

import { CCMCConfigService } from '../../@ccmc/services/config.service';
import { navigation } from '../../app/navigation/navigation';
import { Navigation } from '../../@ccmc/models/navigation.model';

@Component({
    selector: 'ccmc-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CCMCMainComponent implements OnDestroy {
    onConfigChanged: Subscription;
    ccmcSettings: any;
    navigation: Navigation[];
    @HostBinding('attr.ccmc-layout-mode') layoutMode: any;

    constructor(
        private _renderer: Renderer2,
        private _elementRef: ElementRef,
        private ccmcConfig: CCMCConfigService,
        private platform: Platform,
        @Inject(DOCUMENT) private document: any
    ) {
        // Subscribe to config changes
        this.onConfigChanged = this.ccmcConfig.onConfigChanged.subscribe((newSettings) => {
            // Set new settings
            this.ccmcSettings = newSettings;
            // Set new layout mode
            this.layoutMode = this.ccmcSettings.layout.mode;
        });
        // Check mobile platform
        if (this.platform.ANDROID || this.platform.IOS) {
            this.document.body.className += ' is-mobile';
        }
        // Set navigation instance
        this.navigation = navigation;
    }

    ngOnDestroy() {
        // if onConfigChanged still exists, unsubscribe
        if (this.onConfigChanged) {
            this.onConfigChanged.unsubscribe();
        }
    }

    addClass(className: string) {
        this._renderer.addClass(this._elementRef.nativeElement, className);
    }

    removeClass(className: string) {
        this._renderer.removeClass(this._elementRef.nativeElement, className);
    }
}
