<div class="resend-doc-connector-dialog">
  <div mat-dialog-content class="resend-doc-connector-dialog-message">
    <p>Resend the selected failed files for loan {{ loanNumber }}?</p>
  </div>
  <div
    mat-dialog-actions
    class="pt-30"
    class="resend-doc-connector-dialogBtn-Container"
  >
    <button
      class="cancelBtn mat-focus-indicator cancelBtn mat-stroked-button mat-button-base"
      mat-stroked-button
      (click)="
        dialogRef.close({
          statusFlag: false,
          statusMessage: 'Canceled Request'
        })
      "
    >
      Cancel
    </button>
    <button
      mat-raised-button
      class="mat-focus-indicator mat-accent mr-16 mat-raised-button mat-button-base Btn-submit"
      (click)="
        dialogRef.close({
          statusFlag: true,
          resetBeforeSend,
          statusMessage: 'Process Request'
        })
      "
    >
      Confirm
    </button>
  </div>
</div>