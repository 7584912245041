<div class="logging-container">
    <div class="filter-Logging">
      <mat-form-field style="position: sticky; top: 0; margin-bottom: 5px">
        <input matInput placeholder="Filter Search" #filterSearch />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          [matDatepicker]="endDatePicker"
          placeholder="Select Date"
          [formControl]="endDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="endDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
      <!-- <mat-form-field>
              <input matInput [max]="maxDate" [matDatepicker]="endDatePicker" placeholder="End date" [formControl]="endDate" />
              <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>
          </mat-form-field> -->
      <mat-form-field>
        <mat-label>Environment</mat-label>
        <mat-select [(value)]="loggingEnvironment">
          <mat-option value="prod" (click)="setEnvironment('prod')"
            >Prod</mat-option
          >
          <mat-option value="test" (click)="setEnvironment('test')"
            >Test</mat-option
          >
          <mat-option value="dev" (click)="setEnvironment('dev')">Dev</mat-option>
        </mat-select>
      </mat-form-field>
      <div>
        <button
          mat-raised-button
          matTooltip="Retrieve and displays logs"
          matTooltipClass="toolTipStyle"
          color="accent"
          (click)="getLogs()"
          class="fetch-button"
        >
          Fetch Logs
        </button>
      </div>
    </div>
    <div class="table-container">
      <table
        mat-table
        class="table-TableContainer"
        #table
        [dataSource]="dataSource"
        CCMCPerfectScrollbar
        matSort
      >
        <!-- Date - Time Column -->
        <ng-container matColumnDef="timestamp">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            style="min-width: 150px; max-width: 150px"
          >
            Date - Time
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            style="min-width: 150px; max-width: 150px"
          >
            {{ element.timestamp | unixToDate }}
          </td>
        </ng-container>
  
        <!-- Level -->
        <ng-container matColumnDef="level">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            style="min-width: 100px; max-width: 100px"
          >
            Level
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            style="min-width: 100px; max-width: 100px"
          >
            {{ element.level }}
          </td>
        </ng-container>
  
        <!-- User -->
        <ng-container matColumnDef="user">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            style="min-width: 175px; max-width: 175px"
          >
            User
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            style="min-width: 175px; max-width: 175px"
          >
            {{ element.user }}
          </td>
        </ng-container>
  
        <!-- User -->
        <ng-container matColumnDef="loanNumber">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            style="min-width: 125px; max-width: 125px"
          >
            Loan Number
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            style="min-width: 125px; max-width: 125px"
          >
            {{ element.loanNumber }}
          </td>
        </ng-container>
  
        <!-- Message -->
        <ng-container matColumnDef="message">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Message</th>
          <td mat-cell *matCellDef="let element">
            <div class="truncate">
              {{ element.message }}
            </div>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="onSelect(row)"
          [ngClass]="{ 'accent-50-bg': row == currentLog }"
        ></tr>
      </table>
    </div>
    <div class="paginatorContainer">
      <mat-paginator
        [pageSizeOptions]="[100]"
        showFirstLastButtons
        class="paginator-conditions"
      >
      </mat-paginator>
    </div>
    <!--Logging Display Area-->
    <div class="tabBar">
      <mat-tab-group>
        <mat-tab label="Log">
          <div class="exception-message-container" *ngIf="currentLog">
            <!--Exceptions-->
            <div *ngIf="currentException" class="exception-container">
              <h3 class="exception-title">Exception</h3>
              <mat-form-field
                style="width: 100%; height: 33%"
                id="currentException"
              >
                <textarea
                  matInput
                  [(ngModel)]="currentException"
                  readonly
                  cdkTextareaAutosize
                  class="exception-textarea"
                ></textarea>
              </mat-form-field>
            </div>
  
            <!--Messages-->
            <div class="message-container">
              <h3 class="message-title">Message Test</h3>
              <mat-form-field
                id="currentMessage"
                style="width: 100%; height: 66%"
              >
                <textarea
                  matInput
                  [(ngModel)]="currentMessage"
                  readonly
                  cdkTextareaAutosize
                  class="message-textarea"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  
  <!--Spinner-->
  <div class="spinner-container" *ngIf="showSpinner">
    <div class="new-spinner">
      <div class="outer-ring"></div>
      <div class="middle-ring"></div>
      <div class="inner-ring"></div>
    </div>
  </div>
  