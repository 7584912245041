import { NgModule } from '@angular/core';
import { TargetResponseDialogComponent } from './target-response-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [TargetResponseDialogComponent],
  imports: [MatDialogModule, AppMaterialModule, MatButtonModule, CommonModule],
  entryComponents: [TargetResponseDialogComponent]
})
export class TargetResponseDialogModule {}
