<div class="ebm-report-container">
  <div class="filter-Logging">
    <mat-form-field style="position: sticky; top: 0; margin-bottom: 5px">
      <input matInput placeholder="Filter Search" #filterSearch />
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        [matDatepicker]="startDatePicker"
        placeholder="Select Date"
        [formControl]="startDate"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="startDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        [matDatepicker]="endDatePicker"
        placeholder="Select Date"
        [formControl]="endDate"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="endDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>

    <div>
      <button
        mat-raised-button
        matTooltip="Retrieve and displays logs"
        matTooltipClass="toolTipStyle"
        color="accent"
        (click)="getLogs()"
        class="fetch-button"
      >
        Fetch Logs
      </button>
    </div>
  </div>
  <div class="table-container">
    <table
      mat-table
      class="table-TableContainer"
      #table
      [dataSource]="dataSource"
      CCMCPerfectScrollbar
      matSort
    >
      <!-- Date - Time Column -->
      <ng-container matColumnDef="dateTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date - Time</th>
        <td mat-cell *matCellDef="let element">
          {{ element.dateTime | timezoneConversion }}
        </td>
      </ng-container>

      <!-- AssetID -->
      <ng-container matColumnDef="assetID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>AssetID</th>
        <td mat-cell *matCellDef="let element">
          {{ element.assetID }}
        </td>
      </ng-container>

      <!-- Loan Number -->
      <ng-container matColumnDef="loanNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loan Number</th>
        <td mat-cell *matCellDef="let element">
          {{ element.loanNumber }}
        </td>
      </ng-container>

      <!-- Borrower -->
      <ng-container matColumnDef="borrower">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Borrower</th>
        <td mat-cell *matCellDef="let element">
          {{ element.borrower }}
        </td>
      </ng-container>

      <!-- Status -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.status">Booked</div>
          <div *ngIf="!element.status">Failed</div>
        </td>
      </ng-container>

      <!-- Message -->
      <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Message</th>
        <td mat-cell *matCellDef="let element">
          <div class="truncate">
            {{ element.message }}
          </div>
        </td>
      </ng-container>

      <!-- Exception -->
      <ng-container matColumnDef="exception">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Exception</th>
        <td mat-cell *matCellDef="let element">
          <div class="truncate">
            {{ element.exception }}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="onSelect(row)"
        [ngClass]="{ 'accent-50-bg': row == currentLog }"
      ></tr>
    </table>
  </div>
  <div class="paginatorContainer">
    <mat-paginator
      [pageSizeOptions]="[100]"
      showFirstLastButtons
      class="paginator-conditions"
    >
    </mat-paginator>
  </div>
  <!--Logging Display Area-->
  <div class="tabBar">
    <mat-tab-group>
      <mat-tab label="Log">
        <div class="exception-message-container" *ngIf="currentLog">
          <!--Details-->
          <div class="message-details-container">
            <h3 class="message-title">Details</h3>
            <div class="message-details-fields">
              <mat-form-field>
                <input
                  class="input-length"
                  matInput
                  placeholder="Loan Number"
                  [(ngModel)]="currentLoanNumber"
                  readonly
                />
              </mat-form-field>
              <mat-form-field>
                <input
                  class="input-length"
                  matInput
                  placeholder="Borrower Name"
                  [(ngModel)]="currentBorrower"
                  readonly
                />
              </mat-form-field>
            </div>
          </div>
          <!--Messages-->
          <div class="message-container">
            <h3 class="message-title">Message</h3>
            <mat-form-field
              style="width: 100%; height: 66%"
              id="currentMessage"
            >
              <textarea
                matInput
                [(ngModel)]="currentMessage"
                readonly
                cdkTextareaAutosize
                class="message-textarea"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<!--Spinner-->
<div class="spinner-container" *ngIf="showSpinner">
  <div class="new-spinner">
    <div class="outer-ring"></div>
    <div class="middle-ring"></div>
    <div class="inner-ring"></div>
  </div>
</div>
