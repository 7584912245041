import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AvatarService } from 'src/@ccmc/services/avatar.service';
import { MatDialog } from '@angular/material/dialog';
import { EditAvatarDialogComponent } from './edit-avatar-dialog/edit-avatar-dialog.component';
import { CCMCChangePasswordComponent } from '../change-password/change-password.component';
import { NgZone } from '@angular/core';
import { SalesforceService } from 'src/@ccmc/services/salesforce.service';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { Auth } from 'aws-amplify';
import { AmplifyQrCodeDialogComponent } from 'src/@ccmc/components/amplify-qr-code-dialog/amplify-qr-code-dialog.component';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { Router } from '@angular/router';
@Component({
  selector: 'ccmc-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class CCMCUserProfileComponent implements OnInit, OnDestroy {
  unsubscribe: Subject<any> = new Subject();
  userSubscription: Subscription;
  user: any;
  company: any;
  mfaLoginSub: Subscription;
  url: any = '';
  isNXTsoftDev = false;
  isNXTsoftOperations = false;
  isNXTsoftSales = false;
  isClientAdmin = false;
  isSuperAdmin = false;
  isLoanSystemsAdmin = false;
  isLoanServicingAdmin = false;
  avatar: any;
  avatarSubscription: Subscription;
  mfaToggle = false;
  salesforceaccountID: any;
  variableKeys: string[];
  variables: any;
  private _profileData = new BehaviorSubject<any>(null);
  name: any;
  oldVariables: any;
  hideFields: any[];
  editedFlag: boolean;
  environmentType = 'test';
  activeCore: any;
  newVarKeys: any;
  userIcon: any;
  constructor(
    private amplifyService: AmplifyService,
    private avatarService: AvatarService,
    private dialog: MatDialog,
    private zone: NgZone,
    private salesforceService: SalesforceService,
    private adminApiService: AdminApiService,
    private spinnerService: SpinnerService,
    private snackbarService: SnackbarService,
    private fieldEditedService: FieldEditedService,
    private assetService: AssetService,
    private ccmcApiService: CcmcApiService,
    private snackBarService: SnackbarService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.isNXTsoftDev = this.amplifyService.isNXTsoftDev;
    this.isNXTsoftOperations = this.amplifyService.isNXTsoftOperations;
    this.isNXTsoftSales = this.amplifyService.isNXTsoftSales;
    this.isClientAdmin = this.amplifyService.isAdmin;
    this.isSuperAdmin = this.amplifyService.isSuperAdmin;
    this.isLoanServicingAdmin = this.amplifyService.isLoanServicingAdmin;
    this.isLoanSystemsAdmin = this.amplifyService.isLoanSystemsAdmin;
    this.user = {
      firstName: '',
      lastName: '',
      email: '',
      password: ''
    };
    this.company = {
      FIName: '',
      accountID: '',
      billingAddress: '',
      cityState: '',
      federalInsurer: '',
      fiClassification: '',
      fiStatus: '',
      industry: '',
      phoneNumber: '',
      totalAssets: '',
      website: '',
      zipCode: ''
    };
    this.getAvatar();
    this.getUser();
    this.getTargetConfigurationTable();
    this.determineUserIcon();
    this.activeCore = this.assetService.getSelectedCore();
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.avatarSubscription) {
      this.avatarSubscription.unsubscribe();
    }
  }

  onSelectFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      console.log(event.target.files[0]);

      reader.onload = event => {
        // called once readAsDataURL is completed
        event.target;
      };
    }
  }
  public delete() {
    this.url = null;
  }

  async getUser() {
    this.salesforceaccountID = '';
    this.name = await Auth.currentAuthenticatedUser()
      .then()
      .catch(e => {
        console.log(e);
      });
    console.log(this.name);
    this.mfaLoginSub = this.fieldEditedService.activeMFA
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(mfa => {
        console.log(mfa);
        this.mfaToggle = mfa;
      });
    this.salesforceaccountID =
      this.name.signInUserSession.idToken.payload['custom:AccountID'];

    this.salesforceService.setAccountID(this.salesforceaccountID);
    this.salesforceService.getAccountData().subscribe(result => {
      console.log(result);
    });
    this.salesforceService.getContactData().subscribe(result => {
      const parsedResult = JSON.parse(result.StatusMessage);
      console.log(parsedResult);
      console.log(parsedResult.length);
      console.log(this.name.username);
      for (let i = 0; i < parsedResult.length; i++) {
        if (parsedResult[i].contactEmail == this.name.username) {
          this.user.firstName = parsedResult[i].contactFirstName;
          this.user.lastName = parsedResult[i].contactLastName;
        }
      }
      this.user.username = this.name.username;
      console.log(this.user.firstName);
      console.log(this.user.lastName);
      console.log(this.user.username);
    });

    this.salesforceService.getAccountData().subscribe(result => {
      const parsedResult = JSON.parse(result.StatusMessage);
      console.log(parsedResult);
      this.company.FIName = parsedResult.FIName;
      this.company.fiStatus = parsedResult.fiStatus;
      this.company.phoneNumber = parsedResult.phoneNumber;
      this.company.fiClassification = parsedResult.industry;
      this.company.federalInsurer = parsedResult.federalInsurer;
      this.company.website = parsedResult.website;
      this.company.totalAssets = parsedResult.totalAssets;
      this.company.billingAddress = parsedResult.billingAddress;
      this.company.cityState = parsedResult.cityState;
      this.company.zipCode = parsedResult.zipCode;
    });
  }

  getAvatar() {
    this.avatarSubscription = this.avatarService.avatar.subscribe(avatar => {
      this.avatar = avatar;
    });
  }
  // profileEdited() {}
  editAvatar() {
    console.log('change avatar popup');
    this.dialog.open(EditAvatarDialogComponent);
  }

  openChangePassword() {
    const dialogRef1 = this.dialog.open(CCMCChangePasswordComponent, {
      panelClass: 'change-password__panel-dialog',
      disableClose: true
    });
  }

  async onClickSwitch() {
    let user = await Auth.currentAuthenticatedUser()
      .then()
      .catch(e => {
        console.log(e);
      });
    if (this.mfaToggle) {
      console.log(user);
      const totpQRCodeDialogRef = this.dialog.open(
        AmplifyQrCodeDialogComponent,
        {
          disableClose: true,
          panelClass: 'amplify__dialog',
          data: user
        }
      );
      totpQRCodeDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.fieldEditedService.activeMFA.next(true);
          this.snackbarService.openSnackBar(
            'Successfully set up Multi-Factor Authentication',
            'Okay'
          );
        } else {
          this.fieldEditedService.activeMFA.next(false);
          this.mfaToggle = false;
        }
      });
    } else {
      if (!user.Session) {
        Auth.setPreferredMFA(user, 'NOMFA');
        this.snackbarService.openSnackBar(
          'Successfully removed Multi-Factor Authentication',
          'Okay'
        );
      }
    }
  }

  getTargetConfigurationTable() {
    if (this.assetService.getSelectedCore() === 'premier') {
      this.adminApiService
        .getDynamoDBItem(
          'fiserv_premier_users',
          this.amplifyService.getCognitoId(),
          'cognitoID'
        )
        .subscribe(result => {
          console.log(result);
          if (result) {
            const parsedResult = JSON.parse(JSON.stringify(result));
            this.variables = parsedResult;
            this.oldVariables = JSON.parse(JSON.stringify(this.variables));
            this.variableKeys = Object.keys(this.variables);
            console.log(this.variableKeys);
            for(let variable of this.variableKeys){
              if(variable === 'assetID') {
                const index = this.variableKeys.indexOf('assetID');
                if (index > -1) {
                  this.variableKeys.splice(index, 1);
                }
              }
            }
            if(this.variableKeys.indexOf('username') > 0) { 
              this.variableKeys.splice(this.variableKeys.indexOf('username'), 1);
               this.variableKeys.unshift('username');
             }
            this.hideFields = [];
            for (let variable of this.variableKeys) {
              this.hideFields.push(true);
            }
          } else {
            // if no user found pull generic for schema
            this.adminApiService
              .getDynamoDBItem('fiserv_premier_users', 'generic', 'cognitoID')
              .subscribe(result => {
                console.log(result);
                if (result) {
                  const parsedResult = JSON.parse(JSON.stringify(result));
                  this.variables = parsedResult;
                  this.variables.cognitoID = this.amplifyService.getCognitoId();
                  this.oldVariables = JSON.parse(
                    JSON.stringify(this.variables)
                  );
                  this.variableKeys = Object.keys(this.variables);
                  console.log(this.variableKeys);
                  if(this.variableKeys.indexOf('username') > 0) { 
                    this.variableKeys.splice(this.variableKeys.indexOf('username'), 1);
                    this.variableKeys.unshift('username');
                  }  
                }
                this.hideFields = [];
                for (let variable of this.variableKeys) {
                  this.hideFields.push(true);
                }
              });
          }
        });
    } else if (this.assetService.getSelectedCore() === 'spectrum') {
      this.adminApiService
      .getDynamoDBItem(
        'fiserv_spectrum_users',
        this.amplifyService.getCognitoId(),
        'cognitoID'
      )
      .subscribe(result => {
        console.log(result);
        if (result) {
          const parsedResult = JSON.parse(JSON.stringify(result));
          this.variables = parsedResult;
          this.oldVariables = JSON.parse(JSON.stringify(this.variables));
          this.variableKeys = Object.keys(this.variables);
          console.log(this.variableKeys);
          for(let variable of this.variableKeys){
            if(variable === 'assetID') {
              const index = this.variableKeys.indexOf('assetID');
              if (index > -1) {
                this.variableKeys.splice(index, 1);
              }
            }
          }
          // if(this.variableKeys.indexOf('username') > 0) { 
          //   this.variableKeys.splice(this.variableKeys.indexOf('username'), 1);
          //    this.variableKeys.unshift('username');
          //  }
          this.hideFields = [];
          for (let variable of this.variableKeys) {
            this.hideFields.push(true);
          }
        } else {
          // if no user found pull generic for schema
          this.adminApiService
            .getDynamoDBItem('fiserv_spectrum_users', 'generic', 'cognitoID')
            .subscribe(result => {
              console.log(result);
              if (result) {
                const parsedResult = JSON.parse(JSON.stringify(result));
                this.variables = parsedResult;
                this.variables.cognitoID = this.amplifyService.getCognitoId();
                this.oldVariables = JSON.parse(
                  JSON.stringify(this.variables)
                );
                this.variableKeys = Object.keys(this.variables);
                console.log(this.variableKeys);
                // if(this.variableKeys.indexOf('username') > 0) { 
                //   this.variableKeys.splice(this.variableKeys.indexOf('username'), 1);
                //   this.variableKeys.unshift('username');
                // }  
              }
              this.hideFields = [];
              for (let variable of this.variableKeys) {
                this.hideFields.push(true);
              }
            });
        }
      });
    } else if (this.assetService.getSelectedCore() === 'ibs') {
      this.adminApiService
      .getDynamoDBItem(
        'fis_ibs_users',
        this.amplifyService.getCognitoId(),
        'cognitoID'
      )
      .subscribe(result => {
        console.log(result);
        if (result) {
          const parsedResult = JSON.parse(JSON.stringify(result));
          this.variables = parsedResult;
          this.oldVariables = JSON.parse(JSON.stringify(this.variables));
          this.variableKeys = Object.keys(this.variables);
          if(this.variableKeys.indexOf('username') > 0) { 
            this.variableKeys.splice(this.variableKeys.indexOf('username'), 1);
             this.variableKeys.unshift('username');
           }
          this.hideFields = [];
          for (let variable of this.variableKeys) {
            this.hideFields.push(true);
          }
        } else {
          // if no user found pull generic for schema
          this.adminApiService
            .getDynamoDBItem('fis_ibs_users', 'generic', 'cognitoID')
            .subscribe(result => {
              console.log(result);
              if (result) {
                const parsedResult = JSON.parse(JSON.stringify(result));
                this.variables = parsedResult;
                this.variables.cognitoID = this.amplifyService.getCognitoId();
                this.oldVariables = JSON.parse(
                  JSON.stringify(this.variables)
                );
                this.variableKeys = Object.keys(this.variables);
                if(this.variableKeys.indexOf('username') > 0) { 
                  this.variableKeys.splice(this.variableKeys.indexOf('username'), 1);
                   this.variableKeys.unshift('username');
                 }
              }
              this.hideFields = [];
              for (let variable of this.variableKeys) {
                this.hideFields.push(true);
              }
            });
          }
      });
    } else if (this.assetService.getSelectedCore() === 'miser') {
      this.adminApiService
      .getDynamoDBItem(
        'fis_miser_users',
        this.amplifyService.getCognitoId(),
        'cognitoID'
      )
      .subscribe(result => {
        console.log(result);
        if (result) {
          const parsedResult = JSON.parse(JSON.stringify(result));
          this.variables = parsedResult;
          this.oldVariables = JSON.parse(JSON.stringify(this.variables));
          this.variableKeys = Object.keys(this.variables);
          for(let variable of this.variableKeys){
            if(variable === 'assetID') {
              const index = this.variableKeys.indexOf('assetID');
              if (index > -1) {
                this.variableKeys.splice(index, 1);
              }
            }
          }
          this.hideFields = [];
          for (let variable of this.variableKeys) {
            this.hideFields.push(true);
          }
        } else {
          // if no user found pull generic for schema
          this.adminApiService
            .getDynamoDBItem('fis_miser_users', 'generic', 'cognitoID')
            .subscribe(result => {
              console.log(result);
              if (result) {
                const parsedResult = JSON.parse(JSON.stringify(result));
                this.variables = parsedResult;
                this.variables.cognitoID = this.amplifyService.getCognitoId();
                this.oldVariables = JSON.parse(
                  JSON.stringify(this.variables)
                );
                this.variableKeys = Object.keys(this.variables);
                }
              for(let variable of this.variableKeys){
                if(variable === 'assetID') {
                  const index = this.variableKeys.indexOf('assetID');
                    if (index > -1) {
                    this.variableKeys.splice(index, 1);
                  }
                }
              }
              this.hideFields = [];
              for (let variable of this.variableKeys) {
                this.hideFields.push(true);
              }
            });
        }
      });
    }
  }

  fieldEdited() {
    this.editedFlag = true;
    if (this.isEquivalent(this.variables, this.oldVariables)) {
      console.log('is equiv');
      this.editedFlag = false;
    }
  }
  
  isEquivalent(a: any, b: any) {
    console.log(a);
    console.log(b);
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      console.log();
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];
      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
        return false;
      }
    }
    return true;
  }

  updateVariables() {
    if (this.assetService.getSelectedCore() === 'premier') {
      this.ccmcApiService
        .createDynamoDB(
          this.environmentType,
          'fiserv_premier_users',
          this.variables
        )
        .subscribe(result => {
          if (result) {
            this.snackBarService.openSnackBar(
              'Updated Target Credentials',
              'Okay'
            );
            this.router
              .navigateByUrl('/RefrshComponent', {
                skipLocationChange: true
              })
              .then(() => this.router.navigate(['/user-profile']));
          }
        });
    } else if (this.assetService.getSelectedCore() === 'spectrum') {
      this.ccmcApiService
        .createDynamoDB(
          this.environmentType,
          'fiserv_spectrum_users',
          this.variables
        )
        .subscribe(result => {
          if (result) {
            this.snackBarService.openSnackBar(
              'Updated Target Credentials',
              'Okay'
            );
            this.router
              .navigateByUrl('/RefrshComponent', {
                skipLocationChange: true
              })
              .then(() => this.router.navigate(['/user-profile']));
          }
        });
    } else if (this.assetService.getSelectedCore() === 'ibs') {
      this.ccmcApiService
        .createDynamoDB(
          this.environmentType,
          'fis_ibs_users',
          this.variables
        )
        .subscribe(result => {
          if (result) {
            this.snackBarService.openSnackBar(
              'Updated Target Credentials',
              'Okay'
            );
            this.router
              .navigateByUrl('/RefrshComponent', {
                skipLocationChange: true
              })
              .then(() => this.router.navigate(['/user-profile']));
          }
        });

    } else if (this.assetService.getSelectedCore() === 'miser') {
      this.ccmcApiService
      .createDynamoDB(
        this.environmentType,
        'fis_miser_users',
        this.variables
      )
      .subscribe(result => {
        if (result) {
          this.snackBarService.openSnackBar(
            'Updated Target Credentials',
            'Okay'
          );
          this.router
            .navigateByUrl('/RefrshComponent', {
              skipLocationChange: true
            })
            .then(() => this.router.navigate(['/user-profile']));
        }
      });
    }
  }

  determineUserIcon() {
    if (this.isSuperAdmin || this.isNXTsoftDev) {
      this.userIcon = 'rocket';
    } else if (this.isLoanServicingAdmin) {
      this.userIcon = 'pen';
    } else if (this.isLoanSystemsAdmin) {
      this.userIcon = 'cog';
    } else if (this.isNXTsoftOperations || this.isNXTsoftSales) {
      this.userIcon = 'user';
    } else if (this.isClientAdmin) {
      this.userIcon = 'key';
    }
  }
}
