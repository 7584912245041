import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { passwordMatchValidator } from './validate-passwords.validators';

@Directive({
  selector: '[ccmcPasswordMatcher]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PasswordMatcherDirective,
      multi: true
    }
  ]
})
export class PasswordMatcherDirective implements Validators {
  validate(control: AbstractControl): ValidationErrors {
    return passwordMatchValidator(control)!;
  }
}
