import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GeneralLedgerAccountingService } from '../../services/general-ledger-accounting.service';
import { SnackbarService } from '../../services/snackbar.service';

@Component({
  selector: 'app-create-set-dialog',
  templateUrl: './create-set-dialog.component.html',
  styleUrls: ['./create-set-dialog.component.scss']
})
export class CreateSetDialogComponent implements OnInit {
  newSet: any;
  setsSub: Subscription;
  sets: any;
  unsubscribe: Subject<any> = new Subject();
  constructor(
    private glaService: GeneralLedgerAccountingService,
    private dialogRef: MatDialogRef<CreateSetDialogComponent>,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    this.getCurrentSets();
  }

  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  getCurrentSets() {
    this.setsSub = this.glaService.glaDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(glaDocument => {
        if (glaDocument) {
          this.sets = glaDocument.sets;
          this.getNewSetId(this.sets);
        }
      });
  }

  getNewSetId(sets: any) {
    let newSetId = 1;
    for (let set of sets) {
      if (set.setId >= newSetId) {
        newSetId = set.setId + 1;
      }
    }
    this.newSet = {
      setId: newSetId,
      setName: '',
      transactions: []
    };
    console.log(this.newSet);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  create() {
    if (this.newSet.setName.length > 0) {
      this.dialogRef.close(this.newSet);
    } else {
      this.snackbarService.openSnackBar('Set Name must not be blank', 'Okay');
    }
  }
}
