import { Injectable, NgZone } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, Subscription, Subject } from 'rxjs';
import { tap, map, takeUntil, take, filter, catchError } from 'rxjs/operators';
import { Navigation } from '../models/navigation.model';
import { navigation } from '../../app/navigation/navigation';
import { APPCONSTANTS } from 'src/app/app.constants';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { Router } from '@angular/router';
import { CCMCToolbarComponent } from 'src/app/main/toolbar/toolbar.component';
import { AuthService } from 'src/app/auth/auth.service';
import { SpinnerService } from './spinner.service';
import { CCMCSplashScreenService } from './splash-screen.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { AmplifyService } from './amplify.service';
import { AssetService } from './asset.service';
import { ActiveLosService } from './active-los.service';
import { AdminApiService } from './admin-api.service';
import { GeneralLedgerAccountingService } from './general-ledger-accounting.service';
import { DocumentConnectorService } from './doc-connector.service';
import { CcmcApiService } from './ccmc-api.service';
import { SuccessDialogComponent } from '../components/success-dialog/success-dialog.component';
const { Buffer } = require('buffer');

@Injectable({
  providedIn: 'root'
})
export class ImportService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  private baseUrl: string = `https://api.nxtsoft.com/v1`;
  // private baseUrl: string = `localhost:3000/v1/import`;

  navigation: Navigation;
  importAccountID: any;
  assets: any;
  contactID: any;
  email: any;
  hasAccountSelector = false;
  _userArray: any;
  los: any;
  variableKeys: string[];
  assetID: any;
  variables: {};
  oldVariables: {};
  glaDocument: any;
  dcDocument: any;
  unsubscribe: Subject<any> = new Subject();
  

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private router: Router,
    private adminApiService: AdminApiService,
    private activeLosService: ActiveLosService,
    private ccmcApiService: CcmcApiService,
    private assetService: AssetService,
    private amplifyService: AmplifyService,
    private spinnerService: SpinnerService,
    private glaService: GeneralLedgerAccountingService,
    private splashScreenService: CCMCSplashScreenService,
    private cookieService: CookieService,
    private zone: NgZone,
    private customErrorHandlerService: CustomErrorHandlerService
  ) {
    this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, PUT'
    );
    this.httpOptions.headers.append('Accept', 'application/json');
  }

  customerSearch(params: any, glaDoc: any, dcDoc: any) {
    this.assetID = this.assetService.getSelectedAssetId();
    let config;
    const product = this.assetService.getSelectedProduct();
    console.log(product);
    console.log(this.ccmcApiService.configurations);
    if (product.includes('doc_connector')) {
      config = dcDoc.configurations;
    } else if (product.includes('general_ledger_accounting')) {
      config = glaDoc.configurations;
    } else {
      config = this.ccmcApiService.configurations;
    }
    console.log(config);
    let body = {
      assetID: this.assetID,
      content: {
        coreSpecificFields: {
          financialInstitutionInternetUserID: ''
        },
        customerID: params.customerBase.customerID,
        name: {
          businessName: '',
          firstName: params.customerBase.name.firstName,
          lastName: params.customerBase.name.lastName,
          legalName: '',
          middleName: '',
          prefix: '',
          suffix: ''
        },
        taxID: params.customerBase.taxID
      },
      uuid: '',
      configurations: config
    };
    let bodytoSend = JSON.stringify(body);
    console.log(JSON.parse(bodytoSend));
    console.log(body);
    return this.http
      .post<any[]>(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/customer/import-customer-search`,
        bodytoSend,
        this.httpOptions
      )
      .pipe(
        map(result => {
          console.log(JSON.parse(JSON.stringify(result)));
          this.variables = undefined!;
          return JSON.parse(JSON.stringify(result));
        }),
        catchError(this.customErrorHandlerService.handleError)
      );
  }
  sendUsers(users: any) {
    this.spinnerService.setShowSpinner(true);
    let body = users;
    console.log(body);
    return this.http
      .post<any[]>(`${Buffer.from(environment.environmentURL, "base64").toString()}/ice/import`, body, this.httpOptions)
      .pipe(
        map(result => {
          let parsedResult = JSON.parse(JSON.stringify(result));
          console.log(JSON.parse(JSON.stringify(result)));
          if (parsedResult.statusFlag) {
            if (this.activeLosService.activeLos === 'encompass' || this.activeLosService.activeLos === 'bytepro') {
              this.router.navigate(['loan-lookup']);
            } else {
              this.router.navigate(['file-selector']);
            }
            this.spinnerService.setShowSpinner(false);
            return JSON.parse(JSON.stringify(result)).content;
          } else {
            this.spinnerService.setShowSpinner(false);
            this.dialog.open(ErrorDialogComponent, {
              data: {
                title: 'Error',
                message: parsedResult.statusMessage
              }
            });
          }
        })
      );
  }

  setUserArray(array: any) {
    this._userArray = array;
    this.checkUserArray.next(array);
  }
  getUserArray() {
    return this._userArray;
  }
  public checkUserArray: BehaviorSubject<any> = new BehaviorSubject([]);
}
