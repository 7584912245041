import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { RouterModule, Routes } from '@angular/router';
import { CCMCSharedModule } from 'src/@ccmc/shared.module';
import { LoanLookupComponent } from './loan-lookup.component';
import { MatRadioModule } from '@angular/material/radio';

const routes: Routes = [
  {
    path: 'loan-lookup',
    component: LoanLookupComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [LoanLookupComponent],
  imports: [RouterModule.forChild(routes), CCMCSharedModule, MatRadioModule]
})
export class LoanLookupModule {}
