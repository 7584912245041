import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { CCMCSharedModule } from '../../../@ccmc/shared.module';

import { CCMCQuickPanelComponent } from '../../../app/main/quick-panel/quick-panel.component';

@NgModule({
    declarations: [
        CCMCQuickPanelComponent
    ],
    imports     : [
        RouterModule,

        MatDividerModule,
        MatListModule,
        MatSlideToggleModule,

        CCMCSharedModule,
    ],
    exports: [
        CCMCQuickPanelComponent
    ]
})
export class CCMCQuickPanelModule {
}
