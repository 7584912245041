<h1 style="padding-left: 3%;">Add to Validation</h1>
<mat-stepper [linear]="isLinear" #stepper style="background-color:#F2F2F1">
  <mat-step [stepControl]="firstFormGroup">
    <form style="width: 100%;">
      <ng-template matStepLabel>Group or Validation</ng-template>
        <p style="font-size: 18px">Select from the options below. </p>
        <mat-radio-group style="display: grid;margin-top: 3%;" 
        [(ngModel)]="selectedOption" 
        name="selectedOption">
        <div style="display: grid;
        grid-template-columns: 0.4fr 1fr;">
          <mat-radio-button
            value="Create Validation Group"
            >
             Create Validation Group
            </mat-radio-button>
            <mat-form-field  *ngIf="selectedOption === 'Create Validation Group'" style="width: 41%;height: 82%;">
              <mat-label>New Group</mat-label>
              <input [(ngModel)]="newValidationGroup" matInput name="newGroup" style="height: 30px" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
          </div>
          <div style="display: grid;
          grid-template-columns: 0.6fr 1fr;
          margin-top: 3%;">
            <mat-radio-button
            value="Add Validation to an existing group">
              Add Validation to an existing group
            </mat-radio-button>
            <mat-form-field *ngIf="selectedOption === 'Add Validation to an existing group'" style="margin-top: -1%;width: 45%;">
              <mat-label>Validation Group Name</mat-label>
              <mat-select [(ngModel)]="selectedValidationGroup" [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let group of validationGroups" [value]="group"> {{ group }}</mat-option>
            </mat-select>
            </mat-form-field>
          </div>
        </mat-radio-group>
      <div style="margin-top: 6%;
      justify-content: space-between;
      display: flex;">
        <button mat-stroked-button style="background-color: #F2F2F1;border: 1px solid darkgray;" (click)="closeDialog()">Cancel</button>
        <button mat-raised-button style="background-color: #3fb2c6;color: white" (click)="nextStep(stepper)">Next<mat-icon style="padding-left: 5px;">play_arrow</mat-icon></button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="secondFormGroup" label="FieldID / Value / Display Name">
      <mat-form-field id="selectedValidationGroup" style="width: 25%;margin-top: 2%">
        <mat-label>Validation Group Name</mat-label>
        <mat-select [(ngModel)]="validationGroup" [disabled]="validationGroup">
          <mat-option [value]="validationGroup"> {{ validationGroup}} </mat-option>
      </mat-select>
      </mat-form-field>
      <div class="table-container" name="valuesTable">
        <table
          mat-table
          [dataSource]="dataSource"
          class="mat-elevation-z0"
          style="width: 100%"
        >
          <!-- Value -->
          <ng-container matColumnDef="displayName">
            <th mat-header-cell *matHeaderCellDef style="font-size: 16px">
              Display Name
            </th>
            <td mat-cell *matCellDef="let element">
              <div
                style="
                  display: grid;
                  grid-template-columns: auto 75px;
                  font-size: 16px;
                "
              >
                <input
                  [(ngModel)]="element.displayName"
                  matInput
                  type="search"
                  style="padding: 3px; width: 90%; height: 35px"
                  name="element.displayName"
                  readonly
                />
                <button
                  mat-icon-button
                  matTooltip="Remove validation" 
                  matTooltipClass="toolTipStyle"
                  (click)="removeNewValidation(element)"
                >
                  <mat-icon style="color: red">delete</mat-icon>
                </button>
              </div>
            </td>
            <td mat-footer-cell *matFooterCellDef>
              <div
                style="
                  display: grid;
                  grid-template-columns: auto 75px 150px;
                  column-gap: 5px;
                  height: 35px;
                  font-size: 16px;
                "
              >
                <input
                 [(ngModel)]="tempValidation.displayName"
                  name="tempValidation.displayName"
                  type="text"
                  placeholder="Display Name"
                  matInput
                  type="search"
                  style="
                  padding: 2px;
                  margin-right: 10px;
                  width: 90%;
                  height: 37px;
                  background-color: white;
                  margin-top: -1.5%;
                  "
                />

                <button
                  mat-stroked-button
                  style="background-color: white;border: 1px solid darkgray;"
                  (click)="addNewValidation();"
                ><mat-icon style="margin-left: -10px;font-size: 20px;">add</mat-icon>
                  Add
                </button>
                <button
                  mat-raised-button
                  style="background-color:#454545; color:white"
                  (click)="pasteNewValidations()"
                >
                <mat-icon style="margin-left: -10px;font-size: 20px;">list_alt</mat-icon>
                  Paste Clipboard
                </button>
              </div>
            </td>
          </ng-container>

          <!-- Source -->
          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef style="font-size: 16px">
              Value
            </th>
            <td mat-cell *matCellDef="let element" style="font-size: 16px">
              <input
                [(ngModel)]="element.value"
                name="element.value"
                matInput
                type="search"
                style="padding: 3px; width: auto; height: 30px;"
                readonly
              />
            </td>
            <td mat-footer-cell *matFooterCellDef style="font-size: 16px">
              <input
                matInput
                [(ngModel)]="tempValidation.value"
                placeholder="Value"
                type="search"
                style="
                  padding: 3px;
                  margin-right: 10px;
                  width: 90%;
                  height: 35px;
                  background-color: white;
                "
                name="tempValidation.Value"
              />
            </td>
          </ng-container>
          <!--Category-->
          <ng-container matColumnDef="fieldIDs">
            <th mat-header-cell *matHeaderCellDef style="font-size: 16px">
              FieldID
            </th>
            <td mat-cell *matCellDef="let element">
              <input
                [(ngModel)]="element.fieldIDs"
                name="element.fieldIDs"
                matInput
                type="search"
                style="padding: 3px; width: auto; height: 30px"
                readonly
              />
            </td>
            <td mat-footer-cell *matFooterCellDef style="font-size: 16px">
                <input 
                  type="search" 
                  #fieldIDInput 
                  placeholder="Field ID" 
                  matInput
                  [(ngModel)]="tempFieldIDs" 
                  name="tempFieldIDs"
                  style="
                  padding: 3px;
                  margin-right: 10px;
                  width: 90%;
                  height: 35px;
                  background-color: white;
                "
                  />
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr
            mat-footer-row
            *matFooterRowDef="displayedColumns; sticky: true"
            style="background-color: #eeeeee;"
          ></tr>
        </table>
      </div>
      <div style="
        margin-top: 5%;
        justify-content: space-between;
        display: flex;">
        <button mat-stroked-button style="background-color: #F2F2F1;border: 1px solid darkgray;" (click)="closeDialog()">Cancel</button>
        <button matStepperPrevious mat-stroked-button style="background-color: #454545;color:white; margin-left: 66%;padding-left: 0px;"><mat-icon style="padding-right: 5px;">arrow_left</mat-icon>Previous</button>
        <button mat-raised-button matStepperNext style="background-color: #3fb2c6;color: white;padding-right: 0px;">Next<mat-icon>arrow_right</mat-icon></button>
      </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Done</ng-template>
    <mat-form-field id="selectedValidationGroup" style="width: 25%;margin-top: 2%">
      <mat-label>Validation Group Name</mat-label>
        <mat-select [(ngModel)]="validationGroup" placeholder="Validation Group" [disabled]="validationGroup">
          <mat-option [value]="validationGroup"> {{ validationGroup}} </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="table-container" name="valuesTable">
      <table
        mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z0"
        style="width: 100%"
      >
        <!-- Value -->
        <ng-container matColumnDef="displayName">
          <th mat-header-cell *matHeaderCellDef style="font-size: 16px">
            Display Name
          </th>
          <td mat-cell *matCellDef="let element">
            <div
              style="
                display: grid;
                grid-template-columns: auto 75px;
                font-size: 16px;
              "
            >
              <input
                [(ngModel)]="element.displayName"
                matInput
                type="search"
                style="padding: 3px; width: 90%; height: 35px"
                name="element.displayName"
                readonly
              />
            </div>
          </td>
        </ng-container>

        <!-- Source -->
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef style="font-size: 16px">
            Value
          </th>
          <td mat-cell *matCellDef="let element" style="font-size: 16px">
            <input
              [(ngModel)]="element.value"
              name="element.value"
              matInput
              type="search"
              style="padding: 3px; width: auto; height: 30px;"
              readonly
            />
          </td>
        </ng-container>
        <!--Category-->
        <ng-container matColumnDef="fieldIDs">
          <th mat-header-cell *matHeaderCellDef style="font-size: 16px">
            FieldID
          </th>
          <td mat-cell *matCellDef="let element">
            <input
              [(ngModel)]="element.fieldIDs"
              name="element.fieldIDs"
              matInput
              type="search"
              style="padding: 3px; width: auto; height: 30px"
              readonly
            />
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns2; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns2"></tr>
      </table>
    </div>
    <div style="
    margin-top: 5%;
    justify-content: space-between;
    display: flex;">
      <button mat-stroked-button style="background-color: #F2F2F1;border: 1px solid darkgray;" (click)="closeDialog()">Cancel</button>
        <button matStepperPrevious mat-stroked-button style="background-color: #454545;color:white; margin-left: 66%;padding-left: 0px;"><mat-icon style="padding-right: 5px;">arrow_left</mat-icon>Previous</button>
        <button mat-raised-button 
        id="SubmitButton"
        style="padding-right: 17px;" 
        (click)="submitNewValidations()"
        [disabled]="this.validationGroup === undefined || 
        this.validationGroup === '' || 
        this.validations.length === 0 &&
        this.selectedOption === 'Add Validation to an existing group'">Submit</button>
    </div>
  </mat-step>
</mat-stepper>