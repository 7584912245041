import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeRequestDropdownComponent } from './change-request-dropdown.component';
import { RouterModule, Routes } from '@angular/router';

import { CCMCSharedModule } from '../../../../@ccmc/shared.module';

import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RequestChangeModule } from '../request-change/request-change.module';
import { AuthGuard } from 'src/app/auth/auth.guard';
const routes: Routes = [
  {
    path: 'request-change',
    component: ChangeRequestDropdownComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
  }
];
@NgModule({
  declarations: [ChangeRequestDropdownComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CCMCSharedModule,
    KeyboardShortcutsModule.forRoot(),
    // tslint:disable-next-line: deprecation
    ScrollingModule,
    RequestChangeModule
  ],
  exports: [ChangeRequestDropdownComponent]
})
export class ChangeRequestDropdownModule {}
