import { Injectable } from '@angular/core';

/**
 * The CCMCEdgeService contains all the edge functions
 *
 * @export
 * @class CCMCEdgeService
 */
@Injectable({
  providedIn: 'root'
})
export class CCMCEdgeService {
  /**
   * Edge
   *
   * @memberof CCMCEdgeService
   */
  edge: any;

  /**
   * Result
   *
   * @memberof CCMCEdgeService
   */
  result: any;

  /**
   * Compile engine
   *
   * @memberof CCMCEdgeService
   */
  compileEngine: any;

  /**
   * Creates an instance of CCMCEdgeService.
   * @memberof CCMCEdgeService
   */

  /**
   * Takes in vb code as a string and returns the result
   * When calling this fucntion use edgeService.result to get result of the function
   *
   * @param {*} code
   * @memberof CCMCEdgeService
   */
  async compile(code: any) {
    const CompileEngine = this.edge.func({
      assemblyFile: './dlls/cVBEvalProvider.dll',
      typeName: 'cVBEvalProvider.cVBEvalProvider',
      methodName: 'Eval'
    });

    // uses the method Eval in above dll to evaluate code
    await CompileEngine(code, async (error: any, result: any) => {
      this.result = await result;
      console.log(result);
    });
  }
}
