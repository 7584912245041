import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone
} from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ENTER, COMMA, SPACE } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { SpinnerService } from '../../services/spinner.service';
import { Router } from '@angular/router';
import { startWith, map } from 'rxjs/operators';
import { CcmcApiService } from '../../services/ccmc-api.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-asset-dialog',
  templateUrl: './edit-asset-dialog.component.html',
  styleUrls: ['./edit-asset-dialog.component.scss']
})
export class EditAssetDialogComponent implements OnInit {
  username: any;
  assetsSplit: any;
  unsubscribe: Subject<any> = new Subject();
  assetsArray: any;
  selectable = true;
  removable = true;
  addOnBlur = true;
  canEditAssets = false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  assetsCtrl = new FormControl();
  filteredAssets: Observable<any[]>;
  @ViewChild('assetsInput', { static: false }) assetsInput: ElementRef;
  constructor(
    private spinnerService: SpinnerService,
    private zone: NgZone,
    private snackBar: MatSnackBar,
    private router: Router,
    private ccmcApiService: CcmcApiService,
    private dialogRef: MatDialogRef<EditAssetDialogComponent>
  ) {}

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  getData() {}

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our asset
    if ((value || '').trim()) {
      this.assetsArray.push(value.trim());
    }
    input.value = '';
  }

  remove(asset: any): void {
    const index = this.assetsArray.indexOf(asset);

    if (index >= 0) {
      this.assetsArray.splice(index, 1);
    }
  }

  selectedAssetOption(event: MatAutocompleteSelectedEvent): void {
    // Add our asset
    if ((event.option.viewValue || '').trim()) {
      this.assetsArray.push(event.option.viewValue);
      this.assetsCtrl.setValue(null);
    }
    this.assetsInput.nativeElement.value = '';
  }

  openSnackBar(message: string, action: string) {
    this.zone.run(() => {
      setTimeout(() => {
        this.snackBar.open(message, action, {
          duration: 5000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }, 0);
    });
  }

  getUsersAssets() {
    this.assetsArray = [];
    this.ccmcApiService
      .getUserAssets(this.username.toLowerCase())
      .subscribe(result => {
        if (result) {
          console.log(result);
          const parsedResult = JSON.parse(JSON.stringify(result));
          if (parsedResult.statusFlag === true) {
            const assetIDs = parsedResult.content.assetIDs;
            assetIDs.split(', ').forEach((splitId: any) => {
              return this.assetsArray.push(splitId);
            });
            this.canEditAssets = true;
          }
        }
      });
  }

  updateAssets() {
    this.ccmcApiService
      .updateAssets(this.username.toLowerCase(), this.assetsArray)
      .subscribe(result => {
        if (result) {
          const parsedResult = JSON.parse(JSON.stringify(result));
          if (parsedResult) {
            this.openSnackBar('Account Updated Successfully', 'Okay');
          }
        }
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
