import { ClipboardModule } from '@angular/cdk/clipboard';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpinnerService } from '../../services/spinner.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-copy-text-dialog',
  templateUrl: './copy-text-dialog.component.html',
  styleUrls: ['./copy-text-dialog.component.scss']
})
export class CopyTextDialogComponent {
  public confirmMessage: string;

  constructor(
    public dialogRef: MatDialogRef<CopyTextDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public msg: any,
    private spinnerService: SpinnerService,
    public clipboardModule: ClipboardModule,
  ) {
    this.spinnerService.setShowSpinner(false);
  }
}
