import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, filter, map } from 'rxjs/operators';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { SpinnerService } from './spinner.service';
import { GCSettingsService } from './gcsettings.service';
import { APPCONSTANTS } from '../../app/app.constants';
import { AssetService } from './asset.service';
import { environment } from 'src/environments/environment';
import { AmplifyService } from './amplify.service';
import { VBScriptHint } from '../codemirror/addon/hint/vbscript-hint';
import { response } from 'express';
const { Buffer } = require('buffer');
const fetch = require('node-fetch');

@Injectable({
  providedIn: 'root'
})
export class CcmcApiService {
  httpOptions = {
    headers: new HttpHeaders({
    "Content-Type": "application/json",
    })
  };


  /**
   * Core settings
   *
   * @type {*}
   * @memberof CcmcApiService
   */
  coreSettings: any;

  /**
   * Search
   *
   * @type {*}
   * @memberof CcmcApiService
   */
  search: any;
  private coreTable: any;
  private losTable: any;

  /**
   * Conditions
   *
   * @type {*}
   * @memberof CcmcApiService
   */
  conditions: any;

  /**
   * Transactions
   *
   * @type {*}
   * @memberof CcmcApiService
   */
  transactions: any;

  /**
   * Configurations
   *
   * @type {*}
   * @memberof CcmcApiService
   */
  configurations: any;

  /**
   * NCino write back
   *
   * @type {*}
   * @memberof CcmcApiService
   */
  ncinoWriteBack: any;

  /**
   * Mapping
   *
   * @type {*}
   * @memberof CcmcApiService
   */
  mapping: any;

  /**
   * Support Values
   *
   * @type {*}
   * @memberof CcmcApiService
   */
  supportValues: any;

  /**
   * Product Type
   * determines which environment to use
   * @memberof CcmcApiService
   */
  productType: any;

  /**
   * Base url
   *
   * @private
   * @type {string}
   * @memberof CcmcApiService
   */
  loanNumber: any;
  public currentLoanNumber: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public transactionReceiptList: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  documentGLABackup: any;

  private _refreshUsers: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  /**
   * Has Issues
   * Flag for if there are issues with the current loan
   * @private
   * @type {BehaviorSubject<boolean>}
   * @memberof CcmcApiService
   */
  private hasIssues: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  private _documentCore: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _documentLos: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _exportDocument: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _originalCashBoxNumber: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  hasIssues$ = this.hasIssues.asObservable();
  coreConfigruations: any;
  accountAssets: any[] = [];
  private _users: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public logTargetChangesArray: Array<{}> = [];
  public logSourceChangesArray: Array<{}> = [];
  public logGLAChangesArray: Array<{}> = [];
  public logDCChangesArray: Array<{}> = [];

  /**
   *Creates an instance of CcmcApiService.
   * @param {HttpClient} http
   * @param {SpinnerService} spinnerService
   * @param {CustomErrorHandlerService} customErrorHandlerService
   * @param {GCSettingsService} gcSettingService
   * @param {AssetService} assetService
   * @memberof CcmcApiService
   */
  constructor(
    private http: HttpClient,
    private spinnerService: SpinnerService,
    private customErrorHandlerService: CustomErrorHandlerService,
    private gcSettingService: GCSettingsService,
    private assetService: AssetService,
    private vbScriptHint: VBScriptHint,
    private amplifyService: AmplifyService,
    private zone: NgZone
  ) {
    this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, PUT'
    );
    this.httpOptions.headers.append('Accept', 'application/json');
    this.getProductType();
  }
  /**
   * Do Issues Esist
   * @description sets whether there are issues
   * @param {boolean} exist
   * @memberof CcmcApiService
   */
  doIssuesExist(exist: boolean) {
    this.hasIssues.next(exist);
  }
  get users() {
    return this._users.asObservable().pipe(
      map(users => {
        return users;
      })
    );
  }
  deleteCouchbaseItem(assetID: any, bucket: any, cluster: any) {
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/couchbase/delete-document`,
          { assetID, bucket, cluster },
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }
  createCouchbaseGeneric(
    cluster: string,
    assetID: string,
    bucket: string,
    document: any
  ) {
    const body = {
      cluster,
      assetID,
      bucket,
      document
    };

    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/couchbase/create-document`,
        body,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }
  get documentCore() {
    return this._documentCore.asObservable().pipe(
      map(document => {
        return document;
      })
    );
  }
  get documentLos() {
    return this._documentLos.asObservable().pipe(
      map(document => {
        const tempTransactionArray: any[] = [];
        if (document.loan) {
          document.loan.forEach((tranlator: any) => {
            tempTransactionArray.push(tranlator.fieldName);
          });
        }
        this.vbScriptHint.vbscriptKeywords =
          this.vbScriptHint.originalVBScriptKeywords.concat(
            tempTransactionArray
          );
        return document;
      })
    );
  }
  get exportDocument() {
    return this._exportDocument.asObservable().pipe(
      map(document => {
        return document;
      })
    );
  }

  get originalCashBoxNumber() {
    return this._originalCashBoxNumber.asObservable().pipe(
      map(document => {
        return document;
      })
    );
  }
  getAccountData(passedAccountID: any): Observable<any> {
    const body = JSON.stringify({
      accountID: passedAccountID
    });
    console.log(body);
    return this.http
      .post<any[]>(`${Buffer.from(environment.environmentURL, "base64").toString()}/salesforce/asset`, body, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }
  createUser(params: any) {
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/cognito/signup`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }
  callRefreshUsers() {
    this._refreshUsers.next(true);
  }
  get refreshUsers() {
    return this._refreshUsers.asObservable().pipe(
      map(users => {
        return users;
      })
    );
  }
  getAccountAssets() {
    // console.log(this.accountAssets);
    return this.accountAssets;
  }
  setAccountAssets(data: any) {
    data = JSON.stringify(data);
    // console.log(data);
    this.accountAssets.push(data);
    // console.log(this.accountAssets);
  }
  getUsersWithAsset(assetID: any) {
    const params = {
      assetID
    };
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/cognito/get-users`,
          params,
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }
  setUsers(users: any) {
    this._users.next(users);
  }
  addAssetToUser(asset: string) {
    const email = localStorage.getItem('name');
    const assetIDs = JSON.parse(localStorage.getItem('assetIds')!);
    const splitAsset = asset.split(',');
    for (let i = 0; i < splitAsset.length; i++) {
      assetIDs.push(splitAsset[i]);
    }
    const uniqueAssets = assetIDs.filter(function (
      elem: any,
      index: any,
      self: any
    ) {
      return index === self.indexOf(elem);
    });
    let assetIDsString = uniqueAssets[0];
    for (let i = 1; i < uniqueAssets.length; i++) {
      assetIDsString = assetIDsString + ', ' + uniqueAssets[i];
    }
    localStorage.setItem('assetIds', JSON.stringify(uniqueAssets));
    const params = {
      email: email?.toLowerCase(),
      userAttributes: [ 
        {
          Name: 'custom:AssetID',
          Value: assetIDsString
        }
      ]
    };
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/cognito/update-user-attributes`,
          params,
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }
  addUserToGroup(username: any, usergroup: any) {
    const params = {
      username,
      usergroup
    };
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/cognito/add-user-to-group`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  removeUserFromGroup(username: any, groups: any) {
    const params = {
      username,
      groups
    };
    console.log(params);
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/cognito/remove-user-from-group`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  getUserGroupsForUser(username: any) {
    const body = {
      username
    };
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/cognito/get-groups-for-user`,
        body,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  getUserAssets(username: any) {
    const params = {
      username: username
    };
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/cognito/get-user-assets`,
          params,
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }

  getUserGroups() {
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/cognito/get-user-groups`,
          {},
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }

  checkForSpecificConfig(
    listOfConfigs: any = {},
    newConfig: string,
    settings: { core: any; assetId: any }
  ) {
    console.log(listOfConfigs);
    console.log(newConfig);
    console.log(settings);
    console.log(listOfConfigs[newConfig]);
    if (listOfConfigs[newConfig] == undefined) {
      listOfConfigs[newConfig] = false;
      this.getDynamoDBItem(
        'core_connection_data',
        settings.core,
        'coreName'
      ).subscribe(result => {
        // gets current dynamo item for selected item
        const parsedResult = JSON.parse(JSON.stringify(result));
        this.coreConfigruations = parsedResult;
        let connectionString = parsedResult.couchbaseConnectionString;
        let bucket = parsedResult.bucket;

        this.getCouchbaseDocument(
          connectionString,
          bucket,
          settings.assetId
        ).subscribe(coreDocumentResult => {
          // gets current document for selected
          console.log('Get Core Standard', coreDocumentResult);
          if (coreDocumentResult) {
            const parsedResult = JSON.parse(JSON.stringify(coreDocumentResult));
            console.log(parsedResult);
            parsedResult.document.configurations[newConfig] = false;
            console.log(parsedResult.document.configurations);
            if (parsedResult.statusFlag) {
              // creates new couchbase or updates existing
              this.createCouchbaseGeneric(
                connectionString,
                settings.assetId,
                bucket,
                parsedResult.document
              ).subscribe(createDocumentResult => {
                if (createDocumentResult) {
                  // after creating a new one deletes old
                  console.log('updated');
                }
              });
            }
          }
        });
      });
    } else {
      console.log('implementationMode exists');
    }
  }

  getCouchbaseDocument(cluster: string, bucket: string, assetID: string) {
    const body = {
      cluster: cluster,
      assetID: assetID,
      bucket: bucket
    };

    console.log(body);
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/couchbase/get-document`,
        body,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }
  getDynamoDBItem(
    tableName: string,
    primaryKey: string,
    primaryKeyAttribute: string
  ) {
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/dynamodb/get-document`,
          {
            tableName: tableName,
            primaryKeyAttribute: primaryKeyAttribute,
            primaryKey: primaryKey
          },
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }
  // getBaseUrl() {
  //     return this.baseUrl;
  //   }


  getDynamoDBItemsByIndex(
    tableName: string,
    primaryKey: string,
    primaryKeyAttribute: string
  ) {
    console.log(tableName, primaryKey, primaryKeyAttribute);
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/dynamodb/get-documents-by-index`,
          {
            tableName: tableName,
            primaryKeyAttribute: primaryKeyAttribute,
            primaryKey: primaryKey
          },
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }

  getDynamoDBItemsByIndexWithKey(
    tableName: string,
    primaryKey: string,
    primaryKeyAttribute: string,
    lastEvaluatedKey: string
  ) {
    console.log("Args:", tableName, primaryKey, primaryKeyAttribute, lastEvaluatedKey);
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/dynamodb/get-documents-by-index`,
          {
            tableName: tableName,
            primaryKeyAttribute: primaryKeyAttribute,
            primaryKey: primaryKey,
            lastEvaluatedKey: lastEvaluatedKey
          },
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }

  deleteDynamoDBItem(keyName: any, key: any, tableName: any) {
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/dynamodb/delete-item`,
          { keyName, key, tableName },
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }
  updateAssets(username: any, assets: any) {
    console.log(assets);
    let assetIDsString = assets[0];
    if (assets.length > 0) {
      for (let i = 1; i < assets.length; i++) {
        assetIDsString = assetIDsString + ', ' + assets[i];
      }
    } else {
      assetIDsString = '';
    }

    const params = {
      email: username,
      userAttributes: [
        {
          Name: 'custom:AssetID',
          Value: assetIDsString
        }
      ]
    };
    console.log(params);
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/cognito/update-user-attributes`,
          params,
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }
  getCores() {
    this.getDynamoDB('core_connection_data').subscribe((result: any) => {
      this.coreTable = JSON.parse(JSON.stringify(result));
      console.log(this.coreTable);
    });
  }

  getLoses() {
    this.getDynamoDB('los_connection_data').subscribe((result:any) => {
      this.losTable = JSON.parse(JSON.stringify(result));
      console.log(this.losTable);
    });
  }
  /**
   * NCino Booking Results
   * @description sends the booking results to nCino
   * @param {*} { assetId, statusSummary, statusCode }
   * @returns
   * @memberof CcmcApiService
   */
  ncinoBookingResults({ assetId, statusSummary, statusCode, loanKey }: any) {
    const url = `${Buffer.from(environment.environmentURL, "base64").toString()}/ncino/send-booking-results`;
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const body = {
      assetId: assetId,
      statusSummary: statusSummary,
      mapping: this.mapping,
      statusCode: statusCode,
      loanKey: loanKey
    };
    return this.http
      .post(url, body, headers)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * ice
   * @description returns the populated core mapping
   * @param {*} loan
   * @returns
   * @memberof CcmcApiService
   */
  ice(loan: any) {
    const assetID = this.assetService.getSelectedAssetId();
    const body = JSON.stringify({
      assetID: assetID,
      content: {
        data: loan
      }
    });
    return this.http
      .post(`${Buffer.from(environment.environmentURL, "base64").toString()}/ice/data-conversion`, body, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * Book loan
   * @description sends the loan to be booked to the core
   * @returns
   * @memberof CcmcApiService
   */
  bookLoan() {
    const assetID = this.assetService.getSelectedAssetId();
    const body = JSON.stringify({
      assetID: assetID,
      content: {
        data: {
          mapping: this.mapping,
          configurations: this.configurations,
          transactions: this.transactions
        }
      }
    });
    return this.http
      .post(`${Buffer.from(environment.environmentURL, "base64").toString()}/ice/transport-loan`, body, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * Signature message builder
   * @description builds the message for signature
   * @returns
   * @memberof CcmcApiService
   */
  signatureMessageBuilder() {
    console.log(this.mapping);
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/fiserv/signature/message-builder`,
        this.mapping,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * @description retrieves a list of the loans from ncino
   */
  /**
   * S3 list files
   * @description returns files in given bucket
   * @param {*} bucket
   * @returns
   * @memberof CcmcApiService
   */
  s3ListFiles(bucket: any) {
    const assetID = this.assetService.getSelectedAssetId();
    const body = {
      bucket: bucket,
      assetID: assetID
    };
    return this.http
      .post(`${Buffer.from(environment.environmentURL, "base64").toString()}/s3/v2/list-loans`, body, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * S3 get file
   * @description retrieves the specified loan
   * @param {*} bucket
   * @param {*} key
   * @returns
   * @memberof CcmcApiService
   */
  s3GetFile(bucket: any, key: any) {
    const body = {
      bucket: bucket,
      key: key
    };
    return this.http
      .post(`${Buffer.from(environment.environmentURL, "base64").toString()}/s3/v2/get-loan`, body, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * S3 Delete File
   * @description deletes given file from s3
   * @param {*} bucket
   * @param {*} key
   * @returns
   * @memberof CcmcApiService
   */
  s3DeleteFile(bucket: any, key: any, reasonForDelete: any) {
    const body = JSON.stringify({
      bucket: bucket,
      key: key,
      reason: reasonForDelete,
      user: this.amplifyService.username
    });
    return this.http
      .post(`${Buffer.from(environment.environmentURL, "base64").toString()}/s3/v2/delete-file`, body, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  s3DeleteFolder(bucket: any, key: any, reasonForDelete: any) {
    const body = JSON.stringify({
      bucket: bucket,
      key: key,
      reason: reasonForDelete,
      user: this.amplifyService.username,
      assetID: this.assetService.getSelectedAssetId()
    });
    return this.http
      .post(`${Buffer.from(environment.environmentURL, "base64").toString()}/s3/delete-folder`, body, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  recordSender() {
    const assetID = this.assetService.getSelectedAssetId();
    let userName = this.amplifyService.username;
    const body = JSON.stringify({
      assetID: assetID,
      content: {
        user: userName,
        loanNumber: this.loanNumber
      }
    });
    return this.http
      .post(`${Buffer.from(environment.environmentURL, "base64").toString()}/record-sender/counter`, body, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * Get Product Type
   * @description Get the product type for current loan
   * @memberof CcmcApiService
   */
  getProductType() {
    this.gcSettingService.productType$.subscribe(type => {
      this.productType = type;
    });
  }




  getDynamoDB(tableName: string) {
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/dynamodb/get-table`,
          { tableName: tableName },
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }
  /**
   * Sends the data given to the current table and enviroment
   *
   * @param {string} env
   * @param {string} tableName
   * @param {*} document
   * @returns
   * @memberof CcmcApiService
   */
  createDynamoDB(env: string, tableName: string, document: any) {
    
    this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, PUT'
    );
    this.httpOptions.headers.append('Accept', 'application/json');
    console.log("HTTP OPTIONS", this.httpOptions);
    console.log({ tableName: tableName, document: document })
    if (env === 'test') {
      return (
        this.http
          // tslint:disable-next-line: max-line-length
          .post(
            `${Buffer.from(environment.environmentURL, "base64").toString()}/dynamodb/create-document`,
            { tableName: tableName, document: document },
            this.httpOptions
          )
          .pipe(catchError(this.customErrorHandlerService.handleError))
      );
    } else {
      return (
        this.http
          // tslint:disable-next-line: max-line-length
          .post(
            `${Buffer.from(environment.environmentURL, "base64").toString()}/dynamodb/create-document`,
            { tableName: tableName, document: document },
            this.httpOptions
          )
          .pipe(catchError(this.customErrorHandlerService.handleError))
      );
    }
  }

  convertJSONToCSV(json: any) {
    console.log(json)
    console.log({json: json})
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/convert/json-to-csv`,
          { json:json },
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }

  appendLogChanges(changes: any, type: string) {
    console.log(changes);
    switch (type) {
      case 'Target':
        this.logTargetChangesArray.push(changes);
        break;
      case 'Source':
        this.logSourceChangesArray.push(changes);
        break;
    }
  }

  clearLogChanges(type: string) {
    switch (type) {
      case 'Target':
        this.logTargetChangesArray = [];
        break;
      case 'Source':
        this.logSourceChangesArray = [];
        break;
    }
  }

  encryptMessage(message: any) {
    let body = {
      valueToEncrypt: message,
      logLevel: 'DEBUG'
    };
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/nxtsoft/encrypt`,
        body,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  getCoreConnection(core: any) {
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/dynamodb/get-core-connection`,
          { core: core },
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }

  getTransactionInfo(loanNumber: any) {
    const assetID = this.assetService.getSelectedAssetId();
    let coreCompany = this.assetService.getSelectedCoreCompany().replace(/-/g, "");
    let coreName = this.assetService.getSelectedCore().replace(/-/g, "");

    // Fixes core name for certain cores with specific requirements
    if (coreName === 'silverlake' ||
        coreName === '2020' ||
        coreName === 'coredirector') {
      coreName = 'jxchange';
    } else if (coreName === 'horizonxchange') {
      coreName = 'horizon_xchange';
    }

    // Fixes core company for CSI
    if (coreCompany === 'csi' && this.mapping !== undefined) {
      const accountNumberIndex = this.mapping.findIndex((obj: any) => obj.fieldID === "LNSNote_001"); 
      const noteNumberIndex = this.mapping.findIndex((obj: any) => obj.fieldID === "LNSNote_002"); 
      const accountNumber = this.mapping[accountNumberIndex].fieldValue; 
      const noteNumber = this.mapping[noteNumberIndex].fieldValue; 
      loanNumber = `${accountNumber}-${noteNumber}`;
    }

    const params = {
      tableName: `${coreCompany}_${coreName}_transactions`,
      primaryKeyAttribute: "assetID",
      primaryKey: assetID,
      sortKeyAttribute: "loanNumber",
      sortKey: loanNumber
    };
    console.log('Get Transaction Info Params ', params);
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/dynamodb/get-document`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  createDocumentGLA(glaDocument: any, coreConnectionData: any) {
    const assetID = this.assetService.getSelectedAssetId();
    const currentDoc = glaDocument;
    localStorage.setItem('glaDocumentBackup', JSON.stringify(currentDoc));
    this.documentGLABackup = currentDoc;
    let tempDocumentArray: any[] = [];
    // Removes edited from set transactions
    for (let set of currentDoc.sets) {
      tempDocumentArray = [];
      set.transactions.forEach(function (transaction: any) {
        const tempTransaction = Object.assign({}, transaction);
        delete tempTransaction.edited;
        tempDocumentArray.push(tempTransaction);
      });
      set.transactions = tempDocumentArray;
      for (let transaction of set.transactions) {
        let tempColumnArray: any[] = [];
        transaction.columns.forEach(function (column: any) {
          const tempColumn = Object.assign({}, column);
          delete tempColumn.edited;
          delete tempColumn.columnName;
          tempColumnArray.push(tempColumn);
        });
        transaction.columns = tempColumnArray;
      }
    }
    tempDocumentArray = [];
    // Removes edited from translators object
    currentDoc.translators.forEach(function (translator: any) {
      const tempTranslator = Object.assign({}, translator);
      delete tempTranslator.edited;
      tempDocumentArray.push(tempTranslator);
    });
    currentDoc.translators = tempDocumentArray;
    tempDocumentArray = [];
    currentDoc.coreLayout.forEach(function (coreLayoutItem: any) {
      const tempCoreLayoutItem = Object.assign({}, coreLayoutItem);
      delete tempCoreLayoutItem.edited;
      tempDocumentArray.push(tempCoreLayoutItem);
    });
    currentDoc.coreLayout = tempDocumentArray;
    const body = {
      cluster: coreConnectionData.couchbaseConnectionString,
      assetID: assetID,
      bucket: coreConnectionData.bucket,
      document: { ...currentDoc }
    };
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/couchbase/create-document`,
        body,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  updateModifiedBy(username: any) {
    return new Promise(resolve => {
      const assetID = this.assetService.getSelectedAssetId();
      this.getDynamoDBItem('assets', assetID, 'assetId').subscribe(
        (dynamoDBResult: any) => {
          console.log(dynamoDBResult);
          dynamoDBResult['modifiedByUser'] = username;
          dynamoDBResult['dateModified'] = new Date();
          this.createDynamoDB('prod', 'assets', dynamoDBResult).subscribe(
            result => {
              console.log(result);
              resolve(true);
            }
          );
        }
      );
    });
  }


  async logExportResponse(logExportRequest: any) {
    return new Promise(async resolve => {
      logExportRequest.user = await this.amplifyService.getUserName();
      logExportRequest.dateTime = new Date().toISOString();
      console.log(logExportRequest);
      this.createDynamoDB('test', 'gcweb-export-response', logExportRequest).subscribe(
        result => {
          console.log(result);
          resolve(true);
        }
      );
    }
    );
  }

  updateDynamoDBItem (params: any) {
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/dynamodb/update-document`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  setExportDocument(exportDocument: any) {
    this._exportDocument.next(exportDocument);
  }

  getUsersByAccountID(accountID: any) {
    const params = {
      accountID
    };
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post(
          `${Buffer.from(environment.environmentURL, "base64").toString()}/cognito/get-users-by-accountid`,
          params,
          this.httpOptions
        )
        .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }

  setOriginalCashBoxNumber(cashBoxNumber: any) {
    this._originalCashBoxNumber.next(cashBoxNumber);
  }
}
