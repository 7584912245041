import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateSetDialogComponent } from './create-set-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';

@NgModule({
  declarations: [CreateSetDialogComponent],
  imports: [AppMaterialModule, CommonModule, FormsModule, ReactiveFormsModule],
  entryComponents: [CreateSetDialogComponent]
})
export class CreateSetDialogModule {}
