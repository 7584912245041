import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CCMCConfirmDialogComponent } from 'src/@ccmc/components/confirm-dialog/confirm-dialog.component';
import { GetPathDialogComponent } from 'src/@ccmc/components/get-path-dialog/get-path-dialog.component';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';

@Component({
  selector: 'app-edit-global-meta',
  templateUrl: './edit-global-meta.component.html',
  styleUrls: ['./edit-global-meta.component.scss']
})
export class EditGlobalMetaComponent implements OnInit {
  public selectedGlobalMeta: any;
  public localSelectedGlobalMeta: any;
  private selectedSub: Subscription;
  private fieldEditedSub: Subscription;
  fieldEditedFlag = false;
  editedFlag = false;
  unsubscribe: Subject<any> = new Subject();
  private editedObjectSub: Subscription;
  editedObjectsArray: any[];

  /**
   * Creates an instance of SupportValuesSupportValuesComponent.
   * @param {CCMCSelectedFieldService} selectedFieldService
   * @param {CcmcApiService} ccmcApiService
   * @param {FieldEditedService} fieldEditedService
   * @param {MatDialog} dialog
   * @memberof SupportValuesSupportValuesComponent
   */
  constructor(
    private selectedFieldService: CCMCSelectedFieldService,
    private ccmcApiService: CcmcApiService,
    private fieldEditedService: FieldEditedService,
    private dialog: MatDialog
  ) {}

  /**
   * Initialize the component
   *
   * @memberof SupportValuesSupportValuesComponent
   */
  ngOnInit() {
    this.getData();
  }

  //make shortcuts into a array to append shortcuts into ngAfterViewInit
  shortcuts: ShortcutInput[] = [];

  //ngAfterViewinit method that calls the cmd+s shortcut
  ngAfterViewInit() {
    this.shortcuts.push({
      key: ['cmd + s'],
      label: 'Saving',
      description: 'saving',
      command: e => {
        this.save();
      }
    });
  }

  /**
   * Unsubscribe from observables on destroy
   *
   * @memberof SupportValuesSupportValuesComponent
   */
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  /**
   * Get data for the component
   *
   * @memberof SupportValuesSupportValuesComponent
   */
  getData() {
    this.editedObjectSub = this.fieldEditedService.editedObjects
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(editedArray => {
        this.editedObjectsArray = editedArray;
        //console.log(this.editedObjectsArray);
      });
    this.selectedSub = this.selectedFieldService.onGlobalMetasFieldSelected
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(selected => {
        if (selected) {
          if (this.editedFlag) {
            const confirmDialogRef = this.dialog.open(
              CCMCConfirmDialogComponent,
              {
                data: 'Save your current changes?',
                panelClass: 'save-current-dialog'
              }
            );
            confirmDialogRef.afterClosed().subscribe(data => {
              if (data) {
                this.save();
                this.selectedGlobalMeta = selected;
                this.setSelectedFlag(selected);
              } else {
                this.selectedGlobalMeta = selected;
                this.setSelectedFlag(selected);
              }
            });
          } else {
            this.selectedGlobalMeta = selected;
            this.setSelectedFlag(selected);
          }
        }
      });
    this.fieldEditedSub = this.fieldEditedService.fieldEdited
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(fieldEditedObj => {
        this.fieldEditedFlag = fieldEditedObj;
      });
  }

  /**
   * Assigns current selected to updated localselected
   *
   * @memberof SupportValuesSupportValuesComponent
   */
  save() {
    this.addToEditedArray(
      JSON.parse(JSON.stringify(this.selectedGlobalMeta)),
      JSON.parse(JSON.stringify(this.localSelectedGlobalMeta))
    );
    this.fieldEditedService.losEdited.next(true);
    this.selectedGlobalMeta.name = this.localSelectedGlobalMeta.name;
    this.selectedGlobalMeta.index = this.localSelectedGlobalMeta.index;
    this.selectedGlobalMeta.value = this.localSelectedGlobalMeta.value;
    this.selectedGlobalMeta.path = this.localSelectedGlobalMeta.path;
    this.selectedGlobalMeta.edited = true;
    this.editedFlag = false;
    this.fieldEditedService.fieldEdited.next(false);
  }

  undo() {
    // console.log(this.fieldEditedService.editedObjects);
    // console.log(this.editedObjectsArray);
    // console.log(this.editedObjectsArray.length);
    const newObjectIndex = this.editedObjectsArray.findIndex(obj => {
      if (obj.newObject.name === this.localSelectedGlobalMeta.name) {
        return obj;
      }
    });
    if (newObjectIndex > -1) {
      this.localSelectedGlobalMeta = JSON.parse(
        JSON.stringify(this.editedObjectsArray[newObjectIndex].oldObject)
      );
      this.editedObjectsArray = this.editedObjectsArray.splice(
        newObjectIndex,
        1
      );
      this.fieldEditedService.editedObjects.next(this.editedObjectsArray);
      const jsonFields = Object.keys(this.localSelectedGlobalMeta);
      console.log(jsonFields);
      for (let field of jsonFields) {
        this.selectedGlobalMeta[field] = this.localSelectedGlobalMeta[field];
        this.selectedGlobalMeta.edited = false;
      }
    }
    // console.log(this.editedObjectsArray[newObjectIndex]);
  }

  /**
   * Checks if edited flag can be turned on
   *
   * @memberof SupportValuesSupportValuesComponent
   */
  fieldEdited() {
    this.editedFlag = true;
    this.fieldEditedService.fieldEdited.next(true);
    console.log(this.localSelectedGlobalMeta);
    console.log(this.selectedGlobalMeta);
    // Checks to see if modified local selected variable is the same as the current fields
    if (
      this.isEquivalent(this.selectedGlobalMeta, this.localSelectedGlobalMeta)
    ) {
      this.editedFlag = false;
      this.fieldEditedService.fieldEdited.next(false);
    } else {
      this.editedFlag = true;
      this.fieldEditedService.fieldEdited.next(true);
    }
  }

  /**
   * Compares 2 objects and returns if they are equivalent
   *
   * @param {*} a
   * @param {*} b
   * @returns
   * @memberof SupportValuesSupportValuesComponent
   */
  isEquivalent(a: any, b: any) {
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      return false;
    }
    for (let i = 0; i < aProps.length; i++) {
      // ignores the edited property since those values will not be the same
      if (aProps[i] !== 'edited') {
        const propName = aProps[i];
        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
          return false;
        }
      }
    }
    return true;
  }

  setSelectedFlag(selected: any) {
    this.editedFlag = false;
    this.fieldEditedService.fieldEdited.next(false);
    this.localSelectedGlobalMeta = {
      name: selected.name,
      index: selected.index,
      value: selected.value,
      path: selected.path,
      edited: selected.edited
    };
  }

  addToEditedArray(oldObject: any, newObject: any) {
    const newObjectIndex = this.editedObjectsArray.findIndex(obj => {
      if (obj.newObject.category === oldObject.category) {
        return obj;
      }
    });
    // console.log(newObjectIndex);
    if (newObjectIndex > -1) {
      this.editedObjectsArray[newObjectIndex].newObject = newObject;
    } else {
      const editedObject = { oldObject, newObject };
      this.editedObjectsArray.push(editedObject);
      const msg = {
        type: 'Global Metas',
        editedObject
      };
      // this.ccmcApiService.appendLogChanges(msg, 'Target');
      // console.log(this.ccmcApiService.logTargetChangesArray);
    }
    // console.log(oldObject);
    // console.log(newObject);
    this.fieldEditedService.editedObjects.next(this.editedObjectsArray);
  }

  getEncompassPath() {
    const pathDialogRef = this.dialog.open(GetPathDialogComponent);
    pathDialogRef.afterClosed().subscribe(result => {
      if(result){
        let tempResult = result.data;
        this.localSelectedGlobalMeta.path = tempResult.replace('~#~', '0');
        this.fieldEdited();
      }
    })
  }
}
