<div class="transactions-container">
  <!--filter Search section -->
  <div class="filterContainer">
    <mat-form-field class="filterField">
      <input matInput placeholder="Filter Search" #filterSearch />
    </mat-form-field>
    <div class="AddBtn-Container">
      <button
        class="Add-btn"
        matTooltip="Add New Transaction"
        matTooltipClass="toolTipStyle"
        (click)="createTargetLayout()"
      >
        <mat-icon style="color: #3fb2c6">add_circle</mat-icon>
      </button>
    </div>
   </div>
  <div class="table-container">
    <div
      style="overflow-x: auto; height: 100%"
      [style.width]="getTargetLayoutWidth()"
    >
      <mat-table
        [dataSource]="dataSource"
        matSort
        style="overflow-x: true"
        [style.width]="totalTableWidth + 'px'"
      >
        <ng-container
          *ngFor="let disCol of displayedColumns; let colIndex = index"
          matColumnDef="{{ disCol }}"
        >
          <mat-header-cell
            *matHeaderCellDef
            mat-header-cell
            [style.max-width]="columnWidths[colIndex]"
            ><div *ngIf="targetLayout.length > 0">
              {{ disCol | uppercaseFirstLetterPipe }}
            </div>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            style="width: 100%; max-height: 30px; word-wrap: none"
            [style.max-width]="columnWidths[colIndex]"
          >
            <div *ngIf="disCol !== 'edited'" style="max-height: 30px">
              {{ element[disCol] }}
            </div>
            <div *ngIf="disCol == 'edited' && element[disCol]">
              <mat-icon class="editIcon"> star</mat-icon>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="onSelect(row)"
          [ngClass]="{ 'accent-50-bg': row == selected }"
          style="max-height: 30px; align-items: stretch"
        ></mat-row>
      </mat-table>
    </div>
  </div>
  <!--TabBar-->
  <div class="tabBar">
    <div class="AddBtn-Container">
      <button
        class="Btn-remove"
        matTooltip="Remove Selected Column"
        matTooltipClass="toolTipStyle"
        color="primary"
        (click)="removeColumn()"
      >
        <mat-icon style="color: #7570d2">remove_circle</mat-icon>&nbsp;
      </button>
    </div>
    <mat-tab-group>
      <mat-tab label="Column">
        <div *ngIf="targetLayout">
          <ccmc-target-layout-columns></ccmc-target-layout-columns>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<!--Spinner-->
<div class="spinner-container" *ngIf="showSpinner">
  <div class="new-spinner">
    <div style="position: relative" class="outer-ring"></div>
    <div
      style="position: relative; bottom: 592px;"
      class="middle-ring"
    ></div>
    <div
      style="position: relative; bottom: 1196px;"
      class="inner-ring"
    ></div>
  </div>
</div>
