<mat-sidenav-container>

    <div id="ccmc-main-content">

        <!-- TOOLBAR: Above -->
        <ng-container *ngIf="ccmcSettings.layout.toolbar === 'above'">
            <ccmc-toolbar class="above" [ngClass]="ccmcSettings.colorClasses.toolbar"></ccmc-toolbar>
        </ng-container>
        <!-- / TOOLBAR: Above -->

        <!-- NAVBAR: Top -->
        <ccmc-navbar layout="horizontal" class="top-navbar" fxHide fxShow.gt-md
            [ngClass]="ccmcSettings.colorClasses.navbar" *ngIf="ccmcSettings.layout.navigation === 'top'">
        </ccmc-navbar>
        <!-- / NAVBAR: Top -->

        <div id="wrapper">

            <!-- NAVBAR: Left -->
            <ccmc-sidebar [name]="'navbar'" [folded]="ccmcSettings.layout.navigationFolded" [lockedOpen]="'gt-md'"
                class="left-navbar" [ngClass]="ccmcSettings.colorClasses.navbar"
                *ngIf="ccmcSettings.layout.navigation === 'left' || ccmcSettings.layout.navigation === 'top'">
                <ccmc-navbar layout="vertical"></ccmc-navbar>
            </ccmc-sidebar>
            <!-- / NAVBAR: Left -->

            <div class="content-wrapper">

                <!-- TOOLBAR: Below -->
                <ng-container *ngIf="ccmcSettings.layout.toolbar === 'below'">
                    <ccmc-toolbar class="below" [ngClass]="ccmcSettings.colorClasses.toolbar"></ccmc-toolbar>
                </ng-container>
                <!-- / TOOLBAR: Below -->

                <ccmc-content></ccmc-content>

                <!-- FOOTER: Below -->
                <ng-container *ngIf="ccmcSettings.layout.footer === 'below'">
                    <ccmc-footer class="below" [ngClass]="ccmcSettings.colorClasses.footer"></ccmc-footer>
                </ng-container>
                <!-- / FOOTER: Below -->

            </div>

            <!-- NAVBAR: Right -->
            <ccmc-sidebar [name]="'navbar'" [align]="'right'" [folded]="ccmcSettings.layout.navigationFolded"
                [lockedOpen]="'gt-md'" class="right-navbar" [ngClass]="ccmcSettings.colorClasses.navbar"
                *ngIf="ccmcSettings.layout.navigation === 'right'">
                <ccmc-navbar layout="vertical"></ccmc-navbar>
            </ccmc-sidebar>
            <!-- / NAVBAR: Right -->

        </div>

        <!-- FOOTER: Above -->
        <ng-container *ngIf="ccmcSettings.layout.footer === 'above'">
            <ccmc-footer class="above" [ngClass]="ccmcSettings.colorClasses.footer"></ccmc-footer>
        </ng-container>
        <!-- FOOTER: Above -->

    </div>

    <!-- QUICK PANEL -->
    <mat-sidenav ccmcMatSidenavHelper="quick-panel" position="end">
        <ccmc-quick-panel></ccmc-quick-panel>
    </mat-sidenav>
    <!-- / QUICK PANEL -->

</mat-sidenav-container>