import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TargetLayoutComponent } from './target-layout.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../../auth/auth.guard';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { CCMCSharedModule } from '../../../../@ccmc/shared.module';
import { TargetLayoutColumnsComponent } from './target-layout-columns/target-layout-columns.component';
import { AddTargetLayoutColumnDialogComponent } from 'src/@ccmc/components/add-target-layout-column-dialog/add-target-layout-column-dialog.component';

const routes: Routes = [
  {
    path: 'target-layout',
    component: TargetLayoutComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
  }
];
@NgModule({
  declarations: [TargetLayoutComponent, TargetLayoutColumnsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CCMCSharedModule,
    KeyboardShortcutsModule.forRoot()
  ],
  entryComponents: [AddTargetLayoutColumnDialogComponent]
})
export class TargetLayoutModule {}
