import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';
import { DocConnectorHistoryResponseDialogComponent } from './doc-connector-history-response-dialog.component';
import { MatListModule } from '@angular/material/list';

@NgModule({
  declarations: [DocConnectorHistoryResponseDialogComponent],
  imports: [
    MatDialogModule,
    AppMaterialModule,
    MatButtonModule,
    CommonModule,
    MatListModule
  ],
  entryComponents: [DocConnectorHistoryResponseDialogComponent]
})
export class DocConnectorHistoryResponseDialogModule {}
