import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AmplifyQrCodeDialogComponent } from './amplify-qr-code-dialog.component';
import { FormsModule } from '@angular/forms';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [AmplifyQrCodeDialogComponent],
  imports: [CommonModule, FormsModule, NgxQRCodeModule, MatFormFieldModule, AppMaterialModule, MatButtonModule]
})
export class AmplifyQrCodeDialogModule {}
