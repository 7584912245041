<div style="width: 40%; min-width: 500px">
  <h1 #title>Add or Remove Columns</h1>
  <h3>Add or remove columns from the selected set's transactions.</h3>
  <div style="overflow-y: scroll; max-height: 500px; width: 100%">
    <table
      mat-table
      matSort
      #table
      [dataSource]="dataSource"
      CCMCPerfectScrollbar
    >
      <ng-container matColumnDef="columnName">
        <th mat-header-cell *matHeaderCellDef>Column Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element.columnName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="columnID">
        <th mat-header-cell *matHeaderCellDef>ColumnID</th>
        <td mat-cell *matCellDef="let element">
          {{ element.columnID }}
        </td>
      </ng-container>

      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef></th>
        <td
          mat-cell
          *matCellDef="let element"
          style="font-size: 11px"
          class="no-selectable"
        >
          <mat-icon
            style="color: #3b3b3b; cursor: pointer; margin-top: 2px"
            *ngIf="element.active"
            (click)="element.active = !element.active"
            >remove_circle</mat-icon
          >
          <mat-icon
            style="cursor: pointer; margin-top: 2px"
            color="accent"
            *ngIf="!element.active"
            (click)="element.active = !element.active"
            >add_circle</mat-icon
          >
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{ activeColor: !row.active }"
      ></tr>
    </table>
  </div>
  <div style="display: flex; justify-content: space-between">
    <button
      mat-raised-button
      matTooltip="Close Dialog"
      matTooltipClass="toolTipStyle"
      (click)="closeDialog()"
      style="text-align: left; max-width: 75px"
    >
      Cancel
    </button>
    <button
      mat-raised-button
      matTooltip="Update Column ID"
      matTooltipClass="toolTipStyle"
      color="accent"
      (click)="updateColumns()"
      style="text-align: right; max-width: 50px"
    >
      Save
    </button>
  </div>
</div>
