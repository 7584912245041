import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpinnerService } from '../../services/spinner.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss']
})
export class SuccessDialogComponent {
  public confirmMessage: string;

  constructor(
    public dialogRef: MatDialogRef<SuccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public msg: any,
    private spinnerService: SpinnerService
  ) {
    this.spinnerService.setShowSpinner(false);
  }
}
