import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GCSettings, AssetIdSettings } from '../models/gcSettings.model';
import { Observable, Subject } from 'rxjs';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { catchError } from 'rxjs/operators';
import { APPCONSTANTS } from '../../app/app.constants';
import { AmplifyService } from './amplify.service';
import { environment } from 'src/environments/environment';
const { Buffer } = require('buffer');

@Injectable({
  providedIn: 'root'
})
export class GCSettingsService {
  httpOptions = {
    headers: new HttpHeaders({
    "Content-Type": "application/json",
    })
  };
  private los: any;
  private productType = '';
  private onProductTypeSubject: Subject<string> = new Subject();
  productType$ = this.onProductTypeSubject.asObservable();

  constructor(
    private httpClient: HttpClient,
    private customErrorHandler: CustomErrorHandlerService,
    private amplifyService: AmplifyService,
    private http: HttpClient,
    private customErrorHandlerService: CustomErrorHandlerService
  ) 
  {this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
  this.httpOptions.headers.append(
    'Access-Control-Allow-Methods',
    'POST, GET, OPTIONS, PUT'
  );
  this.httpOptions.headers.append('Accept', 'application/json');}

  /**
   * Add Settings
   * @description sets the cognitoId, assetId, los, core, loanPath
   * for the current institution
   * @param {GCSettings} { cognitoId, assetId, los, core, loanPath }
   * @returns
   * @memberof GCSettingsService
   */
  addSettings({ cognitoId, assetId, los, core, loanPath }: GCSettings) {
    const url = `${Buffer.from(environment.environmentURL, "base64").toString()}/assets/settings/add`;
    return this.httpClient
      .post(
        url,
        {
          cognitoId,
          assetId,
          los,
          core,
          loanPath
        },
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandler.handleError));
  }

  /**
   * Retrieve Settings
   * @description Gets the product, los, core, and loanPath
   * for the current institution
   * @param {string} assetId
   * @returns {Observable<any>}
   * @memberof GCSettingsService
   */
  retrieveSettings(assetID: string): Observable<any> {
      return (
        this.http
          // tslint:disable-next-line: max-line-length
          .post(
            `${Buffer.from(environment.environmentURL, "base64").toString()}/dynamodb/get-document`,
            {
              tableName: 'assets',
              primaryKeyAttribute: 'assetId',
              primaryKey: assetID
            },
            this.httpOptions
          )
          .pipe(catchError(this.customErrorHandlerService.handleError))
      );
  }

  /**
   * Edit Settings
   * @description edit the loanPath for the current institution
   * @param {*} newSetting
   * @returns {Observable<any>}
   * @memberof GCSettingsService
   */
  editSettings(newSetting: any): Observable<any> {
    const url = `${Buffer.from(environment.environmentURL, "base64").toString()}/assets/settings/edit`;
    return this.httpClient
      .post(url, newSetting, this.httpOptions)
      .pipe(catchError(this.customErrorHandler.handleError));
  }

  /**
   * Set Los
   * @description set the los
   * @memberof GCSettingsService
   */
  set Los(los: string) {
    this.los = los;
  }

  /**
   * Retrieve Los
   * @description retrieves the los
   * @readonly
   * @memberof GCSettingsService
   */
  get retrieveLos() {
    return this.los;
  }

  /**
   * Set Product Type
   * @description sets the product type
   * @param {string} type
   * @memberof GCSettingsService
   */
  setProductType(type: string) {
    this.onProductTypeSubject.next(type);
  }

  /**
   * Retrieve Product
   * @description gets the product type
   * @readonly
   * @memberof GCSettingsService
   */
  get retrieveProduct() {
    return this.productType;
  }
}
