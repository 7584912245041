import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SendDocConnectorDialogComponent } from './send-doc-connector-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [SendDocConnectorDialogComponent],
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatCheckboxModule,
    CommonModule,
    FormsModule
  ],
  entryComponents: [SendDocConnectorDialogComponent]
})
export class SendDocConnectorDialogModule {}
