import {
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  ViewChild,
  NgZone
} from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CCMCConfirmDialogComponent } from '../../../../../@ccmc/components/confirm-dialog/confirm-dialog.component';
import { FieldEditedService } from '../../../../../@ccmc/services/field-edited.service';
import { CCMCSelectedFieldService } from '../../../../../@ccmc/services/selected-field.service';
import {
  ShortcutInput,
  ShortcutEventOutput,
  KeyboardShortcutsModule
} from 'ng-keyboard-shortcuts';
import { GeneralLedgerAccountingService } from '../../../../../@ccmc/services/general-ledger-accounting.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'ccmc-target-layout-columns',
  templateUrl: './target-layout-columns.component.html',
  styleUrls: ['./target-layout-columns.component.scss']
})
export class TargetLayoutColumnsComponent implements OnInit, OnDestroy {
  /**
   * Selected transaction
   *
   * @type {*}
   * @memberof TargetLayoutColumnsComponent
   */
  selected: any;
  private glaDocumentSub: Subscription;

  /**
   * Local instance of the selected transaction
   *
   * @type {*}
   * @memberof TargetLayoutColumnsComponent
   */
  localSelected: any;

  /**
   * Selected subscription
   *
   * @private
   * @type {Subscription}
   * @memberof TargetLayoutColumnsComponent
   */
  private selectedSub: Subscription;

  /**
   * Edited flag that controls the save button
   *
   * @memberof TargetLayoutColumnsComponent
   */
  editedFlag = false;

  /**
   * Field edited subscription
   *
   * @private
   * @type {Subscription}
   * @memberof TargetLayoutColumnsComponent
   */
  private fieldEditedSub: Subscription;

  /**
   * Field edited flag
   *
   * @memberof TargetLayoutColumnsComponent
   */
  fieldEditedFlag = false;

  /**
   * Unsubscribe from observables on destroy
   *
   * @type {Subject<any>}
   * @memberof TargetLayoutColumnsComponent
   */
  unsubscribe: Subject<any> = new Subject();

  variableKeys: any;

  tfBoolean = [true, false];
  tempDocument: any;
  documentCoreSub: any;
  selectedSetSub: Subscription;
  selectedSet: any;

  /**
   * Creates an instance of TargetLayoutColumnsComponent.
   * @param {CCMCSelectedFieldService} selectedFieldService
   * @param {CcmcApiService} ccmcApiService
   * @param {FieldEditedService} fieldEditedService
   * @param {MatDialog} dialog
   * @memberof TargetLayoutColumnsComponent
   */
  constructor(
    private selectedFieldService: CCMCSelectedFieldService,
    private ccmcGlaService: GeneralLedgerAccountingService,
    private glaService: GeneralLedgerAccountingService,
    private zone: NgZone,
    private snackBar: MatSnackBar,
    private fieldEditedService: FieldEditedService,
    private dialog: MatDialog
  ) {}

  /**
   * Intialize the component
   *
   * @memberof TargetLayoutColumnsComponent
   */
  ngOnInit() {
    this.getData();
  }

  //make shortcuts into a array to append shortcuts into ngAfterViewInit
  shortcuts: ShortcutInput[] = [];

  //ngAfterViewinit method that calls the cmd+s shortcut
  ngAfterViewInit() {
    this.shortcuts.push({
      key: ['cmd + s'],
      label: 'Saving',
      description: 'saving',
      command: e => {
        this.save();
      }
    });
  }

  /**
   * Unsubscribe from observables on destroy
   *
   * @memberof TargetLayoutColumnsComponent
   */
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  /**
   * Get data for the component
   *
   * @memberof TargetLayoutColumnsComponent
   */
  getData() {
    this.documentCoreSub = this.ccmcGlaService.glaDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(glaDocument => {
        if (glaDocument) {
          this.tempDocument = glaDocument.coreLayout;
          // console.log('GLA', this.tempDocument);
        }
      });
    this.selectedSub = this.selectedFieldService.onTargetLayoutFieldSelected
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(selected => {
        if (selected) {
          if (this.editedFlag) {
            const confirmDialogRef = this.dialog.open(
              CCMCConfirmDialogComponent,
              {
                data: 'Save your current changes?',
                panelClass: 'save-current-dialog'
              }
            );
            confirmDialogRef.afterClosed().subscribe(data => {
              if (data) {
                this.save();
                this.updateSelectedOptions(selected);
              } else {
                this.updateSelectedOptions(selected);
              }
            });
          } else {
            this.updateSelectedOptions(selected);
          }
        } else {
          this.updateSelectedOptions({});
        }
      });

    this.fieldEditedSub = this.fieldEditedService.fieldEdited
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(fieldEditedObj => {
        this.fieldEditedFlag = fieldEditedObj;
      });
  }

  /**
   * Updates the localSelected with user input.
   *
   * @param {*} selected User selected attributes.
   * @param {*} isDefined Sets attributes from selected if true otherwiese undefined.
   * @param {*} isEditedFalseFields Sets edited field and flag to false when true.
   * @memberof TargetLayoutColumnsComponent
   */
  updateSelectedOptions(selected: any) {
    this.selected = selected;
    this.variableKeys = Object.keys(this.selected);
    this.localSelected = JSON.parse(JSON.stringify(this.selected));
    let editedLocation = this.variableKeys.indexOf('edited');
    console.log(editedLocation);
    if (-1 < this.variableKeys.indexOf('edited')) {
      this.variableKeys.splice(editedLocation, 1);
    }
    console.log(this.variableKeys);
    console.log(this.localSelected);
    this.fieldEditedService.fieldEdited.next(false);
    this.editedFlag = false;
  }

  /**
   * Updates the transaction with user input
   *
   * @memberof TargetLayoutColumnsComponent
   */
  save() {
    // console.log(this.localSelected['columnID']);
    let columnIdCheck = this.tempDocument.findIndex(
      (column: any) => column['columnID'] === this.localSelected['columnID']
    );
    // console.log(columnIdCheck);
    if (columnIdCheck > -1) {
      // console.log(this.localSelected['columnID']);
      // console.log(this.selected['columnID']);
      if (this.localSelected['columnID'] != this.selected['columnID']) {
        this.openSnackBar(
          `Error: This Column ID '${this.localSelected['columnID']}' already exists`,
          'Okay'
        );
        return;
      }
    }
    this.selectedSetSub =
      this.selectedFieldService.onGLATransactionFieldSelected
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(set => {
          this.selectedSet = set;
          // console.log(this.selectedSet);
        });
    this.fieldEditedService.onGLADocumentEdited.next(true);
    this.localSelected.edited = true;
    this.glaDocumentSub = this.glaService.glaDocument.subscribe(glaDocument => {
      // console.log(glaDocument);
      const jsonFields = Object.keys(this.localSelected);
      for (let field of jsonFields) {
        // console.log(field);
        if (field == 'columnID') {
          for (let i = 0; i < glaDocument.sets.length; i++) {
            for (let j = 0; j < glaDocument.sets[i].transactions.length; j++) {
              for (
                let h = 0;
                h < glaDocument.sets[i].transactions[j].columns.length;
                h++
              ) {
                // console.log(glaDocument.sets[i].transactions[j].columns[h]);
                let columnIdCheck =
                  glaDocument.sets[i].transactions[j].columns[h];
                if (columnIdCheck['columnID'] === this.selected['columnID']) {
                  columnIdCheck['columnID'] = this.localSelected['columnID'];
                }
                // console.log(columnIdCheck);
              }
            }
          }
        }
        this.selected[field] = this.localSelected[field];
      }
    });

    this.editedFlag = false;
    this.fieldEditedService.fieldEdited.next(false);
  }

  /**
   * Checks if the transaction has been edited
   *
   * @memberof TargetLayoutColumnsComponent
   */
  fieldEdited() {
    if (this.isEquivalent(this.selected, this.localSelected)) {
      // Checks to see if modified local selected variable is the same as the current fields
      this.editedFlag = false;
      this.fieldEditedService.fieldEdited.next(false);
    } else {
      this.editedFlag = true;
      this.fieldEditedService.fieldEdited.next(true);
    }
  }

  /**
   * Returns if a and b are equal
   *
   * @param {*} a
   * @param {*} b
   * @returns
   * @memberof TargetLayoutColumnsComponent
   */
  isEquivalent(a: any, b: any) {
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      // ignores the edited property since those values will not be the same
      if (aProps[i] !== 'edited') {
        const propName = aProps[i];

        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
          return false;
        }
      }
    }
    return true;
  }
  openSnackBar(message: string, action: string) {
    this.zone.run(() => {
      setTimeout(() => {
        this.snackBar.open(message, action, {
          duration: 5000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }, 0);
    });
  }
}
