<div class="collateral-details">
  <mat-table
    #collateralDetailsSort="matSort"
    #collateralDetailsTable
    class="collateral-details__table"
    [dataSource]="collateralDataSource"
    matSort
  >
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="cell-text"
        >Field Display</mat-header-cell
      >
      <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="value">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="cell-text"
        >Field Value</mat-header-cell
      >
      <mat-cell *matCellDef="let element">{{ element.value }}</mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="tableColumns; sticky: true"
    ></mat-header-row>
    <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
  </mat-table>
  <div class="collateral-details__button-container">
    <div class="collateral-details__buttons--close">
      <button
        class="collateral-details__buttons"
        id="close-button"
        (click)="close()"
        mat-stroked-button
      >
        Close
      </button>
    </div>
  </div>
</div>
