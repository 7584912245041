<div class="collateral-details">
    <mat-table #collateralDetailsSort="matSort" #collateralDetailsTable class="collateral-details__table" [dataSource]="collateralDetailsDataSource" matSort>
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="cell-text">Field Display</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="value">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="cell-text">Field Value</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.value }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
    </mat-table>
    <!-- *ngClass="{disabled: previousBtnDisabled}" -->
    <div class="collateral-details__button-container">
        <div class="collateral-details__buttons--navigation">
            <button class="
          collateral-details__buttons collateral-details__buttons--previous
        " [disabled]="previousBtnDisabled" [ngClass]="{ disabled: previousBtnDisabled }" (click)="previous()" id="previous-button" mat-raised-button>
        <mat-icon>arrow_left</mat-icon>Previous
      </button>
            <button class="collateral-details__buttons collateral-details__buttons-next" [disabled]="nextBtnDisabled" [ngClass]="{ disabled: nextBtnDisabled }" (click)="next()" id="next-button" mat-raised-button>
        Next<mat-icon> arrow_right</mat-icon>
      </button>
        </div>

        <div class="collateral-details__buttons--close">
            <button class="collateral-details__buttons" id="close-button" (click)="close()" mat-stroked-button>
        Close
      </button>
        </div>
    </div>
</div>