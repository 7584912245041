import { Injectable } from '@angular/core';
import { GCSettingsService } from 'src/@ccmc/services/gcsettings.service';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AvatarService } from 'src/@ccmc/services/avatar.service';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _isLoggedIn = false;
  jwtHelper;
  constructor(
    private amplifyService: AmplifyService,
    private gcSettingsService: GCSettingsService,
    private assetService: AssetService,
    private router: Router,
    private avatarService: AvatarService
  ) {
    this.jwtHelper = new JwtHelperService();
  }

  /**
   * @description sets logged in value
   */
  set isLoggedIn(val: boolean) {
    this._isLoggedIn = val;
  }

  /**
   * @description gets value of whether user is logged in
   */
  get isLoggedIn() {
    return this._isLoggedIn;
  }

  setUserData(idToken: any, accessToken: any, refreshToken: any) {
    const decodedIdToken = this.jwtHelper.decodeToken(idToken);
    const userGroups = decodedIdToken['cognito:groups'];
    if (userGroups) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < userGroups.length; i++) {
        if (userGroups[i] === 'nxtsoft') {
          this.amplifyService.isNXTsoft = true;
        }
        if (userGroups[i] === 'nxtsoft-sales') {
          this.amplifyService.isNXTsoftSales = true;
        }
        if (userGroups[i] === 'client-admin') {
          console.log('is admin');
          this.amplifyService.isAdmin = true;
        }
        if (userGroups[i] === 'nxtsoft-dev') {
          console.log('is dev');
          this.amplifyService.isNXTsoftDev = true;
        }
        if (userGroups[i] === 'nxtsoft-operations') {
          console.log('is operations');
          this.amplifyService.isNXTsoftOperations = true;
        }
        if (userGroups[i] === 'super-admin') {
          console.log('is super admin');
          this.amplifyService.isSuperAdmin = true;
        }
        // if (userGroups[i] === 'loan-servicing-admin') {
        //   console.log('is loan servicing admin');
        //   this.amplifyService.isLoanServicingAdmin = true;
        // }
        // if (userGroups[i] === 'loan-systems-admin') {
        //   console.log('is loan systems admin');
        //   this.amplifyService.isLoanSystemsAdmin = true;
        // }
      }
    } else {
      this.amplifyService.isNXTsoft = false;
      this.amplifyService.isAdmin = false;
      this.amplifyService.isNXTsoftDev = false;
      this.amplifyService.isNXTsoftOperations = false;
      this.amplifyService.isNXTsoftSales = false;
      this.amplifyService.isSuperAdmin = false;
      this.amplifyService.isLoanServicingAdmin = false;
      this.amplifyService.isLoanSystemsAdmin = false;
    }
    const assetIDs = decodedIdToken['custom:AssetID'].trim();
    const assetArray: any[] = [];
    if (assetIDs) {
      assetIDs.split(', ').forEach((splitId: any) => {
        return assetArray.push(splitId);
      });
    }
    // this.handleAvatar(decodedIdToken['picture']);
    this.assetService.setAssetids(assetArray);
    this.router.navigate(['/assetid-selector']);
  }

  handleAvatar(avatar: any) {
    if (avatar) {
      this.avatarService.setAvatar(avatar);
    } else {
      const defaultAvatar = 'basic';
      this.avatarService.updateAvatar(defaultAvatar).subscribe(result => {
        console.log(result);
        if (result) {
          this.avatarService.setAvatar(defaultAvatar);
        }
      });
    }
  }
}
