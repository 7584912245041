import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewStatusDialogComponent } from './new-status-dialog.component';
import { FormsModule } from '@angular/forms';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';

@NgModule({
  declarations: [NewStatusDialogComponent],
  imports: [CommonModule, AppMaterialModule, FormsModule],
  entryComponents: [NewStatusDialogComponent]
})
export class NewStatusDialogModule {}
