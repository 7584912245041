import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddValidationDialogComponent } from './add-validation-dialog.component';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [AddValidationDialogComponent],
  imports: [
    AppMaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule
  ],
  entryComponents: [AddValidationDialogComponent]
})
export class AddValidationDialogModule { }
