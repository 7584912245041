<div style="height: 100%">
  <div style="background-color: #ffffff; border: 1px solid #8d8d8d">
    <mat-form-field
      class="filter-FormField"
      style="width: 80%; padding-left: 1%"
    >
      <input matInput placeholder="Filter Destinations" #filterSearch />
    </mat-form-field>
    <div class="AddBtn-Container">
      <button
        class="Add-btn"
        matTooltip="Add a New Destination"
        matTooltipClass="toolTipStyle"
        style="cursor: pointer"
        (click)="addNewDestination()"
      >
        <mat-icon style="color: #3fb2c6">add_circle</mat-icon>
      </button>
    </div>
  </div>
  <div class="table-container">
    <table
      mat-table
      class="table-TableContainer"
      #table
      [dataSource]="destinationsDataSource"
      CCMCPerfectScrollbar
      matSort
    >
      <!-- Field ID Column -->
      <ng-container matColumnDef="id">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          style="min-width: 175px; max-width: 175px"
        >
          ID
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="noselect"
          style="min-width: 175px; max-width: 175px"
        >
          {{ element.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="edited">
        <th mat-header-cell *matHeaderCellDef>Edited</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.edited === true" class="editIcon">
            star</mat-icon
          >
        </td>
      </ng-container>

      <!-- Remove Column -->
      <ng-container matColumnDef="remove">
        <th mat-header-cell *matHeaderCellDef class="noselect">
          <mat-icon
            style="
              margin-top: 1px;
              margin-right: 8%;
              float: right;
              color: #aaaaaa;
            "
            (click)="removeDestination()"
            matTooltip="Remove selected Destinations"
            >remove_circle</mat-icon>
        </th>
        <td mat-cell *matCellDef="let element" class="noselect"></td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="destinationsDisplayedColumns; sticky: true"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: destinationsDisplayedColumns"
        (dblclick)="onSelectDestination(row)"
        (click)="multiSelectDestinations(row, $event)"
        matTooltip="Double Click to see more details"
        matTooltipClass="tooltip-color"
        [ngClass]="{ 'accent-bg': currentSelection.includes(row) }"
      ></tr>
    </table>
  </div>
</div>
