import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocConnectorHistoryComponent } from './doc-connector-history.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { CCMCSharedModule } from 'src/@ccmc/shared.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectedLoanComponent } from './selected-loan/selected-loan.component';

const routes: Routes = [
  {
    path: 'doc-connector/history',
    component: DocConnectorHistoryComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [DocConnectorHistoryComponent, SelectedLoanComponent],
  imports: [RouterModule.forChild(routes), CCMCSharedModule],
  exports: [DocConnectorHistoryComponent]
})
export class DocConnectorHistoryModule {}
