import { Component, OnInit, NgZone } from '@angular/core';
import { COMMA, ENTER, I, SPACE } from '@angular/cdk/keycodes';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { SalesforceService } from 'src/@ccmc/services/salesforce.service';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { WriteLoggingService } from 'src/@ccmc/services/write-logging.service';

export interface Asset {
  id: string;
}

@Component({
  selector: 'app-add-new-user',
  templateUrl: './add-new-user.component.html',
  styleUrls: ['./add-new-user.component.scss']
})
export class AddNewUserComponent implements OnInit {
  tmpUser: any;
  selectable = true;
  removable = true;
  addOnBlur = true;
  assets: Asset[] = [];
  isNXTsoft = false;
  isNXTsoftDev = false;
  isNXTsoftOperations = false;
  isNXTsoftSales = false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  /**
   * Creates an instance of AddNewUserComponent.
   * @param {CcmcApiService} ccmcAPIService
   * @param {AdminApiService} adminApiService
   * @param {SnackbarService} snackBarService
   * @memberof AddNewUserComponent
   */
  constructor(
    private ccmcAPIService: CcmcApiService,
    private adminApiService: AdminApiService,
    private snackBarService: SnackbarService,
    private amplifyService: AmplifyService,
    private salesforceService: SalesforceService,
    private assetService: AssetService,
    private writeLoggingService: WriteLoggingService
  ) { }

  /**
   * On Init
   *
   * @memberof AddNewUserComponent
   */
  ngOnInit(): void {
    this.isNXTsoft = this.amplifyService.isNXTsoft;
    this.isNXTsoftDev = this.amplifyService.isNXTsoftDev;
    this.isNXTsoftOperations = this.amplifyService.isNXTsoftOperations;
    this.isNXTsoftSales = this.amplifyService.isNXTsoftSales;
    this.tmpUser = {
      email: '',
      password: '',
      accountID: this.amplifyService.getAccountID(),
      assetID: ''
    };
  }

  /**
   * Create User
   * @description Craetes new user in cognito
   * @memberof AddNewUserComponent
   */
  createUser() {
    let tmpAsset = '';
    // Formats asset string
    for (let i = 0; i < this.assets.length; i++) {
      if (i === 0) {
        tmpAsset = this.assets[i].id;
      } else {
        tmpAsset = tmpAsset + ', ' + this.assets[i].id;
      }
    }
    // Set user assetID
    this.tmpUser.assetID = tmpAsset;
    console.log(this.tmpUser);
    // params sent
    const params = {
      email: this.tmpUser.email.toLowerCase(),
      password: this.tmpUser.password,
      accountID: this.tmpUser.accountID,
      assetID: this.tmpUser.assetID
    };
    const salesforceParams = {
      "contact": {
        "accountID": this.tmpUser.accountID,
        "contactEmail": this.tmpUser.email.toLowerCase(),
        "contactFirstName": this.tmpUser.contactFirstName,
        "contactID": "",
        "contactLastName": this.tmpUser.contactLastName,
        "contactPhone": "",
        "contactStatus": "Active",
        "contactTitle": "",
        "contactRole": ""
      },
      "type": "create"
    }
    this.adminApiService.createUser(params).subscribe(res => {
      const result = JSON.parse(JSON.stringify(res));
      console.log(result);
      if (result.errorMessage) {
        // Check if asset contains includes already exists
        if (result.errorMessage.includes('User account already exists')) {
          this.snackBarService.openSnackBar(
            'User account already exists',
            'Okay'
          );
        } else {
          this.snackBarService.openSnackBar('Error Creating User', 'Okay');
        }
      } else {
        this.snackBarService.openSnackBar(res.toString(), 'Okay');

        // Creating Log remove this and use commented below in the future
        const stream = this.assetService.getSelectedAssetId();
        this.writeLoggingService.setStream(stream);
        this.writeLoggingService
          .writeUserLog({
            username: this.amplifyService.username,
            action: 'User Created',
            time: new Date().toISOString(),
            log: `User: ${this.tmpUser.email.toLowerCase()} was created successfully.`
          })
          .subscribe(result => {
            // logging result
            console.log(result);
          });

        // // Creating Log
        // const accountID = this.amplifyService.getAccountID();
        // const stream = accountID;
        // this.writeLoggingService.setStream(stream);
        // this.writeLoggingService
        //   .writeUserLog({
        //     username: this.amplifyService.username,
        //     action: 'User Created',
        //     time: new Date().toISOString(),
        //     log: `User: ${this.tmpUser.email.toLowerCase()} was created successfully.`
        //   })
        //   .subscribe(result => {
        //     // logging result
        //     console.log(result);
        //   });

        // this.adminApiService
        //   .addUsertoGroup(params.email, 'loan-servicing-admin')
        //   .subscribe(result => {
        //     const parsedResult = JSON.parse(JSON.stringify(result));
        //     console.log(parsedResult);
        //   });
        this.adminApiService.callRefreshUsers();
      }
    });
    this.salesforceService.getContactID(this.tmpUser.email.toLowerCase()).subscribe(user => {
      console.log(user)
      if (user.StatusMessage.includes('not found')) {
        this.salesforceService.addNewContactInfo(salesforceParams).subscribe(res => {
          console.log(res)
        })
      }
    })

  }

  /**
   * Add
   * @description Adds chip to chip list
   * @param {MatChipInputEvent} event
   * @memberof AddNewUserComponent
   */
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our asset
    if ((value || '').trim()) {
      this.assets.push({ id: value.trim() });
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  /**
   * Remove
   * @description Remove chip from chip list
   * @param {Asset} asset
   * @memberof AddNewUserComponent
   */
  remove(asset: Asset): void {
    const index = this.assets.indexOf(asset);

    if (index >= 0) {
      this.assets.splice(index, 1);
    }
  }
}
