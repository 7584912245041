import { Injectable, NgZone } from '@angular/core';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CcmcApiService } from './ccmc-api.service';
import { CCMCCollateralSearchDialogComponent } from '../components/collateral-search-dialog/collateral-search-dialog.component';
import { Router } from '@angular/router';
import { navigation } from '../../app/navigation/navigation';
import { SpinnerService } from './spinner.service';
import { ActiveLosService } from './active-los.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, filter } from 'rxjs/operators';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { SuccessDialogComponent } from '../components/success-dialog/success-dialog.component';
import { AssetService } from './asset.service';
import { environment } from 'src/environments/environment';
import { CaptureDataService } from './capture-data.service';
import { AmplifyService } from './amplify.service';
import { GlobalSearchService } from './global-search.service';
import { TargetResponseDialogComponent } from '../components/target-response-dialog/target-response-dialog.component';
const { Buffer } = require('buffer');

@Injectable({
  providedIn: 'root'
})
export class IBSCodeConnectService {
  dynamicNav: any = navigation;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  /**
   * Creates an instance of IBSCodeConnectService.
   * @param {MatDialog} dialog
   * @param {CcmcApiService} ccmcApiService
   * @param {Router} router
   * @param {SpinnerService} spinnerService
   * @param {ActiveLosService} activeLosService
   * @param {NgZone} zone
   * @param {HttpClient} http
   * @param {CustomErrorHandlerService} customErrorHandlerService
   * @param {AssetService} assetService
   * @memberof IBSCodeConnectService
   */
  constructor(
    private dialog: MatDialog,
    private ccmcApiService: CcmcApiService,
    public router: Router,
    private spinnerService: SpinnerService,
    private activeLosService: ActiveLosService,
    private zone: NgZone,
    private http: HttpClient,
    private customErrorHandlerService: CustomErrorHandlerService,
    private assetService: AssetService,
    private amplifyService: AmplifyService,
    private captureDataService: CaptureDataService,
    private globalSearchService: GlobalSearchService
  ) {
    this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, PUT'
    );
    this.httpOptions.headers.append('Accept', 'application/json');
  }

  /**
   * Send
   * @description Sends the loan to the ibs communicator
   * @memberof IBSCodeConnectService
   */
  send() {
    this.spinnerService.setShowSpinner(true);
    let userName = this.amplifyService.username;
    const exportObject = {
      content: {
        mapping: this.ccmcApiService.mapping,
        configurations: this.ccmcApiService.configurations,
        transactions: this.ccmcApiService.transactions,
        cognitoID: this.amplifyService.getCognitoId()
      },
      user: userName,
      assetID: this.assetService.getSelectedAssetId()
    };
    this.export(exportObject).subscribe(async res => {
      console.log('export response: ', res);
      const parsedResult = JSON.parse(JSON.stringify(res));
      if (parsedResult.error) {
        const resultMessage = {
          statusMessage: "Loan booked but no response from communicator, confirm in logs",
          loanNumber: 'ibs-codeconnect',
          statusFlag: true
        };
        const dialogRef2 = this.zone.run(() => {
          this.dialog.open(TargetResponseDialogComponent, {
            data: resultMessage
          });
        });
        await this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: this.getLoanNumber(),
          statusMessage: parsedResult.error,
          statusFlag: false
        });
      } else if (parsedResult.statusFlag === true) {
        let loanNumber;
        if (parsedResult.loanNumber) {
          loanNumber = parsedResult.loanNumber;
        } else {
          loanNumber = this.getLoanNumber();
        }
        const resultMessage = {
          statusMessage: parsedResult.statusMessage,
          loanNumber: loanNumber,
          statusFlag: true
        };

        this.captureDataService.uploadS3File(this.ccmcApiService.mapping);
        this.activeLosService.activeLosService.loanBooked(
          parsedResult,
          JSON.parse(JSON.stringify(this.ccmcApiService.configurations))
        );
        console.log(parsedResult.content);
        if (
          parsedResult.content &&
          parsedResult.content.length > 2 &&
          !this.ccmcApiService.configurations.sendFile
        ) {
          const exportString = parsedResult.content;
          const blob = new Blob([exportString as BlobPart], {
            type: 'txt'
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${loanNumber}`;
          a.click();
        }

        await this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: loanNumber,
          statusMessage: parsedResult.statusMessage,
          statusFlag: parsedResult.statusFlag
        });

        this.ccmcApiService.mapping = undefined;
        this.ccmcApiService.coreSettings = undefined;
        this.ccmcApiService.conditions = undefined;
        this.ccmcApiService.mapping = undefined;
        this.ccmcApiService.supportValues = undefined;
        this.ccmcApiService.search = undefined;
        this.ccmcApiService.transactions = undefined;
        this.ccmcApiService.configurations = undefined;
        if (this.dynamicNav[1].children[1]) {
          this.dynamicNav[1].children[1].children = [];
        }
        this.dynamicNav[1].children[0].badge.title = undefined;
        const dialogRef = this.dialog.open(TargetResponseDialogComponent, {
          data: resultMessage
        });
        dialogRef.afterClosed().subscribe(result => {
          if (this.activeLosService.activeLos === 'encompass' || this.activeLosService.activeLos === 'bytepro') {
            this.router.navigate(['loan-lookup']);
          } else {
            this.router.navigate(['file-selector']);
          }
        });
      } else {
        if (parsedResult.content && parsedResult.content.mapping) {
          // Retrieve Validation From existing mapping object
          let existingMappingCopy = JSON.parse(
            JSON.stringify(this.ccmcApiService.mapping)
          );
          for (let mappingItem of parsedResult.content.mapping) {
            let existingMappingIndex = existingMappingCopy.findIndex(
              (obj: any) => obj.fieldID === mappingItem.fieldID
            );
            if (
              existingMappingIndex > -1 &&
              existingMappingCopy[existingMappingIndex].validation
            ) {
              mappingItem.validation =
                existingMappingCopy[existingMappingIndex].validation;
            }
          }
          this.ccmcApiService.mapping = parsedResult.content.mapping;
        }
        if (parsedResult.content && parsedResult.content.transactions) {
          this.ccmcApiService.transactions = parsedResult.content.transactions;
        }
        await this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: this.getLoanNumber(),
          statusMessage: parsedResult.statusMessage,
          statusFlag: parsedResult.statusFlag
        });
        this.globalSearchService.initData();
        let errorMessage: any;
        if (parsedResult.statusMessage === '') {
          errorMessage = {
            statusMessage: 'Loan booked but no response from communicator, confirm in logs',
            statusFlag: false
          };
        } else {
          errorMessage = {
            statusMessage: parsedResult.statusMessage,
            statusFlag: false
          };
        }
        const dialogRef = this.dialog.open(TargetResponseDialogComponent, {
          data: errorMessage
        });
      }
    });
  }

  /**
   * Customer Search
   * @description performs the customers search in the ibs communicator
   * @param {*} borrower
   * @param {*} callback
   * @memberof IBSCodeConnectService
   */
  customerSearch(borrower: any, callback: any) {
    let userName = this.amplifyService.username;
    const request = {
      content: borrower,
      assetID: this.assetService.getSelectedAssetId(),
      user: userName
    };
    console.log('Request: \n', JSON.stringify(request));
    request.content.cognitoID = this.amplifyService.getCognitoId();
    this.searchCustomer(request).subscribe(res => {
      console.log('Customer search response: ', res);
      const parsedResult = JSON.parse(JSON.stringify(res));
      console.log(parsedResult);
      if (parsedResult.error) {
        const errorMessage = {
          message: parsedResult.error.statusMessage + parsedResult.error.content,
          title: 'IBS Code Connect Result'
        };
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      } else if (parsedResult.statusFlag === true) {
        this.spinnerService.setShowSpinner(false);
        const payload = parsedResult.content;
        if (payload.length > 0) {
          const searchResult = payload;
          console.log(searchResult);
          callback(searchResult);
        } else {
          const noResult = [
            {
              firstName: 'No Result',
              lastName: '',
              taxID: '',
              CIF: '',
              address: ''
            }
          ];
          const searchResult = noResult;
          console.log(searchResult);
          callback(searchResult);
        }
      } else if (parsedResult.statusFlag === false) {
        console.log(parsedResult);
          const errorMessage = {
            message: parsedResult.statusMessage,
            title: 'Search Error'
          };

          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
        
      }
      this.spinnerService.setShowSpinner(false);
    });
  }

  /**
   * Assign Borrowers
   * @description set the field value to the customer name
   * @param {*} party
   * @memberof IBSCodeConnectService
   */
  assignBorrowers(party: any) {
    for (let i = 0; i < party.length; i++) {
      const CIFFields = party[i].CIFFields.split(',');
      CIFFields.forEach((cf: any) => {
        const cifIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) => f.fieldID === cf.trim()
        );
        this.ccmcApiService.mapping[cifIndex].fieldValue = party[i].CIF;
      });
    }
  }

  /**
   * Search Customer
   * @description calls the search customer api
   * @param {*} params
   * @returns
   * @memberof IBSCodeConnectService
   */
  searchCustomer(params: any) {
    console.log(params);
    return this.http
      .post(`${Buffer.from(environment.environmentURL, "base64").toString()}/fis/ibs-codeconnect/search-customer`, params, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * Export
   * @description calls the export api
   * @param {*} params
   * @returns
   * @memberof IBSCodeConnectService
   */
  export(params: any) {
    console.log(params);
    return this.http
      .post(`${Buffer.from(environment.environmentURL, "base64").toString()}/fis/ibs-codeconnect/export-messages`, params, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }
  /**
   * auto Customer Search
   * @description performs the auto customers search in the horizon communicator
   * @param {*} borrower
   * @param {*} callback
   * @memberof IBSCodeConnectService
   */
  autoCustomerSearch(borrower: any, callback: any) {
    let userName = this.amplifyService.username;
    const request = {
      content: borrower,
      assetID: this.assetService.getSelectedAssetId(),
      user: userName
    };
    console.log('Request: \n', JSON.stringify(request));
    request.content.cognitoID = this.amplifyService.getCognitoId();
    this.searchCustomer(request).subscribe(res => {
      console.log('Customer search response: ', res);
      const parsedResult = JSON.parse(JSON.stringify(res));
      console.log(parsedResult);
      if (parsedResult.error) {
        const errorMessage = {
          message: parsedResult.error.statusMessage + parsedResult.error.content,
          title: 'IBS Code Connect Result'
        };

        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      } else if (parsedResult.statusFlag === true) {
        this.spinnerService.setShowSpinner(false);
        const payload = parsedResult.content;
        if (payload.length > 0) {
          const searchResult = payload;
          console.log(searchResult);
          callback(searchResult);
        } else {
          const noResult = [
            {
              firstName: 'No Result',
              lastName: '',
              taxID: '',
              CIF: '',
              address: ''
            }
          ];
          const searchResult = noResult;
          console.log(searchResult);
          callback(searchResult);
        }
      } else if (parsedResult.statusFlag === false) {
        this.spinnerService.setShowSpinner(false);
        if (
          parsedResult.statusMessage
            .toLowerCase()
            .includes('no customer record found')
        ) {
          const noResult = [
            {
              firstName: 'No Result',
              lastName: '',
              taxID: '',
              CIF: '',
              address: ''
            }
          ];
          const searchResult = noResult;
          console.log(searchResult);
          callback(searchResult);
        } else {
          const errorMessage = {
            message: parsedResult.statusMessage,
            title: 'Search Error'
          };

          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
        }
      }
    });
  }

  getLoanNumber() {
    console.log('get Loan number');
    let loanFieldIndex = this.ccmcApiService.mapping.findIndex(
      (obj: any) => obj.fieldID === 'LN01_30051'
    );
    if (loanFieldIndex > -1) {
      // Return loan number
      console.log(
        'loan number',
        this.ccmcApiService.mapping[loanFieldIndex].fieldValue
      );
      return this.ccmcApiService.mapping[loanFieldIndex].fieldValue;
    }
  }
}
