<div class="gcsettings">
  <h1>Ground Control Settings</h1>
  <form [formGroup]="settingsForm">
    <div class="gcsettings__form">
      <mat-form-field>
        <input matInput placeholder="AssetId" type="text" formControlName="assetId" name="assetId" readonly>
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="Product" type="text" formControlName="product" name="product" readonly>
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="LOS" type="text" formControlName="los" name="los" readonly>
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="Core" type="text" formControlName="core" name="core" readonly>
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="Path" type="text" formControlName="loanPath" name="loanPath" readonly>
        <!-- <input matInput placeholder="Path" type="text" formControlName="loanPath" name="loanPath"> -->
      </mat-form-field>
    </div>

    <!-- <button mat-raised-button color="accent" class="form-button" type="submit" aria-label="Modify GC Settings" (click)="changeSettings()">Modify Settings</button> -->
  </form>
</div>