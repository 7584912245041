import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldOptionsComponent } from './field-options.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { CCMCSharedModule } from 'src/@ccmc/shared.module';
import { FieldOptionsConfigComponent } from './field-options-config/field-options-config.component';
import { Role } from 'src/@ccmc/models/role';

const routes: Routes = [
  {
    path: 'field-options',
    component: FieldOptionsComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: {
      roles: [
        Role.ClientAdmin,
        Role.NxtsoftSales,
        Role.NxtsoftOp,
        Role.NxtsoftDev
      ]
    }
  }
];

@NgModule({
  declarations: [FieldOptionsComponent, FieldOptionsConfigComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CCMCSharedModule,
    KeyboardShortcutsModule.forRoot()
  ]
})
export class FieldOptionsModule {}
