<div class="source-child-page">
    <div class="filepath-container">
        <p class="headerText"> Destination &nbsp;/</p>
        <p class="sub-headerText">&nbsp;name</p>
    </div>
    <div style="background-color: #ffffff;">
        <mat-form-field class="filter-FormField">
            <input matInput placeholder="Filter Search" #filterSearch />
        </mat-form-field>

    </div>

    <div class="table-container">
        <table mat-table class="table-TableContainer" #table [dataSource]="dataArray" CCMCPerfectScrollbar matSort>
            <!-- Field ID Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 348px; max-width: 348px">
                    Name
                </th>
                <td mat-cell *matCellDef="let element" class="noselect" style="min-width: 175px; max-width: 175px">
                    {{element.name}}
                </td>
            </ng-container>

            <ng-container matColumnDef="index">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 400px; max-width: 400px;">
                    Index
                </th>

                <td mat-cell *matCellDef="let element" class="noselect" style="min-width: 400px; max-width: 400px;">
                    {{element.index}}
                </td>
            </ng-container>

            <ng-container matColumnDef="vbScript">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 400px; max-width: 400px;">
                    VBScript
                </th>

                <td mat-cell *matCellDef="let element" class="noselect" style="min-width: 400px; max-width: 400px;">
                    {{element.vbScript}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onSelect(row)" [ngClass]="{ 'accent-bg': row == selected }"></tr>
        </table>
    </div>
    <div class="paginatorContainer">
        <mat-paginator [pageSizeOptions]="[100]" showFirstLastButtons class="paginator">
        </mat-paginator>
    </div>
    <div class="source-container">
        <table mat-table class="source-TableContainer" #table [dataSource]="dataArray2" CCMCPerfectScrollbar matSort>
            <ng-container matColumnDef="sources">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 400px; max-width: 400px; font-size: 20px; font-weight:500;">
                    Sources
                </th>

                <td mat-cell *matCellDef="let element" class="noselect">
                    {{element.sources}}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="sourceCol; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: sourceCol" (click)="onSelect(row)" [ngClass]="{ 'accent-bg': row == selected }"></tr>
        </table>
    </div>
    <div style="margin-top:-24%; margin-left: 37%;">
        <h2 class="header-style">Configurations</h2>
        <div class="Btn-container">
            <button mat-raised-button matTooltip="Saves any changes you make, within this section." matTooltipClass="toolTipStyle" color="accent" class="saveBtn">
      Save
    </button>
        </div>
        <div style="margin-top:4%">
            <mat-form-field class="form-field-id">
                <input matInput placeholder="ID" />
            </mat-form-field>
            <mat-form-field class="form-field-joinimages">
                <input matInput placeholder="Join Images" />
            </mat-form-field>
            <mat-form-field class="form-field-separatepage">
                <input matInput placeholder="Separate Page" />
            </mat-form-field>
            <mat-form-field class="form-field-order">
                <input type="number" matInput placeholder="Order Indes" />
            </mat-form-field>
            <mat-form-field class="form-field-newfilename">
                <input type="number" matInput placeholder="New File Name" />
            </mat-form-field>
        </div>
    </div>
</div>