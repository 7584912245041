<div class="findReplaceContainer">
  <div
    style="
      margin: -24px -24px 0px -24px !important;
      cursor: move;
      min-height: 15px;
    "
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  ></div>
  <div
    style="display: flex; flex-direction: row; justify-content: space-between"
  >
    <h1>Find and Replace</h1>
    <h1 *ngIf="total > 0">{{ index + 1 }} / {{ total }}</h1>

    <h1>
      <i class="material-icons noselect" (click)="closeDialog()"> close </i>
    </h1>
  </div>
  <div class="fieldContainer">
    <mat-form-field class="field1">
      <mat-label>Field</mat-label>
      <mat-select
        [(value)]="selectedFilter"
        (selectionChange)="changeFilterPredicate(selectedFilter)"
      >
        <mat-option *ngFor="let key of findObjKeys" [value]="key">{{
          key
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="field1">
      <input
        matInput
        #findInput
        placeholder="Find"
        [(ngModel)]="findStr"
        (keyup.enter)="applyFilter(findStr)"
      />
    </mat-form-field>
    <mat-form-field class="field1">
      <input matInput placeholder="Replace" [(ngModel)]="replaceStr" />
    </mat-form-field>

    <div class="findBtn">
      <button
        style="padding-right: 20px"
        mat-raised-button
        color="primary"
        (click)="applyFilter(findStr)"
        [disabled]="!selectedFilter || !findStr"
      >
        <mat-icon>search</mat-icon>&nbsp;Find
      </button>
    </div>
  </div>

  <div class="midBtnContainer">
    <button
      class="prevBtn"
      mat-stroked-button
      (click)="getPrevItem()"
      [disabled]="!currentItem || index === 0"
    >
      <mat-icon>arrow_left</mat-icon>&nbsp;Prev&nbsp;
    </button>
    <mat-checkbox [(ngModel)]="exactMatch">Exact Match</mat-checkbox>
    <button
      color="primary"
      mat-raised-button
      (click)="replaceItem()"
      [disabled]="!replaceStr"
    >
      Replace
    </button>
    <button
      color="primary"
      mat-raised-button
      (click)="replaceAllItems()"
      [disabled]="!replaceStr"
    >
      Replace All
    </button>
    <button
      class="nextBtn"
      mat-stroked-button
      (click)="getNextItem()"
      [disabled]="!currentItem"
    >
      &nbsp;Next&nbsp; <mat-icon>arrow_right </mat-icon>
    </button>
  </div>
  <div class="findReplaceScript">
    <ngx-codemirror
      #codemirror
      [(ngModel)]="currentItem"
      [options]="codemirrorConfig"
    >
    </ngx-codemirror>
  </div>
</div>
