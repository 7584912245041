<div style="position: relative">
  <h1>Edit Snippet</h1>
  <div>
    <div>
      <mat-form-field style="width: 100%">
        <input
          matInput
          placeholder="Title"
          [(ngModel)]="snippet.title"
          (ngModelChange)="fieldEdited()"
        />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="width: 100%">
        <textarea
          matInput
          placeholder="Description"
          [(ngModel)]="snippet.description"
          (ngModelChange)="fieldEdited()"
        ></textarea>
      </mat-form-field>
    </div>
    <div class="CodeMirrorMappingContainer formContainer6">
      <ngx-codemirror
        #codemirror
        [(ngModel)]="snippet.snippet"
        [options]="codemirrorConfig"
      >
      </ngx-codemirror>
    </div>
  </div>
  <mat-action-row>
    <button mat-button color="primary" (click)="closeDialog()">Cancel</button>
    <button
      mat-button
      color="accent"
      [disabled]="!editedFlag"
      (click)="saveSnippet()"
    >
      Save
    </button>
  </mat-action-row>
</div>
