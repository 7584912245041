<!--Title-->
<h3 class="title">Field Information</h3>
<div class="fieldDialogBox">

    <!--Field Content-->
    <div class="content-container">
        <div mat-dialog-content>Field Description: {{data.fieldDesc}}</div>
        <div mat-dialog-content>Field Type: {{data.fieldType}}</div>
        <div mat-dialog-content>Field Length: {{data.fieldWidth}}</div>
    </div>

    <!--Button-->
    <div mat-dialog-actions class="pt-24" class="button-container">
        <button mat-raised-button class="field-btn" color="primary" (click)="dialogRef.close()">OK</button>
    </div>
</div>