
<h1>Select Spectrum Client Credentials</h1>
<div class="spectrumDialog-container">
  <div>
    <mat-form-field style="width: 100%">
      <mat-label>Spectrum Credentials</mat-label>
              <mat-select  [(ngModel)]="selectedRow" (ngModelChange)="checkPremCreds()">
                  <mat-option *ngFor="let fiservUserItem of finalSpectrumTable" [value]="fiservUserItem">{{fiservUserItem.tellerID + ' - ' + fiservUserItem.username}}</mat-option>
              </mat-select>
    </mat-form-field>
  </div>
</div>
<div id="bottom-button-container">
  <!--Cancel Button-->
  <button
    mat-stroked-button
    class="cancel-btn"
    (click)="closeDialog()"
    id="cancel-button"
  >
    Close
  </button>

  <button
    mat-stroked-button
    (click)="openSpectrumCredentialsDialog()"
    id="newuser-button"
    class="newuser-btn"
  >
    New User
  </button>

  <!--Continue Button-->
  <div style="text-align: right">
    <button
      mat-raised-button
      class="continue-btn"
      id="continue-button"
      [disabled]="editedFlag === false"
      (click)="continue()"
    >
      <span class="button-text">OK &nbsp;</span>
    </button>
  </div>
</div>

