<div style="position: relative">
  <h1 style="color: white">Premier Search Value</h1>
  <p style="color: white">Search returned no results.</p>
  <p style="color: white">Please modify search parameters if necessary.</p>
  <div
    style="
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      row-gap: 15px;
      column-gap: 15px;
      background-color: #f8f8f8;
      border-radius: 5px;
      padding: 15px;
      margin-bottom: 10px;
    "
  >
    <div>
      <mat-form-field class="form-field-two">
        <input
          matInput
          placeholder="First Name"
          [(ngModel)]="borrower.firstName"
          (keydown.enter)="search()"
          (ngModelChange)="checkForm()"
        />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="form-field-two">
        <input
          matInput
          placeholder="Middle Name"
          [(ngModel)]="borrower.middleName"
          (keydown.enter)="search()"
          (ngModelChange)="checkForm()"
        />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="form-field-two">
        <input
          matInput
          placeholder="Last Name"
          [(ngModel)]="borrower.lastName"
          (keydown.enter)="search()"
          (ngModelChange)="checkForm()"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="continue-btn" style="text-align: right">
    <!--Cancel Button-->
    <button
      mat-stroked-button
      class="cancel-btn"
      (click)="closeDialog()"
      id="cancel-button"
    >
      Cancel
    </button>

    <!--Continue Button-->
    <button
      mat-raised-button
      (click)="search()"
      class="search-button"
      [disabled]="!canSend"
    >
      <mat-icon class="icon-style">person</mat-icon>
      <span class="button-text">&nbsp;Search</span>
    </button>
  </div>

  <!--Spinner-->
  <div
    style="
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    "
  >
    <mat-spinner *ngIf="showSpinner"></mat-spinner>
  </div>
</div>
