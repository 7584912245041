import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { APPCONSTANTS } from 'src/app/app.constants';
import { environment } from 'src/environments/environment';
import { AdminApiService } from './admin-api.service';
import { AmplifyService } from './amplify.service';
import { AssetService } from './asset.service';
import { CcmcApiService } from './ccmc-api.service';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { CCMCSelectedFieldService } from './selected-field.service';
import * as AWS from 'aws-sdk';
const { Buffer } = require('buffer');
@Injectable({
  providedIn: 'root'
})
export class DocumentConnectorService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  baseUrl: any;
  documentBackup: any;
  private _dcDocument: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public sendButtonFlag: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  constructor(
    private assetService: AssetService,
    private http: HttpClient,
    private customErrorHandlerService: CustomErrorHandlerService,
    private selectedFieldService: CCMCSelectedFieldService,
    private ccmcApiService: CcmcApiService,
    private adminApiService: AdminApiService,
    private amplifyService: AmplifyService
  ) {
    this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, PUT'
    );
    this.httpOptions.headers.append('Accept', 'application/json');
  }

  get dcDocument() {
    return this._dcDocument.asObservable().pipe(
      map(document => {
        return document;
      })
    );
  }

  setDocumentConnectorDocument(dcDocument: any) {
    if (dcDocument.globalMetas) {
      this.setGlobalMetasEditedFlags(dcDocument.globalMetas);
    }
    if (dcDocument.destinations) {
      this.setDestinationsEditedFlags(dcDocument.destinations);
    }
    this.documentBackup = JSON.parse(JSON.stringify(dcDocument));
    this._dcDocument.next(dcDocument);
  }

  setDcDocumentSimple(dcDocument: any) {
    if (dcDocument.globalMetas) {
      this.selectedFieldService.onGlobalMetasFieldSelected.next(
        dcDocument.globalMetas[0]
      );
    }
    this._dcDocument.next(dcDocument);
  }

  setGlobalMetasEditedFlags(globalMetas: any) {
    for (let globalMetaItem of globalMetas) {
      globalMetaItem.edited = false;
    }
  }

  setDestinationsEditedFlags(destinations: any) {
    for (let destination of destinations) {
      destination.edited = false;
    }
  }

  ice(loan: any) {
    const assetID = this.assetService.getSelectedAssetId();
    const body = JSON.stringify({
      assetID: assetID,
      content: {
        data: loan
      }
    });
    return this.http
      .post(`${Buffer.from(environment.environmentURL, "base64").toString()}/ice/data-conversion-gla`, body, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }



  async getDocConnectorHistory(assetID: any) {
    console.log('assetID', assetID);
    return new Promise(resolve => {
      this.ccmcApiService
        .getDynamoDBItemsByIndex(
          'encompass_document_connector_history',
          assetID,
          'assetID'
        )
        .subscribe(dcHistoryDocument => {
          console.log(dcHistoryDocument);
          const parsedDCDocumentResult = JSON.parse(
            JSON.stringify(dcHistoryDocument)
          );
          return resolve(parsedDCDocumentResult);
        });
    });
  }

  async getMoreDocConnectorHistory(assetID: any, lastEvaluatedKey: any) {
    return new Promise(resolve => {
      this.ccmcApiService
        .getDynamoDBItemsByIndexWithKey(
          'encompass_document_connector_history',
          assetID,
          'assetID',
          lastEvaluatedKey
        )
        .subscribe(dcHistoryDocument => {
          const parsedDCDocumentResult = JSON.parse(
            JSON.stringify(dcHistoryDocument)
          );
          return resolve(parsedDCDocumentResult);
        });
    });
  }

  createDocument(losConnectionData: any) {
    console.log('los Connection Data', losConnectionData);
    const assetID = this.assetService.getSelectedAssetId();
    const currentDoc = this._dcDocument.getValue();
    this.documentBackup = JSON.parse(JSON.stringify(currentDoc));
    let tempDocumentArray: any[] = [];
    currentDoc.destinations.forEach(function (item: any) {
      const tempItem = Object.assign({}, item);
      delete tempItem.edited;
      tempDocumentArray.push(tempItem);
    });
    currentDoc.destinations = tempDocumentArray;
    tempDocumentArray = [];
    currentDoc.globalMetas.forEach(function (item: any) {
      const tempItem = Object.assign({}, item);
      delete tempItem.edited;
      tempDocumentArray.push(tempItem);
    });
    currentDoc.globalMetas = tempDocumentArray;
    console.log('Current Doc', currentDoc);
    const body = {
      cluster: losConnectionData.couchbaseConnectionString,
      assetID: assetID,
      bucket: losConnectionData.bucket,
      document: { ...currentDoc }
    };
    console.log(body);
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/couchbase/create-document`,
        body,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  async sendToICE(docConnectorRequest: any) {
    return ( this.http
      .post(`${Buffer.from(environment.environmentURL, "base64").toString()}/ice/doc-connector`, docConnectorRequest, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError))
    );
  }

async sendToDocListener(docConnectorRequest: any) {
    console.log(docConnectorRequest);
      return  ( this.http
      .post(`${Buffer.from(environment.environmentURL, "base64").toString()}/encompass/doc-connector-listener`, docConnectorRequest, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError))
      );
    
  }
}
