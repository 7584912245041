import { Injectable } from '@angular/core';
import { CcmcApiService } from './ccmc-api.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { environment } from 'src/environments/environment';
import { AssetService } from './asset.service';
import { AmplifyService } from './amplify.service';

@Injectable({
  providedIn: 'root'
})
export class CalyxService {
  HTTP_OPTIONS = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'authorization': ''
  }
  currentLoanKey: string;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(
    private ccmcApiService: CcmcApiService,
    private http: HttpClient,
    private customErrorHandlerService: CustomErrorHandlerService,
    private assetService: AssetService,
    private amplifyService: AmplifyService
  ) {
    this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, PUT'
    );
    this.httpOptions.headers.append('Accept', 'application/json');
  }

  /**
   * Retrieve Files
   * @description displays and sorts the loans, updates the badge count
   * @param {*} callback
   * @memberof CalyxService
   */
  retrieveFiles(callback: any) {
    this.ccmcApiService
      .s3ListFiles('calyx-loans')
      .subscribe((res: any) => {
        if (res) {
          return callback.handleRetrievedFiles(null, res.body);
        }
        const errorMessage = {
          message: 'No files found.',
          title: 'No Results'
        };
        return callback.handleRetrievedFiles(errorMessage, null);
      });
  }

  /**
   * Loan Selected
   * @description gets the loan infomration and returns the populated core mapping
   * @param {*} key
   * @param {*} callback
   * @memberof CalyxService
   */
  loanSelected(key: any, callback: any) {
    this.currentLoanKey = key;
    this.ccmcApiService
      .s3GetFile('calyx-loans', key)
      .subscribe((res: any) => {
        if (res.statusFlag) {
          console.log('s3getFile result ', res);
          this.ccmcApiService.ice(res.content).subscribe(
            (response: any) => {
              console.log(response);
              if (response.statusFlag === true) {
                return callback.handleCoreMappingResponse(null, response);
              } else if (response.statusMessage) {
                const errorMessage = {
                  message: response.statusMessage,
                  title: 'Ice Error'
                };
                return callback.handleCoreMappingResponse(errorMessage, null);
              } else {
                const errorMessage = {
                  message: response.errorMessage,
                  title: 'Ice Error'
                };
                return callback.handleCoreMappingResponse(errorMessage, null);
              }
            },
            error => {
              console.log(error);
              const errorMessage = {
                message: error,
                title: 'Ice Error'
              };
              return callback.handleCoreMappingResponse(errorMessage, null);
            }
          );
        } else {
          const errorMessage = {
            message: 'Error Retrieving Loan',
            title: 'Loan Error'
          };
          return callback.handleCoreMappingResponse(errorMessage, null);
        }
      });
  }

  /**
   * Loan Booked
   * @description Defines what is done to the LOS after the loan has been booked
   * This is a required function, leave empty if nothing is required for the LOS
   * @param {*} result
   * @memberof CalyxService
   */
  loanBooked(result: any, configurations: any) {
    if (!configurations.demoMode && !configurations.implementationMode) {
      // Record Sender
      this.ccmcApiService.recordSender().subscribe(result => {
        console.log(result);
      });
      if (environment.production) {
        // deletes the loan from S3
        this.ccmcApiService
          .s3DeleteFile('calyx-loans', this.currentLoanKey, 'loan-booked')
          .subscribe(response => console.log('response ', response));
      }
    }
  }

  async deleteLoan(loanPath: any) {
    return new Promise(resolve => {
      this.ccmcApiService
        .s3DeleteFile('calyx-loans', loanPath, 'user-deletion')
        .subscribe(response => {
          console.log('response ', response);
          resolve(response);
        });
    });
  }

  retrieveInvokeEnvironment(url: string) {
    if (url.includes('dev')) {
      return 'test-';
    } else if (url.includes('test')) {
      return '';
    } else {
      return '';
    }
  }
}
