<h1>Pledge Value</h1>
<div class="pledge-value-container">
  <div
    style="
      border-bottom: 1px solid #aaaaaa;
      margin-bottom: 10px;
      padding-bottom: 5px;
      font-size: large;
    "
  >
    <h2>Collateral</h2>
    <div
      style="display: grid; grid-template-rows: auto; row-gap: 0px; width: 80%"
    >
      <div style="display: grid; grid-template-columns: 1fr 1fr">
        <div>Collateral:</div>
        <div>
          {{ selectedCollateral.name }}
        </div>
      </div>
      <div style="display: grid; grid-template-columns: 1fr 1fr">
        <div>Collateral Type:</div>
        <div>
          {{ selectedCollateral.type }}
        </div>
      </div>
      <div style="display: grid; grid-template-columns: 1fr 1fr">
        <div>Collateral Status:</div>
        <div>
          {{ selectedCollateral.status }}
        </div>
      </div>
      <div style="display: grid; grid-template-columns: 1fr 1fr">
        <div>Assigned Party:</div>
        <div>
          {{ selectedCollateral.assignedParty }}
        </div>
      </div>
    </div>
  </div>
  <div>
    <h2>Pledge Type</h2>
    <mat-radio-group
      class="pledge-type-radio-group"
      [(ngModel)]="selectedCollateral.pledgeType"
      (change)="focusInput($event)"
    >
      <mat-radio-button class="color-radio-button" value="fixed">
        Pledge Fixed Amount.
      </mat-radio-button>
      <mat-radio-button class="color-radio-button" value="percent">
        Pledge Percent.
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div>
    <h2>Pledge Value</h2>
    <div
      style="display: grid; grid-template-columns: 1fr 1fr; column-gap: 25px"
    >
      <mat-form-field class="form-field-two">
        <input
          matInput
          [disabled]="selectedCollateral.pledgeType !== 'fixed'"
          placeholder="00.00"
          placeholder="Fixed Amount"
          [(ngModel)]="selectedCollateral.dollarOfValue"
          #fixedAmountInput
        />
      </mat-form-field>
      <mat-form-field class="form-field-two">
        <input
          matInput
          [disabled]="selectedCollateral.pledgeType !== 'percent'"
          type="number"
          placeholder="Percent Amount"
          [(ngModel)]="selectedCollateral.percentOfValue"
          #percentAmountInput
        />
      </mat-form-field>
    </div>
  </div>
  <div>
    <div>
      <button
        class="previous-button"
        style="margin-right: 10px"
        [disabled]="prevBtnDisabled"
        [ngClass]="{ disabled: prevBtnDisabled }"
        (click)="previous()"
        id="previous-button"
        mat-raised-button
      >
        <mat-icon>arrow_left</mat-icon>Previous
      </button>
      <button
        class="next-button"
        [disabled]="nextBtnDisabled"
        [ngClass]="{ disabled: nextBtnDisabled }"
        (click)="next()"
        id="next-button"
        mat-raised-button
      >
        Next<mat-icon> arrow_right</mat-icon>
      </button>
    </div>
  </div>
  <div style="display: grid; grid-template-columns: 1fr 1fr; height: 50px">
    <!--Cancel Button-->
    <button
      mat-stroked-button
      style="margin-left: 0; margin-right: auto"
      class="cancel-btn"
      (click)="closeDialog()"
      id="cancel-button"
    >
      Cancel
    </button>

    <!--Continue Button-->
    <button
      mat-raised-button
      style="margin-left: auto; margin-right: 0"
      class="continue-btn"
      [ngClass]="{ disabled: !checkContinue() }"
      [disabled]="!checkContinue()"
      (click)="continue()"
      id="continue-button"
    >
      <span class="button-text">Continue &nbsp;</span>
      <mat-icon class="continue-icon">forward </mat-icon>
    </button>
  </div>
</div>
