<div class="search">
  <div>
    <h1 style="color: black">Account Select</h1>
  </div>
  <mat-table
    class="account-table"
    #searchTable
    [dataSource]="accountSearchDataSource"
  >
    <ng-container matColumnDef="accountNumber">
      <mat-header-cell *matHeaderCellDef class="cell-text-styling"
        >Account Number</mat-header-cell
      >
      <mat-cell *matCellDef="let element">{{ element.accountNumber }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="serial">
      <mat-header-cell *matHeaderCellDef class="cell-text-styling"
        >Serial</mat-header-cell
      >
      <mat-cell *matCellDef="let element">{{ element.serial }}</mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="diplayedAccountColumns; sticky: true"
    ></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: diplayedAccountColumns"
      [style.background]="selectedRow === row ? '#3FB2C6' : ''"
      (click)="onSelectAccount(row)"
    ></mat-row>
  </mat-table>
  <!--botton row of buttons-->
  <div id="bottom-button-container">
    <!--Cancel Button-->
    <button
      mat-stroked-button
      class="cancel-btn"
      (click)="closeDialog()"
      id="cancel-button"
    >
      Cancel
    </button>

    <!--Continue Button-->
    <div style="text-align: right">
      <button
        mat-raised-button
        class="continue-btn"
        (click)="continue()"
        id="continue-button"
        matTooltip="Assign Account Number"
        matTooltipClass="tooltip-color"
      >
        <span class="button-text">Continue &nbsp;</span>
        <mat-icon class="continue-icon">forward </mat-icon>
      </button>
    </div>
  </div>

  <!--Spinner-->
  <div class="spinner-container" *ngIf="showSpinner">
    <div class="new-spinner">
      <div class="outer-ring"></div>
      <div class="middle-ring"></div>
      <div class="inner-ring"></div>
    </div>
  </div>
</div>
