import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
import { WriteLoggingService } from 'src/@ccmc/services/write-logging.service';

@Component({
  selector: 'app-target-response-dialog',
  templateUrl: './target-response-dialog.component.html',
  styleUrls: ['./target-response-dialog.component.scss']
})
export class TargetResponseDialogComponent implements OnInit {
  currentTarget: any;
  title = '';
  message = '';
  constructor(
    public dialogRef: MatDialogRef<TargetResponseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public targetResponseMessage: any,
    private assetService: AssetService,
    private spinnerService: SpinnerService,
    private writeLoggingService: WriteLoggingService,
    private amplifyService: AmplifyService
  ) {
    this.spinnerService.setShowSpinner(false);
    console.log(targetResponseMessage);
  }

  ngOnInit(): void {
    this.currentTarget = this.getCurrentTarget();
    this.buildTitle();
    this.buildMessage();
  }

  getCurrentTarget() {
    let currentTarget = this.assetService.getSelectedCore();
    if (currentTarget === 'synergent-symitar') {
      currentTarget = 'Symxchange';
    }
    return currentTarget;
  }

  buildTitle() {
    if (
      this.assetService.getSelectedAssetId().toLowerCase().substring(0, 5) ===
      'sales'
    ) {
      this.title = 'Generic Response';
    } else {
      this.title = `${this.currentTarget} `;
      if (this.targetResponseMessage.statusFlag) {
        this.title = this.title + 'Response';
      } else {
        this.title = this.title + 'Error';
      }
    }
  }

  buildMessage() {
    if (this.targetResponseMessage.statusFlag && this.targetResponseMessage.loanNumber && this.targetResponseMessage.loanNumber === 'ibs') {
      // Handles ibs booking, with no return message
      this.message = this.targetResponseMessage.statusMessage;
      const assetID = this.assetService.getSelectedAssetId();
      const stream = assetID;
      this.writeLoggingService.setStream(stream);
      this.writeLoggingService
        .writeLog({
          username: this.amplifyService.username,
          action: 'Loan Booked',
          time: new Date().toISOString(),
          log: `IBS loan booked but no response from communicator.`
        })
        .subscribe(result => {
          // logging result
          console.log(result);
        });
    } else if (this.targetResponseMessage.statusFlag) {
      this.message = `Loan: ${this.targetResponseMessage.loanNumber} has been booked successfully.`;
      const assetID = this.assetService.getSelectedAssetId();
      const stream = assetID;
      this.writeLoggingService.setStream(stream);
      this.writeLoggingService
        .writeLog({
          username: this.amplifyService.username,
          action: 'Loan Booked',
          time: new Date().toISOString(),
          log: `Loan: ${this.targetResponseMessage.loanNumber} has been booked successfully.`
        })
        .subscribe(result => {
          // logging result
          console.log(result);
        });
    } else {
      if (this.targetResponseMessage.statusMessage === '' || this.targetResponseMessage.statusMessage === undefined) {
        this.message = 'Status not returned from backend.'
      } else {
        this.message = this.targetResponseMessage.statusMessage;
      }
    }
  }
}
