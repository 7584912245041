<div
  style="background-color: #ececec; height: 100%; overflow: hidden;"
>
  <div class="filter-container">
    <mat-form-field
      class="filter-FormField"
      style="width: 55%; margin-left: 2%"
    >
      <input
        matInput
        style="background-color: inherit"
        [(ngModel)]="extraFilter"
        placeholder="Filter Search"
        #filterSearch
      />
    </mat-form-field>
    <mat-form-field style="width: 20%; margin-left: 2%">
      <mat-label>Filter Options</mat-label>
      <mat-select [(ngModel)]="selectedFilter">
        <mat-option value="all" (click)="changeFilterPredicate('all')"
          >Filter All</mat-option
        >
        <mat-option
          value="loanNumber"
          (click)="changeFilterPredicate('loanNumber')"
          >Loan Number</mat-option
        >
        <mat-option value="fieldID" (click)="changeFilterPredicate('fieldID')"
          >FieldID</mat-option
        >
        <mat-option
          value="fieldDisplay"
          (click)="changeFilterPredicate('fieldDisplay')"
          >Field Display</mat-option
        >
        <mat-option
          value="currentValue"
          (click)="changeFilterPredicate('currentValue')"
          >Current Value</mat-option
        >
        <mat-option
          value="correctedValue"
          (click)="changeFilterPredicate('correctedValue')"
          >Corrected Value</mat-option
        >
        <mat-option value="urgency" (click)="changeFilterPredicate('urgency')"
          >Urgency</mat-option
        >
      </mat-select>
    </mat-form-field>
    <button
      *ngIf="isNXTsoftDev || isNXTsoftOp"
      mat-raised-button
      style="margin-right: 0.5%"
      class="titleSave"
      (click)="createNewStatus()"
    >
      New Status
    </button>
    <section style="display: initial" *ngIf="fromLocation != 'dropdown'">
      <mat-checkbox
        style="padding-left: 3%"
        [(ngModel)]="showOnlyLoan"
        (change)="
          resetTables();
          checkboxTrigger();
          getChangeList();
          turnOffSpinner();
          selectTopOption()
        "
        name="showAllCheckBox"
        >View All Loans</mat-checkbox
      >
    </section>
  </div>
  <div class="users-container">
    <div #container class="table-container">
      <table
        class="table"
        mat-table
        matSort
        #table
        [dataSource]="dataSourceChangePage"
        CCMCPerfectScrollbar
      >
        <ng-container matColumnDef="loanNumber">
          <div *ngIf="isOrigenate == 'origenate'; else elseBlock">
            <th
              class="header-text"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
            >
              Application Number
            </th>
          </div>
          <ng-template #elseBlock>
            <th
              class="header-text"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
            >
              Loan Number
            </th></ng-template
          >
          <td mat-cell *matCellDef="let element" class="noselect">
            <div *ngIf="element.loanNumber == ''">Applicable to All Loans</div>
            <div *ngIf="element.loanNumber != ''">{{ element.loanNumber }}</div>
          </td>
        </ng-container>
        <ng-container matColumnDef="fieldID">
          <th mat-sort-header mat-header-cell *matHeaderCellDef class="thcss">
            FieldID
          </th>
          <td mat-cell *matCellDef="let element" class="noselect">
            {{ element.fieldID }}
          </td>
        </ng-container>

        <ng-container matColumnDef="fieldDisplay">
          <th mat-sort-header mat-header-cell *matHeaderCellDef class="thcss">
            Field Display
          </th>
          <td mat-cell *matCellDef="let element" class="noselect">
            {{ element.fieldDisplay }}
          </td>
        </ng-container>

        <ng-container matColumnDef="openedDate">
          <th mat-sort-header mat-header-cell *matHeaderCellDef class="thcss">
            Opened On
          </th>
          <td mat-cell *matCellDef="let element" class="noselect">
            {{ element.dateCreated }}
          </td>
        </ng-container>

        <ng-container
          matColumnDef="currentValue"
          *ngIf="fromLocation == 'dropdown'"
        >
          <th mat-sort-header mat-header-cell *matHeaderCellDef class="thcss">
            Old Value
          </th>
          <td mat-cell *matCellDef="let element" class="noselect">
            {{ element.currentValue }}
          </td>
        </ng-container>

        <ng-container matColumnDef="correctedValue">
          <th mat-sort-header mat-header-cell *matHeaderCellDef class="thcss">
            Corrected Value
            <div class="AddBtn-Container" *ngIf="fromLocation == 'dropdown'">
              <button
                class="Add-btn"
                matTooltip="Add New Field"
                matTooltipClass="toolTipStyle"
                (click)="newChangeRequest($event)"
              >
                <mat-icon style="color: #3fb2c6">add_circle</mat-icon>
              </button>
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class="noselect">
            {{ element.correctedValue }}
          </td>
        </ng-container>

        <ng-container
          matColumnDef="linkedValue"
          *ngIf="fromLocation != 'dropdown'"
        >
          <th mat-sort-header mat-header-cell *matHeaderCellDef class="thcss">
            Current Value
            <div class="AddBtn-Container">
              <button
                class="Add-btn"
                matTooltip="Add New Field"
                matTooltipClass="toolTipStyle"
                (click)="newChangeRequest($event)"
              >
                <mat-icon style="color: #3fb2c6">add_circle</mat-icon>
              </button>
            </div>
          </th>

          <td mat-cell *matCellDef="let element" class="noselect">
            {{ element.linkedValue }}
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="getDisplayedColumns(); sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: getDisplayedColumns()"
          (click)="onSelects($event, row)"
          [ngClass]="{ 'accent-50-bg': row == selected }"
        ></tr>
      </table>
    </div>
  </div>
</div>
<!--paginator-->
<div class="paginatorContainer">
  <mat-paginator
    [pageSizeOptions]="[100]"
    showFirstLastButtons
    class="paginator-conditions"
    (click)="container.scrollTop = 0"
  >
  </mat-paginator>
</div>

<!--Spinner-->
<div class="spinner-container" *ngIf="showSpinner">
  <div class="new-spinner">
    <div class="outer-ring"></div>
    <div class="middle-ring"></div>
    <div class="inner-ring"></div>
  </div>
</div>
