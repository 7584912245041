import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoanFilesComponent } from './loan-files.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../../auth/auth.guard';
import { CCMCSharedModule } from '../../../../@ccmc/shared.module';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { Role } from 'src/@ccmc/models/role';

const routes: Routes = [
  {
    path: 'loan-files',
    component: LoanFilesComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: {
      roles: [
        Role.ClientAdmin,
        Role.NxtsoftSales,
        Role.NxtsoftOp,
        Role.NxtsoftDev
      ]
    }
  }
];
@NgModule({
  declarations: [LoanFilesComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CCMCSharedModule,
    KeyboardShortcutsModule.forRoot()
  ]
})
export class LoanFilesModule {}
