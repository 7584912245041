import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
const { Buffer } = require('buffer');
@Component({
  selector: 'ccmc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class CCMCFooterComponent {
  constructor() {}
  version = Buffer.from(environment.packageVERSION, "base64").toString();

}
