<div class="mapping-container">
  <div class="filter-container">
    <mat-form-field class="filter-FormField">
      <input matInput placeholder="Filter Search" #filterSearch />
    </mat-form-field>
  </div>

  <div class="table-container">
    <table
      mat-table
      class="table-TableContainer"
      #table
      [dataSource]="dataSource"
      CCMCPerfectScrollbar
      matSort
    >
      <!-- Field ID Column -->
      <ng-container matColumnDef="fieldID">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          style="min-width: 175px; max-width: 175px"
        >
          Field ID
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="noselect"
          style="min-width: 175px; max-width: 175px"
        >
          {{ element.fieldID }}
        </td>
      </ng-container>

      <!-- Field Display Column -->
      <ng-container matColumnDef="fieldDisplay">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          style="min-width: 225px; max-width: 225px"
        >
          Field Display
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="fieldDisplay-col noselect"
          style="min-width: 225px; max-width: 225px"
        >
          {{ element.fieldDisplay }}
        </td>
      </ng-container>
      <ng-container matColumnDef="transactionName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Transaction Name
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.transactionName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="edited">
        <th mat-header-cell *matHeaderCellDef>Edited</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.edited === true" class="editIcon">
            star</mat-icon
          >
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="onSelect(row)"
        [ngClass]="{ 'accent-bg': row == selected }"
      ></tr>
    </table>
  </div>
  <div class="paginatorContainer">
    <mat-paginator
      [pageSizeOptions]="[100]"
      showFirstLastButtons
      class="paginator-conditions"
    >
    </mat-paginator>
  </div>
  <!--TabBar-->
  <div class="tabBar">
    <mat-tab-group style="height: 100%">
      <mat-tab label="Mapping Options">
        <ccmc-field-options-config></ccmc-field-options-config>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<!--Spinner-->
<div class="spinner-container" *ngIf="showSpinner">
  <div class="new-spinner">
    <div class="outer-ring"></div>
    <div class="middle-ring"></div>
    <div class="inner-ring"></div>
  </div>
</div>
