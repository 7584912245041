<h1 matDialogTitle>
  <mat-icon
    *ngIf="targetResponseMessage.statusFlag == true"
    class="success-icon"
    >check_circle_outline</mat-icon
  >
  <mat-icon *ngIf="targetResponseMessage.statusFlag == false" class="error-icon"
    >error_outline</mat-icon
  >
  {{ title | titlecase }}
</h1>

<h3 mat-dialog-content>{{ message }}</h3>

<div
  mat-dialog-actions
  class="pt-24 error-actions"
  style="padding-bottom: 35px"
>
  <button
    class="ok-button"
    mat-raised-button
    color="accent"
    (click)="dialogRef.close(true)"
  >
    OK
  </button>
</div>
