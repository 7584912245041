import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { lastValueFrom, Subject, Subscription } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { CCMCConfirmDialogComponent } from 'src/@ccmc/components/confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from 'src/@ccmc/components/error-dialog/error-dialog.component';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { DocumentConnectorService } from 'src/@ccmc/services/doc-connector.service';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { STATUS_MESSAGES, STATUS_VALUES, TIME_THRESHOLD_MS } from './constants';
import { DialogHandlerService } from 'src/@ccmc/services/dialogHandler.service';
import {
  ResendDocConnectorDialogComponent
} from 'src/@ccmc/components/resend-doc-connector-dialog/resend-doc-connector-dialog.component';
import { environment } from 'src/environments/environment';
import { CustomErrorHandlerService } from 'src/@ccmc/services/custom-error-handler.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Buffer } from 'buffer';
import { SuccessDialogComponent } from 'src/@ccmc/components/success-dialog/success-dialog.component';

@Component({
  selector: 'app-doc-connector-history',
  templateUrl: './doc-connector-history.component.html',
  styleUrls: ['./doc-connector-history.component.scss']
})
export class DocConnectorHistoryComponent implements OnInit {
  dcDoc: any;
  constructor(
    private dcService: DocumentConnectorService,
    private assetService: AssetService,
    private dialog: MatDialog,
    private selectedFieldService: CCMCSelectedFieldService,
    private spinnerService: SpinnerService,
    private ccmcApiService: CcmcApiService,
    private amplifyService: AmplifyService,
    private dialogHandlerService: DialogHandlerService,
    private customErrorHandlerService: CustomErrorHandlerService,
    private httpClient: HttpClient,
    private http: HttpClient,
    private _cd: ChangeDetectorRef
  ) {}
  filterSearchText: string;
  // dataSourceChangepage: any;
  selectedLoan: any;
  assetIdSettingsSubscription: Subscription;
  settings: Array<any> = [];
  private dcDocSub: Subscription;
  private documentLosSub: Subscription;
  private selectedSub: Subscription;
  private spinnerSub: Subscription;
  showSpinner: boolean;
  history: any = [];
  unsubscribe: Subject<any> = new Subject();
  displayedColumns = ['checkBox', 'loanNumber', 'date', 'status', 'processed', 'transmitted', 'pending', 'failed'];
  selectedLoanDisplayedColumns = [
    'fileName',
    'date',
    'statusCompressedandMerged',
    'statusCore',
    'statusS3'
  ];
  dataSource: any;
  selectedLoanDataSource: any;
  checkedLoans: any;
  lastEvaluatedKey: any;
  exportButtonSwitch: boolean = false;
  getHistoryResponse: any;
  @ViewChild('filterSearch') filterSearchRef: ElementRef;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  private selectedLoansToSendSub: Subscription;
  isFetchingHistory = false;
  private intervalId: any; 
  private lastActivityTime = Date.now();
  private isNXTSoftUser = false;
  isLoanSelectVisible = false;
  checkedFiles: Map<string, any> = new Map<string, any>();
  isAllSelectLoanBoxesChecked : boolean = false;
  configurations: any = {};
  httpOptions = {
    headers: new HttpHeaders({
    "Content-Type": "application/json",
    })
  };

  ngOnInit(): void {
    this.getData();
    this.getHistory();
    this.validateNXTSoftUser();
    this.loadSettings();
    const inactivityLimit = 5 * 60 * 1000; // 5 minutes
    // Periodic history fetch every 10 seconds
    this.intervalId = setInterval(async () => {
      if (!this.isFetchingHistory && document.visibilityState === "visible" && Date.now() - this.lastActivityTime < inactivityLimit) {
        try {
          console.log("Fetching more history...");
          this.isFetchingHistory = true;
          await this.getHistory();
        } catch (error) {
          console.error("Error during periodic history fetch:", error);
        } finally {
          this.isFetchingHistory = false;
        }
      } else {
        console.log("No longer fetching data since user is inactive or page not visible...");
      }
    }, 10000);  // Fetch every 10 seconds
    
    // Add event listeners for user activity
    window.addEventListener("mousemove", this.resetActivity.bind(this));
    window.addEventListener("keydown", this.resetActivity.bind(this));
    window.addEventListener("visibilitychange", this.resetActivity.bind(this));
    console.log('Doc Connector Document =>', this.dcDoc);
  }
  
  private async loadSettings() {
    this.settings = await this.retrieveSettings();
  }

  private async validateNXTSoftUser() {
    if (await this.amplifyService.checkNXTsoftUserGroup()) {
      this.isNXTSoftUser = true;
    }
  }
  
  getDetailedStatus(item: any): string {
    if (!this.isNXTSoftUser) {
      return "";
    }
    // Check if 'detailedStatus' is an array and has at least one item
    if (Array.isArray(item?.detailedStatus) && item.detailedStatus.length > 0) {
      const firstItem = item.detailedStatus[0];
  
      if (firstItem?.response?.details) {
        return String(firstItem.response.details);
      }
      else if (firstItem?.message) {
        return String(firstItem.message);
      }
    }
    // If 'detailedStatus' is not an array, check if 'response.details' exists
    else if (item?.detailedStatus?.response?.details) {
      return String(item.detailedStatus.response.details);
    }
    else if (item?.detailedStatus?.message) {
      return String(item.detailedStatus.message);
    }
  
    return "";
  }
  
  
  getStatus(element:any): string  {
      let currentStatus = "";
      const status = element.status;
      switch (typeof status) {
          case 'boolean':
              currentStatus = String(status ? STATUS_VALUES.SUCCESSFUL : STATUS_VALUES.UNSUCCESSFUL);
              break;
          case 'string':
            if (status.toLowerCase() === STATUS_VALUES.SUCCESSFUL.toLowerCase()) {
              currentStatus = STATUS_VALUES.SUCCESSFUL;
            } else if (status.toLowerCase() === STATUS_VALUES.QUEUED.toLowerCase()) {
              currentStatus = STATUS_VALUES.QUEUED;
            } else {
              currentStatus = STATUS_VALUES.UNSUCCESSFUL;
            }
            break;
          default:
            currentStatus = STATUS_VALUES.UNSUCCESSFUL;
            break;
      }
      if (currentStatus === STATUS_VALUES.SUCCESSFUL && this.failedFileCount(element.files) > 0) {
        return STATUS_VALUES.UNSUCCESSFUL;
      }
      return currentStatus;
  }

  async getHistory() {
    try {
      this.isFetchingHistory = true;
      let isRefresh = false;
      console.log(this.assetService.getSelectedAssetId());
      this.getHistoryResponse = await this.dcService.getDocConnectorHistory(
        this.assetService.getSelectedAssetId()
      );
      console.log(this.getHistoryResponse);
      if (this.getHistoryResponse['statusFlag']) {
        if (this.getHistoryResponse['content'].Items.length > 0) {
          this.history = this.getHistoryResponse['content'].Items;
          // Set checkToSend
          for (let historyItem of this.getHistoryResponse['content'].Items) {
            historyItem.checkToSend = false;
            let historyItemFilesStatusMessageArr = historyItem.files.map((item: any) => item.statusMessage);
              if(historyItemFilesStatusMessageArr.length > 0 && historyItemFilesStatusMessageArr.every((item: any) => item && item.toLowerCase() === 'loan file exported successfully')) {
                historyItem.status = STATUS_VALUES.SUCCESSFUL
              } else if (historyItemFilesStatusMessageArr.some((item: any) => 
                item && (
                  item.toLowerCase().includes(STATUS_MESSAGES.DOCUMENT_RETRIEVED) ||
                  item.toLowerCase().includes(STATUS_MESSAGES.PROCESSED_FILE) ||
                  item.toLowerCase().includes(STATUS_MESSAGES.SENDING_FILE)
                )
              ) || historyItem?.status && typeof historyItem.status === 'string' && historyItem.status.toLowerCase() === STATUS_VALUES.QUEUED.toLowerCase()) {
                const currentTime = new Date().getTime();
                const historyItemDate = historyItem.date;
                
                // Initialize the status to "Queued" by default
                historyItem.status = STATUS_VALUES.QUEUED;
  
                try {
                  // Convert historyItem.date to a Date object if it's a string, otherwise use it directly if it's already a timestamp
                  const historyItemDateInMs = typeof historyItemDate === 'string' ? new Date(historyItemDate).getTime() : historyItemDate;
                
                  // Check if the historyItem.date is more than 10 mins older than the current time or there is a detailed Status message
                  if (currentTime - historyItemDateInMs > TIME_THRESHOLD_MS || historyItem?.detailedStatus) {
                    // The date is more than 1 hour old
                    historyItem.status = STATUS_VALUES.UNSUCCESSFUL;
                  }
                } catch (error) {
                  // If an error occurs during the conversion, set the status to "Unsuccessful"
                  console.error('Error in date conversion:', error);
                  historyItem.status = STATUS_VALUES.UNSUCCESSFUL;
                }              
              } else {
                historyItem.status = this.getStatus(historyItem.status);
              }   
          }
  
          if (this.dataSource?.data?.length > 0) {
            isRefresh = true;
            // Loop through new history items
            for (let historyItem of this.getHistoryResponse['content'].Items) {
              historyItem.checkToSend = false;
              if (this.selectedLoan?.loanNumber === historyItem?.loanNumber) {
                this.onSelectLoan(historyItem);
              }
              if (this.checkedLoans.length > 0 
                && this.checkedLoans[this.checkedLoans.length - 1]?.loanNumber === historyItem?.loanNumber) {
                  historyItem.checkToSend = true;
                  this.checkedLoans = [historyItem];
                  this.selectedFieldService.checkedHistoryLoans.next(this.checkedLoans);
                  if (!this.isLoanSelectVisible) {
                    this.dcService.sendButtonFlag.next(true);
                  }
              }
              // Remove existing records with the same loanNumber from existingData
              const existingIndex = this.dataSource.data.findIndex((item: any) => item.loanNumber === historyItem.loanNumber);
              if (existingIndex !== -1) {
                this.dataSource.data.splice(existingIndex, 1); // Remove the old record
              }
            }
            // Prepend new items to the existing data
            const updatedData = [...this.getHistoryResponse['content'].Items, ...this.dataSource.data];
            this.dataSource.data = updatedData;
          } else {
            this.dataSource = new MatTableDataSource(this.getHistoryResponse['content'].Items);
          }
  
          this.dataSource.sort = this.sort;
          const sortState: Sort = {active: 'date', direction: 'desc'};
          this.sort.active = sortState.active;
          this.sort.direction = sortState.direction;
          this.sort.sortChange.emit(sortState);
  
          this.dataSource.sortingDataAccessor = (item: any, property: any) => {
            switch (property) {
              case 'date': return new Date(item.date);
              case 'loanNumber': return new Number(item.loanNumber);
              case 'detailedStatus': return this.getDetailedStatus(item);
              case 'status': return this.getStatus(item.status);
              default: return item[property];
            }
          };
          this.dataSource.paginator = this.paginator;
          if (this.checkedLoans.length > 0 ) {
            this.selectedFieldService.checkedHistoryLoans.next(this.checkedLoans);
            if (!this.isLoanSelectVisible) {
              this.dcService.sendButtonFlag.next(true);
            }
          }
        } else {
          this.dataSource = new MatTableDataSource([]);
          // initializes sort
          this.dataSource.sort = this.sort;
          const sortState: Sort = {active: 'date', direction: 'desc'};
          this.sort.active = sortState.active;
          this.sort.direction = sortState.direction;
          this.sort.sortChange.emit(sortState);
  
          this.dataSource.sortingDataAccessor = (item: any, property: any) => {
            switch (property) {
              case 'date': return new Date(item.date);
              case 'loanNumber': return new Number(item.loanNumber);
              case 'detailedStatus': return this.getDetailedStatus(item);
              case 'status': return this.getStatus(item.status);
              default: return item[property];
            }
          };
          this.dataSource.paginator = this.paginator;
          const errorMessage = {
            title: 'History',
            message: `No History found for AssetID: ${this.assetService.getSelectedAssetId()}`
          };
          this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
        }
        if (!isRefresh  && this.getHistoryResponse['content'].LastEvaluatedKey) {
          this.lastEvaluatedKey = this.getHistoryResponse['content'].LastEvaluatedKey;
        }
      } else {
        const errorMessage = {
          title: 'History',
          message: this.getHistoryResponse['statusMessage']
        };
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      }
    } catch (e) {
      console.log(e);
    }
    this.isFetchingHistory = false;
  }
// TODO: The function retrieves valid data, but there is an issue with the pagination range. For exampele, 
// the initial list may shows data from one range (e.g., 5/21 - 3/2), but when this function runs, 
// it fetches data starting from a different range (e.g., 11/18).
  async getMoreHistory() {
    // Prevent fetching if already in progress
    if (this.isFetchingHistory) {
      console.log("Fetch already in progress...");
      return;
    }
  
    this.isFetchingHistory = true; // Set flag to true indicating the fetch is in progress
    this.spinnerService.setShowSpinner(true);
  
    try {
      this.getHistoryResponse = await this.dcService.getMoreDocConnectorHistory(
        this.assetService.getSelectedAssetId(),
        this.lastEvaluatedKey
      );
      
      if (this.getHistoryResponse['statusFlag']) {
        if (this.getHistoryResponse['content'].Items.length > 0) {
          // Update checkToSend and merge new items into existing data
          for (let historyItem of this.getHistoryResponse['content'].Items) {
            historyItem.checkToSend = false;
          }
  
          // Append the new history items to the existing data source
          this.dataSource.data = [...this.dataSource.data, ...this.getHistoryResponse['content'].Items];
  
          // Reinitialize sort and paginator if needed
          // this.dataSource.sort = this.sort;
          // this.dataSource.paginator = this.paginator;
        } else {
          const errorMessage = {
            title: 'History',
            message: `No more History found for AssetID: ${this.assetService.getSelectedAssetId()}`
          };
          this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
        }
  
        if (this.getHistoryResponse['content'].LastEvaluatedKey) {
          this.lastEvaluatedKey = this.getHistoryResponse['content'].LastEvaluatedKey;
        } else {
          this.lastEvaluatedKey = undefined;
        }
      } else {
        const errorMessage = {
          title: 'History',
          message: this.getHistoryResponse['statusMessage']
        };
        this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      }
    } catch (error) {
      console.error("Error fetching history:", error);
    } finally {
      this.spinnerService.setShowSpinner(false);
      this.isFetchingHistory = false; // Reset the flag after the operation is complete
    }
  
    console.log(this.getHistoryResponse);
  }
  
  resetActivity = () => {
    this.lastActivityTime = Date.now();
  };

  refreshDocConnectorHistoryTable() {
    
  }

  onSelectLoan(selected: any) {
    this.dcService.sendButtonFlag.next(false);
    this.selectedLoan = selected;
    this.selectedFieldService.onHistoryLoanFieldSelected.next(selected);
    this.selectedLoanDataSource = new MatTableDataSource(selected.files);
  }

  getData() {
    this.selectedSub = this.selectedFieldService.onHistoryLoanFieldSelected
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(selected => {
        this.selectedLoan = selected;
      });
    // Subscribe to the dcDocument
    this.dcDocSub = this.dcService.dcDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(documentCore => {
        if (documentCore) {
          this.dcDoc = JSON.parse(JSON.stringify(documentCore));
        }
      });
    // Subscribe to the dcDocument
    this.selectedLoansToSendSub = this.selectedFieldService.checkedHistoryLoans
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(historyLoansToSend => {
        if (historyLoansToSend) {
          console.log(historyLoansToSend);
          this.checkedLoans = JSON.parse(JSON.stringify(historyLoansToSend));
        }
      });
    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        this.showSpinner = spinner;
      });
  }

  clearSelectedLoan() {
    if (this.checkedLoans.length > 0) {
      this.dcService.sendButtonFlag.next(true);
    }
    this.isLoanSelectVisible = false;
    this.selectedFieldService.onHistoryLoanFieldSelected.next(undefined);
  }

  async retrieveSettings(): Promise<any> {
    try {
      const response = await lastValueFrom(
        this.http.post(
          `${Buffer.from(environment.environmentURL, 'base64').toString()}/dynamodb/get-document`,
          {
            tableName: 'assets',
            primaryKeyAttribute: 'assetId',
            primaryKey: this.assetService.getSelectedAssetId(),
          },
          this.httpOptions
        ).pipe(
          catchError((error) => {
            this.customErrorHandlerService.handleError(error); 
            throw error; 
          })
        )
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  
  retryTransfer(data: any, url: string): void {
    this.http.post(
      `${Buffer.from(environment.environmentURL, 'base64').toString()}${url}`,
      data,
      this.httpOptions
    ).pipe(
      catchError((error) => {
        this.customErrorHandlerService.handleError(error); 
        throw error; 
      })
    ).subscribe({
      next: () => {
        console.log('Retry process initiated successfully.');
      },
      error: (error) => {
        console.error('Error occurred while initiating retry:', error);
      }
    });
  }  

  getDocConnectorDocument() {
    this.dcService.dcDocument
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(documentCore => {
      console.log(documentCore);
      if (documentCore) {
        documentCore = documentCore;
        let jsonObject = JSON.parse(JSON.stringify(documentCore));
        delete jsonObject['destinations'];
        delete jsonObject['globalMetas'];
        delete jsonObject['_id'];
        delete jsonObject['_version'];
        jsonObject['_id'] = this.assetService.getSelectedAssetId();
        this.configurations = documentCore.configurations;
      }
    });
  }

  async onRetrySend(event: any) {
      event.preventDefault();
      const sendDocConnectorDialogResponse: any = await this.dialogHandlerService.handleDialog(ResendDocConnectorDialogComponent, {
        data: this.selectedLoan.loanNumber
      })
      console.log({
        sendDocConnectorDialogResponse
      });
      if (!sendDocConnectorDialogResponse.statusFlag) {
        console.log('User canceled send request');
      } else {
        this.spinnerService.setShowSpinner(true);
        const settings = await this.retrieveSettings();
        this.getDocConnectorDocument();
        let coreRequests = [];
        for (const [key, value] of this.checkedFiles.entries()) {
          console.log(JSON.stringify({ key: key, value: value }));

          const fileLocation = value?.S3FileLocation || 
            `${this.assetService.getSelectedAssetId()}/${this.selectedLoan.loanGUID}/${value.destination}/${value.destination}.pdf`;
          const metaLocation = this.replaceLastFilenameWithMeta(value?.S3FileLocation) || 
            `${this.assetService.getSelectedAssetId()}/${this.selectedLoan.loanGUID}/${value.destination}/meta.json`;

          let request = {
            "assetID": this.assetService.getSelectedAssetId(),
            "loanName": this.selectedLoan.loanNumber,
            "fileName": value.fileName,
            "fileType": this.configurations?.fileType || "",
            "metaLocation": metaLocation,
            "fileLocation": fileLocation,
            "configurations": {
              ...this.configurations
            },
            "isRetry": true
          }
          coreRequests.push(request);
        }  
        if (coreRequests.length > 0) {
          const url = `/${settings.coreCompany}/${settings.core}/send-file`;
          if (!settings.core.toLowerCase().includes("centerdoc")) {
            this.retryTransfer(coreRequests, url);
            this.isAllSelectLoanBoxesChecked = false;
            this.dialog.open(SuccessDialogComponent, {
              data: {
                title: 'Success',
                message: 'Retry process initiated successfully.'
              }
            })
          } else {
            this.dialog.open(ErrorDialogComponent, {
              data: {
                message: "Retry transfer feature not available for CenterDoc."
              }
            })
          }
          this.checkedFiles.clear();
        } else {
          this.dialog.open(ErrorDialogComponent, {
            data: {
              title: 'Error',
              message: "No Files Selected"
            }
          })
        }
        console.log("Core Requests", coreRequests);
        this.spinnerService.setShowSpinner(false);
    }
  }


   replaceLastFilenameWithMeta(filePath: string) {
    if (!filePath) {
      return "";
    }
    // Split the path into parts based on '/'
    const pathParts = filePath.split('/');

    // Replace the last part (filename) with 'meta.json'
    pathParts[pathParts.length - 1] = 'meta.json';

    // Rebuild the path
    return pathParts.join('/');
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    window.removeEventListener("mousemove", this.resetActivity.bind(this));
    window.removeEventListener("keydown", this.resetActivity.bind(this));
    document.removeEventListener("visibilitychange", this.resetActivity.bind(this));
    this.selectedFieldService.checkedHistoryLoans.next([]);
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  updateCheckedLoans(event: any, historyLoan: any) {
    console.log(event);
    if (event.checked) {
      // Only allow to send one loan at a time due to long wait times for computation
      this.checkedLoans = [];
      for (let loan of this.dataSource.data) {
        loan.checkToSend = false;
      }
      historyLoan.checkToSend = true;
      this.checkedLoans.push(historyLoan);
    } else {
      let loanIndex = this.checkedLoans.findIndex(
        (loan: any) => loan.loanGUID === historyLoan.loanGUID
      );
      if (loanIndex > -1) {
        this.checkedLoans.splice(loanIndex, 1);
      }
    }
    this.selectedFieldService.checkedHistoryLoans.next(this.checkedLoans);
    if (this.checkedLoans.length > 0) {
      this.dcService.sendButtonFlag.next(true);
    } else {
      this.dcService.sendButtonFlag.next(false);
    }
    console.log('Checked Loans', this.checkedLoans);
  }

   updateCheckedFiles(updatedFiles: Map<string, any>) {
    this.checkedFiles = updatedFiles;
  }

  resetCheckedFiles(): void {
    this.checkedFiles = new Map<string, any>(); // Reset the map
    this.isAllSelectLoanBoxesChecked = false;
  }

  updateLoanSelectVisibility(bool: boolean) {
    this.isLoanSelectVisible = bool;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  isRetry(element: any): boolean {
    const retryTimestampStr = element?.retryTimestamp;
    if (retryTimestampStr) { 
      const retryTimestamp = Number(retryTimestampStr); // Convert string to number
      if (!isNaN(retryTimestamp)) { // Ensure the conversion was successful
        const fiveMinutesAgo = Date.now() - 5 * 60 * 1000;
        return retryTimestamp > fiveMinutesAgo; // Return true if it's within the last 5 minutes
      }
    }
    return false; 
  }

  transmittedFileCount(element: any) {
    let counter = 0;
    let files = element.files;
    if (files && files.length) {
      for (let file of files) {
        if (file?.statusMessage.toLowerCase().includes(STATUS_MESSAGES.LOAN_FILE_EXPORTED)) {
          counter++;
        }
      }
    }
    return counter;
  }

  pendingFileCount(element: any) {
    let counter = 0;
    const files = element.files;
    const historyItemDateInMs = typeof element.date === 'string' ? new Date(element.date).getTime() : element.date;
    const currentTime = new Date().getTime();
    if (files && files.length) {
      for (let file of files) {
        const statusMessage = file.statusMessage.toLowerCase();
        if  (
          ((statusMessage.includes(STATUS_MESSAGES.DOCUMENT_RETRIEVED) ||
          statusMessage.includes(STATUS_MESSAGES.PROCESSED_FILE) ||
          statusMessage.includes(STATUS_MESSAGES.SENDING_FILE)) && currentTime - historyItemDateInMs < TIME_THRESHOLD_MS) 
          || 
          (this.isRetry(file) && (statusMessage.includes(STATUS_MESSAGES.DOCUMENT_RETRIEVED) ||
          statusMessage.includes(STATUS_MESSAGES.PROCESSED_FILE) ||
          statusMessage.includes(STATUS_MESSAGES.SENDING_FILE)))
        ) {
          counter++;
        }
      }
    }
    return counter;
  }

  failedFileCount(element: any) {
    let counter = 0;
    let files = element.files;
    const historyItemDateInMs = typeof element.date === 'string' ? new Date(element.date).getTime() : element.date;
    const currentTime = new Date().getTime();
    if (files && files.length > 0) {
      for (let file of files) {
        const statusMessage = file.statusMessage.toLowerCase();
        if (
          !statusMessage.includes(STATUS_MESSAGES.LOAN_FILE_EXPORTED) &&
          (
            !statusMessage.includes(STATUS_MESSAGES.DOCUMENT_RETRIEVED) &&
            !statusMessage.includes(STATUS_MESSAGES.PROCESSED_FILE) &&
            !statusMessage.includes(STATUS_MESSAGES.SENDING_FILE)
          ) ||

          (
            (statusMessage.includes(STATUS_MESSAGES.DOCUMENT_RETRIEVED) ||
            statusMessage.includes(STATUS_MESSAGES.PROCESSED_FILE) ||
            statusMessage.includes(STATUS_MESSAGES.SENDING_FILE)) && 
            currentTime - historyItemDateInMs > TIME_THRESHOLD_MS &&
            !this.isRetry(file)
          )
        ) {
          counter++;
        }
      }
    }
    if (counter > 0) {
      files.status = STATUS_VALUES.UNSUCCESSFUL;
    } 
    return counter;
  }

  exportToCSVStatusReport() {
    const dialogRef = this.dialog.open(CCMCConfirmDialogComponent, {
      data: 'Continue exporting status report to CSV?'
    });
    console.log('export to csv 703');
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let temp: any = [];

        if (this.exportButtonSwitch === false) {
          for (let row of this.dataSource.filteredData) {
            temp.push({
              loanNumber: row.loanNumber,
              processed: row.files.length,
              transmitted: this.transmittedFileCount(row.files),
              failed: this.failedFileCount(row.files)
            });
          }
        } else {
          for (let row of this.selectedLoan.files) {
            temp.push({
              fileName: row.fileName,
              source: row.source,
              destination: row.destination,
              statusMessage: row.statusMessage
            });
          }
        }

        console.log('Export to CSV');
        this.spinnerService.setShowSpinner(true);
        if (temp) {
          console.log(temp);
          let tempDoc = JSON.parse(JSON.stringify(temp));
          for (let value of tempDoc) {
            delete value.edited;
          }
          console.log(tempDoc);
          this.ccmcApiService
            .convertJSONToCSV(tempDoc)
            .subscribe(result => {
              console.log('export to csv 713');
              this.spinnerService.setShowSpinner(false);
              console.log(result);
              if (result) {
                const parsedResult = JSON.parse(JSON.stringify(result));
                if (parsedResult.statusFlag) {
                  var blob = new Blob([parsedResult.content], {
                    type: 'text/csv;charset=utf-8;'
                  });
                  var link = document.createElement('a');
                  if (link.download !== undefined) {
                    // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    link.setAttribute(
                      'download',
                      `${this.assetService.getSelectedFI()}-${this.assetService.getSelectedAssetId()}-StatusReport-${
                        new Date().toISOString()
                      }.csv`
                    );
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }
                } else {
                  const errorDialogRef = this.dialog.open(
                    ErrorDialogComponent,
                    {
                      data: {
                        title: 'Export Error',
                        message: parsedResult.statusMessage
                      }
                    }
                  );
                }
              }
            });
        }
      }
    });
  }

  clearSearchData() {
    this.filterSearchRef.nativeElement.value = '';
  }
}
