import { Component,ElementRef,Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { AddValidationDialogComponent } from '../add-validation-dialog/add-validation-dialog.component';
import { CCMCConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { EditFieldidsDialogComponent } from '../edit-fieldids-dialog/edit-fieldids-dialog.component';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';

@Component({
  selector: 'app-edit-validation-group-dialog',
  templateUrl: './edit-validation-group-dialog.component.html',
  styleUrls: ['./edit-validation-group-dialog.component.scss']
})
export class EditValidationGroupDialogComponent implements OnInit {
validationGroupsArray: any[] = [];
selectedValGroup: any;
dataSource: any;
displayedColumns = ['fieldIDs', 'edit/delete'];
tempFieldIDs: any;
tempValidation: any = {
  fieldIDs: [],
  modified: true 
}
fieldIDsTableItemsArray: any[] = [];
validationObject: any;
fieldIDArray: any[] = [];
finalFieldIDs: any[] = [];
deletedValidationGroups: any[] = [];
editable = false;
private documentCoreSub: Subscription;
unsubscribe: Subject<any> = new Subject();
tempDocument: any;
mappingObject: any;
@ViewChild('fieldIDsInput', { static: true }) fieldIDsInputEl: ElementRef;
indexArr: any[] = [];
flaggedFieldIDs: any[] = [];
emptyFlag: any = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditValidationGroupDialogComponent>,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private fieldEditedService: FieldEditedService,
    private adminApiService: AdminApiService
    ) 
    {}

  ngOnInit(): void {
    this.validationGroupsArray = JSON.parse(JSON.stringify(this.data.validationGroups));
    this.selectedValGroup = JSON.parse(JSON.stringify(this.data.selectedValidationGroup));
    this.validationObject = JSON.parse(JSON.stringify(this.data.validationObject));
    this.getData();
  }

  getData() {
    this.documentCoreSub = this.adminApiService.documentCore
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((documentCore) => {
      if (documentCore) {
        this.tempDocument = documentCore;
        if (documentCore.mapping) {
          this.mappingObject = JSON.parse(
            JSON.stringify(documentCore.mapping)
          );
        }
      }
    });
    this.fieldIDsTableItemsArray = [];
    this.onDropdownChange();
  }

  async addNewFieldIDs() {
    this.tempValidation.fieldIDs = [];
     if(this.tempFieldIDs.length > 0) {
      this.tempFieldIDs = this.tempFieldIDs.toUpperCase();
      this.fieldIDArray = this.tempFieldIDs.split(',');
      for (let fieldID of this.fieldIDArray) {
        fieldID = fieldID.trim();
        await this.tempValidation.fieldIDs.push(fieldID);
        this.finalFieldIDs.push(fieldID);
      }
        this.fieldIDsTableItemsArray.push(
          JSON.parse(JSON.stringify(this.tempValidation))
        );
        this.tempFieldIDs = '';
        this.tempValidation.fieldIDs = [];
        this.dataSource = [...this.fieldIDsTableItemsArray];
        } else {
          this.snackbarService.openSnackBar('FieldIDs cannot be blank', 'Okay');
    }
} 
  
capFieldIDs(event: any) {
  if (
    event.target.placeholder === 'Field ID' ||
    this.fieldIDsTableItemsArray.length > 0
  ) {
    event.target.value = this.tempFieldIDs.toUpperCase();
  }
}

saveFieldIDs() {
  let returnArray: any = [];
  if (this.dataSource.length > 0) {
    for (let row of this.dataSource) {
      returnArray.push(row.fieldIDs[0]);
    }
  }
  let returnObject: any = {
    validationGroup: this.selectedValGroup,
    updatedFieldIDs: returnArray
  }
  this.flaggedFieldIDs = [];
  this.indexArr = [];
  this.finalFieldIDs.forEach((fieldIDItem) => {
    let index1 = this.mappingObject.findIndex((mappingItem: any) => {
      return mappingItem.fieldID === fieldIDItem;
    });
    this.indexArr.push(index1);
    if (index1 === -1) {
      this.flaggedFieldIDs.push(fieldIDItem);
    }
  });
    if (this.flaggedFieldIDs.length > 0) {
      // There is at least one invalid validation
      // Producing error message
      let counter = 0;
      let tempMessage: any;
      for (let fieldID of this.flaggedFieldIDs) {
        if (counter === 0) {
          tempMessage = fieldID;
        } else {
          tempMessage = tempMessage + ' ' + fieldID;
        }
        counter++;
      }
      const errorMessage = {
        title: 'Validation FieldIDs Error',
        message:
          'The following FieldIDs can not be used because they do not exist in mapping:\n' + tempMessage,
      };
      this.dialog.open(ErrorDialogComponent, {
        data: errorMessage,
      });  
    } else {
      for(let validation of this.data.validationObject) {
      if(validation.validationGroup === this.selectedValGroup && this.selectedValGroup !== 'all' && this.fieldIDsTableItemsArray.length > 0) {
        for(let item of this.finalFieldIDs) {
          if(!validation.fieldIDs.includes(item)) {
              validation.fieldIDs.push(item);
          }
        }
        validation.edited = true;
      } 
    }
    // let exampleNewValidation = {
    //   displayName: 'Placeholder',
    //   edited: true,
    //   fieldIDs: this.finalFieldIDs,
    //   updateField: false,
    //   validationGroup: this.selectedValGroup,
    //   value: '0'
    // };
    // this.data.validationObject.push(exampleNewValidation);
    // this.dialogRef.close(returnObject);
    // return;
  }
  for(let validation of this.data.validationObject) {
    if(this.deletedValidationGroups.length > 0) {
      for(let group of this.deletedValidationGroups) {
        let deletedValidationGroupIndex = this.data.validationGroups.findIndex((item: any) => item === group);
        if (deletedValidationGroupIndex > -1) {
          this.data.validationGroups.splice(deletedValidationGroupIndex, 1);
        }

        if(validation.validationGroup  === group) {
            validation.validationGroup = 'n/a';
            validation.edited = true;
        }
      }
    } 
  }
  this.dialogRef.close(returnObject);
}

  


deleteValidationGroup() {
  const deleteValidationGroupRef = this.dialog.open(CCMCConfirmDialogComponent, {
    data: `Are you sure you wanna delete validation group: ${this.selectedValGroup} ?`,
    disableClose: true,
  });

  deleteValidationGroupRef.afterClosed().subscribe((data) => {
      if(data) {
        let deletedValidationGroupIndex = this.validationGroupsArray.findIndex( (item) => item === this.selectedValGroup);
          if (deletedValidationGroupIndex > -1) {
            this.deletedValidationGroups.push(this.validationGroupsArray[deletedValidationGroupIndex]);
            this.validationGroupsArray.splice(deletedValidationGroupIndex, 1);
            this.selectedValGroup = this.validationGroupsArray[0];
            this.onDropdownChange();
          }
        this.snackbarService.openSnackBar('Successfully deleted validation group', 'Okay');
      }
  });
}

async pasteNewValidations() {
  this.tempValidation.fieldIDs = [];
  let skippedDuplicated = false;
  let validationsWithError = false;
  let text = await navigator.clipboard.readText();
  let splitText = JSON.stringify(text)
    .slice(0, -1)
    .replace('"', '')
    .split('\\r\\n');
  for (let splitItem of splitText) {
    if (splitItem !== '') {
    let splitValidation = splitItem.split('\\t');
    let trimVal = splitValidation[0].split(',');
    let newVal: any[] = [];
    for (let val of trimVal) {
      val = val.trim();
      newVal.push(val);
    }
    if (splitValidation.length === 1) {
      let tempValidation = {
        fieldIDs: newVal,
        modified: true
      };
      let existingValidationIndex = this.fieldIDsTableItemsArray.findIndex(
        (val) =>
        val.fieldIDs.every((validationItem: any) => this.tempValidation.fieldIDs.includes(validationItem)) &&
        val.fieldIDs.length === this.tempValidation.fieldIDs.length
      );
      if (existingValidationIndex === -1) {
        this.fieldIDsTableItemsArray.push(tempValidation);
      } else {
        skippedDuplicated = true;
      }
    } else if (splitValidation[0] !== '') {
      const errorMessage = {
        title: 'Parsing Error',
        message:
          'Clipboard format not valid. Please try copying your column directly from Excel. ',
      };
      this.dialog.open(ErrorDialogComponent, {
        data: errorMessage,
      });
      return;
      }
    }
  }
  this.dataSource = [...this.fieldIDsTableItemsArray];
}

editFieldIDsItem(element: any) {
 let editFieldIdsRef = this.dialog.open(EditFieldidsDialogComponent, {
  data: {
    fieldIDs: element.fieldIDs,
    currentFieldIDArray: this.fieldIDsTableItemsArray
  },
  disableClose: true
 });

 editFieldIdsRef.afterClosed().subscribe((data) => {
  if(data) { 
    element.fieldIDs = data;
    this.finalFieldIDs = [];
    for(let item of this.fieldIDsTableItemsArray) {
      for(let fieldID of item.fieldIDs) {
        this.finalFieldIDs.push(fieldID);
      }
    }
  }
 });
}

removeFieldIDsItem(element: any) {
  let validationIndex = this.fieldIDsTableItemsArray.findIndex(
    (val) =>
      val.fieldIDs === element.fieldIDs 
  );
  if (validationIndex > -1) {
    this.fieldIDsTableItemsArray.splice(validationIndex, 1);
    // if item inside element.fieldIDs is included inside this.finalFieldIDs array
    // you pluck out that item from the finalfieldIDs array
    for(let item of element.fieldIDs) {
      if(this.finalFieldIDs.includes(item)) {
        let index = this.finalFieldIDs.indexOf(item);
        this.finalFieldIDs.splice(index, 1);
      }
    }
  } else {
    console.log('not a valid validation index');
  }
  this.dataSource = [...this.fieldIDsTableItemsArray];
}

onDropdownChange() {
  this.fieldIDsTableItemsArray = [];
  if(this.selectedValGroup !== 'All') {
    let fieldIDsIndex = this.validationObject.findIndex((validation: any) => validation.validationGroup === this.selectedValGroup);
    if (fieldIDsIndex > -1) {
      for(let fieldID of this.validationObject[fieldIDsIndex].fieldIDs) {
        this.tempValidation.fieldIDs = [];
        this.tempValidation.fieldIDs.push(fieldID);
        this.fieldIDsTableItemsArray.push(JSON.parse(JSON.stringify(this.tempValidation)))
      }
      this.dataSource = [...this.fieldIDsTableItemsArray];
      this.emptyFlag = false;
    } else {
      this.dataSource = [];
      this.emptyFlag = true;
    }
  }
}

  closeDialog() {
    this.dialogRef.close();
  }
}
