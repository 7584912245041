<div style="width: 40%; min-width: 400px; background-color: e1e1e1">
  <h1>Edit The Transaction Name</h1>
  <div *ngIf="localTransaction">
    <mat-form-field style="width: 100%">
      <input
        matInput
        placeholder="Transaction Name"
        [(ngModel)]="localTransaction.Name"
      />
    </mat-form-field>
  </div>
  <div style="display: flex; justify-content: space-between">
    <button
      mat-raised-button
      matTooltip="Close Dialog"
      matTooltipClass="toolTipStyle"
      (click)="closeDialog()"
      style="text-align: left; max-width: 75px"
    >
      Cancel
    </button>
    <button
      mat-raised-button
      matTooltip="Update the transaction."
      matTooltipClass="toolTipStyle"
      color="accent"
      (click)="update()"
      style="text-align: right; max-width: 175px"
    >
      Update Transaction
    </button>
  </div>
</div>
