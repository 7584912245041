import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild
} from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { CcmcApiService } from '../../services/ccmc-api.service';
import { CCMCSelectedFieldService } from '../../services/selected-field.service';
import { FieldEditedService } from '../../services/field-edited.service';
import { takeUntil } from 'rxjs/operators';
import { GeneralLedgerAccountingService } from '../../services/general-ledger-accounting.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
/**
 * The add translators dialog component lets the user add new translators
 *
 * @export
 * @class AddTranslatorsDialogComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-add-gla-translators-dialog',
  templateUrl: './add-gla-translators-dialog.component.html',
  styleUrls: ['./add-gla-translators-dialog.component.scss']
})
export class AddGlaTranslatorsDialogComponent implements OnInit, OnDestroy {
  /**
   * Temporary Translator
   *
   * @memberof AddTranslatorsDialogComponent
   */
  tempTranslator: any;

  /**
   * Temporary document
   *
   * @memberof AddTranslatorsDialogComponent
   */
  tempDocument: any;

  /**
   * Translators subscription
   *
   * @type {Subscription}
   * @memberof AddTranslatorsDialogComponent
   */
  translatorsSub: Subscription;

  /**
   * Transaltors object
   *
   * @type {*}
   * @memberof AddTranslatorsDialogComponent
   */
  translatorsObject: any;

  /**
   * Unsubscribes from current observables
   *
   * @type {Subject<any>}
   * @memberof AddTranslatorsDialogComponent
   */
  unsubscribe: Subject<any> = new Subject();

  /**
   * Field name input reference
   *
   * @type {ElementRef}
   * @memberof AddTranslatorsDialogComponent
   */
  @ViewChild('fieldNameInput', { static: true }) fieldNameInputEl: ElementRef;
  GeneralLedgerAccountingService: any;

  /**
   * Creates an instance of AddTranslatorsDialogComponent.
   * @param {CcmcApiService} ccmcApiService
   * @param {CCMCSelectedFieldService} selectedFieldService
   * @param {MatDialogRef<AddTranslatorsDialogComponent>} dialogRef
   * @param {MatSnackBar} snackBar
   * @param {FieldEditedService} fieldEditedService
   * @memberof AddTranslatorsDialogComponent
   */
  constructor(
    private ccmcApiService: CcmcApiService,
    private selectedFieldService: CCMCSelectedFieldService,
    private dialogRef: MatDialogRef<AddGlaTranslatorsDialogComponent>,
    private snackBar: MatSnackBar,
    private fieldEditedService: FieldEditedService,
    private glaService: GeneralLedgerAccountingService
  ) {}

  /**
   * Init Add Translator Dialog
   *
   * @memberof AddTranslatorsDialogComponent
   */
  ngOnInit() {
    this.getData();
  }

  /**
   * Unsubscribes from all observables used in this component
   *
   * @memberof AddTranslatorsDialogComponent
   */
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  /**
   * Gets all the data needed for the component
   *
   * @memberof AddTranslatorsDialogComponent
   */
  getData() {
    this.translatorsSub = this.glaService.glaDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(glaDocument => {
        if (glaDocument) {
          // Set tempDocument to documentLos
          this.tempDocument = glaDocument;
          // Set the translators object
          this.translatorsObject = glaDocument.translators;
          // Initialize tempTranslator
          this.tempTranslator = {
            fieldName: '',
            vbScript: '',
            multEnd: '',
            multBegin: '',
            evaluationMethod: 'path'
          };
        }
      });
  }

  /**
   * Opens snackbar with given message and action
   *
   * @param {string} message Message to user
   * @param {string} action Confirm button
   * @memberof AddTranslatorsDialogComponent
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000
    });
  }

  /**
   * Adds the translator to the translators object if it passes all checks
   *
   * @memberof AddTranslatorsDialogComponent
   */
  addTranslator() {
    if (this.tempTranslator.multEnd < this.tempTranslator.multBegin) {
      this.openSnackBar(
        'Error: MultEnd can not be larger than MultBegin',
        'Okay'
      );
      return;
    }
    if (
      this.tempTranslator.vbScript &&
      this.tempTranslator.vbScript.includes('~#~')
    ) {
      if (this.tempTranslator.multEnd <= this.tempTranslator.multBegin) {
        this.openSnackBar(
          'Error: Multi End must be greater than Multi Begin',
          'Okay'
        );
        return;
      }
    }
    // Check if form is valid
    if (this.checkForm()) {
      // Check if the translators object has items
      if (this.translatorsObject) {
        // Checks if the translator does not already exist
        if (!this.containsObject(this.tempTranslator, this.translatorsObject)) {
          this.tempTranslator.edited = true;
          console.log(this.tempTranslator);

          // If vbscript is empty set evaluationMethod to path
          if (this.tempTranslator.vbScript === '') {
            this.tempTranslator.evaluationMethod = 'path';
          }

          // Add the translator to the array
          this.translatorsObject.push(this.tempTranslator);
          this.fieldEditedService.onGLADocumentEdited.next(true);
          // Update the Los document
          this.tempDocument.translators = this.translatorsObject;
          // Update the global los document
          console.log(this.tempDocument);
          this.glaService.setGLADocumentSimple(this.tempDocument);
          // Close dialog after actions are complete
          this.closeDialog();
        } else {
          this.openSnackBar('Error: This translator already exists', 'Okay');
        }
      } else {
        this.fieldEditedService.onGLADocumentEdited.next(true);

        // If vbscript is empty set evaluationMethod to path
        if (this.tempTranslator.vbScript === '') {
          this.tempTranslator.evaluationMethod = 'path';
        }

        // Translators object is empty.
        this.translatorsObject = [
          {
            fieldName: this.tempTranslator.fieldName,
            vbScript: this.tempTranslator.vbScript,
            evaluationMethod: this.tempTranslator.evaluationMethod,
            multEnd: this.tempTranslator.multEnd,
            multBegin: this.tempTranslator.multBegin,
            edited: true
          }
        ];
        // Update the losDocument
        this.tempDocument.translators = this.translatorsObject;
        // Update the global los document
        this.glaService.setGLADocumentSimple(this.tempDocument);
        // close dialog after actions are complete
        this.closeDialog();
      }
    }
  }

  /**
   * Checks if the obj give is in the object list given
   *
   * @param {*} obj translator
   * @param {*} objList translatorObject
   * @returns
   * @memberof AddTranslatorsDialogComponent
   */
  containsObject(obj: any, objList: any) {
    // console.log(obj);
    for (let i = 0; i < objList.length; i++) {
      // If the fieldName is already in the object list return true
      if (objList[i].fieldName === obj.fieldName) {
        return true;
      }
    }
    return false;
  }

  /**
   * Closes this dialog
   *
   * @memberof AddTranslatorsDialogComponent
   */
  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * Checks whether the form is valid
   *
   * @returns
   * @memberof AddTranslatorsDialogComponent
   */
  checkForm() {
    if (this.tempTranslator.fieldName.length < 1) {
      this.openSnackBar('Error: Field name may not be blank', 'Okay');
      this.fieldNameInputEl.nativeElement.focus();
      return false;
    } else if (
      this.tempTranslator.vbScript &&
      this.tempTranslator.vbScript.includes('~#~')
    )
      if (this.tempTranslator.evaluationMethod.length < 1) {
        this.openSnackBar('Error: Evaluation Method may not be blank', 'Okay');
        return false;
      }
    this.tempTranslator.vbScript = this.tempTranslator.vbScript.trim();

    if (
      this.tempTranslator.vbScript.substring(
        this.tempTranslator.vbScript.length - 2
      ) == '\\n'
    ) {
      this.tempTranslator.vbScript = this.tempTranslator.vbScript.substring(
        0,
        this.tempTranslator.vbScript.length - 2
      );
      this.tempTranslator.vbScript = this.tempTranslator.vbScript.trim();
    }

    return true;
  }
}
