import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DebitCeditCheckDialogComponent } from './debit-cedit-check-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [DebitCeditCheckDialogComponent],
  imports: [
    MatDialogModule,
    MatButtonModule,
    CommonModule,
    MatCheckboxModule,
    MatCardModule,
    FormsModule
  ],
  entryComponents: [DebitCeditCheckDialogComponent]
})
export class DebitCeditCheckDialogModule {}
