import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { UpdateMiserCredentialsComponent } from './update-miser-credentials.component';


@NgModule({
  declarations: [UpdateMiserCredentialsComponent],
  imports: [
    AppMaterialModule,
    ReactiveFormsModule,
    CommonModule,
    MatCheckboxModule,
    FormsModule
  ]
})
export class UpdateMiserCredentialsModule {}
