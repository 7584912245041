<ng-container *ngIf="layout == 'vertical'">

  <div class="navbar-vertical">

    <div class="navbar-header" style="@media screen and (max-width: 1919px) {
      :host {
          zoom:78%;
      }
    }">

      <div class="logo">
        <img class="logo-icon" src="assets/images/logos/GC-rocket-omni-Sidebar.png">
        <span class="logo-text">GROUND<b>CONTROL</b></span>
      </div>

      <button mat-icon-button class="toggle-button-navbar" (click)="toggleSidebarFolded()" fxHide.lt-lg>
        <mat-icon>menu</mat-icon>
      </button>

      <button mat-icon-button class="toggle-button-navbar" (click)="toggleSidebarOpened()" fxHide.gt-md>
        <mat-icon>arrow_back</mat-icon>
      </button>

    </div>

    <div class="navbar-content" ccmcPerfectScrollbar>
      <ccmc-navigation [navigation]="navigation" layout="vertical"></ccmc-navigation>
    </div>

  </div>

</ng-container>

<ng-container *ngIf="layout == 'horizontal'">

  <div class="navbar-horizontal">
    <ccmc-navigation [navigation]="navigation" layout="horizontal"></ccmc-navigation>
  </div>

</ng-container>