import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnippetsDialogComponent } from './snippets-dialog.component';
import { FormsModule } from '@angular/forms';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';
import { EditSnippetsDialogComponent } from './edit-snippets-dialog/edit-snippets-dialog.component';
import { NewSnippetsDialogComponent } from './new-snippets-dialog/new-snippets-dialog.component';
import { CCMCSharedModule } from 'src/@ccmc/shared.module';

@NgModule({
  declarations: [
    SnippetsDialogComponent,
    EditSnippetsDialogComponent,
    NewSnippetsDialogComponent
  ],
  imports: [AppMaterialModule, CommonModule, FormsModule, CodemirrorModule],
  entryComponents: [
    SnippetsDialogComponent,
    EditSnippetsDialogComponent,
    NewSnippetsDialogComponent
  ]
})
export class SnippetsDialogModule {}
