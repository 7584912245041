import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectSetsDialogComponent } from './select-sets-dialog.component';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [SelectSetsDialogComponent],
  imports: [AppMaterialModule, CommonModule, FormsModule, ReactiveFormsModule],
  entryComponents: [SelectSetsDialogComponent]
})
export class SelectSetsDialogModule {}
