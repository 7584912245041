import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddAssetToUserDialogComponent } from './add-asset-to-user-dialog.component';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [AddAssetToUserDialogComponent],
  imports: [
    AppMaterialModule,
    CommonModule,
    FormsModule,
    MatAutocompleteModule
  ],
  entryComponents: [AddAssetToUserDialogComponent]
})
export class AddAssetToUserDialogModule {}
