import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddGlobalMetaDialogComponent } from './add-global-meta-dialog.component';
import { FormsModule } from '@angular/forms';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';

@NgModule({
  declarations: [AddGlobalMetaDialogComponent],
  imports: [AppMaterialModule, CommonModule, FormsModule],
  entryComponents: [AddGlobalMetaDialogComponent]
})
export class AddGlobalMetaDialogModule {}
