import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ColorPickerDialogComponent } from 'src/@ccmc/components/color-picker-dialog/color-picker-dialog.component';
import { CCMCConfirmDialogComponent } from 'src/@ccmc/components/confirm-dialog/confirm-dialog.component';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';

@Component({
  selector: 'ccmc-field-options-config',
  templateUrl: './field-options-config.component.html',
  styleUrls: ['./field-options-config.component.scss']
})
export class FieldOptionsConfigComponent implements OnInit, OnDestroy {
  /**
   * Selected transaction
   *
   * @type {*}
   * @memberof FieldOptionsConfigComponent
   */
  selected: any;
  public selectedMapping: any;
  /**
   * Local instance of the selected transaction
   *
   * @type {*}
   * @memberof FieldOptionsConfigComponent
   */
  localSelected: any;
  showDelay = new FormControl(1000);
  /**
   * Selected subscription
   *
   * @private
   * @type {Subscription}
   * @memberof FieldOptionsConfigComponent
   */
  private selectedSub: Subscription;

  /**
   * Edited flag that controls the save button
   *
   * @memberof FieldOptionsConfigComponent
   */
  editedFlag = false;

  /**
   * Field edited subscription
   *
   * @private
   * @type {Subscription}
   * @memberof FieldOptionsConfigComponent
   */
  private fieldEditedSub: Subscription;

  /**
   * Field edited flag
   *
   * @memberof FieldOptionsConfigComponent
   */
  fieldEditedFlag = false;

  /**
   * Unsubscribe from observables on destroy
   *
   * @type {Subject<any>}
   * @memberof FieldOptionsConfigComponent
   */
  unsubscribe: Subject<any> = new Subject();
  /**
   * variable keys variable
   *
   * @type {any}
   * @memberof FieldOptionsConfigComponent
   */
  variableKeys: any;

  constructor(
    private selectedFieldService: CCMCSelectedFieldService,
    private fieldEditedService: FieldEditedService,
    private dialog: MatDialog,
    private ccmcApiService: CcmcApiService
  ) {}

  ngOnInit(): void {
    this.getData();
  }
  /**
   * Unsubscribe from observables on destroy
   *
   * @memberof FieldOptionsConfigComponent
   */
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  /**
   * Get data for the component
   *
   * @memberof FieldOptionsConfigComponent
   */
  getData() {
    this.selectedSub = this.selectedFieldService.onMappingFieldSelected
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(selected => {
        if (selected) {
          if (this.editedFlag) {
            const confirmDialogRef = this.dialog.open(
              CCMCConfirmDialogComponent,
              {
                data: 'Save your current changes?'
              }
            );
            confirmDialogRef.afterClosed().subscribe(data => {
              if (data) {
                this.save();
                this.updateSelectedOptions(selected, true, false);
              } else {
                // Assign selected field
                this.selectedMapping = selected;
                // Turn flags off
                this.editedFlag = false;
                // Reset edited flag observable
                this.fieldEditedService.fieldEdited.next(false);
                // creates a local variable, so that the changes do not populate to the grid until they are saved
                this.localSelected = {
                  order: selected.order,
                  fieldDefault: selected.fieldDefault,
                  display: selected.display,
                  fieldDisplay: selected.fieldDisplay,
                  required: selected.required,
                  rowColor: selected.rowColor
                };
                this.updateSelectedOptions(selected, true, true);
              }
            });
          } else {
            this.selectedMapping = selected;
            // Reset field edited observable to false
            this.fieldEditedService.fieldEdited.next(false);
            if (selected.columnID) {
              this.updateSelectedOptions(selected, true, true);
            } else {
              this.updateSelectedOptions(selected, false, true);
            }
          }
        }
      });

          this.fieldEditedSub = this.fieldEditedService.fieldEdited
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(fieldEditedObj => {
          this.fieldEditedFlag = fieldEditedObj;
      });
  }

  /**
   * Updates the localSelected with user input.
   *
   * @param {*} selected User selected attributes.
   * @param {*} isDefined Sets attributes from selected if true otherwiese undefined.
   * @param {*} isEditedFalseFields Sets edited field and flag to false when true.
   * @memberof FieldOptionsConfigComponent
   */
  updateSelectedOptions(selected: any, isDefined: any, isEditedFalseFields: any) {
    this.selected = selected;
    this.variableKeys = Object.keys(this.selected);
    this.localSelected = JSON.parse(JSON.stringify(this.selected));
    console.log(this.variableKeys);
    console.log(this.localSelected);
    if (isEditedFalseFields) {
      this.fieldEditedService.fieldEdited.next(false);
      this.editedFlag = false;
    }
  }

  /**
   * Updates the transaction with user input
   *
   * @memberof FieldOptionsConfigComponent
   */
  save() {
    let oldFieldOptions = JSON.parse(JSON.stringify(this.selected));
    let newFieldOptions = JSON.parse(
      JSON.stringify(this.localSelected)
    );
    console.log(oldFieldOptions);
    console.log(newFieldOptions);
    const oldObject = oldFieldOptions;
    const newObject =  newFieldOptions;
    const editedObject = { oldObject, newObject };
    const msg = {
      type: 'Field Options',
      editedObject
    };
    this.ccmcApiService.appendLogChanges(msg, 'Target');
    this.fieldEditedService.coreEdited.next(true);
    const jsonFields = Object.keys(this.localSelected);
    for (let field of jsonFields) {
      this.selected[field] = this.localSelected[field];
    }
    console.log(this.selected);
    this.editedFlag = false;
    this.fieldEditedService.fieldEdited.next(false);
        // Set support value edited flag to true
    this.selected.edited = true;
  }

  /**
   * Checks if the transaction has been edited
   *
   * @memberof FieldOptionsConfigComponent
   */
  fieldEdited() {
    this.editedFlag = true;
    this.fieldEditedService.fieldEdited.next(true);
    // Checks to see if modified local selected variable is the same as the current fields
    if (this.isEquivalent(this.selected, this.localSelected)) {
      this.editedFlag = false;
      this.fieldEditedService.fieldEdited.next(false);
    }
  }

  /**
   * Returns if a and b are equal
   *
   * @param {*} a
   * @param {*} b
   * @returns
   * @memberof FieldOptionsConfigComponent
   */
  isEquivalent(a: any, b: any) {
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      return false;
    }
    for (let i = 0; i < aProps.length; i++) {
      // ignores the edited property since those values will not be the same
      if (aProps[i] !== 'edited') {
        const propName = aProps[i];

        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
          return false;
        }
      }
    }
    return true;
  }
  openColorPickerDialog() {
    const colorPickerDialogRef = this.dialog.open(ColorPickerDialogComponent, {
      data: this.localSelected,
      autoFocus: false
    });
    console.log(this.localSelected.rowColor);
    colorPickerDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.localSelected.rowColor = result;
        this.editedFlag = true;
      }
    });
  }
}
