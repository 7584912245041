import {
  ChangeDetectorRef,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';

import {
  CCMC_CONFIG,
  CCMCConfigService
} from '../@ccmc/services/config.service';
import { CCMCMatchMediaService } from '../@ccmc/services/match-media.service';
import { CCMCMatSidenavHelperService } from '../@ccmc/directives/ccmc-mat-sidenav/ccmc-mat-sidenav.service';
import { CCMCNavigationService } from '../@ccmc/components/navigation/navigation.service';
import { CCMCSidebarService } from '../@ccmc/components/sidebar/sidebar.service';
import { CCMCSplashScreenService } from '../@ccmc/services/splash-screen.service';
import { CCMCCollateralSearchDialogComponent } from './components/collateral-search-dialog/collateral-search-dialog.component';
import { CryptrService } from './services/cryptr.service';
import { AvatarService } from './services/avatar.service';
import { RequestChangeDialogComponent } from './components/request-change-dialog/request-change-dialog.component';
import { RequestChangeDialogComponentBlank } from './components/request-change-dialog-blank/request-change-dialog.component-blank';
import { NewStatusDialogComponent } from './components/new-status-dialog/new-status-dialog.component';
import { SalesforceService } from './services/salesforce.service';
import { AmplifyService } from './services/amplify.service';
@NgModule({
  entryComponents: [
    CCMCCollateralSearchDialogComponent,
    RequestChangeDialogComponent,
    RequestChangeDialogComponentBlank,
    NewStatusDialogComponent
  ],
  providers: [
    CCMCConfigService,
    CCMCMatchMediaService,
    CCMCMatSidenavHelperService,
    CCMCNavigationService,
    CCMCSidebarService,
    CCMCSplashScreenService,
    CryptrService,
    AvatarService,
    SalesforceService,
    AmplifyService
  ]
})
export class CCMCModule {
  constructor(@Optional() @SkipSelf() parentModule: CCMCModule) {
    if (parentModule) {
      throw new Error(
        'ccmcModule is already loaded. Import it in the AppModule only!'
      );
    }
  }

  static forRoot(config: any): ModuleWithProviders<CCMCModule> {
    return {
      ngModule: CCMCModule,
      providers: [
        {
          provide: CCMC_CONFIG,
          useValue: config
        }
      ]
    };
  }
}
