import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CCMCSelectedFieldService {
  /**
   * On Mapping Field Selected
   *
   * @type {BehaviorSubject<any>}
   * @memberof CCMCSelectedFieldService
   */
  public onMappingFieldSelected: BehaviorSubject<any> = new BehaviorSubject({});

  public onChangeRequestStatuses: BehaviorSubject<any> = new BehaviorSubject({
    statuses: []
  });

  public onChangeRequesttestSelected: BehaviorSubject<any> =
    new BehaviorSubject([]);
  public onGLATranslatorFieldSelected: BehaviorSubject<any> =
    new BehaviorSubject(null);
  public onTargetLayoutFieldSelected: BehaviorSubject<any> =
    new BehaviorSubject(null);
  public onAssetFieldSelected: BehaviorSubject<any> = new BehaviorSubject({});
  public onTransactionFieldSelected: BehaviorSubject<any> = new BehaviorSubject(
    {}
  );
  public onGLATransactionFieldSelected: BehaviorSubject<any> =
    new BehaviorSubject({});
  public onTransactionColumnFieldSelected: BehaviorSubject<any> =
    new BehaviorSubject({});
  public onTranslatorFieldSelected: BehaviorSubject<any> = new BehaviorSubject(
    {}
  );
  public onSetSelected: BehaviorSubject<any> = new BehaviorSubject({});
  /**
   * On Field Selected
   * @description Holds which field is selected
   * @type {BehaviorSubject<any>}
   * @memberof CCMCSelectedFieldService
   */
  public onFieldSelected: BehaviorSubject<any> = new BehaviorSubject({});

  /**
   * On Support Value Field Selected
   * @description holds which support value is currently selected
   * @type {BehaviorSubject<
   *     any
   *   >}
   * @memberof CCMCSelectedFieldService
   */
  public onSupportValueFieldSelected: BehaviorSubject<any> =
    new BehaviorSubject({});

  /**
   * On Validation Field Selected
   * @description
   * @type {BehaviorSubject<any>}
   * @memberof CCMCSelectedFieldService
   */
  public onValidationFieldSelected: BehaviorSubject<any> = new BehaviorSubject(
    {}
  );

  /**
   * On Conditions Field Selected
   *
   * @type {BehaviorSubject<any>}
   * @memberof CCMCSelectedFieldService
   */
  public onConditionsFieldSelected: BehaviorSubject<any> = new BehaviorSubject(
    {}
  );

  /**
   * On User Field Selected
   * @description holds which user is curretly selected
   * @type {BehaviorSubject<any>}
   * @memberof CCMCSelectedFieldService
   */
  public onUserFieldSelected: BehaviorSubject<any> = new BehaviorSubject({});

  public onGlobalMetasFieldSelected: BehaviorSubject<any> = new BehaviorSubject(
    undefined
  );

  public onHistoryLoanFieldSelected: BehaviorSubject<any> = new BehaviorSubject(
    undefined
  );
  public onHistoryLoanFileFieldSelected: BehaviorSubject<any> =
    new BehaviorSubject(undefined);

  public onDestinationFieldSelected: BehaviorSubject<any> = new BehaviorSubject(
    undefined
  );

  public checkedHistoryLoans: BehaviorSubject<any> = new BehaviorSubject([]);

  /**
   *Creates an instance of CCMCSelectedFieldService.
   * @memberof CCMCSelectedFieldService
   */
  constructor() {}

  clearOutSelectedFields() {
    this.onConditionsFieldSelected.next(null);
    this.onGLATransactionFieldSelected.next(null);
    this.onGLATranslatorFieldSelected.next(null);
    this.onMappingFieldSelected.next(null);
    this.onSetSelected.next(null);
    this.onSupportValueFieldSelected.next(null);
    this.onTargetLayoutFieldSelected.next(null);
    this.onTransactionColumnFieldSelected.next(null);
    this.onTransactionFieldSelected.next(null);
    this.onTranslatorFieldSelected.next(null);
    this.onDestinationFieldSelected.next(null);
    this.onGlobalMetasFieldSelected.next(null);
    this.onHistoryLoanFieldSelected.next(null);
    this.checkedHistoryLoans.next(null);
    this.onSetSelected.next(null);
    this.onGLATransactionFieldSelected.next(null);
    this.onTransactionColumnFieldSelected.next(null);
    this.onGLATranslatorFieldSelected.next(null);
    this.onTargetLayoutFieldSelected.next(null);
  }
}
