import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddNewSourceDialogComponent } from './add-new-source-dialog.component';
import { FormsModule } from '@angular/forms';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';

@NgModule({
  declarations: [AddNewSourceDialogComponent],
  imports: [AppMaterialModule, CommonModule, FormsModule],
  entryComponents: [AddNewSourceDialogComponent]
})
export class AddNewSourceDialogModule {}
