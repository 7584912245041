import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IssuesService {
  /**
   * On Get issues
   * @description gets issues
   * @type {BehaviorSubject<any>}
   * @memberof IssuesService
   */
  public onGetIssues: BehaviorSubject<any> = new BehaviorSubject({});
  constructor() {}
}
