import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddNewUserDialogComponent } from './add-new-user-dialog.component';
import { AppMaterialModule } from '../../../app/app-material/app-material.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [AddNewUserDialogComponent],
  imports: [AppMaterialModule, CommonModule, FormsModule],
  entryComponents: [AddNewUserDialogComponent]
})
export class AddNewUserDialogModule {}
