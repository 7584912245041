<div class="selected-loan-table-section">
  <div class="selected-loan-filter-container">
    <mat-form-field class="filter-form-field">
      <input
        matInput
        style="background-color: inherit"
        placeholder="Filter Search"
        #filterSearch
      />
    </mat-form-field>
  </div>
  <div #container class="selected-loan-table-container">
    <table
      class="table"
      mat-table
      matSort
      #table
      [dataSource]="selectedLoanDataSource"
      CCMCPerfectScrollbar
    >
     <!-- Checkbox Column -->
     <ng-container matColumnDef="checkBox" >
      <th
        mat-header-cell
        *matHeaderCellDef
        class="checkbox"
      >
        <mat-checkbox
          [checked]="isAllBoxesChecked"
          [indeterminate]="isSomeSelected()"
          (change)="toggleSelectAll($event.checked)"
          [ngClass]="{ 'hidden-column': settings?.core?.toLowerCase().includes('centerdoc') }"
        ></mat-checkbox>
      </th>
      <ng-container *ngIf="settings?.core?.toLowerCase().includes('centerdoc'); else checkBoxColumn">
        <td mat-cell *matCellDef="let element" class="noselect">
        </td>
      </ng-container>
      
      <ng-template #checkBoxColumn>
        <td 
          mat-cell *matCellDef="let element" 
          class="noselect" 
          (contextmenu)="onRightClick($event)"
          [matTooltip]="getCheckBoxToolTipMsg(element)"
        >
          <mat-checkbox
            [checked]="isCheckBox(element)"
            [id]="element.fileName"
            (change)="onCheckboxChange($event, element)" 
            [disabled]="!getStatus(element)?.toLowerCase()?.includes('failed')"
          ></mat-checkbox>
        </td>
      </ng-template>
      
      <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>

      <!-- File Name Column -->
      <ng-container matColumnDef="fileName">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>File Name</th>
        <td mat-cell *matCellDef="let element" class="noselect">
          {{ element.fileName }}
        </td>
      </ng-container>

      <!-- Source Column -->
      <ng-container matColumnDef="source">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Source</th>
        <td mat-cell *matCellDef="let element" class="noselect">
          {{ element.source }}
        </td>
      </ng-container>

      <!-- Destination Column -->
      <ng-container matColumnDef="destination">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Destination</th>
        <td mat-cell *matCellDef="let element" class="noselect">
          {{ element.destination }}
        </td>
      </ng-container>

      <!-- Message Column -->
      <ng-container matColumnDef="statusMessage">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Message</th>
        <td mat-cell *matCellDef="let element" class="noselect" [matTooltip]="getDetailedStatus(element)">
          {{ showDetailedStatusMessage ? element.statusMessage : getStatus(element) }}
        </td>
      </ng-container>

      <!-- Message Column
      <ng-container matColumnDef="goToDestination">
        <th
          mat-sort-header
          mat-header-cell
          *matHeaderCellDef
          class="goToDestinationButton"
        ></th>
        <td mat-cell *matCellDef="let element" class="noselect">
          <mat-icon
            class="goToDestinationButton"
            matTooltip="Navigate to the Destinations screen."
            matTooltipClass="tooltip-color"
            style="cursor: pointer"
            (click)="goToSelectedLoansDestination(element)"
          >
            double_arrow</mat-icon
          >
        </td>
      </ng-container> -->

      <tr
        mat-header-row
        *matHeaderRowDef="selectedLoanDisplayedColumns; sticky: true"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: selectedLoanDisplayedColumns"
        [ngClass]="{ 'accent-50-bg': row == selectedFile }"
      ></tr>
    </table>
    <div 
      *ngIf="selectedLoanDataSource.data.length === 0" 
      class="no-data-container"
    >
      <span class="no-data-message">No files</span>
    </div>
  </div>
</div>
