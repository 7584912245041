<div
  style="
    padding: 10px;
    width: 302px;
  "
>
  <div
    style="display: flex; flex-direction: row;"
  >
    <div>
      <h1>Edit Loan Type</h1>
    </div>
    <div style="margin-top: -12%;margin-left: 32%;cursor: pointer;">
      <h1>
        <i class="material-icons noselect" (click)="closeDialog()"> close </i>
      </h1>
    </div>
  </div>

  <div>
    <mat-form-field style="width: 80%;">
        <mat-select [(ngModel)]="selectedLoanTypeItem.loanType" (ngModelChange)="fieldEdited()"  name="loanType" placeholder="Loan Type">
            <mat-option value="Fixed Rate">Fixed Rate</mat-option>
            <mat-option value="Adjustable Rate">Adjustable Rate</mat-option>
            <mat-option value="Line of Credit">Line of Credit</mat-option>
            <mat-option value="Other Type">Other Type</mat-option>
          </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field style="width: 80%;">
      <input
        matInput
        placeholder="Product Name"
        maxlength="50"
        [(ngModel)]="selectedLoanTypeItem.productName"
        (ngModelChange)="fieldEdited()"
      />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field style="width: 80%;">
      <input
        matInput
        placeholder="Core Value"
        maxlength="15"
       [(ngModel)]="selectedLoanTypeItem.coreValue"
       (ngModelChange)="fieldEdited()"
      />
    </mat-form-field>
  </div>
  <div>
    
    <button
      mat-raised-button
      matTooltip="Update loan type"
      matTooltipClass="toolTipStyle"
      color="accent"
      [disabled]="!editedFlag"
      style="margin-left: 50px;
      float: right;
      margin-top: 6%;
      margin-right: 8%;"
      (click)="save()"
    >
      Submit
    </button>
  </div>
</div>