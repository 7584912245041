<div id="body" fxLayout="column">
  <!--Header-->
  <div class="header mat-accent-bg p-16 p-sm-24" id="header-container">
    <div class="hero-text">
      <!-- Header Image -->
      <img
        src="assets/images/logos/GC-rocket-omni1.png"
        style="z-index: 10000"
      />
    </div>

    <!--Search Bar-->
    <div class="search mat-white-bg mat-elevation-z7" id="search-bar-container">
      <form class="search-form" style="width: 100%; height: 29px">
        <button
          class="local-search-button"
          role="img"
          aria-hidden="true"
          style="border-radius: 4px"
        >
          <mat-icon
            class="mat-icon material-icons"
            style="position: relative; top: 2px; left: 1px"
            >search
          </mat-icon>
        </button>
        <input
          class="search-bar ng-untouched ng-pristine ng-valid"
          matInput
          #searchbar
          (keyup)="applyFilter($any($event.target).value)"
          type="search"
          autocomplete="off"
          placeholder="Filter by loan.."
          style="color: black; flex-grow: 2; height: 29px"
        />
      </form>
    </div>
  </div>

  <!--Core Table-->
  <div fxFlex="60" class="file-selector">
    <button
      mat-icon-button
      class="file-selector__refresh"
      (click)="refreshFileSelector()"
      (click)="rotate($event)"
    >
      <mat-icon class="icon-style">refresh</mat-icon>
    </button>
    <table mat-table #table [dataSource]="dataSource" matSort>
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th
          class="header-text"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Name
        </th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <!-- Loan # Column -->
      <ng-container matColumnDef="loanNumber">
        <div *ngIf="isOrigenate == 'origenate'; else elseBlock">
          <th
            class="header-text"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            Application Number
          </th>
        </div>
        <ng-template #elseBlock>
          <th
            class="header-text"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            Loan Number
          </th></ng-template
        >
        <td mat-cell *matCellDef="let element">{{ element.loanNumber }}</td>
      </ng-container>

      <!-- Product Column -->
      <ng-container matColumnDef="product">
        <th
          class="header-text"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Product
        </th>
        <td mat-cell *matCellDef="let element">{{ element.product }}</td>
      </ng-container>

      <!-- Loan # Column -->
      <ng-container matColumnDef="taxId">
        <th
          class="header-text"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Tax ID
        </th>
        <td mat-cell *matCellDef="let element">{{ element.taxId }}</td>
      </ng-container>

      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <th
          class="header-text"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Last Modified
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.date | date: 'MM/dd/yyyy hh:mm a' }}
        </td>
      </ng-container>

      <!-- Delete Column -->
      <ng-container matColumnDef="delete">
        <th
          class="header-text"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Delete
        </th>
        <td mat-cell *matCellDef="let element">
          <button
            style="cursor: pointer; padding-top: 5px"
            (click)="deleteLoan(element)"
          >
            <mat-icon class="deleteIcon"> delete_forever</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (dblclick)="selectLoan(row)"
        [ngClass]="{ 'selected-row': selectedRow === row ? true : false }"
      ></tr>
    </table>
  </div>

  <!--Spinner-->
  <div class="spinner-container" *ngIf="showSpinner">
    <div class="new-spinner">
      <div class="outer-ring"></div>
      <div class="middle-ring"></div>
      <div class="inner-ring"></div>
    </div>
  </div>
</div>
