import { Pipe, PipeTransform } from '@angular/core';

/**
 * Adds spaces after commas
 *
 * @export
 * @class AddSpaceAfterCommaPipe
 * @implements {PipeTransform}
 */
@Pipe({ name: 'removeCommas' })
export class RemoveCommas implements PipeTransform {
  /**
   * Transforms camelcase to dashes
   *
   * @param {string} value
   * @param {any[]} [args=[]]
   * @returns
   * @memberof AddSpaceAfterCommaPipe
   */
  transform(val: number): string {
    if (val !== undefined && val !== null) {
      // here we just remove the commas from value
      return val.toString().replace(/,/g, '').replace(/\$/g, '');
    } else {
      return '';
    }
  }
}
