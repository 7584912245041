import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ActiveCoreService } from 'src/@ccmc/services/active-core.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';

@Component({
  selector: 'app-premier-search-value',
  templateUrl: './premier-search-value.component.html',
  styleUrls: ['./premier-search-value.component.scss']
})
export class PremierSearchValueComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<PremierSearchValueComponent>,
    @Inject(MAT_DIALOG_DATA) public borrower: any,
    private activeCoreSerivce: ActiveCoreService,
    private spinnerService: SpinnerService,
    private snackbarService: SnackbarService
  ) {}
  canSend = true;
  showSpinner: boolean;
  private spinnerSub: Subscription;
  ngOnInit(): void {
    this.getSpinnerStatus();
    this.spinnerService.setShowSpinner(false);
  }

  getSpinnerStatus() {
    this.spinnerSub = this.spinnerService.spinner.subscribe(spinner => {
      this.showSpinner = spinner;
    });
  }

  checkForm() {
    if (this.borrower.firstName.length > 0) {
      if (this.borrower.lastName.length > 0) {
        this.canSend = true;
      } else {
        this.canSend = false;
      }
    } else {
      this.canSend = false;
    }
    console.log(this.canSend);
  }

  ngOnDestroy() {
    if (this.spinnerSub) {
      this.spinnerSub.unsubscribe();
    }
  }

  search() {
    // turn search button off
    this.canSend = false;
    // Set the spinner to true
    this.spinnerService.setShowSpinner(true);
    // Perform customer search
    this.activeCoreSerivce.activeService.customerSearch(
      this.borrower,
      (result: any) => {
        this.spinnerService.setShowSpinner(false);
        // Turn send back on with end of call
        this.canSend = true;
        console.log('search result: ', result);
        if (result.length > 0) {
          this.dialogRef.close(result);
        } else {
          this.snackbarService.openSnackBar('No results found.', 'Okay');
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
