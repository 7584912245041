<div id="body" fxLayout="column" style="background-color:#ececec ">
    <mat-tab-group #tabGroup style="height: 100%; background-color: #ececec;"  (selectedTabChange)="newTabSelect($event)" [selectedIndex]="">
        <mat-tab label="Implementation Status">
            <app-loan-types></app-loan-types>
        </mat-tab>
        <mat-tab style="height: auto; overflow-x: scroll !important" *ngFor="let status of statuses" label="{{ status }}">
            <app-request-change [fromLocation]="'dropdown'" [tab]="status" [newTabSelect]="statusCurrent"></app-request-change>
        </mat-tab>
    </mat-tab-group>
    <app-selected-change class="selectedChange">
    </app-selected-change>
    <!--Spinner-->
    <div class="spinner-container" *ngIf="showSpinner">
        <div class="new-spinner">
            <div class="outer-ring"></div>
            <div class="middle-ring"></div>
            <div class="inner-ring"></div>
        </div>
    </div>
</div>