import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomErrorHandlerService implements ErrorHandler {
  constructor() {}

  /**
   * Handle Error
   * @description handles errors by outputting in the console
   * @param {HttpErrorResponse} error
   * @returns
   * @memberof CustomErrorHandlerService
   */
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred: ', error.error.message);
    } else {
      console.log(
        `Backend returned code ${error.status} body was: ${JSON.stringify(
          error.error
        )}`
      );
    }
    return of(error);
  }
}
