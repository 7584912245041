import { Injectable, NgZone } from '@angular/core';
import { navigation } from '../../app/navigation/navigation';
import { CcmcApiService } from './ccmc-api.service';
import { SpinnerService } from './spinner.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { SuccessDialogComponent } from '../components/success-dialog/success-dialog.component';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError, filter } from 'rxjs/operators';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { AssetService } from './asset.service';
import { ActiveLosService } from './active-los.service';
import { environment } from 'src/environments/environment';
import { UpdatePremierCredentialsComponent } from '../components/update-premier-credentials/update-premier-credentials.component';
import { CCMCConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { CaptureDataService } from './capture-data.service';
import { AmplifyService } from './amplify.service';
import { GeneralLedgerAccountingService } from './general-ledger-accounting.service';
import { TargetResponseDialogComponent } from '../components/target-response-dialog/target-response-dialog.component';
const { Buffer } = require('buffer');

@Injectable({
  providedIn: 'root'
})
export class PrologueService {
  dynamicNav: any = navigation;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  resultTitle = 'Prologue Result';

  constructor(
    private dialog: MatDialog,
    private ccmcApiService: CcmcApiService,
    private router: Router,
    private spinnerService: SpinnerService,
    private zone: NgZone,
    private http: HttpClient,
    private customErrorHandlerService: CustomErrorHandlerService,
    private assetService: AssetService,
    private amplifyService: AmplifyService,
    private captureDataService: CaptureDataService,
    private glaService: GeneralLedgerAccountingService,
    private activeLosService: ActiveLosService
  ) {
    this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, PUT'
    );
    this.httpOptions.headers.append('Accept', 'application/json');
  }

  sendProcessGLA(params: any) {
    console.log(params);
    return this.http
      .post(`${Buffer.from(environment.environmentURL, "base64").toString()}/fiserv/prologue/process-gla`, params, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  processGLA(glaDocument: any) {
    this.spinnerService.setShowSpinner(true);
    let userName = this.amplifyService.username;
    let sortedCoreLayout = JSON.parse(JSON.stringify(glaDocument.coreLayout));
    let sendFile = glaDocument.configurations.sendFile;
    let fileNamePrefix = glaDocument.configurations.fileNamePrefix;
    let fileNameSuffix = glaDocument.configurations.fileNameSuffix;
    this.glaService.sortCoreLayout(sortedCoreLayout);
    const exportObject = {
      content: {
        set: this.removeBlankAmountsFromSet(glaDocument.set, sortedCoreLayout),
        configurations: glaDocument.configurations,
        coreLayout: sortedCoreLayout,
        cognitoID: this.amplifyService.getCognitoId()
      },
      user: userName,
      assetID: this.assetService.getSelectedAssetId(),
      loanNumber: this.captureDataService.loanNumber
    };
    this.sendProcessGLA(exportObject).subscribe(res => {
      console.log(res);
      const parsedResult = JSON.parse(JSON.stringify(res));
      if (parsedResult.error) {
        const errorMessage = {
          statusMessage: "Ground Control failed to receive a response back from the communicator",
          statusFlag: false
        };
        const dialogRef2 = this.zone.run(() => {
          this.dialog.open(TargetResponseDialogComponent, {
            data: errorMessage
          });
        });
        this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: this.captureDataService.loanNumber,
          statusMessage: parsedResult.error,
          statusFlag: parsedResult.statusFlag
        });
      } else if (parsedResult.statusFlag === true) {
        if (sendFile === undefined || sendFile === false || sendFile === 'false') {
          if (fileNamePrefix === undefined) {
            fileNamePrefix = '';
          }
          if (fileNameSuffix === undefined) {
            fileNameSuffix = '';
          }
          console.log(parsedResult);
          let exportString = parsedResult.content.message;
          console.log(parsedResult.content.message);
          if (exportString != null && exportString != undefined) {
            let split = exportString.split(',');
            let stringToExport = '';
            for (let item of split) {
              if (item[0] === '\\') {
                console.log(item[0]);
                item = item.slice(2);
                console.log(item);
                stringToExport = stringToExport.concat('\r\n').concat(item);
              } else {
                stringToExport = stringToExport.concat(',').concat(item);
              }
            }
            stringToExport = stringToExport.slice(1);
            console.log(stringToExport);
            const blob = new Blob([stringToExport as BlobPart], {
              type: 'text/csv;charset=utf-8;'
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${fileNamePrefix}${this.ccmcApiService.loanNumber}${fileNameSuffix}.csv`;
            a.click();
          }
        }

        const resultMessage = {
          message: parsedResult.statusMessage,
          statusFlag: true,
          loanNumber: parsedResult.loanNumber
        };
        this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: this.captureDataService.loanNumber,
          statusMessage: parsedResult.statusMessage,
          statusFlag: parsedResult.statusFlag
        });
        this.activeLosService.activeLosService.loanBooked(
          parsedResult,
          JSON.parse(JSON.stringify(glaDocument.configurations))
        );
        this.glaService.setGLAObject({});
        const dialogRef = this.dialog.open(TargetResponseDialogComponent, {
          data: resultMessage
        });
        dialogRef.afterClosed().subscribe(result => {
          if (this.activeLosService.activeLos === 'encompass' || this.activeLosService.activeLos === 'bytepro') {
            this.router.navigate(['loan-lookup']);
          } else {
            this.router.navigate(['file-selector']);
          }
        });
      } else {
        if (parsedResult.content && parsedResult.content.mapping) {
          this.ccmcApiService.mapping = parsedResult.content.mapping;
        }
        if (parsedResult.content && parsedResult.content.transactions) {
          this.ccmcApiService.transactions = parsedResult.content.transactions;
        }
        const errorMessage = {
          statusMessage: parsedResult.statusMessage,
          statusFlag: false
        };
        const dialogRef = this.dialog.open(TargetResponseDialogComponent, {
          data: errorMessage
        });
        this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: this.captureDataService.loanNumber,
          statusMessage: parsedResult.error,
          statusFlag: parsedResult.statusFlag
        });
      }
    });
  }

  removeBlankAmountsFromSet(set: any, targetLayout: any) {
    // Initial temp set
    let tempSet = JSON.parse(JSON.stringify(set));
    // Find Amount Index in targetlayout
    let amountIndex = targetLayout.findIndex(
      (targetItem: any) => targetItem.columnName === 'Amount'
    );
    // Set columnID
    let amountColumnID = targetLayout[amountIndex].columnID;
    // Initial cleaned transactions
    let cleanedTransactions = [];

    for (let transaction of tempSet.transactions) {
      let hasAmount = false;
      for (let col of transaction.columns) {
        // If columnId is amount and value is longer than 0 we keep
        if (col.columnID === amountColumnID && col.fieldValue.length > 0) {
          hasAmount = true;
        }
      }
      // If has amount push to new array
      if (hasAmount) {
        cleanedTransactions.push(transaction);
      }
    }
    // Reassign transactions
    tempSet.transactions = cleanedTransactions;
    // Return new set
    return tempSet;
  }
}
