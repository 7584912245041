<div class="fullPage">
  <div class="table-section">
    <div class="filter-container">
      <mat-form-field class="filter-FormField">
        <input matInput placeholder="Filter Search" #filterSearch />
      </mat-form-field>
    </div>
    <div #container class="table-container">
      <table
        class="table"
        mat-table
        matSort
        #table
        [dataSource]="dataSource"
        CCMCPerfectScrollbar
      >
        <!-- LoanName Column -->
        <ng-container matColumnDef="name">
          <th mat-sort-header mat-header-cell *matHeaderCellDef class="thcss">
            Name
          </th>
          <td mat-cell *matCellDef="let element" class="noselect">
            {{ element.name }}
          </td>
        </ng-container>
        <!-- Index -->
        <ng-container matColumnDef="index">
          <th mat-sort-header mat-header-cell *matHeaderCellDef class="thcss">
            Order
          </th>
          <td mat-cell *matCellDef="let element" class="noselect">
            {{ element.index }}
          </td>
        </ng-container>
        <!-- Value Column -->
        <ng-container matColumnDef="value">
          <th mat-sort-header mat-header-cell *matHeaderCellDef class="thcss">
            Value
          </th>
          <td mat-cell *matCellDef="let element" class="noselect">
            {{ element.value }}
          </td>
        </ng-container>
        <!-- Value Column -->
        <ng-container matColumnDef="path">
          <th mat-sort-header mat-header-cell *matHeaderCellDef class="thcss">
            Path
          </th>
          <td mat-cell *matCellDef="let element" class="noselect">
            {{ element.path }}
          </td>
        </ng-container>
        <!-- Field Edited Column -->
        <ng-container matColumnDef="edited">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Edited</th>
          <td
            mat-cell
            *matCellDef="let element"
            style="padding-right: 130px"
            class="noselect"
          >
            <mat-icon *ngIf="element.edited === true" class="editIcon">
              star</mat-icon
            >
          </td>
        </ng-container>

        <!-- Field Edited Column -->
        <ng-container matColumnDef="remove">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div class="AddBtn-Container" style="padding-top: 0px">
              <button
                class="Add-btn"
                matTooltip="Add a New Global Index"
                matTooltipClass="toolTipStyle"
                (click)="addGlobalMeta($event)"
              >
                <mat-icon style="color: #3fb2c6">add_circle</mat-icon>
              </button>
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class="noselect">
            <mat-icon
              style="
                margin-top: 1px;
                margin-right: 8%;
                float: right;
                color: #aaaaaa;
              "
              matTooltip="Remove global meta?"
              matTooltipClass="toolTipStyle"
              (click)="removeGlobalMeta($event, element)"
              >remove_circle</mat-icon
            >
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="onSelectGlobalMeta(row)"
          [ngClass]="{ 'accent-50-bg': row == selectedGlobalMeta }"
        ></tr>
      </table>
    </div>
    <div class="paginatorContainer">
      <mat-paginator
        [pageSizeOptions]="[100]"
        showFirstLastButtons
        class="paginator-conditions"
      >
      </mat-paginator>
    </div>
  </div>
  <!--TabBar-->
  <div class="tabBar">
    <mat-tab-group>
      <mat-tab label="Global Index">
        <div *ngIf="globalMetas">
          <app-edit-global-meta></app-edit-global-meta>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
