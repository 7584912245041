import { Injectable } from '@angular/core';
import { S3 } from 'aws-sdk';
import { APPCONSTANTS } from 'src/app/app.constants';
import { AssetService } from './asset.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { CustomErrorHandlerService } from './custom-error-handler.service';
const { Buffer } = require('buffer');
@Injectable({
  providedIn: 'root'
})
export class CaptureDataService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  loanNumber: any;
  constructor(
    private assetService: AssetService,
    private http: HttpClient,
    private customErrorHandlerService: CustomErrorHandlerService) {}

  uploadS3File(data: any) {
    let canCaptureData = this.assetService.getCanCaptureData();
    console.log(canCaptureData);
    if (!canCaptureData) {
      return;
    }
    return this.http
    .post(
      `${Buffer.from(environment.environmentURL, "base64").toString()}/s3/upload-file/omniportal`,
      data,
      this.httpOptions
    )
    .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  setLoanNumber(loanNumber: any) {
    this.loanNumber = loanNumber;
  }
}
