import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { ErrorDialogComponent } from '../../../../@ccmc/components/error-dialog/error-dialog.component';
import { EncompassService } from '../../../../@ccmc/services/encompass.service';
import { SpinnerService } from '../../../../@ccmc/services/spinner.service';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-encompass-search',
  templateUrl: './encompass-loan-search.component.html',
  styleUrls: ['./encompass-loan-search.scss']
})
export class EncompassLoanSearchComponent implements OnInit {
  selectedEnvironment = 'Client';
  environments: string[] = ['Client', 'Nxtsoft'];
  matcher = new MyErrorStateMatcher();
  loanLookupParams: any;
  losSubscription: Subscription;
  loans: any;
  displayedColumns = [
    'borrowerName',
    'loanNumber',
    'taxID',
    'lastModified',
    'loanGuid'
  ];
  dataSource: any;
  showSpinner: boolean; // flag for the mat-spinner
  private spinnerSub: Subscription;
  selectedLoan: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  loanNumberFormControl = new FormControl('', [Validators.minLength(3)]);
  taxIDFormControl = new FormControl('', [Validators.minLength(3)]);
  firstNameFormControl = new FormControl('', [Validators.minLength(2)]);
  lastNameFormControl = new FormControl('', [Validators.minLength(2)]);
  isSearchDisabled = true;
  changedLoans: any;
  freshchangedLoan: any;
  constructor(
    private spinnerService: SpinnerService,
    private encompassService: EncompassService,
    private matDialog: MatDialog,
    private amplifyService: AmplifyService
  ) {}

  ngOnInit() {
    // Set spinner to false
    this.spinnerService.setShowSpinner(false);
    // Set los
    // this.activeLosService.selectLos();
    // Subscribe to currnet los
    // this.subscribeToLos();
    // init loan lookup parameters
    this.loanLookupParams = {
      loanNumber: '',
      firstName: '',
      lastName: '',
      taxID: ''
    };
    // Subscribe to spinner
    this.spinnerSub = this.spinnerService.spinner.subscribe(spinner => {
      this.showSpinner = spinner;
    });
  }

  loanLookUp() {
    if (this.isSearchDisabled === false) {
      console.log(this.loanNumberFormControl);
      console.log(this.firstNameFormControl);
      console.log(this.lastNameFormControl);
      console.log(this.taxIDFormControl);
      // Set spinner to true
      this.spinnerService.setShowSpinner(true);
      this.loanLookupParams.env = this.selectedEnvironment;
      // Call loan look up for active los
      this.encompassService
        .loanLookUp(this.loanLookupParams)
        .subscribe(result => {
          console.log(result);
          this.amplifyService.environmentOverride = false;
          // Set spinnerto false
          this.spinnerService.setShowSpinner(false);
          // If token returned save it in the curent los service
          if (result) {
            const parsedResult = JSON.parse(JSON.stringify(result));
            if (parsedResult.content && parsedResult.content.accessToken)
              this.encompassService.setAccessToken(
                parsedResult.content.accessToken
              );
            // If success
            if (parsedResult.statusFlag === true) {
              // Set loans
              this.loans = parsedResult.content.loans;
              // creates a copy of loans to work with
              this.changedLoans = this.loans;
              // changes all the dates to be in a format that can be sorted
              // in html reformats the date to "look" correct
              for (var index of this.changedLoans) {
                this.freshchangedLoan = index;
                var datePipe = new DatePipe('en-US');
                var freshDate = datePipe.transform(
                  index.lastModified,
                  'yyyy/MM/dd hh:mm a'
                );
                index.lastModified = freshDate;
              }
              // if loans length is greater than 25 notify user
              if (this.loans.length === 25) {
                this.matDialog.open(ErrorDialogComponent, {
                  data: {
                    message: 'More than 25 results.',
                    title: 'Caution'
                  }
                });
              }
              // If no loans come back notify user
              if (this.loans.length === 0) {
                this.matDialog.open(ErrorDialogComponent, {
                  data: {
                    message: 'No results found.',
                    title: 'Caution'
                  }
                });
              }
              // Update data source to show loans
              this.dataSource = new MatTableDataSource(this.loans);
              // initializes sort
              this.dataSource.sort = this.sort;
            } else {
              const parsedResult = JSON.parse(JSON.stringify(result));
              this.matDialog.open(ErrorDialogComponent, {
                data: {
                  message: parsedResult.statusMessage,
                  title: 'Caution'
                }
              });
            }
          }
        });
    }
  }

  checkForm() {
    // If form is blank
    if (
      this.loanLookupParams.loanNumber === '' &&
      this.loanLookupParams.firstName === '' &&
      this.loanLookupParams.lastName === '' &&
      this.loanLookupParams.taxID === ''
    ) {
      this.isSearchDisabled = true;
      return;
    }
    // Check if all are valid
    if (this.lastNameFormControl.status === 'VALID') {
      if (this.loanNumberFormControl.status === 'VALID') {
        if (this.firstNameFormControl.status === 'VALID') {
          if (this.taxIDFormControl.status === 'VALID') {
            this.isSearchDisabled = false;
          } else {
            this.isSearchDisabled = true;
          }
        } else {
          this.isSearchDisabled = true;
        }
      } else {
        this.isSearchDisabled = true;
      }
    } else {
      this.isSearchDisabled = true;
    }
  }

  selectLoan(loan: any) {
    // set spinner to true
    this.spinnerService.setShowSpinner(true);
    // Set loan get parameters
    const loanGetParams: any = {
      loanGuid: loan.loanGuid
    };
    loanGetParams['env'] = this.selectedEnvironment;
    // Call active los get loan
    this.encompassService.getLoan(loanGetParams).subscribe(result => {
      console.log(result);
      if (result) {
        this.spinnerService.setShowSpinner(false);
        const parsedResult = JSON.parse(JSON.stringify(result));
        if (parsedResult.statusFlag) {
          const blob = new Blob(
            [JSON.stringify(parsedResult.content.loans) as BlobPart],
            {
              type: 'json'
            }
          );
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = parsedResult.content.loans.loanNumber + '.json';
          a.click();
        } else {
          console.log(result);
        }
      }
    });
  }

  clear() {
    this.loanLookupParams.loanNumber = '';
    this.loanLookupParams.firstName = '';
    this.loanLookupParams.lastName = '';
    this.loanLookupParams.taxID = '';
    this.loans = [];
    this.dataSource = new MatTableDataSource(this.loans);
  }
}
