import { Component, OnInit } from '@angular/core';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { CcmcApiService } from 'src/@ccmc/services/ccmc-api.service';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';
import { FormControl } from '@angular/forms';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { WriteLoggingService } from 'src/@ccmc/services/write-logging.service';
import { SpinnerService } from 'src/@ccmc/services/spinner.service';
import { AssetService } from 'src/@ccmc/services/asset.service';

@Component({
  selector: 'app-edit-users-group',
  templateUrl: './edit-users-group.component.html',
  styleUrls: ['./edit-users-group.component.scss']
})
export class EditUsersGroupComponent {
  username: any;
  userGroup = new FormControl();
  unsubscribe: Subject<any> = new Subject();
  private selectedUserSub: Subscription;
  public selectedUser: any;
  listOfCognitoGroups: any;
  arr: any[] = [];
  element: any;
  editedFlag = false;
  addedArray: any[] = [];
  removedArray: any[] = [];
  selected: boolean;
  selectedUserGroup: any;
  /**
   *Creates an instance of EditUsersGroupComponent.
   * @param {AdminApiService} adminApiService
   * @param {SnackbarService} snackBarService
   * @memberof EditUsersGroupComponent
   */
  constructor(
    private ccmcApiService: CcmcApiService,
    private amplifyService: AmplifyService,
    private snackbarService: SnackbarService,
    private selectedFieldService: CCMCSelectedFieldService,
    private fieldEditedService: FieldEditedService,
    private adminApiService: AdminApiService,
    private snackBarService: SnackbarService,
    private writeLoggingService: WriteLoggingService,
    private spinnerService: SpinnerService,
    private assetService: AssetService
  ) { }

  async ngOnInit() {

    this.listOfCognitoGroups = await this.getAvailableUserGroups();
    console.log(this.listOfCognitoGroups);
    // await this.amplifyService.checkLoanSystemsAdmin() add this to the if below
    if (
      await this.amplifyService.checkNXTsoftUserGroup() ||
      await this.amplifyService.checkSuperAdmin() ||
      await this.amplifyService.checkAdmin()
    ) {
      this.getData();

    }
  }

  /**
   * Returns available user groups that the current user can see based on precedence permissions
   *
   * @return {[any]} 
   * @memberof EditUsersGroupComponent
   */
  getAvailableUserGroups() {
    return new Promise((resolve) => {
      this.ccmcApiService.getUserGroups().subscribe(async (userGroupResponse: any) => {
        if (userGroupResponse.statusFlag) {
          const allUserGroups = userGroupResponse.content;
          const currentUserGroup = await this.amplifyService.retrieveUserGroupName();
          let userGroupIndex = allUserGroups.findIndex((userGroup: any) => userGroup.GroupName === currentUserGroup);
          let currentUserGroupPrecedence = 0;
          if (userGroupIndex > -1) {
            currentUserGroupPrecedence = allUserGroups[userGroupIndex].Precedence;
          }
          console.log("User Group Response", userGroupResponse);
          console.log("Current Precedence Cognito", currentUserGroupPrecedence);
          let availableUserGroups = [];
          for (let userGroup of allUserGroups) {
            console.log(`current Group: ${userGroup.GroupName}: ${userGroup.Precedence}`);
            if (userGroup.Precedence >= currentUserGroupPrecedence) {
              availableUserGroups.push(userGroup);
            }
          }
          return resolve(availableUserGroups);
        } else {
          return resolve([]);
        }
      })
    })
  }


  getData() {
    // Get selected user
    this.selectedUserSub = this.selectedFieldService.onUserFieldSelected
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async selected => {
        if (selected && selected.username) {
          this.selectedUser = selected;
          this.username = selected.username;
          this.editedFlag = false;
          this.addedArray = [];
          await this.retrieveGroupsForUser();
        } else {
          this.selectedUser = undefined;
          this.username = undefined;
        }
      });
  }

  async retrieveGroupsForUser() {
    return new Promise((resolve) => {
      this.ccmcApiService
        .getUserGroupsForUser(this.username)
        .subscribe(result => {
          const parsedResult = JSON.parse(JSON.stringify(result));
          this.arr = [];
          if (
            this.listOfCognitoGroups.length &&
            parsedResult &&
            parsedResult.content &&
            parsedResult.content.length
          ) {
            for (var i = 0; i < this.listOfCognitoGroups.length; ++i) {
              for (var j = 0; j < parsedResult.content.length; ++j) {
                if (
                  this.listOfCognitoGroups[i].GroupName ==
                  parsedResult.content[j].GroupName
                ) {
                  this.arr.push(this.listOfCognitoGroups[i]);
                }
              }
            }
          }
          this.userGroup.setValue(this.arr);
          return resolve(true);
        });
    })
  }

  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  /**
   * Add User To Group
   * @description Adds user to the given usergroup
   * @memberof EditUsersGroupComponent
   */
  addUserToGroup(username: string, groups: any) {
    return new Promise((resolve) => {
      this.adminApiService
      .addUsertoGroup(username, groups)
      .subscribe(result => {
        const parsedResult = JSON.parse(JSON.stringify(result));
        if (parsedResult.errorMessage) {
          this.snackbarService.openSnackBar(parsedResult.errorMessage, 'Okay');
          return resolve(false);
        } else {
          // Creating Log
          const stream = this.assetService.getSelectedAssetId();
          this.writeLoggingService.setStream(stream);
          this.writeLoggingService
            .writeUserLog({
              username: this.amplifyService.username,
              action: 'Added User to User Group',
              time: new Date().toISOString(),
              log: `User: ${this.username} was successfully added to ${groups.toString()}.`
            })
            .subscribe(result => {
              // logging result
              console.log(result);
              return resolve(true);
            });
        }
      });
    });
  }


  /**
   * users groups selection change
   * @description checks whether an option gets selected or unselected based on mouse click event
   * @memberof EditUsersGroupComponent
   */
  userGroupsSelectionChange(event: any) {
    if (event.isUserInput) {
      console.log(event.source.value, event.source.selected);
    }
    if (
      event.source.selected === true &&
      !this.arr.includes(event.source.value)
    ) {
      this.addedArray.push(event.source.value);
    } else if (
      event.source.selected === false &&
      this.arr.includes(event.source.value)
    ) {
      this.removedArray.push(event.source.value);
    } else if (event.source.selected === false) {
      var selectedInd = this.addedArray.indexOf(event.source.value);
      var unselectedInd = this.removedArray.indexOf(event.source.value);
      this.addedArray.splice(selectedInd, 1);
    } else if (event.source.selected === true) {
      var unselectedInd = this.removedArray.indexOf(event.source.value);
      this.removedArray.splice(unselectedInd, 1);
    }
  }

  /**
   * Check If Equal
   * @description checks if the existing usergroups are equal to the new selected usergroups
   * @memberof EditUsersGroupComponent
   */
  checkifEqual(value: any) {
    var equals =
      this.arr.length === value.length &&
      this.arr.every(
        (e, i) =>
          e.GroupName === value[i].GroupName && e.GroupName === value[i].GroupName
      );
    if (equals === true) {
      this.editedFlag = false;
    } else {
      this.editedFlag = true;
    }
  }

  /**
   * Modify Usergroups
   * @description modifies the user's usergroups
   * @memberof EditUsersGroupComponent
   */
  async modifyUserGroups() {
    var unselectedArr: any[] = [];
    var selectedArr: any[] = [];
    this.spinnerService.setShowSpinner(true);
    for (let i = 0; i < this.addedArray.length; i++) {
      let addFlag: any = await this.addUserToGroup(this.username, this.addedArray[i].GroupName);
      if (addFlag === false) {
        continue;
      }
      selectedArr.push(this.addedArray[i].GroupName);
    }
    for (let i = 0; i < this.removedArray.length; i++) {
      unselectedArr.push(this.removedArray[i].GroupName);
    }
    if (unselectedArr.length > 0) {
      let removeFlag: any = await this.removeFromUserGroup(this.username, unselectedArr);
      if (removeFlag === false) {
        unselectedArr = [];
      }
    }
    if (unselectedArr.length > 0 && selectedArr.length > 0) {
      this.snackbarService.openSnackBar(
        `Successfully added: ${selectedArr} to and removed: ${unselectedArr} from user ${this.username}.`,
        'Okay'
      );
    } else if (selectedArr.length > 0) {
      this.snackbarService.openSnackBar(
        `Successfully added: ${selectedArr} to user ${this.username}.`,
        'Okay'
      );
    } else if (unselectedArr.length > 0) {
      this.snackbarService.openSnackBar(
        `Successfully removed: ${unselectedArr} from user ${this.username}.`,
        'Okay'
      );
    }
    this.adminApiService.callRefreshUsers();
  }

  async removeFromUserGroup(username: string, groups: any) {
    return new Promise((resolve) => {
      this.adminApiService
        .removeUserFromGroup(this.username, groups)
        .subscribe(result => {
          const parsedResult = JSON.parse(JSON.stringify(result));
          if (parsedResult.errorMessage) {
            this.snackbarService.openSnackBar(parsedResult.errorMessage, 'Okay');
            return resolve(false);
          } else {
            // Creating Log
            const stream = this.assetService.getSelectedAssetId();
            this.writeLoggingService.setStream(stream);
            this.writeLoggingService
              .writeUserLog({
                username: this.amplifyService.username,
                action: 'Removed User from User Group',
                time: new Date().toISOString(),
                log: `User: ${this.username} was successfully removed from ${groups.toString()}.`
              })
              .subscribe(result => {
                // logging result
                console.log(result);
                return resolve(true);
              });
          }
        });
    });
  }
}
