import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  AfterViewInit,
  ElementRef,
  ChangeDetectorRef,
  AfterViewChecked
} from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CcmcApiService } from '../../../../@ccmc/services/ccmc-api.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { navigation } from '../../../../app/navigation/navigation';
import { Subscription, Observable, Subject } from 'rxjs';
import { NgForm, FormControl } from '@angular/forms';
import { CCMCSelectedFieldService } from '../../../../@ccmc/services/selected-field.service';
import { SpinnerService } from '../../../../@ccmc/services/spinner.service';
import { startWith, map, filter, takeUntil } from 'rxjs/operators';
import { ConditionsService } from '../../../../@ccmc/services/conditions.service';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { GlobalSearchService } from 'src/@ccmc/services/global-search.service';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { IssuesService } from 'src/@ccmc/services/issues.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { ActiveLosService } from 'src/@ccmc/services/active-los.service';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { MatPaginator } from '@angular/material/paginator';
import { RequestChangeDialogComponent } from 'src/@ccmc/components/request-change-dialog/request-change-dialog.component';
import { CCMCCollateralSearchDialogComponent } from 'src/@ccmc/components/collateral-search-dialog/collateral-search-dialog.component';
import { RequestChangeService } from 'src/@ccmc/services/request-change.service';
import { CCMCNavigationService } from 'src/@ccmc/components/navigation/navigation.service';

@Component({
  selector: 'ccmc-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class CCMCTransactionsComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  @ViewChild('searchbar') searchBarRef: ElementRef;
  @ViewChild(MatAutocompleteTrigger)
  autocomplete: MatAutocompleteTrigger;
  @ViewChild('arrow-right') arrowRightRef: MatIcon;
  transationSelectedSub: Subscription;
  displayedColumns = ['fieldId', 'fieldDisplay', 'fieldValue', 'status'];
  testPackage = {
    assetID: '',
    FI: '',
    statuses: [
      'Open Items (NXTsoft)',
      'Open Items (FI/Client/Institution)',
      'Items To Test',
      'Completed Items',
      'Custom Items'
    ],
    fields: [
      {
        fieldID: '',
        fieldDisplay: '',
        currentValue: '',
        correctedValue: '',
        loanNumber: '',
        status: '',
        urgency: '',
        description: '',
        nxtsoftNotes: ''
      }
    ]
  };
  @ViewChild('valueField') valueField: ElementRef;
  statuses = [];
  dataSourceChangepage: any;
  dataSource: any;
  dataChangeSource: any;
  transactionID: any;
  navigationSubscription: Subscription;
  @ViewChild(MatPaginator, { static: true }) paginators: MatPaginator;
  currentTransactionFields: any[] = [];
  showSpinner: boolean; // flag for the mat-spinner
  private spinnerSub: Subscription;
  shortcuts: ShortcutInput[] = [];
  public selected: any;
  showGlobalSearchCancel = false;
  showNoResults = false;
  readOnlyArray: any[] = [];
  requiredArray: any[] = [];
  showCheckBoxSub: Subscription;
  changeRequestStatusSub: Subscription;
  changeRequestFieldsSub: Subscription;
  showCheckBox: boolean;

  autoOptions: any[];
  filteredOptions: any;
  showDelay = new FormControl(1000);
  isEditable = false;
  fieldSearchPlaceholder = 'Search by Field...';
  globalSearchPlaceholder = 'Search Globally...';
  @ViewChild(MatSort) sort: MatSort;

  globalSearch = true;
  currentGlobalSearchField: any;
  otherGlobalSearchFields: any;
  private currentGlobalSearchFieldSub: Subscription;
  private getIssuesSub: Subscription;
  currentGlobalFilterString: any;
  currentGlobalSearchIndex: any;
  globalSearchCounter: any;
  unsubscribe: Subject<any> = new Subject();
  impModechecked: boolean;
  impModeboxshow: boolean;
  docFields: any;
  statusCurrent: any;
  currentPosition: number;

  /**
   * Creates an instance of CCMCTransactionsComponent.
   * @param {CcmcApiService} ccmcApiService
   * @param {ActivatedRoute} route
   * @param {Router} router
   * @param {CCMCSelectedFieldService} selectedFieldService
   * @param {SpinnerService} spinnerService
   * @param {MatDialog} dialog
   * @param {ConditionsService} conditionsService
   * @param {GlobalSearchService} globalSearchService
   * @param {ChangeDetectorRef} cdRef
   * @param {ScrollDispatcher} scrollDispatcher
   * @param {IssuesService} issuesService
   * @param {SnackbarService} snackBarService
   * @memberof CCMCTransactionsComponent
   */
  constructor(
    private ccmcApiService: CcmcApiService,
    private route: ActivatedRoute,
    private router: Router,
    private selectedFieldService: CCMCSelectedFieldService,
    private spinnerService: SpinnerService,
    public dialog: MatDialog,
    private conditionsService: ConditionsService,
    private globalSearchService: GlobalSearchService,
    private fieldEditedService: FieldEditedService,
    private cdRef: ChangeDetectorRef,
    private scrollDispatcher: ScrollDispatcher,
    private issuesService: IssuesService,
    private snackBarService: SnackbarService,
    private requestChangeService: RequestChangeService,
    private navigationService: CCMCNavigationService
  ) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initialize the component
      if (e instanceof NavigationEnd) {
        this.transactionID = this.route.snapshot.paramMap.get('id');
        this.getFields();
      }
    });
    this.scrollDispatcher.scrolled().subscribe(() => {
      if (this.autocomplete) {
        this.autocomplete.closePanel();
      }
    });
  }

  /**
   * On Swap
   * @description Switches between global and field search
   * @param {*} fieldSearchPlaceholder
   * @param {*} globalSearchPlaceholder
   * @memberof CCMCTransactionsComponent
   */
  onSwap(fieldSearchPlaceholder: any, globalSearchPlaceholder: any) {
    this.globalSearchPlaceholder = fieldSearchPlaceholder;
    this.fieldSearchPlaceholder = globalSearchPlaceholder;
  }

  /**
   * On Init
   *
   * @memberof CCMCTransactionsComponent
   */
  ngOnInit() {
    this.checkMode();
    // Get transactionID
    this.transactionID = this.route.snapshot.paramMap.get('id');
    // Get Fields
    this.getFields();
    // Get issues
    this.getIssues();
    // Subscribe to selected transaction
    this.selectedFieldService.onFieldSelected.subscribe(selected => {
      this.selected = selected;
    });
    // Subscribe to spinner
    this.spinnerSub = this.spinnerService.spinner.subscribe(spinner => {
      this.showSpinner = spinner;
      // if a dialog is open hide spinner so it isn't spinner behind dialog.
      if (this.dialog.openDialogs[0]) {
        this.showSpinner = false;
      }
    });
    // Subscribe to issues
    this.getIssuesSub = this.issuesService.onGetIssues.subscribe(getIssues => {
      if (getIssues) {
        this.getIssues();
      }
    });

    // Init global search object
    this.globalSearchService.initData();

    this.navigationService.onItemCollapsed.subscribe(clickedItem => {
      if(clickedItem) {
        this.clearSearchData();
        }
    });

    this.globalSearchService.globalSearchRefresh.subscribe(refresh => {
      if (refresh) {
        this.clearSearchData();
        this.globalSearchService.globalSearchRefresh.next(false);
      }
    });

    this.conditionsService.evaluateConditions();
  }

  checkMode() {
    this.showCheckBoxSub = this.fieldEditedService.impModechecked
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(check => {
        // console.log(check);
        this.showCheckBox = check;
      });
    this.changeRequestStatusSub =
      this.requestChangeService.changeRequestDocStatuses
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(fields => {
          // console.log(check);
          this.statuses = fields.statuses;
        });
  }

  /**
   * After View Init
   * @memberof CCMCTransactionsComponent
   */
  ngAfterViewInit() {
    // Init shortcuts
    this.shortcuts.push({
      key: ['cmd + shift + d'],
      label: 'Demo Mode',
      description: 'Demo Mode Toggle',
      command: e => {
        this.setDemoMode();
      },
      preventDefault: true
    });
  }

  ngAfterViewChecked() {
    // Detect changes
    this.cdRef.detectChanges();
  }

  /**
   * Get Fields
   * @description Sets the datasource based on the active transaction ID
   * @memberof CCMCTransactionsComponent
   */
  getFields() {
    // console.log(this.impModechecked);
    this.impModechecked = false;
    // console.log(this.transactionID);

    // Get issues if on issues tab
    if (this.transactionID === 'issueList') {
      this.getIssues();
      // If global search
    } else if (this.transactionID === 'reviewList') {
      this.getReviewList();
    } else if (this.transactionID === 'changereq') {
      this.impModechecked = true;
    } else if (this.currentGlobalSearchField) {
      this.impModechecked = false;

      // Set datasource to global search values
      this.dataSource = new MatTableDataSource(
        this.otherGlobalSearchFields.filter((obj: any) => {
          return (
            obj.transactionID.toLowerCase() ===
            this.currentGlobalSearchField.transactionID.toLowerCase()
          );
        })
      );
    } else {
      // Get fields with transaction ID
      this.dataSource = new MatTableDataSource(
        this.ccmcApiService.mapping
          .filter((obj: any) => {
            return (
              obj.transactionID.toLowerCase() ===
              this.transactionID.toLowerCase() && obj.display === true
            );
          })
          .sort((a: any, b: any) => {
            if (a.order < b.order) {
              return -1;
            }
            if (a.order > b.order) {
              return 1;
            }
            return 0;
          })
      );
    }

    // Read only array
    // required array
    this.readOnlyArray = [];
    this.requiredArray = [];
    // tslint:disable-next-line: prefer-for-of
    // Populate the readOnly and requiredArray
    if (this.dataSource) {
      for (let i = 0; i < this.dataSource.data.length; i++) {
        this.readOnlyArray.push(this.dataSource.data[i].readOnly);
        this.requiredArray.push(this.dataSource.data[i].required);
      }
      // Sort data
      this.sortData();
    }
  }

  /**
   * Sort Data
   * @description allows the data to be sortable
   * @memberof CCMCTransactionsComponent
   */
  sortData() {
    this.dataSource.sort = this.sort;
  }

  /**
   * Apply Filter
   * @description filter the transactions
   * @param {string} filterValue
   * @memberof CCMCTransactionsComponent
   */
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  /**
   * On Submit
   *
   * @param {NgForm} form
   * @memberof CCMCTransactionsComponent
   */
  onSubmit(form: NgForm) {
    console.log('form ', form);
  }

  /**
   * On Destroy
   *
   * @memberof CCMCTransactionsComponent
   */
  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }

    if (this.spinnerSub) {
      this.spinnerSub.unsubscribe();
    }
    if (this.getIssuesSub) {
      this.getIssuesSub.unsubscribe();
    }
  }

  /**
   * Get Issues
   * @description Checks to see if there are any issues, if so sets the error icon next to the issue list nav item
   *  Sets the datasource to the issues, if on issue list page
   * @memberof CCMCTransactionsComponent
   */
  getIssues() {
    this.impModechecked = false;
    // init displayed transactions
    const displayedTransactions: any[] = [];
    // set transaction navigation
    const transactionNav = navigation[2].children[1].children;
    // if transaction has an index set it to display
    for (const transaction of transactionNav) {
      if (transaction.transactionIndex > -1) {
        transaction.children.forEach((element: any) => {
          element.title = element.title.trim();
        });
        if (
          this.ccmcApiService.transactions[transaction.transactionIndex]
            .display === true
        ) {
          transaction.children.forEach((element: any) => {
            if (
              this.ccmcApiService.transactions[element.transactionIndex]
                .display === true
            ) {
              // push it to displayed Transactions
              displayedTransactions.push(element.id);
            }
          });
          // push it to displayed Transactions
          displayedTransactions.push(transaction.id);
        }
      }
    }

    // Returns as an issue if the transaction is displayed, field value is greater then max field width, and validation object
    // and if required and the value is less then one
    const issues = this.ccmcApiService.mapping.filter((obj: any) => {
      let validationCheck = true;
      if (obj.validation) {
        validationCheck = false;
        for (let i = 0; i < obj.validation.length; i++) {
          const value = obj.fieldValue;
          // console.log(value);
          // console.log((parseInt(obj.validation[i].value, 10)));
          if (value === obj.validation[i].value) {
            validationCheck = true;
          }
        }
        // Add this check to turn validationcheck to true if it isn't required and not no value
        if (obj.required === false && obj.fieldValue.length < 1) {
          validationCheck = true;
        }
      }
      // tslint:disable-next-line:max-line-length
      return (
        displayedTransactions.includes(obj.transactionID) &&
        ((obj.fieldValue.length > obj.fieldWidth && !obj.disabled === true) ||
          (obj.required === true && obj.fieldValue.length < 1) ||
          !validationCheck ||
          (obj.fieldType.toLowerCase() === "number" && /[a-zA-Z]/.test(obj.fieldValue)))
      );
    });
    // Set issues nav
    const issuesNav = navigation[2].children[1].children[0];
    // If issues exist
    if (issues.length > 0) {
      issuesNav.icon = 'warning';
      // Set issues exist to true
      this.ccmcApiService.doIssuesExist(true);
    } else {
      // No issues
      issuesNav.icon = '';
      this.ccmcApiService.doIssuesExist(false);
    }
    // Set datasource to issues
    if (this.transactionID === 'issueList') {
      this.dataSource = new MatTableDataSource(issues);
    }
  }

  /**
   * Get Status
   * @description returns a status if there is an error with the value of that field
   * @param {*} row
   * @returns
   * @memberof CCMCTransactionsComponent
   */
  getStatus(row: any) {
    if (row.disabled === true) {
      return;
    } else if (row.required === true && row.fieldValue.length < 1) {
      return 'Required';
    } else if (row.fieldValue.length > row.fieldWidth) {
      return 'Exceeds Max Length';
    } else if (row.fieldType.toLowerCase() === "number" && /[a-zA-Z]/.test(row.fieldValue) === true) {
      return 'Numerical Value Expected'
    } else if (row.validation && row.fieldValue.length > 0) {
      const value = row.fieldValue;
      let validValue = false;
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < row.validation.length; i++) {
        if (value === row.validation[i].value) {
          validValue = true;
        }
      }
      if (validValue === false) {
        return 'Value Not Found';
      } else {
        return;
      }
    } else {
      return;
    }
  }

  /**
   * On Select
   * @description add focus on the selected row's input, set filtered options
   * @param {*} e
   * @param {*} selected
   * @memberof CCMCTransactionsComponent
   */
  onSelect(e: any, selected: any) {
    console.log(selected);
    // Set parent node
    const parentNode = e.target.parentNode;
    // focus on the input in the selected row
    const select = parentNode.querySelector('td input[type="text"]');
    if (select) {
      select.focus();
    }
    // If validation apply
    if (selected.validation) {
      this.autoOptions = selected.validation;
      this.applyAutoFilter('');
    }
    this.selectedFieldService.onFieldSelected.next(selected);
  }

  /**
   * Evaluate Conditions
   * @description re-evaluates conditions when field values change
   * @param {*} updatedField
   * @memberof CCMCTransactionsComponent
   */
  evaluateConditions(updatedField: any) {
    this.conditionsService.evaluateConditionByField(updatedField);
  }

  /**
   * Apply Auto Filter
   * @description set filtered options
   * @param {*} filters
   * @memberof CCMCTransactionsComponent
   */
  applyAutoFilter(filters: any) {
    if (filters === '') {
      this.filteredOptions = this.autoOptions;
    } else {
      this.filteredOptions = this.autoOptions.filter(o => {
        return o.displayName.toLowerCase().includes(filters.toLowerCase());
      });
    }
  }

  /**
   * Set Demo Mode
   * @description Sets demo mode in configurations
   * @memberof CCMCTransactionsComponent
   */
  setDemoMode() {
    this.ccmcApiService.configurations.demoMode =
      !this.ccmcApiService.configurations.demoMode;
    this.snackBarService.openSnackBar(
      'Demo Mode: ' + this.ccmcApiService.configurations.demoMode,
      'Okay'
    );
  }

  /**
   * Toggle Global
   * @description Toggles global search
   * @param {*} type
   * @memberof CCMCTransactionsComponent
   */
  toggleGlobal(type: any) {
    this.clearSearchData();
    if (type === 'global') {
      this.globalSearch = true;
    } else {
      this.globalSearch = false;
      this.currentGlobalSearchField = undefined;
      this.getFields();
    }
  }

  /**
   * Apply Global Search
   * @description Applies global search
   * @param {*} event
   * @returns
   * @memberof CCMCTransactionsComponent
   */
  applyGlobalSearch(event: any, selected: any) {
    // If keyCode is 13 (enter) and there is already a searchfield grab next search index
    if (event.keyCode === 13 && this.currentGlobalSearchField) {
      // If shift is active get previous
      if (event.shiftKey) {
        this.getPrevGlobalSearch(event, selected);
      } else {
        this.getNextGlobalSearch(event, selected);
      }
      return;
    }
    let filterValue = event.target.value;
    if (filterValue.length === 0) {
      this.globalSearchService.clearData();
      this.currentGlobalSearchField = undefined;
      this.showGlobalSearchCancel = false;
      return;
    } else {
      this.showGlobalSearchCancel = true;
    }
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    // Save current filter string
    this.currentGlobalFilterString = filterValue;
    this.globalSearchService.globalSearch(filterValue);
    this.otherGlobalSearchFields = this.globalSearchService.globalSearchFields;
    this.currentGlobalSearchField = this.globalSearchService.currentField;
    if (this.currentGlobalSearchField && this.otherGlobalSearchFields) {
      this.currentGlobalSearchIndex = this.otherGlobalSearchFields.indexOf(
        this.currentGlobalSearchField
      );
      this.showNoResults = false;
    } else {
      this.showNoResults = true;
      this.currentGlobalSearchField = undefined;
    }
    this.globalSearchCounter = this.globalSearchService.globalSearchCounter;
    if (this.otherGlobalSearchFields) {
      this.dataSource = new MatTableDataSource(
        this.otherGlobalSearchFields.filter((obj: any) => {
          return (
            obj.transactionID.toLowerCase() ===
            this.currentGlobalSearchField.transactionID.toLowerCase()
          );
        })
      );
    } else {
      this.getFields();
    }
  }

  /**
   * Get Next Global Search
   * @description Gets the next instance of global search
   * @memberof CCMCTransactionsComponent
   */
  getNextGlobalSearch(e: any,selected: any) {
    this.globalSearchService.getNext();
    this.currentGlobalSearchField = this.globalSearchService.currentField;
    this.globalSearchCounter = this.globalSearchService.globalSearchCounter;
    this.onSelect(e,this.currentGlobalSearchField);
  }

  /**
   * Gets Previous Global Search
   * @description Get the previous instance of global search
   * @memberof CCMCTransactionsComponent
   */
  getPrevGlobalSearch(e: any, selected: any) {
    this.globalSearchService.getPrev();
    this.currentGlobalSearchField = this.globalSearchService.currentField;
    this.globalSearchCounter = this.globalSearchService.globalSearchCounter;
    this.onSelect(e,this.currentGlobalSearchField);
  }

  getReviewList() {
    this.impModechecked = false;

    let reviewList = []
    console.log(this.ccmcApiService.mapping)
    console.log(this.ccmcApiService.transactions)
    for (let i = 0; i < this.ccmcApiService.mapping.length; i++) {
      let mapping = this.ccmcApiService.mapping[i]
      let transactionIndex = this.ccmcApiService.transactions.findIndex((transaction: any) => transaction.transactionID === mapping.transactionID)
      let transaction = this.ccmcApiService.transactions[transactionIndex]
      if (
        transaction && mapping.display === true && transaction.display != false
      ) {
        // push it to displayed Transactions
        reviewList.push(mapping);
      }
    }
    reviewList = reviewList.filter(
      obj => obj.rowColor === 'D1D1D1'
    );
    console.log(reviewList)
    this.ccmcApiService.setExportDocument(reviewList);
    this.dataSource = new MatTableDataSource(reviewList);
  }
  /**
   * Clear Search Data
   * @describe Clears the global search data
   * @memberof CCMCTransactionsComponent
   */
  clearSearchData() {
    this.searchBarRef.nativeElement.value = '';
    this.currentGlobalSearchField = undefined;
    this.showGlobalSearchCancel = false;
    this.showNoResults = false;
    this.getFields();
  }
  newChangeRequest(element: any, i: any) {
    // console.log(element);
    // console.log(this.readOnlyArray[i]);
    const premierSearchValueRef = this.dialog.open(
      RequestChangeDialogComponent,
      {
        data: { name: element },
        panelClass: 'change-request__panel-dialog'
      }
    );
    premierSearchValueRef.afterClosed().subscribe(result => {
      if (result) {
        // element.fieldValue = result.correctedValue;
        this.snackBarService.openSnackBar('Change Request Added', 'Okay');
      } else {
        this.snackBarService.openSnackBar('Change Request Failed', 'Okay');
      }
      this.spinnerService.setShowSpinner(false);
    });
  }

  newTabSelect(a: any) {
    // console.log('something');
    // console.log(a.index);
    this.statusCurrent = a.tab.textLabel;
    this.fieldEditedService.newTableChecked.next(this.statusCurrent);
  }

  blurSelect(event: any, element: any) {
    this.valueField.nativeElement.blur();
    event.stopPropagation();
    event.preventDefault();
    this.currentPosition = this.dataSource.filteredData.indexOf(element);
    if (this.transactionID === 'issueList') {
      this.getIssues();
      this.onSelect(event, this.dataSource.filteredData[this.currentPosition]);
    }
  }
}
