import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersComponent } from './users.component';
import { AuthGuard } from '../../../auth/auth.guard';
import { Routes, RouterModule } from '@angular/router';
import { CCMCSharedModule } from '../../../../@ccmc/shared.module';
import { AddNewUserComponent } from './add-new-user/add-new-user.component';
import { EditUserAssetsComponent } from './edit-user-assets/edit-user-assets.component';
import { EditUsersGroupComponent } from './edit-users-group/edit-users-group.component';
import { Role } from 'src/@ccmc/models/role';

const routes: Routes = [
  {
    path: 'users',
    component: UsersComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: {
      roles: [
        Role.ClientAdmin,
        Role.NxtsoftSales,
        Role.NxtsoftOp,
        Role.NxtsoftDev
      ]
    }
  }
];

@NgModule({
  declarations: [
    UsersComponent,
    AddNewUserComponent,
    EditUserAssetsComponent,
    EditUsersGroupComponent
  ],
  imports: [CommonModule, RouterModule.forChild(routes), CCMCSharedModule],
  exports: [UsersComponent]
})
export class UsersModule {}
