<div id="main-navigation" class="nav" [ngClass]="{
    horizontal: layout === 'horizontal',
    vertical: layout === 'vertical'
  }">
    <!-- Vertical Navigation Layout -->
    <ng-container *ngIf="layout === 'vertical'">
        <ng-container *ngFor="let item of navigation">
            <ccmc-nav-vertical-group *ngIf="item.type == 'group'" [item]="item"></ccmc-nav-vertical-group>
            <ccmc-nav-vertical-collapse *ngIf="item.type == 'collapse'" [item]="item"></ccmc-nav-vertical-collapse>
            <ccmc-nav-vertical-item *ngIf="item.type == 'item'" [item]="item"></ccmc-nav-vertical-item>
        </ng-container>
    </ng-container>
    <!-- / Vertical Navigation Layout -->

    <!-- Horizontal Navigation Layout -->
    <ng-container *ngIf="layout === 'horizontal'">
        <ng-container *ngFor="let item of navigation">
            <ccmc-nav-horizontal-collapse *ngIf="item.type == 'group'" [item]="item"></ccmc-nav-horizontal-collapse>
            <ccmc-nav-horizontal-collapse *ngIf="item.type == 'collapse'" [item]="item"></ccmc-nav-horizontal-collapse>
            <ccmc-nav-horizontal-item *ngIf="item.type == 'item'" [item]="item"></ccmc-nav-horizontal-item>
        </ng-container>
    </ng-container>
    <!-- / Horizontal Navigation Layout -->
</div>