<div class="target-layout-columns-container" *ngIf="localSelected">
  <div class="target-layout-columns-select-options">
    <div class="select-options-title">Select Options</div>
    <div class="tooltip">
      <span class="tooltiptext"></span
      ><button
        mat-raised-button
        matTooltip="Saves any changes you make, within this section."
        matTooltipClass="toolTipStyle"
        color="accent"
        (click)="save()"
        [disabled]="!editedFlag"
        class="target-layout-columns-save-btn"
      >
        Save
      </button>
    </div>
  </div>
  <div
    style="
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
      overflow-y: auto;
      max-height: 150px;
      column-gap: 15px;
      padding-right: 5px;
    "
  >
    <div *ngFor="let variable of variableKeys; let i = index">
      <div *ngIf="variable == 'required'; then thenBlock; else elseBlock"></div>
      <ng-template #thenBlock>
        <mat-form-field style="width: 100%">
          <mat-label>required</mat-label>
          <mat-select
            [(ngModel)]="localSelected[variable]"
            (ngModelChange)="fieldEdited()"
            style="width: 100%"
            (keydown.control.s)="save()"
            name="truefalse"
          >
            <mat-option *ngFor="let tfOption of tfBoolean" [value]="tfOption">
              {{ tfOption }}
            </mat-option>
          </mat-select></mat-form-field
        ></ng-template
      >
      <ng-template #elseBlock>
        <div
          *ngIf="
            variable == 'columnID' ||
              variable == 'length' ||
              variable == 'displayOrder';
            then thenTypeNumberBlock;
            else elseTypeNumberBlock
          "
        ></div>
        <ng-template #thenTypeNumberBlock>
          <mat-form-field style="width: 100%"
            ><input
              matInput
              type="number"
              placeholder="{{ variable }}"
              [(ngModel)]="localSelected[variable]"
              (ngModelChange)="fieldEdited()"
              style="width: 100%"
              (keydown.control.s)="save()"
          /></mat-form-field> </ng-template
        ><ng-template #elseTypeNumberBlock>
          <mat-form-field style="width: 100%"
            ><input
              matInput
              placeholder="{{ variable }}"
              [(ngModel)]="localSelected[variable]"
              (ngModelChange)="fieldEdited()"
              style="width: 100%"
              (keydown.control.s)="save()"
          /></mat-form-field>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>
