import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GeneralLedgerAccountingService } from '../../services/general-ledger-accounting.service';

@Component({
  selector: 'app-create-set-column-dialog',
  templateUrl: './create-set-column-dialog.component.html',
  styleUrls: ['./create-set-column-dialog.component.scss']
})
export class CreateSetColumnDialogComponent implements OnInit {
  newColumn: any;
  targetLayoutSub: any;
  unsubscribe: Subject<any> = new Subject();
  targetLayout: any;
  constructor(
    private glaService: GeneralLedgerAccountingService,
    private dialogRef: MatDialogRef<CreateSetColumnDialogComponent>
  ) {}

  ngOnInit() {
    this.newColumn = {
      columnID: '',
      vbScript: ''
    };
    this.getTargetLayout();
  }

  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  getTargetLayout() {
    this.targetLayoutSub = this.glaService.glaDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(glaDocument => {
        if (glaDocument) {
          this.targetLayout = glaDocument.coreLayout;
        }
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  create() {
    this.newColumn.edited = true;
    this.dialogRef.close(this.newColumn);
  }
}
