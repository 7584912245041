import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DocumentConnectorService } from 'src/@ccmc/services/doc-connector.service';
import { FieldEditedService } from 'src/@ccmc/services/field-edited.service';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';
import { AddGlobalMetaDialogComponent } from '../add-global-meta-dialog/add-global-meta-dialog.component';

@Component({
  selector: 'app-add-new-source-dialog',
  templateUrl: './add-new-source-dialog.component.html',
  styleUrls: ['./add-new-source-dialog.component.scss']
})
export class AddNewSourceDialogComponent implements OnInit {
  tempSource = '';
  tempDocument: any;
  selectedDestinationSub: Subscription;
  unsubscribe: Subject<any> = new Subject();
  selectedDestination: any;
  sources: any;
  constructor(
    private dcService: DocumentConnectorService,
    private selectedFieldService: CCMCSelectedFieldService,
    private dialogRef: MatDialogRef<AddGlobalMetaDialogComponent>,
    private fieldEditedService: FieldEditedService,
    private snackBarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  getData() {
    // Subscribe to the document and grab the support values
    this.selectedDestinationSub =
      this.selectedFieldService.onDestinationFieldSelected
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(destination => {
          if (destination) {
            console.log(destination);
            this.selectedDestination = destination;
            this.sources = this.selectedDestination.sources;
          }
        });
  }

  addNewSource() {
    if (this.tempSource.length > 0) {
      if (this.selectedDestination.sources.includes(this.tempSource)) {
        this.snackBarService.openSnackBar(
          'Error: Duplicate source detected',
          'Okay'
        );
      } else {
        this.selectedDestination.sources.push(this.tempSource);
        this.selectedFieldService.onDestinationFieldSelected.next(
          this.selectedDestination
        );
        this.fieldEditedService.losEdited.next(true);
        this.dialogRef.close(true);
      }
    } else {
      this.snackBarService.openSnackBar(
        'Error: Source cannot be blank',
        'Okay'
      );
    }
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
}
