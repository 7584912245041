import { NgModule } from '@angular/core';
import { GlaTransactionsComponent } from './gla-transactions.component';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { RouterModule, Routes } from '@angular/router';
import { CCMCSharedModule } from 'src/@ccmc/shared.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';

const routes: Routes = [
  {
    path: 'gla/transactions',
    component: GlaTransactionsComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [GlaTransactionsComponent],
  imports: [
    RouterModule.forChild(routes),
    CCMCSharedModule,
    KeyboardShortcutsModule.forRoot(),
    ScrollingModule
  ],
  exports: [GlaTransactionsComponent]
})
export class GlaTransactionsModule {}
