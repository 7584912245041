import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { CCMCSharedModule } from '../../../@ccmc/shared.module';

import { CCMCNavbarComponent } from '../../../app/main/navbar/navbar.component';
import { CCMCNavigationModule } from '../../../@ccmc/components';

@NgModule({
    declarations: [
        CCMCNavbarComponent
    ],
    imports     : [
        RouterModule,

        MatButtonModule,
        MatIconModule,

        CCMCSharedModule,
        CCMCNavigationModule
    ],
    exports     : [
        CCMCNavbarComponent
    ]
})
export class CCMCNavbarModule {
}
