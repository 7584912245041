import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { CCMCDirectivesModule } from '../@ccmc/directives/directives';
import { CCMCPipesModule } from '../@ccmc/pipes/pipes.module';
import { AppMaterialModule } from '../app/app-material/app-material.module';
import { CCMCErrorDialogModule } from './components/error-dialog/error-dialog.module';
import { CCMCSearchBorrowerDialogModule } from './components/search-borrower-dialog/search-borrower-dialog.module';
import { CCMCFieldHelpDialogModule } from './components/field-help-dialog/field-help-dialog.module';
import { CCMCCollateralSearchDialogModule } from './components/collateral-search-dialog/collateral-search-dialog.module';
import { CCMCForceChangePasswordModule } from './components/force-change-password/force-change-password.module';
import { CCMCSuccessDialogModule } from './components/success-dialog/success-dialog.module';
import { AddValidationDialogModule } from './components/add-validation-dialog/add-validation-dialog.module';
import { SuffixSearchDialogModule } from './components/suffix-search-dialog/suffix-search-dialog.module';
import { SelectSetsDialogModule } from './components/select-sets-dialog/select-sets-dialog.module';
import { UpdatePremierCredentialsModule } from './components/update-premier-credentials/update-premier-credentials.module';
import { ColorPickerDialogModule } from './components/color-picker-dialog/color-picker-dialog.module';
import { RequestChangeDialogModule } from './components/request-change-dialog/request-change-dialog.module';
import { RequestChangeDialogModuleBlank } from './components/request-change-dialog-blank/request-change-dialog.module';
import { NewStatusDialogModule } from './components/new-status-dialog/new-status-dialog.module';
import { AssetSelectorEditDialogModule } from './components/asset-selector-edit-dialog/asset-selector-edit-dialog.module';
import { IbsCredentialModule } from './components/ibs-credential/ibs-credential.module';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { HorizonXchangeCollateralSearchDialogModule } from './components/horizon-xchange-collateral-search-dialog/horizon-xchange-collateral-search-dialog.module';
import { AmplifyQrCodeDialogModule } from './components/amplify-qr-code-dialog/amplify-qr-code-dialog.module';
import { KeystoneAccountSearchDialogModule } from './components/keystone-account-search-dialog/keystone-account-search-dialog.module';
import { CCMCAddSupportValuesDialogModule } from './components/add-support-values-dialog/add-support-values-dialog.module';
import { CCMCConfirmDialogModule } from './components/confirm-dialog/confirm-dialog.module';
import { AddGlobalMetaDialogModule } from './components/add-global-meta-dialog/add-global-meta-dialog.module';
import { AddNewSourceDialogModule } from './components/add-new-source-dialog/add-new-source-dialog.module';
import { DocConnectorHistoryResponseDialogModule } from './components/doc-connector-history-response-dialog/doc-connector-history-response-dialog.module';
import { AddNewSndMetaModule } from './components/add-new-snd-meta/add-new-snd-meta.module';
import { GetPathDialogModule } from './components/get-path-dialog/get-path-dialog.module';
import { TargetResponseDialogModule } from './components/target-response-dialog/target-response-dialog.module';
import { DebitCeditCheckDialogModule } from './components/debit-cedit-check-dialog/debit-cedit-check-dialog.module';
import { EditUserPopupDialogModule } from './components/edit-user-popup-dialog/edit-user-popup-dialog.module';
import { AddNewUserDialogModule } from './components/add-new-user-dialog/add-new-user-dialog.module';
import { AddAssetToUserDialogModule } from './components/add-asset-to-user-dialog/add-asset-to-user-dialog.module';
import { UpdateMiserCredentialsModule } from './components/update-miser-credentials/update-miser-credentials.module';
import { AddGlaTranslatorsDialogModule } from './components/add-gla-translators-dialog/add-gla-translators-dialog.module';
import { AddRemoveColumnsDialogModule } from './components/add-remove-columns-dialog/add-remove-columns-dialog.module';
import { AddTargetLayoutColumnDialogModule } from './components/add-target-layout-column-dialog/add-target-layout-column-dialog.module';
import { CreateSetColumnDialogModule } from './components/create-set-column-dialog/create-set-column-dialog.module';
import { CreateSetDialogModule } from './components/create-set-dialog/create-set-dialog.module';
import { CreateSetTransactionDialogModule } from './components/create-set-transaction-dialog/create-set-transaction-dialog.module';
import { EditSetColumnDialogModule } from './components/edit-set-column-dialog/edit-set-column-dialog.module';
import { EditSetDialogModule } from './components/edit-set-dialog/edit-set-dialog.module';
import { EditSetTransactionDialogModule } from './components/edit-set-transaction-dialog/edit-set-transaction-dialog.module';
import { FindAndReplaceDialogModule } from './components/find-and-replace-dialog/find-and-replace-dialog.module';
import { GetFieldIDDialogModule } from './components/get-field-id-dialog/get-field-id-dialog.module';
import { SnippetsDialogModule } from './components/snippets-dialog/snippets-dialog.module';
import { SelectPremierCredentialsModule } from './components/select-premier-credentials/select-premier-credentials.module';
import { AddUserPopupDialogModule } from './components/add-user-popup-dialog/add-user-popup-dialog.module';
import { AddUsergroupModule } from './components/add-usergroup/add-usergroup.module';
import { EditAssetDialogModule } from './components/edit-asset-dialog/edit-asset-dialog.module';
import { EditSourceDialogModule } from './components/edit-source-dialog/edit-source-dialog.module';
import { CCMCCopyTextDialogModule } from './components/copy-text-dialog/copy-text-dialog.module';
import { ShouldSendGlDialogModule } from './components/should-send-gl-dialog/should-send-gl-dialog.module';
import { SendDocConnectorDialogModule } from './components/send-doc-connector-dialog/send-doc-connector-dialog.module';
import { EditLoanTypeDialogComponent } from './components/edit-loan-type-dialog/edit-loan-type-dialog.component';
import { NewLoanTypeDialogComponent } from './components/new-loan-type-dialog/new-loan-type-dialog.component';
import { EditValidationGroupDialogModule } from './components/edit-validation-group-dialog/edit-validation-group-dialog.module';
import { EditFieldidsDialogModule } from './components/edit-fieldids-dialog/edit-fieldids-dialog.module';
import { AddValidationGroupDialogModule } from './components/add-validation-group-dialog/add-validation-group-dialog.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CCMCErrorDialogModule,
    CCMCSuccessDialogModule,
    FlexLayoutModule,
    CCMCAddSupportValuesDialogModule,
    CCMCDirectivesModule,
    CCMCPipesModule,
    AppMaterialModule,
    CCMCSearchBorrowerDialogModule,
    CCMCFieldHelpDialogModule,
    CCMCCollateralSearchDialogModule,
    CCMCForceChangePasswordModule,
    AddValidationDialogModule,
    SuffixSearchDialogModule,
    SelectSetsDialogModule,
    UpdatePremierCredentialsModule,
    CCMCConfirmDialogModule,
    DebitCeditCheckDialogModule,
    ColorPickerDialogModule,
    RequestChangeDialogModule,
    EditUserPopupDialogModule,
    EditAssetDialogModule,
    AddUsergroupModule,
    AddNewUserDialogModule,
    AddUserPopupDialogModule,
    AddAssetToUserDialogModule,
    RequestChangeDialogModuleBlank,
    NewStatusDialogModule,
    IbsCredentialModule,
    NgxQRCodeModule,
    AmplifyQrCodeDialogModule,
    HorizonXchangeCollateralSearchDialogModule,
    AddGlobalMetaDialogModule,
    AddNewSourceDialogModule,
    DocConnectorHistoryResponseDialogModule,
    AddNewSndMetaModule,
    KeystoneAccountSearchDialogModule,
    AddGlaTranslatorsDialogModule,
    AddRemoveColumnsDialogModule,
    AddTargetLayoutColumnDialogModule,
    CreateSetColumnDialogModule,
    CreateSetDialogModule,
    CreateSetTransactionDialogModule,
    EditSetColumnDialogModule,
    EditSetDialogModule,
    GetFieldIDDialogModule,
    EditSetTransactionDialogModule,
    FindAndReplaceDialogModule,
    SnippetsDialogModule,
    GetPathDialogModule,
    TargetResponseDialogModule,
    SelectPremierCredentialsModule,
    UpdateMiserCredentialsModule,
    EditSourceDialogModule,
    CCMCCopyTextDialogModule,
    ShouldSendGlDialogModule,
    SendDocConnectorDialogModule,
    EditValidationGroupDialogModule,
    EditFieldidsDialogModule,
    AddValidationGroupDialogModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CCMCErrorDialogModule,
    CCMCSuccessDialogModule,
    FlexLayoutModule,
    AddGlaTranslatorsDialogModule,
    AddRemoveColumnsDialogModule,
    AddTargetLayoutColumnDialogModule,
    CreateSetColumnDialogModule,
    CreateSetDialogModule,
    GetFieldIDDialogModule,
    CreateSetTransactionDialogModule,
    EditSetColumnDialogModule,
    EditSetDialogModule,
    EditSetTransactionDialogModule,
    FindAndReplaceDialogModule,
    SnippetsDialogModule,
    CCMCAddSupportValuesDialogModule,
    CCMCDirectivesModule,
    CCMCPipesModule,
    EditUserPopupDialogModule,
    EditAssetDialogModule,
    AddUsergroupModule,
    AddUserPopupDialogModule,
    AddNewUserDialogModule,
    AddAssetToUserDialogModule,
    AppMaterialModule,
    CCMCSearchBorrowerDialogModule,
    CCMCFieldHelpDialogModule,
    CCMCCollateralSearchDialogModule,
    CCMCForceChangePasswordModule,
    AddValidationDialogModule,
    SuffixSearchDialogModule,
    SelectSetsDialogModule,
    UpdatePremierCredentialsModule,
    CCMCConfirmDialogModule,
    DebitCeditCheckDialogModule,
    RequestChangeDialogModule,
    RequestChangeDialogModuleBlank,
    NewStatusDialogModule,
    IbsCredentialModule,
    NgxQRCodeModule,
    AmplifyQrCodeDialogModule,
    HorizonXchangeCollateralSearchDialogModule,
    AddGlobalMetaDialogModule,
    AddNewSourceDialogModule,
    DocConnectorHistoryResponseDialogModule,
    AddNewSndMetaModule,
    KeystoneAccountSearchDialogModule,
    GetPathDialogModule,
    TargetResponseDialogModule,
    SelectPremierCredentialsModule,
    UpdateMiserCredentialsModule,
    EditSourceDialogModule,
    CCMCCopyTextDialogModule,
    ShouldSendGlDialogModule,
    SendDocConnectorDialogModule,
    EditValidationGroupDialogModule,
    EditFieldidsDialogModule,
    AddValidationGroupDialogModule
  ],
  declarations : [
    EditLoanTypeDialogComponent,
    NewLoanTypeDialogComponent
  ]
})
export class CCMCSharedModule {}
