import { Injectable } from '@angular/core';
import { GCSettingsService } from './gcsettings.service';
import { NcinoService } from './ncino.service';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TCIService } from './tci.service';
import { AssetService } from './asset.service';
import { SageworksService } from './sageworks.service';
import { CalyxService } from './calyx.service';
import { EncompassService } from './encompass.service';
import { MortgageBotService } from './mortgagebot.service';
import { MortgageDirectorService } from './mortgagedirector.service';
import { OpenCloseService } from './openclose.service';
import { KasasaService } from './kasasa.service';
import { LinearService } from './linear.service';
import { BakerhillService } from './bakerhill.service';
import { NxtsoftService } from './nxtsoft.service';
import { OrigenateService } from './origenate.service';
import { ByteproService } from './bytepro.service';
import { EmpowerService } from './empower.service';
import { DefiOriginationsService } from './defioriginations.service';
import { LendingQBService } from './lendingqb.service';
import { MCPService } from './mcp.service';
import { LoanVantageService } from './loanvantage.service';
import { LoanQuestService } from './loanquest.service';
import { ActService } from './act.service';
import { LendingPadService } from './lendingpad.service';

@Injectable({
  providedIn: 'root'
})
export class ActiveLosService {
  private los: any;
  public activeLosService: any;
  public losSubject: Subject<string> = new Subject<string>();

  /**
   * Creates an instance of ActiveLosService.
   * @param {GCSettingsService} gcSettingsService
   * @param {NcinoService} ncinoService
   * @param {TCIService} tciService
   * @param {AssetService} assetService
   * @param {SageworksService} sageworksService
   * @param {MortgageBotService} mortgageBotService
   * @param {CalyxService} calyxService
   * @param {EncompassService} encompassService
   * @memberof ActiveLosService
   */
  constructor(
    private gcSettingsService: GCSettingsService,
    private bakerhillService: BakerhillService,
    private ncinoService: NcinoService,
    private tciService: TCIService,
    private assetService: AssetService,
    private sageworksService: SageworksService,
    private mortgageBotService: MortgageBotService,
    private calyxService: CalyxService,
    private encompassService: EncompassService,
    private mortgageDirectorService: MortgageDirectorService,
    private kasasaService: KasasaService,
    private linearService: LinearService,
    private openCloseService: OpenCloseService,
    private nxtsoftService: NxtsoftService,
    private origenateService: OrigenateService,
    private byteproService: ByteproService,
    private empowerService: EmpowerService,
    private defiOriginationsService: DefiOriginationsService,
    private lendingQBService: LendingQBService,
    private mcpService: MCPService,
    private loanVantageService: LoanVantageService,
    private loanQuestService: LoanQuestService,
    private actService: ActService,
    private lendingPadService: LendingPadService
  ) {}

  /**
   * Get Active Los
   *
   * @memberof ActiveLosService
   */
  get activeLos() {
    return this.los;
  }

  /**
   * Set Active Los
   *
   * @memberof ActiveLosService
   */
  set activeLos(los) {
    this.los = los;
    this.losSubject.next(los);
  }

  /**
   * Set Los
   * @description check if los isnt active and sets it
   * @returns
   * @memberof ActiveLosService
   */
  selectLos() {
    if (!this.los) {
      const assetId = this.assetService.getSelectedAssetId();
      this.gcSettingsService
        .retrieveSettings(assetId)
        .pipe(filter(settings => !!settings))
        .subscribe(settings => {
          this.activeLos = settings.los;
          this.setActiveLosService();
        });
      return;
    } else {
      this.setActiveLosService();
    }
  }

  /**
   * Set Active Los Service
   * @description sets reference to the current los service
   * @memberof ActiveLosService
   */
  setActiveLosService() {
    switch (this.los) {
      case 'bakerhill':
        this.activeLosService = this.bakerhillService;
        break;
      case 'ncino':
        this.activeLosService = this.ncinoService;
        break;
      case 'tci':
        this.activeLosService = this.tciService;
        break;
      case 'sageworks':
        this.activeLosService = this.sageworksService;
        break;
      case 'mortgagebot':
        this.activeLosService = this.mortgageBotService;
        break;
      case 'mortgagedirector':
        this.activeLosService = this.mortgageDirectorService;
        break;
      case 'calyx':
        this.activeLosService = this.calyxService;
        break;
      case 'encompass':
        this.activeLosService = this.encompassService;
        break;
      case 'kasasa':
        this.activeLosService = this.kasasaService;
        break;
      case 'linear':
        this.activeLosService = this.linearService;
        break;
      case 'openclose':
        this.activeLosService = this.openCloseService;
        break;
      case 'origenate':
        this.activeLosService = this.origenateService;
        break;
      case 'nxtsoft':
        this.activeLosService = this.nxtsoftService;
        break;
      case 'bytepro':
        this.activeLosService = this.byteproService;
        break;
      case 'empower':
        this.activeLosService = this.empowerService;
        break;
      case 'defioriginations':
        this.activeLosService = this.defiOriginationsService;
        break;
      case 'lendingqb':
        this.activeLosService = this.lendingQBService;
        break;
      case 'mcp':
        this.activeLosService = this.mcpService;
        break;
      case 'loanvantage':
        this.activeLosService = this.loanVantageService;
        break;
      case 'loanquest':
        this.activeLosService = this.loanQuestService;
        break;
      case 'act':
        this.activeLosService = this.actService;
        break;
      case 'lendingpad':
        this.activeLosService = this.lendingPadService;
        break;
      default:
        this.activeLosService = this.nxtsoftService;
    }
    this.losSubject.complete();
  }

  /**
   * Set Active Los
   * @param {*} los
   * @memberof ActiveLosService
   */
  setActiveLos(los: any) {
    this.los = los;
  }

  /**
   * get Active Los
   *
   * @returns
   * @memberof ActiveLosService
   */
  getActiveLos() {
    return this.los;
  }
}
