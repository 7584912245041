import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { CcmcApiService } from '../../services/ccmc-api.service';
import { CCMCSelectedFieldService } from '../../services/selected-field.service';
import { FieldEditedService } from '../../services/field-edited.service';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { AdminApiService } from 'src/@ccmc/services/admin-api.service';
import { SnackbarService } from 'src/@ccmc/services/snackbar.service';

/**
 * The CCMCAddSupportValuesDialogComponent lets user add new support values to the object
 *
 * @export
 * @class CCMCAddSupportValuesDialogComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ccmc-add-support-values-dialog',
  templateUrl: './add-support-values-dialog.component.html',
  styleUrls: ['./add-support-values-dialog.component.scss']
})
export class CCMCAddSupportValuesDialogComponent implements OnInit, OnDestroy {
  tempSupportValue: any;
  tempDocument: any;
  supportValuesSub: Subscription;
  supportValuesObject: any;
  unsubscribe: Subject<any> = new Subject();
  @ViewChild('categoryInput', { static: true }) categoryInputEl: ElementRef;
  @ViewChild('sourceInput', { static: true }) sourceInputEl: ElementRef;
  @ViewChild('valueInput', { static: true }) valueInputEl: ElementRef;
  constructor(
    private adminApiService: AdminApiService,
    private selectedFieldService: CCMCSelectedFieldService,
    private dialogRef: MatDialogRef<CCMCAddSupportValuesDialogComponent>,
    private fieldEditedService: FieldEditedService,
    private snackBarService: SnackbarService
  ) {}

  /**
   * Initialize the component
   *
   * @memberof CCMCAddSupportValuesDialogComponent
   */
  ngOnInit() {
    this.getData();
  }

  /**
   * Unsubscribe from observables on destroy
   *
   * @memberof CCMCAddSupportValuesDialogComponent
   */
  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  /**
   * Gets data needed for the component
   *
   * @memberof CCMCAddSupportValuesDialogComponent
   */
  getData() {
    // Subscribe to the document and grab the support values
    this.supportValuesSub = this.adminApiService.documentCore
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(document => {
        if (document) {
          this.tempDocument = document;
          this.supportValuesObject = document.supportValues;
          // console.log(this.supportValuesObject);
          this.tempSupportValue = {
            category: '',
            source: '',
            value: ''
          };
        }
      });
  }

  /**
   * Adds support value to the current array
   *
   * @memberof CCMCAddSupportValuesDialogComponent
   */
  addSupportValue() {
    //  Check if form is acceptable
    if (this.checkForm()) {
      // If there are already support values present
      if (this.supportValuesObject) {
        // Check if tempSupportValue is already in the support value object array
        if (
          !this.containsObject(this.tempSupportValue, this.supportValuesObject)
        ) {
          this.tempSupportValue.edited = true;
          // Adds tempSupportValue to the current array
          this.supportValuesObject.push(this.tempSupportValue);
          this.fieldEditedService.coreEdited.next(true);
          // Sets support array to new value
          this.tempDocument.supportValues = this.supportValuesObject;
          // update documentCore globally
          this.adminApiService.setDocumentCoreSimple(this.tempDocument);
          this.closeDialog();
        } else {
          this.snackBarService.openSnackBar(
            'Error: This Support Value already exists',
            'Okay'
          );
        }
        // If there are no support values we can avoid any duplicate checks
      } else {
        this.fieldEditedService.coreEdited.next(true);
        this.supportValuesObject = [
          {
            category: this.tempSupportValue.category,
            source: this.tempSupportValue.source,
            value: this.tempSupportValue.value,
            edited: true
          }
        ];
        //  Sets support values array to new value
        this.tempDocument.supportValues = this.supportValuesObject;
        // Update documentCore globally
        this.adminApiService.setDocumentCoreSimple(this.tempDocument);
        this.closeDialog();
      }
    }
  }

  /**
   * Closes the current dialog
   *
   * @memberof CCMCAddSupportValuesDialogComponent
   */
  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * Checks if tempsupportvalue form is valid
   *
   * @returns
   * @memberof CCMCAddSupportValuesDialogComponent
   */
  checkForm() {
    if (this.tempSupportValue.category.length < 1) {
      // Open snackbar with message
      this.snackBarService.openSnackBar(
        'Error: Category cannot be blank',
        'Okay'
      );
      // Refocus category input
      this.categoryInputEl.nativeElement.focus();
      // Return false to flag button as disabled
      return false;
    } else if (this.tempSupportValue.source.length < 1) {
      // Open snackbar with message
      this.snackBarService.openSnackBar(
        'Error: Source cannot be blank',
        'Okay'
      );
      // Refocus source input
      this.sourceInputEl.nativeElement.focus();
      // Return false to flag button as disabled
      return false;
    }
    // Return true to flag button as enabled
    return true;
  }

  /**
   * Checks if an object is already in the object list array
   *
   * @param {*} obj
   * @param {*} objList
   * @returns
   * @memberof CCMCAddSupportValuesDialogComponent
   */
  containsObject(obj: any, objList: any) {
    // Loop through object list and check if the current obj is already in the list
    for (let i = 0; i < objList.length; i++) {
      if (
        objList[i].category === obj.category &&
        objList[i].source === obj.source
      ) {
        return true;
      }
    }
    return false;
  }
}
