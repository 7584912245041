import { Injectable, NgZone } from '@angular/core';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CcmcApiService } from './ccmc-api.service';
import { Router } from '@angular/router';
import { navigation } from '../../app/navigation/navigation';
import { SpinnerService } from './spinner.service';
import { ActiveLosService } from './active-los.service';
import { AssetService } from './asset.service';
import { CaptureDataService } from './capture-data.service';
import { AmplifyService } from './amplify.service';
import { GlobalSearchService } from './global-search.service';
import { TargetResponseDialogComponent } from '../components/target-response-dialog/target-response-dialog.component';
import { APPCONSTANTS } from 'src/app/app.constants';
import { InvokeCommand, InvokeCommandInput, LambdaClient } from '@aws-sdk/client-lambda';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs';
import { CustomErrorHandlerService } from './custom-error-handler.service';
const { Buffer } = require('buffer');


/* Constants Section */
const FISERV_XP2_RESOURCE = '/fiserv/xp2';
const FISERV_XP2_LAMBDA_NAME = 'fiserv-xp2-communicator-lending-connector';


@Injectable({
  providedIn: 'root'
})
export class XP2Service {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  
  dynamicNav: any = navigation;
  HTTP_OPTIONS = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'authorization': ''
  }
  constructor(
    private dialog: MatDialog,
    private ccmcApiService: CcmcApiService,
    public router: Router,
    private spinnerService: SpinnerService,
    private assetService: AssetService,
    private amplifyService: AmplifyService,
    private activeLosService: ActiveLosService,
    private captureDataService: CaptureDataService,
    private globalSearchService: GlobalSearchService,
    private http: HttpClient,
    private customErrorHandlerService: CustomErrorHandlerService,
  ) {}

  async send() {
    this.spinnerService.setShowSpinner(true);
    let userName = await this.amplifyService.getUserName();
    const exportObject = {
      content: {
        mapping: this.ccmcApiService.mapping,
        configurations: this.ccmcApiService.configurations,
        transactions: this.ccmcApiService.transactions
      },
      user: userName,
      assetID: this.assetService.getSelectedAssetId()
    };
    let exportMessagesResponse: any;
    (await this.exportMessages(exportObject)).subscribe((result:any) => {
      exportMessagesResponse = result;

      if (exportMessagesResponse.error) {
        const errorMessage = {
          statusMessage: exportMessagesResponse.error.message,
          statusFlag: false
        };
        this.dialog.open(TargetResponseDialogComponent, {
          data: errorMessage
        });
        this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: this.getLoanNumber(),
          statusMessage: exportMessagesResponse.error,
          statusFlag: exportMessagesResponse.statusFlag
        });
      } else if (exportMessagesResponse.statusFlag === false) {
        const loanNumber = this.getLoanNumber();
        if (exportMessagesResponse.content && exportMessagesResponse.content.mapping) {
          // Retrieve Validation From existing mapping object
          let existingMappingCopy = JSON.parse(
            JSON.stringify(this.ccmcApiService.mapping)
          );
          for (let mappingItem of exportMessagesResponse.content.mapping) {
            let existingMappingIndex = existingMappingCopy.findIndex(
              (obj: any) => obj.fieldID === mappingItem.fieldID
            );
            if (
              existingMappingIndex > -1 &&
              existingMappingCopy[existingMappingIndex].validation
            ) {
              mappingItem.validation =
                existingMappingCopy[existingMappingIndex].validation;
            }
          }
          this.ccmcApiService.mapping = exportMessagesResponse.content.mapping;
        }
        this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: loanNumber,
          statusMessage: exportMessagesResponse.statusMessage,
          statusFlag: exportMessagesResponse.statusFlag
        });
        this.globalSearchService.initData();
        const errorMessage = {
          statusMessage: exportMessagesResponse.statusMessage,
          statusFlag: false
        };
        const dialogRef = this.dialog.open(TargetResponseDialogComponent, {
          data: errorMessage
        });
      } else if (exportMessagesResponse.statusFlag === true) {
        let loanNumber;
        if (exportMessagesResponse.loanNumber) {
          loanNumber = exportMessagesResponse.loanNumber;
        } else {
          loanNumber = this.getLoanNumber();
        }
        const resultMessage = {
          statusMessage: exportMessagesResponse.statusMessage,
          loanNumber: loanNumber,
          statusFlag: true
        };
        this.captureDataService.uploadS3File(this.ccmcApiService.mapping);
        this.activeLosService.activeLosService.loanBooked(
          exportMessagesResponse,
          JSON.parse(JSON.stringify(this.ccmcApiService.configurations))
        );
        this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: loanNumber,
          statusMessage: exportMessagesResponse.statusMessage,
          statusFlag: exportMessagesResponse.statusFlag
        });
        this.ccmcApiService.mapping = undefined;
        this.ccmcApiService.coreSettings = undefined;
        this.ccmcApiService.conditions = undefined;
        this.ccmcApiService.mapping = undefined;
        this.ccmcApiService.supportValues = undefined;
        this.ccmcApiService.search = undefined;
        this.ccmcApiService.transactions = undefined;
        this.ccmcApiService.configurations = undefined;
        if (this.dynamicNav[1].children[1]) {
          this.dynamicNav[1].children[1].children = [];
        }
        this.dynamicNav[1].children[0].badge.title = undefined;
        const dialogRef = this.dialog.open(TargetResponseDialogComponent, {
          data: resultMessage
        });
        dialogRef.afterClosed().subscribe(result => {
          if (this.activeLosService.activeLos === 'encompass' || this.activeLosService.activeLos === 'bytepro') {
            this.router.navigate(['loan-lookup']);
          } else {
            this.router.navigate(['file-selector']);
          }
        });
      }
    });
  }

  async customerSearch(borrower: any, callback: any) {
    this.spinnerService.setShowSpinner(true);
    let userName = await this.amplifyService.getUserName();
    borrower.cognitoID = this.amplifyService.getCognitoId();
    const borrowerRequest ={
      content: borrower,
      assetID: this.assetService.getSelectedAssetId(),
      user: userName
    };
    let searchCustomerResponse: any;
    (await this.searchCustomer(borrowerRequest)).subscribe((result: any) => {
      searchCustomerResponse = result;

      console.log('searchCustomerResponse', searchCustomerResponse);
      if (searchCustomerResponse.statusFlag === true) {
        this.spinnerService.setShowSpinner(false);
        callback(searchCustomerResponse.content);
      } else {
        const errorMessage = {
          message: searchCustomerResponse.statusMessage,
          title: 'Search Error'
        };
        this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      }
    });
  }

  async searchCustomer(params: any) {
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/fiserv/xp2/search-customer`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * @description set the field value to the customer name
   */
  assignBorrowers(party: any) {
    for (let i = 0; i < party.length; i++) {
      const CIFFields = party[i].CIFFields.split(',');
      CIFFields.forEach((cf: any) => {
        const cifIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) => f.fieldID === cf.trim()
        );
        this.ccmcApiService.mapping[cifIndex].fieldValue = party[i].CIF;
      });
    }
  }

  async autoCustomerSearch(borrower: any, callback: any) {
    this.spinnerService.setShowSpinner(true);
    let userName = await this.amplifyService.getUserName();
    borrower.cognitoID = this.amplifyService.getCognitoId();
    const borrowerRequest = {
      content: borrower,
      assetID: this.assetService.getSelectedAssetId(),
      user: userName
    };
    let searchCustomerResponse: any;
    (await this.searchCustomer(borrowerRequest)).subscribe((result: any) => {
      searchCustomerResponse = result;

      this.spinnerService.setShowSpinner(false);
      if (searchCustomerResponse.error) {
        const errorMessage = {
          message: JSON.stringify(searchCustomerResponse.error.message),
          title: 'XP2 Result'
        };

        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      } else if (searchCustomerResponse.statusFlag === true) {
        const payload = searchCustomerResponse.content;
        if (payload.length > 0) {
          callback(payload);
        } else {
          const noResult = [
            {
              firstName: 'No Result',
              lastName: '',
              taxID: '',
              CIF: '',
              address: ''
            }
          ];
          console.log(noResult);
          callback(noResult);
        }
      } else if (searchCustomerResponse.statusFlag === false) {
        if (
          searchCustomerResponse.statusMessage
            .toLowerCase()
            .includes('no customer record found')
        ) {
          const noResult = [
            {
              firstName: 'No Result',
              lastName: '',
              taxID: '',
              CIF: '',
              address: ''
            }
          ];
          console.log(noResult);
          callback(noResult);
        } else {
          const errorMessage = {
            message: searchCustomerResponse.statusMessage,
            title: 'Search Error'
          };
          this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
          callback(searchCustomerResponse);
        }
      }
    });
  }

  getLoanNumber() {
    console.log('get Loan number');
    let loanFieldIndex = this.ccmcApiService.mapping.findIndex(
      (obj: any) => obj.fieldID === 'AS00_00_004'
    );
    if (loanFieldIndex > -1) {
      // Return loan number
      console.log(
        'loan number',
        this.ccmcApiService.mapping[loanFieldIndex].fieldValue
      );
      return this.ccmcApiService.mapping[loanFieldIndex].fieldValue;
    }
  }

   exportMessages(params: any) {
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/fiserv/xp2/export-messages`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  retrieveInvokeEnvironment(url: string) {
    if (url.includes('dev')) {
      return 'dev';
    } else if (url.includes('test')) {
      return 'test';
    } else {
      return 'prod';
    }
  }
}
