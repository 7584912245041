<div style="display: grid; grid-template-rows: 1fr 1fr 1fr 1fr; padding-left: 30px; padding-right: 30px;">
  <div style="display: flex; justify-content: space-between;">
    <h1>Add User to Usergroup</h1>
    <div style="position: relative; top: 15px; height: 50px;">
      <button class="addBtn" mat-raised-button [disabled]="!editedFlag" (click)="modifyUserGroups()" color="accent">
        Submit
      </button>
    </div>
  </div>
  <div>
    <mat-form-field class="username" style="width: 30%;">
      <input matInput placeholder=" Username..." [(ngModel)]="username">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="usergroup" style="width: 30%">
      <mat-label>Cognito Groups</mat-label>
      <mat-select [formControl]="userGroup" name="listOfCognitoGroups" multiple (selectionChange)="checkifEqual($event.value)">
      <mat-option
            *ngFor="let CognitoGroup of listOfCognitoGroups"
            [value]="CognitoGroup"
            (onSelectionChange)="userGroupsSelectionChange($event)"
            #matOption
          >
            {{ CognitoGroup.Description }}
          </mat-option>
        
      </mat-select>
    </mat-form-field>
  </div>
</div>
