import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CcmcApiService } from '../../services/ccmc-api.service';
import { EncompassService } from '../../services/encompass.service';
import { SpinnerService } from '../../services/spinner.service';
import { GetPathDialogComponent } from '../get-path-dialog/get-path-dialog.component';

@Component({
  selector: 'app-get-field-id-dialog',
  templateUrl: './get-field-id-dialog.component.html',
  styleUrls: ['./get-field-id-dialog.component.scss']
})
export class GetFieldIdDialogComponent implements OnInit {
  fieldName: any;
  jsonPath;
  encompassID: any;
  showSpinner: boolean;
  private spinnerSub: Subscription;
  unsubscribe: Subject<any> = new Subject();
  jsonSchema: any;
  constructor(
    private dialogRef: MatDialogRef<GetFieldIdDialogComponent>,
    private ccmcAPIService: CcmcApiService,
    private snackBar: MatSnackBar,
    private zone: NgZone,
    private encompassService: EncompassService,
    private spinnerService: SpinnerService,
    @Inject(MAT_DIALOG_DATA) public selectedTranslator: any
  ) {
    if (this.selectedTranslator.vbScript.length > 0) {
      this.jsonPath = this.selectedTranslator.vbScript;
    }
  }

  ngOnInit() {
    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        this.showSpinner = spinner;
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next(0);
    this.unsubscribe.complete();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string) {
    this.zone.run(() => {
      setTimeout(() => {
        this.snackBar.open(message, action, {
          duration: 5000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }, 0);
    });
  }

  getEncompassFieldID() {
    const tempSchema = localStorage.getItem('encompass-json-schema');
    if (tempSchema) {
      this.jsonSchema = JSON.parse(tempSchema);
      this.getFieldID();
      return;
    }
    const params = {
      fieldName: this.fieldName,
      assetID: localStorage.getItem('selectedAsset')
    };
    this.spinnerService.setShowSpinner(true);
    this.encompassService.getJSONSchema(params).subscribe(result => {
      const parsedResult = JSON.parse(JSON.stringify(result));
      this.spinnerService.setShowSpinner(false);
      if (parsedResult.statusFlag === true) {
        localStorage.setItem(
          'encompass-json-schema',
          parsedResult.content.data
        );
        this.jsonSchema = JSON.parse(parsedResult.content.data);
        this.getFieldID();
        // this.dialogRef.close({ data: this.fieldPath });
      } else {
        console.log(parsedResult.error);
        if (parsedResult.error) {
          this.openSnackBar(parsedResult.error, 'Okay');
        }
        if (parsedResult.error && parsedResult.error.details) {
          this.openSnackBar(parsedResult.error.details, 'Okay');
        }
      }
    });
  }

  getFieldID() {
    const formatedJSON = this.formatJSONPath(this.jsonPath);
    const jsonIndex = this.jsonSchema.findIndex((field: any) =>
      field.jsonPath.includes(formatedJSON)
    );
    if (jsonIndex > -1) {
      console.log(this.jsonSchema[jsonIndex]);
      this.encompassID = this.jsonSchema[jsonIndex].id;
    } else {
      this.openSnackBar('No EncompassID found for JSON Path', 'Okay');
    }
  }

  formatJSONPath(jsonPath: any) {
    let tempJSONPath = jsonPath.replace('.~#~', '[0]');
    tempJSONPath = tempJSONPath.replace('"', "'");
    tempJSONPath = tempJSONPath.replace('{', '(').replace('}', ')');
    return tempJSONPath;
  }
}
